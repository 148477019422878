import { Usage } from '@async-reducers/usages';
import enums from 'enums';
import { getLastDayOfTheMonth } from './../dates';

interface monthlyValues {
    dateFrom: string;
    dateTill: string;
    intervalType: number;
    snapshotID: string;
    get(): void;
}

export class MonthlyValues implements monthlyValues {
    dateFrom: string;
    dateTill: string;
    intervalType: number;
    snapshotID: string;
    // required to refetch monthly data when all current data is 0
    constructor(
        snapshotID: string,
        from?: Date,
        till?: Date,
        interval?: number,
    ) {
        this.snapshotID = snapshotID;
        this.dateFrom = from ? from.toISOString() : new Date().toISOString();
        this.dateTill = till ? till.toISOString() : new Date().toISOString();
        if (till == null && from != null) {
            this.dateTill = getLastDayOfTheMonth(from).toISOString();
        }
        this.intervalType = interval ? interval : enums.IntervalTypeHourly;
    }

    get(): Usage {
        return {
            dateFrom: this.dateFrom,
            dateTill: this.dateTill,
            intervalType: this.intervalType,
            snapshotID: this.snapshotID,
        };
    }
}
