import { Resource } from './helpers/Resource';
import { Bearer } from './helpers/types/Bearer';
import { UUID } from './helpers/types/UUID';
import { ISODate } from './helpers/types/ISODate';

/**
 * Store member management object for usage
 * RESOURCE ZELF GESCHREVEN VOOR GPRC, HOE WILLEN WE DAAR UBERHAUPT MEE OMGAAN? MET SERVICE CALLS IN FRONTEND
 */
export class CostSummary extends Resource {
    /**
     * Action string
     * @return {string}
     */
    static get action() {
        return 'CostSummary';
    }

    /**
     * Description string
     * @return {string}
     */
    static get description() {
        return 'Get the summary of costs';
    }

    /**
     * Request method
     * @return {string}
     */
    static get method() {
        return 'get';
    }

    /**
     * Resource string
     * @return {string}
     */
    static get resource() {
        return 'snapshot';
    }

    /**
     * Route string
     * @return {string}
     */
    static get route() {
        return 'cost/summary/{snapshotId}';
    }

    /**
     * Parameters object
     * @return {Object}
     */
    static get parameters() {
        return {
            snapshotId: {
                in: 'path',
                type: UUID,
                required: true,
            },
            from: {
                in: 'query',
                name: 'from',
                required: true,
                type: ISODate,
            },
            'x-auth': {
                in: 'header',
                required: true,
                type: Bearer,
            },
        };
    }
}
