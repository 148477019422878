import { provider } from '@weavelab/frontend-connect';
import { PolymerElement, html } from '@polymer/polymer/polymer-element';

// Router import
import { Router } from '@vaadin/router';
import { formSlotInjector } from 'helpers/formInjector';
import { loginHook } from 'helpers/loginHook';
import { routePaths } from '../../../helpers/routing';

import { store } from '../../../store';

import { updateTermsAndConditions } from '../../../actions/app';

import css from '../ez-lander/lander.pcss';
import template from './login.html';

import '@polymer/paper-input/paper-input';
import '@polymer/iron-flex-layout/iron-flex-layout-classes';

import { doSignInCall } from '../../../helpers/signIn';
import { fromLanderPage } from '../../../actions/history';

const themeSettings = provider.settings('lander');

/**
 * Login class
 */
export default class EzLogin extends formSlotInjector('form')(PolymerElement) {
    /**
     * Gets properties of class
     */
    static get properties() {
        return {
            email: {
                type: String,
            },
            password: {
                type: String,
            },
        };
    }

    /**
     * Gets template of class
     */
    static get template() {
        const cssTemplate = document.createElement('template');
        cssTemplate.innerHTML = provider.styles(css);
        const htmlTemplate = document.createElement('template');
        htmlTemplate.innerHTML = template;
        return html`<style include="iron-flex">
                ${cssTemplate}
            </style>
            ${htmlTemplate}`;
    }

    /**
     * Constructor of reset password
     */
    constructor() {
        super();

        /** @type {String} */
        this.body =
            themeSettings && themeSettings.body ? themeSettings.body : '';
        /**
         * Email logic
         */
        /** @type {String} */
        this.emailTitle =
            themeSettings && themeSettings.emailTitle
                ? themeSettings.emailTitle
                : '';
        /** @type {String} */
        this.emailPlaceholder =
            themeSettings && themeSettings.emailPlaceholder
                ? themeSettings.emailPlaceholder
                : '';
        /**
         * Password logic
         */
        /** @type {String} */
        this.passwordTitle =
            themeSettings && themeSettings.passwordTitle
                ? themeSettings.passwordTitle
                : '';
        /** @type {String} */
        this.passwordPlaceholder =
            themeSettings && themeSettings.passwordPlaceholder
                ? themeSettings.passwordPlaceholder
                : '';
        /** @type {String} */
        this.forgotPassword =
            themeSettings && themeSettings.forgotPassword
                ? themeSettings.forgotPassword
                : '';
        /** @type {String} */
        this.forgotPasswordPre =
            themeSettings && themeSettings.forgotPasswordPre
                ? themeSettings.forgotPasswordPre
                : '';
        /** @type {String} */
        this.body =
            themeSettings && themeSettings.bodyLogin
                ? themeSettings.bodyLogin
                : 'Log hieronder in met je bestaande account.';
    }

    /**
     * onBeforeEnter is a Vaadin router lifecycle method
     * @param {RouterLocation} location
     * @param {PreventAndRedirectCommands} commands
     * @param {Router} router
     */
    // onBeforeEnter(location, commands, router) {
    //     console.warn('onBeforeEnter login', location, commands, router);
    // }

    /**
     * Subscribes on user state when element is ready
     */
    ready() {
        super.ready();
        this.$?.pass?.addEventListener('keypress', (e) => {
            if (e instanceof KeyboardEvent) {
                const key = e.which || e.keyCode;
                if (key === 13) {
                    // 13 is enter
                    this._login();
                }
            }
        });

        // Target current login flow with login form
        loginHook.onLogin = this.onLogin;
    }

    /**
     * Navigates the forgot password page
     */
    _goToForgot = () => {
        store.dispatch(fromLanderPage());
        Router.go(routePaths.forgot);
    };

    /**
     * Login handler
     * @param {string} email what the user has claimed their email address is
     * @param {string} password what the user has claimed their password is
     */
    onLogin = async (email, password) => {
        this.email = email;
        this.password = password;
        await this._login();
    };

    /**
     * Login dispatch sigin function to the store
     */
    async _login() {
        if (this.email === '' || this.password === '') {
            window.displayMessage(
                'E-mail en wachtwoord moeten worden ingevuld.',
            );
            return;
        }
        const email = this.email.trim();
        const re = new RegExp('^[^@\\s:]+@[^@\\s]+\\.[^@\\s]+$');
        if (!re.test(email)) {
            window.displayMessage('Dit is geen geldig e-mailadres.');
            return;
        }
        store.dispatch(updateTermsAndConditions(true));

        const [response, err] = await doSignInCall(email, this.password);
        if (
            err ||
            response?.user?.contracts == null ||
            response.type !== 'RECEIVE_USER'
        ) {
            window.displayMessage(
                'Er is iets fout gegaan tijdens het inloggen probeert u het later nog eens',
            );

            return;
        }

        // If user is logged in, reroute the user to the customer form
        this.email = '';
        this.password = '';

        if (response.user.contracts) {
            window.displayMessage('Succesvol ingelogd');
            Router.go(routePaths.customerForm);
        }
    }

    /**
     * Back will remove redirect url and let the user go to prev page
     */
    _back = () => {
        Router.go(routePaths.customerForm);
    };
}

window.customElements.define('ez-login', EzLogin);
