import { customElement, html, LitElement } from 'lit-element';
import { whitelabel } from '@weavelab/whitelabel';
import helperTextStyle from './helperTextStyle';

// ChangePasswordHelperText is the helper text with styling, used in the change password form.
// Because the forms are rendered without a shadow, styling must be done in seperate components (like this one)
// and then be imported into the change password form
@customElement('ice-change-password-helper-text')
@whitelabel({ name: 'ice-change-password-helper-text' })
export class ChangePasswordHelperText extends LitElement {
    public static get styles() {
        return [helperTextStyle];
    }

    public render() {
        return html`
            <p>
                Vul hieronder je nieuwe wachtwoord twee keer in. Hierboven staan
                een aantal vereisten om je wachtwoord te versterken.
            </p>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        'ice-change-password-helper-text': ChangePasswordHelperText;
    }
}
