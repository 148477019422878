import { resourcesSetup } from 'goagen-js-resource';
import { applyMiddleware, compose as origCompose, createStore } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
// defaults to localStorage for web and AsyncStorage for react-native
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk';
import { defaultAuthParam, defaultParam, xAuth } from './core/store/store';
import enums from './enums';
// Google tag manager middleware
import { gtmMiddelware } from './gtm-middleware';
import reducers from './reducers';
import { AcceptRecalculationOffer } from './resources/AcceptRecalculationOffer';
import { ArchiveSnapshot } from './resources/ArchiveSnapshot';
import { CostSummary } from './resources/CostSummary';
import { CreateLead } from './resources/CreateLead';
import { DownloadDocument } from './resources/DownloadDocument';
import { GetAuthToken } from './resources/GetAuthToken';
import { GetChallengeScoreboard } from './resources/GetChallengeScoreboard';
import { GetCompetitionComparisonStats } from './resources/GetCompetitionComparisonStats';
import { GetCompetitionScoreboard } from './resources/GetCompetitionScoreboard';
import { InitOda } from './resources/InitOda';
import { ListBusinessDeclarationDocumentsUser } from './resources/ListBusinessDeclarationDocumentsUser';
import { ListDocuments } from './resources/ListDocuments';
import { ListInvoice } from './resources/ListInvoices';
import { ListInvoicesDocumentsUser } from './resources/ListInvoicesDocumentsUser';
import { ListSplitBusiness } from './resources/ListSplitBusiness';
import { ListVkoDocumentsUser } from './resources/ListVkoDocumentsUser';
import { ListWorkDaysSchedule } from './resources/ListWorkDaysSchedule';
import { NotifyEdsnLiveProductoffering } from './resources/NotifyEdsnLiveProductoffering';
import { OfferAcceptSnapshot } from './resources/OfferAcceptSnapshot';
import { OfferRecalculateSnapshot } from './resources/OfferRecalculateSnapshot';
import { SendRecalculationOfferInvalidMail } from './resources/SendRecalculationOfferInvalidMail';
import { SettledSchedule } from './resources/SettledSchedule';
import { SettleMonthlyWorkdaysSchedule } from './resources/SettleMonthlyWorkdaysSchedule';
import { ShowCost } from './resources/ShowCosts';
import { ShowMonthCostsCost } from './resources/ShowMonthCostsCost';
import { ShowUsage } from './resources/ShowUsage';
import { UpdateCompetitionAlias } from './resources/UpdateCompetitionAlias';
import { UpdateSnapshot } from './resources/UpdateSnapshot';
import { VerifyOda } from './resources/VerifyOda';
import sagas from './sagas';

// Init saga middleware
const sagaMiddleware = createSagaMiddleware();

const persistConfig = {
    key: 'root',
    storage,
    whitelist: [
        'app',
        'costsMonth',
        'costSummary',
        'costValues',
        'jwtSnapshots',
        'order',
        'offers',
        'entrollStatus',
        'createUser',
        'createUserLocation',
        'createInvoiceLocation',
        'createUserPaymentAccount',
        'setStartDate',
    ],
};

// Sets up a Chrome extension for time travel debugging.
// See https://github.com/zalmoxisus/redux-devtools-extension for more information.
const compose =
    window.location &&
    window.location.href.toLowerCase().indexOf('energyzero.nl') > -1
        ? origCompose
        : window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || origCompose;

// Initializes the Redux store with a lazyReducerEnhancer (so that you can
// lazily add reducers after the store has been created) and redux-thunk (so
// that you can dispatch async actions). See the "Redux and state management"
// section of the wiki for more details:
// https://github.com/Polymer/pwa-starter-kit/wiki/4.-Redux-and-state-management
const middlewares = [sagaMiddleware, thunk, gtmMiddelware()];
const enhancers = compose(applyMiddleware(...middlewares));

const defaultParamFactory = (defaultParamOptions = {}, basePath = '/v1') => {
    const defaults = {
        defaultParameters: {
            xAuth,
            ...defaultParamOptions,
        },
        basePath,
    };
    return defaults;
};

// offer recal
const offerRecalculationParams = defaultParamFactory(undefined, '/v2');
export const offerRecalculation = new OfferRecalculateSnapshot(
    'offerRecalculation',
    offerRecalculationParams,
);

const acceptOfferRecalculationParams = defaultParamFactory(undefined, '/v2');
export const acceptOfferRecalculation = new OfferAcceptSnapshot(
    'acceptOfferRecalculation',
    acceptOfferRecalculationParams,
);

export const requestInvalidOfferRecalculation =
    new SendRecalculationOfferInvalidMail(
        'requestInvalidOfferRecalculation',
        defaultParamFactory(),
    );

/** Usages */
export const gasUsage = new ShowUsage(
    'gasUsage',
    defaultParamFactory({ usageType: enums.UsageTypeGasUsage }),
);

export const electricityUsage = new ShowUsage(
    'electricityUsage',
    defaultParamFactory({ usageType: enums.UsageTypeElekUsage }),
);

export const chargingSessionsUsage = new ShowUsage(
    'chargingSessionsUsage',
    defaultParamFactory({
        usageType: enums.UsageTypeElekUsage,
        chargerData: true,
    }),
);

export const productionUsage = new ShowUsage(
    'productionUsage',
    defaultParamFactory({ usageType: enums.UsageTypeElekProd }),
);

export const showMonthCostsPredictions = new ShowMonthCostsCost(
    'showMonthCostsPredictions',
    defaultParam,
);

export const showMonthCosts = new ShowMonthCostsCost(
    'showMonthCosts',
    defaultParam,
);

/** Estimations */
export const electricityUsageEstimation = new ShowUsage(
    'electricityUsageEstimation',
    defaultParamFactory({ usageType: enums.UsageTypeElekUsageEstimation }),
);
export const gasUsageEstimation = new ShowUsage(
    'gasUsageEstimation',
    defaultParamFactory({ usageType: enums.UsageTypeGasUsageEstimation }),
);

/**  Costs */
export const elekUsageCost = new ShowCost(
    'elekUsageCost',
    defaultParamFactory({ usageType: enums.UsageTypeElekUsage }),
);

export const elekProdCost = new ShowCost(
    'elekProdCost',
    defaultParamFactory({ usageType: enums.UsageTypeElekProd }),
);
export const gasUsageCost = new ShowCost(
    'gasUsageCost',
    defaultParamFactory({ usageType: enums.UsageTypeGasUsage }),
);
export const elekUsageEstimationCost = new ShowCost(
    'elekUsageEstimationCost',
    defaultParamFactory({ usageType: enums.UsageTypeElekUsageEstimation }),
);

export const listInvoices = new ListInvoice('ListInvoices', defaultParam);

// documents
export const listDocuments = new ListDocuments(
    'ListDocuments',
    defaultAuthParam,
);

export const downloadDocument = new DownloadDocument(
    'DownloadDocument',
    defaultAuthParam,
);

// recal offer
export const acceptRecalculationOffer = new AcceptRecalculationOffer(
    'AcceptRecalculationOffer',
    defaultAuthParam,
);

// TODO: service call, special case
export const costSummary = new CostSummary('costSummary', {
    ...defaultAuthParam,
    basePath: '/v2',
});

// TODO: no backend route matches this description.
export const getAuthToken = new GetAuthToken('GetAuthToken', defaultAuthParam);

export const createLead = new CreateLead('CreateLead');

export const updateSnapshot = new UpdateSnapshot(
    'UpdateSnapshot',
    defaultParam,
);
export const listSplitBusiness = new ListSplitBusiness(
    'ListSplitBusiness',
    defaultParam,
);
export const listWorkDaysSchedule = new ListWorkDaysSchedule(
    'ListWorkDaysSchedule',
    defaultParam,
);
export const listVkoDocumentsUser = new ListVkoDocumentsUser(
    'listVkoDocumentsUser',
    defaultParam,
);
export const listInvoicesDocumentsUser = new ListInvoicesDocumentsUser(
    'listInvoicesDocumentsUser',
    defaultParam,
);
export const listInvoicesDocumentsBundleUser = new ListInvoicesDocumentsUser(
    'listInvoicesDocumentsBundleUser',
    defaultParam,
);
export const listBusinessDeclarationDocumentsUser =
    new ListBusinessDeclarationDocumentsUser(
        'listBusinessDeclarationDocumentsUser',
        defaultParam,
    );
export const settleMonthlyWorkdaysSchedule = new SettleMonthlyWorkdaysSchedule(
    'SettleMonthlyWorkdaysSchedule',
    defaultParam,
);
export const isMonthSettled = new SettledSchedule(
    'IsMonthSettled',
    defaultParam,
);

const notifyEdsnLiveParams = {
    basePath: '/v1',
};
export const notifyEdsnLive = new NotifyEdsnLiveProductoffering(
    'notifyEdsnLive',
    notifyEdsnLiveParams,
);

export const archiveSnapshot = new ArchiveSnapshot(
    'archiveSnapshot',
    defaultParam,
);
export const initOda = new InitOda('initOda', defaultParam);
export const verifyOda = new VerifyOda('initOda', defaultParam);

// TODO: DEPRICATED REDUCERS. Reducers that are currently not in use, or where the functionality is currently not used.
// TODO: depricated
export const getChallengeScoreboard = new GetChallengeScoreboard(
    'GetChallengeScoreboard',
    defaultAuthParam,
);

// TODO: depricated
export const getCompetitionScoreboard = new GetCompetitionScoreboard(
    'GetCompetitionScoreboard',
    defaultAuthParam,
);

// TODO: depricated
export const updateCompetitionAlias = new UpdateCompetitionAlias(
    'UpdateCompetitionAlias',
    defaultAuthParam,
);

// TODO: depricated
export const getCompetitionComparisonStats = new GetCompetitionComparisonStats(
    'GetCompetitionComparisonStats',
    defaultAuthParam,
);

/** @type {Array<import('./resources/helpers/Resource').Resource>} */
const resourcesArray = [
    /** Usages */
    gasUsage,
    electricityUsage,
    productionUsage,
    chargingSessionsUsage,
    /**  Costs */
    elekUsageCost,
    elekProdCost,
    gasUsageCost,
    /** Estimations */
    elekUsageEstimationCost,
    electricityUsageEstimation,
    gasUsageEstimation,
    /** Invoice */
    listInvoices,
    listDocuments,
    downloadDocument,
    /** Recalculation offer */
    acceptRecalculationOffer,
    acceptOfferRecalculation,
    getAuthToken,
    /** Competition */
    getChallengeScoreboard,
    getCompetitionScoreboard,
    updateCompetitionAlias,
    getCompetitionComparisonStats,
    /** Snapshot */
    updateSnapshot,
    requestInvalidOfferRecalculation,
    /** Leads */
    createLead,
    /** Split business */
    listSplitBusiness,
    listWorkDaysSchedule,
    /** list documents */
    listVkoDocumentsUser,
    listInvoicesDocumentsUser,
    listInvoicesDocumentsBundleUser,
    listBusinessDeclarationDocumentsUser,

    settleMonthlyWorkdaysSchedule,
    isMonthSettled,

    showMonthCosts,
    showMonthCostsPredictions,
    costSummary,
    notifyEdsnLive,
    archiveSnapshot,
    /** oda */
    initOda,
    verifyOda,
    offerRecalculation,
];
if (localStorage.getItem('state')) {
    localStorage.removeItem('state');
    console.warn('old state storage object found and removed');
} else {
    // console.warn('old state object not found');
}

const rootReducer = (state, action) => {
    if (action.type === 'USER_LOG_OUT') {
        // Clear auth - presist:root / etc
        localStorage.clear();
        window.location.reload();
    }
    const reducer = reducers(...resourcesArray);
    return reducer(state, action);
};

resourcesSetup({
    apiBaseURL: () => window.API_LINK,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);
export const store = createStore(persistedReducer, enhancers);
window.store = store;

export const persistor = persistStore(store);

// @ts-ignore
sagaMiddleware.run(sagas(...resourcesArray));
