import { currency, currencyExtended } from 'helpers/currency';
import { GetVatFromProductGroup, VATPercentage } from 'helpers/vat';
import { Cost } from 'types/offers';

export const roundNumber = (num: number, decimals: number = 2): number => {
    // @ts-ignore
    const multiplier = 10 ** decimals;
    return Math.round(num * multiplier) / multiplier;
};

/**
 * Convert number to displayable price object
 */
export const NumberToPrice = (
    price: number,
    productGroups: Cost | Cost[] | null,
    roundingDecimals = 2,
    includeVat = true,
): String => {
    if (typeof price === 'string') {
        price = parseFloat(price);
    }
    let _price = price;
    /**
     * Check if the product group exists, this is mandatory for adding vat to the price
     */
    if (productGroups == null && includeVat) {
        console.error(
            'missing the productGroup to calculate the VAT',
            productGroups,
        );
        // @ts-ignore
        productGroups = {
            price: _price,
            vat: VATPercentage,
        };
    }
    /**
     * The include adds the vat to the given price
     */
    if (productGroups && includeVat) {
        let vatPrice = 0;
        // if vat exist use vat from productgroup
        if (!Array.isArray(productGroups)) {
            if (productGroups.vat != null) {
                vatPrice = productGroups.vat;
            } else {
                console.error('VAT is missing in the productGroup ', price);
                vatPrice = roundNumber(price * VATPercentage, roundingDecimals);
            }
        } else {
            // if productgroup is an array get the first vat percentage from the group
            let vat = GetVatFromProductGroup(productGroups);
            if (vat == null) {
                console.error('VAT is missing in the productGroups ', price);
                vat = price * VATPercentage;
            }
            vatPrice = roundNumber(vat, roundingDecimals);
        }

        _price = price + vatPrice;
    }
    // Create currency with specific ammount of rouding decimals
    switch (roundingDecimals) {
        case 2:
            return currency(Number(_price));
        case 5:
            return currencyExtended(Number(_price));
        default:
            console.error('Number to price, given decimal is not set');
            return currency(Number(_price));
    }
};

export const parseVatFloat = (
    cost: Cost,
    roundingDecimals: number = 2,
): { vat: number; err: boolean } => {
    const price = parseFloat(cost.price);
    let err = false;
    // if vat doesnt exist calculate the vat over the calculated amount
    if (cost.vat == null) {
        cost.vat = price * VATPercentage;
        const amount = parseFloat(cost.calculated_amount);
        if (amount) {
            cost.vat *= amount;
        }
        cost.vat = roundNumber(cost.vat, roundingDecimals);
        err = true;
    }
    if (typeof cost.vat === 'string') {
        cost.vat = parseFloat(cost.vat);
    }
    return { vat: cost.vat, err };
};
