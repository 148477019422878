// Import ReduxGTM
import { FINISH_CALCULATOR } from '@actions/calculator';
import { FINISH_ENROLL } from '@actions/enroll';
import { AGREE_OFFER } from '@actions/offer';
import { SET_OFFER } from '@actions/order';
import {
    uiAutomaticIncassoClicked,
    uiCalculatorInteraction,
    uiCustomerFormInteraction,
    uiOrderOverviewInteraction,
    uiPriceDetailsClicked,
    uiProductListInteraction,
} from '@actions/ui';
import { trackEvent } from '@redux-beacon/google-analytics';
import GoogleTagManager from '@redux-beacon/google-tag-manager';
import logger from '@redux-beacon/logger';
import { AnyAction } from 'redux';
import { createMiddleware, EventDefinition } from 'redux-beacon';

// Events
export const USER_INTERACTION = 'user_interaction';

/**
 * Events
 */
const defaultEvent = (eventTitle: string): EventDefinition =>
    trackEvent(
        (act): any => ({
            label: eventTitle,
            category: 'onboarding',
            action: act.type,
        }),
        eventTitle,
    );

/**
 * currencyCode should always be an ISO 4217 code
 */
const addPurchaseToDataLayer = (action: AnyAction): void => {
    window.dataLayer.push({
        event: 'purchase',
        ecommerce: {
            currencyCode: 'EUR',
            purchase: {
                actionField: {
                    id: action.snapshot.id,
                    revenue:
                        action.snapshot.calculated_product_group_verified
                            .monthly_pricing,
                    affiliation: 'Energie',
                },
            },
        },
    });
};

// uiPriceDetailsClickedDataLayer
const uiPriceDetailsClickedDataLayer = (): void => {
    window.dataLayer.push({
        event: USER_INTERACTION,
        type: 'link',
        component: 'kassabon',
        field: 'prijsopbouw',
        action: 'click',
        actionValue: 'valid',
    });
};

// uiAutomaticIncassoClickedDataLayer
const uiAutomaticIncassoClickedDataLayer = (): void => {
    window.dataLayer.push({
        event: USER_INTERACTION,
        type: 'button',
        component: 'incassomachtiging-afgeven',
        field: 'nu-machtigen',
        action: 'click',
        actionValue: 'valid',
    });
};

// Maps Redux actions to gootle tag events when required
const eventsMapper = (action: any) => {
    switch (action.type) {
        case FINISH_CALCULATOR:
            return defaultEvent('onboarding-usages-calculated');
        case SET_OFFER:
            return defaultEvent('onboarding-offer-selected');
        case FINISH_ENROLL:
            return defaultEvent('onboarding-user-enrolled');
        case AGREE_OFFER:
            // add additional purchase data to the dataLayer
            addPurchaseToDataLayer(action);
            return defaultEvent('purchase');
        case uiPriceDetailsClicked.actionTypeMap.save:
            uiPriceDetailsClickedDataLayer();
            return defaultEvent(USER_INTERACTION);
        case uiAutomaticIncassoClicked.actionTypeMap.save:
            uiAutomaticIncassoClickedDataLayer();
            return defaultEvent(USER_INTERACTION);
        case uiCalculatorInteraction.actionTypeMap.save:
        case uiProductListInteraction.actionTypeMap.save:
        case uiCustomerFormInteraction.actionTypeMap.save:
        case uiOrderOverviewInteraction.actionTypeMap.save:
            window.dataLayer.push(action.data);
            return defaultEvent(USER_INTERACTION);
        default:
            return [];
    }
};

export const gtmMiddelware = (): any => {
    const ga = GoogleTagManager();
    let extensions = {};
    if (window.env?.debug) {
        extensions = { logger };
    }
    return createMiddleware(eventsMapper, ga, extensions);
};
