import {
    REQUEST_COLLECTIVES,
    REQUEST_COLLECTIVES_SUCCESS,
    REQUEST_COLLECTIVES_FAILED,
} from '../actions/collective';

const defaultCollectives = { data: [], error: null };

/**
 * State reducer for collectives request
 * @param {Object} state
 * @param {Object} action
 * @return {Object}
 */
export const collectives = (state = { ...defaultCollectives }, action) => {
    switch (action.type) {
        case REQUEST_COLLECTIVES:
            return state;
        case REQUEST_COLLECTIVES_SUCCESS:
            return { ...state, data: action.data, error: null };
        case REQUEST_COLLECTIVES_FAILED:
            return { ...state, error: action.error };
        default:
            return state;
    }
};
