import { provider } from '@weavelab/frontend-connect';
import { PolymerElement, html } from '@polymer/polymer/polymer-element';
import { Router } from '@vaadin/router';
import { store } from '../../../store';

import { signIn } from '../../../actions/user';

import { updateTermsAndConditions } from '../../../actions/app';

import css from './login-prompt.pcss';
import template from './login-prompt.html';

import '@polymer/paper-input/paper-input';
import '@polymer/iron-flex-layout/iron-flex-layout-classes';

import '../../atoms/Popup';
import { hideLoginPrompt } from '../../../actions/loginPrompt';
import { routePaths } from '../../../helpers/routing';

// TODO: validate if login-prompt is still used else delete file and imports
/**
 * Login class
 */
export default class EzLoginPrompt extends PolymerElement {
    /**
     * Gets properties of class
     */
    static get properties() {
        return {
            email: {
                type: String,
            },
            password: {
                type: String,
            },
        };
    }

    /**
     * Gets template of class
     */
    static get template() {
        const cssTemplate = document.createElement('template');
        cssTemplate.innerHTML = provider.styles(css);
        const htmlTemplate = document.createElement('template');
        htmlTemplate.innerHTML = template;
        return html`<style include="iron-flex">
                ${cssTemplate}
            </style>
            ${htmlTemplate}`;
    }

    /**
     * Constructor of reset password
     */
    constructor() {
        super();
        /** @type {string} */
        this.email = '';
    }

    /**
     * Subscribes on user state when element is ready
     */
    ready() {
        super.ready();
        this.$.pass.addEventListener('keypress', (e) => {
            if (e instanceof KeyboardEvent) {
                const key = e.which || e.keyCode;
                if (key === 13) {
                    // 13 is enter
                    this._login();
                }
            }
        });
    }

    /**
     * Login dispatch sigin function to the store
     */
    _login() {
        if (this.email === '' || this.password === '') {
            window.displayMessage(
                'E-mail en wachtwoord moeten worden ingevuld.',
            );
            return;
        }
        const email = this.email.trim();
        const re = new RegExp('^[^@\\s:]+@[^@\\s]+\\.[^@\\s]+$');
        if (!re.test(email)) {
            window.displayMessage('Dit is geen geldig e-mailadres.');
            return;
        }
        store.dispatch(updateTermsAndConditions(true));

        // TODO: use autentication saga to handle this logic.
        // @ts-ignore
        Promise.resolve(store.dispatch(signIn(this.email, this.password))).then(
            (e) => {
                if (e.type === 'RECEIVE_USER') {
                    this.email = '';
                    this.password = '';
                    window.displayMessage('Succesvol ingelogd');
                    store.dispatch(hideLoginPrompt());
                    window.location.reload();
                }
            },
        );
    }

    /**
     * Forgot action
     */
    _forgot = () => {
        store.dispatch(hideLoginPrompt());
        Router.go(routePaths.forgot);
    };
}

window.customElements.define('ez-login-prompt', EzLoginPrompt);
