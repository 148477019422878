import {
    CLEAR_CREATE_COMPANY_QUERY,
    REQUEST_CREATE_COMPANY,
    REQUEST_CREATE_COMPANY_SUCCESS,
    REQUEST_CREATE_COMPANY_FAILED,
    RESET_CREATE_COMPANY,
} from '../actions/company';

const defaultCreateCompanyState = { query: null, data: null, error: null };

/**
 * State reducer for create company request
 * @param {Object} state
 * @param {Object} action
 * @return {Object}
 */
export const createCompany = (
    state = { ...defaultCreateCompanyState },
    action,
) => {
    switch (action.type) {
        case CLEAR_CREATE_COMPANY_QUERY:
            return { ...state, query: null };
        case REQUEST_CREATE_COMPANY:
            return { ...state, query: action.payload, data: null };
        case REQUEST_CREATE_COMPANY_SUCCESS:
            return { ...state, data: action.data, error: null };
        case REQUEST_CREATE_COMPANY_FAILED:
            return { ...state, error: action.error };
        case RESET_CREATE_COMPANY:
            return { ...defaultCreateCompanyState };
        default:
            return state;
    }
};
