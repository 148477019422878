import { css } from 'lit-element';

export default css`
    :host {
        --hv-introduction-padding: 20px 14px;
        --hv-introduction-margin: 0;
        --hv-introduction-background: #f1f1f1;
        --hv-introduction-font-size: 13px;
        --hv-introduction-font-weight: 500;
        display: block;
        width: 100%;
        box-sizing: border-box;
        padding: var(--hv-introduction-padding);
        margin: var(--hv-introduction-margin);
        background: var(--hv-introduction-background);
        font-size: var(--hv-introduction-font-size);
        font-weight: var(--hv-introduction-font-weight);
    }
`;
