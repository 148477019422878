import {
    LitElement,
    TemplateResult,
    customElement,
    html,
    property,
} from 'lit-element';

import style from './style';

@customElement('product-information')
export class ProductInformation extends LitElement {
    @property({ type: String })
    public title: string = '';

    @property({ type: String })
    public description?: string;

    static get styles() {
        return [style];
    }

    public render = (): TemplateResult => {
        return html`
            ${this.title !== '' ? html`<h1>${this.title}</h1>` : null}
            ${this.description ? html`<p>${this.description}</p>` : null}
        `;
    };
}

declare global {
    interface HTMLElementTagNameMap {
        'product-information': ProductInformation;
    }
}
