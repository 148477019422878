import {vendorConfig} from '@async-reducers/vendor';
import '@polymer/iron-flex-layout/iron-flex-layout-classes';
import '@polymer/paper-button/paper-button';
import '@polymer/paper-input/paper-input';
import {timeOut} from '@polymer/polymer/lib/utils/async';
// @ts-ignore
import {Debouncer} from '@polymer/polymer/lib/utils/debounce';
import {html, PolymerElement} from '@polymer/polymer/polymer-element';
import {Router} from '@vaadin/router';
import {provider} from '@weavelab/frontend-connect';
import axios from 'axios';
// @ts-ignore
import watch from 'redux-watch';
import {setStartDate} from '@async-reducers/startDate';
import {uiCalculatorInteraction, uiCustomerFormInteraction,} from '@actions/ui';
import {
    choosePrivateOrBusinessSelected,
    existingCustomerSignIN,
    formFieldBuildingOccupiedChanged,
    formFieldInvoiceaddressChanged,
    formFieldMoveReasonChanged,
    formFieldPaymentMethodChanged,
    formFieldStartdateTypeChanged,
    personalDataConfirmed,
    personalDataDenied,
    personalDataInputChanged,
    uiLoginOrResetPasswordClickedDataLayer,
    uiNewRequestClickedDataLayer,
} from 'gtm-middleware/customerForm';
import {invalidMessage, returnErrorOrEmptyString,} from 'gtm-middleware/helpers/actionValue';
import {startEnroll} from '../../../../actions/enroll';
import {clear as clearOrder, setProposition} from '../../../../actions/order';
import {clearCreateUserQuery, logOut} from '../../../../actions/user';
import enums from '../../../../enums';
import {formatDate, legalNoticePeriod, validDate} from '../../../../globals';
import {convertDateTo, isValidDate} from '../../../../helpers/dates';
import {routePaths} from '../../../../helpers/routing';
// This element is connected to the Redux store.
import {archiveSnapshot, store} from '../../../../store';
import '../../../atoms/RadioButton';
import '../../../atoms/RadioButtons';
import '../../../elements/tooltip/Tooltip';
import '../../../general/ez-check-box/EzCheckbox';
import '../../../molecules/OrderMenu';
import '../../../molecules/Spinner';
import '../general/productgroup-overview-display/productgroup-overview-display';
import '../../../atoms/HvInfoBox';
import template from './customer-form.html';
import css from './customer-form.pcss';
import {focusPaperInputEventIsInvalid} from '../helpers/paperInputEvent';

const themeSettings = provider.settings('customer-form');
const calculationToolSettings = provider.settings('calculation-tool');
const appSettings = provider.settings('app');

/**
 * Default error messages
 */
const firstNameErrorMessage = 'Vul een voornaam in.';
const lastNameErrorMessage = 'Vul een achternaam in.';
const emailErrorMessage = 'Vul een e-mailadres in.';
const emailInvalidErrorMessage = 'Dit is geen geldig e-mailadres.';
const phoneErrorMessage = 'Vul een telefoonnummer in.';
const birthdateErrorMessage = 'Vul een geboortedatum in.';
const birthdateInvalidErrorMessage = 'Vul een geldige geboortedatum in.';
const smartMeterErrorMessage =
    'Je heeft aangegeven geen slimme meter te hebben, maar deze is verplicht voor het geselecteerde energiecontract.';
const startDateErrorMessage = 'De aangegeven startdatum is niet correct.';
const chamberOfCommerceErrorMessage = 'Vul een KvK-nummer in.';
const companyErrorMessage = 'Vul een bedrijfnaam in.';
const invoiceZipcodeErrorMessage = 'Vul een postcode voor het factuuradres in.';
const invoiceHouseNumberErrorMessage =
    'Vul een huisnummer voor het factuuradres in.';
const invoiceErrorMessage = 'Het factuuradres is niet gevonden.';

const phoneRegex = '[0-9]{10,20}';

/**
 * Customer form class
 */
export default class EzCustomerForm extends PolymerElement {
    /** @typedef {import('../../../../actions/offer').Offer} Offer */

    /** @typedef {import('../../../../reducers/order').Order} Order */

    /**
     * Gets properties of class
     */
    static get properties() {
        return {
            user: {
                type: Object,
            },
            smartMeterChoiceType: {
                type: Number,
                value: 0,
            },
            phoneNumber: {
                type: String,
                observer: '_phoneNumberChanged',
            },
            companyVatCode: {
                type: String,
                observer: '_sanitizeCompanyVatCode',
            },
            companyVatCodePrevious: {
                type: String,
                value: '',
            },
            birthDate: {
                type: String,
                observer: '_birthDateChanged',
            },
            startDate: {
                type: String,
                observer: '_startDateChanged',
            },
            email: {
                type: String,
                observer: '_emailValidator',
            },
            invoiceAreaCode: {
                type: String,
                observer: '_updateInvoiceGeocode',
            },
            invoiceGeocode: {
                type: Object,
                value: {},
            },
            invoiceGeocodeQuery: {
                type: String,
            },
            menuSelected: {
                type: Number,
            },
            multipleSnapshots: {
                type: Boolean,
                value: false,
            },
            tooltipStatus: {
                type: Boolean,
                value: false,
            },
            showLoginReferral: {
                type: Boolean,
                value: false,
            },
            companyName: {
                type: String,
                value: '',
            },
            companyNamePrevious: {
                type: String,
                value: '',
            },
            companyChamberCommerceId: {
                type: String,
                value: '',
            },
            companyChamberCommerceIdPrevious: {
                type: String,
                value: '',
            },
            firstName: {
                type: String,
                value: '',
            },
            firstNamePrevious: {
                type: String,
                value: '',
            },
            previousErrorMessage: {
                type: String,
                value: '',
            },
        };
    }

    /**
     * onBeforeEnter is a Vaadin router lifecycle method
     * @param {RouterLocation} location
     * @param {PreventAndRedirectCommands} commands
     * @param {Router} router
     * @return {RedirectResult | undefined }
     */
    onBeforeEnter = (location, commands) => {
        const { order } = store.getState();
        // check if order exist else redirect user to the customer page.
        if (order == null && order.offer != null) {
            return commands.redirect(routePaths.customer);
        }
        return null;
    };

    /**
     * Constructor of the customer form
     */
    constructor() {
        super();
        this.asset = provider.asset;
        // User properties
        /** @type {String} */
        this.middleName = '';
        /** @type {String} */
        this.middleNamePrevious = '';
        /** @type {String} */
        this.lastName = '';
        /** @type {String} */
        this.lastNamePrevious = '';
        /** @type {String} */
        this.email = '';
        /** @type {String} */
        this.emailPrevious = '';
        /** @type {String} */
        this.phoneNumber = '';
        /** @type {String} */
        this.phoneNumberPrevious = '';
        /** @type {String} */
        this.birthDate = '';

        /** @type {String} */
        this.prevBirthDate = '';
        /** @type {String} */
        this.birthDatePrevious = '';

        // Contract specific questions
        /** @type {Number} */
        this.applyTaxCredit = 0;
        /** @type {Number} */
        this.transferType = 0;
        /** @type {Number} */
        this.startDateType = 0;
        /** @type {String} */
        this.startDate = '';
        /** @type {String} */
        this.startDatePrevious = '';
        /** @type {String} */
        this.minStartDate = new Date().toISOString();
        /** @type {Number} */
        this.smartMeterChoiceType = 0;
        /** @type {Boolean} */
        this.multipleSnapshots;
        // Colletive
        /** @type {Array} */
        this.collectives;
        /** @type {Object} */
        this.selectedCollective;

        // Payment
        /** @type {Number} */
        this.paymentMethod = 0;

        // consumerType 0 = non-business and 1 = business
        /** @type {Number} */
        this.consumerType =
            themeSettings && themeSettings.consumerTypeBusiness ? 1 : 0;

        // vendorName (the name or generic string that fits in the sentence)
        /** @type {String} */
        this.vendorName = appSettings?.appTitle ?? 'je nieuwe leverancier';

        /** @type {String} */
        this.companyVatCode;

        // Invoice location
        /** @type {String} */
        this.invoiceHouseNumber = '';
        /** @type {String} */
        this.invoiceHouseNumberPrevious = '';
        /** @type {String} */
        this.invoiceHouseNumberSuffix = '';
        /** @type {String} */
        this.invoiceHouseNumberSuffixPrevious = '';
        /** @type {String} */
        this.invoiceAreaCode = '';
        /** @type {String} */
        this.invoiceAreaCodePrevious = '';
        /** @type {Number} */
        this.invoiceAddressType = 0;

        /** @type {Boolean} */
        this.splitBusinessSelected;

        /** @type {Object} */
        this.user;

        /** @type {Object} */
        this.productgroup;

        /** @type {Object} */
        this.invoiceGeocode;

        /** @type {String} */
        this.invoiceGeocodeQuery;

        /** @type {Number} */
        this.menuSelected =
            themeSettings && !isNaN(themeSettings.menuSelected)
                ? themeSettings.menuSelected
                : 1;

        /** @type {String} */
        this.themeTitle =
            themeSettings && themeSettings.title ? themeSettings.title : '';

        /** @type {String} */
        this.themeVendor =
            themeSettings && themeSettings.vendor
                ? themeSettings.vendor
                : '__VENDOR__';

        /** @type {Boolean} */
        this.splitBusiness = false;

        this.electricityChargingPoleEnabled =
            !!calculationToolSettings?.electricityChargingPoleEnabled;

        this.debounceTime = themeSettings?.debounceTime
            ? themeSettings.debounceTime
            : 900;

        this.geoErrorMesssage = '';

        /** @type {Boolean} */
        this.smartMeterChoiceNoSmartMeterAndDontWantDisabled =
            !!themeSettings?.smartMeterChoiceNoSmartMeterAndDontWantDisabled;

        /** @type {Object} */
        this.offers;

        // select no as default radio button for split-business.
        this.selectedRadioIndexForSplitBusiness = 0;
        this.splitBusinessTrueIndex = 1;
        /** @type {Boolean} */
        this.splitBusinessSelected =
            this.selectedRadioIndexForSplitBusiness ===
            this.splitBusinessTrueIndex;

        /** @type {String} */
        this.loginReferralText = themeSettings?.loginReferralText
            ? themeSettings.loginReferralText
            : 'Log in of vraag uw wachtwoord opnieuw aan om verder te gaan.';

        this.smartMeter = themeSettings?.smartMeter
            ? themeSettings.smartMeter
            : `Wanneer je hieronder kiest voor het bestellen van een 'slimme energiemeter' vragen we dit met prioriteit aan bij de netbeheerder in jouw regio. De oude energiemeters worden binnen dertien weken vervangen. De netbeheerder rekent hier eenmalig zo'n € 72,60 voor. Daarom ontvang je na deze aanvraag van ons een iDEAL-betalingsverzoek om deze kosten te voldoen. Bij twijfel kan je gerust een slimme meter bestellen. In de laatste stap controleren we de landelijke energieregisters. Heb je de slimme meters wel al in huis? Dan vervalt gelijk je bestelling.`;

        this.livingInformation = themeSettings?.livingInformation
            ? themeSettings.livingInformation
            : `Op elk adres met een stroomaansluiting dat dient als verblijfsfunctie wordt er vermindering van energiebelasting toegepast. Voorbeelden van ruimtes waar men verblijft zijn: woningen, bedrijfspanden, horecagelegenheden, bouwkeet of bouwkraan, vakantiehuisjes, kassen/kwekerijen, brughokjes, aula's, evenementzalen of religieuze gebouwen.`;

        this.userSigninDescription = themeSettings?.userSigninDescription
            ? themeSettings.userSigninDescription
            : 'Log in om een adres toe te voegen aan je account';

        this.paymentDescription = themeSettings?.paymentDescription
            ? themeSettings.paymentDescription
            : `Let op: óók als je kiest voor 'Automatische Incasso' dien je onze 1e maandfactuur zelf te betalen via iDEAL of handmatig per overboeking.`;

        this.today = new Date();
        this.earliestDeliveryDate = themeSettings?.earliestDeliveryDate
            ? themeSettings.earliestDeliveryDate
            : new Date('2022', '00', '01');

        this.minimumWaitingDays = 30;
        this.urgentMinimumWaitingDays = 2;
        this.vendorAcceptsUrgentRequests = false;
    }

    /**
     * On element ready
     */
    ready() {
        super.ready();

        /** @type {Object} */
        let state = store.getState();
        /** @type {Object} */
        this.offers = state.offers || {};
        this.setProductGroupFromOffer(this.offers, true);
        const watchOffer = watch(store.getState, 'offers');

        store.subscribe(
            watchOffer(
                /**
                 * @param {Object} offers
                 * @return {void}
                 */
                (offers) => {
                    // must have state, order and offers
                    this.setProductGroupFromOffer(offers, true);
                },
            ),
        );

        this.collectives =
            state.collectives && Array.isArray(state.collectives.data)
                ? state.collectives.data
                : [];
        state = store.getState();
        this.order = state.order || {};
        if (this.order.hasOwnProperty('energysplitter')) {
            this.selectedRadioIndexForSplitBusiness = this.order.energysplitter
                ? this.splitBusinessTrueIndex
                : this.selectedRadioIndexForSplitBusiness;
            this.splitBusinessSelected = this.order.energysplitter
                ? this.order.energysplitter
                : false;
        }
        const watchOrder = watch(store.getState, 'order');
        store.subscribe(
            watchOrder(
                /**
                 * @param {Object} order
                 * @return {void}
                 */
                (order) => {
                    this.collectives =
                        state.collectives &&
                        Array.isArray(state.collectives.data)
                            ? state.collectives.data
                            : [];
                    this.order = { ...order };
                    state = store.getState();
                    if (
                        this.order.collective &&
                        this.collectives.filter(
                            (c) => c && c.id === this.order.collective,
                        ).length > 0
                    ) {
                        this.selectedCollective = this.collectives.find(
                            (c) => c && c.id === this.order.collective,
                        );
                    } else {
                        this.selectedCollective = null;
                    }
                    this.setProductGroupFromOffer(this.offers, false);
                },
            ),
        );

        state = store.getState();
        this.geocode = state.geocode || {};
        const watchGeocode = watch(store.getState, 'geocode');
        store.subscribe(
            watchGeocode(
                /**
                 * @param {Object} geocode
                 * @return {void}
                 */
                (geocode) => {
                    this.geocode = { ...geocode };
                },
            ),
        );

        state = store.getState();
        this.user = state.user || {};
        const watchUser = watch(store.getState, 'user');
        store.subscribe(
            watchUser(
                /**
                 * @param {Object} user
                 * @return {void}
                 */
                (user) => {
                    this.user = { ...user };
                },
            ),
        );

        state = store.getState();
        this.createUser = state.createUser || {};
        const watchCreateUser = watch(store.getState, 'createUser');
        store.subscribe(
            watchCreateUser(
                /**
                 * @param {Object} createUser
                 * @return {void}
                 */
                (createUser) => {
                    this.createUser = { ...createUser };
                },
            ),
        );

        state = store.getState();
        this.createUserLocation = state.createUserLocation || {};
        const watchCreateUserLocation = watch(
            store.getState,
            'createUserLocation',
        );
        store.subscribe(
            watchCreateUserLocation(
                /**
                 * @param {Object} createUserLocation
                 * @return {void}
                 */
                (createUserLocation) => {
                    this.createUserLocation = { ...createUserLocation };
                },
            ),
        );

        state = store.getState();
        this.createInvoiceLocation = state.createInvoiceLocation || {};
        const watchCreateInvoiceLocation = watch(
            store.getState,
            'createInvoiceLocation',
        );
        store.subscribe(
            watchCreateInvoiceLocation(
                /**
                 * @param {Object} createInvoiceLocation
                 * @return {void}
                 */
                (createInvoiceLocation) => {
                    this.createInvoiceLocation = { ...createInvoiceLocation };
                },
            ),
        );

        state = store.getState();
        this.createUserPaymentAccount = state.createUserPaymentAccount || {};
        const watchCreateUserPaymentAccount = watch(
            store.getState,
            'createUserPaymentAccount',
        );
        store.subscribe(
            watchCreateUserPaymentAccount(
                /**
                 * @param {Object} createUserPaymentAccount
                 * @return {void}
                 */
                (createUserPaymentAccount) => {
                    this.createUserPaymentAccount = {
                        ...createUserPaymentAccount,
                    };
                },
            ),
        );

        state = store.getState();
        this.createCompany = state.createCompany || {};
        const watchCreateCompany = watch(store.getState, 'createCompany');
        store.subscribe(
            watchCreateCompany(
                /**
                 * @param {Object} createCompany
                 * @return {void}
                 */
                (createCompany) => {
                    this.createCompany = { ...createCompany };
                },
            ),
        );

        state = store.getState();
        this.enrollStatus = state.enrollStatus || {};
        const watchEnrollStatus = watch(store.getState, 'enrollStatus');
        store.subscribe(
            watchEnrollStatus(
                /**
                 * @param {Object} enrollStatus
                 * @return {void}
                 */
                (enrollStatus) => {
                    this.enrollStatus = { ...enrollStatus };
                },
            ),
        );

        this._snapshotStateChanged(state.jwtSnapshots);
        const watchSnapshots = watch(store.getState, 'jwtSnapshots');
        store.subscribe(
            watchSnapshots(
                /**
                 * @param {Array} jwtSnapshots
                 */
                (jwtSnapshots) => {
                    this._snapshotStateChanged(jwtSnapshots);
                },
            ),
        );

        const subPageWatcher = watch(store.getState, 'app.routing.sub_route');
        store.subscribe(
            subPageWatcher(
                /**
                 * @param {Object} subPage
                 */
                (subPage) => {
                    if (
                        subPage === 'customer-form' &&
                        store.getState()?.order?.redirectType ===
                            'manual-retention'
                    ) {
                        setTimeout(() => {
                            this._checkForAutomaticallyProceed();
                        }, 500);
                    }
                },
            ),
        );
        store.dispatch(vendorConfig.run(window.env.vendorId));
        const vendorConfigWatcher = watch(store.getState, 'vendorConfig');
        store.subscribe(
            vendorConfigWatcher(
                /**
                 * @param {Object} vendorConfig
                 */
                (vendorConfig) => {
                    if (vendorConfig.data) {
                        if (vendorConfig.data.min_start_date) {
                            this.minStartDate =
                                vendorConfig.data.min_start_date;
                        }
                        if (vendorConfig.data.min_waiting_days) {
                            this.minimumWaitingDays =
                                vendorConfig.data.min_waiting_days;
                        }
                        if (vendorConfig.data.min_waiting_days) {
                            this.urgentMinimumWaitingDays =
                                vendorConfig.data.urgent_min_waiting_days;
                            if (this.urgentMinimumWaitingDays >= 0) {
                                this.vendorAcceptsUrgentRequests = true;
                            }
                        }
                    }
                },
            ),
        );
    }

    /**
     * On connectedCallback
     * We validate if the required information has been set.
     */
    connectedCallback() {
        super.connectedCallback();
        const { order } = store.getState();
        // if postalcode, elec or gas is null return to the customer page.
        if (
            order?.postalCode == null ||
            (order.electricityUsageEstimate === 0 && order.electricity) ||
            (order.gasUsageEstimate === 0 && order.gas)
        ) {
            Router.go(routePaths.customer);
        } else if (store.getState().offers == null) {
            // if offers is undefined return to the offers page to fetch te offers.
            Router.go(routePaths.customerProductList);
        }
    }

    disconnectedCallback() {
        // if users email exist the error will be set to null, when the user is leaving this page.
        if (this.createUser.error != null) {
            store.dispatch(clearCreateUserQuery());
        }
        super.disconnectedCallback();
    }

    _goToLoginOrResetPassword = () => {
        store.dispatch(
            uiCustomerFormInteraction.run(
                uiLoginOrResetPasswordClickedDataLayer,
            ),
        );
    };

    /**
     * Data layer events
     * They dispatch a redux event that create google tags for the google tag manger
     */
    // Company input fields
    _companyNameObserver(event) {
        const { inValid, firstElement, isValid } =
            focusPaperInputEventIsInvalid(event, this.companyNamePrevious);
        if (inValid) {
            return;
        }

        this.companyNamePrevious = firstElement.value;
        store.dispatch(
            uiCustomerFormInteraction.run(
                personalDataInputChanged(
                    returnErrorOrEmptyString(
                        isValid,
                        firstElement?.errorMessage,
                    ),
                    '02.form-field-company-name',
                ),
            ),
        );
    }

    _companyChamberCommerceObserver(event) {
        const { inValid, firstElement, isValid } =
            focusPaperInputEventIsInvalid(
                event,
                this.companyChamberCommerceIdPrevious,
            );
        if (inValid) {
            return;
        }

        this.companyChamberCommerceIdPrevious = firstElement.value;
        store.dispatch(
            uiCustomerFormInteraction.run(
                personalDataInputChanged(
                    returnErrorOrEmptyString(
                        isValid,
                        firstElement?.errorMessage,
                    ),
                    '03.form-field-kvk-number',
                ),
            ),
        );
    }

    _changedVATInput(event) {
        const { inValid, firstElement, isValid } =
            focusPaperInputEventIsInvalid(
                event,
                this.companyChamberCommerceIdPrevious,
            );
        if (inValid) {
            return;
        }

        this.companyVatCodePrevious = firstElement.value;
        store.dispatch(
            uiCustomerFormInteraction.run(
                personalDataInputChanged(
                    returnErrorOrEmptyString(
                        isValid,
                        firstElement?.errorMessage,
                    ),
                    '04.form-field-vat',
                ),
            ),
        );
    }

    /**
     *  END company input data layer events
     *  Start customer information data layer events
     */
    _changedInitialsInput(event) {
        const { inValid, firstElement, isValid } =
            focusPaperInputEventIsInvalid(event, this.firstNamePrevious);
        if (inValid) {
            return;
        }

        this.firstNamePrevious = firstElement.value;
        store.dispatch(
            uiCustomerFormInteraction.run(
                personalDataInputChanged(
                    returnErrorOrEmptyString(
                        isValid,
                        firstElement.errorMessage,
                    ),
                    '05.form-field-initials',
                ),
            ),
        );
    }

    _changedSurnamePrefixInput(event) {
        const { inValid, firstElement, isValid } =
            focusPaperInputEventIsInvalid(event, this.middleNamePrevious);
        if (inValid) {
            return;
        }

        this.middleNamePrevious = firstElement.value;
        store.dispatch(
            uiCustomerFormInteraction.run(
                personalDataInputChanged(
                    returnErrorOrEmptyString(
                        isValid,
                        firstElement.errorMessage,
                    ),
                    '06.form-field-surnamePrefix',
                ),
            ),
        );
    }

    _changedSurnameInput(event) {
        const { inValid, firstElement, isValid } =
            focusPaperInputEventIsInvalid(event, this.lastNamePrevious);
        if (inValid) {
            return;
        }

        this.lastNamePrevious = firstElement.value;
        store.dispatch(
            uiCustomerFormInteraction.run(
                personalDataInputChanged(
                    returnErrorOrEmptyString(
                        isValid,
                        firstElement.errorMessage,
                    ),
                    '07.form-field-surname',
                ),
            ),
        );
    }

    _changedEmailInput(event) {
        const { inValid, firstElement, isValid } =
            focusPaperInputEventIsInvalid(event, this.emailPrevious);
        if (firstElement) {
            firstElement.value = firstElement.value.replaceAll(' ', '');
            const { inValid: isInValid } = focusPaperInputEventIsInvalid(
                event,
                this.emailPrevious,
            );
            if (isInValid) {
                return;
            }
        }
        if (inValid) {
            return;
        }

        this.emailPrevious = firstElement.value;
        store.dispatch(
            uiCustomerFormInteraction.run(
                personalDataInputChanged(
                    returnErrorOrEmptyString(
                        isValid,
                        firstElement.errorMessage,
                    ),
                    '08.form-field-email',
                ),
            ),
        );
    }

    _changedPhoneInput(event) {
        const { inValid, firstElement } = focusPaperInputEventIsInvalid(
            event,
            this.phoneNumberPrevious,
        );
        if (inValid) {
            return;
        }
        const isPhoneValid = this._validatorHelper(
            firstElement,
            phoneRegex,
            firstElement.value,
        );

        firstElement.invalid = isPhoneValid;
        this.phoneNumberPrevious = firstElement.value;
        store.dispatch(
            uiCustomerFormInteraction.run(
                personalDataInputChanged(
                    returnErrorOrEmptyString(
                        !isPhoneValid,
                        firstElement.errorMessage,
                    ),
                    '09.form-field-telephone',
                ),
            ),
        );
    }

    _changedBirthdateInput(event) {
        const { inValid, firstElement } = focusPaperInputEventIsInvalid(
            event,
            this.birthDatePrevious,
        );

        if (inValid) {
            return;
        }

        this.birthDatePrevious = firstElement.value.replaceAll(' ', '');
        // this validates if the birth date value is correct or incorrect
        this._birthDateValidator(this.birthDatePrevious);
        // this validates if the birthdate should show an error
        const validBirthDate = this._validateBirthDate(this.birthDatePrevious);

        store.dispatch(
            uiCustomerFormInteraction.run(
                personalDataInputChanged(
                    returnErrorOrEmptyString(
                        validBirthDate === '',
                        validBirthDate,
                    ),
                    '10.form-field-birth-date-field',
                ),
            ),
        );
    }

    _changedStartdateInput(event) {
        const { inValid, firstElement } = focusPaperInputEventIsInvalid(
            event,
            this.startDatePrevious,
        );
        if (inValid) {
            return;
        }

        this.startDatePrevious = firstElement.value;
        this._startDateValidator(firstElement.value);
        const validStartDate = this._validateStartDate(firstElement.value);
        store.dispatch(
            uiCustomerFormInteraction.run(
                personalDataInputChanged(
                    validStartDate === ''
                        ? `valid ${firstElement.value}`
                        : invalidMessage(firstElement.value),
                    '14.form-field-startdate',
                ),
            ),
        );
    }

    _changedInvoicePostalcodeInput(event) {
        const { inValid, firstElement, isValid } =
            focusPaperInputEventIsInvalid(event, this.invoiceAreaCodePrevious);
        if (inValid) {
            return;
        }

        this.invoiceAreaCodePrevious = firstElement.value.replaceAll(' ', '');
        store.dispatch(
            uiCustomerFormInteraction.run(
                personalDataInputChanged(
                    returnErrorOrEmptyString(
                        isValid,
                        firstElement.errorMessage,
                    ),
                    '17.form-field-invoiceaddress-postalcode',
                ),
            ),
        );
    }

    _changedInvoiceHouseNumberInput(event) {
        const { inValid, firstElement, isValid } =
            focusPaperInputEventIsInvalid(
                event,
                this.invoiceHouseNumberPrevious,
            );
        if (inValid) {
            return;
        }

        this.invoiceHouseNumberPrevious = firstElement.value;
        store.dispatch(
            uiCustomerFormInteraction.run(
                personalDataInputChanged(
                    returnErrorOrEmptyString(
                        isValid,
                        firstElement.errorMessage,
                    ),
                    '18.form-field-invoiceaddress-housenumber',
                ),
            ),
        );
    }

    _changedInvoiceHouseNumberAdditionInput(event) {
        const { inValid, firstElement, isValid } =
            focusPaperInputEventIsInvalid(
                event,
                this.invoiceHouseNumberSuffixPrevious,
            );
        if (inValid) {
            return;
        }

        this.invoiceHouseNumberSuffixPrevious = firstElement.value;
        store.dispatch(
            uiCustomerFormInteraction.run(
                personalDataInputChanged(
                    returnErrorOrEmptyString(
                        isValid,
                        firstElement.errorMessage,
                    ),
                    '19.form-field-invoiceaddress-housenumber-addition',
                ),
            ),
        );
    }
    // End data layer events

    /**
     * @param {Object} offers
     * @param {Boolean} redirect
     * @return {void}
     */
    setProductGroupFromOffer(offers, redirect = false) {
        const state = store.getState();
        const tempOffers = new Map(Object.entries(offers));
        const { order } = state;
        // TODO: Future refactor: offers is now depending on the updated lifecycle for (order/offer).
        this.offers = { ...offers };
        // return when offers is empty.
        if ((order && order.offer == null) || tempOffers.size === 0) {
            return;
        }

        // get the offer ID and index from the order offer object.
        const offerID = order.offer ? order.offer.id : order.proposition;
        const offerIndex = order.offer.index;

        // validate if offer ID exist in the map.
        if (tempOffers.has(offerID) && offerIndex !== undefined) {
            const productOfferGroups =
                tempOffers.get(offerID).product_group_offering;
            if (productOfferGroups) {
                this.productgroup =
                    productOfferGroups.configurations[offerIndex];
            } else {
                console.warn('offer is missing productOfferGroups');
            }
        } else if (redirect) {
            // if offer isn't found redirect to product-list and reset proposition in the order object.
            store.dispatch(setProposition(''));
            Router.go(routePaths.customerProductList);
        }
    }

    /**
     * Will check if we need to immediately redirect the user
     * @private
     */
    _checkForAutomaticallyProceed() {
        const state = store.getState();
        if (
            state &&
            state.order &&
            state.order.redirectType &&
            state.order.redirectType !== ''
        ) {
            this._submit();
        }
    }

    /**
     * Called when snapshot state is changed
     * @param {Object} snapshotState snapshot state
     */
    _snapshotStateChanged(snapshotState) {
        if (
            snapshotState &&
            snapshotState.snapshots &&
            snapshotState.snapshots.length > 1
        ) {
            this.multipleSnapshots = true;
        } else {
            this.multipleSnapshots = false;
        }
    }

    _requestNewContract = () => {
        window.store.dispatch(
            uiCustomerFormInteraction.run(uiNewRequestClickedDataLayer()),
        );
        this._requestSignout();
    };

    /**
     * Signout action
     */
    _requestSignout = () => {
        store.dispatch(clearOrder());
        const snapshot = store.getState().jwtSnapshots;
        const selectedSnapshot = snapshot?.selectedSnapshot;
        // if the snapshot is not deliverd, archive the snapshot
        if (
            selectedSnapshot &&
            selectedSnapshot.snapshot_phase < enums.SnapshotPhaseUsageChecked
        ) {
            window.store.dispatch(
                archiveSnapshot.request(
                    {
                        sendEmail: false,
                        snapshotId: selectedSnapshot.id,
                        userId: selectedSnapshot.user_id,
                    },
                    {},
                ),
            );

            return;
        }

        store.dispatch(logOut());
        localStorage.removeItem('auth');
        localStorage.clear();
        Router.go(routePaths.customer);
    };

    /**
     * Validator to validate email
     * @param {String} email
     * @private
     */
    _emailValidator(email) {
        if (email.length === 0) return;

        const el = this.shadowRoot?.getElementById('email');
        // @ts-ignore
        this._dbj = this._debounceHelper(this._dbj, () => {
            this._validatorHelper(el, `.*@.*\\..*`, email);
        });
    }

    /**
     * Changes for phoneNumber
     * @param {String} phoneNumber
     */
    _phoneNumberChanged(phoneNumber) {
        this._phoneNumberValidator(phoneNumber);
        this._sanitizePhoneNumber(phoneNumber);
    }

    /**
     * validator for the phoneNumber
     * @param {String} phoneNumber
     * @private
     */
    _phoneNumberValidator(phoneNumber) {
        if (phoneNumber.length === 0) return;

        const el = this.shadowRoot?.getElementById('phoneNumber');
        // @ts-ignore
        this._dbj = this._debounceHelper(this._dbj, () => {
            this._validatorHelper(el, phoneRegex, phoneNumber);
        });
    }

    /**
     * Sanitizer for phone numbers
     * @param {String} phoneNumber
     */
    _sanitizePhoneNumber(phoneNumber) {
        const formatted = phoneNumber.replace('+', '00');
        if (this.phoneNumber !== formatted) {
            this.phoneNumber = formatted;
        }
    }

    /**
     * Sanitizer for company VAT code
     * @param {String} companyVatCode
     */
    _sanitizeCompanyVatCode(companyVatCode) {
        const formatted = companyVatCode.toUpperCase();
        if (this.companyVatCode !== formatted) {
            this.companyVatCode = formatted;
        }
    }

    /**
     * Changes for birth date
     * @param {String} birthDate
     */
    _birthDateChanged(birthDate) {
        this._birthDateValidator(birthDate);
        const formatted = this._sanitizeDate(birthDate);
        if (formatted !== '') {
            this.birthDate = formatted;
        }
    }

    /**
     * Validator for birth date
     * @param {String} birthDate
     */
    _birthDateValidator(birthDate) {
        if (birthDate.length === 0) return;

        const el = this.shadowRoot?.getElementById('birthDate');
        this._bdj = this._debounceHelper(this._bdj, () => {
            this._validatorHelper(
                el,
                // eslint-disable-next-line no-useless-escape
                `(0?[1-9]|[1-2][0-9]|3[0-1])[\/-](0?[1-9]|1[0-2])[\/-][0-9]{4}`,
                birthDate,
            );
        });
    }

    /**
     * Validator for birth date
     * @param {Object} dbj
     * @param {any} cb
     * @return {Object}
     */
    _debounceHelper(dbj, cb) {
        return Debouncer.debounce(dbj, timeOut.after(this.debounceTime), cb);
    }

    /**
     * Validator helper will help setting the invalid attribute
     * @param {Object} el
     * @param {String} pattern
     * @param {String} val
     * @return {Boolean}
     * @private
     */
    _validatorHelper(el, pattern, val) {
        const regex = RegExp(pattern);
        // @ts-ignore
        if (val && regex.test(val)) {
            el?.removeAttribute('invalid');
            return false;
        }

        el?.setAttribute('invalid', 'true');
        return true;
    }

    /**
     * Sanitizer for birth date
     * @param {String} birthDate
     * @return {String} formatted
     */
    _sanitizeDate(birthDate) {
        const prev = this.prevBirthDate;

        // Delete all string characters
        // eslint-disable-next-line no-useless-escape
        birthDate = birthDate.replace(/[^\d\/-]/g, '');
        // Double delete when the prev date strings length was higher than the new incoming
        if (
            this.prevBirthDate &&
            prev.length > birthDate.length &&
            prev[prev.length - 1] === '/'
        ) {
            // delete char because normal backspace deleted just the /
            birthDate = birthDate.substring(0, birthDate.length - 1);
            // double delete because the existing number is 0
            if (
                birthDate.length > 0 &&
                birthDate[birthDate.length - 1] === '0'
            ) {
                birthDate = birthDate.substring(0, birthDate.length - 1);
            }
        }
        // make sure that the date was joined by the /
        let formatted = birthDate.split('-').join('/').split(' ').join('/');

        // add a 0 if for example date starts 9/
        let arrFormatted = formatted
            .split('/')
            .map((o, i, a) =>
                a.length > i + 1 &&
                i < 2 &&
                o.length < 2 &&
                a[a.length - 1].length === 0
                    ? `0${o}`
                    : o,
            );

        // save the values in separate variables
        let day = arrFormatted[0];
        let month = arrFormatted[1];
        let year = arrFormatted[2];

        // if we  have more than 3 all others are deleted
        if (arrFormatted.length > 3) {
            arrFormatted = [day, month, year];
        }

        if (day && day.length === 2 && parseInt(day[0] + day[1], 10) === 0) {
            // we do this to make sure we  don't get 00  as dd
            arrFormatted = [];
        } else if (
            day.length > 1 &&
            parseInt(day[0] + day[1], 10) > 0 &&
            !month
        ) {
            // we do this  so that when entering 121 we'll get 12/1
            day = day[0] + day[1];
            month = day[2];
            arrFormatted = [day, month];
        } else if (
            month &&
            month.length === 2 &&
            parseInt(month[0] + month[1], 10) === 0
        ) {
            // this is for the same reason as the day to avoid 00 as mm
            arrFormatted = [day];
        } else if (
            month &&
            month.length > 1 &&
            parseInt(month[0] + month[1], 10) > 0 &&
            !year
        ) {
            month = month[0] + month[1];
            year = month[2];
            arrFormatted = [day, month, year];
        } else if (
            year &&
            year.length === 4 &&
            parseInt(year[0] + year[1] + year[2] + year[3], 10) === 0
        ) {
            arrFormatted = [day, month];
        } else if (year && year.length > 4) {
            year = year[0] + year[1] + year[2] + year[3];
            arrFormatted = [day, month, year];
        }
        // cap the day to 31
        if (arrFormatted[0] && parseInt(arrFormatted[0]) > 31) {
            arrFormatted[0] = '31';
        }
        // cap the month  to 12
        if (arrFormatted[1] && parseInt(arrFormatted[1]) > 12) {
            arrFormatted[1] = '12';
        }
        formatted = arrFormatted.join('/');
        if (birthDate !== formatted) {
            return formatted;
        }
        this.prevBirthDate = formatted;
        return '';
    }

    /**
     * Validator for birth date (if is in the past)
     * @param {String} birthDate
     * @return {String}
     */
    _validateBirthDate(birthDate) {
        if (/[0-9]{2}\/[0-9]{2}\/[0-9]{4}/.test(birthDate)) {
            const date = this._newDateFromFieldDate(birthDate);
            return date < new Date(Date.now() - 1000 * 60 * 60 * 24 * 365 * 18)
                ? ''
                : 'Je moet 18 jaar of ouder zijn om een energiecontract af te sluiten.';
        }
        return '';
    }

    /**
     * Validator for start date
     * @param {String} startDate
     */
    _startDateValidator(startDate) {
        if (startDate.length === 0) return;

        const el = this.shadowRoot?.getElementById('startDate');
        this._bdj = this._debounceHelper(this._bdj, () => {
            this._validatorHelper(
                el,
                // eslint-disable-next-line no-useless-escape
                `(0?[1-9]|[1-2][0-9]|3[0-1])[\/-](0?[1-9]|1[0-2])[\/-][0-9]{4}`,
                startDate,
            );
        });
    }

    /**
     * Changes for start date
     * @param {String} startDate
     */
    _startDateChanged(startDate) {
        this._startDateValidator(startDate);
        const formatted = this._sanitizeDate(startDate);
        if (formatted !== '') {
            this.startDate = formatted;
        }
    }

    /**
     * format the earliest date to human readable string
     * @param {Date} minimumDate
     * @param {Object} productgroup
     * @return {String}
     */
    formatEarliestDate(minimumDate, productgroup, minimumWaitingDays) {
        if (!minimumWaitingDays) {
            console.warn('min waiting days not set');
            return '';
        }
        if (!isValidDate(minimumDate)) {
            console.warn('is not instance of date');
            return '';
        }

        const minDate = this.getMinimumStartDate(minimumDate, productgroup);
        const convertDate = convertDateTo(minDate);
        window.store.dispatch(setStartDate.run(convertDate));

        return convertDate;
    }

    /**
     * add minimum waiting days to the given date
     * @param {Date} dt
     * @return {Date}
     */
    addMinimumWaitingDays(dt, forUrgency) {
        let daysToAdd = this.minimumWaitingDays;
        if (forUrgency) {
            daysToAdd = this.urgentMinimumWaitingDays;
        }
        return new Date(
            new Date(dt.setDate(dt.getDate() + daysToAdd)).setHours(0),
        );
    }

    /**
     * get the earliest delivery date
     * @param {Date} d
     * @param {Date} earliestDeliveryDate
     * @return {Date}
     */
    getEarliestDeliveryDate(d, earliestDeliveryDate) {
        if (d.getTime() < earliestDeliveryDate.getTime()) {
            // copy earliest delivery date to prevent heap change
            const earlyDate = new Date(earliestDeliveryDate);
            // remove the min
            return new Date(
                earlyDate.setDate(
                    earlyDate.getDate() - this.minimumWaitingDays,
                ),
            );
        }
        return new Date();
    }

    /**
     * get the minimum start date checked by the given date and selected product group
     * @param {Date} minimumDate
     * @param {Object} productgroup
     * @return {Date}
     */
    getMinimumStartDate(startDate, productgroup, forUrgency) {
        // prevents updating the date when productgroup is most likely null on construction
        if (startDate == null || productgroup == null) {
            return;
        }
        let startD = new Date(startDate);
        // if the duration type isn't monthly check if the deliveryDate is valid
        if (
            this.productgroup &&
            this.productgroup.duration_type !== enums.ProductDurationMonth &&
            this.today.getTime() < this.earliestDeliveryDate.getTime()
        ) {
            // check if the statdate is before the earliest delivery date
            startD = this.getEarliestDeliveryDate(
                startD,
                this.earliestDeliveryDate,
            );
        }

        const newMinimumStartDate = this.addMinimumWaitingDays(
            startD,
            !!forUrgency,
        );
        const absolutMinStartDate = new Date(this.minStartDate);

        if (newMinimumStartDate.getTime() < absolutMinStartDate.getTime()) {
            return absolutMinStartDate;
        }

        return newMinimumStartDate;
    }

    /**
     * Validator for start date (between +2 weeks and +6 months)
     * @param {String} startDate
     * @return {String}
     */
    _validateStartDate(startDate) {
        if (/[0-9]{2}\/[0-9]{2}\/[0-9]{4}/.test(startDate)) {
            const inputDate = this._newDateFromFieldDate(startDate);
            if (this.productgroup == null) {
                console.warn('product group must be available');
                return `Er gaat iets fout bij het selecteren van je contract, neem contact met ons op.`;
            }
            const minDate = this.getMinimumStartDate(
                this.today,
                this.productgroup,
                this.transferType === 1 && this.vendorAcceptsUrgentRequests,
            );
            const maxDate = new Date(
                new Date().setMonth(new Date().getMonth() + 6),
            );

            if (inputDate < minDate) {
                return `De vroegst mogelijke leveringsdatum is ${formatDate(
                    minDate,
                )}`;
            }
            if (inputDate > maxDate) {
                return `De laatst mogelijke leveringsdatum is ${formatDate(
                    maxDate,
                )}`;
            }
        }
        return '';
    }

    /**
     * Convert a field string date to a Date object
     * @param {String} fieldDate
     * @return {Date}
     */
    _newDateFromFieldDate(fieldDate) {
        if (fieldDate == null) {
            console.error('fieldDate is missing');
            return;
        }
        const date = new Date(0);
        const fields = fieldDate.split('/');
        date.setFullYear(parseInt(fields[2], 10));
        date.setMonth(parseInt(fields[1], 10) - 1);
        date.setDate(parseInt(fields[0], 10));

        return date;
    }

    /**
     * Get the current date as a date field string
     * @param {Date} [date]
     * @return {String}
     */
    _date(date = new Date()) {
        return `${date.getDate() < 10 ? '0' : ''}${date.getDate()}/${
            date.getMonth() < 9 ? '0' : ''
        }${date.getMonth() + 1}/${date.getFullYear()}`;
    }

    /**
     * Get invoice address
     * @param {String} invoiceAreaCode
     * @param {Object} invoiceGeocode
     * @return {String}
     */
    _classInvoiceGeocode(invoiceAreaCode, invoiceGeocode) {
        return /[0-9]{4}[A-Z]{2}/.test(invoiceAreaCode) &&
            invoiceGeocode.city &&
            invoiceGeocode.street_name
            ? ' green'
            : '';
    }

    /**
     * Get invoice address
     * @param {String} invoiceAreaCode
     * @param {Object} invoiceGeocode
     * @param {String} invoiceHouseNumber
     * @param {String} invoiceHouseNumberSuffix
     */
    _invoiceGeocode(
        invoiceAreaCode,
        invoiceGeocode,
        invoiceHouseNumber,
        invoiceHouseNumberSuffix,
    ) {
        this._dbj = this._debounceHelper(this._dbj, () => {
            const areacode = invoiceAreaCode.trim();
            const regex = RegExp(`[0-9]{4}[A-Za-z]{2}`);
            if (regex.test(areacode)) {
                if (invoiceGeocode.busy) {
                    this.geoErrorMesssage = '';
                    return;
                }
                if (invoiceGeocode.city && invoiceGeocode.street_name) {
                    this.geoErrorMesssage =
                        `${invoiceGeocode.street_name} ${invoiceHouseNumber}${invoiceHouseNumberSuffix},` +
                        ` ${invoiceGeocode.query} ${invoiceGeocode.city}`;
                    return;
                }
            }
            this.geoErrorMesssage =
                invoiceAreaCode && invoiceAreaCode.length > 0
                    ? 'Voer een geldige postcode in.'
                    : '';
        });
    }

    /**
     * Watcher for invoice area code
     * @param {String} next
     */
    _updateInvoiceGeocode(next) {
        const formatted = next.split(' ').join('').toUpperCase();
        if (this.invoiceAreaCode !== formatted) {
            this.invoiceAreaCode = formatted;
        } else if (
            this.invoiceGeocodeQuery !== next &&
            /[0-9]{4}[A-Z]{2}/.test(next)
        ) {
            this.invoiceGeocodeQuery = next;
            setTimeout(async () => {
                if (this.invoiceGeocodeQuery === next) {
                    this.invoiceGeocode = { busy: true };
                    const response = await axios.get(`/v1/geocode/${next}`, {
                        baseURL: window.API_LINK,
                    });
                    if (
                        response.statusText === 'OK' ||
                        response.status === 200
                    ) {
                        this.invoiceGeocode = { ...response.data, query: next };
                    } else {
                        this.invoiceGeocode = { error: response.status };
                    }
                }
            }, 500);
        }
    }

    /**
     * Return the inverse boolean of the input
     * @param {any} val
     * @return {Boolean}
     */
    _not(val) {
        return !val;
    }

    /**
     * Setter for applyTaxCredit
     * @param {Object} e
     */
    _setApplyTaxCredit(e) {
        const prev = e.detail.selected;
        this.applyTaxCredit = e.detail.selected;

        if (prev !== e.detail.select) {
            store.dispatch(
                uiCalculatorInteraction.run(
                    formFieldBuildingOccupiedChanged(
                        this.applyTaxCredit ? 'not-occupied' : 'occupied',
                    ),
                ),
            );
        }
    }

    /**
     * Setter for company
     * @param {Object} e
     */
    _setconsumerType(e) {
        const prev = this.consumerType;
        this.consumerType = e.detail.selected;

        if (prev !== e.detail.selected) {
            const privateOrBusiness =
                enums.ConsumerTypeConsumer === this.consumerType + 1
                    ? 'private'
                    : 'business';
            store.dispatch(
                uiCalculatorInteraction.run(
                    choosePrivateOrBusinessSelected(privateOrBusiness),
                ),
            );
        }
    }

    /**
     * Setter for invoiceToAddress
     * @param {Object} e
     */
    _setInvoiceAddressType(e) {
        const prev = this.invoiceAddressType;
        this.invoiceAddressType = e.detail.selected;

        if (prev !== e.detail.selected) {
            store.dispatch(
                uiCalculatorInteraction.run(
                    formFieldInvoiceaddressChanged(
                        this.invoiceAddressType === 0
                            ? 'same-as-delivery'
                            : 'different-adress',
                    ),
                ),
            );
        }
    }

    /**
     * Setter for transferType
     * @param {Object} e
     */
    _settransferType(e) {
        const prev = this.transferType;
        this.transferType = e.detail.selected;

        if (prev !== e.detail.selected) {
            store.dispatch(
                uiCalculatorInteraction.run(
                    formFieldMoveReasonChanged(
                        this.transferType === 0
                            ? 'change-provider'
                            : 'new-address',
                    ),
                ),
            );
        }
        // we want to reset the startdate when changing transfer type.
        // this is because changing transfer types does not trigger a re-render
        // of the urgent info box, unless we trigger a field which is used by that box
        // which is the startdate in this case
        this._checkIfStartDateUrgent(this.startDate);
    }

    /**
     * Setter for paymentMethod
     * @param {Object} e
     */
    _setPaymentMethod(e) {
        const prev = this.paymentMethod;
        this.paymentMethod = e.detail.selected;

        if (prev !== e.detail.selected) {
            store.dispatch(
                uiCalculatorInteraction.run(
                    formFieldPaymentMethodChanged(
                        this.transferType === 0
                            ? 'automatic-collection'
                            : 'manual',
                    ),
                ),
            );
        }
    }

    /**
     * Setter for splitBusinessSelected
     * @param {Object} e
     */
    _setSplitBusiness(e) {
        // The first radio button is 0;
        this.splitBusinessSelected =
            e.detail.selected === this.splitBusinessTrueIndex;
    }

    /**
     * Setter for smartMeter
     * @param {Object} e
     */
    _setSmartMeter(e) {
        this.smartMeterChoiceType = e.detail.selected;
    }

    /**
     * Setter for startOnDate
     * @param {Object} e
     */
    _setStartOnDate(e) {
        const prev = this.startDateType;
        this.startDateType = e.detail.selected;
        const firstElement = e.composedPath()[0];

        if (prev !== e.detail.selected) {
            store.dispatch(
                uiCalculatorInteraction.run(
                    formFieldStartdateTypeChanged(
                        this.startDateType === 0
                            ? `as-soon-as-possible ${firstElement.asap}`
                            : 'pick-date',
                    ),
                ),
            );
        }
    }

    /**
     * Check if user has ordered smart meter
     * @param {Number} type type of smart meter choice
     * @return {Boolean} indiciation if user has order smart meter
     */
    _hasOrderedSmartMeter(type) {
        type += 1;
        return (
            type ===
            enums.SmartMeterChoiceTypeIDoNotHaveASmartmeterAndWantToOrderOne
        );
    }

    /**
     * Helper function for a dom-if
     * @param {Boolean} invalidated
     * @param {String} postalCode
     * @return {Boolean}
     */
    _showGeocodeResponse(invalidated, postalCode) {
        return !!postalCode && !invalidated;
    }

    _dispatchSubmitError(errorMessage) {
        if (errorMessage === this.previousErrorMessage) {
            return;
        }
        this.previousErrorMessage = errorMessage;
        store.dispatch(
            uiCalculatorInteraction.run(
                personalDataConfirmed(invalidMessage(errorMessage)),
            ),
        );
    }

    /**
     * Submit
     */
    _submit() {
        const inputVerified = this._verifyInput();
        if (!inputVerified) {
            return;
        }
        let userPayload = null;
        if (!this.user || !this.user.id) {
            userPayload = this._buildUserPayload();
        } else if (this.invoiceAddressType === 1) {
            // if invoice address was changed we need to update the snapshot invoice_location_id
            // check my account call which can be used to update invoice locations
            // 1 create a new location (id)
            // 2 update the snapshots invoice_location_id

            const snapshotUpdatePayload = {};
            snapshotUpdatePayload.area_code = this.invoiceAreaCode;
            snapshotUpdatePayload.house_number = parseInt(
                this.invoiceHouseNumber,
                10,
            );
            snapshotUpdatePayload.house_addition =
                this.invoiceHouseNumberSuffix;
        }
        const invoiceLocationPayload = this._buildInvoiceLocationPayload();
        const deliveryLocationPayload = this._buildDeliveryLocationPayload();
        const companyPayload = this._buildCompanyPayload();
        const paymentAccountPayload = this._buildPaymentAccountPayload();
        const snapshotPayload = this._buildSnapshotPayload();

        store.dispatch(
            startEnroll(
                userPayload,
                deliveryLocationPayload,
                invoiceLocationPayload,
                companyPayload,
                paymentAccountPayload,
                snapshotPayload,
            ),
        );

        store.dispatch(
            uiCalculatorInteraction.run(personalDataConfirmed('valid')),
        );
    }

    /**
     * Build company payload based on form
     * @return {Object|null} company payload
     */
    _buildCompanyPayload() {
        if (this.consumerType === 0) {
            return null;
        }
        const companyPayload = {
            name: this.companyName,
            kvk_number: this.companyChamberCommerceId,
            btw_number: this.companyVatCode,
        };
        return companyPayload;
    }

    /**
     * @typedef SnapshotPayload
     * @property {string} product_group_id
     * @property {boolean} has_tax_credit_right
     *
     * @property {number} config_id
     *
     * @property {string} [company_id]
     * @property {string} invoice_location_id
     * @property {string} delivery_location_id
     * @property {string} [payment_account_id]
     * @property {string} [payment_account_id]
     *
     * @property {Number} consumer_type
     * @property {Number} transfer_type
     * @property {Number} payment_method
     * @property {Number} smart_meter_choice
     *
     * @property {Number} high_low_distribution
     *
     * @property {Array} product_ids
     * @property {String} collective_id
     *
     * @property {Number} elec_usage_amount
     * @property {Number} gas_usage_amount
     * @property {Number} elec_prod_amount
     * @property {Number} gas_transmission_type
     * @property {Number} elec_transmission_type
     * @property {boolean} has_gas
     * @property {boolean} has_elec
     * @property {string} [start_date]
     * @property {string} vendor_id
     * @property {Number} charging_pole_usage_amount
     */
    /**
     * Build snapshot payload based on form
     * @return {SnapshotPayload} user payload
     */
    _buildSnapshotPayload() {
        /** @type SnapshotPayload */
        const snapshotPayload = {};
        snapshotPayload.product_group_id = this.productgroup.id;
        snapshotPayload.has_tax_credit_right = this.applyTaxCredit === 0;

        snapshotPayload.config_id = this.productgroup.config_id;

        snapshotPayload.elec_usage_amount = this.order.electricityUsageEstimate;
        snapshotPayload.gas_usage_amount = this.order.gasUsageEstimate;
        snapshotPayload.elec_prod_amount =
            this.order.electricityProductionEstimate;
        snapshotPayload.gas_transmission_type = this.order.transmissionTypeGas;
        snapshotPayload.elec_transmission_type =
            this.order.transmissionTypeElec;
        snapshotPayload.has_gas = this.order.gas;
        snapshotPayload.has_elec = this.order.electricity;
        snapshotPayload.collective_id = this.order.collective;
        if (this.consumerType === 0) {
            snapshotPayload.consumer_type = enums.ConsumerTypeConsumer;
        } else {
            snapshotPayload.consumer_type = enums.ConsumerTypeCompany;
        }
        if (this.transferType === 0) {
            snapshotPayload.transfer_type = enums.ContractTransferTypeSwitch;
        } else {
            snapshotPayload.transfer_type = enums.ContractTransferTypeRehousing;
        }
        if (this.electricityChargingPoleEnabled) {
            snapshotPayload.charging_pole_usage_amount =
                this.order.chargePoleUsage;
        }
        if (this.paymentMethod === 0) {
            snapshotPayload.payment_method =
                enums.PaymentMethodWithPaymentAccount;
        } else {
            snapshotPayload.payment_method = enums.PaymentMethodIdealLink;
        }
        snapshotPayload.smart_meter_choice = this.smartMeterChoiceType + 1;

        snapshotPayload.high_low_distribution = Number(
            this.order.electricityUsageFactorEstimate,
        );

        const productIDs = [];
        for (const prod of this.productgroup.products) {
            productIDs.push(prod.id);
        }
        snapshotPayload.product_ids = productIDs;
        if (this.startDateType === 1 || this._isNewHome(this.transferType)) {
            snapshotPayload.start_date = this._newDateFromFieldDate(
                this.startDate,
            ).toISOString();

            const convertDate = convertDateTo(snapshotPayload.start_date);
            window.store.dispatch(setStartDate.run(convertDate));
        }
        snapshotPayload.vendor_id = window.VENDOR;
        // adds splitbussines to the snapshot payload if exist
        if (this.splitBusiness) {
            snapshotPayload.split_business = this.splitBusinessSelected;
        }
        return snapshotPayload;
    }

    _checkIfStartDateUrgent(startDate) {
        this.urgent = false;
        if (
            this._isNewHome(this.transferType) &&
            this.vendorAcceptsUrgentRequests
        ) {
            const urgentMinimumStartDate = this.getMinimumStartDate(
                this.today,
                this.productgroup,
                true,
            );
            const legalNoticePeriodFromNowInDays = new Date(
                new Date().setDate(new Date().getDate() + legalNoticePeriod),
            ).setHours(0);
            const legalNoticePeriodDate = new Date(
                legalNoticePeriodFromNowInDays,
            );
            if (startDate) {
                const inputDate = this._newDateFromFieldDate(startDate);
                if (
                    inputDate >= urgentMinimumStartDate &&
                    inputDate < legalNoticePeriodDate
                ) {
                    this.urgent = true;
                }
            }
        }
    }

    /**
     * Build payment account payload based on form
     * @return {Object|null} payment account payload
     */
    _buildPaymentAccountPayload() {
        if (this.paymentMethod === 1) {
            return null;
        }
        return {
            metadata: JSON.stringify({
                iban: '',
            }),
            type: enums.PaymentAccountTypeSEPADirectDebit,
        };
    }

    /**
     * Build delivery location payload based on form
     * @return {Object|null} delivery location payload
     */
    _buildDeliveryLocationPayload() {
        const deliveryLocationPayload = {};
        deliveryLocationPayload.area_code = this.order.postalCode;
        deliveryLocationPayload.house_number = this.order.houseNumber;
        deliveryLocationPayload.house_addition =
            this.order.houseNumberSuffix != null &&
            this.order.houseNumberSuffix !== ''
                ? this.order.houseNumberSuffix
                : '';
        return deliveryLocationPayload;
    }

    /**
     * @typedef UserPayload
     * @property {string} first_name
     * @property {string} [middle_name]
     * @property {string} family_name
     * @property {string} email
     * @property {string} phone_number
     * @property {string} birth_date
     * @property {string} area_code
     * @property {number} house_number
     * @property {string} house_addition
     */

    /**
     * Build user payload based on form
     * @return {Object} user payload
     */
    _buildUserPayload() {
        /** @type {UserPayload} */
        const userPayload = {};
        userPayload.first_name = this.firstName.trim();
        userPayload.middle_name = this.middleName.trim();
        userPayload.family_name = this.lastName.trim();
        userPayload.email = this.email.trim();
        userPayload.phone_number = this.phoneNumber.replaceAll(' ', '');
        userPayload.birth_date = this._newDateFromFieldDate(
            this.birthDate,
        ).toISOString();
        if (this.invoiceAddressType === 1) {
            userPayload.area_code = this.invoiceAreaCode;
            userPayload.house_number = parseInt(this.invoiceHouseNumber, 10);
            userPayload.house_addition = this.invoiceHouseNumberSuffix;
        } else {
            userPayload.area_code = this.order.postalCode.replaceAll(' ', '');
            userPayload.house_number = this.order.houseNumber;
            if (this.order.houseNumberSuffix) {
                userPayload.house_addition =
                    this.order.houseNumberSuffix.trim();
            }
        }
        return userPayload;
    }

    /**
     * Build invoice location based on form
     * @return {Object} invoice location payload
     */
    _buildInvoiceLocationPayload() {
        const invoiceLocationPayload = {};
        invoiceLocationPayload.area_code = this.invoiceAreaCode.replaceAll(
            ' ',
            '',
        );
        invoiceLocationPayload.house_number = parseInt(
            this.invoiceHouseNumber,
            10,
        );
        invoiceLocationPayload.house_addition =
            this.invoiceHouseNumberSuffix.trim();
        return invoiceLocationPayload;
    }

    /**
     * Verifies input form
     * @return {Boolean} boolean indicating if input form is correct
     */
    _verifyInput() {
        if (!this.user?.id) {
            const userInput = this._verifyUserInput();
            if (!userInput) {
                return false;
            }
        }
        const contractInput = this._verifyContractInput();
        if (!contractInput) {
            return false;
        }
        const companyInput = this._verifyCompanyInput();
        if (!companyInput) {
            return false;
        }

        return this._verifyInvoiceLocationInput();
    }

    /**
     * Verifies the company input
     * @return {Boolean} boolean indicating if company input is correct
     */
    _verifyInvoiceLocationInput() {
        if (this.invoiceAddressType === 0) {
            return true;
        }
        if (this.invoiceAreaCode === '') {
            window.displayMessage(invoiceZipcodeErrorMessage);
            this._dispatchSubmitError(invoiceZipcodeErrorMessage);
            return false;
        }
        if (this.invoiceHouseNumber === '') {
            window.displayMessage(invoiceHouseNumberErrorMessage);
            this._dispatchSubmitError(invoiceHouseNumberErrorMessage);
            return false;
        }
        if (!this.invoiceGeocode.city || !this.invoiceGeocode.street_name) {
            window.displayMessage(invoiceErrorMessage);
            this._dispatchSubmitError(invoiceErrorMessage);
            return false;
        }
        return true;
    }

    /**
     * Verifies the copmany input
     * @return {Boolean} boolean indicating if company input is correct
     */
    _verifyCompanyInput() {
        if (this.consumerType === 0) {
            return true;
        }
        if (this.companyChamberCommerceId === '') {
            window.displayMessage(chamberOfCommerceErrorMessage);
            this._dispatchSubmitError(chamberOfCommerceErrorMessage);
            return false;
        }
        if (this.companyName === '') {
            window.displayMessage(companyErrorMessage);
            this._dispatchSubmitError(companyErrorMessage);
            return false;
        }
        return true;
    }

    /**
     * Verifies the contract input
     * @return {Boolean} boolean indicating if contract input is correct
     */
    _verifyContractInput() {
        if (
            this.smartMeterChoiceType + 1 ===
                enums.SmartMeterChoiceTypeIDoNotHaveASmartmeterAndDoNotWantOne &&
            this.productgroup.smart_meter_required
        ) {
            window.displayMessage(smartMeterErrorMessage);
            this._dispatchSubmitError(smartMeterErrorMessage);
            return false;
        }
        if (
            this.startDateType === 1 &&
            !validDate(this._newDateFromFieldDate(this.startDate))
        ) {
            window.displayMessage(startDateErrorMessage);
            this._dispatchSubmitError(startDateErrorMessage);
            return false;
        }
        if (
            this.startDateType === 1 &&
            validDate(this._newDateFromFieldDate(this.startDate))
        ) {
            const msg = this._validateStartDate(this.startDate);
            if (msg) {
                window.displayMessage(msg);
                this._dispatchSubmitError(msg);
                return false;
            }
        }
        return true;
    }

    /**
     * Verifies the user input
     * @return {Boolean} boolean indicating if user input is correct
     */
    _verifyUserInput() {
        if (this.firstName === '') {
            window.displayMessage(firstNameErrorMessage);
            this._dispatchSubmitError(firstNameErrorMessage);
            return false;
        }
        if (this.lastName === '') {
            window.displayMessage(lastNameErrorMessage);
            this._dispatchSubmitError(lastNameErrorMessage);
            return false;
        }
        if (this.email === '') {
            window.displayMessage(emailErrorMessage);
            this._dispatchSubmitError(emailErrorMessage);
            return false;
        }
        const email = this.email.trim();
        const re = /^[^@\s:]+@[^@\s]+\.[^@\s]+$/;
        if (!re.test(email)) {
            window.displayMessage(emailInvalidErrorMessage);
            this._dispatchSubmitError(emailInvalidErrorMessage);
            return false;
        }
        if (this.phoneNumber === '') {
            window.displayMessage(phoneErrorMessage);
            this._dispatchSubmitError(phoneErrorMessage);
            return false;
        }
        if (this.birthDate === '') {
            window.displayMessage(birthdateErrorMessage);
            this._dispatchSubmitError(birthdateErrorMessage);
            return false;
        }
        if (!validDate(this._newDateFromFieldDate(this.birthDate))) {
            window.displayMessage(birthdateInvalidErrorMessage);
            this._dispatchSubmitError(birthdateInvalidErrorMessage);
            return false;
        }
        return true;
    }

    /**
     * Check a reducer's data and return whether we should allow user input
     * @param {Object} reducerData
     * @return {Boolean}
     */
    _showForm(reducerData) {
        return this._isFetchError(reducerData) || !reducerData.data;
    }

    /**
     * Check a reducer's data and return whether we have an error
     * @param {Object} reducerData
     * @return {Boolean}
     */
    _isFetchError(reducerData) {
        return reducerData && !!reducerData.error;
    }

    /**
     * Check a reducer's data and return whether we have an error and dispatch the error to the datalayer
     * @param {Object} reducerData
     * @return {Boolean}
     */
    _isFetchErrorWithDatalayer(reducerData) {
        const reducerError = reducerData && !!reducerData.error;
        if (!reducerError || this.previousReducerError === reducerData.error) {
            return reducerError;
        }
        this.previousReducerError = reducerData.error;
        const errorMessage = this._errorDetail(reducerData);
        if (errorMessage != null) {
            store.dispatch(
                uiCalculatorInteraction.run(
                    personalDataDenied(invalidMessage(errorMessage)),
                ),
            );
        }

        return reducerError;
    }

    /**
     * Check if the request is for a new home
     * @param {Boolean} transferType
     * @return {Boolean}
     */
    _isNewHome(transferType) {
        return transferType === 1;
    }

    /**
     * Class setter for when there is an error
     * @param {Object} reducerData
     * @return {String}
     */
    _classError(reducerData) {
        return this._isFetchError(reducerData) ? ' error' : '';
    }

    /**
     * Check if there is an error in the reducerDatas
     * @param  {...Object} items
     * @return {Boolean}
     */
    _isAnyErrors(...items) {
        return !!items.map((o) => this._isFetchError(o)).find((o) => o);
    }

    /**
     * Get the submit button text from the theme settings
     * @return {String}
     */
    _submitButtonText() {
        // @ts-ignore
        return themeSettings?.submitButtonText || 'Volgende stap';
    }

    /**
     * Get the error in human readable format
     * @param {Object} reducerData
     * @return {String}
     */
    _errorDetail(reducerData) {
        this.showLoginReferral = false;

        if (reducerData?.error) {
            const err = reducerData.error;
            if (err?.response) {
                const data = err.response?.data;
                if (data?.detail.includes('@')) {
                    let email = data.detail;
                    email = email.replace('{', '');
                    email = email.replace('}', '');
                    this.showLoginReferral = true;

                    return `Een account met het volgende e-mailadres ${email} bestaat al.`;
                }
                if (data?.detail) {
                    return typeof data.detail === 'string' ? data.detail : '';
                }
                const { statusText } = err.response;

                return typeof statusText === 'string' ? statusText : '';
            }
            return;
        }

        console.warn(`Should not end here, user error isn't handled`);
        return 'Fout';
    }

    /**
     * Menu position variant selector
     * @param {String|Number} number
     * @return {Boolean}
     */
    _isMenuPositionVariant(number) {
        return (
            !isNaN(Number(number)) &&
            // @ts-ignore
            ((themeSettings &&
                themeSettings.menuPositionVariant ===
                    parseInt(String(number), 10)) ||
                (!themeSettings && parseInt(String(number), 10) === 1))
        );
    }

    /**
     * Gets template of class
     */
    static get template() {
        const cssTemplate = document.createElement('template');
        cssTemplate.innerHTML = provider.styles(css);
        const htmlTemplate = document.createElement('template');
        htmlTemplate.innerHTML = template;
        return html`<style include="iron-flex">
                ${cssTemplate}
            </style>
            ${htmlTemplate}`;
    }

    /**
     * Tooltip logic to set visibility
     */
    _openTooltip() {
        this.tooltipStatus = !this.tooltipStatus;
        const tooltipText =
            this.shadowRoot.querySelector('.tooltip__text').classList;
        if (tooltipText) {
            this.tooltipStatus
                ? tooltipText.add('visibility')
                : tooltipText.remove('visibility');
        }
    }

    // if the user wants to sign in go to the lander page.
    signInExistingCustomer = () => {
        store.dispatch(uiCalculatorInteraction.run(existingCustomerSignIN()));
        Router.go(routePaths.lander);
    };
}

window.customElements.define('customer-form', EzCustomerForm);
