import enums from 'enums';
import { Snapshot } from 'types/snapshot';
import { ContactType } from '../Contract';
import { ContractBase } from './BaseContract';

export class SnapshotContract extends ContractBase {
    public currentPhase: number = enums.SnapshotPhaseDeliveryStarted;
    public contractType: ContactType = 'UTILITIES';
    constructor(public snapshot: Snapshot) {
        super();
    }
}
