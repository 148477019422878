import '../base/button/ButtonComponent';
import '../modal/ModalComponent';

import {
    LitElement,
    PropertyValues,
    TemplateResult,
    customElement,
    html,
    property,
    query,
} from 'lit-element';

import modalStyle from './modalStyle';
import { provider } from '@weavelab/frontend-connect';

const themeSettings = provider.settings('user-settings-display');

const energySplitOn = 'Aanzetten';
const energySplitOff = 'Uitzetten';

const defaultSplitBusinessText =
    // eslint-disable-next-line max-len
    'Je staat op het punt om de Energiesplitter aan te zetten.';
const defaultSplitBusinessDisableText =
    // eslint-disable-next-line max-len
    'Je staat op het punt om de Energiesplitter uit te zetten.';

let vendorSplitBusinessText: string;
let vendorSplitBusinessDisabledText: string;

@customElement('energysplitter-activation-modal')
export class EnergysplitterActivationModal extends LitElement {
    @property({ type: String })
    public information: string = defaultSplitBusinessText;

    @property({ type: Boolean, reflect: true })
    public energysplitterActive: boolean = false;

    @property({ type: String })
    public primaryButtonText: string = energySplitOn;

    @query('modal-component')
    public modalComponent?: HTMLElementTagNameMap['modal-component'];

    @property({ type: String })
    public splitBusinessInformation: string =
        themeSettings && themeSettings.splitBusinessInformation
            ? themeSettings.splitBusinessInformation
            : defaultSplitBusinessText;

    @property({ type: String })
    public splitBusinessName =
        themeSettings && themeSettings.splitBusinessName
            ? themeSettings.splitBusinessName
            : 'Energiesplitter';

    static get styles() {
        return [modalStyle];
    }

    protected firstUpdated() {
        vendorSplitBusinessText =
            themeSettings && themeSettings.splitBusinessInformation
                ? themeSettings.splitBusinessInformation
                : null;
        vendorSplitBusinessDisabledText =
            themeSettings && themeSettings.splitBusinessDisabledText
                ? themeSettings.splitBusinessDisabledText
                : null;

        this.addEventListener('modal-window-closed', this.close);
        this.addEventListener('modal-overlay-closed', this.close);
    }

    public render = (): TemplateResult => {
        return html`<modal-component
            id="split__business--modal"
            header-title="Werk je thuis? Probeer de ${this.splitBusinessName}"
            max-width="550px"
        >
            <div id="split__business-container">
                <p class="modal-text">${this.information}</p>
                <button-component
                    id="split--add"
                    label=${this.primaryButtonText}
                    theme="primary"
                    @click="${() =>
                        this.createEvent(
                            this.energysplitterActive
                                ? 'energysplitter-turn-off'
                                : 'energysplitter-accept',
                        )}"
                ></button-component>
                <button-component
                    id="split--cancel"
                    label="Annuleren"
                    theme="secondary"
                    @click=${() => this.createEvent('energysplitter-declined')}
                ></button-component>
            </div>
        </modal-component>`;
    };

    protected updated(changedProperties: PropertyValues) {
        if (
            changedProperties.has('energysplitterActive') &&
            this.energysplitterActive != null
        ) {
            this.information = this.energysplitterActive
                ? vendorSplitBusinessDisabledText
                    ? vendorSplitBusinessDisabledText
                    : defaultSplitBusinessDisableText
                : vendorSplitBusinessText
                ? vendorSplitBusinessText
                : defaultSplitBusinessText;
            this.primaryButtonText = this.energysplitterActive
                ? energySplitOff
                : energySplitOn;
        }
    }

    public open = (): void => {
        if (this.modalComponent) {
            this.modalComponent.open();
        }
    };

    public close = (): void => {
        if (this.modalComponent) {
            this.modalComponent.close();
        }
    };

    private readonly createEvent = (eventName: string): void => {
        this.dispatchEvent(
            new CustomEvent(eventName, {
                bubbles: true,
                composed: true,
                detail: { target: this, state: this.energysplitterActive },
            }),
        );

        this.close();
    };
}

declare global {
    interface HTMLElementTagNameMap {
        'energysplitter-activation-modal': EnergysplitterActivationModal;
    }
}
