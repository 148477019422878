export const PREFIX = 'Busy: ';

export const IS_BUSY = `${PREFIX}IS_BUSY`;
/**
 * Set state to busy
 * @param {String} [message]
 * @return {import("redux").AnyAction}
 */
export const isBusy = (message) => ({ type: IS_BUSY, message });

export const NOT_BUSY = `${PREFIX}NOT_BUSY`;
/**
 * Set state to not busy
 * @return {import("redux").AnyAction}
 */
export const notBusy = () => ({ type: NOT_BUSY });
