import { Snapshot } from 'types/snapshot';

/**
 * Find the first snapshot in the array that has a collective id
 */
const checkForCollective = (snapshots: Snapshot[]): Snapshot | undefined =>
    snapshots.find((snapshot) => snapshot.collective_id !== '');

/**
 * Find and return the first collective_id from snapshots
 */
export const checkForSnapshotsWithACollective = (): String | undefined => {
    const { snapshots } = window.store.getState().jwtSnapshots;
    if (snapshots?.length) {
        const snapshotWithCollective = checkForCollective(snapshots);
        if (snapshotWithCollective) {
            return snapshotWithCollective.collective_id;
        }
    }

    return undefined;
};
