export enum PhaseNotificationStyle {
    WARNING = 'WARNING',
    ERROR = 'ERROR',
    NEUTRAL = 'NEUTRAL',
    SUCCESS = 'SUCCESS',
}

export interface PhaseNotification {
    title: string;
    style?: PhaseNotificationStyle;
    status?: string;
    body?: string;
}

export interface PhaseNotifications {
    [phase: number]: PhaseNotification;
}
