import { PolymerElement, html } from '@polymer/polymer/polymer-element';
import { provider } from '@weavelab/frontend-connect';

import watch from 'redux-watch';
import { Router } from '@vaadin/router';
import css from '../styles.pcss';
import settingsEditCss from '../../settings-edit.pcss';
import template from './template.html';
import {
    // eslint-disable-next-line no-unused-vars
    updateUser,
} from '../../../../../../actions/user';
import { store, updateCompetitionAlias } from '../../../../../../store';
import { routePaths } from '../../../../../../helpers/routing';
// import {
//     setError,
// } from '../../../../../../actions/error';
//

/**
 * Login class
 * @namespace SettingsEditCompetitionAlias
 * @class SettingsEdit
 * @extends PolymerElement
 */
export default class SettingsEditCompetitionAlias extends PolymerElement {
    /**
     * Constructor of SettingsEdit
     */
    constructor() {
        super();
        /** @type {Object} */
        this.snapshot = {};
        /** @type {string} */
        this.alias = '';
    }

    /**
     * Gets properties of class
     */
    static get properties() {
        return {
            snapshot: {
                type: Object,
                value: {},
            },
            alias: {
                type: String,
                value: '',
            },
        };
    }

    /**
     * Gets template of class
     */
    static get template() {
        const cssTemplate = document.createElement('template');
        cssTemplate.innerHTML =
            provider.styles(css) + provider.styles(settingsEditCss);
        const htmlTemplate = document.createElement('template');
        htmlTemplate.innerHTML = template;
        return html`<style include="iron-flex">
                ${cssTemplate}
            </style>
            ${htmlTemplate}`;
    }

    /**
     * Goes back to settings overview
     */
    _backButtonPressed = () => {
        Router.go(routePaths.editSettings);
    };

    /**
     * _editAlias
     */
    _editAlias() {
        if (this.alias === '') {
            window.displayMessage('De alias moet ingevuld zijn.');
            return;
        }
        // @ts-ignore
        if (this.snapshot && this.snapshot.competition) {
            try {
                store.dispatch(
                    updateCompetitionAlias.request({
                        vendor_id: this.snapshot.vendor_id,
                        competition_id: this.snapshot.competition.id,
                        snapshot_id: this.snapshot.id,
                        alias: this.alias,
                    }),
                );
                this.set('snapshot.alias', this.alias);
                this.alias = '';
                window.displayMessage('Je alias is aangepast');
                this._goToCompetition();
            } catch (error) {
                console.warn('_updateDisplayName failed', error);
            }
        }
    }

    /**
     * goToCompetition
     */
    _goToCompetition = () => {
        Router.go(routePaths.competition);
    };

    /**
     * Subscribes on competition state when element is ready
     */
    ready() {
        super.ready();
        this.snapshot = store.getState().jwtSnapshots.selectedSnapshot;

        const competitionAliasStateWatcher = watch(
            store.getState,
            'UpdateCompetitionAlias',
        );
        store.subscribe(
            competitionAliasStateWatcher(
                /**
                 * Watching the snapshot state
                 * @param {Object} competitionAliasState
                 */
                (competitionAliasState) => {
                    if (
                        competitionAliasState &&
                        competitionAliasState.data &&
                        competitionAliasState.data.alias
                    ) {
                        this.enableUpdateAlias =
                            competitionAliasState.data.alias === '';
                        if (competitionAliasState.data.alias !== '') {
                            this.displayName = competitionAliasState.data.alias;
                            this.showSuccess = true;
                        }
                    }
                },
            ),
        );
    }
}

window.customElements.define(
    'settings-edit-competition-alias',
    SettingsEditCompetitionAlias,
);
