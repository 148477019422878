import { customElement, property } from 'lit-element';
import { TemplateResult, html } from 'lit-html';
import { ifDefined } from 'lit-html/directives/if-defined';

import { GridLogic } from '../GridLogic';

/**
 * Path is the property used for sorting the data.
 * sort-direction returns in which order the item needs to be sorted
 */
@customElement('grid-column')
export class GridColumn extends GridLogic {
    @property()
    public path: string = '';

    @property()
    public header?: string;

    protected render = (): TemplateResult => {
        return html`
            <header>
                ${ifDefined(this.header)
                    ? html` ${this.header} `
                    : html` <slot name="header"></slot> `}
                &nbsp;
            </header>
            <main>
                <slot class="slotted"></slot>
            </main>
        `;
    };
}

declare global {
    interface HTMLElementTagNameMap {
        'grid-column': GridColumn;
    }
}
