import './layout/GridSorter';
import './layout/GridColumn';
import './layout/GridCheckbox';
import './GridCell';
import './GridDataProvider';
import './GridLogic';

import { customElement, html, property } from 'lit-element';
import { TemplateResult } from 'lit-html';
import { whitelabel } from '@weavelab/whitelabel';

import { GridDataProvider } from './GridDataProvider';
import style from './style/styleGridComponent';

@customElement('grid-component')
@whitelabel({ name: 'grid-component' })
export class GridComponent extends GridDataProvider {
    @property({
        attribute: 'title-plural',
    })
    public titlePlural: string = '';

    @whitelabel() // Allow styles to be injected from theme
    static get styles() {
        return [style];
    }

    protected render = (): TemplateResult => html`
        <div class="grid-component-body">
            <slot @slotchange=${this.handleSlotchangeEvent}></slot>
        </div>
        ${(!this.items || (this.items && this.items.length === 0)) &&
        !this.loading &&
        !this.loadIndicator
            ? html`
                  <div class="empty">
                      Nog geen ${this.titlePlural.toLowerCase()} gevonden...
                  </div>
              `
            : null}
    `;

    private handleSlotchangeEvent(event: Event): void {
        const slot = event.target as HTMLSlotElement;
        if (slot && slot.assignedNodes()) {
            this.gridChildren = slot
                .assignedNodes()
                .filter((element) => element.nodeName.includes('GRID')) as
                | HTMLElementTagNameMap['grid-column'][]
                | HTMLElementTagNameMap['grid-sorter'][];
        }
    }
}

declare global {
    interface HTMLElementTagNameMap {
        'grid-component': GridComponent;
    }
}
