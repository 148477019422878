import { setLoaderPrefix, setLoaderBouncePrfix } from '../actions/loader';

export interface LoaderInterface {
    loading: boolean;
    preventDebounce: boolean;
}

const defaultLoader: LoaderInterface = {
    loading: false,
    preventDebounce: false,
};
/**
 * State reducer for loader
 */
export const loader = (
    state: LoaderInterface = defaultLoader,
    action: any,
): LoaderInterface => {
    switch (action.type) {
        case setLoaderPrefix:
            return {
                loading: action.loading,
                preventDebounce: action.preventDebounce,
            };
        case setLoaderBouncePrfix:
            return {
                ...state,
                preventDebounce: action.loaderDebounce,
            };
        default:
            return state;
    }
};
