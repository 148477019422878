import { Resource } from './helpers/Resource';
import { Bearer } from './helpers/types/Bearer';
import { ISODate } from './helpers/types/ISODate';
import { UUID } from './helpers/types/UUID';

/**
 * Store member management object for usage
 */
export class GetChallengeScoreboard extends Resource {
    /**
     * Action string
     * @return {string}
     */
    static get action() {
        return 'GetChallengeScoreboard';
    }

    /**
     * Description string
     * @return {string}
     */
    static get description() {
        return 'Returns a scoreboard for the challenge active within the given dates';
    }

    /**
     * Request method
     * @return {string}
     */
    static get method() {
        return 'get';
    }

    /**
     * Resource string
     * @return {string}
     */
    static get resource() {
        return 'competition';
    }

    /**
     * Route string
     * @return {string}
     */
    static get route() {
        return 'vendor/{vendor_id}/competition/{competition_id}/challenge_scoreboard';
    }

    /**
     * Parameters object
     * @return {Object}
     */
    static get parameters() {
        return {
            vendor_id: {
                in: 'path',
                type: UUID,
                required: true,
            },
            competition_id: {
                in: 'path',
                type: UUID,
                required: true,
            },
            from: {
                in: 'query',
                type: ISODate,
                required: true,
            },
            page: {
                in: 'query',
                type: ISODate,
                required: true,
            },
            page_size: {
                in: 'query',
                type: ISODate,
                required: true,
            },
            'x-auth': {
                in: 'header',
                required: true,
                type: Bearer,
            },
        };
    }
}
