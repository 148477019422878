import {
    LitElement,
    TemplateResult,
    customElement,
    html,
    property,
} from 'lit-element';
import { whitelabel } from '@weavelab/whitelabel';

import { _defaults } from '../helpers/_defaults';
import { ironFlex } from '../helpers/iron-flex-layout/iron-flex';

@customElement('order-menu')
@whitelabel({ name: 'order-menu' })
export class OrderMenu extends LitElement {
    @whitelabel()
    public static items: string[] = [
        'Persoonlijke gegevens',
        'Gegevens controleren',
        'Bevestiging contract',
    ];

    @property({ type: Number })
    public selected: number = 1;

    @whitelabel()
    static get styles() {
        return [_defaults, ironFlex];
    }

    public render = (): TemplateResult => html`
        <div class="menuItemWrapper layout vertical center-justified">
            <div class="layout horizontal menuItems">
                ${OrderMenu.items.map(
                    (item: any, index: number) => html`
                        <div
                            class="menuItem${this.getMenuClasses(
                                index,
                                this.selected,
                            )}"
                        >
                            <div class="menuItemNumber">${this.inc(index)}</div>
                            <div class="menuItemContent">${item}</div>
                        </div>
                    `,
                )}
            </div>
        </div>
    `;

    /**
     * Increment number by one
     * @param {Number} index
     * @return {Number}
     */
    private inc = (index: number): number => index + 1;

    /**
     * Get classes for menu item
     * @param {Number} current current unit
     * @param {Number} selected selected unit
     * @return {String} list of classes
     */
    private getMenuClasses(current: number, selected: number): string {
        if (!(Number.isNaN(current) || Number.isNaN(selected))) {
            if (
                this.inc(parseInt(String(current), 10)) ===
                parseInt(String(selected), 10)
            ) {
                return ' selected';
            }
            return this.inc(parseInt(String(current), 10)) <
                parseInt(String(selected), 10)
                ? ' passed'
                : '';
        }
        return '';
    }
}

declare global {
    interface HTMLElementTagNameMap {
        'order-menu': OrderMenu;
    }
}
