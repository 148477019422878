import { ResourceDataType } from './ResourceDataType';

/**
 * UUID object
 */
export class UUID extends ResourceDataType {
    /**
     * Constructor to create a UUID object with a newly generated UUID or from a UUID string
     * @param {null|string|UUID} [value] The UUID value as a string or as a UUID object
     * @param {boolean} [nullable] Flag to allow for null (will return 00000000-0000-0000-0000-000000000000 on null)
     */
    constructor(value, nullable) {
        super(nullable);
        /** @type {string?} */
        this._value = null;

        if (value === undefined) {
            this.value = UUID._new();
        } else if (value instanceof UUID) {
            this.value = value.getValue();
        } else {
            this.value = value;
        }
    }

    /**
     * Get UUID value
     * @return {string?}
     */
    get value() {
        return this._value;
    }

    /**
     * Get UUID value
     * @return {string?}
     */
    getValue() {
        return this.value;
    }

    /**
     * Set UUID value
     * @param {string?} value
     */
    set value(value) {
        if (value && UUID.validate(value)) {
            this._value = value;
        } else if (
            (!value || value === '00000000-0000-0000-0000-000000000000') &&
            this._nullable
        ) {
            this._value = null;
        } else {
            if (!this._nullable && !value) {
                console.warn(
                    'To allow for nullable UUIDs use',
                    'new UUID(null, true)',
                );
            }
            throw new Error('Parameter is not a valid UUID');
        }
    }

    /**
     * Set UUID value
     * @param {string?|UUID} value
     */
    setValue(value) {
        this.value = value instanceof UUID ? value.getValue() : value;
    }

    /**
     * Check if the value is set
     * @return {boolean}
     */
    isSet() {
        return !!this._value;
    }

    /**
     * Get UUID as a format string
     * @return {string}
     */
    toFormatString() {
        return `"${this.toString()}"`;
    }

    /**
     * Get UUID as string
     * @return {string}
     */
    toString() {
        return this._value
            ? this._value
            : '00000000-0000-0000-0000-000000000000';
    }

    /**
     * Generate a new UUID string
     * @return {string}
     */
    static _new() {
        // Credit https://gist.github.com/jed/982883
        return '10000000-1000-4000-8000-100000000000'.replace(/[018]/g, (c) =>
            (
                Number(c) ^
                (crypto.getRandomValues(new Uint8Array(1))[0] &
                    (15 >> (Number(c) / 4)))
            ).toString(16),
        );
    }

    /**
     * Validate a UUID string
     * @param {string} value
     * @return {boolean}
     */
    static validate(value) {
        return /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(
            value,
        );
    }
}
