import { css } from 'lit-element';

export default css`
    :host {
        display: flex;
        flex-direction: column;
        -webkit-user-select: none;
        user-select: none;

        --cell-height: 55px;
        --cell-padding: 0 0 0 30px;
        --cell__hover--color: #f6f6f6;
        --cell__active--color: var(--primary-color);
        --cell__active--font-weight: 600;
        --cell__active--text-decoration: underline;
    }
    :host([depth0]) .dropdown-header,
    :host([depth1]) .dropdown-header,
    :host([depth2]) .dropdown-header,
    :host([depth3]) .dropdown-header {
        border-bottom: 1px solid var(--ew-gray-light);
    }
    :host([depth2]) .icon-indicator {
        margin-left: 20px;
    }
    :host([depth3]) .icon-indicator {
        margin-left: 30px;
    }
    :host(:hover) {
        background-color: var(--cell__hover--color);
        text-decoration: var(--cell__active--text-decoration);
    }
    :host([active]) {
        font-weight: var(--cell__active--font-weight);
    }
    :host([active]) .header-title {
        color: var(--cell__active--color);
    }
    :host([active-path]) .dropdown-header .icon-collapse {
        background-color: var(--ew-blue-navy);
    }
    p {
        -webkit-user-select: none;
        user-select: none;
    }

    .dropdown-header {
        display: flex;
        align-items: center;
        flex-direction: row;
        padding: var(--cell-padding);
        position: relative;
        height: var(--cell-height);
    }
    .header-title {
        display: flex;
        flex-grow: 1;
        margin: 0;
        padding: 0;
    }

    div:last-of-type,
    .dropdown-header:last-of-type {
        border-bottom: none;
    }
    .icon-indicator {
        margin-right: 20px;
    }
    .icon-selector {
        width: 20px;
        height: 20px;
        margin-right: 12px;
    }
    .icon-collapse {
        position: absolute;
        right: 0;
        padding: 26px;
    }
`;
