import {
    PropertyValues,
    TemplateResult,
    customElement,
    html,
} from 'lit-element';
import { watch } from 'lit-redux-watch';
import { BusinessDeclartions } from 'types/documents';
import { ListBusinessDeclarationDocumentsUserRequestObject } from '../../../../resources/ListBusinessDeclarationDocumentsUser';
import { listBusinessDeclarationDocumentsUser, store } from '../../../../store';
import { renderTemplatePeriod } from '../../helpers/GridRenders';
import { BaseTab } from '../base-tab/BaseTab';

@customElement('business-declaration-tab')
export class BusinessDeclarationTab extends BaseTab {
    @watch('listBusinessDeclarationDocumentsUser.data')
    private bdInformation?: BusinessDeclartions;

    public render = (): TemplateResult => {
        const { loading } = this;
        return html`
            <style>
                grid-component {
                    --grid__font-size: 14px;
                    min-height: 500px;
                    display: flex;
                    flex-grow: 1;
                }
            </style>
            <grid-component
                title-plural="facturen"
                ?loading="${loading}"
                ?error="${this.error}"
                .items=${this.items}
                .itemsTotal=${this.total}
                id="invoice__grid"
            >
                <grid-column
                    path="document.date"
                    header="Periode"
                    .onRender=${renderTemplatePeriod}
                ></grid-column>
                <grid-column
                    path="download"
                    width="140"
                    .onRender=${this.renderTemplateButton}
                ></grid-column>
            </grid-component>
        `;
    };

    protected updated(changedProperties: PropertyValues) {
        super.updated(changedProperties);
        if (changedProperties.has('bdInformation') && this.bdInformation) {
            this.setInvoiceAndPagiantionData(
                this.bdInformation.business_declarations,
                this.bdInformation.pagination,
            );

            this.total = this.bdInformation.pagination.total;
        }
    }

    public fetchGridData = (
        requestObj: ListBusinessDeclarationDocumentsUserRequestObject,
    ) => {
        const payload = requestObj;
        store.dispatch(listBusinessDeclarationDocumentsUser.request(payload));
    };
}

declare global {
    interface HTMLElementTagNameMap {
        'business-declaration-tab': BusinessDeclarationTab;
    }
}
