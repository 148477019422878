import { css } from 'lit-element';

export default css`
    :host() {
        --grid__even--row--background-color: #eaf2ff;
        --grid__font-size: 14px;
    }
    :host {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        color: var(--ew-grey-50);
        -webkit-user-select: none; /* Safari 3.1+ */
        -moz-user-select: none; /* Firefox 2+ */
        -ms-user-select: none; /* IE 10+ */
        user-select: none; /* Standard syntax */
        animation: fadeIn 300ms;
        -moz-animation: fadeIn 300ms; /* Firefox */
        -webkit-animation: fadeIn 300ms; /* Safari and Chrome */
        -o-animation: fadeIn 300ms; /* Opera */

        font-size: var(--grid__font-size);
    }
    :host(.first-elm) main {
        background: var(--ew-white);
        border-radius: 15px 0 0 15px;
    }
    :host(.last-elm) main {
        background: var(--ew-white);
        border-radius: 0 15px 15px 0;
    }

    :host(.first-elm) main ::slotted(*),
    :host(.first-elm) header {
        padding-left: 20px;
    }

    :host([width]) {
        flex-grow: 0 !important;
    }
    :host([hidden]) {
        display: none;
    }

    header {
        background-color: var(--grid__header--background--color);
        font-weight: var(--grid__font-weight, --grid__header--font-weight);
        color: var(--ew-gray);
        display: flex;
        width: 100%;
        padding-bottom: 23px;
        white-space: nowrap;
    }

    main {
        cursor: default;
        background-color: var(--ew-white);
        color: var(--ew-gray-neutral);
    }

    main ::slotted(grid-cell),
    header {
        padding-top: 17px;
        padding-bottom: 17px;
    }

    main ::slotted(grid-cell) {
        white-space: nowrap;
        border-bottom: 1px solid var(--ew-gray-light);
    }

    main ::slotted(grid-cell:nth-child(odd)) {
        background-color: var(--grid__even--row--background-color);
    }

    :host([compact]) .slotted::slotted(grid-cell) {
        padding: 30px;
        height: auto;
    }

    :host([compact].first-elm) .slotted::slotted(grid-cell:first-of-type) {
        border-radius: 15px 0 0 0;
    }

    :host([compact].first-elm) .slotted::slotted(grid-cell:last-of-type) {
        border-radius: 0 0 0 15px;
    }

    :host([compact].first-elm) .slotted::slotted(grid-cell) {
        border-left: 1px solid var(--ew-gray-light);
    }

    :host([compact].last-elm) .slotted::slotted(grid-cell:first-of-type) {
        border-radius: 0 15px 0 0;
    }

    :host([compact].last-elm) .slotted::slotted(grid-cell:last-of-type) {
        border-radius: 0 0 15px 0;
    }

    :host([compact].last-elm) .slotted::slotted(grid-cell) {
        border-right: 1px solid var(--ew-gray-light);
    }

    :host([compact]) .slotted::slotted(grid-cell:first-of-type) {
        border-top: 1px solid var(--ew-gray-light);
    }
`;
