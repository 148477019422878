import './GridCell';

import {
    LitElement,
    PropertyValues,
    customElement,
    property,
    query,
} from 'lit-element';

import { GridCell } from './GridCell';
import { GridLogic } from './GridLogic';
import {
    GridSorter,
    SortDirections,
    defaultSort,
    ascSort,
} from './layout/GridSorter';
import { CheckboxEvent } from '../base/checkbox-component/helpers/events';

export interface Sorter {
    path: string;
    direction: SortDirections;
    connected: boolean;
}
export type itemType = object & { id: string };

@customElement('grid-data-provider')
export class GridDataProvider extends LitElement {
    /**
     * An array containing the items which will be stamped to the column template
     * instances.
     */
    @property({ type: Array })
    public items: any = [];

    @property({ type: Number })
    public itemsTotal: number = 0;

    @property({ attribute: 'render-new-row-for', type: String })
    public renderNewRowFor?: string;

    @property({ type: Object })
    public sorter: Sorter = {
        path: '',
        direction: 'default',
        connected: false,
    };

    @property()
    private lastPath?: string;

    @property({ type: Array })
    private itemsDefault: any[] = [];

    @property({ type: Array })
    private elementColmuns?: GridLogic[];

    @query('slot')
    private slotElements?: HTMLSlotElement;

    @property({
        type: Boolean,
    })
    public loading: boolean = false;

    @property({
        type: Boolean,
        reflect: true,
    })
    public loadIndicator: boolean = true;

    @property({
        type: Boolean,
    })
    public counter: boolean = false;

    @property({
        type: Boolean,
        reflect: true,
    })
    public border: boolean = false;

    @property({
        type: Boolean,
        reflect: true,
    })
    public compact: boolean = false;

    @property({
        attribute: 'is-collapsed',
        type: Boolean,
        reflect: true,
    })
    public isCollapsed: boolean = false;

    @property({
        type: Array,
    })
    public gridChildren?:
        | HTMLElementTagNameMap['grid-column'][]
        | HTMLElementTagNameMap['grid-sorter'][]
        | HTMLElementTagNameMap['grid-checkbox'][];

    private firstCell?: GridCell;
    private childElements?: GridCell[];

    @property({ type: Boolean })
    public allCheckboxesSelected: boolean = false;

    @property({ type: Object })
    public checkedItems: Map<string, object> = new Map();

    @property({ type: Object })
    public excludedItems: Map<string, object> = new Map();

    @property({ type: Number })
    public checkedItemsTotalSize: number = 0;

    @property({ type: Number })
    public checkedItemsCurrentSize: number = 0;

    @property({ type: Object })
    public gridCheckbox?: HTMLElementTagNameMap['grid-checkbox'];

    private dispatchItemsChecked: boolean = false;

    constructor() {
        super();
        this.addEventListener(
            'sorter-changed',
            this.onSorterChanged as EventListener,
        );
        this.addEventListener(
            'checkbox-changed',
            this.onCheckboxChanged as EventListener,
        );
        this.addEventListener(
            'checkbox-slotted-length',
            ((e: CustomEvent) =>
                (this.checkedItemsTotalSize = e.detail)) as EventListener,
        );
        this.addEventListener(
            'all-checkboxes-changed',
            this.allCheckboxesChanged as EventListener,
        );
    }

    // update grid when items has been changed.
    public async updated(changedProperties: PropertyValues) {
        if (changedProperties.has('loading')) {
            setTimeout(() => {
                this.loadIndicator = this.loading;
            }, 5000);
        }
        if (changedProperties.has('items')) {
            if (this.items) {
                await this.clearCache();
                this._columnTreeChanged();
            } else if (changedProperties.get('items') && this.items === null) {
                // if items is empty clear renderd items.
                this.clearCache();
            }
        }
        if (
            changedProperties.has('gridChildren') ||
            changedProperties.has('compact') ||
            changedProperties.has('border')
        ) {
            if (this.gridChildren && this.gridChildren.length > 0) {
                this.gridChildren.forEach((child: any) =>
                    child.updateProps(this.compact, this.border),
                );
            }
        }

        if (
            changedProperties.has('checkedItemsCurrentSize') &&
            changedProperties.get('checkedItemsCurrentSize') !== undefined &&
            this.checkedItemsTotalSize
        ) {
            dispatchEvent(
                new CustomEvent('checkbox-parent-change', {
                    detail:
                        this.checkedItemsCurrentSize ===
                        this.checkedItemsTotalSize,
                }),
            );
        }

        // when a checkbox is deselected and all checkboxes where selected dispatch to parent to disable all active check.
        if (
            changedProperties.has('allCheckboxesSelected') &&
            changedProperties.get('allCheckboxesSelected') !== undefined &&
            !this.allCheckboxesSelected
        ) {
            dispatchEvent(
                new CustomEvent('checkbox-parent-change', { detail: false }),
            );
        }

        if (
            (changedProperties.has('allCheckboxesSelected') &&
                this.allCheckboxesSelected) ||
            (changedProperties.has('checkedItemsCurrentSize') &&
                this.checkedItemsCurrentSize != null)
        ) {
            if (
                !this.dispatchItemsChecked &&
                (this.allCheckboxesSelected || this.checkedItems.size > 0)
            ) {
                this.dispatchItemsChecked = !this.dispatchItemsChecked;
                this.dispatchCheckboxesActive(this.dispatchItemsChecked);
            } else if (
                this.dispatchItemsChecked &&
                !this.allCheckboxesSelected &&
                this.checkedItemsCurrentSize === 0
            ) {
                this.dispatchItemsChecked = !this.dispatchItemsChecked;
                this.dispatchCheckboxesActive(this.dispatchItemsChecked);
            }
        }
    }

    private dispatchCheckboxesActive = (state: boolean): void => {
        this.dispatchEvent(
            new CustomEvent('grid-checkboxes-state-change', {
                detail: state,
            }),
        );
    };

    public firstUpdated(changedProperties: any) {
        super.firstUpdated(changedProperties);

        this.setFirstAndLastElement();
    }

    public resetCheckedItems = (): void => {
        this.allCheckboxesSelected = false;
        this.checkedItems = new Map();
        this.excludedItems = new Map();
        this.checkedItemsTotalSize = 0;
        this.checkedItemsCurrentSize = 0;
        this.shadowRoot?.querySelector('grid-checkbox')?.reset();
        this.items = { ...this.items };
    };

    private allCheckboxesChanged = (e: CustomEvent): void => {
        const checked: boolean = e.detail;
        this.allCheckboxesSelected = checked;

        // if all checkboxes ar checked or unchecked reset the individual check logic.
        this.checkedItemsTotalSize = 0;
        this.checkedItems = new Map();
        this.checkedItemsCurrentSize =
            this.itemsTotal !== 0 ? this.itemsTotal : this.items.length;

        if (!checked) {
            this.checkedItemsCurrentSize = 0;
        }
    };

    /**
     * Check if paths are different if so find the last component and change the state to default.
     */
    private onSorterChanged(e: CustomEvent) {
        this.sorter = e.detail;
        const { sorter, lastPath, slotElements } = this;

        if (typeof lastPath !== 'undefined' && lastPath !== sorter.path) {
            const components =
                slotElements!.assignedElements() as unknown as GridSorter[];
            components.forEach((component: GridSorter) => {
                if (component.path !== sorter.path && component.connected) {
                    component.connected = false;
                    component.direction = defaultSort;
                }
            });
        }
        // set path to lastPath
        this.lastPath = sorter.path;

        e.stopPropagation();

        this._dataProviderChanged();
    }

    /**
     * When a checkbox change see if item needs to be added or removed from checkedItems
     */
    private onCheckboxChanged = (e: CheckboxEvent): void => {
        const item = e.detail.item as itemType;
        const checked = e.detail.checked;

        // if the grid checkbox is undefined set the grid checkbox component.
        if (this.gridCheckbox == null) {
            const gridCheckbox:
                | HTMLElementTagNameMap['grid-checkbox']
                | undefined = this.querySelector(
                'grid-checkbox',
            ) as HTMLElementTagNameMap['grid-checkbox'];

            if (gridCheckbox) {
                this.gridCheckbox = gridCheckbox;
            }
        }

        // if the parent checkbox is checked exclude the unchecked elements
        if (this.allCheckboxesSelected) {
            this.setAllCheckboxValues(checked, item);

            return;
        }

        // if individual items are checked add or remove individual items.
        this.setIndividualCheckboxValues(checked, item);
    };

    /**
     * set values if the parent checkbox is changed
     * this adds/ deletes only the unchecked items.
     */
    private setAllCheckboxValues = (checked: boolean, item: itemType): void => {
        // if not chcked and item doesnt exist in exclude items add item.
        if (!checked && !this.excludedItems.has(item.id)) {
            this.excludedItems.set(item.id, item);
        } else if (checked && this.excludedItems.has(item.id)) {
            // if excluded items has item and checked delete exclude item.
            this.excludedItems.delete(item.id);
        }

        if (this.gridCheckbox) {
            this.gridCheckbox.itemsSelected =
                this.itemsTotal - this.excludedItems.size;
            this.gridCheckbox.itemsTotal = this.itemsTotal;
        }
    };

    /**
     * set values if individual checkboxes are selected
     * this set/ changes only the checked items.
     */
    private setIndividualCheckboxValues = (
        checked: boolean,
        item: itemType,
    ): void => {
        if (!item) {
            return;
        }

        if (this.checkedItems.has(item.id)) {
            this.checkedItems.delete(item.id);
        } else if (item != null && checked) {
            this.checkedItems.set(item.id, item);
        }

        if (this.gridCheckbox) {
            this.gridCheckbox.itemsSelected = this.checkedItems.size;
            this.gridCheckbox.itemsTotal = this.itemsTotal;
        }

        this.checkedItemsCurrentSize = this.checkedItems.size;
    };

    private async _dataProviderChanged() {
        const { sorter, items } = this;
        let sortedItems;

        if (sorter.direction !== defaultSort) {
            if (sorter.direction === ascSort) {
                this.itemsDefault = [...items];
            }
            sortedItems = items.sort((a: any, b: any): any => {
                if (sorter.direction === ascSort) {
                    return this._compare(a[sorter.path], b[sorter.path]);
                }

                return this._compare(b[sorter.path], a[sorter.path]);
            });
        } else {
            sortedItems = [...this.itemsDefault];
        }
        this.items = [...sortedItems];
    }

    // compare values and returns greater than, less than or equal to number for sorting.
    private _compare(a: any, b: any) {
        // normalize value if empty
        a = this._normalizeEmptyValue(a);
        b = this._normalizeEmptyValue(b);

        if (a < b) {
            return -1;
        }
        if (a > b) {
            return 1;
        }
        return 0;
    }

    // return normalized string value if the value is empty.
    private _normalizeEmptyValue(value: any) {
        if ([undefined, null].indexOf(value) >= 0) {
            return '';
        } else if (isNaN(value)) {
            return value.toString().toLowerCase();
        }

        return value;
    }

    // clear the existing columns.
    private async clearCache() {
        if (this.slotElements) {
            const columns: GridLogic[] =
                this.slotElements.assignedElements() as unknown as GridLogic[];
            if (columns && columns.length > 0) {
                columns.forEach((column: GridLogic) => {
                    if (typeof column.resetContent !== 'undefined') {
                        column.resetContent();
                    }
                });
            }
        }
    }

    /**
     * get all items and set there values in a grid-cell component.
     * if @formatter exist on A column the value gets formatted and that value gets returned.
     * if @textNotation exist the value gets extended with a text notation.
     */
    // todo: refactor columnTreeChanged
    private _columnTreeChanged(): void {
        const { items, elementColmuns } = this;
        if (
            items &&
            elementColmuns &&
            elementColmuns.length > 0 &&
            items.length > 0
        ) {
            for (const item of items) {
                for (const [index, el] of elementColmuns!.entries()) {
                    if (
                        typeof el.path !== 'undefined' ||
                        (typeof el.path === 'undefined' &&
                            el.onRender !== undefined)
                    ) {
                        // Created each cell in the row.
                        const cell = document.createElement(
                            'grid-cell',
                        ) as GridCell;
                        if (index === 0) {
                            cell.changeDropdownState = !this.isCollapsed;
                        }
                        cell.item = item;
                        cell.value = el.path
                            .split('.')
                            .reduce((o, i) => o[i], item);
                        cell.index = index;
                        if (el.onRender) {
                            cell.onRender = el.onRender;
                        }
                        el.appendChild(cell);

                        // logic to add all cells to the first cell (collapsable logic)
                        if (index === 0) {
                            this.firstCell = cell;
                            this.childElements = [];
                        }

                        // render new rows for the child elements from the first row.
                        if (
                            this.renderNewRowFor != null &&
                            item.hasOwnProperty(this.renderNewRowFor) &&
                            item[this.renderNewRowFor] != null &&
                            Object.keys(item[this.renderNewRowFor]).length > 0
                        ) {
                            item[this.renderNewRowFor].forEach(
                                (newRowItem: any) => {
                                    const newRowCell = document.createElement(
                                        'grid-cell',
                                    ) as GridCell;

                                    newRowCell.item = newRowItem;
                                    newRowCell.value = newRowItem[el.path];
                                    newRowCell.index = index;
                                    newRowCell.changeRenderer = true;
                                    newRowCell.hidden = this.isCollapsed;
                                    if (this.childElements) {
                                        this.childElements.push(newRowCell);
                                    }
                                    if (el.onRender) {
                                        newRowCell.onRender = el.onRender;
                                    }
                                    el.append(newRowCell);
                                },
                            );
                        }

                        // when the last element has been reached
                        if (
                            this.renderNewRowFor != null &&
                            index === elementColmuns.length - 1
                        ) {
                            if (this.firstCell) {
                                this.firstCell.childElements =
                                    this.childElements;
                                this.firstCell = undefined;
                            }
                        }
                    }
                }
            }
        }
    }

    // set attribute to show whats the first and last element in the grid.
    private setFirstAndLastElement() {
        const slot = this.renderRoot.querySelector('slot') as HTMLSlotElement;
        if (!slot) {
            return;
        }

        this.elementColmuns = slot.assignedElements() as unknown as GridLogic[];
        if (slot && this.elementColmuns) {
            const slotLength = this.elementColmuns.length - 1;
            // set firstElement
            if (!this.elementColmuns[0].hidden) {
                this.elementColmuns[0].classList.add('first-elm');
            } else {
                for (const element of this.elementColmuns) {
                    if (!element.hidden) {
                        element.classList.add('first-elm');
                        break;
                    }
                }
            }
            // set lastElement
            const lastElement = this.elementColmuns[slotLength];
            if (!lastElement.hidden) {
                lastElement.classList.add('last-elm');
            } else {
                for (let i = this.elementColmuns.length - 1; i > 0; i--) {
                    const element = this.elementColmuns[i];
                    if (!element.hidden) {
                        element.classList.add('last-elm');
                        break;
                    }
                }
            }
        }
    }
}

declare global {
    interface HTMLElementTagNameMap {
        'grid-data-provider': GridDataProvider;
    }
}
