/**
 * Setting resources
 */

export const xAuth = () => window.STDHeaders.get('x-auth');

export const authHeaders = {
    'x-auth': xAuth,
};
export const defaultAuthParam = {
    defaultParameters: { ...authHeaders },
    basePath: '/v1',
};
export const defaultParam = {
    defaultParameters: { xAuth },
    basePath: '/v1',
};
