import { MeteringPoint } from 'types/meteringPoint';
import { EanUsageType } from 'atoms/EANInput';

export const getPrefixFromEan = (ean: string) => ean.slice(0, 4);

export const getEanPrefixForUsage = (
    meteringPoints: MeteringPoint[],
    eanUsageType: EanUsageType,
    prefixLength: number = 4,
): string => {
    const ean = meteringPoints?.find((m: MeteringPoint) => {
        return m.description === eanUsageType;
    });

    if (ean) {
        return ean.identifier.slice(0, prefixLength);
    }

    return '';
};
