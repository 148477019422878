import {
    CLEAR_CREATE_USER_LOCATION_QUERY,
    REQUEST_CREATE_LOCATION,
    REQUEST_CREATE_USER_LOCATION_SUCCESS,
    REQUEST_CREATE_INVOICE_LOCATION,
    REQUEST_CREATE_INVOICE_LOCATION_FAILED,
    REQUEST_CREATE_INVOICE_LOCATION_SUCCESS,
    REQUEST_CREATE_LOCATION_FAILED,
    RESET_CREATE_USER_LOCATION,
    RESET_CREATE_INVOICE_LOCATION,
    INVOICE_LOCATION,
    USER_LOCATION,
} from '../actions/userLocation';

const defaultCreateUserLocationState = {
    query: null,
    data: null,
    locationType: USER_LOCATION,
    error: null,
};
const defaultCreateInvoiceLocationState = {
    query: null,
    data: null,
    locationType: INVOICE_LOCATION,
    error: null,
};

/**
 * State reducer for create user location request
 * @param {Object} state
 * @param {Object} action
 * @return {Object}
 */
export const createUserLocation = (
    state = { ...defaultCreateUserLocationState },
    action,
) => {
    switch (action.type) {
        case CLEAR_CREATE_USER_LOCATION_QUERY:
            return { ...state, query: null, locationType: USER_LOCATION };
        case REQUEST_CREATE_LOCATION:
            return {
                ...state,
                query: action.payload,
                locationType: USER_LOCATION,
                data: null,
            };
        case REQUEST_CREATE_USER_LOCATION_SUCCESS:
            return {
                ...state,
                data: action.data,
                locationType: USER_LOCATION,
                error: null,
            };
        case REQUEST_CREATE_LOCATION_FAILED:
            return {
                ...state,
                locationType: USER_LOCATION,
                error: action.error,
            };
        case RESET_CREATE_USER_LOCATION:
            return { ...defaultCreateUserLocationState };
        default:
            return state;
    }
};

/**
 * State reducer for create user location request
 * @param {Object} state
 * @param {Object} action
 * @return {Object}
 */
export const createInvoiceLocation = (
    state = { ...defaultCreateInvoiceLocationState },
    action,
) => {
    switch (action.type) {
        case REQUEST_CREATE_INVOICE_LOCATION:
            return {
                ...state,
                query: action.payload,
                locationType: INVOICE_LOCATION,
                data: null,
            };
        case REQUEST_CREATE_INVOICE_LOCATION_SUCCESS:
            return {
                ...state,
                data: action.data,
                locationType: INVOICE_LOCATION,
                error: null,
            };
        case REQUEST_CREATE_INVOICE_LOCATION_FAILED:
            return {
                ...state,
                locationType: INVOICE_LOCATION,
                error: action.error,
            };
        case RESET_CREATE_INVOICE_LOCATION:
            return { ...defaultCreateInvoiceLocationState };
        default:
            return state;
    }
};
