import { SET_ERROR } from '../actions/error';
import { USER_LOG_OUT } from '../actions/user';

/**
 * State reducer for Error state
 * @param {Object} state
 * @param {Object} action
 * @return {Object}
 */
export const error = (state = {}, action) => {
    switch (action.type) {
        case SET_ERROR:
            return {
                ...state,
                message: action.error,
            };
        case USER_LOG_OUT:
            return { message: '' };
        default:
            return state;
    }
};

export default error;
