import {
    REQUEST_GRAPH,
    REQUEST_GRAPH_FAILED,
    REQUEST_GRAPH_SUCCESS,
} from '../actions/graph';

const defaultGraphs = { data: [], error: null };

/**
 * State reducer for invoices request
 * @param {Object} state
 * @param {Object} action
 * @return {Object}
 */
export const graph = (state = { ...defaultGraphs }, action) => {
    switch (action.type) {
        case REQUEST_GRAPH:
            return { ...state, busy: true };
        case REQUEST_GRAPH_SUCCESS:
            return { ...state, data: action.data, busy: false, error: null };
        case REQUEST_GRAPH_FAILED:
            return { ...state, busy: false, error: action.error };
        default:
            return state;
    }
};
