import { all } from 'redux-saga/effects';
import { asyncSagas } from '../async-reducers';
import { watchSetJwtSnapshot, watchUpdateRouting } from './app';
import { watchArchiveSnapshot } from './archiveSnapshot';
import { watchAuthentication } from './authentication';
import { watchRequestOfferFlow } from './calculator';
import { watchRequestCollectives } from './collective';
import { watchRequestCreateCompany } from './company';
import { watchCostReports } from './costReports';
import { watchRequestCostValues } from './costValues';
import { watchDownload } from './downloads';
import { watchNotifyEDSNLive } from './edsnLive';
import {
    watchFinishEnroll,
    watchResetEnroll,
    watchStartEnroll,
} from './enroll';
import { watchRequestGraph, watchRequestGraphSuccess } from './graph';
import { watchRequestInsights } from './insights';
import { watchRequestInvoices } from './invoices';
import { watchSetJWTSnapshots, watchSetSelectedSnapshot } from './jwtSnapshots';
import { watchOda } from './oda';
import { watchRequestOffer } from './offer';
import { watchSetPostalCode } from './order';
import { watchResetPassword } from './resetPassword';
import { watchAuthRedirect } from './routing';
import { watchRequestSavings, watchRequestSavingsFetched } from './savings';
import { watchEnergysplitter } from './schedule';
import { watchSnapshot } from './snapshot';
import { watchSnapshotVast } from './snapshotVast';
import { watchAuthStateChange, watchStateChange } from './unauth';
import { watchUsages } from './usages';
import { watchRequestCreateUser } from './user';
import {
    watchRequestCreateInvoiceLocation,
    watchRequestCreateUserLocation,
} from './userLocation';
import { watchRequestCreateUserPaymentAccount } from './userPaymentAccount';
import {
    watchRequestCreateUserSnapshot,
    watchRerouteUser,
} from './userSnapshot';
import { watchRequestVehicleList } from './vehicle';
import { watchRequestVendorConfig } from './vendor';
import { watchEditWorkDay } from './weekday';
import { watchSetLocation, watchListBroadcasts } from './broadcasts';

/**
 * Root saga for redux-saga
 */
const rootSaga = (...resources) =>
    function* () {
        yield all([
            ...resources.map((resource) => resource.watch()()),
            watchFinishEnroll(),
            watchRerouteUser(),
            watchResetEnroll(),
            watchRequestCollectives(),
            watchRequestCreateCompany(),
            watchRequestCreateUser(),
            watchRequestCreateUserLocation(),
            watchRequestCreateInvoiceLocation(),
            watchRequestCreateUserPaymentAccount(),
            watchRequestCreateUserSnapshot(),
            watchRequestOffer(),
            watchRequestOfferFlow(),
            watchSetPostalCode(),
            watchStartEnroll(),
            watchUpdateRouting(),
            watchRequestInvoices(),
            watchRequestGraph(),
            watchRequestGraphSuccess(),
            watchSetJWTSnapshots(),
            watchRequestSavings(),
            watchRequestSavingsFetched(),
            watchRequestInsights(),
            watchAuthRedirect(),
            watchSetJwtSnapshot(),
            watchRequestCostValues(),
            asyncSagas(),
            watchStateChange(),
            watchAuthStateChange(),
            watchSnapshot(),
            watchEditWorkDay(),
            watchResetPassword(),
            watchEnergysplitter(),
            watchNotifyEDSNLive(),
            watchArchiveSnapshot(),
            watchDownload(),
            watchSnapshotVast(),
            watchRequestVehicleList(),
            watchOda(),
            watchSetSelectedSnapshot(),
            watchRequestVendorConfig(),
            watchAuthentication(),
            watchUsages(),
            watchCostReports(),
            watchSetLocation(),
            watchListBroadcasts(),
        ]);
    };

export default rootSaga;
