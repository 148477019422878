import {
    UPDATE_ROUTING,
    UPDATE_OFFLINE,
    OPEN_SNACKBAR,
    CLOSE_SNACKBAR,
    UPDATE_DRAWER_STATE,
    UPDATE_TERMS_AND_CONDITIONS,
    UPDATE_USER_AUTHENTICATED,
    UPDATE_INSIGHTS_APP_USER,
    UPDATE_ENABLED_ITEMS,
} from '../actions/app';

/** @typedef {import('../actions/app').App} App */

/**
 * State reducer for App state
 * @param {Object} state
 * @param {Object} action
 * @return {App}
 */
const app = (
    state = {
        drawerOpened: false,
        page: 'start',
        acceptedTermsAndConditions: false,
        userAuthenticated: false,
        insightsAppUser: false,
    },
    action,
) => {
    switch (action.type) {
        case UPDATE_ROUTING:
            return {
                ...state,
                routing: action.routing,
            };
        case UPDATE_OFFLINE:
            return {
                ...state,
                offline: action.offline,
            };
        case UPDATE_DRAWER_STATE:
            return {
                ...state,
                drawerOpened: action.opened,
            };
        case UPDATE_TERMS_AND_CONDITIONS:
            return {
                ...state,
                acceptedTermsAndConditions: action.acceptedTermsAndConditions,
            };
        case UPDATE_USER_AUTHENTICATED:
            return {
                ...state,
                userAuthenticated: action.userAuthenticated,
            };
        case UPDATE_INSIGHTS_APP_USER:
            return {
                ...state,
                insightsAppUser: action.insightsAppUser,
            };
        case OPEN_SNACKBAR:
            return {
                ...state,
                snackbarOpened: true,
            };
        case CLOSE_SNACKBAR:
            return {
                ...state,
                snackbarOpened: false,
            };
        case UPDATE_ENABLED_ITEMS:
            return {
                ...state,
                enabledItems: {
                    competition: action.enabledItems.competition,
                    electricity: action.enabledItems.electricity,
                    production: action.enabledItems.production,
                    gas: action.enabledItems.gas,
                    savings: action.enabledItems.savings,
                    costs: action.enabledItems.costs,
                    invoices: action.enabledItems.invoices,
                    splitBusiness: action.enabledItems.splitBusiness,
                },
            };
        default:
            return state;
    }
};

export default app;
