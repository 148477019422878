import { css } from 'lit-element';

export default css`
    :host {
        --capacity--background-rgb: var(
            --capacity-background-rgb,
            rgb(255, 111, 73)
        );
        --capacity--input-border-color: rgb(242, 242, 242);
        --capacity--input-border-width: 2px;
    }

    :root,
    :host,
    .sl-theme-light {
        --sl-color-sky-600: var(--capacity--background-rgb);
        --sl-input-font-family: var(--primary-font-family);
        --sl-input-border-color: var(--capacity--input-border-color);
        --sl-input-border-width: var(--capacity--input-border-width);
    }
`;
