import { css } from 'lit-element';

export default css`
    :host {
        --ean-input-succes-color: green;
        --ean-input-error-color: red;
    }

    #confirm-btn {
        width: 100%;
        margin-top: 35px;
    }

    .eaninput__helper {
        font-size: 14px;
        font-weight: 500;
        margin-top: 6px;
    }

    .eaninput__helper--success {
        color: var(--ean-input-succes-color);
    }

    .eaninput__helper--error {
        color: var(--ean-input-error-color);
    }
`;
