import { RouterLocation } from '@vaadin/router';
import {
    SetLocationAction,
    SET_AWAIT_LOGIN,
    SET_LOCATION,
} from '../actions/routing';

export interface RoutingState {
    location?: RouterLocation;
    url: string;
    page: string;
}

const defaultState: RoutingState = {
    location: undefined,
    url: '',
    page: '',
};

/**
 * State reducer for awaitLogin
 */
export const awaitLogin = (state: boolean = false, action: any): boolean => {
    switch (action.type) {
        case SET_AWAIT_LOGIN:
            if (action.loading !== state) {
                return action.loading;
            }

            return state;
        default:
            return state;
    }
};

/**
 * State reducer for routing
 */
export const routing = (
    state: RoutingState = defaultState,
    action: SetLocationAction,
): RoutingState => {
    switch (action.type) {
        case SET_LOCATION:
            return {
                location: action.location,
                url: action.location.pathname,
                page: action.location.pathname.split('/')[1],
            };
        default:
            return state;
    }
};
