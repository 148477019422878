import { LionInput } from '@lion/input';
import { CSSResultArray, PropertyValues } from 'lit';
import { customElement } from 'lit/decorators/custom-element.js';
import { property } from 'lit/decorators/property.js';
import style from './HvInputCodeStyle';

const regexReplaceLabel = / \d+$/;
const firstField = 1;

@customElement('hv-input-code')
export class HvInputCode extends LionInput {
    @property({ type: Number, reflect: true })
    public fields: number = 0;
    @property({ type: Number, reflect: true })
    private currentField: number = 0;

    public prev?: this;
    private next?: this;
    private lastChildEvent?: Event;

    public static get styles(): CSSResultArray {
        return [...super.styles, style];
    }

    public connectedCallback(): void {
        super.connectedCallback();

        // Add listeners
        this.addEventListener('keydown', this.keydownListener);
        this.addEventListener('focus', this.focusListener);
    }

    public disconnectedCallback(): void {
        // Remove listeners
        this.removeEventListener('keydown', this.keydownListener);
        this.removeEventListener('focus', this.focusListener);

        // Remove label listener if one exists
        this.maybeDisconnectLabel();

        super.disconnectedCallback();
    }

    public maybeDisconnectLabel = (): void => {};
    public firstUpdated(changedProperties: PropertyValues): void {
        super.firstUpdated(changedProperties);

        // Attempt to find <slot name="label"></slot> to patch a click-to-focus issue in Safari
        const labelSlot = this.shadowRoot?.querySelector(
            'slot[name="label"]',
        ) as HTMLSlotElement | undefined;
        if (labelSlot) {
            // Attempt to find slotted <label></label>
            const label = labelSlot
                .assignedElements()
                .find((n: Element) => n.tagName.toLowerCase() === 'label') as
                | HTMLSlotElement
                | undefined;

            // Add click listener and unsubscribe logic
            if (label) {
                label.addEventListener('click', this.focus);
                this.maybeDisconnectLabel = () => {
                    label.removeEventListener('click', this.focus);
                };
            }
        }

        // Force numeric keyboard on mobile Safari by setting inner <input pattern="[0-9]*" inputmode="numeric"></input>
        this._inputNode.setAttribute('pattern', '[0-9]*');
        this._inputNode.setAttribute('inputmode', 'numeric');
    }

    public updated(changedProperties: PropertyValues): void {
        super.updated(changedProperties);

        // Can't be both child and controller, controller will be the inverse of child
        if (this.hasAttribute('child') === this.hasAttribute('controller')) {
            this.toggleAttribute('controller');
        }

        // On attribute setting change, propagate to children
        const changedPropertyKeys: PropertyKey[] = [
            ...changedProperties.keys(),
        ];
        const watch: string[] = [
            'currentField',
            'fields',
            'name',
            'type',
            'disabled',
        ];
        if (watch.find((key) => changedPropertyKeys.includes(key))) {
            this.updateStructure();
        }

        // On value change
        if (
            changedProperties.has('modelValue') &&
            !this.hasAttribute('controller')
        ) {
            if (
                this.inputMode === 'numeric' &&
                String(this.modelValue).length &&
                /^[0-9]*$/.test(String(this.modelValue))
            ) {
                this.updateValue(changedProperties.get('modelValue'));
                return;
            }
            this.modelValue = '';
        }
    }

    private updateStructure = (): void => {
        if (this.fields > this.currentField) {
            // Get existing or create new child
            const next: this = <this>(
                (this.next !== undefined
                    ? this.next
                    : window.document.createElement(this.tagName))
            );

            // Update
            next.currentField = this.currentField + 1;
            next.disabled = this.disabled;
            next.fields = this.fields;
            next.inputMode = this.inputMode;
            next.placeholder = '0';
            next.label = `${this.label.replace(regexReplaceLabel, '')} ${
                next.currentField
            }`;
            next.name = `${this.name.replace(regexReplaceLabel, '')}__${
                next.currentField
            }`;
            next.type = this.type;

            // Setup first field for autofill
            if (next.currentField === firstField) {
                next.autocomplete = this.autocomplete;
            }

            // Finalize if we just created a new child
            if (this.next === undefined) {
                // Setup
                next.autocapitalize = 'off';
                next.prev = this;
                next.slot = 'after';
                next.setAttribute('child', String(this.currentField + 1));
                if (next.currentField === firstField) {
                    next.toggleAttribute('first');
                }

                // Listen to changes
                next.addEventListener(
                    'user-input-changed',
                    this.listenChildChanges,
                );

                // Disable default feedback for all non-first fields
                const feedback = window.document.createElement('div');
                feedback.slot = 'feedback';
                next.appendChild(feedback);

                // Attach
                this.next = next;
                this.appendChild(next);
            }
            return;
        }
        if (!this.next) {
            return;
        }
        // Remove existing
        this.next.removeEventListener(
            'user-input-changed',
            this.listenChildChanges,
        );
        this.removeChild(this.next);
        this.next.prev = undefined;
        this.next = undefined;
    };

    private updateValue = (old: any): void => {
        const len = String(this.modelValue).length;
        const prev = String(old).length;

        // Removed a char, goto previous if exists
        if (
            len === 0 &&
            prev === 1 &&
            this.focused &&
            this.prev &&
            !this.prev.hasAttribute('controller')
        ) {
            {
                this.prev.focus();
            }
        }

        // Typed a char, goto next if exists
        if (old !== this.modelValue && len === 1) {
            setTimeout(() => {
                if (this.focused && this.next) {
                    this.next.focus();
                }
            }, 10);
        }

        // Pasted/autofilled something
        if (len > 1 && this.next) {
            const [f, ...r] = [...String(this.modelValue)];
            this.next.modelValue = `${r.join('')}${this.next.modelValue}`;
            this.modelValue = f;
            this.next.focus();
        } else if (len > 1 && this.fields === this.currentField) {
            this.modelValue = String(this.modelValue)[0];
        }
    };

    private keydownListener = (e: KeyboardEvent): void => {
        e.stopPropagation();

        switch (e.key) {
            case 'Backspace':
                // If we find a backspace in an empty field, focus previous
                if (
                    String(this.modelValue).length === 0 &&
                    this.prev &&
                    !this.prev.hasAttribute('controller')
                ) {
                    this.prev.modelValue = '';
                    setTimeout(() => {
                        if (this.prev) {
                            this.prev.focus();
                        }
                    }, 10);
                }
                break;
            case 'ArrowLeft':
                if (this.prev && !this.prev.hasAttribute('controller')) {
                    this.prev.focus();
                }
                break;
            case 'ArrowRight':
                if (this.next) {
                    this.next.focus();
                }
                break;
            default:
        }
    };

    public focus = (): void => {
        super.focus();
        setTimeout(() => {
            // When we focus a field, select the text inside it, except for in
            // an empty field because mobile Safari will try to select the empty
            // string.
            if (this.modelValue.length > 0) {
                try {
                    (this._inputNode as HTMLInputElement).select();
                } catch (_) {}
            }

            // If the disabled controller is somehow focussed, force focus to
            // the next field. This also makes the label click work for the
            // controller label without breaking accessibility.
            if (this.hasAttribute('controller') && this.next) {
                this.next.focus();
            }
        }, 10);
    };

    /**
     * Get value from this and it's children as an array
     */
    public chain = (): string[] => [
        String(this.modelValue),
        ...(this.next?.chain() || []),
    ];

    /**
     * If the disabled controller is somehow focussed, force focus to the next
     * field.
     */
    private focusListener = (): void => {
        if (this.hasAttribute('controller') && this.next) {
            this.next.focus();
        }
    };

    /**
     * Prevent children from firing individual change events but combine and
     * debounce (10ms) into a single @user-input-changed event.
     */
    private listenChildChanges = (e: Event) => {
        if (this.hasAttribute('controller')) {
            e.stopImmediatePropagation();
            this.lastChildEvent = e;
            setTimeout(() => {
                if (this.lastChildEvent === e) {
                    const chain: string[] | undefined = this.next?.chain();
                    if (chain) {
                        const next = chain.join('');
                        if (this.modelValue !== next) {
                            this.modelValue = next;
                            this.dispatchEvent(e);
                        }
                    }
                }
            }, 10);
        }
    };
}

declare global {
    interface HTMLElementTagNameMap {
        'hv-input-code': HvInputCode;
    }
}
