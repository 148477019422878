import { Snapshot, Snapshots } from 'types/snapshot';
import { createSelector, Selector } from 'reselect';
import { JWTSnapshotState } from '../reducers/snapshot';
import { isOda } from '../helpers/snapshot';

export interface StorePart {
    jwtSnapshots: JWTSnapshotState;
}

export const selectOdaSnapshot: Selector<StorePart, Snapshot | undefined> =
    createSelector(
        (state: StorePart) => state.jwtSnapshots.snapshots,
        (snapshots: Snapshots) =>
            snapshots.find((snapshot: Snapshot) => isOda(snapshot)),
    );
