import { css } from 'lit-element';

export default css`
  .phase-banner {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100px;
    padding: 10px 0;
  }

  p {
    margin: 0;
    padding: 2px 0;
  }

  .title {
    font-size: 20px;
    font-weight: 600;
    color: #030521;
  }

  .status-row {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .dot {
    margin-right: 4px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
  }

  .status {
    font-size: 12px;
  }

  .body {
    font-size: 12px;
    color: grey;
  }

  .phase-banner[phasebannerstyle="NEUTRAL"] .dot {
    background-color: #339cde;
  }

  .phase-banner[phasebannerstyle="NEUTRAL"] .status {
    color: #339cde;
  }

  .phase-banner[phasebannerstyle="SUCCESS"] .dot {
    background-color: green;
  }

  .phase-banner[phasebannerstyle="SUCCESS"] .status {
    color: green;
  }

  .phase-banner[phasebannerstyle="WARNING"] .dot {
    background-color: yellow;
  }

  .phase-banner[phasebannerstyle="WARNING"] .status {
    color: yellow;
  }

  .phase-banner[phasebannerstyle="ERROR"] .dot {
    background-color: red;
  }

  .phase-banner[phasebannerstyle="ERROR"] .status {
    color: red;
  }
}
`;
