import { provider } from '@weavelab/frontend-connect';
import enums from 'enums';
import settings from '../../../internationalization/settings';
import EzUsageTemplate from '../../templates/ez-usage-template/usage-template';

const themeSettings = provider.settings('electricity');

/**
 * Class for showing Energy usage using Usage Template
 */
export default class EzElectricity extends EzUsageTemplate {
    /**
     * Constructor for EzElectricity
     */
    constructor() {
        super();
        /** @type {Number | null} */
        this.usageType = enums.UsageTypeElekUsage;
        /** @type {String} */
        this.label = 'Euro';
        /** @type {Boolean} */
        this.active = false;
        /** @type {string} */
        this.usageHeaderType = 'electricity';
        /** @type {String} */
        this.appTitle =
            themeSettings && themeSettings.title != null
                ? themeSettings.title
                : this.appTitle;
        /** @type {String} */
        this.dataColor = settings.electricityAccentColor;
    }

    /**
     * Callback for global state changed
     * @param  {...any} args
     */
    _stateChanged(...args) {
        // @ts-ignore
        super._stateChanged(...args);
    }
}

window.customElements.define('ez-electricity', EzElectricity);
