import {
    Bearer,
    BearerSupportedTypes,
    ParameterDefinitionsObject,
    ParameterIn,
    RequestAction,
    RequestOptions,
    RequestSagaIterableIterator,
    ResolvableOrType,
    Resource,
    ResourceOptions,
    UUID,
    UUIDSupportedTypes,
} from 'goagen-js-resource';
import { Method } from 'axios';

export interface ArchiveSnapshotRequestObject {
    // (Boolean)
    sendEmail?: boolean;
    // (UUID)
    snapshotId?: UUIDSupportedTypes;
    // User ID (UUID)
    userId?: UUIDSupportedTypes;
    // Injected Bearer header (Bearer)
    xAuth?: BearerSupportedTypes;
}

export interface ResolvableArchiveSnapshotRequestObject {
    // (Boolean)
    sendEmail?: ResolvableOrType<ArchiveSnapshotRequestObject['sendEmail']>;
    // (UUID)
    snapshotId?: ResolvableOrType<ArchiveSnapshotRequestObject['snapshotId']>;
    // User ID (UUID)
    userId?: ResolvableOrType<ArchiveSnapshotRequestObject['userId']>;
    // Injected Bearer header (Bearer)
    xAuth?: ResolvableOrType<ArchiveSnapshotRequestObject['xAuth']>;
}

/**
 * Store member management object for Archive snapshot
 */
export class ArchiveSnapshot extends Resource<
    ArchiveSnapshotRequestObject,
    ResolvableArchiveSnapshotRequestObject
> {
    /**
     * Create new ArchiveSnapshot
     *
     * | Parameter |  | Target |  |  |
     * | --- | --- | --- | --- | --- |
     * | `sendEmail` * | `Boolean` | query | `sendEmail` |  |
     * | `snapshotId` * | `UUID` | path | `snapshotID` |  |
     * | `userId` * | `UUID` | path | `user_id` | User ID |
     * | `xAuth` | `Bearer` | header | `X-Auth` | Injected Bearer header |
     */
    constructor(
        storeName: string,
        options: ResourceOptions<ResolvableArchiveSnapshotRequestObject> = {},
    ) {
        super(storeName, options);
    }

    /**
     * Generate request action
     *
     * | Parameter |  | Target |  |  |
     * | --- | --- | --- | --- | --- |
     * | `sendEmail` * | `Boolean` | query | `sendEmail` |  |
     * | `snapshotId` * | `UUID` | path | `snapshotID` |  |
     * | `userId` * | `UUID` | path | `user_id` | User ID |
     * | `xAuth` | `Bearer` | header | `X-Auth` | Injected Bearer header |
     */
    public request(
        requestObject: ArchiveSnapshotRequestObject = {},
        requestOptions: RequestOptions = {},
    ): RequestAction<ArchiveSnapshotRequestObject> {
        return super.request(requestObject, requestOptions);
    }

    /**
     * Run request as saga
     *
     * | Parameter |  | Target |  |  |
     * | --- | --- | --- | --- | --- |
     * | `sendEmail` * | `Boolean` | query | `sendEmail` |  |
     * | `snapshotId` * | `UUID` | path | `snapshotID` |  |
     * | `userId` * | `UUID` | path | `user_id` | User ID |
     * | `xAuth` | `Bearer` | header | `X-Auth` | Injected Bearer header |
     */
    // @ts-ignore
    public get requestSaga(): (
        requestObject?: ArchiveSnapshotRequestObject,
        requestOptions?: RequestOptions,
    ) => RequestSagaIterableIterator<
        RequestAction<ArchiveSnapshotRequestObject>
    > {
        return super.requestSaga;
    }

    /**
     * Action string
     */
    static get action(): string {
        return 'Archive';
    }

    /**
     * Description string
     */
    static get description(): string {
        return 'Archive a snapshot';
    }

    /**
     * Request method
     */
    static get method(): Method {
        return 'post';
    }

    /**
     * Resource string
     */
    static get resource(): string {
        return 'snapshot';
    }

    /**
     * Route string
     */
    static get route(): string {
        return '/user/{user_id}/snapshot/{snapshotID}/archive';
    }

    /**
     * Unique name string
     */
    static get uniqueName(): string {
        return 'ArchiveSnapshot';
    }

    /**
     * Parameters object
     */
    static get parameters(): ParameterDefinitionsObject {
        return {
            sendEmail: {
                in: ParameterIn.query,
                name: 'sendEmail',
                required: true,
                type: Boolean,
            },
            snapshotId: {
                in: ParameterIn.path,
                name: 'snapshotID',
                required: true,
                type: UUID,
            },
            userId: {
                description: 'User ID',
                in: ParameterIn.path,
                name: 'user_id',
                required: true,
                type: UUID,
            },
            xAuth: {
                description: 'Injected Bearer header',
                in: ParameterIn.header,
                name: 'X-Auth',
                type: Bearer,
            },
        };
    }
}
