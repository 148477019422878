import {
    LitElement,
    TemplateResult,
    customElement,
    html,
    property,
} from 'lit-element';
import { connect, watch } from 'lit-redux-watch';

import '../../../../../elements/base/button/ButtonComponent';
import { archiveSnapshot } from 'data-access/store';
import { RequestOptions } from 'goagen-js-resource';
import { whitelabel } from '@weavelab/whitelabel';
import { Snapshot } from 'types/snapshot';
import { uiOrderOverviewInteraction } from '@actions/ui';
import {
    uiUsageCheckAgreeClickedDataLayer,
    uiUsageSupportClickedDataLayer,
    uiUsageCheckDisagreeClickedDataLayer,
} from 'gtm-middleware/orderOverview';
import { ArchiveSnapshotRequestObject } from '../../../../../../resources/ArchiveSnapshot';

@customElement('opt-out-buttons')
export class OptOutButtons extends connect(window.store)(LitElement) {
    @property({ type: String })
    public textApproved: string = 'JA, ik ga akkoord';

    @property({ type: String })
    public textSupport: string =
        'Ik wil graag een prijsvoorstel gebaseerd op het door mij opgegeven verbruik.';

    @property({ type: String })
    public textDeclined: string = 'Nee, ik ga niet akkoord';

    @watch('jwtSnapshots.selectedSnapshot')
    private selectedSnapshot?: Snapshot;

    @watch('routing.location.params.id')
    private orderOverviewSnapshotId?: string;

    @whitelabel()
    static get styles() {
        return [];
    }

    public render = (): TemplateResult => html`
        <style>
            :host {
                --opt-out--font-weight: 400;
                --opt-out--font-size: 1.2em;
                --opt-out--margin-top-bottom: 10px;
                --opt-out--margin-left-right: 0;
                --opt-out--margin: var(--opt-out--margin-top-bottom)
                    var(--opt-out--margin-left-right);

                display: flex;
                flex-direction: column;
            }

            button-component {
                --button-component--font-weight: var(--opt-out--font-weight);
                --button-component--font-size: var(--opt-out--font-size);
                --button-component--margin: var(--opt-out--margin);
                --button-component__secondary--border: 2px solid
                    var(--primary-color);
            }
            button-component:first-of-type {
                margin-top: calc(var(--opt-out--margin-top-bottom) * 2);
            }
            button-component:last-of-type {
                margin-bottom: 0;
            }
            #support {
                --button-component--height: fit-content;
            }
        </style>
        <button-component
            id="approve"
            label="${this.textApproved}"
            theme="primary"
            @click="${this.acceptTerms}"
            ?hidden=${this.hidden}
        ></button-component>
        <button-component
            id="support"
            label="${this.textSupport}"
            theme="secondary"
            @click="${this.contactSupport}"
            ?hidden=${this.hidden}
        ></button-component>
        <button-component
            id="decline"
            label="${this.textDeclined}"
            theme="text"
            @click="${this.declineTermsAndArchiveSnapshot}"
            ?hidden=${this.hidden}
        ></button-component>
    `;

    private acceptTerms = (e: Event): void => {
        e.stopImmediatePropagation();
        this.dispatchEvent(new CustomEvent('opt-out-accept') as CustomEvent);
        window.store.dispatch(
            uiOrderOverviewInteraction.run(uiUsageCheckAgreeClickedDataLayer()),
        );
    };

    private contactSupport = (e: Event): void => {
        window.store.dispatch(
            uiOrderOverviewInteraction.run(
                uiUsageSupportClickedDataLayer(`valid ${this.textSupport}`),
            ),
        );
        this.dispatchEvent(
            new CustomEvent('opt-out-contact-support') as CustomEvent,
        );
        this.closeModal(e);
    };

    private closeModal = (e: Event): void => {
        e.stopImmediatePropagation();
        this.dispatchEvent(new CustomEvent('opt-out-declined') as CustomEvent);
    };

    private declineTermsAndArchiveSnapshot = (e: Event): void => {
        this.closeModal(e);
        window.store.dispatch(
            uiOrderOverviewInteraction.run(
                uiUsageCheckDisagreeClickedDataLayer(),
            ),
        );
        if (!this.orderOverviewSnapshotId) {
            console.warn('Selected snapshot is missing');
            // todo clear store and redirect.
            return;
        }
        if (this.selectedSnapshot) {
            window.store.dispatch(
                archiveSnapshot.request(
                    {
                        sendEmail: true,
                        snapshotId: this.orderOverviewSnapshotId,
                        userId: this.selectedSnapshot.user_id,
                    } as ArchiveSnapshotRequestObject,
                    {} as RequestOptions,
                ),
            );
        }
    };
}

declare global {
    interface HTMLElementTagNameMap {
        'opt-out-buttons': OptOutButtons;
    }
}
