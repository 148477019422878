import { Resource } from './helpers/Resource';
import { Bearer } from './helpers/types/Bearer';
import { UUID } from './helpers/types/UUID';

/**
 * Store member management object for usage
 */
export class GetCompetitionComparisonStats extends Resource {
    /**
     * Action string
     * @return {string}
     */
    static get action() {
        return 'CompetitionComparisonStatsCompetition';
    }

    /**
     * Description string
     * @return {string}
     */
    static get description() {
        return 'Returns the stats of the snapshot vs the competition';
    }

    /**
     * Request method
     * @return {string}
     */
    static get method() {
        return 'get';
    }

    /**
     * Resource string
     * @return {string}
     */
    static get resource() {
        return 'competition';
    }

    /**
     * Route string
     * @return {string}
     */
    static get route() {
        return '/vendor/{vendor_id}/competition/{competition_id}/comparison_stats';
    }

    /**
     * Parameters object
     * @return {Object}
     */
    static get parameters() {
        return {
            vendor_id: {
                in: 'path',
                type: UUID,
                required: true,
            },
            competition_id: {
                in: 'path',
                type: UUID,
                required: true,
            },
            snapshot_id: {
                in: 'query',
                type: UUID,
                required: true,
            },
            'x-auth': {
                in: 'header',
                required: true,
                type: Bearer,
            },
        };
    }
}
