import {
    LitElement,
    TemplateResult,
    html,
    property,
    customElement,
} from 'lit-element';
import { whitelabel } from '@weavelab/whitelabel';

import style from './style';
import sharedStyles from '../../sharedStyles';

@customElement('price-compare')
@whitelabel({ name: 'price-compare' })
export class PriceCompare extends LitElement {
    static get styles() {
        return [sharedStyles, style];
    }

    @property({ type: String })
    public newPrice?: string;

    @property({ type: String })
    public oldPrice?: string;

    @property({ type: Boolean })
    public incl?: boolean = true;

    public render = (): TemplateResult => html`
        <p>
            <span class="old__price">${this.oldPrice}</span>
            <span class="new__price">${this.newPrice}</span> per maand
            ${this.incl ? 'incl.' : 'excl.'} BTW
        </p>
    `;
}

declare global {
    interface HTMLElementTagNameMap {
        'price-compare': PriceCompare;
    }
}
