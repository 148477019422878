import axios from 'axios';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
    REQUEST_CREATE_LOCATION,
    REQUEST_CREATE_INVOICE_LOCATION,
    requestCreateUserLocationSuccess,
    requestCreateLocationFailed,
    requestCreateInvoiceLocationSuccess,
    INVOICE_LOCATION,
    requestCreateInvoiceLocationFailed,
} from '../actions/userLocation';
import headers from './helpers/headers';

import { store } from '../store';

/**
 * Create userLocation fetcher
 * @param {Object} action
 */
function* fetchCreateUserLocation(action) {
    const state = store.getState();
    /** @type {String} */
    const userId = state.user.id;
    try {
        if (!userId) throw state.user;
        const result = yield call(async () => {
            const response = await axios.post(
                `/v1/user/${userId}/location/create`,
                { ...action.payload },
                {
                    baseURL: window.API_LINK,
                    headers: headers(STDHeaders),
                },
            );
            if (response.statusText === 'OK' || response.status === 200) {
                return { data: response.data };
            }
            throw response.status;
        });
        if (action.locationType === INVOICE_LOCATION) {
            yield put(requestCreateInvoiceLocationSuccess(result.data));
        } else {
            yield put(requestCreateUserLocationSuccess(result.data));
        }
    } catch (e) {
        if (action.locationType === INVOICE_LOCATION) {
            yield put(requestCreateInvoiceLocationFailed(e));
        } else {
            yield put(requestCreateLocationFailed(e));
        }
    }
}

/**
 * Watcher for REQUEST_CREATE_USER_LOCATION action
 */
export function* watchRequestCreateUserLocation() {
    yield takeLatest(REQUEST_CREATE_LOCATION, fetchCreateUserLocation);
}

/**
 * Watcher for REQUEST_CREATE_INVOICE_LOCATION action
 */
export function* watchRequestCreateInvoiceLocation() {
    yield takeLatest(REQUEST_CREATE_INVOICE_LOCATION, fetchCreateUserLocation);
}
