import { css } from 'lit-element';

export default css`
    :host {
        display: flex;
        flex-direction: column;
        padding: 20px 40px !important;
        font-family: var(--primary-font-family);
        overflow-y: auto;
        max-height: calc(100vh - 134px);
    }

    #monthly-pricing__header {
        display: flex;
        align-items: center;
    }

    @media only screen and (max-device-width: 480px) {
        header #monthly-pricing__header h1 {
            line-height: 1;
        }
    }

    tool-tip {
        --tooltip--margin: 20px 0 0 5px;
    }

    .power {
        vertical-align: super;
        font-size: 10px;
    }

    .monthly-pricing {
        font-weight: bold;
        color: var(--primary-color);
    }

    p {
        font-family: var(--primary-font-family);
        font-size: 13px;
        font-weight: 300;
        line-height: 1.46;
        color: var(--greyish);
        margin-top: 25px;
        max-width: 530px;
    }

    header h1 {
        font-family: var(--primary-font-family);
        font-size: 26px;
        font-weight: 400;
        line-height: 0.73;
        letter-spacing: -0.5px;
        color: var(--primary-color);
        margin-bottom: 0;
    }

    header p {
        font-family: var(--primary-font-family);
    }

    hv-faq#faq {
        margin: 40px 0;
    }

    @media screen and (max-width: 1025px) {
        hv-faq#faq {
            padding-bottom: 40px;
        }
    }
`;
