import * as actions from '../actions/usage';

/**
 * State reducer for Usage state
 * @param {Object} state
 * @param {Object} action
 * @return {Object}
 */
export const usage = (state = { labels: [] }, action) => {
    switch (action.type) {
        case actions.RECEIVE_USAGE:
            return {
                ...state,
                ...action.usage,
                failure: false,
                isFetching: false,
            };
        default:
            return state;
    }
};

export default usage;
