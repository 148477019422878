import { LitElement, html, property } from 'lit-element';
import { TemplateResult } from 'lit-html';

/**
 * This documentation comment will be shown when you hover your component imported in other files
 */
export class ErrorHandling extends LitElement {
    @property({ type: Boolean })
    public error: boolean = false;

    @property({
        attribute: 'error-message',
        reflect: true,
    })
    public errorMessage?: string;

    protected render = (): TemplateResult => {
        return html`
            <style>
                :host {
                    --error--font-size: 15px;
                    --error--color: #ff6e6e;
                    --error-margin: 10px 0 -10px 0;
                }
                .error-message {
                    font-family: inherit;
                    text-align: left;
                    font-size: var(--error--font-size);
                    color: var(--error--color);
                    margin: var(--error-margin);
                }
            </style>
            ${this.error
                ? html`
                      <p class="error-message">
                          ${this.errorMessage != null
                              ? this.errorMessage
                              : 'Verplicht veld'}
                      </p>
                  `
                : null}
        `;
    };
}

customElements.define('error-handling', ErrorHandling);
