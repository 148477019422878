import { css } from 'lit-element';

export default css`
    :host() {
        width: 100%;

        --text-component--background-color: var(--white);
        --text-component__border-radius: 5px;
        --text-component--min-height: 50px;
        --text-component__border-color: blue;
        --text-component--border: 1px solid var(--text-component__border-color);
        --text-component--color: #999;
        --text-component--font-size: 15px;
        --text-component__placeholder--color: #ddd;
        --text-component--padding: 0 20px;

        --warning-red: #f51e44;
    }
    :host {
        --text-component__input-suffix--width: 80px;
        --text-component__input-suffix--font-size: 12px;
    }
    .forgot-password {
        color: var(--primary-color);
        margin: 0;
        text-decoration: underline;
    }

    .input-container {
        -webkit-flex-direction: row; /* Safari 6.1+ */
        display: flex;
        flex-grow: 1;
        flex-direction: row;
        justify-content: flex-start;
        background-color: var(--text-component--background-color);
        align-items: flex-start;
        min-height: var(--text-component--min-height);
        border: var(--text-component--border);
        border-radius: var(--text-component__border-radius);
        position: relative;
    }

    .input-container .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        box-sizing: border-box;
        margin: 13px 10px 13px 13px;
        width: 24px;
        height: 24px;
    }

    .input-container > .icon > img {
        max-width: 24px;
        max-height: 24px;
        display: block;
        z-index: 10;
    }

    .input-container[theme='borderless'] input {
        padding-left: 0;
    }
    .input-container[theme='no-border'] {
        border-radius: 0;
        border: none;
    }

    .input-container input {
        height: 50px;
        border-radius: 5px;
        box-shadow: none;
    }

    .input-container .input-wrapper {
        display: flex;
        flex-grow: 1;
    }

    .input-container input:focus {
        outline: none;
    }
    .input-container[input=':focus'] .input-container {
        background-color: yellow;
    }

    .active {
        -moz-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        -webkit-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        box-shadow: 0 0 5px var(--ew-blue-navy-50-opacity);
    }

    .suffix {
        color: var(--text-component--color);
        padding: 10px 20px;
    }

    .input__suffix {
        color: var(--text-component--color);
        display: flex;
        justify-content: center;
        align-items: center;
        width: var(--text-component__input-suffix--width);
        background-color: var(--app-light-grey);
        font-size: var(--text-component__input-suffix--font-size);
    }

    .error-message {
        text-align: left;
        font-size: var(--text-component--font-size);
        color: var(--warning-red);
        margin-top: -10px;
    }

    input {
        padding: var(--text-component--padding);
        color: var(--text-component__color);
        font-size: var(--text-component--font-size);
        border: none;
        width: 100%;
    }

    .input-container[error] {
        border: 1px solid var(--warning-red);
    }

    input:focus + .suffix {
        color: var(--ew-blue-navy);
    }

    .center {
        margin: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    textarea {
        border: none;
        background-color: transparent;
        resize: none;
        outline: none;
        width: 100%;
        padding: 20px;
        font-size: var(--text-component--font-size);
        border-radius: 5px;
    }

    textarea,
    input {
        font-size: var(--text-component--font-size);
        color: var(--text-component--color);
    }

    textarea::placeholder,
    input::placeholder {
        color: var(--text-component__placeholder--color);
    }
`;
