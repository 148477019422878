import {
    CSSResultArray,
    LitElement,
    TemplateResult,
    customElement,
    property,
    query,
    PropertyValues,
} from 'lit-element';

import style from './style/style';

/**
 * The class contains the shared logic and attributes for grid components
 */
@customElement('grid-logic')
export class GridLogic extends LitElement {
    @property({ attribute: 'text-notation' })
    public textNotation?: string;

    @property({ type: Array })
    public items: any = [];

    @property()
    public path: string = '';

    @property()
    public id: string = '';

    @property()
    public header?: string;

    @property()
    public width?: string;

    @property({ type: Object })
    public formatter?: 'dateformatter' | object;

    @property({
        attribute: 'first-elm',
    })
    public firstElm?: string;

    @property({
        attribute: 'last-elm',
    })
    public lastElm?: string;

    @property({ attribute: false })
    public onRender?: (value: any, data: any) => TemplateResult;

    @property({ attribute: 'custom-style' })
    public customStyle?: string;

    @query('slot')
    private slotElement?: HTMLSlotElement;

    @property({
        type: Boolean,
    })
    public counter: boolean = false;

    @property({
        type: Boolean,
        reflect: true,
    })
    public border: boolean = false;

    @property({
        type: Boolean,
        reflect: true,
    })
    public compact: boolean = false;

    @property({ type: Boolean })
    public hidden = false;

    static get styles(): CSSResultArray {
        return [style];
    }

    public updateProps(compact: boolean, border: boolean): void {
        this.compact = compact;
        this.border = border;
    }

    public resetContent() {
        if (this.slotElement !== null) {
            const content = this.slotElement!.assignedElements();
            content.forEach((element) =>
                element.parentNode!.removeChild(element),
            );
        }
    }

    protected firstUpdated(_: PropertyValues) {
        // @ts-ignore
        const el: HTMLElement = (this.renderRoot! as HTMLElement).host;
        if (typeof this.width !== 'undefined') {
            el.style.width = `${this.width}px`;
            this.slotElement!.style.removeProperty('flex-grow');
        }
    }
}

declare global {
    interface HTMLElementTagNameMap {
        'grid-logic': GridLogic;
    }
}
