import { put, race, take, takeLatest } from 'redux-saga/effects';
import { Router } from '@vaadin/router';
import {
    REQUEST_OFFER,
    REQUEST_OFFER_SUCCESS,
    REQUEST_OFFER_FAILED,
} from '../actions/offer';
import { setOffer } from '../actions/order';
import {
    startCalculator,
    promptCalculator,
    finishCalculator,
} from '../actions/calculator';
import { store } from '../store';
import { routePaths } from '../helpers/routing';

/**
 * Runs calculator flow
 */
function* waitForOffers() {
    yield put(startCalculator());

    yield race([take(REQUEST_OFFER_SUCCESS), take(REQUEST_OFFER_FAILED)]);

    let state = store.getState();
    if (state.offers.error) {
        yield put(
            promptCalculator(
                [
                    state.geocode && state.geocode.data
                        ? state.geocode.data.street_name
                        : 'Naamloze straat',
                    state.order ? ` ${state.order.houseNumber}` : '',
                    state.order && state.order.houseNumberSuffix !== null
                        ? state.order.houseNumberSuffix
                        : '',
                    state.order ? `, ${state.order.postalCode}` : '',
                    state.geocode && state.geocode.data
                        ? ` ${state.geocode.data.city}`
                        : '',
                ].join(''),
            ),
        );
        return;
    }

    yield put(finishCalculator());

    // if proposition is set skip product list and set seelected offer(proposition).
    if (
        state.order &&
        state.order.proposition &&
        state.order.proposition.length
    ) {
        const proposition = state.order.proposition.split('/');
        let propositionChoice = 0;
        if (proposition.length > 1) {
            const [, pChoice] = proposition;
            propositionChoice = pChoice;
        }
        state = store.getState();
        // set offer in order object
        store.dispatch(setOffer(proposition[0], propositionChoice));
        // go to customer form to fill in the customer information
        Router.go(routePaths.customerForm);
    } else {
        Router.go(routePaths.customerProductList);
    }
}

/**
 * Watcher for REQUEST_OFFER action
 */
export function* watchRequestOfferFlow() {
    yield takeLatest(REQUEST_OFFER, waitForOffers);
}
