import { isBusy, notBusy } from '@actions/busy';
import { setMeteringPoints } from '@async-reducers/meteringPoint';
import { EanEvent, EanUsageType } from '@atoms/EANInput';
import '@elements/base/button/ButtonComponent';
import { LitElement, TemplateResult, html, property } from 'lit-element';
import { connect } from 'lit-redux-watch';
import { customElement } from 'lit/decorators/custom-element';
import { MeteringPoint } from 'types/meteringPoint';
import { Snapshot } from 'types/snapshot';
import style from './EanConfirmStyle';

@customElement('ean-confirm')
export class EanConfirm extends connect(window.store)(LitElement) {
    @property({ type: Array })
    public meteringPoints?: MeteringPoint[];

    @property({ type: Object })
    private snapshot?: Snapshot;

    @property({ type: String })
    private ean: string = '';

    @property({ type: Boolean })
    private showValidation: boolean = false;

    @property({ type: Boolean })
    private eanIsValid: boolean = false;

    @property({ type: String })
    usageType: EanUsageType = EanUsageType.ELEC;

    private eanCharLength: number = 18;

    static get styles() {
        return [style];
    }

    private onChangeEan(e: EanEvent) {
        const { ean } = e.detail;
        this.ean = ean;
        this.showValidation = false;

        const match = this.findMatch(ean);

        this.eanIsValid = !!(ean.length && match);
        if (this.eanIsValid || ean.length === this.eanCharLength) {
            this.showValidation = true;
        }
    }

    private onEanBlur() {
        this.showValidation = true;
    }

    private findMatch(ean: string) {
        return this.meteringPoints?.find(
            (m) => m.identifier === ean && m.description === this.usageType,
        );
    }

    private renderValidation() {
        if (!this.showValidation) {
            return html``;
        }

        if (this.eanIsValid) {
            return html`<div class="eaninput__helper eaninput__helper--success">
                De ingevulde EAN-code is gevonden.
            </div>`;
        }
        return html`<div class="eaninput__helper eaninput__helper--error">
            De ingevulde EAN-code is onjuist.
        </div>`;
    }

    // get the gas metering points for the given house addition
    private getGasMeteringPoints = (
        snapshot: Snapshot,
    ): {
        gasMetingPointsForHouseAddition: MeteringPoint[];
        error: boolean;
    } => {
        const houseInformation = `${snapshot.delivery_location.area_code} ${snapshot.delivery_location.house_number}`;
        const houseAddition = String(snapshot.delivery_location.house_addition);
        // get all the gas metering points
        const gasMeteringPoints = this.meteringPoints?.filter(
            (m: MeteringPoint) => m.description === EanUsageType.GAS,
        );

        let hasError = false;
        // filter the gas metering points for the given house addition for example: '', A, B, C
        const gasMetingPointsForHouseAddition = gasMeteringPoints?.filter(
            (m: MeteringPoint) => {
                // @ts-ignore
                const metaData = JSON.parse(m.metadata);
                // get the address from the metering point
                let address = metaData.EDSN_AddressSearch;
                // check if there is an extended address else it could be that the address is returned as extended address
                // the extended address is used when there is an existing and active contract for that meteringpoint in our system
                if (address == null && metaData.EDSN_AddressExtended != null) {
                    // if we find multiple extended addresses we should throw an error because we can't select the correct gas meteringpoint for the adress
                    if (metaData.EDSN_AddressExtended.length > 1) {
                        console.error(
                            `Multiple gas metering points found for the extended house addition ${houseInformation}: ${houseAddition}`,
                        );
                        window.displayMessage(
                            'Voor dit adres zijn meerdere gas aansluitingen gevonden, neem contact op met de klantenservice.',
                            'error',
                        );
                        hasError = true;
                        return false;
                    }
                    // eslint-disable-next-line prefer-destructuring
                    address = metaData.EDSN_AddressExtended[0];
                }

                // check for metering points when house addition is an empty string
                if (houseAddition === '') {
                    return address?.ExBuildingNr == null;
                }

                // check for metering points with the same house addition called ExBuildingNr and take into account that ExBuildingNr can be undefined
                return String(address?.ExBuildingNr) === houseAddition;
            },
        );

        // only happens when there are multiple existing and active gas metering points found
        if (hasError) {
            return { gasMetingPointsForHouseAddition: [], error: true };
        }

        // check if we found a gas metering point, else return an error?
        // TODO: check what if there is only a electricity metering point found?
        // gas only when gas is true
        if (!gasMetingPointsForHouseAddition?.length) {
            console.error(
                'No gas metering points are found for the house addition',
            );
            window.displayMessage(
                'Voor dit adres zijn geen gas meternummers gevonden, neem contact op met de klantenservice.',
                'error',
            );
            return { gasMetingPointsForHouseAddition: [], error: true };
        }

        // check if we found more than one gas metering point,
        // then return an error because we cant select the correct gas meteringpoint and they should contact support?
        if (gasMetingPointsForHouseAddition?.length > 1) {
            console.error(
                `Multiple gas metering points found for the house addition ${houseInformation}: ${houseAddition}`,
            );
            window.displayMessage(
                'Voor dit adres zijn meerdere gas meternummers gevonden, neem contact op met de klantenservice.',
                'error',
            );
            return { gasMetingPointsForHouseAddition: [], error: true };
        }

        return {
            gasMetingPointsForHouseAddition,
            error: false,
        };
    };

    private setMeteringPoints() {
        window.store.dispatch(isBusy('setting metering points...'));

        if (!this.snapshot) {
            console.warn('Unable to set metering points, snapshot is missing');
            window.displayMessage(
                'Er is iets misgegaan bij het zetten van de meetpunten',
                'error',
            );
            window.store.dispatch(notBusy());
            return;
        }

        // todo: ask leon why this is happening here?
        if (!this.ean) {
            window.displayMessage('Gas EAN-code is niet gevonden.', 'error');
            window.store.dispatch(notBusy());
            return;
        }
        const selectedMetering = this.meteringPoints?.find(
            (m: MeteringPoint) => m.identifier === this.ean,
        );

        if (!selectedMetering) {
            window.displayMessage(
                'Filled in EAN-code is niet gevonden.',
                'error',
            );
            window.store.dispatch(notBusy());
            return;
        }

        const meteringPoints = [];
        if (this.snapshot.user_snapshot_payload.has_gas) {
            const { error, gasMetingPointsForHouseAddition } =
                this.getGasMeteringPoints(this.snapshot);

            // if there is an error with the gas metering points we should return
            if (error) {
                window.store.dispatch(notBusy());
                return;
            }

            if (gasMetingPointsForHouseAddition[0]) {
                meteringPoints.push(gasMetingPointsForHouseAddition[0].id);
            }
        }
        meteringPoints.push(selectedMetering.id);

        window.store.dispatch(
            setMeteringPoints.run(this.snapshot.id, meteringPoints),
        );
    }

    public render(): TemplateResult {
        return html`
            <div>
                <ean-input
                    .meteringPoints="${this.meteringPoints}"
                    .usageType="${this.usageType}"
                    @ean="${this.onChangeEan}"
                    @blur="${this.onEanBlur}"
                    .maxLength=${this.eanCharLength}
                >
                </ean-input>
                ${this.renderValidation()}
                <button-component
                    id="confirm-btn"
                    label="EAN-code bevestigen"
                    theme="primary"
                    .disabled="${!this.eanIsValid}"
                    custom-style="width:100%;"
                    @click="${this.setMeteringPoints}"
                ></button-component>
            </div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        'ean-confirm': EanConfirm;
    }
}
