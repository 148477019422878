import { getUsages, Usages } from '@async-reducers/usages';
import { takeLatest, ForkEffect } from 'redux-saga/effects';
import { SuccessAction } from 'goagen-js-resource';
import { firstDayPreviousMonth } from 'helpers/dates';

/**
 * checks if the current month has usages if not it fetches the privious month usages.
 */
const checkCurrentMonthUsages: (result: SuccessAction) => void = (
    result: SuccessAction,
): void => {
    const { data }: { data: Usages } = result;
    const { totalElec, totalGas, totalProd } = data;
    // check if totals are null for the current month
    if (totalElec == null && totalGas == null && totalProd == null) {
        const params = data.requestObject;
        const today = new Date(Date.now());

        const till = new Date(params.dateTill);
        // check to only continue if the previous request is the same month
        if (today.getMonth() !== till.getMonth()) {
            return;
        }

        params.dateTill = params.dateFrom;
        // set the start date to the first day of the privous month to fetch the privious month usages
        params.dateFrom = firstDayPreviousMonth(
            new Date(params.dateTill),
        ).toISOString();
        window.store.dispatch(getUsages.run(params));
    }
};

/**
 * Watcher for getUsages action
 */
export function* watchUsages(): IterableIterator<ForkEffect> {
    yield takeLatest(
        [getUsages.actionTypeMap.callback],
        checkCurrentMonthUsages,
    );
}
