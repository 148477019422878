export const REQUEST_COST_VALUES = 'REQUEST_COST_VALUES';
/**
 * Action generator for request
 * @param {Number} intervalType
 * @param {String} snapshotID
 * @param {String} dateFrom
 * @param {String} dateTill
 * @return {Object}
 */
export const requestCostValues = (
    intervalType,
    snapshotID,
    dateFrom,
    dateTill,
) => ({
    type: REQUEST_COST_VALUES,
    intervalType,
    snapshotID,
    dateFrom,
    dateTill,
});

export const REQUEST_COST_VALUES_SUCCESS = 'REQUEST_COST_VALUES_SUCCESS';
/**
 * Success callback action
 * @param {Number} cur_month_real
 * @param {Number} cur_month_estimation
 * @param {Number} savings_till_now_excl_cur_month
 * @param {Number} year_savings
 * @param {Date} tillDate
 * @param {Object} requestObject
 * @return {Object}
 */
export const requestCostValuesSuccess = (
    curMonthReal,
    savingsTillNowExclCurMonth,
    yearSavings,
    tillDate,
    requestObject,
) => ({
    type: REQUEST_COST_VALUES_SUCCESS,
    cur_month_real: curMonthReal,
    savings_till_now_excl_cur_month: savingsTillNowExclCurMonth,
    year_savings: yearSavings,
    tillDate,
    requestObject,
});

export const REQUEST_COST_VALUES_FAILED = 'REQUEST_MONTHLY_VALUES_FAILED';
/**
 * Error callback action
 * @param {Object} error
 * @return {Object}
 */
export const requestCostValuesFailed = (error) => ({
    type: REQUEST_COST_VALUES_FAILED,
    error,
});
