import { all } from 'redux-saga/effects';
import { goToNextPhase, userAgreesSnapshot } from './snapshot';
import { orderRetentionOffer } from '../actions/orderRetentionOffer';
import {
    recalculationModalErrorOpener,
    recalculationModalOpener,
} from '../core/store/snapshot/sagas/offerRecalculation';
import {
    watchInvalidRequestRecalculationOffer,
    watchInvalidRequestRecalculationOfferError,
    watchRequestAcceptRecalculationOffer,
    watchRequestAcceptRecalculationOfferError,
    watchRequestRecalculationOffer,
    watchRequestRecalculationOfferError,
} from '../core/store/snapshot/sagas/watchOfferRecalculation';
import { watchOrderRetentionOffer } from '../sagas/orderRetentionOffer';
import {
    watchDeleteVacation,
    watchRefetchWorkSchedule,
    watchVactionRefetchWorkSchedule,
} from '../sagas/schedule';
import { authCheck, receiveSelf, signIn } from './authentication';
import { geoCodeCheck, geoCodeEDSNCheck } from './geocode';
import { setIconFamily } from './icon';
import { acceptOda, initOda, setOdaOnboarding, verifyOda } from './oda';
import {
    createVacation,
    createWorkday,
    createWorkSchedule,
    deleteVacation,
    deleteWorkday,
    getCurrentSchedule,
    getListWorkdays,
    getScheduleVacationsList,
    updateVacation,
} from './schedule';
import { getUsages } from './usages';
import { changePassword, forgotPassword, resetPassword } from './user';
import {
    vehicleAuthenticate,
    vehicleGet,
    vehicleList,
    vehicleStartStopCharging,
} from './vehicle';
import { vendorConfig } from './vendor';
import { setStartDate } from './startDate';
import { watchChangePassword } from '../core/store/user/sagas/password';
import {
    getMeteringPoints,
    hasConventionalMeter,
    setMeteringPoints,
    sendMeteringPointsReminderMail,
} from './meteringPoint';
import { showOnboarding } from './settings';
import {
    ListCostReports,
    ListCostReportsEstimations,
    CostReportsWithEstimations,
} from './costReports';
import { ListBroadcasts } from './broadcasts';
import { setPurchase } from './purchase';
import { watchIfMeteringPointsSet } from '../sagas/meteringpoint';

interface AsyncReducers {
    getCurrentSchedule: typeof getCurrentSchedule.reducer;
    getListWorkdays: typeof getListWorkdays.reducer;
    getScheduleVacationsList: typeof getScheduleVacationsList.reducer;
    createWorkSchedule: typeof createWorkSchedule.reducer;
    createVacation: typeof createVacation.reducer;
    createWorkday: typeof createWorkday.reducer;
    deleteVacation: typeof deleteVacation.reducer;
    deleteWorkday: typeof deleteWorkday.reducer;
    updateVacation: typeof updateVacation.reducer;
    resetPassword: typeof resetPassword.reducer;
    forgotPassword: typeof forgotPassword.reducer;
    recalculationModalOpener: typeof recalculationModalOpener.reducer;
    recalculationModalErrorOpener: typeof recalculationModalErrorOpener.reducer;
    orderRetentionOffer: typeof orderRetentionOffer.reducer;
    vendorConfig: typeof vendorConfig.reducer;
    vehicleAuthenticate: typeof vehicleAuthenticate.reducer;
    vehicleGet: typeof vehicleGet.reducer;
    vehicleList: typeof vehicleList.reducer;
    vehicleStartStopCharging: typeof vehicleStartStopCharging.reducer;
    setOdaOnboarding: typeof setOdaOnboarding.reducer;
    acceptOda: typeof acceptOda.reducer;
    geoCodeCheck: typeof geoCodeCheck.reducer;
    geoCodeEDSNCheck: typeof geoCodeEDSNCheck.reducer;
    setIconFamily: typeof setIconFamily.reducer;
    getUsages: typeof getUsages.reducer;
    verifyOda: typeof verifyOda.reducer;
    initOda: typeof initOda.reducer;
    signIn: typeof signIn.reducer;
    receiveSelf: typeof receiveSelf.reducer;
    authCheck: typeof authCheck.reducer;
    setStartDate: typeof setStartDate.reducer;
    changePassword: typeof changePassword.reducer;
    hasConventionalMeter: typeof hasConventionalMeter.reducer;
    userAgreesSnapshot: typeof userAgreesSnapshot.reducer;
    showOnboarding: typeof showOnboarding.reducer;
    listCostReports: typeof ListCostReports.reducer;
    ListCostReportsEstimations: typeof ListCostReportsEstimations.reducer;
    CostReportsWithEstimations: typeof CostReportsWithEstimations.reducer;
    ListBroadcasts: typeof ListBroadcasts.reducer;
    setPurchase: typeof setPurchase.reducer;
    setMeteringPoints: typeof setMeteringPoints.reducer;
    goToNextPhase: typeof goToNextPhase.reducer;
    getMeteringPoints: typeof getMeteringPoints.reducer;
    sendMeteringPointsReminderMail: typeof sendMeteringPointsReminderMail.reducer;
}

export const asyncReducers: AsyncReducers = {
    getCurrentSchedule: getCurrentSchedule.reducer,
    getListWorkdays: getListWorkdays.reducer,
    getScheduleVacationsList: getScheduleVacationsList.reducer,
    createWorkSchedule: createWorkSchedule.reducer,
    createVacation: createVacation.reducer,
    createWorkday: createWorkday.reducer,
    deleteVacation: deleteVacation.reducer,
    deleteWorkday: deleteWorkday.reducer,
    updateVacation: updateVacation.reducer,
    resetPassword: resetPassword.reducer,
    forgotPassword: forgotPassword.reducer,
    recalculationModalOpener: recalculationModalOpener.reducer,
    recalculationModalErrorOpener: recalculationModalErrorOpener.reducer,
    orderRetentionOffer: orderRetentionOffer.reducer,
    vendorConfig: vendorConfig.reducer,
    vehicleAuthenticate: vehicleAuthenticate.reducer,
    vehicleGet: vehicleGet.reducer,
    vehicleList: vehicleList.reducer,
    vehicleStartStopCharging: vehicleStartStopCharging.reducer,
    setOdaOnboarding: setOdaOnboarding.reducer,
    acceptOda: acceptOda.reducer,
    geoCodeCheck: geoCodeCheck.reducer,
    geoCodeEDSNCheck: geoCodeEDSNCheck.reducer,
    setIconFamily: setIconFamily.reducer,
    getUsages: getUsages.reducer,
    verifyOda: verifyOda.reducer,
    initOda: initOda.reducer,
    signIn: signIn.reducer,
    receiveSelf: receiveSelf.reducer,
    authCheck: authCheck.reducer,
    setStartDate: setStartDate.reducer,
    changePassword: changePassword.reducer,
    hasConventionalMeter: hasConventionalMeter.reducer,
    userAgreesSnapshot: userAgreesSnapshot.reducer,
    showOnboarding: showOnboarding.reducer,
    listCostReports: ListCostReports.reducer,
    ListCostReportsEstimations: ListCostReportsEstimations.reducer,
    CostReportsWithEstimations: CostReportsWithEstimations.reducer,
    ListBroadcasts: ListBroadcasts.reducer,
    setPurchase: setPurchase.reducer,
    setMeteringPoints: setMeteringPoints.reducer,
    goToNextPhase: goToNextPhase.reducer,
    getMeteringPoints: getMeteringPoints.reducer,
    sendMeteringPointsReminderMail: sendMeteringPointsReminderMail.reducer,
};

export function* asyncSagas() {
    yield all([
        getCurrentSchedule.saga(),
        getListWorkdays.saga(),
        getScheduleVacationsList.saga(),
        createWorkSchedule.saga(),
        createVacation.saga(),
        createWorkday.saga(),
        deleteVacation.saga(),
        acceptOda.saga(),
        verifyOda.saga(),
        initOda.saga(),
        deleteWorkday.saga(),
        updateVacation.saga(),
        resetPassword.saga(),
        forgotPassword.saga(),
        vendorConfig.saga(),
        vehicleAuthenticate.saga(),
        vehicleGet.saga(),
        vehicleList.saga(),
        vehicleStartStopCharging.saga(),
        setOdaOnboarding.saga(),
        geoCodeCheck.saga(),
        geoCodeEDSNCheck.saga(),
        getUsages.saga(),
        signIn.saga(),
        receiveSelf.saga(),
        authCheck.saga(),
        setStartDate.saga(),
        hasConventionalMeter.saga(),
        changePassword.saga(),
        userAgreesSnapshot.saga(),
        showOnboarding.saga(),
        ListCostReports.saga(),
        ListCostReportsEstimations.saga(),
        CostReportsWithEstimations.saga(),
        ListBroadcasts.saga(),
        setPurchase.saga(),
        watchRefetchWorkSchedule(),
        watchVactionRefetchWorkSchedule(),
        watchDeleteVacation(),
        watchRequestAcceptRecalculationOffer(),
        watchRequestRecalculationOffer(),
        watchRequestRecalculationOfferError(),
        watchChangePassword(),
        watchRequestAcceptRecalculationOfferError(),
        watchInvalidRequestRecalculationOffer(),
        watchInvalidRequestRecalculationOfferError(),
        watchOrderRetentionOffer(),
        setMeteringPoints.saga(),
        goToNextPhase.saga(),
        watchIfMeteringPointsSet(),
        getMeteringPoints.saga(),
        sendMeteringPointsReminderMail.saga(),
    ]);
}
