import { customElement } from 'lit-element';
import { MaxLength, Required } from '@lion/form-core';
import { whitelabel } from '@weavelab/whitelabel';
import { loginHook } from 'helpers/loginHook';
import { Form } from './Form';
import { CustomEventValueTrimmer } from './helpers/value';
import { IsEmailWithTrim } from './validators/EmailWithTrim';

@customElement('ice-login')
@whitelabel({ name: 'ice-login' })
export class Login extends Form {
    @whitelabel()
    public static showLabel?: boolean;

    @whitelabel()
    public static styleObject?: Partial<CSSStyleDeclaration>;

    @whitelabel()
    public static placeholder?: Partial<{
        email: string | boolean;
        password: string | boolean;
    }>;

    @whitelabel()
    public static label?: Partial<{
        email: string;
        password: string;
        submit: string;
    }>;

    public connectedCallback(): void {
        super.connectedCallback();

        this.style.width = '100%';

        Object.keys(Login.styleObject ?? {}).forEach((k: string) => {
            // @ts-ignore
            this.style[k] = Login.styleObject[k];
        });
    }

    protected form = [
        Form.field('username', 'email', Login.label?.email ?? 'E-mailadres', {
            label: Login.showLabel ?? false,
            placeholder: Login.placeholder?.email ?? 'naam@domein.nl',
            validators: [
                new IsEmailWithTrim(null, {
                    getMessage: async () => 'E-mailadres is onjuist',
                }),
                new MaxLength(320, {
                    getMessage: async () =>
                        'Het e-mailadres mag niet langer zijn dan 320 tekens',
                }),
                new Required(null, {
                    getMessage: async () => 'Verplicht veld',
                }),
            ],
            listener: CustomEventValueTrimmer,
        }),
        Form.field(
            'current-password',
            'password',
            Login.label?.password ?? 'Wachtwoord',
            {
                label: Login.showLabel ?? false,
                placeholder: Login.placeholder?.password ?? true,
                validators: [
                    new MaxLength(512, {
                        getMessage: async () =>
                            'Het wachtwoord mag niet langer zijn dan 512 tekens',
                    }),
                    new Required(null, {
                        getMessage: async () => 'Verplicht veld',
                    }),
                ],
            },
        ),
        Form.button('submit', Login.label?.submit ?? 'Inloggen'),
    ];

    // eslint-disable-next-line class-methods-use-this
    protected async submit(values: {
        email: string;
        password: string;
    }): Promise<void> {
        await loginHook.onLogin(values.email, values.password);
    }
}

declare global {
    interface HTMLElementTagNameMap {
        'ice-login': Login;
    }
}
