import enums from 'enums';
import { Snapshot } from 'types/snapshot';
import { ContactType } from '../Contract';
import { ContractBase } from './BaseContract';

export class SmartChargingContract extends ContractBase {
    public currentPhase: number =
        enums.SnapshotPhaseSmartChargingContractCreated;
    public contractType: ContactType = 'SMART_CHARGING';

    constructor(public snapshot: Snapshot) {
        super();
    }
}
