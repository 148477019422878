import {
    LitElement,
    TemplateResult,
    customElement,
    html,
    property,
    PropertyValues,
} from 'lit-element';

import { repeat } from 'lit-html/directives/repeat';

import '../elements/base/button/ButtonComponent';
import { connect, watch } from 'lit-redux-watch';
import './HvNotificationComponent';
import { deleteAllNotifications } from '../../actions/notification';
import { NotificationState } from 'src/reducers/notification';
import { whitelabel } from '@weavelab/whitelabel';
import { ifDefined } from 'lit-html/directives/if-defined';
import { RoutingState } from '../../reducers/routing';

@customElement('hv-notification-manager')
export class NotificationsManager extends connect(window.store)(LitElement) {
    @watch('notification')
    public notifications: NotificationState[] = [];

    @watch('routing')
    public routing: RoutingState = {
        page: '',
        url: '/',
    };

    @property()
    activeTimers: number[] = [];

    @property({ type: Number })
    defaultNotificationDuration: number = 5000;

    @whitelabel()
    public static defaultNotificationInformation: string =
        'Vanwege de sterk toegenomen energieprijzen op de huidige energiemarkt kunnen we een nieuw energiecontract met een 1 of meerjarige looptijd pas in laten gaan op of na 1 januari 2022.';

    @property({ type: Number })
    public maxNotifications: number = 2;

    protected updated(changedProperties: PropertyValues) {
        if (changedProperties.has('notifications')) {
            // if the array is longer than allowed, pop elements till max notification is reached
            for (let i = 0; i < this.notifications.length; i++) {
                const notification = this.notifications[i];
                if (
                    this.notifications.length > this.maxNotifications &&
                    i < this.notifications.length - this.maxNotifications
                ) {
                    // delete specific notification here by index or id
                    window.store.dispatch(deleteAllNotifications());
                }
                // if notification is not fixed, we can add it to the active timers and set a timeout for removal
                if (
                    !notification.duration?.fixed &&
                    !this.activeTimers.includes(i)
                ) {
                    this.activeTimers.push(i);
                    setTimeout(() => {
                        // delete specific notification here by index or id
                        window.store.dispatch(deleteAllNotifications());
                        this.activeTimers.splice(i, 1);
                    }, notification.duration?.ms || this.defaultNotificationDuration);
                }
            }
        }
    }

    public render = (): TemplateResult => {
        return html` <style>
                :host {
                    display: flex;
                    flex-direction: column;
                }
            </style>
            ${repeat(
                this.notifications,
                (notification) => notification.information,
                (item, _) => {
                    const hideBasedOnRoute =
                        item.routePaths &&
                        !item.routePaths.includes(this.routing.url);
                    return html` <hv-notification
                        .hidden="${hideBasedOnRoute}"
                        hide-based-on-route="${hideBasedOnRoute}"
                        .callback=${ifDefined(item.cta?.callback)}
                        cta-text="${ifDefined(item.cta?.text)}"
                        type="${item.notificationBarType}"
                    >
                        ${item.information}
                    </hv-notification>`;
                },
            )}`;
    };
}

declare global {
    interface HTMLElementTagNameMap {
        'hv-notification-manager': NotificationsManager;
    }
}
