import { Capacitor } from '@capacitor/core';
import { Filesystem, Directory, WriteFileResult } from '@capacitor/filesystem';
import { FileOpener } from '@capacitor-community/file-opener';

const writeSecretFile = async (fileName: string, fileData: any) => {
    // android storage
    let dir = Directory.Data;
    // ios storage
    if (Capacitor.getPlatform() === 'ios') {
        dir = Directory.Documents;
    }
    return Filesystem.writeFile({
        path: fileName,
        data: fileData,
        directory: dir,
    });
};

const openFile = async (getUriResult: WriteFileResult) => {
    const path = getUriResult.uri;
    FileOpener.open({ filePath: path, contentType: 'application/pdf' }).catch(
        (error) => console.warn('Error openening file', error),
    );
};

// download function that writes a pdf and opens the pdf on devices
export const download = (fileName: string, pdfBase6f: string) => {
    document.addEventListener('deviceready', () => {
        if (Capacitor.isNativePlatform()) {
            // Save the File to the device then open te File
            writeSecretFile(fileName, pdfBase6f).then(openFile);
        }
    });
};

const handleError = (errorMessage: string) => {
    console.warn(errorMessage);
    window.displayMessage(
        'Het downloaden van het bestand is niet gelukt.',
        'error',
    );
};

// handleDownload handles the download on mobile devices
export const handleDownload = (event: MouseEvent) => {
    if (event == null) {
        handleError('download event is null');
        return;
    }

    const elem = event.target;
    if (!elem) {
        handleError(`download event doesn't exist`);
        return;
    }
    while (elem !== document) {
        const el = elem as HTMLElement;
        let downloadName = 'document.pdf';
        const downloadElem = el.getAttribute('download');
        if (downloadElem != null) {
            downloadName = downloadElem;
        }
        event.preventDefault();
        const href = el.getAttribute('href');
        if (href == null) {
            handleError('download element href is null');
            return;
        }

        if (href.slice(0, 5) === 'data:') {
            download(downloadName, href);
            return;
        }

        fetch(href).then((response) =>
            download(downloadName, response as unknown as string),
        );
    }
};
