/**
 * Adds a non script tag to the document head based on the given source url
 * @returns {boolean} true when script has been added or false when script already exists
 * */
export function insertScriptElement(
    url: string,
    // eslint-disable-next-line no-undef
    position: InsertPosition = 'afterbegin',
): boolean {
    const sanitizedUrl = url.split(/[?#]/)[0]; // removes query string and hash
    let scriptElement = document.head.querySelector<HTMLScriptElement>(
        `script[src~="${sanitizedUrl}"]`,
    );

    if (!scriptElement) {
        scriptElement = document.createElement('script');
        scriptElement.type = 'text/javascript';
        scriptElement.src = url;
        document.head.insertAdjacentElement(position, scriptElement);

        return true;
    }

    return false;
}
