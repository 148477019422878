import {
    Bearer,
    BearerSupportedTypes,
    ParameterDefinitionsObject,
    ParameterIn,
    RequestAction,
    RequestOptions,
    RequestSagaIterableIterator,
    ResolvableOrType,
    Resource,
    ResourceOptions,
} from 'goagen-js-resource';
import { Method } from 'axios';

export interface SettleMonthlyWorkdaysScheduleRequestObject {
    // Injected Bearer header (Bearer)
    xAuth?: BearerSupportedTypes;
}

export interface ResolvableSettleMonthlyWorkdaysScheduleRequestObject {
    // Injected Bearer header (Bearer)
    xAuth?: ResolvableOrType<
        SettleMonthlyWorkdaysScheduleRequestObject['xAuth']
    >;
}

/**
 * Store member management object for settleMonthlyWorkdays schedule
 */
export class SettleMonthlyWorkdaysSchedule extends Resource<
    SettleMonthlyWorkdaysScheduleRequestObject,
    ResolvableSettleMonthlyWorkdaysScheduleRequestObject
> {
    /**
     * Create new SettleMonthlyWorkdaysSchedule
     *
     * | Parameter |  | Target |  |  |
     * | --- | --- | --- | --- | --- |
     * | `xAuth` | `Bearer` | header | `X-Auth` | Injected Bearer header |
     */
    constructor(
        storeName: string,
        options: ResourceOptions<ResolvableSettleMonthlyWorkdaysScheduleRequestObject> = {},
    ) {
        super(storeName, options);
    }

    /**
     * Generate request action
     *
     * | Parameter |  | Target |  |  |
     * | --- | --- | --- | --- | --- |
     * | `xAuth` | `Bearer` | header | `X-Auth` | Injected Bearer header |
     */
    public request(
        requestObject: SettleMonthlyWorkdaysScheduleRequestObject = {},
        requestOptions: RequestOptions = {},
    ): RequestAction<SettleMonthlyWorkdaysScheduleRequestObject> {
        return super.request(requestObject, requestOptions);
    }

    /**
     * Run request as saga
     *
     * | Parameter |  | Target |  |  |
     * | --- | --- | --- | --- | --- |
     * | `xAuth` | `Bearer` | header | `X-Auth` | Injected Bearer header |
     */
    // @ts-ignore
    public get requestSaga(): (
        requestObject?: SettleMonthlyWorkdaysScheduleRequestObject,
        requestOptions?: RequestOptions,
    ) => RequestSagaIterableIterator<
        RequestAction<SettleMonthlyWorkdaysScheduleRequestObject>
    > {
        return super.requestSaga;
    }

    /**
     * Action string
     */
    static get action(): string {
        return 'settleMonthlyWorkdays';
    }

    /**
     * Description string
     */
    static get description(): string {
        return 'Will settle all workdays in a month';
    }

    /**
     * Request method
     */
    static get method(): Method {
        return 'post';
    }

    /**
     * Resource string
     */
    static get resource(): string {
        return 'schedule';
    }

    /**
     * Route string
     */
    static get route(): string {
        return '/schedule/settle';
    }

    /**
     * Unique name string
     */
    static get uniqueName(): string {
        return 'SettleMonthlyWorkdaysSchedule';
    }

    /**
     * Parameters object
     */
    static get parameters(): ParameterDefinitionsObject {
        return {
            xAuth: {
                description: 'Injected Bearer header',
                in: ParameterIn.header,
                name: 'X-Auth',
                type: Bearer,
            },
        };
    }
}
