export const PREFIX = 'Enroll: ';

/** @typedef {import('./user').CreateUserPayload} CreateUserPayload */
/** @typedef {import('./userLocation').CreateLocationPayload} CreateLocationPayload */
/** @typedef {import('./company').CreateCompanyPayload} CreateCompanyPayload */
/** @typedef {import('./userPaymentAccount').CreateUserPaymentAccountPayload} CreateUserPaymentAccountPayload */

export const START_ENROLL = `${PREFIX}START_ENROLL`;
/**
 * Start enrollment process
 * @param {CreateUserPayload} user
 * @param {CreateLocationPayload} deliveryLocation
 * @param {CreateLocationPayload} invoiceLocation
 * @param {CreateCompanyPayload} company
 * @param {CreateUserPaymentAccountPayload} paymentAccount
 * @param {Object} snapshot
 * @return {Object}
 */
export const startEnroll = (
    user,
    deliveryLocation,
    invoiceLocation,
    company,
    paymentAccount,
    snapshot,
) => ({
    type: START_ENROLL,
    user,
    deliveryLocation,
    invoiceLocation,
    company,
    paymentAccount,
    snapshot,
});

export const RESUME_ENROLL = `${PREFIX}RESUME_ENROLL`;
/**
 * Start enrollment process
 * @param {CreateLocationPayload} deliveryLocation
 * @param {CreateCompanyPayload} company
 * @param {CreateUserPaymentAccountPayload} paymentAccount
 * @param {Object} snapshot
 * @return {Object}
 */
export const resumeEnroll = (
    deliveryLocation,
    company,
    paymentAccount,
    snapshot,
) => ({
    type: START_ENROLL,
    deliveryLocation,
    company,
    paymentAccount,
    snapshot,
});

export const FINISH_ENROLL = `${PREFIX}FINISH_ENROLL`;
/**
 * Finish enrollment process
 * @return {Object}
 */
export const finishEnroll = () => ({ type: FINISH_ENROLL });

export const RESET_ENROLL = `${PREFIX}RESET_ENROLL`;
/**
 * Reset all enrollment helper reducers
 * @return {Object}
 */
export const resetEnroll = () => ({ type: RESET_ENROLL });

export const PROMPT_ENROLL = `${PREFIX}PROMPT_ENROLL`;
/**
 * Prompt user to fix errors
 * @return {Object}
 */
export const promptEnroll = () => ({ type: PROMPT_ENROLL });
