import axios from 'axios';
import { call, put, takeLatest } from 'redux-saga/effects';
import { store } from '../store';
import {
    REQUEST_CREATE_USER,
    requestCreateUserSuccess,
    requestCreateUserFailed,
    setUser,
    authActionStart,
} from '../actions/user';
import headers from './helpers/headers';

/**
 * Create user fetcher
 * @param {Object} action
 */
function* fetchCreateUser(action) {
    try {
        store.dispatch(authActionStart);
        const result = yield call(async () => {
            const response = await axios.post(
                `/v1/user/create/${action.vendorId}?user_type=${action.userType}`,
                { ...action.payload },
                {
                    baseURL: window.API_LINK,
                    headers: headers(STDHeaders),
                },
            );
            if (response.statusText === 'OK' || response.status === 200) {
                const bearer = response.headers.authorization;
                if (bearer) {
                    window.STDHeaders.set('X-Auth', bearer);
                    localStorage.setItem('auth', bearer);
                    return { data: response.data };
                }
                throw response.headers;
            } else {
                throw response.status;
            }
        });
        yield put(requestCreateUserSuccess(result.data));
        // @ts-ignore
        yield put(setUser(result.data));
    } catch (e) {
        yield put(requestCreateUserFailed(e));
    }
}

/**
 * Watcher for REQUEST_CREATE_USER action
 */
export function* watchRequestCreateUser() {
    yield takeLatest(REQUEST_CREATE_USER, fetchCreateUser);
}
