import { css } from '@lion/core';

export default css`
    :host {
        --usage-arrow-icon-size: 32px;
        --usage-background-color: var(--component-background-color, #e6f1f8);
        --usage-border-radius: var(--border-radius);
        --usage-cap-font-size: 0.6em;
        --usage-cost-first-margin: 8px 0 0 0;
        --usage-cost-last-margin: 22px;
        --usage-cost-margin: 8px 0px;
        --usage-cost-padding: 12px 24px;
        --usage-cost-specification-background-color: #ffffff;
        --usage-cost-specification-tool-tip-margin: 0 0 0 6px;
        --usage-font-family: var(--primary-font-family, sans-serif);
        --usage-font-size: 20px;
        --usage-font-weight: 700;
        --usage-header-arrow-color: #0096da;
        --usage-icon-background-color: #ffffff;
        --usage-icon-border: 2px solid #d8e9f4;
        --usage-icon-margin: 0 4px 0 0;
        --usage-icon-size: 32px;
        --usage-margin: 0 18px 24px;
        --usage-min-height: 58px;
        --usage-padding: 0 12px;
        --usage-price-margin: 0 8px 0 0;
        --usage-primary-color: #003d86;
        --usage-tool-tip-size: 14px;

        background-color: var(--usage-background-color);
        border-radius: var(--usage-border-radius);
        display: flex;
        flex-direction: column;
        font-family: var(--usage-font-family);
        font-size: var(--usage-font-size);
        font-weight: var(--usage-font-weight);
        justify-content: center;
        margin: var(--usage-margin);
        padding: var(--usage-padding);
        max-width: 462px;
        margin: 0 auto;
        min-width: 282px;
        margin-bottom: 20px;
    }

    /**
     * Header
     */
    header {
        align-items: center;
        color: var(--usage-primary-color);
        cursor: pointer;
        display: flex;
        flex-direction: row;
        flex-flow: row nowrap;
        min-height: var(--usage-min-height);
        user-select: none;
    }

    header #description {
        display: flex;
        flex-grow: 1;
        justify-content: space-between;
        width: inherit;
    }

    header #usage-icon {
        border-radius: 50%;
        border: var(--usage-icon-border);
        background-color: var(--usage-icon-background-color);
        margin: var(--usage-icon-margin);
        width: var(--usage-icon-size);
        height: var(--usage-icon-size);
        display: flex;
        justify-content: center;
        align-items: center;
    }

    /* The font size in the superscript prevents pushing all the words off the center */
    header sup {
        font-size: var(--usage-cap-font-size);
    }

    /**
     * Icon
     */
    header[aria-expanded='true'] > hv-icon.arrow {
        transform: rotate(180deg);
    }
    header hv-icon {
        --hv-icon-font-size: var(--usage-arrow-icon-size);
        transition: transform 300ms ease-in-out;
    }
    /* Icon focus set color */
    header > hv-icon,
    header[focused] > hv-icon {
        --hv-icon-color: var(--usage-header-arrow-color);
    }

    /**
     * Content
     */
    #content-container .cost-specification {
        background-color: var(--usage-cost-specification-background-color);
        border-radius: var(--usage-border-radius);
        margin: var(--usage-cost-margin);
        padding: var(--usage-cost-padding);
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
    }

    #content-container .cost-specification .header,
    #content-container .cost-specification .body {
        align-items: center;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
    }

    #content-container .cost-specification .header {
        font-size: 16px;
    }

    #content-container .cost-specification .body {
        font-size: 12px;
        font-weight: normal;
        display: flex;
        flex-direction: column;
    }

    #content-container .cost-specification .body .entry {
        display: flex;
        width: 100%;
        justify-content: space-between;
        flex-direction: row;
        flex-wrap: nowrap;
    }

    #content-container .cost-specification:first-of-type {
        margin: var(--usage-cost-first-margin);
    }

    #content-container .cost-specification:last-of-type {
        margin-bottom: var(--usage-cost-last-margin);
    }

    #content-container .cost-specification span {
        /* The margin is required to align the prices */
        margin: var(--usage-price-margin);
        user-select: all;
    }

    #content-container .cost-specification span.cost-specification-description {
        align-items: center;
        display: flex;
    }

    #content-container .cost-specification tool-tip {
        --tooltip-icon-width: var(--usage-tool-tip-size);
        --tooltip-icon-height: var(--usage-tool-tip-size);

        display: flex;
        margin: var(--usage-cost-specification-tool-tip-margin);
        user-select: none;
    }

    /* Hide the information title for small screens to prevent overflowing */
    @media only screen and (max-width: 480px) {
        header .information-title {
            display: none;
        }
    }
`;
