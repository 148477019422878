import { vehicleAuthenticate } from './../async-reducers/vehicle';
import {
    LitElement,
    TemplateResult,
    customElement,
    html,
    PropertyValues,
} from 'lit-element';
import { connect, watch } from 'lit-redux-watch';
import { Snapshot } from 'types/snapshot';

@customElement('enode-sign-in')
export class EnodeSignIn extends connect(window.store)(LitElement) {
    @watch('jwtSnapshots.selectedSnapshot')
    private selectedSnapshot?: Snapshot;

    public render = (): TemplateResult => {
        return html`context`;
    };

    protected updated(changedProperties: PropertyValues) {
        if (
            changedProperties.has('selectedSnapshot') &&
            this.selectedSnapshot
        ) {
            window.store.dispatch(
                vehicleAuthenticate.run(this.selectedSnapshot.id),
            );
        }
    }
}

declare global {
    interface HTMLElementTagNameMap {
        'enode-sign-in': EnodeSignIn;
    }
}
