interface LoginHook {
    // eslint-disable-next-line no-unused-vars
    onLogin: (username: string, password: string) => Promise<void>;
}

export const loginHook: LoginHook = {
    onLogin: async () => {
        throw new Error('onLogin not implemented');
    },
};
