import { ErrorAction, SuccessAction } from 'goagen-js-resource';
import { ForkEffect, takeLatest } from 'redux-saga/effects';
import {
    CostReportsWithEstimations,
    ListCostReports,
    ListCostReportsEstimations,
} from '@async-reducers/costReports';
import enums from 'enums';

const endDate = 0;
const vendorID = 1;
const startDate = 2;
const productType = 3;

// this function fetches all the estimations for the cost reports when the cost reports are fetched succesfull
const fetchEstimationsForCostReports = (_: SuccessAction): void => {
    const listCostReportQuery = window.store.getState().listCostReports.query;
    // the cost report endpoint has the same query params as list cost reports estimations.
    window.store.dispatch(
        ListCostReportsEstimations.run(
            listCostReportQuery[endDate],
            listCostReportQuery[vendorID],
            listCostReportQuery[startDate],
            listCostReportQuery[productType],
        ),
    );
};

/**
 * addEstimationToTheCosts when the estimation has been fetched this function calls the CostReportsWithEstimations
 * to bind the data from the cost estimations and cost to a usage and cost objects that can be used for creating charts.
 */
const addEstimationToTheCosts = (_: SuccessAction): void => {
    const listCostReportsEstimations =
        window.store.getState().ListCostReportsEstimations.query;

    window.store.dispatch(
        CostReportsWithEstimations.run(false, listCostReportsEstimations[3]),
    );
};

// dispatch an event to CostReportsWithEstimations to empty the cost and cost estimations
const resetCostUsageAndUsage = (usageType: typeof enums.UsageType): void => {
    window.store.dispatch(CostReportsWithEstimations.run(true, usageType));
};

// if the list cost reports fails the CostReportsWithEstimations is set to default to prevent unexpected behaviour
const resetCostAndUsages = (_: ErrorAction): void => {
    const listCostReportQuery = window.store.getState().listCostReports.query;
    resetCostUsageAndUsage(listCostReportQuery ? listCostReportQuery[1] : null);
};

// if the list cost estimation reports fails the CostReportsWithEstimations is set to default to prevent unexpected behaviour
const resetEstimationsForCostReports = (_: ErrorAction): void => {
    const listCostEstimationsReportQuery =
        window.store.getState().ListCostReportsEstimations.query;
    resetCostUsageAndUsage(
        listCostEstimationsReportQuery
            ? listCostEstimationsReportQuery[1]
            : null,
    );
};

/**
 * Watcher for all cost report actions
 */
export function* watchCostReports(): IterableIterator<ForkEffect> {
    yield takeLatest(
        ListCostReports.actionTypeMap.callback,
        fetchEstimationsForCostReports,
    );
    yield takeLatest(
        ListCostReportsEstimations.actionTypeMap.callback,
        addEstimationToTheCosts,
    );
    // error handlers
    yield takeLatest(ListCostReports.actionTypeMap.error, resetCostAndUsages);
    yield takeLatest(
        ListCostReportsEstimations.actionTypeMap.error,
        resetEstimationsForCostReports,
    );
}
