import { takeLatest, ForkEffect, put } from 'redux-saga/effects';
import { Order } from 'types/order';
import { Snapshot, CreateUserSnapshotPayload } from 'types/snapshot';
import { Offers } from 'types/offers';
import { Router } from '@vaadin/router';
import { routePaths } from '../helpers/routing';
import { receiveSelf } from '@async-reducers/authentication';
import { NIL as NIL_UUID } from 'uuid';

import {
    orderRetentionOffer,
    OrderRetentionOfferState,
} from '../actions/orderRetentionOffer';
import { setLoader } from '../actions/loader';

import {
    requestCreateUserSnapshot,
    REQUEST_CREATE_USER_SNAPSHOT_FAILED,
    REQUEST_CREATE_USER_SNAPSHOT_SUCCESS,
} from '../actions/userSnapshot';
import enums from 'enums';
import { Action } from 'redux';

/**
 * Watcher for watchRequestRecalculationOffer action
 */
export function* watchOrderRetentionOffer(): IterableIterator<ForkEffect> {
    yield takeLatest(
        orderRetentionOffer.actionTypeMap.save,
        handleOrderRetentionOffer,
    );
}

interface orderRetentionOfferAction {
    type: string;
    data: OrderRetentionOfferState;
}

// handleOrderRetentionOffer should check the
function* handleOrderRetentionOffer(action: orderRetentionOfferAction) {
    const offers = action.data.offers;
    const order = action.data.order;
    const snapshot = action.data.snapshot;

    if (offers && order && snapshot) {
        const payload = buildSnapshotPayload(offers, order, snapshot);
        yield put(setLoader(true));
        yield takeLatest(
            REQUEST_CREATE_USER_SNAPSHOT_FAILED,
            handleRequestCreateRetentionSnapshot,
        );
        yield takeLatest(
            REQUEST_CREATE_USER_SNAPSHOT_SUCCESS,
            handleRequestCreateRetentionSnapshot,
        );
        yield put(requestCreateUserSnapshot(payload) as Action);
    }
}

function* handleRequestCreateRetentionSnapshot(action: any) {
    switch (action.type) {
        case REQUEST_CREATE_USER_SNAPSHOT_SUCCESS:
            const snapshot: Snapshot = action.data;
            yield put(receiveSelf.run());
            Router.go(`${routePaths.customerOverview}/${snapshot.id}`);
            break;
        case REQUEST_CREATE_USER_SNAPSHOT_FAILED:
            window.displayMessage(
                `Er is iets fout gegaan, probeer het later nog eens.`,
                'error',
            );
            Router.go(routePaths.customerProductList);
            break;
    }

    // Reset retention offer state
    yield put(setLoader(false));
    yield put(
        orderRetentionOffer.run({
            snapshot: undefined,
            offers: undefined,
            order: undefined,
            busy: false,
            enabled: false,
        }),
    );
}

const buildSnapshotPayload = (
    offers: Offers,
    order: Order,
    retentionSnapshot: Snapshot,
): CreateUserSnapshotPayload => {
    return {
        product_group_id:
            offers[order.offer.id].product_group_offering.configurations[
                order.offer.index
            ].id,
        has_tax_credit_right: retentionSnapshot.has_tax_credit_right,
        config_id:
            offers[order.offer.id].product_group_offering.configurations[
                order.offer.index
            ].config_id,
        elec_usage_amount: order.electricityUsageEstimate,
        gas_usage_amount: order.gasUsageEstimate,
        elec_prod_amount: order.electricityProductionEstimate,
        gas_transmission_type: order.transmissionTypeGas,
        elec_transmission_type: order.transmissionTypeElec,
        has_gas: order.gas,
        has_elec: order.electricity,
        collective_id: order.collective,
        smart_meter_choice: retentionSnapshot.smart_meter_choice_type,
        consumer_type:
            retentionSnapshot.company.id !== NIL_UUID
                ? enums.ConsumerTypeCompany
                : enums.ConsumerTypeConsumer,
        transfer_type: enums.ContractTransferTypeSwitch,
        payment_method: retentionSnapshot.payment_method,
        high_low_distribution:
            retentionSnapshot.verified_snapshot_payload.high_low_distribution,
        product_ids: Array.from(
            offers[order.offer.id].product_group_offering.configurations[
                order.offer.index
            ].products,
            (product) => product.id,
        ),
        vendor_id: retentionSnapshot.vendor_id,
        delivery_location_id: retentionSnapshot.delivery_location.id,
        invoice_location_id: retentionSnapshot.invoice_location.id,
        payment_account_id: retentionSnapshot.paymentaccount.id,
    };
};
