import { css } from 'lit-element';

export default css`
    @media only screen and (max-width: 768px) {
        :host {
            margin-top: -20px;
            min-height: calc(100vh - 64px);
        }
    }
`;
