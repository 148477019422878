export const REQUEST_GRAPH = 'REQUEST_GRAPH';
/**
 * Action generator for request fetch collectives
 * @param {String} intervalType
 * @param {Number | null} usageType
 * @param {String} dateFrom
 * @param {String} dateTill
 * @param {Snapshot} snapshot
 * @param {boolean} chargingSessions
 * @return {Object}
 */
export const requestGraph = (
    intervalType,
    usageType,
    dateFrom,
    dateTill,
    snapshot,
    chargingSessions,
) => ({
    type: REQUEST_GRAPH,
    intervalType,
    usageType,
    dateFrom,
    dateTill,
    snapshot,
    chargingSessions,
});

export const REQUEST_GRAPH_SUCCESS = 'REQUEST_GRAPH_SUCCESS';
/**
 * Success callback action for fetch collectives
 * @param {any} data
 * @param {Object} requestObject
 * @return {Object}
 */
export const requestGraphSuccess = (data, requestObject) => ({
    type: REQUEST_GRAPH_SUCCESS,
    data,
    requestObject,
});

export const REQUEST_GRAPH_FAILED = 'REQUEST_GRAPH_FAILED';
/**
 * Error callback action for fetch collectives
 * @param {Object} error
 * @return {Object}
 */
export const requestGraphFailed = (error) => ({
    type: REQUEST_GRAPH_FAILED,
    error,
});
