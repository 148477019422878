import { customElement, property } from 'lit-element';

import '@templates/oda-onboarding-step-wrapper/OdaOnboardingStepWrapper';

import '@components/molecules/InfoButton';
import '@components/atoms/HvInputCode';
import '@components/molecules/HvNotificationComponent';

import { connect, watch } from 'lit-redux-watch';
import { WeaveStep } from '@components/organism/stepper/WeaveStep';
import { css, html, CSSResult } from '@lion/core';
import { verifyOda } from '@async-reducers/oda';
import { asyncReducerResolver } from '@async-reducers/helpers/asyncReducerResolver';
import { selectOdaSnapshot } from '@selectors/snapshot';
import { Snapshot } from '../../types/snapshot';

const lengthOfInputcode = 6;

@customElement('metering-point-step')
export class MeteringPointStep extends connect(window.store)(WeaveStep) {
    @watch('verifyOda.error.message')
    public verifyOdaError?: string;

    @watch('verifyOda.data')
    public verifOdaData?: any;

    @watch('verifyOda.busy')
    public busy: boolean = false;

    @watch(selectOdaSnapshot)
    public snapshot?: Snapshot;

    @property({ type: Boolean })
    private disabledState: boolean = true;

    @watch('initOda.data.smp_id')
    public odaSmpID?: string;

    @property()
    private hasData: boolean = false;

    @property()
    private ready: boolean = false;

    validate = async (): Promise<boolean> => {
        if (this.verifyOdaError?.length) {
            return false;
        }
        window.displayMessage(
            `Je meternummer is succesvol toegevoegd.`,
            'succes',
        );
        return this.ready;
    };

    verify = async (e: CustomEvent): Promise<void> => {
        const { value } = e.target as HTMLInputElement;
        if (
            value.length === lengthOfInputcode &&
            this.odaSmpID &&
            this.snapshot
        ) {
            window.store.dispatch(
                verifyOda.run(this.odaSmpID, this.snapshot.id, value),
            );
            await asyncReducerResolver(this);
            this.hasData = true;
            if (!this.verifyOdaError) {
                this.ready = true;
                this.disabledState = false;

                return;
            }
            window.displayMessage(
                'Je meternummer is helaas niet gevonden',
                'warning',
            );
            this.disabledState = true;
            this.ready = false;
        }
    };

    renderHelper = () => {
        if (!this.hasData) {
            return html``;
        }
        if (this.verifyOdaError?.length) {
            return html`<p class="helper error">
                Slimme meter niet gevonden bij opgegeven adres in het landelijk
                energiesysteem. Controleer of je echt de laatste zes cijfers
                goed uit de streepjescode hebt overgenomen.
            </p>`;
        }
        if (!this.verifyOdaError) {
            return html`<p class="helper">
                Slimme meter gevonden in het landelijk energiesysteem.
            </p>`;
        }

        return html``;
    };

    renderInfoButton = () => html`
        <hv-info-button title="Streepjescode op je meter">
            <hv-notification type="info"
                >Er zijn verschillende merken voor de stroommeter(slimme meter)
                apparatuur. In grote lijnen zijn ze identiek en in details zul
                je kleine verschillen opmerken.</hv-notification
            >
            <h4>Meterkast</h4>
            <img src="/assets/img/oda/elec1.png" alt="meter 1" />
            <p>
                In het midden van de meterkast is de stroommeter(slimme meter)
                op de muur gemonteerd.
            </p>
            <h4>Stroommeter/slimme meter</h4>
            <img src="/assets/img/oda/elec2.png" alt="meter 2" />
            <p>
                Close-up van stroommeter(slimme meter) met de laatste zes
                cijfers met blauwe lijn gemarkeerd.
            </p>
            <h4>Waar moet je op letten</h4>
            <ul>
                <li>De slimme meter is altijd de stroommeter</li>
                <li>De stroommeter is het telwerk met "kWh"</li>
                <li>
                    Begint de streepjescode met een "E"? Vul dan de laatste zes
                    cijfers in.
                </li>
                <li>
                    Ook altijd elke "0" in de laatste 6 cijfers van het
                    meternummer invullen (bijvoorbeeld xxxx012320)
                </li>
            </ul>
        </hv-info-button>
    `;

    render = () => html`
        <oda-onboarding-step-wrapper
            nextText="Meter toevoegen"
            ?disable-navigation=${this.disabledState}
        >
            <main slot="title">Vul je stroommeternummer in</main>
            <span slot="introduction"
                >Vind in de meterkast op de voorzijde van de stroommeter(slimme
                meter) de langste of enige streepjescode(meternummer). Vul
                alleen de laatste zes cijfers van deze code in. Klik voor meer
                informatie op het vraagteken.</span
            >
            <div class="content">
                <div class="title">
                    <h4>Wat zijn de laatste zes cijfers?</h4>
                    ${this.renderInfoButton()}
                </div>

                <hv-input-code
                    label="Stroommeternummer(slimme meternummer)"
                    name="meter-code"
                    type="text"
                    inputmode="numeric"
                    autocomplete="meter-code"
                    autocapitalize="off"
                    ?disabled="${this.busy}"
                    fields="${lengthOfInputcode}"
                    @user-input-changed="${this.verify}"
                ></hv-input-code>
                ${this.renderHelper()}
            </div>
        </oda-onboarding-step-wrapper>
    `;

    static get styles(): CSSResult[] {
        return [
            ...super.styles,
            css`
                :host {
                    --oda-onboarding-content-border: 1px solid #e5e5e5;
                    --oda-onboarding-content-padding: 0 15px 15px;
                    --oda-onboarding-info-button-padding-top: 5px;
                    --oda-onboarding-input-label-font-size: 10px;
                    --oda-onboarding-info-button-title-margin-bottom: 5px;
                    --oda-onboarding-info-button-text-margin: 0;
                    --oda-onboarding-info-button-text-font-size: 12px;
                    --oda-onboarding-info-button-color: #aaa;
                }
                .content {
                    border: var(--oda-onboarding-content-border);
                    padding: var(--oda-onboarding-content-padding);
                }
                hv-info-button {
                    padding-top: var(--oda-onboarding-info-button-padding-top);
                }
                hv-input-code::slotted(label) {
                    font-size: var(--oda-onboarding-input-label-font-size);
                }
                .title {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                }
                .helper {
                    margin-top: 0;
                    font-size: var(--oda-onboarding-info-button-text-font-size);
                    color: #3aad3a;
                }
                .helper.error {
                    color: #ea9700;
                }
                hv-notification {
                    --hv-notification--font-size: 13px;
                    --hv-notification--padding: 14px;
                }
                hv-info-button h4 {
                    margin-bottom: var(
                        --oda-onboarding-info-button-title-margin-bottom
                    );
                }
                hv-info-button p {
                    margin: var(--oda-onboarding-info-button-text-margin);
                    font-size: var(--oda-onboarding-info-button-text-font-size);
                    color: var(--oda-onboarding-info-button-color);
                }
                li {
                    font-size: var(--oda-onboarding-info-button-text-font-size);
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        'metering-point-step': MeteringPointStep;
    }
}
