import {
    html,
    LitElement,
    PropertyValues,
    query,
    TemplateResult,
} from 'lit-element';

const SLOT: unique symbol = Symbol('SLOT');
const UPDATE_SLOTTED: unique symbol = Symbol('UPDATE_SLOTTED');
const UPDATED_SLOTTED: unique symbol = Symbol('UPDATED_SLOTTED');

export type ElementOrType<T extends Element | undefined> = T extends undefined
    ? Element
    : T;
export type TypeOrArray<T> = T | T[];
export type Constructor<T> = new (...args: any[]) => T; // eslint-disable-line no-unused-vars

/**
 * App root component
 */
export abstract class Slot<T extends Element = Element> extends LitElement {
    @query('slot', true)
    private readonly [SLOT]?: HTMLSlotElement;

    protected filterSlotted: Constructor<T>[] = [];

    private [UPDATED_SLOTTED]: boolean = false;

    /**
     * Get the unfiltered slotted items as given by <slot>.assignedElements()
     */
    protected get unfilteredSlotted(): Element[] {
        // Ensure <slot> exists
        if (!this[SLOT]) {
            throw new Error('Unexpected missing <slot>');
        }

        return this[SLOT]!.assignedElements();
    }

    /**
     * Get the filtered slotted items as passed with slottedChanged
     */
    protected get slotted(): T[] {
        if (this.filterSlotted.length === 0) {
            return this.unfilteredSlotted as T[];
        }

        return this.unfilteredSlotted.filter(
            (e: Element) =>
                !!this.filterSlotted.find(
                    (f: Constructor<T>) => e instanceof f,
                ),
        ) as T[];
    }

    /**
     * Render <slot> - you can override this as long as your element will still have a <slot> somewhere.
     */
    // eslint-disable-next-line class-methods-use-this
    public render(): TemplateResult {
        return html`<slot></slot>`;
    }

    /**
     * Remove connected event listener
     */
    public disconnectedCallback() {
        // Ensure <slot> exists
        if (!this[SLOT]) {
            throw new Error('Unexpected missing <slot>');
        }

        // Remove event listener from slotted
        this[SLOT]!.removeEventListener('slotchange', this[UPDATE_SLOTTED]);

        // Continue
        super.disconnectedCallback();
    }

    /**
     * Add event listener
     */
    protected firstUpdated(changedProperties: PropertyValues) {
        super.firstUpdated(changedProperties);

        // Ensure <slot> exists
        if (!this[SLOT]) {
            throw new Error('Unexpected missing <slot>');
        }

        // Add event listener to slotted
        this[SLOT]!.addEventListener('slotchange', this[UPDATE_SLOTTED]);

        // Adds task after existing render tasks to trigger slottedChanged once if no event was fired
        queueMicrotask(() => {
            if (!this[UPDATED_SLOTTED]) {
                // Trigger first slot update
                this[UPDATE_SLOTTED]();
            }
        });
    }

    /**
     * Hook slot changes
     */
    protected abstract slottedChanged(elements: T[]): void; // eslint-disable-line no-unused-vars

    /**
     * Handle slot changes
     */
    private [UPDATE_SLOTTED] = (): void => {
        this[UPDATED_SLOTTED] = true;

        this.slottedChanged(this.slotted);
    };
}
