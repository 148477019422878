import { Router } from '@vaadin/router';
import { routePaths } from 'data-access/router';
import enums from 'enums';
import { Snapshot } from 'types/snapshot';
import { OdaContract } from './contract/OdaContract';
import { SmartChargingContract } from './contract/SmartChargingContract';
import { SnapshotContract } from './contract/SnapshotContract';

export type ContactType = 'ODA' | 'UTILITIES' | 'SMART_CHARGING';
export type ValueOf<T> = T[keyof T];
export interface IContract {
    readonly maxWaitingDays: number;

    IsActive: () => boolean;
    RouteToPage: () => void;
    contractType: ContactType;
    currentPhase: number;
    // todo: add intelicense deep type
    page: ValueOf<typeof routePaths>;
}

// factory
export class Contract implements IContract {
    public readonly maxWaitingDays: number = 10;
    public readonly page: ValueOf<typeof routePaths> = routePaths.dashboard;

    public contractType: ContactType = 'UTILITIES';
    public currentPhase: number = enums.SnapshotPhaseDeliveryStarted;

    constructor(public snapshot: Snapshot) {}

    public IsActive(): boolean {
        return false;
    }

    public RouteToPage(_skipped: boolean = false): void {
        Router.go(this.page);
    }
}

export const contractFactory = (snapshot: Snapshot): Contract => {
    const phase = snapshot.snapshot_phase;
    // ODA / Smart insights / snapshots
    if (
        phase >= enums.SnapshotPhaseDemoContractCreated &&
        phase <= enums.SnapshotPhaseInsightContractStopped
    ) {
        return new OdaContract(snapshot);
    } else if (
        phase >= enums.SnapshotPhaseSmartChargingContractCreated &&
        phase <= enums.SnapshotPhaseSmartChargingStopped
    ) {
        return new SmartChargingContract(snapshot);
    }

    return new SnapshotContract(snapshot);
};
