import { css } from 'lit-element';

export default css`
    #split__business-container p.modal-text {
        color: #1e2129;
    }

    #split__business-container button-component {
        margin-top: 20px;
    }

    #split__business__history--modal .split__history {
        font-size: 13px;
    }
    #split__business__history--modal .split__history:first-of-type {
        margin-block-start: 0;
    }
    #split__business__history--modal .split__history:last-of-type {
        margin-block-end: 0;
    }
`;
