import { ReduxLazy } from '@weavedev/redux-lazy';

// request recalculation offer
export const recalculationModalOpen = 'RECALCULATION_MODAL_OPEN';
export const recalculationModalOpener = new ReduxLazy(
    recalculationModalOpen,
    false,
);

// request recalculation offer error
export const recalculationModalOpenError = 'RECALCULATION_MODAL_OPEN_ERROR';
export const recalculationModalErrorOpener = new ReduxLazy(
    recalculationModalOpenError,
    {
        price: 0,
        opened: false,
    },
);
