import { css } from 'lit-element';

export default css`
    .eaninput {
        color: #777777;
    }
    .eaninput__container {
        display: flex;
        border: 2px solid #cccccc;
        border-radius: 3px 3px 3px 3px;
        height: 35px;
    }

    .eaninput__label {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 20px;
        background-color: #f5f5f5;
        border-right: 2px solid #cccccc;
    }

    .eaninput__label p {
        margin: 0;
    }

    .eaninput__input {
        font-family: inherit;
        font-size: 16px;
        margin: 0;
        width: 100%;
        border: hidden;
        padding: 0px 15px;
        letter-spacing: 1px;
    }
`;
