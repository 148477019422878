// v2.0 (todo reuse this in vendor-dashboard.)

import enums from 'enums';

export interface ExternalSupplier {
    id: string;
    name: string;
    logo_url: string;
    snapshot_id: string;
    created_at: Date;
    updated_at: Date;
}

export interface Configuration {
    config_id: number;
    duration_type: number;
    name: string;
}

export interface Cost {
    calculated_amount: string;
    calculated_price: string;
    description: string;
    group_id: number;
    interval: number;
    is_group_parent: boolean;
    ledger_code: string;
    margin_percentage: string;
    margin_price: string;
    price: string;
    type: number;
    vat: number;
    vat_percentage: number;
    weight: number;
}

export interface Product {
    cost: Cost[];
    id: string;
    monthly_pricing: number;
    name: string;
    type: number;
    weight: number;
}

export interface CalculatedProductGroupUserMetadata {
    configurations: Configuration[];
    description: string[];
    short_name: string;
    sub_title: string;
    title: string;
}

export interface CalculatedProductGroup {
    config_id: number;
    duration_type: number;
    id: string;
    metadata: CalculatedProductGroupUserMetadata;
    minimum_upfront_payment_amount: number;
    monthly_pricing: number;
    monthly_pricing_excl: number;
    name: string;
    optional_products: any;
    payment_interval: number;
    payment_type: number;
    products: Product[];
    smart_meter_required: boolean;
    vat: number;
    vendor_id: string;
    yearly_pricing: number;
}

export interface Company {
    btw_number: string;
    id: string;
    kvk_number: string;
    name: string;
}

export interface CostCalculationConfig {
    cost_calculated_until: Date | string;
    interval_type: number;
    usage_type: number;
}

export interface RecalculationOfferConfig {
    accepted: boolean;
    accepted_at: string;
    next_recalculation_date: string;
    recalculated_at: string;
}
export interface EmailServiceConfig {
    invoice_mail: any;
    loss_mail: number[];
    mandate_mail: any;
    settlement_mail: any;
    snapshot_mail: number[];
}

export interface Location {
    area_code: string;
    city: string;
    house_addition: string;
    house_number: number;
    id: string;
    street_name: string;
}

export interface EzSearch {
    should_vectorize: boolean;
}

export interface EndOfSupplyConfig {
    reason: number;
}

export interface SnapshotMetadata {
    cost_calculation_config: CostCalculationConfig[];
    email_service_config: EmailServiceConfig;
    end_of_supply_config: EndOfSupplyConfig;
    ez_search: EzSearch;
    gain_reason: number;
    last_calculation_date: Date | string;
    recalculation_offer: RecalculationOfferConfig;
}

export interface PaymentaccountMetadata {
    iban: string;
}

export interface Paymentaccount {
    date: Date | string;
    id: string;
    metadata: PaymentaccountMetadata;
}

export interface ProductChoice {
    config_id: number;
    product_group_id: string;
    product_ids: string[];
}

export interface SnapshotPayload {
    delivery_stopped_date: Date | string;
    elec_prod_amount: number;
    elec_transmission_type: number;
    elec_usage_amount: number;
    gas_transmission_type: number;
    gas_usage_amount: number;
    has_elec: boolean;
    has_gas: boolean;
    high_low_distribution: number;
    start_date: Date | string;
    current_start?: Date | string;
    planned_stop_date?: string;
    end_date?: string;
}

export interface ContractCancellation {
    date: string;
    failed: boolean;
    id: string;
    metering_point_id: string;
    snapshot_id: string;
    type: number;
}

export type Snapshots = Snapshot[];

export interface Snapshot {
    alias: string;
    calculated_product_group_user: CalculatedProductGroup;
    calculated_product_group_verified: CalculatedProductGroup;
    collective_id: string;
    company: Company;
    consumer_type: number;
    contract_cancellation: ContractCancellation[];
    contract_duration: number;
    delivery_location: Location;
    error_type: number;
    external_supplier: ExternalSupplier;
    has_mandate: boolean;
    has_tax_credit_right: boolean;
    id: string;
    invoice_location: Location;
    mandate_id: string;
    metadata: SnapshotMetadata;
    metering_point_ids: string[];
    payment_method: number;
    paymentaccount: Paymentaccount;
    product_choice: ProductChoice;
    smart_meter_choice_type: number;
    smart_meter_required: boolean;
    snapshot_phase: number;
    split_business: boolean;
    transfer_type: number;
    user_id: string;
    user_snapshot_payload: SnapshotPayload;
    vendor_id: string;
    verified_snapshot_payload: SnapshotPayload;
    prefix: string;
}
// eslint-disable-next-line no-redeclare
export class Snapshot {
    constructor(snapshot: Snapshot) {
        let key: keyof Snapshot;
        for (key in snapshot) {
            if ({}.hasOwnProperty.call(snapshot, key)) {
                this.addProp(snapshot, key);
            }
        }
    }

    private addProp<K extends keyof Snapshot>(snap: Snapshot, k: K): void {
        (this as Snapshot)[k] = snap[k];
    }

    isFlex(): boolean {
        return this.contract_duration === enums.ProductDurationMonth;
    }

    isVast(): boolean {
        return this.contract_duration !== enums.ProductDurationMonth;
    }

    isActive(): boolean {
        const snapshotState = this.snapshot_phase;
        if (
            snapshotState === enums.SnapshotPhaseInsightContractActive ||
            snapshotState === enums.SnapshotPhaseDeliveryStarted
        ) {
            return true;
        }
        return false;
    }
}

// Generated by https://quicktype.io

export interface CreateUserSnapshotPayload {
    product_group_id: string;
    has_tax_credit_right: boolean;
    config_id: number;
    elec_usage_amount: number;
    gas_usage_amount: number;
    elec_prod_amount: number;
    gas_transmission_type: number;
    elec_transmission_type: number;
    has_gas: boolean;
    has_elec: boolean;
    collective_id: null | string;
    consumer_type: number;
    transfer_type: number;
    payment_method: number;
    smart_meter_choice: number | undefined;
    high_low_distribution: number;
    product_ids: string[];
    vendor_id: string;
    delivery_location_id: string;
    invoice_location_id: string;
    payment_account_id: string;
}
