import { Vehicle } from 'types/vehicle';
import { put, takeLatest } from 'redux-saga/effects';
import { VEHICLE_LIST_SUCCESS, vehicleGet } from '../async-reducers/vehicle';

function* fetchVehicle(action: any) {
    const smartChargingSnapshot =
        window.store.getState().jwtSnapshots.smartChargingSnapshots[0];
    const vehicles: Vehicle[] = action.data?.vehicles;
    if (vehicles && vehicles.length > 0 && smartChargingSnapshot) {
        yield put(vehicleGet.run(smartChargingSnapshot.id, vehicles[0].id));
    }
}

/**
 * Watcher for VEHICLE_LIST_SUCCESS action
 */
export function* watchRequestVehicleList() {
    yield takeLatest(VEHICLE_LIST_SUCCESS, fetchVehicle);
}
