import { put, takeLatest } from 'redux-saga/effects';
import {
    recalculationModalOpener,
    recalculationModalErrorOpener,
} from './offerRecalculation';
import {
    offerRecalculation,
    acceptOfferRecalculation,
    requestInvalidOfferRecalculation,
} from '../../../../store';
import { receiveSelf } from '@async-reducers/authentication';
import { messageTypes } from '../../../../index';

const newOfferSuccesText = `Je maandbedrag is succesvol gewijzigd.`;
const offerValidText = 'Je maandbedrag is succesvol berekend';
const offerInvalidText =
    'Het nieuwe maandbedrag is succesvol berekend. Maar de ingevulde verbruiksgegevens verschillen fors met de bij ons bekende energiegegevens.';

const couldNotRecalculateText = 'Herberekening niet gelukt.';
const requestSubmittedText = 'Je aanvraag is ingediend';
const errorMessage =
    'Er is iets misgegaan bij het vastleggen van deze aanpassing. Probeer het nog een keer of neem contact op met de klantenservice of vul een contactformulier in op de website.';

const requestFailedText = 'Er is iets mis gegaan tijdens het aanvragen.';

/**
 * Create snapshot fetcher
 */
function* fetchSnapshot() {
    yield put(receiveSelf.run());
    yield put(recalculationModalOpener.run(false));
    yield put(
        recalculationModalErrorOpener.run({
            opened: false,
            price: 0,
        }),
    );
    window.displayMessage(newOfferSuccesText, messageTypes.succes);
}

function* recalculatedOffer() {
    yield put(recalculationModalOpener.run(true));
    window.displayMessage(offerValidText, messageTypes.succes);
}

function* recalculatedOfferError(action: any) {
    yield put(recalculationModalOpener.run(false));
    if (action?.error === 400) {
        yield put(
            recalculationModalErrorOpener.run({
                opened: true,
                price: action?.errorData?.price || 0,
            }),
        );
        window.displayMessage(offerInvalidText, messageTypes.info);
        return;
    }
    window.displayMessage(couldNotRecalculateText, messageTypes.error);
}

function* sendMailSucces() {
    yield window.displayMessage(requestSubmittedText, messageTypes.succes);
}

function* sendMailError() {
    yield window.displayMessage(requestFailedText, messageTypes.error);
}

function* acceptOfferRecalError() {
    yield window.displayMessage(errorMessage, messageTypes.error);
}

/**
 * Watcher for acceptRecalculationOfferSucess action
 */
export function* watchRequestAcceptRecalculationOffer() {
    yield takeLatest(acceptOfferRecalculation.REQUEST_SUCCESS, fetchSnapshot);
}

/**
 * Watcher for acceptRecalculationOfferSucessError action
 */
export function* watchRequestAcceptRecalculationOfferError() {
    yield takeLatest(
        acceptOfferRecalculation.REQUEST_FAILED,
        acceptOfferRecalError,
    );
}

/**
 * Watcher for watchRequestRecalculationOffer action
 */
export function* watchRequestRecalculationOffer() {
    yield takeLatest(offerRecalculation.REQUEST_SUCCESS, recalculatedOffer);
}

/**
 * Watcher for watchRequestRecalculationOfferError action
 */
export function* watchRequestRecalculationOfferError() {
    yield takeLatest(offerRecalculation.REQUEST_FAILED, recalculatedOfferError);
}

/**
 * Watcher for watchInvalidRequestRecalculationOffer action
 */
export function* watchInvalidRequestRecalculationOffer() {
    yield takeLatest(
        requestInvalidOfferRecalculation.REQUEST_SUCCESS,
        sendMailSucces,
    );
}

/**
 * Watcher for watchInvalidRequestRecalculationOfferError action
 */
export function* watchInvalidRequestRecalculationOfferError() {
    yield takeLatest(
        requestInvalidOfferRecalculation.REQUEST_FAILED,
        sendMailError,
    );
}
