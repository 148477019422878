export class AgreeSnapshotError extends Error {
    public snapshotID: string;

    constructor(snapshotID: string) {
        super(`While updating the snapshot with snapshotID: ${snapshotID}`);

        this.name = 'AgreeSnapshotError';

        // Saving data for later access
        this.snapshotID = snapshotID;
    }
}
