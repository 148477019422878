import { PaperInputElement } from '@polymer/paper-input/paper-input';

/**
 * checks if the input is valid and returns the first HTML element
 * in this case (paper-input) and if the input is valid.
 */
export const focusPaperInputEventIsInvalid = (
    event: CustomEvent,
    previousProper = null,
) => {
    if (event == null || event.detail.value) {
        return { inValid: true, firstElement: null };
    }

    const firstElement = event.composedPath()[0] as PaperInputElement;
    if (
        firstElement.value == null ||
        firstElement.value === '' ||
        previousProper === firstElement.value ||
        !!firstElement.disabled
    ) {
        firstElement.value?.trim();
        return { inValid: true, firstElement };
    }
    firstElement.value = firstElement.value?.trim();

    const isValid = firstElement?.validate();
    return { inValid: false, firstElement, isValid };
};
