import {
    Bearer,
    BearerSupportedTypes,
    ParameterDefinitionsObject,
    ParameterIn,
    RequestAction,
    RequestOptions,
    RequestSagaIterableIterator,
    ResolvableOrType,
    Resource,
    ResourceOptions,
    UUID,
    UUIDSupportedTypes,
} from 'goagen-js-resource';
import { Method } from 'axios';

export interface ListInvoicesDocumentsUserRequestObject {
    // (Boolean)
    bundle?: boolean;
    // (String)
    filter?: string;
    // (String)
    order?: string;
    // (Number)
    pageNumber?: number;
    // (Number)
    pageSize?: number;
    // (UUID)
    snapshotId?: UUIDSupportedTypes;
    // (UUID)
    userId?: UUIDSupportedTypes;
    // Injected Bearer header (Bearer)
    xAuth?: BearerSupportedTypes;
}

export interface ResolvableListInvoicesDocumentsUserRequestObject {
    // (Boolean)
    bundle?: ResolvableOrType<ListInvoicesDocumentsUserRequestObject['bundle']>;
    // (String)
    filter?: ResolvableOrType<ListInvoicesDocumentsUserRequestObject['filter']>;
    // (String)
    order?: ResolvableOrType<ListInvoicesDocumentsUserRequestObject['order']>;
    // (Number)
    pageNumber?: ResolvableOrType<
        ListInvoicesDocumentsUserRequestObject['pageNumber']
    >;
    // (Number)
    pageSize?: ResolvableOrType<
        ListInvoicesDocumentsUserRequestObject['pageSize']
    >;
    // (UUID)
    snapshotId?: ResolvableOrType<
        ListInvoicesDocumentsUserRequestObject['snapshotId']
    >;
    // (UUID)
    userId?: ResolvableOrType<ListInvoicesDocumentsUserRequestObject['userId']>;
    // Injected Bearer header (Bearer)
    xAuth?: ResolvableOrType<ListInvoicesDocumentsUserRequestObject['xAuth']>;
}

/**
 * Store member management object for list invoices documents user
 */
export class ListInvoicesDocumentsUser extends Resource<
    ListInvoicesDocumentsUserRequestObject,
    ResolvableListInvoicesDocumentsUserRequestObject
> {
    /**
     * Create new ListInvoicesDocumentsUser
     *
     * | Parameter |  | Target |  |  |
     * | --- | --- | --- | --- | --- |
     * | `bundle` * | `Boolean` | query | `bundle` |  |
     * | `filter` | `String` | query | `filter` |  |
     * | `order` | `String` | query | `order` |  |
     * | `pageNumber` * | `Number` | query | `page_number` |  |
     * | `pageSize` * | `Number` | query | `page_size` |  |
     * | `snapshotId` * | `UUID` | query | `snapshot_id` |  |
     * | `userId` * | `UUID` | query | `user_id` |  |
     * | `xAuth` | `Bearer` | header | `X-Auth` | Injected Bearer header |
     */
    constructor(
        storeName: string,
        options: ResourceOptions<ResolvableListInvoicesDocumentsUserRequestObject> = {},
    ) {
        super(storeName, options);
    }

    /**
     * Generate request action
     *
     * | Parameter |  | Target |  |  |
     * | --- | --- | --- | --- | --- |
     * | `bundle` * | `Boolean` | query | `bundle` |  |
     * | `filter` | `String` | query | `filter` |  |
     * | `order` | `String` | query | `order` |  |
     * | `pageNumber` * | `Number` | query | `page_number` |  |
     * | `pageSize` * | `Number` | query | `page_size` |  |
     * | `snapshotId` * | `UUID` | query | `snapshot_id` |  |
     * | `userId` * | `UUID` | query | `user_id` |  |
     * | `xAuth` | `Bearer` | header | `X-Auth` | Injected Bearer header |
     */
    public request(
        requestObject: ListInvoicesDocumentsUserRequestObject = {},
        requestOptions: RequestOptions = {},
    ): RequestAction<ListInvoicesDocumentsUserRequestObject> {
        return super.request(requestObject, requestOptions);
    }

    /**
     * Run request as saga
     *
     * | Parameter |  | Target |  |  |
     * | --- | --- | --- | --- | --- |
     * | `bundle` * | `Boolean` | query | `bundle` |  |
     * | `filter` | `String` | query | `filter` |  |
     * | `order` | `String` | query | `order` |  |
     * | `pageNumber` * | `Number` | query | `page_number` |  |
     * | `pageSize` * | `Number` | query | `page_size` |  |
     * | `snapshotId` * | `UUID` | query | `snapshot_id` |  |
     * | `userId` * | `UUID` | query | `user_id` |  |
     * | `xAuth` | `Bearer` | header | `X-Auth` | Injected Bearer header |
     */
    // @ts-ignore
    public get requestSaga(): (
        requestObject?: ListInvoicesDocumentsUserRequestObject,
        requestOptions?: RequestOptions,
    ) => RequestSagaIterableIterator<
        RequestAction<ListInvoicesDocumentsUserRequestObject>
    > {
        return super.requestSaga;
    }

    /**
     * Action string
     */
    static get action(): string {
        return 'list invoices documents';
    }

    /**
     * Description string
     */
    static get description(): string {
        return 'List all invoices documents for a snapshot';
    }

    /**
     * Request method
     */
    static get method(): Method {
        return 'get';
    }

    /**
     * Resource string
     */
    static get resource(): string {
        return 'user';
    }

    /**
     * Route string
     */
    static get route(): string {
        return '/user/document/invoice';
    }

    /**
     * Unique name string
     */
    static get uniqueName(): string {
        return 'ListInvoicesDocumentsUser';
    }

    /**
     * Parameters object
     */
    static get parameters(): ParameterDefinitionsObject {
        return {
            bundle: {
                in: ParameterIn.query,
                name: 'bundle',
                required: true,
                type: Boolean,
            },
            filter: {
                in: ParameterIn.query,
                name: 'filter',
                type: String,
            },
            order: {
                in: ParameterIn.query,
                name: 'order',
                type: String,
            },
            pageNumber: {
                in: ParameterIn.query,
                name: 'page_number',
                required: true,
                type: Number,
            },
            pageSize: {
                in: ParameterIn.query,
                name: 'page_size',
                required: true,
                type: Number,
            },
            snapshotId: {
                in: ParameterIn.query,
                name: 'snapshot_id',
                required: true,
                type: UUID,
            },
            userId: {
                in: ParameterIn.query,
                name: 'user_id',
                required: true,
                type: UUID,
            },
            xAuth: {
                description: 'Injected Bearer header',
                in: ParameterIn.header,
                name: 'X-Auth',
                type: Bearer,
            },
        };
    }
}
