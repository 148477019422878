import { PropertyValues } from 'lit';
import { customElement } from 'lit/decorators/custom-element';
import { html, LitElement, property, TemplateResult } from 'lit-element';
import style from './EANInputStyle';
import { connect } from 'lit-redux-watch';
import { MeteringPoint } from 'types/meteringPoint';
import KeyPressEvent = JQuery.KeyPressEvent;
import { getEanPrefixForUsage } from 'helpers/ean';

export enum EanUsageType {
    ELEC = 1,
    GAS = 2,
}

interface EanEventDetail {
    ean: string;
}
export type EanEvent = CustomEvent<EanEventDetail>;

/**
 * The ean input field contains a prefix label with the first x amount characters of the ean number, something like 1119.
 * Next to the prefix label is een input field with the remaining numbers of the ean which can be filled in by the user.
 *
 **/
@customElement('ean-input')
export class EANInput extends connect(window.store)(LitElement) {
    @property({ type: Array })
    public meteringPoints?: MeteringPoint[];

    /**
     * Character which will be used repeatedly for placeholder
     * Example if '0' then placeholder could be something like '0000 0000 0000 00'
     */
    @property({ type: String })
    placeHolderChar: string = '0';

    /**
     * Determines the amount of character to use as a substring of the beginning of the full ean string
     * Example if eanPrefixLength = 4 and full ean is 1119345634563456345 than prefix would be 1119
     * @private
     */
    private eanPrefixLength = 4;

    /**
     * Holds the first x amount character of full ean string, determined by the eanPrefixLength prop
     */
    @property({ type: String })
    eanPrefix: string = '';

    /**
     * Let this component know which metering point to use as context
     * Will be used to filter metering points, meteringpoints.description === EnUsageType
     * For example to get the ean for its prefix
     */
    @property({ type: String })
    usageType: EanUsageType = EanUsageType.ELEC;

    /**
     * Max length to use for formatted input.
     * Note: gap character are excluded from count!
     * Example
     *      - if gapCharacter = '-' && gapLength = 2 && groupLength = 3 && maxLength = 7
     *      - input field str would be '000--000--0' (Note that gap characters are excluded)
     */
    @property({ type: Number })
    maxLength: number = 14;

    /**
     * Holds the full raw ean number as a string
     * Which means if the input field string would be '234---345---56'
     * Than it contains prefix+inputWithoutFormat like 111923434556
     * This var will be communicated to the outside world
     */
    @property({ type: String })
    public ean: string = '';

    static get styles() {
        return [style];
    }

    protected updated(changedProperties: PropertyValues) {
        if (changedProperties.has('meteringPoints') && this.meteringPoints) {
            this.eanPrefix = getEanPrefixForUsage(
                this.meteringPoints,
                this.usageType,
                this.eanPrefixLength,
            );
        }
    }

    private inputHandler(e: InputEvent) {
        const inputElement = e.target as HTMLInputElement;
        const { value } = inputElement;

        this.ean = this.getFullEan(value);

        const eanEvent: EanEvent = new CustomEvent('ean', {
            bubbles: true,
            composed: true,
            detail: {
                ean: this.ean,
            },
        });

        this.dispatchEvent(eanEvent);
    }

    private getFullEan(partiallyInputEan: string) {
        return `${this.eanPrefix}${partiallyInputEan}`;
    }

    private getMaxInputFieldLength() {
        return this.maxLength - this.eanPrefix.length;
    }

    private inputKeyPressHandler(e: KeyPressEvent) {
        // only allow numbers as input
        const isNum = /^\d+$/.test(e.key);
        const maxInputReached =
            e.target.value.length >= this.getMaxInputFieldLength();

        if (!isNum || maxInputReached) {
            e.preventDefault();
        }
    }

    private getPlaceHolder() {
        return this.placeHolderChar.repeat(this.getMaxInputFieldLength());
    }

    public render(): TemplateResult {
        return html`
            <div class="eaninput">
                <p>EAN-code<sup>*</sup></p>
                <div class="eaninput__container">
                    <div class="eaninput__label">
                        <p>${this.eanPrefix}</p>
                    </div>
                    <input
                        class="eaninput__input"
                        placeholder="${this.getPlaceHolder()}"
                        @input="${this.inputHandler}"
                        @keypress="${this.inputKeyPressHandler}"
                        type="text"
                    />
                </div>
            </div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        'ean-input': EANInput;
    }
}
