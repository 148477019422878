import { landerPage, resetLander } from '../actions/history';
/**
 * State reducer for loader
 */
export const lander = (state: boolean = false, action: any): boolean => {
    switch (action.type) {
        case landerPage:
            return true;
        case resetLander:
            return false;
        default:
            return state;
    }
};
