import { all, call, put, takeLatest } from 'redux-saga/effects';
import enums from 'enums';
import { VAT } from 'helpers/vat';
import { betterWrapper } from './helpers/wrapper';
import {
    REQUEST_SAVINGS,
    REQUEST_SAVINGS_FETCHED,
    requestSavingsFetched,
    requestSavingsFailed,
    requestSavingsSuccess,
} from '../actions/savings';
import { getMonthShortNameFromDate } from '../globals';
import {
    listInvoices,
    showMonthCosts,
    showMonthCostsPredictions,
} from '../store';

/**
 * addMonthTotalToTheCostType gets the cost dataset and adds the total to the given array on the given property for each month
 */
function addMonthTotalToTheCostType(costItem) {
    // check if value exist if total_incl doesnt exist add total_excl * VAT
    if (costItem == null || costItem.total_excl == null) {
        console.error('cost data is missing cost data or total_excl.');
        return;
    }

    if (
        !costItem.total_incl ||
        costItem.total_incl == null ||
        costItem.total_incl === ''
    ) {
        // fallback if total_incl doesnt exists
        if (typeof costItem.total_excl === 'string') {
            // eslint-disable-next-line no-param-reassign
            costItem.total_excl = parseFloat(costItem.total_excl);
        }
        // eslint-disable-next-line no-param-reassign
        costItem.total_incl = costItem.total_excl * VAT;
    }
}

/**
 * addTotalsToTheCostType adds the totals for both the calculcated (real cost) and the expected cost (estimate cost)
 */
function addTotalsToTheCostType(
    realCosts,
    estimationCosts,
    costData,
    monthCount,
) {
    for (let i = 0; i < monthCount; i++) {
        // REAL COSTS
        const realCostItem = realCosts.data ? realCosts.data[i] : undefined;

        // If a real cost exist than add the real cost and set estimated cost to undefined
        if (realCostItem) {
            addMonthTotalToTheCostType(realCostItem);
            costData[i].calculated = realCostItem.total_incl;
            costData[i].estimate = undefined;
        }

        // Set the estimated cost for each month when available
        const estimationCostItem = estimationCosts.data[i];
        if (estimationCostItem) {
            addMonthTotalToTheCostType(estimationCostItem);
            costData[i].estimate = estimationCostItem.total_incl;
        }
    }
}

/**
 *
 * @param {Date} startDate
 * @param {Date} endIterator
 * @return {number}
 * @private
 */
function _calculateDifferenceInMonths(startDate, endIterator) {
    const year1 = startDate.getFullYear();
    const year2 = endIterator.getFullYear();
    let month1 = startDate.getMonth();
    let month2 = endIterator.getMonth();

    if (month1 === 0) {
        month1 += 1;
        month2 += 1;
    }

    return (year2 - year1) * 12 + (month2 - month1) - 1 + 2;
}

/**
 * Runs prepareSavingsData
 * gets estimated, invoices, and costs and returns the total estimations, invoice paid amount, and cost as one dataset (CostData)
 *
 * Action contains data: estCost, invoices and realCost
 * Invoices are an Array of ExtendedDocument action.data.invoices.data (src/types/documents.ts:33)
 * Est/real Cost are of type (src/types/cost.ts:1)
 * @param {Object} action
 */
function* prepareSavingsData(action) {
    const costData = [];
    const { selectedSnapshot } = window.store.getState().jwtSnapshots;
    const startDate = new Date(action.data.realCosts.query.dateFrom);
    const endIterator = new Date(
        action.data.realCosts.query.dateTill.split('T')[0].split('-').join('-'),
    );
    if (action.data == null) {
        console.error('Invoices data is missing');
        return;
    }

    const invoicesForSelectedSnapshot = action.data.invoices.data
        .filter(
            (invoice) => invoice.metadata.snapshot_id === selectedSnapshot.id,
        )
        .filter(
            (invoice) =>
                invoice.metadata.invoice_type === enums.InvoiceTypeUpfront ||
                invoice.metadata.invoice_type === enums.InvoiceTypeMonthly,
        )
        .filter(
            (invoice) =>
                invoice.status !== enums.InvoiceStatusCredited &&
                invoice.status !== enums.InvoiceStatusInvalid,
        )
        .sort(
            (invoice, invoiceTwo) =>
                new Date(invoice.metadata.from) -
                new Date(invoiceTwo.metadata.from),
        );

    const calculated = _calculateDifferenceInMonths(startDate, endIterator);

    /**
     * here we walk trough all the months inbetween the start and end invoice.
     * we make sure that there are only 12 upfront invoices
     */
    let isSet = false;
    let previousFrom;
    let count = 0;
    for (let i = 0; i < calculated; i += 1) {
        const curDate = new Date(
            startDate.getFullYear(),
            startDate.getMonth() + i,
            1,
        );
        const costObject = {
            upfront:
                selectedSnapshot.calculated_product_group_verified
                    .monthly_pricing,
            month: getMonthShortNameFromDate(curDate),
            date: curDate,
        };
        /**
         * loop over al invoices for the snapshot to find all the upfront cost for that month
         */
        if (
            invoicesForSelectedSnapshot &&
            invoicesForSelectedSnapshot.length > 0
        ) {
            for (const invoice of invoicesForSelectedSnapshot) {
                const iDate = new Date(invoice.metadata.from);
                // at first set the upfront to null for the first invoice
                if (!isSet) {
                    costObject.upfront = null;
                }
                // if upfront is already set and its a future invoice overwrite total and form for that month
                if (isSet && invoice.metadata.from > previousFrom) {
                    costObject.upfront = invoice.total_incl;
                    previousFrom = invoice.metadata.from;
                    break;
                }
                // check if invoice is the current invoice and set total and from for that month
                if (
                    iDate.getMonth() === curDate.getMonth() &&
                    iDate.getFullYear() === curDate.getFullYear()
                ) {
                    if (!isSet) {
                        costObject.upfront = invoice.total_incl;
                        isSet = true;
                        previousFrom = invoice.metadata.from;
                        break;
                    }
                }
            }
        }
        // count every upfront invoice
        if (costObject.upfront !== null) {
            count += 1;
        }
        // you can only have twelf upfront invoices so if there are more set the future upfront invoice to null
        if (count > 12) {
            costObject.upfront = null;
        }
        costData.push(costObject);
    }

    if (action.data) {
        const { data } = action;

        addTotalsToTheCostType(
            data.realCosts,
            data.estCosts,
            costData,
            calculated,
        );
    }

    try {
        yield put(requestSavingsSuccess(costData));
    } catch (error) {
        yield put(requestSavingsFailed(error));
    }
}

/**
 * Runs doGetSavings
 * @param {Object} action
 */
function* doGetSavings(action) {
    const state = window.store.getState();
    const { selectedSnapshot } = state.jwtSnapshots;
    const userID = state.user.id;
    const requestObject = {
        dateFrom: action.dateFrom,
        dateTill: action.dateTill,
        snapshotID: selectedSnapshot.id,
        snapshotId: selectedSnapshot.id,
    };
    if (
        selectedSnapshot == null ||
        selectedSnapshot.snapshot_phase ===
            enums.SnapshotPhaseDemoContractCreated ||
        selectedSnapshot.snapshot_phase < enums.SnapshotPhaseSmartMeterChecked
    ) {
        return;
    }

    // TODO: validate if monthly values are correctly shown in other account then independer

    try {
        const [realCosts, estCosts, invoices] = yield all([
            call(
                betterWrapper,
                showMonthCosts.request({
                    ...requestObject,
                    predictions: false,
                }),
                showMonthCosts,
            ),
            call(
                betterWrapper,
                showMonthCostsPredictions.request({
                    ...requestObject,
                    predictions: true,
                }),
                showMonthCostsPredictions,
            ),
            call(betterWrapper, listInvoices.request({ userID }), listInvoices),
        ]);

        yield put(
            requestSavingsFetched({
                realCosts,
                estCosts,
                invoices,
            }),
        );
    } catch (e) {
        yield put(requestSavingsFailed(e));
    }
}

/**
 * Watcher for REQUEST_GRAPH action
 */
export function* watchRequestSavings() {
    yield takeLatest(REQUEST_SAVINGS, doGetSavings);
}

/**
 * Watcher for REQUEST_GRAPH_SUCCESS action
 */
export function* watchRequestSavingsFetched() {
    yield takeLatest(REQUEST_SAVINGS_FETCHED, prepareSavingsData);
}
