import { customElement, property, PropertyValues } from 'lit-element';
import { Slot } from './Slot';
import { RadioButton } from './RadioButton';

@customElement('ez-radio-buttons')
export class RadioButtons extends Slot<RadioButton> {
    @property({ type: String, attribute: 'attr-for-selected' })
    public attrForSelected?: string;

    @property({ type: String })
    public selected: string | number = 0;

    public updated = (changedProperties: PropertyValues): void => {
        super.updated(changedProperties);

        if (changedProperties.has('selected')) {
            // Update children
            this.setChildAttributes();
        }
    };

    /**
     * Super setting to filter on RadioButton
     */
    protected filterSlotted = [RadioButton];

    /**
     * Super setting to call on changes from <slot>
     */
    protected slottedChanged(elements: RadioButton[]): void {
        elements.forEach((e: RadioButton, i: number) => {
            // Set child index
            e.index = i;

            // Attach callbacks to new children
            if (e.selectCallback !== this._handleSelect) {
                e.selectCallback = this._handleSelect;
            }
        });

        // Update children
        this.setChildAttributes();

        if (this.selected === null || this.selected === undefined) {
            this._handleSelect(0);
        }
    }

    /**
     * Get the selectable by index
     */
    private _getSelectableByIndex(index: number): string {
        if (!Number.isNaN(index)) {
            let selectable: string | null = '';
            this.slotted.forEach((n, i) => {
                if (i === index) {
                    selectable = n.getAttribute(<string>this.attrForSelected);
                }
            });
            return selectable;
        }
        return '';
    }

    /**
     * Event to fire when selection is changed
     */
    private _handleSelect = (i: number): void => {
        let selected: number | string = i;
        if (this.attrForSelected) {
            selected = this._getSelectableByIndex(i);
        }
        this.selected = selected;
        this.dispatchEvent(
            new CustomEvent('value-changed', { detail: { selected } }),
        );
    };

    /**
     * Get the current selected index
     */
    private findSelectedIndex(): number {
        if (this.selected) {
            if (this.attrForSelected) {
                const selected = String(this.selected);
                let index = -1;
                this.slotted.forEach((n, i) => {
                    if (
                        n.getAttribute(<string>this.attrForSelected) ===
                        selected
                    ) {
                        index = i;
                    }
                });
                return index > 0 ? index : 0;
            }
            const index = !Number.isNaN(Number(this.selected))
                ? parseInt(String(this.selected), 10)
                : 0;
            if (index < this.slotted.length) {
                return index;
            }
        }
        return 0;
    }

    /**
     * Set the selected attributes for all children
     */
    private setChildAttributes = (): void => {
        setTimeout(() => {
            const selected = this.findSelectedIndex();
            this.slotted.forEach((n, i) => {
                if (i === selected) {
                    n.setAttribute('ez-radio-button', 'selected');
                    n.classList.add('ez-radio-button_selected');
                } else {
                    n.removeAttribute('ez-radio-button');
                    n.classList.remove('ez-radio-button_selected');
                }
            });
        }, 25);
    };
}

declare global {
    interface HTMLElementTagNameMap {
        'ez-radio-buttons': RadioButtons;
    }
}
