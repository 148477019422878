import {
    Bearer,
    BearerSupportedTypes,
    ISODate,
    ISODateSupportedTypes,
    ParameterDefinitionsObject,
    ParameterIn,
    RequestAction,
    RequestOptions,
    RequestSagaIterableIterator,
    ResolvableOrType,
    Resource,
    ResourceOptions,
    UUID,
    UUIDSupportedTypes,
} from 'goagen-js-resource';
import { Method } from 'axios';

export interface ShowCostRequestObject {
    // (Boolean)
    calculationDetails?: boolean;
    // (ISODate)
    dateFrom?: ISODateSupportedTypes;
    // (ISODate)
    dateTill?: ISODateSupportedTypes;
    // (Number)
    intervalType?: number;
    // (UUID)
    snapshotId?: UUIDSupportedTypes;
    // (Number)
    usageType?: number;
    // (UUID)
    userId?: UUIDSupportedTypes;
    // Injected Bearer header (Bearer)
    xAuth?: BearerSupportedTypes;
}

export interface ResolvableShowCostRequestObject {
    // (Boolean)
    calculationDetails?: ResolvableOrType<
        ShowCostRequestObject['calculationDetails']
    >;
    // (ISODate)
    dateFrom?: ResolvableOrType<ShowCostRequestObject['dateFrom']>;
    // (ISODate)
    dateTill?: ResolvableOrType<ShowCostRequestObject['dateTill']>;
    // (Number)
    intervalType?: ResolvableOrType<ShowCostRequestObject['intervalType']>;
    // (UUID)
    snapshotId?: ResolvableOrType<ShowCostRequestObject['snapshotId']>;
    // (Number)
    usageType?: ResolvableOrType<ShowCostRequestObject['usageType']>;
    // (UUID)
    userId?: ResolvableOrType<ShowCostRequestObject['userId']>;
    // Injected Bearer header (Bearer)
    xAuth?: ResolvableOrType<ShowCostRequestObject['xAuth']>;
}

/**
 * Store member management object for show cost
 */
export class ShowCost extends Resource<
    ShowCostRequestObject,
    ResolvableShowCostRequestObject
> {
    /**
     * Create new ShowCost
     *
     * | Parameter |  | Target |  |  |
     * | --- | --- | --- | --- | --- |
     * | `calculationDetails` | `Boolean` | query | `calculationDetails` |  |
     * | `dateFrom` * | `ISODate` | query | `dateFrom` |  |
     * | `dateTill` * | `ISODate` | query | `dateTill` |  |
     * | `intervalType` * | `Number` | query | `intervalType` |  |
     * | `snapshotId` * | `UUID` | query | `snapshotID` |  |
     * | `usageType` * | `Number` | query | `usageType` |  |
     * | `userId` | `UUID` | query | `userID` |  |
     * | `xAuth` | `Bearer` | header | `X-Auth` | Injected Bearer header |
     */
    constructor(
        storeName: string,
        options: ResourceOptions<ResolvableShowCostRequestObject> = {},
    ) {
        super(storeName, options);
    }

    /**
     * Generate request action
     *
     * | Parameter |  | Target |  |  |
     * | --- | --- | --- | --- | --- |
     * | `calculationDetails` | `Boolean` | query | `calculationDetails` |  |
     * | `dateFrom` * | `ISODate` | query | `dateFrom` |  |
     * | `dateTill` * | `ISODate` | query | `dateTill` |  |
     * | `intervalType` * | `Number` | query | `intervalType` |  |
     * | `snapshotId` * | `UUID` | query | `snapshotID` |  |
     * | `usageType` * | `Number` | query | `usageType` |  |
     * | `userId` | `UUID` | query | `userID` |  |
     * | `xAuth` | `Bearer` | header | `X-Auth` | Injected Bearer header |
     */
    public request(
        requestObject: ShowCostRequestObject = {},
        requestOptions: RequestOptions = {},
    ): RequestAction<ShowCostRequestObject> {
        return super.request(requestObject, requestOptions);
    }

    /**
     * Run request as saga
     *
     * | Parameter |  | Target |  |  |
     * | --- | --- | --- | --- | --- |
     * | `calculationDetails` | `Boolean` | query | `calculationDetails` |  |
     * | `dateFrom` * | `ISODate` | query | `dateFrom` |  |
     * | `dateTill` * | `ISODate` | query | `dateTill` |  |
     * | `intervalType` * | `Number` | query | `intervalType` |  |
     * | `snapshotId` * | `UUID` | query | `snapshotID` |  |
     * | `usageType` * | `Number` | query | `usageType` |  |
     * | `userId` | `UUID` | query | `userID` |  |
     * | `xAuth` | `Bearer` | header | `X-Auth` | Injected Bearer header |
     */
    // @ts-ignore
    public get requestSaga(): (
        requestObject?: ShowCostRequestObject,
        requestOptions?: RequestOptions,
    ) => RequestSagaIterableIterator<RequestAction<ShowCostRequestObject>> {
        return super.requestSaga;
    }

    /**
     * Action string
     */
    static get action(): string {
        return 'show';
    }

    /**
     * Description string
     */
    static get description(): string {
        return 'Get cost information for snapshot';
    }

    /**
     * Request method
     */
    static get method(): Method {
        return 'get';
    }

    /**
     * Resource string
     */
    static get resource(): string {
        return 'cost';
    }

    /**
     * Route string
     */
    static get route(): string {
        return '/cost/';
    }

    /**
     * Unique name string
     */
    static get uniqueName(): string {
        return 'ShowCost';
    }

    /**
     * Parameters object
     */
    static get parameters(): ParameterDefinitionsObject {
        return {
            calculationDetails: {
                in: ParameterIn.query,
                name: 'calculationDetails',
                type: Boolean,
            },
            dateFrom: {
                in: ParameterIn.query,
                name: 'dateFrom',
                required: true,
                type: ISODate,
            },
            dateTill: {
                in: ParameterIn.query,
                name: 'dateTill',
                required: true,
                type: ISODate,
            },
            intervalType: {
                in: ParameterIn.query,
                name: 'intervalType',
                required: true,
                type: Number,
            },
            snapshotId: {
                in: ParameterIn.query,
                name: 'snapshotID',
                required: true,
                type: UUID,
            },
            usageType: {
                in: ParameterIn.query,
                name: 'usageType',
                required: true,
                type: Number,
            },
            userId: {
                in: ParameterIn.query,
                name: 'userID',
                type: UUID,
            },
            xAuth: {
                description: 'Injected Bearer header',
                in: ParameterIn.header,
                name: 'X-Auth',
                type: Bearer,
            },
        };
    }
}
