import {
    LitElement,
    PropertyValues,
    TemplateResult,
    customElement,
    html,
    property,
    query,
} from 'lit-element';

import '../../components/elements/pagination-component/Pagination';
import '../../components/elements/tabs-component/TabsComponent';
import './components/business-declaration-tab/BusinessDeclarationTab';
import './components/information-header/InformationHeader';
import './components/invoice-tab/InvoiceTab';
import './components/vko-tab/VkoTab';

import { whitelabel } from '@weavelab/whitelabel';
import { ifDefined } from 'lit-html/directives/if-defined';
import { connect, watch } from 'lit-redux-watch';
import {
    // Pagination,
    BusinessDeclartions,
    InvoiceInfo,
    InvoiceInvoices,
    VkoInvoices,
} from 'types/documents';
import { Snapshot } from 'types/snapshot';
import { debounce } from '../../helpers/functions';
import { isPageMobile } from '../../helpers/mobileHelpers';
import { store } from '../../store';
import style from './style/style';

export type tabNames =
    | 'Facturen'
    | 'Verzamelfacturen'
    | 'Verzamel'
    | 'Zakelijke declaratiebon'
    | 'Zakelijk'
    | 'Verbruikskostenoverzicht'
    | 'Vko';

interface PaginationPageEvent extends CustomEvent {
    detail: {
        previousPage: number;
        page: number;
    };
}

export const PAGESIZE = 14;
const invoices = 0;
const collectiveInvoices = 1;
const businessDeclaration = 2;
const vko = 3;

@customElement('invoice-grid')
@whitelabel({ name: 'invoice-grid' })
export class InvoiceGrid extends connect(store)(LitElement) {
    @property({ type: String })
    public activeTabName: tabNames = 'Facturen';

    @property({ attribute: 'payment-status', type: String })
    public paymentStatus?: string;

    // pagination properties
    @property({ type: Number })
    private paginationPage: number = 1;

    @property({ type: Number })
    private paginationTotal: number = 0;

    @property({ type: Number })
    private paginationSize: number = PAGESIZE;

    @property({ type: Boolean, reflect: true })
    private hideDetailsForMobile: boolean = false;

    @watch('listBusinessDeclarationDocumentsUser.data.business_declarations')
    private bdInformation: InvoiceInfo[] = [];

    @watch('jwtSnapshots.selectedSnapshot')
    protected snapshot?: Snapshot;

    @query('invoice-tab')
    protected readonly invoiceTab?: HTMLElementTagNameMap['invoice-tab'];

    @query('business-declaration-tab')
    protected readonly businessDeclartaitonTab?: HTMLElementTagNameMap['business-declaration-tab'];

    @query('vko-tab')
    protected readonly vkoTab?: HTMLElementTagNameMap['vko-tab'];

    @query('tabs-component')
    public tabsComponent?: HTMLElementTagNameMap['tabs-component'];

    @watch('listInvoicesDocumentsUser.data')
    private invoiceInformation?: InvoiceInvoices;

    @watch('listInvoicesDocumentsBundleUser.data')
    private invoiceBundleInformation?: InvoiceInvoices;

    @watch('listVkoDocumentsUser.data')
    private vkoInformation?: VkoInvoices;

    @watch('listBusinessDeclarationDocumentsUser.data')
    private businessDeclarationInformation?: BusinessDeclartions;

    @property({ type: Array })
    private tabNames: tabNames[] = [
        'Facturen',
        'Verzamelfacturen',
        'Zakelijke declaratiebon',
        'Verbruikskostenoverzicht',
    ];

    @property({ type: Array })
    private tabNamesMobile: tabNames[] = [
        'Facturen',
        'Verzamel',
        'Zakelijk',
        'Vko',
    ];

    @property({ type: Array })
    private preventRenderFor: string[] = [
        this.tabNames[collectiveInvoices],
        this.tabNames[businessDeclaration],
        this.tabNames[vko],
    ];

    private preventRender = (hideDetailsForMobile: boolean): tabNames[] => {
        const tabNames = hideDetailsForMobile
            ? this.tabNamesMobile
            : this.tabNames;
        return [tabNames[collectiveInvoices], tabNames[businessDeclaration]];
    };

    constructor() {
        super();
        const isMobile = isPageMobile();
        if (!isMobile) {
            const windowSizeDebounced = debounce(800, this.reportWindowSize);
            window.addEventListener('resize', windowSizeDebounced);
        } else {
            this.hideDetailsForMobile = true;
        }
    }

    @whitelabel()
    static get styles() {
        return [style];
    }

    public render = (): TemplateResult => html`
        <information-header
            ?isMobile=${this.hideDetailsForMobile}
        ></information-header>
        <tabs-component
            theme="center"
            @tabs-active-name=${this.fetchActiveTab}
            .preventNamesToRender=${this.preventRender(
                this.hideDetailsForMobile,
            )}
        >
            <!-- Invoices -->
            <invoice-tab
                name="${this.tabNames[invoices]}"
                ?isMobile=${this.hideDetailsForMobile}
                paymentStatus=${ifDefined(this.paymentStatus)}
            ></invoice-tab>
            <!-- Invoices collections -->
            <invoice-tab
                name="${this.tabNames[collectiveInvoices]}"
                ?isMobile=${true}
                ?bundle=${true}
                paymentStatus=${ifDefined(this.paymentStatus)}
            ></invoice-tab>
            <!-- Business invoices -->
            <business-declaration-tab
                ?isMobile=${true}
                name="${this.tabNames[businessDeclaration]}"
            ></business-declaration-tab>
            <!-- Usage costs invoices -->
            <vko-tab name="${this.tabNames[vko]}"></vko-tab>
        </tabs-component>

        <footer>
            <div>
                ${this.paginationTotal}
                ${this.paginationTotal > 1 ? 'resultaten' : 'resultaat'}
            </div>
            <pagination-component
                page="${this.paginationPage}"
                total="${this.paginationTotal}"
                limit="${this.paginationSize}"
                size="2"
                @page-change=${this.fetchNewPagintation}
                ?isMobile=${this.hideDetailsForMobile}
            ></pagination-component>
        </footer>
    `;

    protected watchPagination() {
        switch (this.activeTabName) {
            case 'Facturen':
                this.paginationPage =
                    this.invoiceInformation?.pagination.page || 1;
                this.paginationTotal =
                    this.invoiceInformation?.pagination.total || 0;
                break;
            case 'Verzamelfacturen':
            case 'Verzamel':
                this.paginationPage =
                    this.invoiceBundleInformation?.pagination.page || 1;
                this.paginationTotal =
                    this.invoiceBundleInformation?.pagination.total || 0;
                break;
            case 'Verbruikskostenoverzicht':
            case 'Vko':
                this.paginationPage = this.vkoInformation?.pagination.page || 1;
                this.paginationTotal =
                    this.vkoInformation?.pagination.total || 0;
                break;
            case 'Zakelijke declaratiebon':
            case 'Zakelijk':
                this.paginationPage =
                    this.businessDeclarationInformation?.pagination.page || 1;
                this.paginationTotal =
                    this.businessDeclarationInformation?.pagination.total || 0;
                break;
            default:
                this.paginationPage = 1;
        }
    }

    protected updated(changedProperties: PropertyValues) {
        super.updated(changedProperties);
        this.watchPagination();
        if (changedProperties.has('snapshots') && this.snapshot) {
            this.preventRenderFor = [...this.preventRenderFor];
        }

        // check if business declaration information needs to be hidden
        if (changedProperties.has('bdInformation')) {
            const hidden = this.bdInformation?.length === 0;
            if (hidden) {
                this.businessDeclartaitonTab?.removeAttribute('hidden');
            } else {
                this.businessDeclartaitonTab?.setAttribute('hidden', 'true');
            }
        }

        if (changedProperties.has('hideDetailsForMobile')) {
            this.tabNames[collectiveInvoices] = this.hideDetailsForMobile
                ? 'Verzamel'
                : 'Verzamelfacturen';
            this.tabNames[businessDeclaration] = this.hideDetailsForMobile
                ? 'Zakelijk'
                : 'Zakelijke declaratiebon';
            this.tabNames[vko] = this.hideDetailsForMobile
                ? 'Vko'
                : 'Verbruikskostenoverzicht';
            this.tabNames = [...this.tabNames];
        }
    }

    // Fetch invoice information if the selected tab changes
    private fetchActiveTab = (e: MouseEvent): void => {
        e.stopImmediatePropagation();
        this.activeTabName = e.detail as unknown as tabNames;
    };

    // when pagination component changes update the pagination logic.
    private fetchNewPagintation = (e: PaginationPageEvent): void => {
        e.stopImmediatePropagation();
        const pageNumber: number = e.detail.page;

        switch (this.activeTabName) {
            case 'Facturen':
                this.invoiceTab?.updatePaginationData(pageNumber);
                break;
            case 'Verbruikskostenoverzicht':
            case 'Vko':
                this.vkoTab?.updatePaginationData(pageNumber);
                break;
            case 'Zakelijke declaratiebon':
            case 'Zakelijk':
                this.businessDeclartaitonTab?.updatePaginationData(pageNumber);
                break;
            default:
                console.warn('Unhandled tab case!');
                break;
        }
    };

    // resize window if required
    private reportWindowSize = (): void => {
        const isSmaller = window.innerWidth <= 870;
        // only update if the state has changed.
        if (isSmaller !== this.hideDetailsForMobile) {
            this.hideDetailsForMobile = isSmaller;
        }
    };
}

declare global {
    interface HTMLElementTagNameMap {
        'invoice-grid': InvoiceGrid;
    }
}
