export const PREFIX = 'User snapshot: ';

/**
 * @typedef {Object} CreateUserSnapshotPayload
 */

export const CLEAR_CREATE_USER_SNAPSHOT_QUERY = `${PREFIX}CLEAR_CREATE_USER_SNAPSHOT_QUERY`;
/**
 * Action generator to clear the sent create user snapshot
 * @return {Object}
 */
export const clearCreateUserSnapshotQuery = () => ({
    type: CLEAR_CREATE_USER_SNAPSHOT_QUERY,
});

export const REQUEST_CREATE_USER_SNAPSHOT = `${PREFIX}REQUEST_CREATE_USER_SNAPSHOT`;
/**
 * Action generator for request create user snapshot
 * @param {CreateUserSnapshotPayload} payload
 * @return {Object}
 */
export const requestCreateUserSnapshot = (payload) => ({
    type: REQUEST_CREATE_USER_SNAPSHOT,
    payload,
});

export const REQUEST_CREATE_USER_SNAPSHOT_SUCCESS = `${PREFIX}REQUEST_CREATE_USER_SNAPSHOT_SUCCESS`;
/**
 * Success callback action for create user snapshot
 * @param {Object} data
 * @return {Object}
 */
export const requestCreateUserSnapshotSuccess = (data) => ({
    type: REQUEST_CREATE_USER_SNAPSHOT_SUCCESS,
    data,
});

export const REQUEST_CREATE_USER_SNAPSHOT_FAILED = `${PREFIX}REQUEST_CREATE_USER_SNAPSHOT_FAILED`;
/**
 * Error callback action for create user snapshot
 * @param {Object} error
 * @return {Object}
 */
export const requestCreateUserSnapshotFailed = (error) => ({
    type: REQUEST_CREATE_USER_SNAPSHOT_FAILED,
    error,
});

export const RESET_CREATE_USER_SNAPSHOT = `${PREFIX}RESET_CREATE_USER_SNAPSHOT`;
/**
 * Action generator to reset create user snapshot
 * @return {Object}
 */
export const resetCreateUserSnapshot = () => ({
    type: RESET_CREATE_USER_SNAPSHOT,
});

export const REROUTE_USER = `${PREFIX}REROUTE_USER`;
/**
 * Error callback action for create user snapshot
 * @param {string} route
 * @return {Object}
 */
export const requestRerouteUser = (route) => ({ type: REROUTE_USER, route });
