import {
    REQUEST_OFFER,
    REQUEST_OFFER_SUCCESS,
    REQUEST_OFFER_FAILED,
    ADD_OFFER,
    REPLACE_OFFERS,
    RESET_OFFER,
    AGREE_OFFER,
} from '../actions/offer';

/** @typedef {import('../actions/offer').OfferAction} OfferAction */

/**
 * @typedef Offer
 * @property {string} id UUID of the offer
 * @property {string} [name] of the offer
 * @property {ProductGroup} product_group attached to offer
 */

/**
 * @typedef ProductGroup
 * @property {string} id UUID of the product group
 * @property {string} name of the offer (parent)
 * @property {number} payment_interval enum of when product group payment is done
 * @property {number} payment_type enum of how product group should be payed (voorschot)
 * @property {Array<Product>} products the products in product group
 */

/**
 * @typedef Product
 * @property {string} id UUID of product
 * @property {string} name of product
 * @property {number} type enum of product type
 * @property {Array<Cost>} cost Array of cost object which are subobject for building price for product
 */

/**
 * @typedef Cost
 * @property {number} [calculated_amount] is unit amount of products used for given interval/amount used for calculation
 * @property {number} [calculated_price] is calculated price for given interval/amount used for calculation
 * @property {string} description of the product price rule
 * @property {number} interval type of the product price rule
 * @property {number} [margin_percentage] margin over price in percentage
 * @property {number} [margin_price] margin over price in constant price
 * @property {number} [price] is estimated price of current price rule
 * @property {number} type enum of price rule
 */

/**
 * Reducer for offer state
 * @param {Object} state
 * @param {OfferAction} action
 * @return {Object}
 */
const offer = (state = {}, action) => {
    switch (action.type) {
        case ADD_OFFER:
            return {
                ...state,
            };
        default:
            return state;
    }
};

/**
 * State reducer for offers state
 * @param {Object} state
 * @param {OfferAction} action
 * @return {Object}
 */
export const offers = (state = {}, action) => {
    switch (action.type) {
        case AGREE_OFFER:
            return state;
        case REQUEST_OFFER:
            return {};
        case REQUEST_OFFER_FAILED:
            return {
                ...state,
                error: action.error,
            };
        case RESET_OFFER:
            return {};
        case ADD_OFFER: {
            const next = { ...state };
            if (next.error !== undefined) {
                delete next.error;
            }
            return {
                ...next,
                [action.offer.id]: offer(action.offer, action),
            };
        }
        case REQUEST_OFFER_SUCCESS:
        case REPLACE_OFFERS: {
            /** @type {Object} */
            const res = {};
            let allOffers = action.offers;
            if (!action.offers && action.data) {
                allOffers = action.data;
            }
            allOffers.forEach((el) => {
                res[el.id] = offer(el, { type: ADD_OFFER });
            });
            return res;
        }
        default:
            return state;
    }
};

export default offers;
