export const PREFIX = 'Login prompt: ';

export const SHOW_LOGIN_PROMPT = `${PREFIX}SHOW_LOGIN_PROMPT`;
/**
 * Show login prompt
 * @param {String} [message]
 * @return {Object}
 */
export const showLoginPrompt = (message) => ({
    type: SHOW_LOGIN_PROMPT,
    message,
});

export const HIDE_LOGIN_PROMPT = `${PREFIX}HIDE_LOGIN_PROMPT`;
/**
 * Hide login prompt
 * @return {Object}
 */
export const hideLoginPrompt = () => ({ type: HIDE_LOGIN_PROMPT });
