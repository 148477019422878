import { all, call, put, takeLatest } from 'redux-saga/effects';
import { betterWrapper } from './helpers/wrapper';
import {
    REQUEST_GRAPH,
    REQUEST_GRAPH_SUCCESS,
    requestGraphFailed,
    requestGraphSuccess,
} from '../actions/graph';
import { prepareData } from '../actions/usage';
import { multipleCostsToUsage } from '../helpers/calculateMonthlyValues';
import {
    getDetailed,
    getDetailedFromMultiple,
} from '../components/atoms/HvUsageHeader';

import {
    store,
    gasUsage,
    gasUsageEstimation,
    electricityUsage,
    electricityUsageEstimation,
    productionUsage,
    elekProdCost,
    elekUsageCost,
    gasUsageCost,
    chargingSessionsUsage,
} from '../store';
import enums from '../enums';

/**
 * Runs prepareGraphData
 * @param {Object} action
 */
function* prepareGraphData(action) {
    try {
        yield put(
            prepareData(
                action.data,
                action.requestObject.intervalType,
                action.requestObject.usageType,
            ),
        );
    } catch (error) {
        console.warn('Err', error);
    }
}

/**
 * Checks if current month and year is same as active contract start date if so corrects is
 * @param {Object} snapshot
 * @param {string} dateFrom
 * @return {Object}
 */
export const getDataFromSnapshot = (snapshot, dateFrom) => {
    let startDate = new Date();
    /* eslint-disable camelcase */
    if (snapshot?.verified_snapshot_payload?.start_date) {
        startDate = snapshot.verified_snapshot_payload.start_date;
    }
    const snapshotID = snapshot.id;

    const startDateZero = new Date(startDate);
    startDateZero.setHours(0, 0, 0, 0);
    const realStartDate = startDateZero.toISOString();
    if (dateFrom < realStartDate) {
        dateFrom = realStartDate;
    }
    return { snapshotID, dateFrom };
};

/**
 * Runs doGetGraphData
 * @param {Object} action
 */
function* doGetGraphData(action) {
    const state = store.getState();

    let requestObject = {
        dateFrom: action.dateFrom,
        dateTill: action.dateTill,
        intervalType: enums.IntervalTypeHourly,
    };

    // check if snapshot is given else use the selected snapshot from the store.
    const selectedSnapshot = action.snapshot
        ? action.snapshot
        : state.jwtSnapshots.selectedSnapshot;
    if (selectedSnapshot) {
        const { snapshotID, dateFrom } = getDataFromSnapshot(selectedSnapshot);
        requestObject.snapshotID = snapshotID;
        requestObject.snapshotId = snapshotID;
        if (dateFrom) {
            requestObject.dateFrom = dateFrom;
        }
    }
    if (action.usageType === enums.UsageTypeElekUsage) {
        try {
            // elec usages
            requestObject = { ...requestObject, calculationDetails: true };

            if (!action.chargingSessions) {
                const [elecCosts] = yield all([
                    call(
                        betterWrapper,
                        elekUsageCost.request(requestObject),
                        elekUsageCost,
                    ),
                ]);
                const [usages, estimations] = yield all([
                    call(
                        betterWrapper,
                        electricityUsage.request(requestObject),
                        electricityUsage,
                    ),
                    call(
                        betterWrapper,
                        electricityUsageEstimation.request(requestObject),
                        electricityUsageEstimation,
                    ),
                ]);
                requestObject.usageType = action.usageType;
                requestObject.intervalType = action.intervalType;
                const detailed = getDetailed(elecCosts.data);
                yield put(
                    requestGraphSuccess(
                        { usages, estimations, detailed },
                        requestObject,
                    ),
                );
            } else {
                // elec charging sessions usages
                const usages = yield call(
                    betterWrapper,
                    chargingSessionsUsage.request(requestObject),
                    chargingSessionsUsage,
                );
                requestObject.usageType = action.usageType;
                requestObject.intervalType = action.intervalType;
                yield put(requestGraphSuccess({ usages }, requestObject));
            }
        } catch (e) {
            yield put(requestGraphFailed(e));
        }
    } else if (action.usageType === enums.UsageTypeGasUsage) {
        try {
            requestObject = { ...requestObject, calculationDetails: true };

            const [gasCosts] = yield all([
                call(
                    betterWrapper,
                    gasUsageCost.request(requestObject),
                    gasUsageCost,
                ),
            ]);
            const [usages, estimations] = yield all([
                call(betterWrapper, gasUsage.request(requestObject), gasUsage),
                call(
                    betterWrapper,
                    gasUsageEstimation.request(requestObject),
                    gasUsageEstimation,
                ),
            ]);
            requestObject.usageType = action.usageType;
            requestObject.intervalType = action.intervalType;
            const detailed = getDetailed(gasCosts.data);

            yield put(
                requestGraphSuccess(
                    { usages, estimations, detailed },
                    requestObject,
                ),
            );
        } catch (e) {
            yield put(requestGraphFailed(e));
        }
    } else if (action.usageType === enums.UsageTypeElekProd) {
        try {
            const [usages] = yield all([
                call(
                    betterWrapper,
                    productionUsage.request(requestObject),
                    productionUsage,
                ),
            ]);
            requestObject.usageType = action.usageType;
            requestObject.intervalType = action.intervalType;

            yield put(requestGraphSuccess({ usages }, requestObject));
        } catch (e) {
            yield put(requestGraphFailed(e));
        }
    } else {
        requestObject = { ...requestObject, calculationDetails: true };
        try {
            const [elecCosts, gasCosts, prodCosts] = yield all([
                call(
                    betterWrapper,
                    elekUsageCost.request(requestObject),
                    elekUsageCost,
                ),
                call(
                    betterWrapper,
                    gasUsageCost.request(requestObject),
                    gasUsageCost,
                ),
                call(
                    betterWrapper,
                    elekProdCost.request(requestObject),
                    elekProdCost,
                ),
            ]);

            requestObject.usageType = action.usageType;
            requestObject.intervalType = action.intervalType;

            const detailed = getDetailedFromMultiple(
                elecCosts.data,
                gasCosts.data,
                prodCosts.data,
            );

            elecCosts.data = multipleCostsToUsage(
                elecCosts.data,
                gasCosts.data,
                prodCosts.data,
            );

            yield put(
                requestGraphSuccess(
                    { usages: elecCosts, detailed },
                    requestObject,
                ),
            );
        } catch (e) {
            yield put(requestGraphFailed(e));
        }
    }
}

/**
 * Watcher for REQUEST_GRAPH action
 */
export function* watchRequestGraph() {
    yield takeLatest(REQUEST_GRAPH, doGetGraphData);
}

/**
 * Watcher for REQUEST_GRAPH_SUCCESS action
 */
export function* watchRequestGraphSuccess() {
    yield takeLatest(REQUEST_GRAPH_SUCCESS, prepareGraphData);
}
