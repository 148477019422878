export const PREFIX = 'User payment account: ';

/**
 * @typedef {Object} CreateUserPaymentAccountPayload
 */

export const CLEAR_CREATE_USER_PAYMENT_ACCOUNT_QUERY = `${PREFIX}CLEAR_CREATE_USER_PAYMENT_ACCOUNT_QUERY`;
/**
 * Action generator to clear the sent create user payment account query
 * @return {Object}
 */
export const clearCreateUserPaymentAccountQuery = () => ({
    type: CLEAR_CREATE_USER_PAYMENT_ACCOUNT_QUERY,
});

export const REQUEST_CREATE_USER_PAYMENT_ACCOUNT = `${PREFIX}REQUEST_CREATE_USER_PAYMENT_ACCOUNT`;
/**
 * Action generator for request create user payment account
 * @param {CreateUserPaymentAccountPayload} payload
 * @return {Object}
 */
export const requestCreateUserPaymentAccount = (payload) => ({
    type: REQUEST_CREATE_USER_PAYMENT_ACCOUNT,
    payload,
});

export const REQUEST_CREATE_USER_PAYMENT_ACCOUNT_SUCCESS = `${PREFIX}REQUEST_CREATE_USER_PAYMENT_ACCOUNT_SUCCESS`;
/**
 * Success callback action for create user payment account
 * @param {Object} data
 * @return {Object}
 */
export const requestCreateUserPaymentAccountSuccess = (data) => ({
    type: REQUEST_CREATE_USER_PAYMENT_ACCOUNT_SUCCESS,
    data,
});

export const REQUEST_CREATE_USER_PAYMENT_ACCOUNT_FAILED = `${PREFIX}REQUEST_CREATE_USER_PAYMENT_ACCOUNT_FAILED`;
/**
 * Error callback action for create user payment account
 * @param {Object} error
 * @return {Object}
 */
export const requestCreateUserPaymentAccountFailed = (error) => ({
    type: REQUEST_CREATE_USER_PAYMENT_ACCOUNT_FAILED,
    error,
});

export const RESET_CREATE_USER_PAYMENT_ACCOUNT = `${PREFIX}RESET_CREATE_USER_PAYMENT_ACCOUNT`;
/**
 * Action generator to reset create user payment account
 * @return {Object}
 */
export const resetCreateUserPaymentAccount = () => ({
    type: RESET_CREATE_USER_PAYMENT_ACCOUNT,
});
