import { LionAccordion } from '@lion/accordion';
import { customElement } from 'lit-element';

import '@atoms/icon/HvIcon';
// import { StoreEntry } from '@lion/accordion/src/LionAccordion';

@customElement('hv-accordion')
export class HvAccordion extends LionAccordion {}

declare global {
    interface HTMLElementTagNameMap {
        'hv-accordion': HvAccordion;
    }
}
