import { CSSResult, unsafeCSS } from 'lit-element';
import {
    className,
    ClassPropertyKey,
    WhitelabelClass,
    WhitelabelMiddleware,
    WhitelabelMiddlewareConnectors,
    WhitelabelNextBinder,
    WhitelabelNextGetter,
} from '@weavelab/whitelabel';
import { layoutFlex } from './cssApplyDataRaw';

/**
 * All @apply CSS blocks for patching. These can be removed from the codebase
 * once all @apply occurrences have been removed from all theme files.
 */
const applies: { [k: string]: string } = {
    ...layoutFlex,
    '--radiobutton-mixin': `
        display: flex;
        -ms-flex-direction: var(--radiobutton-mixin_-_flex-direction, var(--at-apply-fdir));
        -webkit-flex-direction: var(--radiobutton-mixin_-_flex-direction, var(--at-apply-fdir));
        flex-direction: var(--radiobutton-mixin_-_flex-direction, var(--at-apply-fdir));
    `,
};

/**
 * All @apply CSS definitions for patching. These can be removed from the
 * codebase once all @apply occurrences have been removed from all theme files.
 */
const defs: { [k: string]: { expect: string; replace: string } } = {
    '--radiobutton-mixin': {
        expect: applies['--layout-vertical'],
        replace: `--radiobutton-mixin_-_flex-direction: column;`,
    },
};

/**
 * Patches CSS @apply cases in input CSS string.
 */
function patchApplies(str: string): string {
    return str.replace(
        /@apply\s+([a-zA-Z0-9-_]*)([\s;}])/g,
        (match: string, name: string, terminator: string): string => {
            const apply = applies[name];

            if (!apply) {
                console.warn(`Unmatched @apply ${name}`);

                return match;
            }

            const suffix: string = terminator === ';' ? '' : terminator;

            return `/* Patched @apply ${name} */${apply}${suffix}`;
        },
    );
}

/**
 * Patches CSS @apply variable definitions in input CSS string.
 */
function patchDefs(str: string): string {
    return str.replace(
        /([^a-zA-Z0-9-_])(--[a-zA-Z0-9-_]*)\s*:\s*{\s*([^}]*?)\s*}/g,
        (match: string, prev: string, name: string, value: string): string => {
            // if key in defs doesnt exists
            if (!(name in defs)) {
                return match;
            }
            const { expect, replace } = defs[name];

            if (value !== expect) {
                console.warn(`Unmatched @apply definition ${name}`);

                return match;
            }

            return `${prev}/* Patched @apply ${name} definition */${replace}`;
        },
    );
}

// Caching map for parsed CSS
const memoized: Map<string, string> = new Map();
/**
 * Patches input CSS.
 */
function patch(str: string): string {
    // Return cached patch if it exists
    const memo: string | undefined = memoized.get(str);
    if (memo !== undefined) {
        return memo;
    }

    // Patch, cache, and return
    const patched: string = patchDefs(patchApplies(str));
    memoized.set(str, patched);

    return patched;
}

/**
 * Unpacks `static get styles() { ... }` result and patches it.
 */
function processStylesResult(some: any): any {
    switch (typeof some) {
        case 'object':
            // Handle arrays
            if (Array.isArray(some)) {
                return some.map(processStylesResult);
            }

            // Handle CSSResults
            if (typeof (<CSSResult>some).cssText === 'string') {
                return unsafeCSS(patch(some.cssText));
            }

            // Do nothing
            return some;
        case 'string':
            // Patch CSS string
            return patch(some);
        default:
            // Do nothing
            return some;
    }
}

/**
 * CSS @apply patch whitelabel middleware
 */
export const cssApplyPatchWhitelabelMiddleware: WhitelabelMiddleware = (
    whitelabel: WhitelabelMiddlewareConnectors,
): void => {
    // Register styles patcher
    whitelabel.addPropertyGetterMiddleware(
        (next: WhitelabelNextGetter) => (key: ClassPropertyKey) => {
            // Skip whitelabel variables that are not styles
            if (key !== 'styles') {
                return next();
            }

            // Return patched CSS
            return processStylesResult(next());
        },
    );

    // Register reporter in DEV mode
    if (window?.env?.dev) {
        whitelabel.addPropertyBinderMiddleware((next: WhitelabelNextBinder) => {
            const reports: string[] = [];

            /**
             * Add message to reporter and trigger a debounced report.
             */
            const report = (message: string): void => {
                if (reports.indexOf(message) < 0) {
                    reports.push(message);

                    const c = reports.length;
                    setTimeout(() => {
                        if (c === reports.length) {
                            console.groupCollapsed(
                                `%cPatched ${c} @apply occurrences in CSS`,
                                `
                                    font-size: 12px;
                                    font-weigth: bold;
                                    background-color: red;
                                    padding: 2px 5px;
                                    margin: 5px 2px;
                                    color: white;
                                    border-radius: 5px;
                                `,
                            );
                            reports.sort().forEach((r) => {
                                console.log(
                                    r,
                                    'color: red; font-weight: bold;',
                                );
                            });
                            console.log(
                                '%cAvoid the use of @apply probably...',
                                'font-weight: bold;',
                            );
                            console.groupEnd();
                        }
                    }, 2000);
                }
            };

            /**
             * Unpack CSS complex into array of CSS strings.
             */
            const clean = (v: any): string[] => {
                switch (typeof v) {
                    case 'string':
                        return [v];
                    case 'object':
                        if (Array.isArray(v)) {
                            return v.reduce((a, o) => [...a, ...clean(o)], []);
                        }

                        if (typeof (<CSSResult>v).cssText === 'string') {
                            return [(<CSSResult>v).cssText];
                        }

                        return [];
                    default:
                        return [];
                }
            };

            const rex = /\/\* (Patched @apply \S+( definition)?) \*\//g;

            return (
                _,
                target: Object,
                key: ClassPropertyKey,
            ): PropertyDescriptor => {
                // Get base descriptor
                const base: PropertyDescriptor = next();

                // Only handle styles
                if (key !== 'styles' || typeof base.get !== 'function') {
                    return base;
                }

                // Patch PropertyDescriptor to report on finalization
                return {
                    ...base,
                    get(): any {
                        // Run original getter
                        const v: any = base.get!.apply(this);

                        // Get component name
                        const clazz = className(<WhitelabelClass>target);

                        // Report @apply patches
                        clean(v).forEach((css) => {
                            css.replace(rex, (nop, match) => {
                                report(
                                    // @ts-ignore
                                    `${match} in %cclass ${target.name} <${clazz}>`,
                                );

                                return nop;
                            });
                        });

                        return v;
                    },
                };
            };
        });
    }
};
