import { css } from 'lit-element';

export default css`
    :host {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        overflow: auto;
    }
    :host() {
        --grey: #e5e5e5;
    }
    :host([hideDetailsForMobile]) invoice-tab,
    :host([hideDetailsForMobile]) vko-tab,
    :host([hideDetailsForMobile]) business-declaration-tab {
        margin: 0;
    }

    :host([hideDetailsForMobile]) footer {
        padding: 0 24px 0 23px;
    }

    tabs-component {
        --tab--color: none;
        --tab__font--color: var(--tertiary-color);
        --tab__font--hover-color: var(--primary-color);
        --tab__font--active-color: var(--primary-color);
        --tab__font--active-weight: 900;
        --tab__font--size: 18px
        --tab__background--color: none;
        --tab__hover--background--color: none;
        --tab__active--color: none;
        --tab__padding: 0;
        --tab--height: 70px;

        --tab__border: 2px solid var(--grey);
        --tab__border--bottom: 2px solid #e5e5e5;
        --tab__border--bottom--active: 2px solid var(--primary-color);
    }

    footer {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        align-items: center;
        background-color: #F6F8F9;
        border-radius: 0 0 2px 2px;
        height: 48px;
        padding: 0 90px 0 24px;
        font-size: 14px;
    }

    invoice-tab,
    vko-tab,
    business-declaration-tab {
        margin: 33px 0 0;
    }

    @media only screen and (max-width: 780px) {
        :host([hideDetailsForMobile]) footer {
            padding-bottom: 20px;
        }
    }
`;
