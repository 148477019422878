import { provider } from '@weavelab/frontend-connect';
import EzUsageTemplate from '../../templates/ez-usage-template/usage-template';
import '../../molecules/HvNotificationComponent';
import settings from '../../../internationalization/settings';

const themeSettings = provider.settings('energy-costs');

/**
 * Class for showing Energy usage using Usage Template
 */
export default class EzEnergyCosts extends EzUsageTemplate {
    /**
     * Constructor for EzElectricity
     */
    constructor() {
        super();
        /** @type {Number | null} */
        this.usageType = null;
        /** @type {String} */
        this.label = 'Euro';
        /** @type {string} */
        this.usageHeaderType = 'cost';
        /** @type {Boolean} */
        this.active = false;
        /** @type {String} */
        this.appTitle =
            themeSettings && themeSettings.title !== undefined
                ? themeSettings.title
                : this.appTitle;
        /** @type {String} */
        this.dataColor = settings.energyCostAccentColor;
        /** @type {String} */
        this.businessDataColor = settings.energyCostBusinessAccentColor;
    }

    /**
     * Callback for global state changed
     * @param  {...any} args
     */
    _stateChanged(...args) {
        // @ts-ignore
        super._stateChanged(...args);
    }
}

window.customElements.define('ez-energy-costs', EzEnergyCosts);
