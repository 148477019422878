import axios from 'axios';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
    REQUEST_CREATE_USER_PAYMENT_ACCOUNT,
    requestCreateUserPaymentAccountSuccess,
    requestCreateUserPaymentAccountFailed,
} from '../actions/userPaymentAccount';
import headers from './helpers/headers';

import { store } from '../store';

/**
 * Create userPaymentAccount fetcher
 * @param {Object} action
 */
function* fetchCreateUserPaymentAccount(action) {
    const state = store.getState();

    /** @type {String} */
    const userId = state.user.id;

    try {
        if (!userId) throw state.user;
        const result = yield call(async () => {
            const response = await axios.post(
                `/v1/user/${userId}/paymentaccount/create`,
                { ...action.payload },
                {
                    baseURL: window.API_LINK,
                    headers: headers(STDHeaders),
                },
            );
            if (response.statusText === 'OK' || response.status === 200) {
                return { data: response.data };
            }
            throw response.status;
        });
        yield put(requestCreateUserPaymentAccountSuccess(result.data));
    } catch (e) {
        yield put(requestCreateUserPaymentAccountFailed(e));
    }
}

/**
 * Watcher for REQUEST_CREATE_USER_PAYMENT_ACCOUNT action
 */
export function* watchRequestCreateUserPaymentAccount() {
    yield takeLatest(
        REQUEST_CREATE_USER_PAYMENT_ACCOUNT,
        fetchCreateUserPaymentAccount,
    );
}
