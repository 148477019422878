export const PREFIX = 'User location: ';

/**
 * @typedef {Object} CreateLocationPayload
 */

export const CLEAR_CREATE_USER_LOCATION_QUERY = `${PREFIX}CLEAR_CREATE_USER_LOCATION_QUERY`;
/**
 * Action generator to clear the sent create user location
 * @return {Object}
 */
export const clearCreateUserLocationQuery = () => ({
    type: CLEAR_CREATE_USER_LOCATION_QUERY,
});

export const REQUEST_CREATE_LOCATION = `${PREFIX}REQUEST_CREATE_LOCATION`;
/**
 * Action generator for request create location
 * @param {CreateLocationPayload} payload
 * @param {LocationType} locationType
 * @return {Object}
 */
export const requestCreateLocation = (payload, locationType) => ({
    type: REQUEST_CREATE_LOCATION,
    payload,
    locationType,
});

export const REQUEST_CREATE_USER_LOCATION_SUCCESS = `${PREFIX}REQUEST_CREATE_USER_LOCATION_SUCCESS`;
/**
 * Success callback action for create user location
 * @param {Object} data
 * @return {Object}
 */
export const requestCreateUserLocationSuccess = (data) => ({
    type: REQUEST_CREATE_USER_LOCATION_SUCCESS,
    data,
});

export const RESET_CREATE_USER_LOCATION = `${PREFIX}RESET_CREATE_USER_LOCATION`;
/**
 * Action generator to reset create user location
 * @return {Object}
 */
export const resetCreateUserLocation = () => ({
    type: RESET_CREATE_USER_LOCATION,
});

export const REQUEST_CREATE_LOCATION_FAILED = `${PREFIX}REQUEST_CREATE_LOCATION_FAILED`;
/**
 * Error callback action for create user location
 * @param {Object} error
 * @return {Object}
 */
export const requestCreateLocationFailed = (error) => ({
    type: REQUEST_CREATE_LOCATION_FAILED,
    error,
});

export const REQUEST_CREATE_INVOICE_LOCATION = `${PREFIX}REQUEST_CREATE_INVOICE_LOCATION`;
/**
 * Action generator for request create location
 * @param {CreateLocationPayload} payload
 * @param {LocationType} locationType
 * @return {Object}
 */
// eslint-disable-next-line no-unused-vars
export const requestCreateInvoiceLocation = (payload, locationType) => ({
    type: REQUEST_CREATE_INVOICE_LOCATION,
    payload,
    locationType,
});

export const REQUEST_CREATE_INVOICE_LOCATION_SUCCESS = `${PREFIX}REQUEST_CREATE_INVOICE_LOCATION_SUCCESS`;
/**
 * Success callback action for create invoice location
 * @param {Object} data
 * @return {Object}
 */
export const requestCreateInvoiceLocationSuccess = (data) => ({
    type: REQUEST_CREATE_INVOICE_LOCATION_SUCCESS,
    data,
});

export const REQUEST_CREATE_INVOICE_LOCATION_FAILED = `${PREFIX}REQUEST_CREATE_INVOICE_LOCATION_FAILED`;
/**
 * Error callback action for create user location
 * @param {Object} error
 * @return {Object}
 */
export const requestCreateInvoiceLocationFailed = (error) => ({
    type: REQUEST_CREATE_INVOICE_LOCATION_FAILED,
    error,
});

export const RESET_CREATE_INVOICE_LOCATION = `${PREFIX}RESET_CREATE_INVOICE_LOCATION`;
/**
 * Action generator to reset create user location
 * @return {Object}
 */
export const resetCreateInvoiceLocation = () => ({
    type: RESET_CREATE_INVOICE_LOCATION,
});

export const USER_LOCATION = `USER`;
export const INVOICE_LOCATION = `INVOICE`;
