import { PolymerElement, html } from '@polymer/polymer/polymer-element';
import { provider } from '@weavelab/frontend-connect';

import watch from 'redux-watch';

import {
    ConvertVATDecimalToInteger,
    GetVATFromProduct,
    VATPercentage,
} from 'helpers/vat';
import enums from 'enums';
import { store } from 'data-access/store';
import { currency } from 'helpers/currency';
import css from './product-details.pcss';
import template from './product-details.html';

import '@polymer/paper-button/paper-button';
import '@polymer/iron-flex-layout/iron-flex-layout-classes';

import { NumberToPrice, parseVatFloat, roundNumber } from '../helpers/number';

const themeSettings = provider.settings('product-details');
// FIXME: https://energyzero.atlassian.net/browse/EN-2388
const ledgerCodeForSubsidy = '1809';
const descriptionGasSubsidy = 'Prijsplafond gas';
const descriptionElecSubsidy = 'Prijsplafond stroom';

/**
 *  Typescript types
 * @typedef {import('../../../../types/productDetails').FootNote} FootNote
 * @typedef {import('../../../../types/productDetails').FootNoteType} FootNoteType
 */

/**
 * Login class
 */
export default class EzProductDetails extends PolymerElement {
    /** @typedef {import('../../../../actions/offer').Offer} Offer */
    /** @typedef {import('../../../../reducers/order').Order} Order */

    /**
     * Gets properties of class
     */
    static get properties() {
        return {
            configuration: {
                type: Object,
            },
            collective: {
                type: String,
            },
            collectives: {
                type: Array,
            },
            order: {
                type: Object,
                value: {
                    chargePoleUsage: null,
                    collective: null,
                    electricity: null,
                    electricityProductionEstimate: null,
                    electricityUsageEstimate: null,
                    electricityUsageFactorEstimate: null,
                    gas: false,
                    gasUsageEstimate: null,
                    houseNumber: null,
                    houseNumberSuffix: null,
                    offer: null,
                    postalCode: null,
                    proposition: null,
                    redirectType: null,
                    transmissionTypeElec: null,
                    transmissionTypeGas: null,
                },
            },
            vatIsFalse: {
                type: Boolean,
                value: false,
            },
            showCurrency: {
                type: Boolean,
                value: true,
            },
        };
    }

    /**
     * Constructor of reset password
     */
    constructor() {
        super();
        /** @type {Array} */
        this.collectives = [];
        /** @type {Object} */
        // TODO linting: what is the default type?
        // eslint-disable-next-line no-unused-expressions
        this.order;
        /** @type {String} */
        this.collective = '';
        /** @type {String} */
        this.titleSuffix =
            themeSettings && themeSettings.titleSuffix !== undefined
                ? themeSettings.titleSuffix
                : ' details';

        /** @type {String} */
        this.elecFlexRateTitle =
            themeSettings && themeSettings.elecFlexRateTitle !== undefined
                ? themeSettings.elecFlexRateTitle
                : 'Stroom flextarief per kWh';

        /** @type {String} */
        this.gasRateTitle =
            themeSettings && themeSettings.gasRateTitle !== undefined
                ? themeSettings.gasRateTitle
                : 'Gastarief per m³';

        /** @type {String} */
        this.dailyRateEpexTitle =
            themeSettings && themeSettings.dailyRateEpexTitle !== undefined
                ? themeSettings.dailyRateEpexTitle
                : 'Dagprijs EPEX per kWh';

        /** @type {String} */
        this.dailyRateLebaTitle =
            themeSettings && themeSettings.dailyRateLebaTitle !== undefined
                ? themeSettings.dailyRateLebaTitle
                : 'Dagprijs LEBA per m³';

        /** @type {FootNote[]} */
        this.themeFootNotes =
            themeSettings && themeSettings.footNotes !== undefined
                ? themeSettings.footNotes
                : [];

        /**
         * btwFootNote is @type {FootNote}
         * type prop is @enum {FootNoteType}
         */
        this.btwFootNote = {
            type: 'VAT',
            text: `Let op: de huidige berekening maakt gebruik van 9% btw. Echter wordt vóór 01-07-2022 en ná 31-12-2022 gebruik gemaakt van het 21% btw tarief.`,
        };

        /**
         * @type {FootNote[]}
         */
        this.footNotes = [];
    }

    /**
     * On element ready
     */
    ready() {
        super.ready();
        const state = store.getState();
        this.order = state.order || {};
        watch(store.getState, 'order');

        /**
         * @type {FootNote[]}
         */
        const tempFootNotes = [
            this._nonDefaultVat(this.configuration)
                ? this.btwFootNote
                : undefined,
            ...this.themeFootNotes,
        ].filter((note) => note);

        this.footNotes = tempFootNotes.map((footnote, i) => ({
            ...footnote,
            noteIndex: i + 1,
        }));
    }

    /**
     * Checks if configuration is a flex configuration
     * @param {Object} item
     * @return {Boolean}
     */
    _isFlexConfiguration = (item) =>
        !!(
            (item && item.metadata && item.metadata.short_name === 'Flex') ||
            item.duration_type === 5
        );

    /**
     * Gets the flex amount per Kwh
     * @param {import('types/offers').Product[]} products
     * @return {String}
     */
    _getFlexAmountPerKwh(products) {
        if (!products || !Array.isArray(products)) {
            return '';
        }

        const elecProduct = products.find(
            (p) => p.name === 'Leveringskosten stroom',
        );
        if (elecProduct) {
            let pricePerKwh = parseFloat('0,00000');
            const costs = elecProduct.cost;
            costs.forEach((c) => {
                if (this._isVariableCost(c)) {
                    pricePerKwh += this._unit(c, true);
                }
            });
            return NumberToPrice(pricePerKwh, costs, 5, false);
        }
        return '';
    }

    /**
     * Gets the gas amount per m3
     * @param {import('types/offers').Product[]} products
     * @return {String}
     */
    _getGasAmountPerM3(products) {
        if (!products || !Array.isArray(products)) {
            return '';
        }

        const gasProduct = products.find(
            (p) => p.name === 'Leveringskosten gas',
        );
        if (gasProduct) {
            let pricePerM3 = parseFloat('0,00000');
            const costs = gasProduct.cost;
            costs.forEach((c) => {
                if (this._isVariableCost(c)) {
                    pricePerM3 += this._unit(c, true);
                }
            });
            return NumberToPrice(pricePerM3, costs, 5, false);
        }
        return '';
    }

    /**
     * Gets the low elec amount per kwh
     * @param {import('types/offers').Product[]} products
     * @return {String}
     */
    _getLowElecAmountPerKwh(products) {
        if (!products || !Array.isArray(products)) {
            return '';
        }

        const elecProduct = products.find(
            (p) => p.name === 'Leveringskosten stroom',
        );
        if (elecProduct) {
            let pricePerKwh = parseFloat('0,00000');
            const costs = elecProduct.cost;
            costs.forEach((c) => {
                if (
                    this._isVariableCost(c) &&
                    c.description !== 'Normaaltarief per kWh'
                ) {
                    pricePerKwh += parseFloat(c.price);
                }
            });
            return NumberToPrice(pricePerKwh, costs, 5);
        }
        return '';
    }

    /**
     * Gets the normal elec amount per kwh
     * @param {import('types/offers').Product[]} products
     * @return {String}
     */
    _getNormalElecAmountPerKwh(products) {
        if (!products || !Array.isArray(products)) {
            return '';
        }

        const elecProduct = products.find(
            (p) => p.name === 'Leveringskosten stroom',
        );
        if (elecProduct) {
            let pricePerKwh = parseFloat('0,00000');
            const costs = elecProduct.cost;
            costs.forEach((c) => {
                if (
                    this._isVariableCost(c) &&
                    c.description !== 'Daltarief per kWh'
                ) {
                    pricePerKwh += parseFloat(c.price);
                }
            });
            return NumberToPrice(pricePerKwh, costs, 5);
        }
        return '';
    }

    /**
     * Get the amount as human readable
     * @param {import('types/offers').Cost} item
     * @param {Object} parent
     * @return {String}
     */
    _amount = (item, parent) => {
        if (!item || !item.calculated_amount) {
            return '';
        }

        const TYPE_ELEC = '__LOCAL_TYPE_ELEC__';
        const TYPE_GAS = '__LOCAL_TYPE_GAS__';

        const types = enums.CaptarType.map((o) =>
            o.toLowerCase().includes('elec') ? TYPE_ELEC : o,
        ).map((o) => (o.toLowerCase().includes('gas') ? TYPE_GAS : o));

        if (item.description === descriptionGasSubsidy) {
            return `${item.calculated_amount} m³`;
        } else if (item.description === descriptionElecSubsidy) {
            return `${item.calculated_amount} kWh`;
        }

        switch (
            !Number.isNaN(parent.type) && types.length > Number(parent.type)
                ? types[parseInt(parent.type, 10)]
                : ''
        ) {
            case TYPE_ELEC:
                return `${item.calculated_amount} kWh`;
            case TYPE_GAS:
                return `${item.calculated_amount} m³`;
            default:
                return item.calculated_amount;
        }
    };

    /**
     * Returns the low usage
     * @param {import('types/order').Order} order
     * @return {Number}
     */
    _lowYearlyUsage = (order) =>
        Math.round(
            order.electricityUsageEstimate *
                (1.0 - order.electricityUsageFactorEstimate),
        );

    /**
     * Returns the low production
     * @param {import('types/order').Order} order
     * @return {Number}
     */
    _lowYearlyProduction = (order) =>
        Math.round(
            order.electricityProductionEstimate *
                (1.0 - order.electricityUsageFactorEstimate),
        );

    /**
     * Returns the normal production
     * @param {import('types/order').Order} order
     * @return {Number}
     */
    _normalYearlyProduction = (order) =>
        Math.round(
            order.electricityProductionEstimate *
                order.electricityUsageFactorEstimate,
        );

    /**
     * returns the normal usage
     * @param {import('types/order').Order} order
     * @return {Number}
     */
    _normalYearlyUsage = (order) =>
        Math.round(
            order.electricityUsageEstimate *
                order.electricityUsageFactorEstimate,
        );

    /**
     * Get the price as human readable
     * @param {import('types/offers').Cost[]} item
     * @return {String}
     */
    _price(item) {
        if (
            !item ||
            !item.calculated_price ||
            Number.isNaN(item.calculated_price)
        ) {
            return '';
        }
        if (item.description.includes('Vermindering Energiebelasting')) {
            return NumberToPrice(item.calculated_price, item);
        }

        return NumberToPrice(item.calculated_price, item).split('€')[1];
    }

    /**
     * convert value to currency value
     * @param {String|Number} value
     * @return {String}
     */
    _currencyfy(value) {
        if (!value || Number.isNaN(Number(value))) {
            return '';
        }
        return currency(value);
    }

    /**
     * Get the unit price as human readable
     * @param {import('types/offers').Cost[]} item
     * @return {String}
     */
    _unit(item, withoutUnit = false) {
        if (
            !item ||
            !item.price ||
            Number.isNaN(item.price) ||
            !item.calculated_amount
        ) {
            return '';
        }

        const price = parseFloat(item.price);
        // Divide the price with amount of costs.
        const finalPrice = NumberToPrice(
            price + price * item.vat_percentage,
            item,
            5,
            false,
        );
        if (withoutUnit) {
            return Number(finalPrice.split('€')[1].trim().replace(',', '.'));
        }
        return `× ${finalPrice}`;
    }

    /**
     * Get weight for a price row
     * @param {Number|String} weight
     * @return {String}
     */
    _weighted = (weight) => {
        if (!Number.isNaN(Number(weight))) {
            return `order: ${parseInt(String(weight), 10)}`;
        }
        return `order: 1;`;
    };

    /**
     * Check if price group has multiple items
     * @param {import('types/offers').Cost[]} item
     * @return {Boolean}
     */
    _complexPriceGroup(item) {
        return !this._simplePriceGroup(item);
    }

    /**
     * Check if price group item has a variable cost price
     * @param {import('types/offers').Cost[]} item
     * @return {Boolean}
     */
    _hasVariablePricing = (item) =>
        !!(
            item &&
            Array.isArray(item.cost) &&
            item.cost.some(
                (c) =>
                    c.type !== 24 ||
                    c.type !== 4 ||
                    c.type !== 10 ||
                    c.type !== 11,
            )
        );

    /**
     * Check if price group item has a fixed cost price
     * @param {import('types/offers').Cost[]} item
     * @return {Boolean}
     */
    _hasFixedPricing = (item) =>
        !!(
            item &&
            Array.isArray(item.cost) &&
            item.cost.some(
                (c) =>
                    c.type === 24 ||
                    c.type === 4 ||
                    c.type === 10 ||
                    c.type === 11,
            )
        );

    /**
     * Checks if cost setting is variable
     * @param {Object} cost
     * @return {Boolean}
     */
    _isVariableCost(cost) {
        return !this._isFixedCost(cost);
    }

    /**
     * Checks if cost setting is fixed
     * @param {Object} cost
     * @return {Boolean}
     */
    _isFixedCost = (cost) =>
        !!(
            cost &&
            (cost.type === 24 ||
                cost.type === 4 ||
                cost.type === 10 ||
                cost.type === 11)
        );

    _getCostDisplayDescription = (cost) => {
        switch (cost.type) {
            case 12:
                return this.dailyRateEpexTitle;
            case 13:
                return this.dailyRateLebaTitle;
            default:
                return cost.description;
        }
    };

    /**
     * Gets the foot note index suffix for a cost entry, returns empty if none exists
     * @param {import('types/offers').Cost} cost
     * @return {String}
     */
    _getFootNoteSuffix = (cost) => {
        /**
         * @type {FootNote}
         */
        const foundFoodNote = this.footNotes.find((note) =>
            note.costTypes?.find((ct) => ct === cost.type),
        );
        return foundFoodNote ? foundFoodNote.noteIndex : '';
    };

    /**
     * Gets the foot note index suffix voor VAT, returns empty if none exists
     * @param {FootNote[]} footNotes
     * @return {String}
     */
    _getVatFootNoteSuffix(footNotes) {
        if (!footNotes) return '';
        const foundFoodNote = footNotes.find((note) => note.type === 'VAT');
        return foundFoodNote ? foundFoodNote.noteIndex : '';
    }

    /**
     * Calculates the subtotal for fixed or variable
     * @param {import('types/offers').Product[]} item
     * @return {number}
     */
    getTotalPrice = (cost) => {
        const price = parseFloat(cost.calculated_price);
        // eslint-disable-next-line prefer-const
        let { vat, err } = parseVatFloat(cost);
        if (vat == null) {
            vat = price * VATPercentage;
        }
        return { total: roundNumber(price + vat), er: err };
    };

    /**
     * Calculates the subtotal for fixed or variable
     * @param {String} type
     * @param {import('types/offers').Product[]} item
     * @param {Boolean} showCurrency
     * @return {DisplayPrice}
     */
    _calculateSubtotal(type, item, showCurrency) {
        if (item?.cost == null) {
            return '';
        }
        const costs = item.cost;
        let subtotal = parseFloat('0,00');
        let err = false;

        switch (type) {
            case 'fix':
                costs.forEach((c) => {
                    if (this._isFixedCost(c)) {
                        const { total, er } = this.getTotalPrice(c);
                        if (!err) err = er;
                        subtotal += total;
                    }
                });
                break;
            case 'var':
                costs.forEach((c) => {
                    if (this._isVariableCost(c)) {
                        const { total, er } = this.getTotalPrice(c);
                        if (!err) err = er;
                        subtotal += total;
                    }
                });
                break;
            case 'all':
                costs.forEach((c) => {
                    const { total } = this.getTotalPrice(c);
                    subtotal += total;
                });
                break;
            default:
                console.error('type is missing for calculate sub total');
                break;
        }
        if (err) {
            console.error('missing vat for the price structure', item);
        }

        const total = currency(subtotal);
        if (showCurrency) {
            return total;
        }
        return total.split('€')[1].trim();
    }

    /**
     * Check if price group has only one item
     * @param {import('types/offers').Product[]} item
     * @return {Boolean}
     */
    _simplePriceGroup = (item) =>
        item &&
        Array.isArray(item.cost) &&
        item.cost.length <= 1 &&
        item.cost[0].ledger_code !== ledgerCodeForSubsidy;

    /**
     * _getComplexProductSubTitle returns the title for subsidy or normal variable type
     * @param {import('types/offers').Product[]} item
     * @return {String}
     */
    _getComplexProductSubTitle = (item) =>
        item &&
        item.cost &&
        Array.isArray(item.cost) &&
        item.cost.filter((c) => c.ledger_code === ledgerCodeForSubsidy).length >
            0
            ? 'Korting'
            : 'Variabele kosten';

    /**
     * Fire close event
     */
    _close() {
        this.dispatchEvent(new CustomEvent('close', {}));
    }

    /**
     * Get collective from collectives based on collective id
     * @param {String} collective
     * @param {Array} collectives
     * @return {Object}
     */
    _getCollective = (collective, collectives) =>
        collectives.find((c) => c.id === collective);

    /**
     * Get collective offer text from collectives based on collective id
     * @param {String} collective
     * @param {Array} collectives
     * @return {String}
     */
    _getCollectiveOfferText(collective, collectives) {
        const found = this._getCollective(collective, collectives);
        return found && found.config && found.config.offer_text
            ? found.config.offer_text
            : '';
    }

    /**
     * Show collective offer text from collectives based on collective id
     * @param {String} collective
     * @param {Array} collectives
     * @return {Boolean}
     */
    _showCollectiveOfferText(collective, collectives) {
        const found = this._getCollective(collective, collectives);
        return found && found.config && found.config.offer_text;
    }

    /** @typedef {import('types/offers').Configuration} Configuration */
    /**
     * Show collective offer text from collectives based on collective id
     * @param {Configuration} config
     * @return {String} VATPrice
     */
    getVATForProductgroup(config) {
        const { products } = config;
        if (!products) {
            console.error('missing products for productgroup');
            return '';
        }
        let VATPrice;

        // loop over all products
        for (const product of products) {
            const isGasOrElecCostType =
                product.type === enums.CostTypeElecUnit ||
                product.type === enums.CostTypeGasUnit;
            // TODO: future update this logic VAT is now always set to the elec or gas percentage
            if (!isGasOrElecCostType) {
                return ``;
            }
            // Get VAT from product
            VATPrice = GetVATFromProduct(product);
            // prevent looping over other products
            if (VATPrice != null) {
                break;
            }
        }

        if (VATPrice == null) {
            console.error('VATPrice is missing', products);
            return ``;
        }

        const vat = ConvertVATDecimalToInteger(VATPrice);
        return `${vat}% BTW`;
    }

    /** @typedef {import('types/offers').Configuration} Configuration */
    /**
     * Show collective offer text from collectives based on collective id
     * @param {Configuration} config
     * @return {Boolean} NonDefaultVat
     */
    _nonDefaultVat(config) {
        if (config.products && config.products.length > 0) {
            for (const product of config.products) {
                for (const cost of product.cost) {
                    if (
                        !cost.is_group_parent &&
                        cost.vat_percentage &&
                        cost.vat_percentage === 0.09
                    ) {
                        return true;
                    }
                }
            }
        }
        return false;
    }

    /**
     * Gets template of class
     */
    static get template() {
        const cssTemplate = document.createElement('template');
        cssTemplate.innerHTML = provider.styles(css);
        const htmlTemplate = document.createElement('template');
        htmlTemplate.innerHTML = template;
        return html`<style include="iron-flex">
                ${cssTemplate}
            </style>
            ${htmlTemplate}`;
    }
}

window.customElements.define('product-details', EzProductDetails);
