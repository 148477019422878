import {
    Bearer,
    BearerSupportedTypes,
    ParameterDefinitionsObject,
    ParameterIn,
    RequestAction,
    RequestOptions,
    RequestSagaIterableIterator,
    ResolvableOrType,
    Resource,
    ResourceOptions,
    UUID,
    UUIDSupportedTypes,
} from 'goagen-js-resource';
import { Method } from 'axios';

export interface NotifyEdsnLiveProductofferingRequestObject {
    // Vendor ID (UUID)
    vendorId?: UUIDSupportedTypes;
    // Injected Bearer header (Bearer)
    xAuth?: BearerSupportedTypes;
}

export interface ResolvableNotifyEdsnLiveProductofferingRequestObject {
    // Vendor ID (UUID)
    vendorId?: ResolvableOrType<
        NotifyEdsnLiveProductofferingRequestObject['vendorId']
    >;
    // Injected Bearer header (Bearer)
    xAuth?: ResolvableOrType<
        NotifyEdsnLiveProductofferingRequestObject['xAuth']
    >;
}

/**
 * Store member management object for notify edsn live productoffering
 */
export class NotifyEdsnLiveProductoffering extends Resource<
    NotifyEdsnLiveProductofferingRequestObject,
    ResolvableNotifyEdsnLiveProductofferingRequestObject
> {
    /**
     * Create new NotifyEdsnLiveProductoffering
     *
     * | Parameter |  | Target |  |  |
     * | --- | --- | --- | --- | --- |
     * | `vendorId` * | `UUID` | path | `vendorID` | Vendor ID |
     * | `xAuth` | `Bearer` | header | `X-Auth` | Injected Bearer header |
     */
    constructor(
        storeName: string,
        options: ResourceOptions<ResolvableNotifyEdsnLiveProductofferingRequestObject> = {},
    ) {
        super(storeName, options);
    }

    /**
     * Generate request action
     *
     * | Parameter |  | Target |  |  |
     * | --- | --- | --- | --- | --- |
     * | `vendorId` * | `UUID` | path | `vendorID` | Vendor ID |
     * | `xAuth` | `Bearer` | header | `X-Auth` | Injected Bearer header |
     */
    public request(
        requestObject: NotifyEdsnLiveProductofferingRequestObject = {},
        requestOptions: RequestOptions = {},
    ): RequestAction<NotifyEdsnLiveProductofferingRequestObject> {
        return super.request(requestObject, requestOptions);
    }

    /**
     * Run request as saga
     *
     * | Parameter |  | Target |  |  |
     * | --- | --- | --- | --- | --- |
     * | `vendorId` * | `UUID` | path | `vendorID` | Vendor ID |
     * | `xAuth` | `Bearer` | header | `X-Auth` | Injected Bearer header |
     */
    // @ts-ignore
    public get requestSaga(): (
        requestObject?: NotifyEdsnLiveProductofferingRequestObject,
        requestOptions?: RequestOptions,
    ) => RequestSagaIterableIterator<
        RequestAction<NotifyEdsnLiveProductofferingRequestObject>
    > {
        return super.requestSaga;
    }

    /**
     * Action string
     */
    static get action(): string {
        return 'notify edsn live';
    }

    /**
     * Description string
     */
    static get description(): string {
        return 'Creates new EDSN live notification in DB';
    }

    /**
     * Request method
     */
    static get method(): Method {
        return 'post';
    }

    /**
     * Resource string
     */
    static get resource(): string {
        return 'productoffering';
    }

    /**
     * Route string
     */
    static get route(): string {
        return '/vendor/{vendorID}/productoffering/edsn-queue';
    }

    /**
     * Unique name string
     */
    static get uniqueName(): string {
        return 'NotifyEdsnLiveProductoffering';
    }

    /**
     * Parameters object
     */
    static get parameters(): ParameterDefinitionsObject {
        return {
            vendorId: {
                description: 'Vendor ID',
                in: ParameterIn.path,
                name: 'vendorID',
                required: true,
                type: UUID,
            },
            xAuth: {
                description: 'Injected Bearer header',
                in: ParameterIn.header,
                name: 'X-Auth',
                type: Bearer,
            },
        };
    }
}
