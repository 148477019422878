import { html, property } from '@lion/core';
import { LionAccordion } from '@lion/accordion';
import { customElement } from 'lit-element';
import './HvAccordion';

import HvFaqStyle from './HvFaqStyle';
import './icon/HvIcon';

@customElement('hv-faq')
export class HVFaq extends LionAccordion {
    @property({ type: Array })
    public questions: [] = [];

    static get scopedElements() {
        return { 'lion-accordion': LionAccordion };
    }

    static get styles() {
        return [HvFaqStyle];
    }

    public render = (): any => html`
        <hv-accordion>
            ${[...this.questions].map((question) => {
                const title = question[0];
                const awnser = question[1];
                return html`<h3 slot="invoker">
                        <span
                            ><div>${title}</div>
                            <hv-icon name="arrow-down" family=""></hv-icon>
                        </span>
                    </h3>
                    <p slot="content">${awnser}</p>`;
            })}
        </hv-accordion>
    `;
}

declare global {
    interface HTMLElementTagNameMap {
        'hv-faq': HVFaq;
    }
}
