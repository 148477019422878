import { customElement, property, PropertyValues } from 'lit-element';

import { css } from '@lion/core';

import { LionSteps } from '@lion/steps';

/**
 * Use @lion/steps components to implement a step wizard https://lion-web.netlify.app/components/navigation/steps/overview/
 * The <weave-stepper> has <weave-step> childs (WeaveStep.ts)
 * Add functionality by the ability to add a startingStep as attribute.
 * This comes in handy when binding with redux state
 */
@customElement('weave-stepper')
export class WeaveStepper extends LionSteps {
    @property({ type: Number })
    public startingStep: number = 0;

    static get styles() {
        return [...super.styles, css``];
    }

    constructor() {
        super();
    }

    firstUpdated(changedProperties: PropertyValues): void {
        super.firstUpdated(changedProperties);
        this.current = this.startingStep;
        this.initialStepChanged(this.current);
    }

    private initialStepChanged = (step: number): void => {
        this.dispatchEvent(
            new CustomEvent('initial-step-changed', {
                detail: step,
            }),
        );
    };
}

declare global {
    interface HTMLElementTagNameMap {
        'weave-stepper': WeaveStepper;
    }
}
