import {
    LitElement,
    TemplateResult,
    customElement,
    html,
    property,
} from 'lit-element';

// todo: replace for the whitelabel utility
import { provider } from '@weavelab/frontend-connect';

import { isPageMobile } from '../../../../helpers/mobileHelpers';
import style from './ezHeaderStyle';

@customElement('ez-header-video')
export class EzHeaderVideo extends LitElement {
    @property()
    public assets?: any;

    @property({ attribute: 'banner-title', type: String })
    public bannerTitle?: string;

    @property({ attribute: 'banner-sub-title', type: String })
    public bannerSubTitle?: string;

    @property({ attribute: 'banner-sub-title-span', type: String })
    public bannerSubTitleSpan?: string;

    static get styles() {
        return [style];
    }

    constructor() {
        super();
        this.assets = provider.asset;
    }

    public render = (): TemplateResult => {
        const isNotMobile = !isPageMobile();
        const background: string = this.assets(
            'mijndomein-background-banner.png',
        );
        return html`
            <style>
                .bannerVideoWrapper {
                    background-image: url('${background}');
                }
                .banner__title__wrapper {
                    position: ${isNotMobile
                        ? 'absolute'
                        : 'relative'} !important;
                }
            </style>
            <div class="bannerVideoWrapper">
                ${isNotMobile
                    ? html`
                          <video autoplay muted loop id="bannerVideo">
                              <source
                                  src="https://www.mijndomein.nl/energie/assets/img/mde-opgewekt.mp4"
                                  type="video/mp4"
                              />
                          </video>
                      `
                    : null}
                <div class="banner__title__wrapper">
                    <div class="bannerAddress">
                        ${this.bannerTitle}<br />
                        <small>
                            ${this.bannerSubTitle}
                            ${this.bannerSubTitleSpan
                                ? html`<span>${this.bannerSubTitleSpan}</span>.`
                                : null}
                        </small>
                    </div>
                </div>
            </div>
        `;
    };
}

declare global {
    interface HTMLElementTagNameMap {
        'ez-header-video': EzHeaderVideo;
    }
}
