import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons/faCalendarAlt';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown';
import { litFontawesome } from '@weavedev/lit-fontawesome';
import { whitelabel } from '@weavelab/whitelabel';
import {
    customElement,
    html,
    property,
    PropertyValues,
    queryAll,
} from 'lit-element';
import { TemplateResult } from 'lit-html';
import { ifDefined } from 'lit-html/directives/if-defined';
import '../base/button/ButtonComponent';
import { OverlayComponent } from '../base/overlay-component/OverlayComponent';
import style from '../base/overlay-component/style';
import '../base/placeholder-component/PlaceholderComponent';
import { checkIfStringValueExistWithinAStringArray } from '../helpers/data';
import './DropDownCell';
import { DefaultCellEvent, DropdownCell } from './DropDownCell';
import dropdownStyle from './StyleDropdownSelector';

export interface CustomFormEvent extends CustomEvent {
    detail: {
        required: boolean;
        validity: boolean;
        element: any;
        id?: string;
        value?: any;
    };
}

export const rendererConverter: (
    value: string | object | any | undefined,
    data: object | any | undefined,
) => object = (
    value: string | object | undefined,
    data: object | undefined,
): object => {
    if (typeof value === 'string') {
        return { value, data };
    }
    return { value, data };
};

export type valueType = string | number | string[];

/**
 * Dropdown selector component
 * This component creates a dropdown selector with text-holder and default slot content.
 * @items excepts an data object or a object with strings.
 * @itemLabel is the path inside the item object to show inside the placeholder.
 * @itemProp is the name of the property for subCategories if there are any.
 * @itemValue is the value that will be returned of a item is selected and needed for formvalidation.
 * @value accepts the value string which sets the default value for the submenu and value for formvalidation.
 * @width optional to create different widths for the sub-menu.
 * @placeholder is the placeholder text for the menu.
 * @error when error: item.map is not a function shows up you forgot to set the attribute @itemLabel
 * @collapsable Adds logic to collapse items underneath a parent item.
 * slot="field"
 */
@customElement('dropdown-selector-component')
@whitelabel({ name: 'dropdown-selector-component' })
export class DropdownSelector extends OverlayComponent {
    @property()
    public placeholder?: string;

    @property({ type: Number })
    public width: number = 265;

    @property()
    public theme: string = 'primary';

    @property()
    public value?: valueType;

    @property({ type: Boolean })
    public required?: boolean;

    @property({ type: Boolean })
    public error: boolean = false;

    @property({ attribute: 'prevent-errors', type: Boolean })
    public preventErrors: boolean = false;

    @property({ attribute: 'collapsable', type: Boolean })
    public collapsable: boolean = false;

    @property({ type: Boolean })
    public preventInactiveSelectedItem: boolean = true;

    @property({
        attribute: 'multi-selector',
        type: Boolean,
        reflect: true,
    })
    public multiSelector: boolean = false;

    @property({
        attribute: 'is-collapsed',
        type: Boolean,
        reflect: true,
    })
    public isCollapsed: boolean = false;

    @property({
        attribute: 'is-collapsed-selectable',
        type: Boolean,
        reflect: true,
    })
    public isCollapsableSelectable: boolean = false;

    @property({
        attribute: 'active-path',
        type: Boolean,
        reflect: true,
    })
    public activePath: boolean = false;

    @property({
        attribute: 'error-message',
        reflect: true,
    })
    public errorMessage: string = `Verplicht selecteer een optie.`;

    @property({ attribute: false })
    protected lastActiveElement?: HTMLElementTagNameMap['dropdown-cell'];

    @queryAll('dropdown-cell')
    private allParentDropdownCells?: DropdownCell[];

    @property({ attribute: 'hidden', type: Boolean, reflect: true })
    public hidden: boolean = false;

    @property({ attribute: 'disabled', type: Boolean, reflect: true })
    public disabled: boolean = false;

    @property({ type: Boolean, reflect: true })
    public label: boolean = false;

    @property({ type: Boolean })
    public showIcon?: boolean = false;

    private firstValueUpdate: boolean = false;

    constructor() {
        super();
        this.addEventListener(
            'selected-value',
            this.setSelectedValue as EventListener,
        );

        this.addEventListener('dropdown-selector-cell-incative', ((
            e: CustomEvent,
        ) => {
            const val = e.detail.value;
            // If value changes and itemSelected is bind to the old value.
            // Deselect the selected item and set it to an empty string.
            // If value changes and its a array check if array has the value.
            if (this.itemsSelected != null && val != null) {
                const containsValue = Array.isArray(this.itemSelected)
                    ? checkIfStringValueExistWithinAStringArray(
                          val,
                          this.itemsSelected,
                      )
                    : val === this.itemSelected;
                if (containsValue) {
                    if (!this.multiSelector) {
                        this.itemSelected = '';
                    } else {
                        if (
                            Array.isArray(this.itemSelected) &&
                            this.itemSelected.includes(val)
                        ) {
                            const newItemArray = this.itemSelected.filter(
                                (item: any) => item !== val,
                            );
                            this.itemsSelected = newItemArray;
                        }
                    }
                }
            }
        }) as EventListener);
    }

    @whitelabel() // Allow styles to be injected from theme
    static get styles() {
        return [style, dropdownStyle];
    }

    public render = (): TemplateResult => {
        return html`<style>
                slot[name='sub-menu'] {
                    right: -${this.width ? this.width : 320}px;
                    width: ${this.width ? this.width : 310}px;
                }
            </style>
            <aside ?error=${this.error} class="dropdown">
                <slot
                    @click=${() => this.changeDropdownState}
                    @mouseover="${() => this.checkContentPosition}"
                    name="menu"
                >
                    ${this.showIcon
                        ? litFontawesome(faCalendarAlt, { className: 'icon' })
                        : null}
                    ${this.label && this.itemSelected
                        ? html`
                              <p id="label">
                                  Dag
                                  <span>${this.itemSelected}</span>
                              </p>
                          `
                        : html`<placeholder-component
                              name="scopes"
                              .value=${this.itemSelected}
                              .error="${this.error}"
                              .errorMessage="${this.errorMessage}"
                              placeholder="${ifDefined(this.placeholder)}"
                          >
                          </placeholder-component>`}
                    ${!this.disabled
                        ? html`
                              <div id="arrow">
                                  ${litFontawesome(faChevronDown, {
                                      className: 'arrow-down',
                                  })}
                              </div>
                          `
                        : null}
                </slot>
                <slot
                    class="dropdown-content"
                    name="sub-menu"
                    width="${this.width}px"
                    theme=${ifDefined(this.theme)}
                >
                    ${this.items !== undefined
                        ? html`
                              ${this.items!.map((item: any) =>
                                  this.renderSlotItems(item, 0),
                              )}
                          `
                        : null}
                </slot>
                <div id="overlay"></div>
            </aside> `;
    };

    public validate = (): boolean => {
        this.error = false;
        // Check if value exist.
        if (this.value || this.value === 0) {
            // Validate the component if required or the value is not empty.
            if (this.required || this.value !== '') {
                if (Array.isArray(this.value)) {
                    let bool: boolean = false;
                    for (const value of this.value) {
                        if (!this.isStringValid(value)) {
                            bool = true;
                            break;
                        }
                    }
                    this.error = bool;
                } else {
                    this.error = !this.isStringValid(this.value);
                }
            }
        } else if (this.required) {
            // Show error if the value doesn't exist and validation is required.
            this.error = true;
        }
        return !this.error;
    };

    public setSelectedValue = (e: CustomEvent): void => {
        const itemVal = this.itemValue !== undefined ? this.itemValue : 'items';
        // check if items is within the detail.
        if (e.detail.items) {
            // @ts-ignore
            const newVal: any = e.detail.items[itemVal];
            if (this.multiSelector && this.firstValueUpdate) {
                if (this.value == null) {
                    this.value = [];
                }
                if (Array.isArray(this.value) && this.firstValueUpdate) {
                    if (!this.value.includes(newVal as string)) {
                        this.value = [...this.value, newVal];
                    } else {
                        const array = JSON.parse(JSON.stringify(this.value));
                        const index = array.indexOf(newVal);
                        if (index > -1) {
                            array.splice(index, 1);
                        }
                        this.value = [...array];
                    }
                }
            } else if (this.firstValueUpdate) {
                this.value = newVal;
            }

            const elm = e.detail
                .element as HTMLElementTagNameMap['dropdown-cell'];
            if (elm && elm.active) {
                // add the lastActiveElement needed to disable the previous one.
                this.lastActiveElement = e.detail.element;
            }
        }
    };

    protected updated(changedProperties: PropertyValues) {
        super.updated(changedProperties);
        if (changedProperties.has('value') && this.value !== '') {
            if (this.value != null) {
                if (
                    this.firstValueUpdate &&
                    changedProperties.get('value') !== this.value
                ) {
                    const changeEvent: Event = new CustomEvent(
                        'form-element-changed',
                        {
                            bubbles: true,
                            composed: true,
                            detail: {
                                element: this,
                                id: this.id,
                                required: this.required,
                                validity: this.validate(),
                                value: this.value,
                            },
                        } as CustomFormEvent,
                    );
                    this.dispatchEvent(changeEvent);
                }
            } else {
                this.itemSelected = '';
            }
        }

        if (
            changedProperties.has('lastActiveElement') &&
            changedProperties.get('lastActiveElement')
        ) {
            const elm = changedProperties.get(
                'lastActiveElement',
            ) as HTMLElementTagNameMap['dropdown-cell'];
            if (elm) {
                elm.active = !elm.active;
            }
        }

        // required to set the placeholder if the value prop changes.
        if (
            this.itemValue &&
            this.itemSelected != null &&
            this.value &&
            changedProperties.has('value')
        ) {
            const item = this.items?.find(
                (item) => item[this.itemValue!] === this.value,
            );
            if (item != null && item !== '') {
                let placeholder;
                if (this.onRender) {
                    placeholder = this.onRender(this.value, item);
                    if (typeof placeholder != 'string') {
                        placeholder = placeholder.getHTML();
                    }
                    if (this.itemSelected !== placeholder) {
                        this.itemSelected = placeholder;
                    }
                }
            }
        }
    }

    protected firstUpdated(changedProperties: PropertyValues) {
        super.firstUpdated(changedProperties);
        // Give components one second to init
        setTimeout(() => {
            this.firstValueUpdate = true;
        }, 1000);

        if (this.subMenuSlot) {
            this.subMenuSlot.addEventListener('click', (event: Event) => {
                if ((event.target! as HTMLElement).innerText !== '') {
                    this.changeDropdownState(event);
                }
            });
        }
        if (this.multiSelector) {
            if (!Array.isArray(this.itemSelected)) {
                this.itemSelected =
                    this.itemSelected == null ? [] : this.itemSelected;
            }
        }
    }

    private isStringValid = (value: any): boolean => {
        if (this.required || value !== '') {
            // Trim the begin and end of the string. (no whitespaces)
            if (typeof value === 'string') {
                value = value.trim();
            }
            return value !== '' ? true : false;
        }
        return true;
    };

    // This renders al the parent cell items for the drop-down-selector
    private renderSlotItems = (item: any, depth: number): TemplateResult => {
        const itemProp = this.itemsProp ? this.itemsProp : 'items';
        return this.renderSlottedElement(depth, item, itemProp);
    };

    // this function returns the html for the submenu items in the drop down.
    private renderSlottedElement = (
        depth: number,
        item: any,
        itemProp: string,
    ): TemplateResult => {
        let collapse: boolean = false;
        if (this.collapsable && this.itemsProp) {
            collapse =
                this.itemLabel && (item[this.itemsProp] as any[]).length > 0
                    ? true
                    : false;
        }
        return html`
            ${this.itemLabel
                ? html`
                      <dropdown-cell
                          .item="${item}"
                          .value=${this.value}
                          item-label=${this.itemLabel}
                          item-value=${ifDefined(this.itemValue)}
                          items-prop=${itemProp}
                          .itemSelected=${this.itemSelected}
                          ?collapsable=${collapse}
                          ?is-collapsed=${this.isCollapsed}
                          ?is-collapsed-selectable=${this
                              .isCollapsableSelectable}
                          ?multi-selector=${this.multiSelector}
                          ?prevent-inactive-selected-item=${this
                              .preventInactiveSelectedItem}
                          @dropdown-cell-open-parent=${this
                              .collapseCellFromParent}
                          .onRender=${this.onRender}
                      ></dropdown-cell>
                  `
                : html`
                      <div class="${depth > 2 ? `depth2` : `depth${depth}`}">
                          ${this.renderElement(item, depth)}
                      </div>
                  `}
        `;
    };

    private renderElement = (item: any, depth: number): TemplateResult => {
        return html`
            ${depth !== 0
                ? html`
                      <img
                          src="assets/icons/indent.svg"
                          alt="Indent indicator"
                      />
                  `
                : null}
            ${(this.itemValue && this.value === item[this.itemValue]) ||
            this.value === item
                ? html`
                      <img
                          src="assets/icons/selected.svg"
                          alt="${item} is selected"
                      />
                  `
                : html`
                      <img
                          src="assets/icons/deselected.svg"
                          alt="${item} could be selected"
                      />
                  `}${this.itemLabel && item[this.itemLabel]
                ? item[this.itemLabel]
                : item}
        `;
    };

    private collapseCellFromParent = (e: DefaultCellEvent): void => {
        e.stopImmediatePropagation();
        if (this.allParentDropdownCells) {
            this.allParentDropdownCells.forEach((dropdownCell) => {
                if (dropdownCell !== e.detail.prevElement) {
                    if (this.isCollapsed) {
                        dropdownCell.isCollapsed = true;
                    }
                    dropdownCell.activePath = false;
                }
            });
        }
    };
}

declare global {
    interface HTMLElementTagNameMap {
        'dropdown-selector-component': DropdownSelector;
    }
}
