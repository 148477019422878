import {
    REQUEST_INSIGHTS,
    REQUEST_INSIGHTS_FAILED,
    REQUEST_INSIGHTS_SUCCESS,
} from '../actions/insights';

const defaultInsights = {
    e: null,
    g: null,
    p: null,
    requestObject: {},
    error: null,
    busy: false,
};

/**
 * State reducer for invoices request
 * @param {Object} state
 * @param {Object} action
 * @return {Object}
 */
export const insights = (state = { ...defaultInsights }, action) => {
    switch (action.type) {
        case REQUEST_INSIGHTS:
            return {
                ...state,
                busy: true,
            };
        case REQUEST_INSIGHTS_SUCCESS:
            return {
                ...state,
                e: action.e,
                g: action.g,
                p: action.p,
                error: null,
                busy: false,
            };
        case REQUEST_INSIGHTS_FAILED:
            return { ...state, error: action.error, busy: false };
        default:
            return state;
    }
};
