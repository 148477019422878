export const DEFAULT_LOCALE = 'nl-NL';

// Date layouts
export const DateLayoutDayMonthYear: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
};
export const dateOptions: Intl.DateTimeFormatOptions = {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
};
export const monthYearOption = {
    month: 'long',
    year: 'numeric',
} as Intl.DateTimeFormatOptions;
export const monthOption: Intl.DateTimeFormatOptions = { month: 'long' };
export const dayOption: Intl.DateTimeFormatOptions = { day: 'numeric' };

// Date helper functions
export const getStartOfTheWeek = (date?: Date | string | number): Date => {
    date = date ? new Date(date) : new Date();
    date.setHours(2, 0, 0);
    const diff =
        date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1);
    const startDate = new Date(date.setDate(diff));
    return startDate;
};
export const getEndOfTheWeek = (date?: Date | string | number): Date => {
    date = date ? new Date(date) : new Date();
    date.setHours(21, 59, 59);
    const lastday = new Date(
        date.setDate(date.getDate() - (date.getDay() - 1) + 6),
    );
    return lastday;
};
export const getFirstDayOfTheMonth = (date: Date | string | number): Date => {
    date = new Date(date);
    return new Date(date.getFullYear(), date.getMonth(), 1);
};
export const getLastDayOfTheMonth = (date: Date | string | number): Date => {
    date = new Date(date);
    return new Date(date.getFullYear(), date.getMonth() + 1, 0);
};
export const datesAreOnSameMonth = (
    fDate: Date | string | number,
    sDate: Date | string | number | null,
): boolean => {
    fDate = new Date(fDate);
    sDate = sDate ? new Date(sDate) : new Date();
    return (
        fDate.getFullYear() === sDate.getFullYear() &&
        fDate.getMonth() === sDate.getMonth()
    );
};
export const datesAreOnSameDay = (
    fDate: Date | string | number,
    sDate: Date | string | number | null,
): boolean => {
    fDate = new Date(fDate);
    sDate = sDate ? new Date(sDate) : new Date();
    return (
        fDate.getFullYear() === sDate.getFullYear() &&
        fDate.getMonth() === sDate.getMonth() &&
        fDate.getDate() === sDate.getDate()
    );
};
export const daysDiffBetweenDates = (d1: Date, d2: Date): number => {
    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    const firstDate = d1.getTime();
    const secondDate = d2.getTime();

    return Math.round(Math.abs((firstDate - secondDate) / oneDay));
};
export const hoursDiffBetweenDates = (
    d1: Date | string,
    d2: Date | string,
): number => {
    d1 = new Date(d1);
    d2 = new Date(d2);
    let diff = (d2.getTime() - d1.getTime()) / 1000;
    diff /= 60 * 60;
    return Math.abs(Math.round(diff));
};
export const millisecondsToMinutes = (milliseconds: number): number =>
    Math.floor((milliseconds / 60000) * 1);
export const sortDate = (
    a: Date | number | string,
    b: Date | number | string,
): boolean => {
    // Turn your strings into dates, and then subtract them
    // to get a value that is either negative, positive, or zero.
    const first = new Date(a).getTime();
    const last = new Date(b).getTime();
    return last - first > 0;
};
export const sortDateByCreatedAt = (dateArr: any[]): any[] | null => {
    if (dateArr == null || dateArr.length === 0) {
        return dateArr;
    }
    return dateArr.sort(
        (a: any, b: any) =>
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            // @ts-ignore
            new Date(b.created_at) - new Date(a.created_at),
    );
};
export const convertDateTo = (
    date: Date | string,
    option: Intl.DateTimeFormatOptions = DateLayoutDayMonthYear,
    local: string | string[] = DEFAULT_LOCALE,
): string => {
    date = new Date(date);
    return date.toLocaleDateString(local, option);
};
export const isFutureDate = (date: Date | string | number): boolean => {
    date = new Date(date).getTime();
    return date > Date.now();
};
export const getEndOfWeek = (date: Date): Date => {
    const lastday = date.getDate() - (date.getDay() - 1) + 6;
    return new Date(date.setDate(lastday));
};
export const getWeekDifference = (
    d1: Date | string | number,
    d2: Date | string | number,
): number => {
    const oneWeek: number = 7 * 24 * 60 * 60 * 1000; // days*hours*minutes*seconds*milliseconds
    return Math.ceil(
        (getStartOfTheWeek(d1).getTime() - getStartOfTheWeek(d2).getTime()) /
            oneWeek,
    );
};
export const firstDayPreviousMonth = (date: Date | string | number): Date => {
    date = date == null ? new Date() : new Date(date);
    date.setDate(0); // 0 will result in the last day of the previous month
    date.setDate(1); // 1 will result in the first day of the month
    return date;
};

export interface YearWeekFromDate {
    year: number;
    week: number;
}

export const getWeekNumberfromDate = (
    date?: Date | string | number,
): YearWeekFromDate => {
    date = date == null ? new Date() : new Date(date);
    // Copy date so don't modify original
    const d = new Date(
        Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()),
    );
    // Set to nearest Thursday: current date + 4 - current day number
    // Make Sunday's day number 7
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
    // Get first day of year
    const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    // Calculate full weeks to nearest Thursday
    const weekNo = Math.ceil(
        ((d.getTime() - yearStart.getTime()) / 86400000 + 1) / 7,
    );
    // Return array of year and week number
    return { year: d.getUTCFullYear(), week: weekNo };
};

export const isValidDate = (date: Date | string): boolean =>
    // @ts-ignore
    new Date(date) !== 'Invalid Date' && !isNaN(new Date(date));
