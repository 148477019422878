/**
 * returns a array with
 */
export const insertAt = (
    array: any[],
    index: number,
    ...elementsArray: any[]
): void => {
    array.splice(index, 0, ...elementsArray);
};
