import { Product, Cost } from 'types/offers';

export const VATPercentage = 0.21;
export const VAT = 1.21;

// Loop over Cost or Cost to find the VAT price
export const GetVatFromProductGroup = (
    productGroup: Cost[] | Cost,
): undefined | number => {
    if (!Array.isArray(productGroup)) {
        const vat = productGroup.vat_percentage;
        if (vat != null && !Number.isNaN(vat)) {
            // vat found
            return vat;
        }

        console.error('Productgroup vat is missing');
        return undefined;
    }

    for (let i = 0; i < productGroup.length; i++) {
        const vat = productGroup[i].vat_percentage;
        if (vat != null && !Number.isNaN(vat)) {
            // vat found
            return vat;
        }
    }

    console.error('Productgroup vat is missing');
    return undefined;
};

// Loop over Products to find the VAT price
export const GetVATFromProduct = (product: Product): undefined | Number => {
    let VATPrice: Number | undefined;

    // loop over all cost types in the product
    for (let j = 0; j < product.cost.length; j++) {
        // check if product has vat
        VATPrice = GetVatFromProductGroup(product.cost);
        // prevent looping over other cost
        if (VATPrice != null) {
            break;
        }
    }

    return VATPrice;
};

/**
 * convert decimal numbers to integer
 * if VAT is not a integer its a decimal number like 0.21
 */
export const ConvertVATDecimalToInteger = (vat: Number): Number => {
    // check if the param vat is not a integer
    if (!Number.isInteger(vat)) {
        const percentage = vat.valueOf() * 100;
        return Math.round(percentage);
    }

    return Math.round(vat.valueOf());
};
