import { ContractBase } from './BaseContract';
import { Router } from '@vaadin/router';
import { routePaths } from 'data-access/router';
import enums from 'enums';
import { Snapshot } from 'types/snapshot';
import { ContactType } from '../Contract';

export class OdaContract extends ContractBase {
    public currentPhase: number = enums.SnapshotPhaseInsightContractActive;
    public contractType: ContactType = 'ODA';
    public maxWaitingDays: number = 10;

    constructor(public snapshot: Snapshot) {
        super();
    }

    public RouteToPage(skipped: boolean = false): void {
        const currentRoute = window.location.pathname;
        let odaRoute = routePaths.dashboard;
        if (
            this.snapshot.snapshot_phase ===
                enums.SnapshotPhaseDemoContractCreated &&
            !skipped
        ) {
            // check if current page isn't loaded
            odaRoute = routePaths.dashboard + routePaths.oda;
            if (currentRoute === odaRoute) {
                return;
            }
            Router.go(odaRoute);
        } else {
            if (currentRoute === odaRoute) {
                return;
            }
            Router.go(odaRoute);
        }
    }
}
