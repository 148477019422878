import { PolymerElement, html } from '@polymer/polymer/polymer-element';
import { provider } from '@weavelab/frontend-connect';
import { Router } from '@vaadin/router';
import css from '../styles.pcss';
import settingsEditCss from '../../settings-edit.pcss';
import template from './template.html';
import { updateUser } from '../../../../../../actions/user';

import { setError } from '../../../../../../actions/error';

import { store } from '../../../../../../store';
import { routePaths } from '../../../../../../helpers/routing';

/**
 * Login class
 * @namespace SettingsEditContactDetails
 * @class SettingsEdit
 * @extends PolymerElement
 */
export default class SettingsEditContactDetails extends PolymerElement {
    /**
     * Constructor of SettingsEdit
     */
    constructor() {
        super();

        this.gotoSettings = false;
    }

    /**
     * Gets properties of class
     */
    static get properties() {
        return {
            user: {
                type: Object,
                value: {},
            },
            email: {
                type: String,
                valie: '',
            },
        };
    }

    /**
     * Gets template of class
     */
    static get template() {
        const cssTemplate = document.createElement('template');
        cssTemplate.innerHTML =
            provider.styles(css) + provider.styles(settingsEditCss);
        const htmlTemplate = document.createElement('template');
        htmlTemplate.innerHTML = template;
        return html`<style include="iron-flex">
                ${cssTemplate}
            </style>
            ${htmlTemplate}`;
    }

    /**
     * Goes back to settings overview
     */
    _backButtonPressed = () => {
        Router.go(routePaths.editSettings);
    };

    /**
     * _editUser
     */
    _editUser() {
        // @ts-ignore
        if (this.user.email === '') {
            window.displayMessage('E-mailadres moet ingevuld zijn.');
            return;
        }
        // @ts-ignore
        if (this.user.phone_number === '') {
            window.displayMessage('Telefoonnummer moet ingevuld zijn.');
            return;
        }
        const phoneNumber = this.shadowRoot.querySelector('#phone__number');
        let payload;
        this.gotoSettings = false;
        if (this.user.email !== this.email && this.email.length > 0) {
            if (!this._validateEmail(this.email)) {
                store.dispatch(setError('Vul een geldig e-mailadres in.'));
                return;
            }
            fetch(
                `${API_LINK}/v1/user/change/${
                    // @ts-ignore
                    this.user.id
                }`,
                {
                    method: 'POST',
                    headers: STDHeaders,
                    // @ts-ignore
                    body: JSON.stringify({
                        new_mail: this.email,
                        current_mail: this.user.email,
                    }),
                },
            )
                .then((res) => res.json())
                .then((data) => {
                    if (data.status === 400) {
                        return store.dispatch(
                            setError(
                                `Dit e-mailadres is al in gebruik. Kies voor een ander e-mailadres.`,
                            ),
                        );
                    }
                    // @ts-ignore
                    Promise.resolve(
                        store.dispatch(updateUser(payload, this.user.id)),
                    ).then((e) => {
                        if (e.type === 'RECEIVE_USER') {
                            // @ts-ignore
                            window.displayMessage(
                                `Je contactgegevens zijn succesvol gewijzigd. Ga naar de mailbox van ${
                                    // @ts-ignore
                                    this.email
                                } en bevestig je wijziging.`,
                            );
                            this.set('email', '');
                        }
                    });
                    return null;
                })
                .catch((error) => {
                    console.warn(error);
                    store.dispatch(
                        setError(
                            'Er is iets fout gegaan, probeer het later nog eens.',
                        ),
                    );
                });
            this.gotoSettings = true;
        }
        // update phone number
        if (phoneNumber && phoneNumber.value !== this.user.phone_number) {
            payload = {
                phone_number: phoneNumber.value,
            };
            Promise.resolve(store.dispatch(updateUser(payload, this.user.id)))
                .then((e) => {
                    if (e.type === 'RECEIVE_USER') {
                        // @ts-ignore
                        window.displayMessage(
                            `Je telefoonnummer is succesvol gewijzigd.`,
                        );
                    }
                })
                .catch((error) => {
                    console.warn(error);
                    store.dispatch(
                        setError(
                            'Er is iets fout gegaan, probeer het later nog eens.',
                        ),
                    );
                });
            this.gotoSettings = true;
        } else if (
            phoneNumber.value === this.user.phone_number &&
            this.user.email === this.email
        ) {
            window.displayMessage(
                `Op dit moment zijn er geen aanpassingen, geef eerst een nieuw e-mailadres of telefoonnummer op.`,
            );
            return;
        }

        if (this.gotoSettings) {
            Router.go(routePaths.settings);
        }
    }

    /**
     * Validates email address
     * @param {String} email
     * @return {Boolean}
     */
    _validateEmail = (email) => {
        /*eslint-disable */
        var re =
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
        /* eslint-enable */
    };

    /**
     * Subscribes on user state when element is ready
     */
    ready() {
        super.ready();

        this.user = store.getState().user;
        this.email = this.user.email;
    }
}

window.customElements.define(
    'settings-edit-contact-details',
    SettingsEditContactDetails,
);
