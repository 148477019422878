import { Router } from '@vaadin/router';
import { whitelabel } from '@weavelab/whitelabel';
import { routePaths } from 'data-access/router';
import { unit, unitCustom } from 'helpers/currency';
import { DEFAULT_LOCALE, convertDateTo } from 'helpers/dates';
import {
    CSSResult,
    LitElement,
    TemplateResult,
    customElement,
    html,
    property,
} from 'lit-element';
import '../elements/tooltip/Tooltip';
import TileStyle from './DashboardBlockHalfStyle';

type TileType = 'savings' | 'faq' | undefined;
export type dashboardIcon = 'production' | 'gas' | 'stroom' | 'coins' | '';
@customElement('dashboard-block-half')
@whitelabel({ name: 'dashboard-block-half' })
export class DashboardBlockHalf extends LitElement {
    @property({ type: Boolean })
    hasData: boolean = false;

    @property({ type: Number })
    value: number = 0;

    @property({ type: Boolean })
    busy: boolean = true;

    @property({ type: String })
    net: dashboardIcon = '';

    @property({ type: String })
    icon: string = '';

    @property({ type: String })
    description: string = '';

    @property({ type: String })
    info: string = '';

    @property({ type: String })
    unit: 'Euro' | 'kWh' | 'm' | '' = '';

    @property({ type: String })
    upperUnit: string = '';

    @property({ type: Number })
    decimals: number = 0;

    @property({ type: Boolean })
    dataLoading: boolean = true;

    @property({ type: String })
    tillDate: string = '';

    @property({ type: String })
    tileType: TileType = undefined;

    @property({ type: Boolean })
    disabled: boolean = false;

    @property({ attribute: 'faq-url', type: String, reflect: true })
    public faqUrl: string = '#';

    @whitelabel()
    public static get styles(): CSSResult[] {
        return [TileStyle];
    }

    /**
     * will return the data string for a type
     * @param {String} type
     * @return {Boolean}
     * @private
     */
    getDataString = (type: dashboardIcon): string => {
        switch (type) {
            case 'production':
                return 'Teruggeleverd aan\nhet stroomnet';
            case 'coins':
                return 'Verbruik in\nenergiekosten';
            default:
                return 'Verbruikt van het\n%snet';
        }
    };

    /**
     * Data check will check if it has data
     * @return {Boolean}
     * @private
     */
    dataCheck = (): boolean => this.hasData && this.value !== null;

    /**
     * Get no data string
     * @return {String}
     */
    noDataString = (): string => 'Nog géén data beschikbaar';

    /**
     * Get data string
     * @return {String}
     */
    dataString = (): string =>
        this.getDataString(this.net).replace('%s', this.net);

    /**
     * Set styling class
     * @return {String | null}
     */
    hasDataOn = (): string | null => (this.hasData ? `hasDataOn` : null);

    /**
     * return the correct value formatting
     * @return {string}
     */
    getValue = (value: number, decimals: number): string => {
        if (typeof value !== 'number') {
            value = Number(value);
        }
        if (
            String(value).toLowerCase().includes('nan') ||
            value.toFixed(decimals).toLowerCase().includes('nan')
        ) {
            return '0';
        }
        const num = value.toFixed(0);
        if (decimals !== undefined && num !== '0') {
            return unitCustom(decimals).format(value);
        }

        if (num === '0') {
            return unit(value);
        }

        return num;
    };

    /**
     * Check for description
     * @return {string}
     */
    dateFormat = (): string => {
        const dateFormat: Intl.DateTimeFormatOptions = {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
        };
        return `${convertDateTo(this.tillDate, dateFormat, DEFAULT_LOCALE)}`;
    };

    /**
     * Check for till date
     * @return {Boolean}
     */
    hasTillDate = (): boolean => {
        const { tillDate } = this;
        if (tillDate === '') {
            return false;
        }
        const d = new Date(tillDate);
        if (Number.isNaN(d.getTime())) {
            return false;
        }
        return tillDate != null;
    };

    renderDisabledText = (
        tileType: TileType,
        faqUrl: string,
    ): TemplateResult | null => {
        switch (tileType) {
            case 'savings':
                return html`
                    <div class="disabled">
                        <div class="title">Tip</div>
                        <span class="text">
                            Check regelmatig je
                            <span
                                class="savings"
                                @click=${() => {
                                    Router.go(routePaths.energySavings);
                                }}
                                >tussenstand </span
                            >en pas je maandbedrag zelf aan via
                            <span
                                class="settings"
                                @click=${() => {
                                    Router.go(
                                        routePaths.editMonthlyPaymentSettings,
                                    );
                                }}
                                >mijn maandbedrag</span
                            >
                        </span>
                    </div>
                `;
            case 'faq':
                return html`
                    <div class="disabled">
                        <div class="title faq">Veelgestelde vragen</div>
                        <span class="text">
                            Vind
                            <a class="price-cap" href="${faqUrl}">hier</a>
                            alle informatie
                        </span>
                    </div>
                `;
            default:
                return null;
        }
    };

    renderSkeleton = (value: number, decimals: number) => html` <div
        class="skeleton "
    >
        <div class="skeleton-wrapper">
            ${this.icon
                ? html` <iron-icon icon="ez:${this.icon}"></iron-icon>`
                : html` <iron-icon icon="ez:${this.net}"></iron-icon>`}
            <div class="unit-wrapper">
                <div class="value">${this.getValue(value, decimals)}</div>
                <div class="unit">${this.unit}</div>
                <div class="upper-unit">${this.upperUnit}</div>
            </div>
        </div>
        <div class="line line_two"></div>
        <div class="line line_three"></div>
    </div>`;

    renderPriceLine = (value: number, decimals: number) => html` <div
        class="layout dash-block"
    >
        ${this.icon
            ? html` <iron-icon
                  icon="ez:${this.icon}"
                  id="icon_override_${this.net}"
              ></iron-icon>`
            : html` <iron-icon
                  icon="ez:${this.net}"
                  id="icon_override_${this.net}"
              ></iron-icon>`}
        <div class="unit-wrapper">
            <div class="value">${this.getValue(value, decimals)}</div>
            <div class="unit">${this.unit}</div>
            <div class="upper-unit">${this.upperUnit}</div>
        </div>
    </div>`;

    renderTillDate = () => {
        if (this.hasTillDate()) {
            return html` <div class="">
                1 t/m
                <span class="strong">${this.dateFormat()}</span>
            </div>`;
        }
        return null;
    };

    renderDataString = () => {
        if (this.dataCheck()) {
            return this.dataString();
        }
        return this.noDataString();
    };

    renderBodyContent = () => html`
        <span class="bodyContent">
            ${this.description !== ''
                ? this.description
                : this.renderDataString()}
            ${this.renderTillDate()}
        </span>
    `;

    renderToolTip = () => {
        if (this.info !== '') {
            return html` <tool-tip value="${this.info}"></tool-tip>`;
        }
        return null;
    };

    renderTile = (
        value: number,
        decimals: number,
        tileType: TileType,
        priceCapUrl: string,
    ) => {
        if (this.disabled) {
            return this.renderDisabledText(tileType, priceCapUrl);
        }
        return html`${this.renderPriceLine(value, decimals)}
        ${this.renderBodyContent()} ${this.renderToolTip()}`;
    };

    public render = (): TemplateResult => html`
        <div class="block-half">
            ${this.busy
                ? this.renderSkeleton(this.value, this.decimals)
                : this.renderTile(
                      this.value,
                      this.decimals,
                      this.tileType,
                      this.faqUrl,
                  )}
        </div>
    `;
}

declare global {
    interface HTMLElementTagNameMap {
        'dashboard-block-half': DashboardBlockHalf;
    }
}
