import {
    LitElement,
    TemplateResult,
    property,
    customElement,
    html,
    PropertyValues,
} from 'lit-element';

import '@polymer/paper-toggle-button/paper-toggle-button';
import css from './style';

@customElement('toggle-element')
export class ScheduleTableStatus extends LitElement {
    @property({
        type: Boolean,
        reflect: true,
        hasChanged(value: boolean, previousValue: boolean): boolean {
            return value !== previousValue;
        },
    })
    public isChecked: boolean = false;

    @property({
        type: Boolean,
        hasChanged(value: boolean, previousValue: boolean): boolean {
            return value !== previousValue;
        },
    })
    public empty?: boolean;

    @property({
        type: Boolean,
        hasChanged(value: boolean, previousValue: boolean): boolean {
            return value !== previousValue;
        },
    })
    public disabled?: boolean;

    static get styles() {
        return [css];
    }

    public render = (): TemplateResult => {
        let disbaled = false;
        if (this.empty || this.disabled) {
            disbaled = true;
        }
        return html` <div class="toggle">
            ${this.isChecked != null
                ? html`
                      <paper-toggle-button
                          ?checked=${this.isChecked ? this.isChecked : false}
                          @click="${() => (this.isChecked = !this.isChecked)}"
                          ?disabled=${disbaled}
                      ></paper-toggle-button>
                  `
                : null}
        </div>`;
    };

    public resetPreviousValue = (): void => {
        this.isChecked = !this.isChecked;
    };

    protected updated(changedProperties: PropertyValues) {
        // prevent dispatch event if the previous value is undefined.
        if (
            changedProperties.has('isChecked') &&
            this.isChecked != null &&
            changedProperties.get('isChecked') != null
        ) {
            this.dispatchToggleChanged();
        }
    }

    private dispatchToggleChanged = (): void => {
        if (!this.disabled) {
            this.dispatchEvent(
                new CustomEvent('toggle-changed', {
                    detail: this.isChecked,
                }),
            );
        }
    };

    // throttle function to throttle a function call by ms.
    protected throttle = (
        fn: (...arg: any[]) => void,
        ms: number,
    ): ((...arg: any[]) => any) => {
        let timer: number | any = 0;

        return (...args: any[]): any => {
            if (typeof timer === 'number') {
                clearTimeout(timer);
            }
            timer = setTimeout(fn.bind(this, ...args), ms || 0);
        };
    };
}

declare global {
    interface HTMLElementTagNameMap {
        'toggle-element': ScheduleTableStatus;
    }
}
