import {
    LitElement,
    customElement,
    TemplateResult,
    html,
    property,
    PropertyValues,
    queryAll,
} from 'lit-element';

import style from './style';
import sharedStyles from '../../../sharedStyles';

import './password-validation/PasswordValidation';
import { specialCharacters } from '../../../forms/validators/ContainsSpecialCharacter';

const MINIMAL_PASSWORD_LENGTH = 12;

@customElement('password-validations')
export class PasswordValidations extends LitElement {
    static get styles() {
        return [sharedStyles, style];
    }

    @property({ type: String })
    public password: string = '';

    @queryAll('password-validation')
    public passwordValidations?: HTMLElementTagNameMap['password-validation'][];

    public minLength(): boolean {
        return this.password.length >= MINIMAL_PASSWORD_LENGTH;
    }

    public hasUppercase(): boolean {
        return /[A-Z]/.test(this.password);
    }

    public hasLowercase(): boolean {
        return /[a-z]/.test(this.password);
    }

    public hasNumber(): boolean {
        return /[0-9]/.test(this.password);
    }

    public hasSpecialCharacter(): boolean {
        return specialCharacters.test(this.password);
    }

    protected valid?: boolean;

    public render = (): TemplateResult => html`<div>
        <password-validation
            validation-text="minimaal ${MINIMAL_PASSWORD_LENGTH} tekens"
            ?is-valid=${this.minLength()}
        ></password-validation>
        <password-validation
            validation-text="minstens 1 hoofdletter"
            ?is-valid=${this.hasUppercase()}
        ></password-validation>
        <password-validation
            validation-text="minstens 1 kleine letter"
            ?is-valid=${this.hasLowercase()}
        ></password-validation>
        <password-validation
            validation-text="minstens 1 cijfer"
            ?is-valid=${this.hasNumber()}
        ></password-validation>
        <password-validation
            validation-text="minstens 1 speciaal teken (!@#$%^?)"
            ?is-valid=${this.hasSpecialCharacter()}
        ></password-validation>
    </div> `;

    protected updated(changedProperties: PropertyValues) {
        if (this.valid === undefined) {
            this.valid = false;
        }

        if (
            changedProperties.has('password') &&
            this.password &&
            this.passwordValidations
        ) {
            let valid: boolean = false;
            let j = 0;
            // tslint:disable-next-line: prefer-for-of
            for (let i = 0; i < this.passwordValidations.length; i++) {
                if (this.passwordValidations[i].isValid) {
                    j++;
                }
                if (j === this.passwordValidations.length) {
                    valid = true;
                }
            }

            if (valid !== this.valid) {
                this.valid = valid;
                this.dispatchValidState(valid);
            }
        }
    }

    public dispatchValidState = (isValid: boolean): void => {
        window.document.dispatchEvent(
            new CustomEvent('password-valid', {
                detail: { valid: isValid },
            } as CustomEvent),
        );
    };
}

declare global {
    interface HTMLElementTagNameMap {
        'password-validations': PasswordValidations;
    }
}
