import { customElement, property } from 'lit-element';

import { LionStep } from '@lion/steps';

/**
 * Use @lion/steps components to implement a step wizard https://lion-web.netlify.app/components/navigation/steps/overview/
 * The <weave-step> must have a <weave-stepper> parent (WeaveStepper.ts)
 *
 * Listens to next/previous bubbling events from its ui children
 *
 * Validates itself by its validate prop (functions passed from outside) before going to next step
 * Validate must be return true otherwise halt
 */
@customElement('weave-step')
export class WeaveStep extends LionStep {
    // Pass a validate function from outside that returns a bool
    // Will be run before next() will be triggered
    @property({ type: Function })
    public validate: (data?: Object | undefined) => Promise<boolean> =
        (): Promise<boolean> => new Promise((res) => res(false));

    constructor() {
        super();
        this.addEventListener('weave-step-previous', this.prevHandler);
        this.addEventListener('weave-step-next', this.nextHandler);
    }

    public prevHandler = async (): Promise<void> => {
        if (this.controller) {
            const validated = await this.validate();
            if (validated) {
                this.controller.previous();
            }
        }
    };

    public nextHandler = async (): Promise<void> => {
        if (this.controller) {
            const validated = await this.validate();
            if (validated) {
                this.controller.next();
            }
        }
    };
}

declare global {
    interface HTMLElementTagNameMap {
        'weave-step': WeaveStep;
    }
}
