/* eslint-disable no-undef, max-classes-per-file, no-unused-vars */
import { Constructor } from 'lit-element';

interface FormInjectorClass extends HTMLElement {
    injectForm(tagName: keyof HTMLElementTagNameMap): void;
    uninjectForm(): void;
}

// Place to store a reference to FormInjector for development feedback
let element: FormInjectorClass | undefined;

// Returns the current injector element or throws if none exists
function definitelyElement(): FormInjectorClass {
    if (element === undefined) {
        throw new Error('FormInjector was accessed before it was initialized');
    }

    return element;
}

/**
 * Create and bind form element to the injector element
 */
export function injectForm(tagName: keyof HTMLElementTagNameMap): void {
    definitelyElement().injectForm(tagName);
}

/**
 * Clear injected form element from the injector element
 */
export function uninjectForm(): void {
    definitelyElement().uninjectForm();
}

// Hidden property key to store the currently injected form
const INJECTED: unique symbol = Symbol('formInjector: INJECTED');
interface Reference extends HTMLElement {
    [INJECTED]: {
        tagName: string;
        location: string;
    };
}

export const SLOT_NAME: 'injected-form' = 'injected-form';

/**
 * Mixin to add form injection functionality to an element
 */
export function formInjector(c: Constructor<HTMLElement>) {
    return class FormInjector extends c implements FormInjectorClass {
        // Currently injected form
        public [INJECTED]?: HTMLElement; // NOTE: Not allowed to be private (ts4094)

        constructor(...args: any[]) {
            super(...args);

            // Notify if already initialized
            if (element) {
                console.warn('FormInjector was already initialized', element);
            }

            // Save reference
            element = this;
        }

        /**
         * Create and bind form element
         */
        public injectForm = (tagName: keyof HTMLElementTagNameMap): void => {
            if (this[INJECTED]) {
                // Do nothing if we already have this form loaded
                const old = (<Reference>this[INJECTED])[INJECTED];
                if (
                    old.tagName === tagName &&
                    old.location === window.location.pathname
                ) {
                    return;
                }
            }

            // Ensure previous is cleared
            this.uninjectForm();

            // Construct element
            this[INJECTED] = window.document.createElement(tagName);
            if (this[INJECTED] === undefined) {
                throw new Error(`Unable to create element <${tagName}>`);
            }

            // Target <slot name="${SLOT_NAME}">
            this[INJECTED]!.setAttribute('slot', SLOT_NAME);

            // Save reference
            (<Reference>this[INJECTED])[INJECTED] = {
                tagName,
                location: window.location.pathname,
            };

            // Attach element
            this.appendChild(this[INJECTED]!);
        };

        /**
         * Clear injected form element
         */
        public uninjectForm = (): void => {
            // Check if there is something to remove
            if (this[INJECTED] === undefined) {
                return;
            }

            // Remove from DOM
            this.removeChild(this[INJECTED]!);
            // Clear reference
            delete this[INJECTED];
        };
    };
}

/**
 * Mixin to append a <slot name="${SLOT_NAME}"> element to the constructed element.
 *
 * This allows router targets to receive slotted contend from the router parent.
 */
export function formSlotInjector(
    internalName: string,
    externalName: string = SLOT_NAME,
) {
    return function formSlotInjectorMixin(c: Constructor<HTMLElement>) {
        return class SlotInjector extends c {
            /**
             * Marker to make sure the slot is only injected once
             */
            [INJECTED]: boolean = false;

            /**
             * Wait for component to be mounted to the DOM
             */
            connectedCallback() {
                // @ts-ignore
                if (typeof super.connectedCallback === 'function') {
                    // @ts-ignore
                    super.connectedCallback();
                }

                // Make sure the slot was not already injected
                if (this[INJECTED]) {
                    return;
                }
                this[INJECTED] = true;

                // Queue slot injection after synchronous stuff has finished
                queueMicrotask(() => {
                    const slot = window.document.createElement('slot');
                    slot.setAttribute('name', externalName);
                    slot.setAttribute('slot', internalName);
                    slot.style.display = 'contents';

                    this.appendChild(slot);
                });
            }
        };
    };
}
