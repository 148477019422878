import {
    LitElement,
    TemplateResult,
    customElement,
    html,
    css,
    query,
    property,
} from 'lit-element';
import '@components/elements/modal/ModalComponent';
import '@atoms/icon/HvIcon';

@customElement('hv-info-button')
export class HvInfoButton extends LitElement {
    @query('#modal')
    public modal?: HTMLElementTagNameMap['modal-component'];

    @property({ type: String })
    title: string = '';

    static get styles() {
        return [
            css`
                :host {
                    --hv-info-button-icon-size: 21px;
                    --hv-info-button-icon-color: #777;
                }
                hv-icon {
                    --hv-icon-color: var(--hv-info-button-icon-color);
                    --hv-icon-font-size: var(--hv-info-button-icon-size);
                }
                modal-component {
                    --modal__close--font-size: 50px;
                    --modal__header--margin: 10px 30px;
                    --modal__header--font-size: 20px;
                    --modal__header--text-align: left;
                }
            `,
        ];
    }

    public render = (): TemplateResult => {
        return html`<modal-component
                header-title="${this.title}"
                id="modal"
                prevent-closing-outside
            >
                <slot></slot>
            </modal-component>
            <div @click=${this.open}>
                <hv-icon name="question-circle" tone="outlined"></hv-icon>
            </div>`;
    };

    public open = (): void => {
        this.modal?.open();
    };

    public close = (): void => {
        this.modal?.close();
    };
}

declare global {
    interface HTMLElementTagNameMap {
        'hv-info-button': HvInfoButton;
    }
}
