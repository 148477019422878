const defaultCurrency = new Intl.NumberFormat('nl-NL', {
    style: 'currency',
    currency: 'EUR',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
});

const roundedCurrency = new Intl.NumberFormat('nl-NL', {
    style: 'currency',
    currency: 'EUR',
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
});

const extendedCurrency = new Intl.NumberFormat('nl-NL', {
    style: 'currency',
    currency: 'EUR',
    maximumFractionDigits: 5,
    minimumFractionDigits: 5,
});

const defaultUnit = new Intl.NumberFormat('nl-NL', {
    currency: 'EUR',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
});

const extendedUnit = new Intl.NumberFormat('nl-NL', {
    currency: 'EUR',
    maximumFractionDigits: 5,
    minimumFractionDigits: 5,
});

const defaultNumber = new Intl.NumberFormat('nl-NL');

export const unitCustom = (decimals: number) =>
    new Intl.NumberFormat('nl-NL', {
        currency: 'EUR',
        maximumFractionDigits: decimals,
        minimumFractionDigits: decimals,
    });

export const numberFormat = (value: number): string =>
    defaultNumber.format(value);

/**
 * New price helpers
 */
export const currency = (value: number): string =>
    defaultCurrency.format(value);

export const currencyExtended = (value: number): string =>
    extendedCurrency.format(value);

export const currencyRounded = (value: number): string =>
    roundedCurrency.format(value);

export const unit = (value: number): string => defaultUnit.format(value);

export const unitExtended = (value: number): string =>
    extendedUnit.format(value);
