import { provider } from '@weavelab/frontend-connect';
import enums from 'enums';
import settings from 'internationalization/settings';
import EzUsageTemplate from '../../templates/ez-usage-template/usage-template';

const themeSettings = provider.settings('production');

/**
 * Class for showing electric producing using Usage Template
 */
export default class EzProduction extends EzUsageTemplate {
    /**
     * Constructor of EzProducton
     */
    constructor() {
        super();
        /** @type {Number | null} */
        this.usageType = enums.UsageTypeElekProd;
        /** @type {string} */
        this.label = 'kWh';
        /** @type {boolean} */
        this.active = false;
        /** @type {string} */
        this.usageHeaderType = 'production';
        /** @type {String} */
        this.appTitle =
            themeSettings && themeSettings.title !== undefined
                ? themeSettings.title
                : this.appTitle;

        this.dataColor = settings.electricityProductionAccentColor;
        this.graphDescription =
            'Deze grafiek gaat uit van volledige saldering incl. energiebelasting, btw en inkoopkosten. Lever je per jaar meer terug dan dat je van het net verbruikt, krijg je bij de jaarafrekening over dat extra deel alleen de kale stroomprijs incl. btw terug.';
    }

    /**
     * Callback for global state changed
     * @param  {...any} args
     */
    _stateChanged(...args) {
        // @ts-ignore
        super._stateChanged(...args);
    }
}

window.customElements.define('ez-production', EzProduction);
