// returns a trimmed string.
export const trimString = (
    str: string,
    length: number = 2,
    after: boolean = false,
): string =>
    after ? str.substring(length, str.length - 1) : str.substring(0, length);

// converts a string to a number and removes the zero value if the string starts with it.
export const convertStringToNumber = (str: string): number =>
    Number(str.replace(/^0+/, ''));

export const getHourFromString = (str: string): number =>
    convertStringToNumber(trimString(str));

export const convertNumberToString = (hour: number): string => {
    let stringHour = hour.toString();
    if (stringHour.length === 1) {
        stringHour = `0${stringHour}`;
    }
    return stringHour;
};

export const hexToRGB = (str: string): number[] => {
    if (str.charAt(0) === '#') {
        str = str.substring(1, str.length);
    }
    if (str.length !== 6) {
        console.warn('Only six-digit hex colors are allowed.');
    }

    const aRgbHex = str.match(/.{1,2}/g);
    let aRgb = [0, 0, 0];
    if (aRgbHex) {
        aRgb = [
            parseInt(aRgbHex[0], 16),
            parseInt(aRgbHex[1], 16),
            parseInt(aRgbHex[2], 16),
        ];
    }
    return aRgb;
};

export const capitalizeFirstCharacter = (str: string): string =>
    str.charAt(0).toUpperCase() + str.slice(1);
