import { ReduxAsync } from '@weavedev/redux-async';
import { Purchase } from '../gtm-middleware/orderOverview';

// save the purchase data
const PURCHASE = 'PURCHASE';
const PURCHASE_SUCCESS = 'PURCHASE_SUCCESS';
const PURCHASE_FAILED = 'PURCHASE_FAILED';
export const setPurchase = new ReduxAsync(
    PURCHASE,
    PURCHASE_SUCCESS,
    PURCHASE_FAILED,
    async (purchaseObj: Purchase | null): Promise<Purchase | null> =>
        Promise.resolve(purchaseObj),
);
