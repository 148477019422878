import { all, call, takeLatest, put } from 'redux-saga/effects';
import { betterWrapper } from './helpers/wrapper';
import {
    REQUEST_INSIGHTS,
    requestInsightsSuccess,
    requestInsightsFailed,
} from '../actions/insights';

import { store, electricityUsage, gasUsage, productionUsage } from '../store';

import { calculateMonthlyUsages } from '../helpers/calculateMonthlyValues';

import enums from '../enums';

/**
 * Runs doGetGraphData
 */
function* doGetInsights() {
    const state = store.getState();

    const today = new Date();
    let from = new Date(today.getFullYear(), today.getMonth(), 1);
    const till = new Date(today.getFullYear(), today.getMonth() + 1, 0);

    const { contracts } = state.user;
    if (contracts) {
        let contractFirstStartDate = today;
        if (contracts.length > 1) {
            contracts.forEach((contract) => {
                if (
                    contractFirstStartDate &&
                    new Date(contract.start) < contractFirstStartDate
                ) {
                    contractFirstStartDate = new Date(contract.start);
                }
            });
        } else if (contracts.length === 1) {
            contractFirstStartDate = new Date(contracts[0].start);
        }
        if (
            contractFirstStartDate.getFullYear() === today.getFullYear() &&
            contractFirstStartDate.getMonth() === today.getMonth()
        ) {
            from = contractFirstStartDate;
        }
    }

    const requestObject = {
        dateFrom: new Date(from.setUTCHours(0, 0, 0)).toISOString(),
        dateTill: new Date(till.setUTCHours(0, 0, 0)).toISOString(),
        intervalType: enums.IntervalTypeHourly,
    };

    try {
        const [e, g, p] = yield all([
            call(
                betterWrapper,
                electricityUsage.request(requestObject),
                electricityUsage,
            ),
            call(betterWrapper, gasUsage.request(requestObject), gasUsage),
            call(
                betterWrapper,
                productionUsage.request(requestObject),
                productionUsage,
            ),
        ]);
        yield put(
            requestInsightsSuccess(
                e.data.length > 0
                    ? Number(calculateMonthlyUsages(e.data))
                    : null,
                g.data.length > 0
                    ? Number(calculateMonthlyUsages(g.data))
                    : null,
                p.data.length > 0
                    ? Number(calculateMonthlyUsages(p.data))
                    : null,
                requestObject,
            ),
        );
    } catch (error) {
        yield put(requestInsightsFailed(error));
    }
}

/**
 * Watcher for REQUEST_GRAPH action
 */
export function* watchRequestInsights() {
    yield takeLatest(REQUEST_INSIGHTS, doGetInsights);
}
