import { routePaths } from 'data-access/router';
import { setShowMenu } from '@actions/menu';
import { ReduxAsync } from '@weavedev/redux-async';
import { AxiosResponse } from 'axios';
import { store } from 'data-access/store';
import { Snapshots } from 'types/snapshot';
import { User } from 'types/user';
import { MeteringPoint } from 'types/meteringPoint';
import settings, { defaultText } from 'internationalization/settings';
import { updateUserAuthenticated } from 'actions/app';
import { unAuthAction } from 'actions/user';

export interface signInData {
    user: User;
    snapshots: Snapshots[];
    contracts?: Snapshots[];
    meteringpoints: MeteringPoint[];
}

export interface signInResponse extends AxiosResponse {
    data: signInData;
}

// shows menu after signing in or receive self only if the location doesn't contain a customer or an oda route
const showMenu = () => {
    if (store.getState().showMenu) {
        return;
    }
    if (
        !new URL(window.location.href).pathname.includes(routePaths.customer) &&
        !new URL(window.location.href).pathname.includes(routePaths.oda)
    ) {
        store.dispatch(setShowMenu(true));
    }
};

// Sign the user in
const SIGN_IN = 'SIGN_IN';
const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS';
const SIGN_IN_FAILED = 'SIGN_IN_FAILED';
export const signIn = new ReduxAsync(
    SIGN_IN,
    SIGN_IN_SUCCESS,
    SIGN_IN_FAILED,
    async (email: string, password: string): Promise<signInResponse> => {
        const headers = new Headers();
        headers.append('Accept', 'application/json');
        headers.append('Content-type', 'application/json');
        // buf.toString instead of btoa
        headers.append(
            'Authorization',
            `Basic ${btoa(`${email}:${password}`)}`,
        );

        let params = '';
        const signinEndPoint = `${window.API_LINK}/v1/jwt/signin`;
        // todo: validate if appDomain or appTitle are set for every vendor
        if (settings.appDomain && settings.appDomain !== defaultText) {
            params += `EZVendor=${settings.appDomain.toLowerCase()}`;
        } else if (settings.appTitle && settings.appTitle !== defaultText) {
            params += `EZVendor=${settings.appTitle.toLowerCase()}`;
        }
        if (settings.vendorID && settings.vendorID.toString() !== '') {
            if (params.length > 0) {
                params = `?${params}&`;
            } else if (params.length === 0) {
                params = '?';
            }
            params += `vendorID=${settings.vendorID}`;
        }

        const resp = await fetch(signinEndPoint + params, {
            method: 'POST',
            headers,
        });

        if (resp.status >= 200 && resp.status < 300) {
            const bearer = resp.headers.get('Authorization');
            if (bearer != null) {
                window.STDHeaders.set('X-Auth', bearer);
                localStorage.setItem('auth', bearer);
                showMenu();
            }
            return await resp.json();
        }
        throw resp.status;
    },
);

const RECEIVE_SELF = 'RECEIVE_SELF';
const RECEIVE_SELF_SUCCESS = 'RECEIVE_SELF_SUCCESS';
const RECEIVE_SELF_FAILED = 'RECEIVE_SELF_FAILED';
export const receiveSelf = new ReduxAsync(
    RECEIVE_SELF,
    RECEIVE_SELF_SUCCESS,
    RECEIVE_SELF_FAILED,
    async (): Promise<signInResponse> => {
        const resp = await fetch(`${window.API_LINK}/v1/jwt/refresh`, {
            method: 'GET',
            headers: window.STDHeaders,
        });

        if (
            (localStorage.getItem('auth') != null &&
                (resp.status < 200 || resp.status > 299)) ||
            resp.status === 401
        ) {
            store.dispatch(unAuthAction);
            throw resp.status;
        } else if (resp.status >= 200 && resp.status < 300) {
            const bearer = resp.headers.get('Authorization');
            if (bearer != null) {
                store.dispatch(updateUserAuthenticated(true));
                window.STDHeaders.set('X-Auth', bearer);
                localStorage.setItem('auth', bearer);
                showMenu();
                store.dispatch(authCheck.run(true));
            }
        }
        return await resp.json();
    },
);

const AUTH_CHECK = 'AUTH_CHECK';
const AUTH_CHECK_SUCCESS = 'AUTH_CHECK_SUCCESS';
const AUTH_CHECK_FAILED = 'AUTH_CHECK_FAILED';
export const authCheck = new ReduxAsync(
    AUTH_CHECK,
    AUTH_CHECK_SUCCESS,
    AUTH_CHECK_FAILED,
    async (bool: Boolean): Promise<Boolean> => {
        const previousState = store.getState().authCheck;
        // Prevent updating the state if auth has been checked
        if (previousState) {
            return true;
        }

        return bool;
    },
);
