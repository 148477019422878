import { createSelector, Selector } from 'reselect';
import { initOda, acceptOda, verifyOda } from '../async-reducers/oda';
import { geoCodeCheck } from '../async-reducers/geocode';

export interface OdaStorePart {
    initOda: typeof initOda.defaultState;
    acceptOda: typeof acceptOda.defaultState;
    verifyOda: typeof verifyOda.defaultState;
    geoCodeCheck: typeof geoCodeCheck.defaultState;
}

export const odaStateBusy: Selector<OdaStorePart, Boolean> = createSelector(
    (storeState: OdaStorePart) => {
        const { initOda, acceptOda, verifyOda, geoCodeCheck } = storeState;
        return { initOda, acceptOda, verifyOda, geoCodeCheck };
    },
    (odaState: OdaStorePart) => {
        return (
            odaState.initOda.busy ||
            odaState.acceptOda.busy ||
            odaState.verifyOda.busy ||
            odaState.geoCodeCheck.busy
        );
    },
);
