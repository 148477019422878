import { PolymerElement, html } from '@polymer/polymer/polymer-element';
import { provider } from '@weavelab/frontend-connect';

// Router import
import { Router } from '@vaadin/router';
import { routePaths } from 'helpers/routing';
import watch from 'redux-watch';
import { signIn } from '@async-reducers/authentication';
import { formSlotInjector } from 'helpers/formInjector';
import { loginHook } from 'helpers/loginHook';
import { store } from 'data-access/store';
import { debounce } from 'helpers/functions';

import { awaitLogin as _awaitLgn } from 'actions/routing';
import { forcedUserToSnapshotIfOpen } from 'actions/user';
import { updateTermsAndConditions } from 'actions/app';

import css from './lander.pcss';
import template from './lander.html';

import '@polymer/paper-input/paper-input';
import '@polymer/iron-flex-layout/iron-flex-layout-classes';
import { redirectManager } from '../../../packages/redirect/RedirectManager';

import { sleep } from '../../../globals';

const themeSettings = provider.settings('lander');
const sleepTime = 500;

/**
 * Lander class
 */
export default class EzLander extends formSlotInjector('form')(PolymerElement) {
    /**
     * Gets properties of class
     */
    static get properties() {
        return {
            email: {
                type: String,
            },
            password: {
                type: String,
            },
            route: {
                type: String,
                observer: '_routeWatcher',
            },
            isApp: {
                type: Boolean,
                value: false,
            },
            isRedirected: {
                type: Boolean,
                value: false,
            },
            loading: {
                type: Boolean,
                value: false,
            },
            disableNewCustomerOnboardingButton: {
                type: Boolean,
                value: false,
            },
        };
    }

    /**
     * Gets template of class
     */
    static get template() {
        const cssTemplate = document.createElement('template');
        cssTemplate.innerHTML = provider.styles(css);
        const htmlTemplate = document.createElement('template');
        htmlTemplate.innerHTML = template;
        return html`<style include="iron-flex">
                ${cssTemplate}
            </style>
            ${htmlTemplate}`;
    }

    /**
     * Constructor of reset password
     */
    constructor() {
        super();
        /** @type {String} */
        this.body = themeSettings?.body ? themeSettings.body : '';
        /** @type {Array} */
        this.customLinks =
            themeSettings && Array.isArray(themeSettings.customLinks)
                ? themeSettings.customLinks
                : [];

        /** @type {String} */
        this.emailPlaceholder =
            themeSettings?.emailPlaceholder != null
                ? themeSettings.emailPlaceholder
                : '';

        /** @type {String} */
        this.emailTitle =
            themeSettings?.emailTitle != null ? themeSettings.emailTitle : '';

        /** @type {String} */
        this.forgotPassword =
            themeSettings?.forgotPassword != null
                ? themeSettings.forgotPassword
                : '';

        /** @type {String} */
        this.forgotPasswordPre =
            themeSettings?.forgotPasswordPre != null
                ? themeSettings.forgotPasswordPre
                : '';

        /** @type {String} */
        this.newCustomer =
            themeSettings?.newCustomer != null ? themeSettings.newCustomer : '';

        /** @type {String} */
        this.newCustomerPre =
            themeSettings?.newCustomerPre != null
                ? themeSettings.newCustomerPre
                : '';

        /** @type {String} */
        this.passwordPlaceholder =
            themeSettings?.passwordPlaceholder != null
                ? themeSettings.passwordPlaceholder
                : '';

        /** @type {String} */
        this.passwordTitle =
            themeSettings?.passwordTitle != null
                ? themeSettings.passwordTitle
                : '';

        /** @type {String} */
        this.submit = themeSettings?.submit != null ? themeSettings.submit : '';

        /** @type {String} */
        this.appTitle = themeSettings?.title != null ? themeSettings.title : '';

        /** @type {Boolean} */
        this.disableLogin =
            themeSettings?.disableLogin != null
                ? themeSettings.disableLogin
                : false;

        /** @type {Boolean} */
        this.disableNewCustomerOnboardingButton =
            themeSettings?.disableNewCustomerOnboardingButton || false;
        /** @type {String} */
        this.editCustomerURLProduction =
            themeSettings?.newCustomerRedirectOverwrite;
        /** @type {String} */
        this.editCustomerURLPrerelease = themeSettings?.editURLStaging;
    }

    /**
     * onBeforeEnter is a Vaadin router lifecycle method
     * @param {RouterLocation} location
     * @param {PreventAndRedirectCommands} commands
     * @param {Router} router
     */
    onBeforeEnter() {
        // timeout is required because the component needs to be loaded first
        (async () => {
            this.loading = true;
            await sleep(sleepTime);
            if (localStorage.getItem('auth') != null) {
                const location = JSON.parse(localStorage.getItem('location'));
                if (location != null) {
                    let loc = routePaths.overview;
                    if (location && location.hash) {
                        loc += `${location.hash}`;
                    }
                    Router.go(loc);
                    localStorage.removeItem('location');
                }
            }
            // awaitLogin is true when the sign in url has an auth param
            const awaitLoading = store.getState().awaitLogin;
            this.loading = awaitLoading;
            // if the login page is called but snapshots are set redirect the overview page.
            if (store.getState().jwtSnapshots.snapshots.length) {
                Router.go(routePaths.overview);
            }
        })();
    }

    /**
     * getRedirected will set the bool and returns it
     * @return {boolean}
     */
    getRedirected = () => redirectManager.redirectURL !== '';

    /**
     * Subscribes on user state when element is ready
     */
    ready() {
        super.ready();
        this.isApp = !!window.cordova;
        this.isRedirected = this.getRedirected();

        const pageWatcher = watch(store.getState, 'redirect.to');
        store.subscribe(
            pageWatcher(
                /**
                 * @param {String} to
                 */
                (to) => {
                    if (to !== '') {
                        this.isRedirected = this.getRedirected();
                    }
                },
            ),
        );

        // if the login page has a selected snapshot redirect to the overview page
        const watchSnapshots = watch(store.getState, 'jwtSnapshots');
        store.subscribe(
            watchSnapshots(
                /**
                 * @param {Snapshot} snapshots
                 */
                (snapshots) => {
                    if (
                        snapshots.selectedSnapshot &&
                        localStorage.getItem('auth')
                    ) {
                        store.dispatch(_awaitLgn(false));
                        if (window.location.hash !== '') {
                            Router.go('/');
                        }
                    }
                },
            ),
        );

        // fallback watcher to set loading when required.
        const awaitLogin = watch(store.getState, 'awaitLogin');
        store.subscribe(
            awaitLogin(
                /**
                 * @param {Boolean} awaitLogin
                 */
                (awaitLogin_) => {
                    debounce(400, () => {
                        this.loading = awaitLogin_;
                    });
                },
            ),
        );

        // Target current login flow with login form
        loginHook.onLogin = this.onLogin;
    }

    /**
     * Logout watcher
     * @param {string} next
     * @param {string} prev
     */
    _routeWatcher = (next, prev) => {
        if (
            next === 'lander' &&
            next !== prev &&
            window.location.hash === '#/lander'
        ) {
            localStorage.clear();
            STDHeaders.delete('X-Auth');
        }
    };

    // validate the given URL if its a string and not empty
    // then use the URL to open in the current window
    validateURLAndOpenInCurrentWindow = (redirectURL) => {
        if (typeof redirectURL === 'string' && redirectURL !== '') {
            window.open(redirectURL, '_self');
            return;
        }
        console.warn('the customer redirect URL is not valid');
    };

    /**
     * Get the target URL for the new customer button
     * @return {String}
     */
    _newCustomerURL = () => {
        const { isProduction } = window.env;
        // checks if there is a customer URL redirect required for production and prerelease environments
        if (this.editCustomerURLProduction != null && isProduction) {
            this.validateURLAndOpenInCurrentWindow(
                this.editCustomerURLProduction,
            );
            return;
        }
        if (this.editCustomerURLPrerelease != null && !isProduction) {
            this.validateURLAndOpenInCurrentWindow(
                this.editCustomerURLPrerelease,
            );
            return;
        }
        Router.go(routePaths.customer);
    };

    /**
     * Catch the keyboard events for sign in a user, when the enter button is pressed
     * @param {KeyboardEvent | MouseEvent}
     */
    passwordKeypress(e) {
        if (e instanceof KeyboardEvent && e.key === 'Enter') {
            this._login();
        }
    }

    /**
     * Back will remove redirect url and let the user go to prev page
     */
    _back = () => {
        Router.go(routePaths.customerForm);
    };

    /**
     * Navigates the forgot password page
     */
    goToForgot = () => {
        Router.go(routePaths.forgot);
    };

    /**
     * Login handler
     * @param {string} email what the user has claimed their email address is
     * @param {string} password what the user has claimed their password is
     */
    onLogin = async (email, password) => {
        this.email = email;
        this.password = password;
        this._login();
        let yep;
        this.loginPromise = new Promise((resolve) => {
            yep = resolve;
        });
        this.loginPromise.yep = yep;
        await this.loginPromise;
        this._login();
    };

    _disableNewCustomer() {
        return this.isRedirected || this.disableNewCustomerOnboardingButton;
    }

    /**
     * Login dispatch sigin function to the store
     */
    _login() {
        if (this.email === '' || this.password === '') {
            window.displayMessage(
                'E-mail en wachtwoord moeten worden ingevuld.',
            );
            this.loginPromise.yep();
            return;
        }
        const email = this.email.trim();
        const re = new RegExp('^[^@\\s:]+@[^@\\s]+\\.[^@\\s]+$');
        if (!re.test(email)) {
            window.displayMessage('Dit is geen geldig e-mailadres.');
            this.loginPromise.yep();
            return;
        }
        store.dispatch(updateTermsAndConditions(true));

        Promise.resolve(store.dispatch(signIn.run(email, this.password)))
            .then((e) => {
                if (e.type === 'RECEIVE_USER') {
                    this.email = '';
                    this.password = '';

                    const { user } = store.getState();

                    window.displayMessage('Succesvol ingelogd');

                    if (
                        !user ||
                        !user.snapshots ||
                        !Array.isArray(user.snapshots) ||
                        user.snapshots.length === 0
                    ) {
                        if (user.contracts && user.contracts.length === 0) {
                            Router.go(routePaths.customer);
                            return;
                        }
                    }
                    if (forcedUserToSnapshotIfOpen(user.snapshots)) {
                        return;
                    }

                    /** @type {String?} */
                    let goTo = null;
                    if (user.snapshots && user.snapshots.length > 0) {
                        user.snapshots.forEach(
                            /**
                             * @param {Object} s
                             * @return {void}
                             */
                            (s) => {
                                goTo =
                                    s && s.id && s.snapshot_phase < 7
                                        ? s.id
                                        : goTo;
                            },
                        );
                    }

                    const redirectUrl = redirectManager.redirectURL;

                    // CHECK IF WE SHOULD REDIRECT
                    // We have a known redirect so redirect to this page
                    if (redirectUrl !== '') {
                        // TODO: delete redirecte url
                        // @ts-ignore
                        window.location.href = redirectUrl;
                        redirectManager.clear();
                        return;
                    }

                    if (goTo) {
                        Router.go(`${routePaths.customer}/${goTo}`);
                    } else {
                        Router.go(routePaths.overview);
                    }
                }
            })
            .finally(() => {
                this.loginPromise.yep();
            });
    }
}

window.customElements.define('ez-lander', EzLander);
