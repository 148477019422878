// Checks if an user is authenticated and have a valid snapshot to display the dashboard
import { ForkEffect, put, takeLatest } from 'redux-saga/effects';
import { setMeteringPoints } from '@async-reducers/meteringPoint';
import { goToNextPhase as goToNextSnapshotPhase } from '@async-reducers/snapshot';

export function* watchIfMeteringPointsSet(): IterableIterator<ForkEffect> {
    yield takeLatest([setMeteringPoints.actionTypeMap.callback], goToNextPhase);
}

function* goToNextPhase() {
    yield put(
        goToNextSnapshotPhase.run(
            window.store.getState().user.id,
            window.store.getState().jwtSnapshots.snapshots[0].id,
        ),
    );
}
