export const invalidMessage = (errorMessage: string) =>
    `invalid ${errorMessage}`;

export const returnErrorOrEmptyString = (
    isValid: boolean,
    str: string,
): string => {
    if (isValid) {
        return 'valid';
    }

    return invalidMessage(str || '');
};
