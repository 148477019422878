import { SET_SHOW_MENU } from '../actions/menu';

/**
 * State reducer for energysplitter
 */
export const showMenu = (state: boolean = false, action: any): boolean => {
    switch (action.type) {
        case SET_SHOW_MENU:
            return action.showMenu;
        default:
            return state;
    }
};
