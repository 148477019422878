'use strict';
// Load polyfills
import '@webcomponents/scoped-custom-element-registry';

import EventTarget from '@ungap/event-target';

window.EventTarget = EventTarget;

import { Store } from 'redux';

// Set window headers
window.STDHeaders = new Headers();
window.STDHeaders.append('Accept', 'application/json');
window.STDHeaders.append('Content-type', 'application/json');
const bearer = localStorage.getItem('auth');
if (bearer !== null) {
    window.STDHeaders.append('X-Auth', bearer);
    window.XAuth = bearer;
}

export type MessageTypes = 'info' | 'succes' | 'warning' | 'error';
export enum messageTypes {
    'info' = 'info',
    'succes' = 'succes',
    'warning' = 'warning',
    'error' = 'error',
}

// bind displayMessage to the window.
export const displayMessage = (message: any, type?: MessageTypes) => {
    if (message !== '') {
        const app = document.getElementById('app');
        if (app) {
            app.dispatchEvent(
                new CustomEvent('display-message', {
                    composed: true,
                    detail: { text: message, type },
                }),
            );
        }
    }
};

window.displayMessage = displayMessage;

/* Import WebpackApp */

/* eslint-disable no-unused-vars */
import '@polymer/polymer/lib/elements/dom-if';
/* tslint:disable:no-import-side-effect */
import './setup';
/* tslint:disable:no-import-side-effect */
import './components/containers/ez-app/app';
// @ts-ignore
import sw from './sw-loader';

// @ts-ignore
sw();

declare global {
    interface Window {
        store: Store;
        XAuth: string;
    }
}
