import { nullableInt } from '../helpers/reduxTypedActions';

export const PREFIX = 'Order: ';

export const CLEAR = `${PREFIX}CLEAR`;
export const CLEAR_COLLECTIVE = `${PREFIX}CLEAR_COLLECTIVE`;
export const SET_COLLECTIVE = `${PREFIX}SET_COLLECTIVE`;
export const SET_ELECTRICITY = `${PREFIX}SET_ELECTRICITY`;
export const SET_ELECTRICITY_PRODUCTION_ESTIMATE = `${PREFIX}SET_ELECTRICITY_PRODUCTION_ESTIMATE`;
export const SET_ELECTRICITY_USAGE_ESTIMATE = `${PREFIX}SET_ELECTRICITY_USAGE_ESTIMATE`;
export const SET_ELECTRICITY_USAGE_FACTOR_ESTIMATE = `${PREFIX}SET_ELECTRICITY_USAGE_FACTOR_ESTIMATE`;
export const SET_GAS_USAGE_ESTIMATE = `${PREFIX}SET_GAS_USAGE_ESTIMATE`;
export const SET_HOUSE_NUMBER = `${PREFIX}SET_HOUSE_NUMBER`;
export const SET_HOUSE_NUMBER_SUFFIX = `${PREFIX}SET_HOUSE_NUMBER_SUFFIX`;
export const SET_PROPOSITION = `${PREFIX}SET_PROPOSITION`;
export const SET_OFFER = `${PREFIX}SET_OFFER`;
export const SET_POSTAL_CODE = `${PREFIX}SET_POSTAL_CODE`;
export const SET_CHARGE_POLE_USAGE = `${PREFIX}SET_CHARGE_POLE_USAGE`;
export const SET_SOLAR_MAIL = `${PREFIX}SET_SOLAR_MAIL`;
export const ADD_LABEL = `${PREFIX}ADD_LABEL`;
export const SET_ENERGYSPLITTER = `${PREFIX}SET_ENERGYSPLITTER`;
export const RESET_ORDER = `${PREFIX}RESET_ORDER`;
export const SET_GEO_INFORMATION = `${PREFIX}SET_GEO_INFORMATION`;

/**
 * Action generator for clear
 * @return {Object}
 */
export const clear = () => ({ type: CLEAR });

/**
 * Action generator for clear collective
 * @return {Object}
 */
export const clearCollective = () => ({ type: CLEAR_COLLECTIVE });

/**
 * Action generator for set collective
 * @param {String} collective
 * @return {Object}
 */
export const setCollective = (collective) => ({
    type: SET_COLLECTIVE,
    collective,
});

/**
 * Action generator for set electricity
 * @param {Boolean} electricity
 * @return {Object}
 */
export const setElectricity = (electricity) => ({
    type: SET_ELECTRICITY,
    electricity,
});

/**
 * Action generator for set electricity production estimate
 * @param {?Number} electricityProductionEstimate
 * @return {Object}
 */
export const setElectricityProductionEstimateAction = (
    electricityProductionEstimate,
) => ({
    type: SET_ELECTRICITY_PRODUCTION_ESTIMATE,
    electricityProductionEstimate,
});
/**
 * Checked action generator for set electricity production estimate
 * @param {?Number} electricityProductionEstimate
 * @return {Object}
 */
export const setElectricityProductionEstimate = (
    electricityProductionEstimate,
) =>
    nullableInt(
        electricityProductionEstimate,
        setElectricityProductionEstimateAction,
        PREFIX,
    );

/**
 * Action generator for set electricity usage estimate
 * @param {?Number} electricityUsageEstimate
 * @return {Object}
 */
export const setElectricityUsageEstimateAction = (
    electricityUsageEstimate,
) => ({
    type: SET_ELECTRICITY_USAGE_ESTIMATE,
    electricityUsageEstimate,
});
/**
 * Checked action generator for set electricity usage estimate
 * @param {?Number} electricityUsageEstimate
 * @return {Object}
 */
export const setElectricityUsageEstimate = (electricityUsageEstimate) =>
    nullableInt(
        electricityUsageEstimate,
        setElectricityUsageEstimateAction,
        PREFIX,
    );

/**
 * Action generator for set electricity usage factor estimate
 * @param {Number} electricityUsageFactorEstimate
 * @return {Object}
 */
export const setElectricityUsageFactorEstimate = (
    electricityUsageFactorEstimate,
) => ({
    type: SET_ELECTRICITY_USAGE_FACTOR_ESTIMATE,
    electricityUsageFactorEstimate,
});

export const SET_GAS = `${PREFIX}SET_GAS`;
/**
 * Action generator for set gas
 * @param {Boolean} gas
 * @return {Object}
 */
export const setGas = (gas) => ({ type: SET_GAS, gas });

/**
 * Action generator for set gas usage estimate
 * @param {?Number} gasUsageEstimate
 * @return {Object}
 */
export const setGasUsageEstimateAction = (gasUsageEstimate) => ({
    type: SET_GAS_USAGE_ESTIMATE,
    gasUsageEstimate,
});
/**
 * Checked action generator for set gas usage estimate
 * @param {?Number} gasUsageEstimate
 * @return {Object}
 */
export const setGasUsageEstimate = (gasUsageEstimate) =>
    nullableInt(gasUsageEstimate, setGasUsageEstimateAction, PREFIX);

/**
 * Action generator for set house number
 * @param {?Number} houseNumber
 * @return {Object}
 */
export const setHouseNumberAction = (houseNumber) => ({
    type: SET_HOUSE_NUMBER,
    houseNumber,
});
/**
 * Checked action generator for set house number
 * @param {?Number} houseNumber
 * @return {Object}
 */
export const setHouseNumber = (houseNumber) =>
    nullableInt(houseNumber, setHouseNumberAction, PREFIX);

/**
 * Action generator for set house number suffix
 * @param {String} houseNumberSuffix
 * @return {Object}
 */
export const setHouseNumberSuffix = (houseNumberSuffix) => ({
    type: SET_HOUSE_NUMBER_SUFFIX,
    houseNumberSuffix,
});

/**
 * Action generator for set proposition
 * @param {String} proposition
 * @return {Object}
 */
export const setProposition = (proposition) => ({
    type: SET_PROPOSITION,
    proposition,
});

/**
 * Action generator for set offer
 * @param {String} id
 * @param {Number} index
 * @return {Object}
 */
export const setOffer = (id, index) => ({ type: SET_OFFER, id, index });

/**
 * Action generator for set postal code
 * @param {String} postalCode
 * @return {Object}
 */
export const setPostalCode = (postalCode) => ({
    type: SET_POSTAL_CODE,
    postalCode,
});

/**
 * Action generator for set gas usage estimate
 * @param {?Number} chargePoleUsage
 * @return {Object}
 */
export const setChargePoleUsage = (chargePoleUsage) => ({
    type: SET_CHARGE_POLE_USAGE,
    chargePoleUsage,
});

/**
 * Action generator for set redirect type
 * @param {?boolean} solarMail
 * @return {Object}
 */
export const setSolarMail = (solarMail) => ({
    type: SET_SOLAR_MAIL,
    solarMail,
});

/**
 * Action generator for set redirect type
 * @param {any} label
 * @return {Object}
 */
export const addLabel = (label) => ({
    type: ADD_LABEL,
    label,
});

/**
 * Action generator for set redirect type
 * @param {?boolean} energysplitter
 * @return {Object}
 */
export const setEnergysplitter = (energysplitter) => ({
    type: SET_ENERGYSPLITTER,
    energysplitter,
});

/**
 * Action generator for set redirect type
 * @param {?Object} geoInformation
 * @return {Object}
 */
export const setGeoInformation = (geoInformation) => ({
    type: SET_GEO_INFORMATION,
    geoInformation,
});

/**
 * Action generator for reset order
 * @return {Object}
 */
export const resetOrder = () => ({
    type: RESET_ORDER,
});
