import { ReduxAsync } from '@weavedev/redux-async';
import { store } from 'data-access/store';
import axios, { AxiosResponse } from 'axios';
import { Snapshot } from 'types/snapshot';
import headers from '../sagas/helpers/headers';
import { isBusy } from '@actions/busy';
import { setLoader } from '@actions/loader';

// Start stop charging vehicle
const SNAPSCHOT_AGREE = 'SNAPSCHOT_AGREE';
const SNAPSCHOT_AGREE_SUCCESS = 'SNAPSCHOT_AGREE_SUCCESS';
const SNAPSCHOT_AGREE_FAILED = 'SNAPSCHOT_AGREE_FAILED';
export const userAgreesSnapshot = new ReduxAsync(
    SNAPSCHOT_AGREE,
    SNAPSCHOT_AGREE_SUCCESS,
    SNAPSCHOT_AGREE_FAILED,
    async (userID: string, snapshotID: string): Promise<Snapshot> => {
        store.dispatch(setLoader(true));
        store.dispatch(isBusy('Snapshot agree'));
        const response: AxiosResponse = await axios.put(
            `v1/user/${userID}/snapshot/${snapshotID}/agree`,
            {
                user_id: userID,
                snapschot_id: snapshotID,
            },
            {
                baseURL: window.API_LINK,
                headers: headers(window.STDHeaders),
            },
        );
        if (response.statusText === 'OK' || response.status === 200) {
            return response.data;
        }

        response.data = { ...response.data, snapshotID };
        throw response;
    },
);

// This call triggers a snapshot phase proceed, but only proceeds when applicable
const NEXT_SNAPSHOT_PHASE = 'NEXT_SNAPSHOT_PHASE';
const NEXT_SNAPSHOT_PHASE_SUCCESS = 'NEXT_SNAPSHOT_PHASE_SUCCESS';
const NEXT_SNAPSHOT_PHASE_FAILED = 'NEXT_SNAPSHOT_PHASE_FAILED';
export const goToNextPhase = new ReduxAsync(
    NEXT_SNAPSHOT_PHASE,
    NEXT_SNAPSHOT_PHASE_SUCCESS,
    NEXT_SNAPSHOT_PHASE_FAILED,
    async (userID: string, snapshotID: string): Promise<Snapshot> => {
        const response: AxiosResponse = await axios.get(
            `v1/user/${userID}/snapshot/${snapshotID}`,
            {
                baseURL: window.API_LINK,
                headers: headers(window.STDHeaders),
            },
        );
        if (response.statusText === 'OK' || response.status === 200) {
            return response.data;
        }

        response.data = { ...response.data, snapshotID };
        throw response;
    },
);
