import {
    LitElement,
    customElement,
    html,
    property,
    CSSResult,
} from 'lit-element';
import style from './HvCheckboxButtonStyle';
import '@atoms/icon/HvIcon';

@customElement('hv-checkbox-button')
export class HvCheckboxButton extends LitElement {
    @property({ type: Boolean })
    checked: boolean = false;

    @property({ type: String })
    label: string = '';

    public static get styles(): CSSResult[] {
        return [style];
    }

    constructor() {
        super();
        this.addEventListener('click', (ev) => {
            ev.stopPropagation();
            this.dispatchEvent(
                new Event('user-input-changed', {
                    bubbles: true,
                    composed: true,
                }),
            );
        });
    }

    render() {
        return html`<div ?checked="${this.checked}">
            <hv-icon class="check" name="check"></hv-icon>
        </div>`;
    }
}
