import { LitElement, TemplateResult, customElement, html } from 'lit-element';

@customElement('hv-excuse')
export class Excuse extends LitElement {
    public render = (): TemplateResult => {
        return html` <style>
                :host {
                    display: flex;
                    flex-direction: column;
                    padding: 40px;
                    font-family: var(--app-primary-font-family), sans-serif;
                }
            </style>
            <h1>
                Het is op dit moment niet mogelijk om een energiecontract af te
                sluiten
            </h1>
            <p>Excuses voor het ongemak!</p>`;
    };
}

declare global {
    interface HTMLElementTagNameMap {
        'hv-excuse': Excuse;
    }
}
