import {
    LitElement,
    TemplateResult,
    customElement,
    html,
    css,
} from 'lit-element';

import '@components/organism/stepper/WeaveStepper';
import '@components/organism/stepper/WeaveStep';
import '@views/smart-charging/SmartChargingOnboarding';
import '@views/smart-charging/SmartChargingDashboard';
import { connect, watch } from 'lit-redux-watch';
import { Snapshot } from 'types/snapshot';
import enums from 'enums';

@customElement('smart-charging')
export class SmartCharging extends connect(window.store)(LitElement) {
    @watch('jwtSnapshots.smartChargingSnapshots')
    public smartChargingSnapshot?: Snapshot[];

    static get styles() {
        return [
            css`
                :host {
                    flex-grow: unset !important;
                }
            `,
        ];
    }

    public render = (): TemplateResult => {
        return html`
            ${this.smartChargingSnapshot?.[0]?.snapshot_phase ===
            enums.SnapshotPhaseSmartChargingContractCreated
                ? html`<view-smart-charging-onboarding></view-smart-charging-onboarding>`
                : html`<view-smart-charging
                      ><view-smart-charging-dashboard></view-smart-charging-dashboard
                  ></view-smart-charging>`}
        `;
    };
}

declare global {
    interface HTMLElementTagNameMap {
        'smart-charging': SmartCharging;
    }
}
