export const DEFAULT_LOCALE = 'nl-NL';

export const convertToLocalCurrency = (
    num: number | string,
    radix: number = 10,
    local: string = DEFAULT_LOCALE,
): string => {
    if (typeof num === 'string') {
        num = parseInt(num, radix);
    }
    return new Intl.NumberFormat(local, {
        style: 'currency',
        currency: 'EUR',
    }).format(num);
};

export const convertToLocalCurrencyNotRounded = (
    num: number | string,
    local: string = DEFAULT_LOCALE,
): string => {
    if (typeof num === 'string') {
        num = parseFloat(num);
    }
    return new Intl.NumberFormat(local, {
        style: 'currency',
        currency: 'EUR',
    }).format(num);
};
