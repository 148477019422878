import {
    LitElement,
    TemplateResult,
    customElement,
    html,
    property,
} from 'lit-element';
import { whitelabel } from '@weavelab/whitelabel';

import forgotPasswordStyle from './ForgotPasswordStyle';
import { provider } from '@weavelab/frontend-connect';
import { store } from '../../store';
import { connect } from 'lit-redux-watch';
import { _defaults } from '@components/helpers/_defaults';
import { ironFlex } from '@components/helpers/iron-flex-layout/iron-flex';
import { Router } from '@vaadin/router';
import { routePaths } from '../../helpers/routing';

import '@polymer/paper-input/paper-input';
import { formSlotInjector } from 'helpers/formInjector';

const themeSettings = provider.settings('forgot-password');

@customElement('forgot-password')
@whitelabel({ name: 'forgot-password' })
export class ForgotPassword extends formSlotInjector('form')(
    connect(store)(LitElement),
) {
    @property({ type: String })
    public email: string = '';

    @property({ type: String })
    public backButtonLabel: string = themeSettings?.backButtonLabel
        ? themeSettings.backButtonLabel
        : '';

    @property({ type: String })
    public backButtonText: string = themeSettings?.backButtonText
        ? themeSettings.backButtonText
        : '';

    @property({ type: String })
    public body: string = themeSettings?.body ? themeSettings.body : '';

    @property({ type: String })
    public inputLabel: string = themeSettings?.inputLabel
        ? themeSettings.inputLabel
        : '';

    @property({ type: String })
    public inputPlaceholder: string = themeSettings?.inputPlaceholder
        ? themeSettings.inputPlaceholder
        : '';

    @property({ type: Boolean })
    public showBackButton: boolean = themeSettings?.showBackButton
        ? themeSettings.showBackButton
        : false;

    @property({ type: String })
    public submit: string = themeSettings?.submit
        ? themeSettings.submit
        : 'Versturen';

    @property({ type: String })
    public appTitle: string = themeSettings?.title
        ? themeSettings.title
        : 'Wachtwoord vergeten';

    @whitelabel()
    static get styles() {
        return [_defaults, ironFlex, forgotPasswordStyle];
    }

    public render = (): TemplateResult => {
        return html`
            <div class="layout horizontal center-justified centerParent">
                <div class="Wrapper loginWrapper layout-vertical centerChild">
                    <h3 class="bodyHeader">${this.appTitle}</h3>
                    <p class="bodyContent">${this.body}</p>
                    <slot name="form"></slot>
                    ${this.showBackButton
                        ? html`
                              <div class="backButton">
                                  <div class="backButtonLabel">
                                      ${this.backButtonLabel}
                                  </div>
                                  <div
                                      class="backButtonText"
                                      @click="${this.signIn}"
                                  >
                                      ${this.backButtonText}
                                  </div>
                              </div>
                          `
                        : null}
                </div>
            </div>
        `;
    };

    /**
     * Go to sign in
     */
    private signIn = () => {
        Router.go(routePaths.overview);
    };
}

declare global {
    interface HTMLElementTagNameMap {
        'forgot-password': ForgotPassword;
    }
}
