import axios from 'axios';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
    REQUEST_OFFER,
    requestOfferSuccess,
    requestOfferFailed,
} from '../actions/offer';
import headers from './helpers/headers';

/**
 * Offer fetcher
 * @param {Object} action
 */
function* fetchOffer(action) {
    try {
        const result = yield call(async () => {
            const response = await axios.get(
                `/v1/vendor/${action.vendorId}/productoffering/offers`,
                {
                    baseURL: window.API_LINK,
                    headers: headers(STDHeaders),
                    params: action.params,
                },
            );
            if (response.statusText === 'OK' || response.status === 200) {
                return { data: response.data };
            }
            throw response.status;
        });
        yield put(requestOfferSuccess(result.data));
    } catch (e) {
        yield put(requestOfferFailed(e));
    }
}

/**
 * Watcher for REQUEST_OFFER action
 */
export function* watchRequestOffer() {
    yield takeLatest(REQUEST_OFFER, fetchOffer);
}
