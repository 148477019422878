import { SET_REDIRECT, CLEAR_REDIRECT } from '../actions/redirect';

const defaultRedirect = { to: '', till: '' };

export const redirect = (state = { ...defaultRedirect }, action) => {
    switch (action.type) {
        case SET_REDIRECT:
            return { to: action.to, till: action.till };
        case CLEAR_REDIRECT:
            return { to: action.to, till: action.till };
        default:
            return state;
    }
};
