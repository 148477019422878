import { put, take, race } from 'redux-saga/effects';
/**
 * Wrapper for yield all
 * @param {Object} request
 * @param {Object} resource
 * @return {any}
 */
export function* betterWrapper(request, resource) {
    yield put(request);

    const [f, s] = yield race([
        take(resource.REQUEST_FAILED),
        take(resource.REQUEST_SUCCESS),
    ]);

    return resource.reducer()(resource.reducer()(undefined, request), f || s);
}
