import { ReduxAsync } from '@weavedev/redux-async';
import axios, { AxiosResponse } from 'axios';
import enums from 'enums';
import { daysDiffBetweenDates } from 'helpers/dates';
import {
    calculateMonthlyUsages,
    multipleCostsToUsage,
} from 'helpers/calculateMonthlyValues';
import headers from '../sagas/helpers/headers';

export type Usage = {
    dateFrom: string;
    dateTill: string;
    snapshotID: string;
    intervalType?: number;
};
export interface Usages {
    hasElec: boolean;
    hasGas: boolean;
    hasProd: boolean;
    totalElec: number;
    totalGas: number;
    totalProd: number;
    totalCosts?: number;
    endDate: Date;
    requestObject: Usage;
}

// fetch usages for a snapshot by its type
const getUsage = async (
    usage: Usage,
    usageType: string,
    costs: boolean = false,
): Promise<AxiosResponse> =>
    axios.get(
        `/v1/${costs ? `cost/?calculationDetails=true&` : `usage/?`}dateFrom=${
            usage.dateFrom
        }&dateTill=${usage.dateTill}&intervalType=${
            enums.IntervalTypeHourly
        }&snapshotID=${usage.snapshotID}&usageType=${usageType}`,
        {
            baseURL: window.API_LINK,
            headers: headers(window.STDHeaders),
        },
    );

// TODO: prevent fetching multiple request_usages (only latest)
const REQUEST_USAGES = 'REQUEST_USAGES';
const REQUEST_USAGES_SUCCESS = 'REQUEST_USAGES_SUCCESS';
const REQUEST_USAGES_FAILED = 'REQUEST_USAGES_FAILED';
export const getUsages = new ReduxAsync(
    REQUEST_USAGES,
    REQUEST_USAGES_SUCCESS,
    REQUEST_USAGES_FAILED,
    async (usage: Usage): Promise<Usages> => {
        // prevent fetching usages for inactive snapshots
        const state = window.store.getState();
        const { selectedSnapshot } = state.jwtSnapshots;
        if (
            selectedSnapshot == null ||
            selectedSnapshot.snapshot_phase ===
                enums.SnapshotPhaseDemoContractCreated ||
            selectedSnapshot.snapshot_phase <
                enums.SnapshotPhaseSmartMeterChecked
        ) {
            return {
                hasElec: false,
                hasGas: false,
                hasProd: false,
                totalElec: 0,
                totalGas: 0,
                totalProd: 0,
                requestObject: usage,
            } as Usages;
        }

        // TODO: only fetch the required usages (snapshots.verified_snapshot_payload)
        const usageElec = await getUsage(usage, enums.UsageTypeElekUsage);
        const costElec = await getUsage(usage, enums.UsageTypeElekUsage, true);
        const usageGas = await getUsage(usage, enums.UsageTypeGasUsage);
        const costGas = await getUsage(usage, enums.UsageTypeGasUsage, true);
        const usageProd = await getUsage(usage, enums.UsageTypeElekProd);
        const costProd = await getUsage(usage, enums.UsageTypeElekProd, true);

        const combinedCosts = multipleCostsToUsage(
            costElec.data,
            costGas.data,
            costProd.data,
        );

        let combinedSum: number = 0;
        for (const c of combinedCosts) {
            combinedSum += c.reading;
        }

        // await all above calls to finish
        const response = Promise.all([usageElec, usageGas, usageProd]).then(
            (response: AxiosResponse[]): any => {
                const usageObj: Usages = {
                    hasElec: false,
                    hasGas: false,
                    hasProd: false,
                    requestObject: usage,
                } as Usages;
                response.forEach((resp) => {
                    if (resp.data.length === 0) {
                        return;
                    }
                    const endDate = new Date(
                        resp.data[resp.data.length - 1].reading_date,
                    );
                    if (
                        usageObj.endDate == null ||
                        daysDiffBetweenDates(usageObj.endDate, endDate) > 0
                    ) {
                        usageObj.endDate = endDate;
                    }

                    switch (resp.data[0].usage_type) {
                        case enums.UsageTypeElekUsage:
                            usageObj.hasElec = resp.data.length > 0;
                            usageObj.totalElec = Number(
                                calculateMonthlyUsages(resp.data),
                            );
                            break;
                        case enums.UsageTypeElekProd:
                            usageObj.hasProd = resp.data.length > 0;
                            usageObj.totalProd = Number(
                                calculateMonthlyUsages(resp.data),
                            );
                            break;
                        case enums.UsageTypeGasUsage:
                            usageObj.hasGas = resp.data.length > 0;
                            usageObj.totalGas = Number(
                                calculateMonthlyUsages(resp.data),
                            );
                            break;
                        default:
                            break;
                    }

                    return resp;
                });

                // Set total costs
                usageObj.totalCosts = combinedSum;
                return usageObj;
            },
        );

        return response;
    },
);
