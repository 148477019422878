import { LitElement, property, PropertyValues } from 'lit-element';
import { connect, watch } from 'lit-redux-watch';
import { html, TemplateResult } from 'lit-html';
import { Snapshot } from 'types/snapshot';
import { User } from 'types/user';
import { InvoiceInfo, Pagination } from 'types/documents';
import { downloadDocument, store } from '../../../../store';
import { PAGESIZE } from '../../InvoiceGrid';
import { AllowTab } from '../../../../components/elements/tabs-component/TabsComponent';

export interface DefaultPayload {
    filter?: string;
    order?: string;
    pageNumber: number;
    pageSize: number;
    snapshotId?: string;
    userId?: string;
}

export abstract class BaseTab extends connect(store)(LitElement) {
    @property({ type: Boolean, reflect: true })
    public hidden: boolean = false;

    @property({ type: Boolean, reflect: true })
    public isMobile: boolean = false;

    @property({ type: Array })
    protected items: InvoiceInfo[] = [];

    @property({ type: Object })
    protected paginationState?: Pagination;

    @property({ type: Boolean })
    protected error: boolean = false;

    @property({ type: Boolean })
    protected loading: boolean = false;

    @property({ type: Number })
    protected total: number = 0;

    @property({ type: Boolean })
    protected hideDetailsForMobile: boolean = false;

    @property({ type: Boolean })
    protected hasFetched = false;

    @property({ type: Object })
    protected defaultPayload: DefaultPayload = {
        // (Number)
        pageNumber: 1,
        // (Number)
        pageSize: PAGESIZE,
    } as DefaultPayload;

    @property({ type: Boolean, reflect: true })
    protected active: boolean = false;

    @watch('jwtSnapshots.selectedSnapshot')
    protected snapshot?: Snapshot;

    @watch('jwtSnapshots')
    protected snapshots?: any;

    @watch('user')
    protected user?: User;

    protected updated(changedProperties: PropertyValues): void {
        if (
            // this.active && // this means that it was false but now active
            !this.hasFetched &&
            this.snapshot?.id &&
            this.user?.id
        ) {
            this.fetchGridData({
                pageNumber: 1,
                pageSize: PAGESIZE,
                snapshotId: this.snapshot?.id,
                userId: this.user?.id,
            });
            this.hasFetched = true;
        }
        if (
            changedProperties.has('active') &&
            !this.active &&
            this.hasFetched
        ) {
            // this measn that it was active, but now it inactive
            this.hasFetched = false;
        }
    }

    public readonly updatePaginationData = (pageNumber: number): void => {
        if (this.defaultPayload == null) {
            return;
        }
        if (this.paginationState == null) {
            this.paginationState = {
                offset: 0,
                page: this.defaultPayload.pageNumber,
                total: 0,
            };
        }
        this.paginationState.page = pageNumber;
        this.updatedGridData();
    };

    protected readonly setInvoiceAndPagiantionData = (
        data: InvoiceInfo[],
        pagination: Pagination,
    ): void => {
        this.items = data;
        this.paginationState = pagination;
        // if items is not empty, dispatch event to allow tab render
        dispatchEvent(
            new CustomEvent('allow-tab-render', {
                bubbles: true,
                composed: true,
                detail: {
                    element: this,
                    allow: this.items?.length > 0,
                } as AllowTab,
            }),
        );
    };

    // download button render logic
    protected renderTemplateButton = (
        _: string,
        bd: InvoiceInfo,
    ): TemplateResult => html`
        <style>
            .grid-button {
                max-width: 116px;
                max-height: 32px;
            }
            button-component {
                --button-component--margin: 7px 0 8px;
                --button-component--height: 32px;
                --button-component--font-size: 14px;
            }
        </style>
        <button-component
            class="grid-button"
            label="Download"
            theme="primary"
            @click="${(e: MouseEvent) => this.downloadInvoice(e, bd)}"
        ></button-component>
    `;

    // download pdf logic
    protected readonly downloadInvoice = (
        e: MouseEvent,
        invoice: InvoiceInfo,
    ): void => {
        e.stopImmediatePropagation();
        if (this.user == null) {
            console.warn(`user is null couldn't download the invoice`);
            return;
        }
        try {
            store.dispatch(
                downloadDocument.request({
                    user_id: this.user.id,
                    document_id: invoice.document.id,
                    file: 1,
                    type: invoice.type,
                }),
            );
        } catch (error) {
            console.warn(`Invoice couldn't be downloaded`, error);
            // @ts-ignore
            window.displayMessage(
                'Kan PDF niet downloaden, probeer het later nog eens.',
            );
        }
    };

    // logic that fetches the new grid data for the page
    protected readonly updatedGridData = () => {
        const payload = this.defaultPayload;
        if (this.paginationState) {
            payload.pageNumber = this.paginationState.page;
        }
        if (!this.snapshot?.id || !this.user?.id) {
            console.warn('snapshot or user is null');
            return;
        }
        this.fetchGridData({
            pageNumber: payload.pageNumber,
            pageSize: payload.pageSize,
            userId: this.user?.id,
            snapshotId: this.snapshot?.id,
        });
    };

    /**
     * Used to fetch grid date with default payload.
     */
    // eslint-disable-next-line no-unused-vars
    abstract fetchGridData(payload: DefaultPayload): void;
}
