import { IS_BUSY, NOT_BUSY } from '../actions/busy';

/**
 * State reducer for busy
 * @param {String?} state
 * @param {Object} action
 * @return {String?}
 */
export const busy = (state = null, action) => {
    switch (action.type) {
        case IS_BUSY:
            return action.message === undefined ? 'Bezig...' : action.message;
        case NOT_BUSY:
            return null;
        default:
            return state;
    }
};
