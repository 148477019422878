
import enums from 'enums';
import { Location, Snapshot } from 'types/snapshot';
import { isSmartCharging } from './smartCharging';

export const snapshotsPhaseAllowedFrom = 6;

// checks if snapshot map has 1 or more snapshots
export const hasSnapshots = (
    snapshots: Map<string, Snapshot> | Snapshot[],
): boolean => {
    if (Array.isArray(snapshots)) {
        return snapshots && snapshots.length >= 0;
    }
    return snapshots && snapshots.size >= 1;
};
// checks if snapshot map has one snapshot
export const hasOneSnapshot = (
    snapshots: Map<string, Snapshot> | Snapshot[],
): boolean => {
    if (Array.isArray(snapshots)) {
        return snapshots && snapshots.length <= 1;
    }
    return snapshots && snapshots.size <= 1;
};

// Get the snapshot form snapshot ID
export const getSnapshotByID = (
    snapshots: Snapshot[],
    snapshotID: string,
): Snapshot | undefined =>
    snapshots.find((snapshot) => snapshot.id === snapshotID);

/**
 * @param {object} snapshot
 * @return {string}
 * @private
 */
export const contractPrefix = (snapshot: Snapshot): string => {
    if (!snapshot || !snapshot.verified_snapshot_payload) {
        return 'onbekend';
    }

    const payload = snapshot.verified_snapshot_payload;
    const dateNow = new Date();
    const startDate =
        payload.start_date !== undefined && payload.start_date !== ''
            ? new Date(payload.start_date)
            : undefined;
    const endDate =
        payload.end_date !== undefined && payload.end_date !== ''
            ? new Date(payload.end_date)
            : undefined;
    const deliveryStoppedDate =
        payload.delivery_stopped_date != null &&
        payload.delivery_stopped_date !== ''
            ? new Date(payload.delivery_stopped_date)
            : undefined;

    if (
        (endDate && endDate < dateNow) ||
        (deliveryStoppedDate && deliveryStoppedDate < dateNow)
    ) {
        return 'beeindigd';
    }

    if (deliveryStoppedDate) {
        return 'aflopend';
    }

    if (startDate && startDate < dateNow) {
        return 'lopend';
    }

    if (startDate && endDate && startDate > endDate) {
        return 'aangemaakt';
    }

    return 'In behandeling';
};

export const formatAddress = (address: Location): string | void => {
    if (address == null) {
        return;
    }
    let newAddress = address.street_name;
    if (newAddress.length > 24) {
        // returns the substring of the street with the house number and the area code.
        newAddress = `${address.street_name.substring(0, 24)}...`;
    }

    newAddress += ` ${address.house_number}`;
    if (address.house_addition) {
        newAddress += ` ${address.house_addition}`;
    }

    return newAddress;
};

// Checks in the current user snapshots of there is a snapshot that matches the delivery address
// used in the offer payload.
export const getSnapshotsForRetention = (
    snapshots: Snapshot[],
    orderLocation: Location,
): Snapshot[] => {
    // Check user state if a retention contract onboarding is going on
    const retentionSnapshots: Snapshot[] = [];

    for (const snapshot of snapshots) {
        if (
            snapshot.snapshot_phase >= enums.SnapshotPhaseFirstInvoiceSend &&
            snapshot.snapshot_phase <= enums.SnapshotPhaseDeliveryStarted &&
            snapshot.delivery_location.area_code?.toLocaleUpperCase() ===
                orderLocation.area_code?.toLocaleUpperCase() &&
            snapshot.delivery_location.house_number ===
                orderLocation.house_number &&
            (snapshot.delivery_location.house_addition?.toLocaleUpperCase() ===
                orderLocation.house_addition?.toLocaleUpperCase() ||
                (!snapshot.delivery_location.house_addition?.toLocaleUpperCase() &&
                    !orderLocation.house_addition?.toLocaleUpperCase()))
        ) {
            retentionSnapshots.push(snapshot);
        }
    }

    return retentionSnapshots;
};

// soonToBeDelivered snapshot are snapshots that received a invoice and when paid are going to be delivered
export const soonOrAlreadyDelivering = (snapshot: Snapshot) =>
    snapshot.snapshot_phase >= enums.SnapshotPhaseFirstInvoiceSend &&
    snapshot.snapshot_phase <= enums.SnapshotPhaseDeliveryStarted;

// Returns if the snapshot could be listed for example in the dropdown menu to select contract/snapshots.
// For example the smart charging contract should not be selected as selectedSnapshot
export const snapshotAllowedToBelisted = (snapshot: Snapshot) =>
    !isSmartCharging(snapshot);

export const isOdaCreated = (snapshot: Snapshot) =>
    snapshot.snapshot_phase === enums.SnapshotPhaseDemoContractCreated;

export const isOda = (snapshot: Snapshot) =>
    snapshot.snapshot_phase >= enums.SnapshotPhaseDemoContractCreated &&
    snapshot.snapshot_phase <= enums.SnapshotPhaseInsightContractStopped;

// Checks wether a contract type is flex
export const isFlex = (snapshot: Snapshot): boolean =>
    snapshot.contract_duration === enums.ProductDurationMonth;

export const latestAllowedPhaseSnapshots = (
    snapshots?: Snapshot[],
    selectedSnapshot?: Snapshot | null,
): Snapshot[] => {
    if (snapshots == null) {
        return [];
    }

    const id: string | undefined = selectedSnapshot?.delivery_location?.id;
    let res: Snapshot[] = [];
    // create a snapshot map with key: stopDate and value: snapshot
    snapshots.forEach((snapshot) => {
        if (
            Number(snapshot.snapshot_phase) > snapshotsPhaseAllowedFrom &&
            snapshot.snapshot_phase !== enums.SnapshotPhaseNeverDelivered
        ) {
            if (snapshot.delivery_location.id === id || id == null) {
                res.push(snapshot);
            }
        }
    });

    res = res.sort((a: Snapshot, b: Snapshot) => {
        const aPayload = a.verified_snapshot_payload;
        const aStart: Date = new Date(aPayload.start_date);

        const bPayload = b.verified_snapshot_payload;
        const bStart: Date = new Date(bPayload.start_date);

        const aRes: number = aStart.getTime();
        const bRes: number = bStart.getTime();
        if (aRes > bRes) {
            return -1;
        }
        return 1;
    });
    return res;
};

export const snapshotHasGas = (snapshot: Snapshot): boolean =>
    !!snapshot?.verified_snapshot_payload?.has_gas;

export const snapshotHasElec = (snapshot: Snapshot): boolean =>
    !!snapshot?.verified_snapshot_payload?.has_elec;
