import { CSSResult } from 'lit-element';
import { whitelabel } from '@weavelab/whitelabel';

@whitelabel({ name: '_defaults' })
class DefaultTemplate {
    @whitelabel()
    public static get styles(): CSSResult[] {
        return [];
    }
}

export const _defaults: CSSResult = DefaultTemplate.styles[0];
