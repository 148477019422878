import { deleteAllNotifications } from '@actions/notification';
import { vendorConfig } from '@async-reducers/vendor';
import '@polymer/paper-checkbox/paper-checkbox';
import { html, PolymerElement } from '@polymer/polymer/polymer-element';
import { Router } from '@vaadin/router';
import { provider } from '@weavelab/frontend-connect';

// @ts-ignore
import watch from 'redux-watch';
import enums from 'enums';
import 'atoms/RadioButtonGroup';
import '@molecules/OrderMenu';
import '../../../elements/modal/ModalComponent';
import '../../../general/ez-dropdown-menu/dropdown-menu';
import '../general/productgroup-overview-display/productgroup-overview-display';
import './components/opt-out-buttons/OptOutButtons';
import { routePaths } from 'helpers/routing';
import { getSnapshotsForRetention } from 'helpers/snapshot';
import { receiveSelf } from '@async-reducers/authentication';
import { userAgreesSnapshot } from '@async-reducers/snapshot';
import '@organism/MultipleEanModal';

import settings from 'internationalization/settings';
import {
    uiAutomaticIncassoClicked,
    uiOrderOverviewInteraction,
    uiProductListInteraction,
} from 'actions/ui';
import {
    changeOrder,
    readTermsAndConditions,
    conditionsChanged,
    uiProceedToMoClickedDataLayer,
    purchaseEventConfirmation,
} from 'gtm-middleware/orderOverview';
import { setPurchase } from '@async-reducers/purchase';
import css from './order-overview.pcss';
import template from './order-overview.html';

import { formatDate } from '../../../../globals';
import { store } from '../../../../store';
import { isBusy, notBusy } from '../../../../actions/busy';
import {
    showLoginPrompt,
    hideLoginPrompt,
} from '../../../../actions/loginPrompt';
import { setSelectedSnapshot } from '../../../../actions/snapshot';
import { resetCreateCompany } from '../../../../actions/company';
import { resetCreateUserPaymentAccount } from '../../../../actions/userPaymentAccount';
import { NumberToPrice, parseVatFloat, roundNumber } from '../helpers/number';
import './components/no-smart-meter/NoSmartMeter';
import {
    getMeteringPoints,
    hasConventionalMeter,
    sendMeteringPointsReminderMail,
    setMeteringPoints,
} from '@async-reducers/meteringPoint';
import { returnErrorOrEmptyString } from 'gtm-middleware/helpers/actionValue';
import '@components/molecules/HvLoaderModal/HvLoaderModal';
import { EanUsageType } from 'atoms/EANInput';

const themeSettings = provider.settings('order-overview');
const appSettings = provider.settings('app');
const termsErrorMessage = 'Je hebt de voorwaarden nog niet geaccepteerd';
const termsAndPrivacyErrorMessage =
    'Om je energiecontract af te ronden moet je akkoord gaan met onze (algemene) voorwaarden en privacybeleid.';

/**
 * Login class
 */
export default class EzOrderOverview extends PolymerElement {
    /** @typedef {import('../../../../actions/user').User} User */

    /**
     * Gets properties of class
     */
    static get properties() {
        return {
            id: {
                type: String,
            },
            user: {
                type: Object,
                value: {},
                observer: '_userChanged',
            },
            snapshot: {
                type: Object,
                value: {},
            },
            disableCustomerForm: {
                type: Boolean,
                value: false,
            },
            hideChoice: {
                type: Boolean,
                value: true,
            },
            termsChecked: {
                type: Boolean,
                value: false,
            },
            vendorDataChecked: {
                type: Boolean,
                value: false,
            },
            smartMeterChecked: {
                type: Boolean,
                value: false,
            },
            secSubRoute: {
                type: String,
                observer: '_userChanged',
            },
            subRoute: {
                type: String,
                observer: '_userChanged',
            },
            startDateCheckboxValue: {
                type: Boolean,
                value: false,
            },
            menuSelectedPostAgree: {
                type: Number,
            },
            menuSelectedPreAgree: {
                type: Number,
            },
            minStartDays: {
                type: Number,
                value: 30,
            },
            retentionSnapshot: {
                type: Object,
            },
            paymentDetails: {
                type: Object,
                value: null,
            },
            confirmOrder: {
                type: Boolean,
                value: false,
                observer: '_checkIfConfirmedToAddCampaign',
            },
            hasConventionalMeter: {
                type: Boolean,
                value: false,
            },
            termsAndPrivacyTitle: {
                type: String,
            },
            compactTerms: {
                type: Boolean,
                value: false,
            },
            vendorName: {
                type: String,
                value: '',
            },
        };
    }

    /**
     * Constructor of reset password
     */
    constructor() {
        super();
        this.asset = provider.asset;
        /** @type {User} */
        this.user;
        /** @type {String} */
        this.subRoute;
        /** @type {String} */
        this.secSubRoute;
        /** @type {Boolean} */
        this.termsChecked;
        /** @type {Boolean} */
        this.vendorDataChecked;
        /** @type {Boolean} */
        this.startDateCheckboxValue;
        /** @type {Number} */
        this.menuSelectedPostAgree;
        /** @type {Number} */
        this.minStartDays;
        // @ts-ignore
        this.menuSelectedPostAgree =
            themeSettings && !Number.isNaN(themeSettings.menuSelectedPostAgree)
                ? themeSettings.menuSelectedPostAgree
                : 1;
        /** @type {Number} */
        this.menuSelectedPreAgree;
        // @ts-ignore
        this.menuSelectedPreAgree =
            themeSettings && !Number.isNaN(themeSettings.menuSelectedPreAgree)
                ? themeSettings.menuSelectedPreAgree
                : 1;
        /** @type {String} */
        this.termsOfServiceURL = settings.termsOfServiceURL;
        /** @type {String} */
        this.themePreAgreeTitle =
            themeSettings && themeSettings.preAgreeTitle
                ? themeSettings.preAgreeTitle
                : '';
        /** @type {String} */
        this.themePostAgreeTitle =
            themeSettings && themeSettings.postAgreeTitle
                ? themeSettings.postAgreeTitle
                : '';
        /** @type {String} */
        this.vendorPhoneNumber = settings.phoneNumber;
        /** @type {Boolean} */
        this.showSecondaryTitle = themeSettings
            ? !themeSettings.hideSecondaryTitle
            : false;
        // Colletive
        /** @type {Array} */
        this.collectives;
        /** @type {Object} */
        this.selectedCollective;
        /** @type {Object} */
        this.paymentDetails;
        /** @type {Boolean} */
        this.vendorCheckBoxActive = !!(
            themeSettings &&
            themeSettings.vendorCheckBoxActive &&
            themeSettings.vendorCheckBoxActive === true
        );
        /** @type {String} */
        this.vendorCheckBoxActiveText =
            themeSettings &&
            themeSettings.vendorCheckBoxActiveText &&
            themeSettings.vendorCheckBoxActiveText !== ''
                ? themeSettings.vendorCheckBoxActiveText
                : `Ja, ik geef toestemming dat Mijndomein Energie inzicht heeft in mijn klantgegevens en 
            deze gebruikt om jou te informeren over de afgesproken dienstverlening.`;
        this.vendorPixel =
            themeSettings && themeSettings.vendorPixel
                ? themeSettings.vendorPixel
                : null;
        /** @type {Array} */
        this.vendorPixelUrl =
            themeSettings && themeSettings.vendorPixelUrl
                ? themeSettings.vendorPixelUrl
                : null;
        this.readyOrNot = false;

        this.collectives = [];
        this.order = {};
        this.hasConventionalMeter = false;
        this.termsAndPrivacyTitle =
            themeSettings.vendorTermsAndPrivacyTitle ||
            'Privacy en voorwaarden';
        this.compactTerms = themeSettings.compactTerms || false;

        /**
         * Disconnect watcher
         */
        /** @type {Function} */
        this.vendorConfigWatcher;
        /** @type {Function} */
        this.snapshotWatcher;
        /** @type {Function} */
        this.conventialMeterWatcher;
        /** @type {Function} */
        this.orderWatcher;
        /** @type {Function} */
        this.selectedSnapshotWatcher;
        /** @type {Function} */
        this.createSnapshotWatcher;
        /** @type {Function} */
        this.userWatcher;
        /** @type {Function} */
        this.collectivesWatcher;
        /** @type {Function} */
        this.createUserPaymentAccountWatcher;

        this.eanUsageType = EanUsageType.ELEC;
        this.meteringPoints = [];
        this.incassoInformationURL = themeSettings?.incassoInformationURL
            ? themeSettings.incassoInformationURL
            : null;
    }

    /**
     * checks if scripts needs to be added
     * @param {Boolean} confirmOrder
     */
    _checkIfConfirmedToAddCampaign(confirmOrder) {
        if (
            this.vendorPixel == null ||
            this.vendorPixelUrl == null ||
            this.vendorPixelUrl.includes(window.APP_URL) ||
            !confirmOrder
        ) {
            return;
        }
        // create tracking campagne for vendor
        const campaignID = '31039';
        let { vendorPixel } = this;
        // add tracking campagne for vendor
        const ttConversionOptions = {
            type: 'sales',
            campaignID,
            productID: '47504',
            transactionID: this.snapshot?.id
                ? this.snapshot.id
                : 'Snapshot id is niet bekend',
            transactionAmount: this.snapshot?.calculated_product_group_user
                ?.monthly_pricing_excl
                ? `Maandelijks €${this.snapshot?.calculated_product_group_user?.monthly_pricing_excl} excl btw.`
                : 'Maandelijkse prijs is niet bekend.',
            quantity: '1',
            descrMerchant: '',
            descrAffiliate: '',
            vc: '',
            currency: 'EUR',
        };

        // replace pixel variablen when needed
        vendorPixel = vendorPixel.replace(
            'ORDER_ID',
            encodeURIComponent(ttConversionOptions.transactionID),
        );
        vendorPixel = vendorPixel.replace(
            'ORDER_AMOUNT',
            encodeURIComponent(ttConversionOptions.transactionAmount),
        );

        let s = document.getElementsByTagName('script');
        s = s[s.length - 1];
        // addes the updated vendopPixel to an image element
        const img = document.createElement('img');
        img.src = vendorPixel;
        s.parentNode.appendChild(img);

        // add ttConversionOptions to the global variables required for the external script below.
        window.ttConversionOptions = ttConversionOptions;

        // add tracker to the html body.
        const tt = document.createElement('script');
        tt.type = 'text/javascript';
        tt.async = true;
        tt.src = `//tm.tradetracker.net/conversion?s=${encodeURIComponent(
            campaignID,
        )}&t=m`;
        s.parentNode.insertBefore(tt, s);
    }

    /**
     * _hasDifferences
     * @param {boolean} bool
     * @param {boolean} bool2
     * @return {boolean}
     */
    _hasDifferences(bool, bool2) {
        return bool || bool2;
    }

    /**
     * connectedCallback when element attaches to DOM
     */
    connectedCallback() {
        super.connectedCallback();

        const snapshotID = this.location.params.id;

        /** @type {Object} */
        const state = store.getState();

        store.dispatch(vendorConfig.run(window.env.vendorId));
        this.vendorConfigWatcher = watch(store.getState, 'vendorConfig');
        store.subscribe(
            this.vendorConfigWatcher(
                /**
                 * @param {Object} vendor
                 */
                (vendor) => {
                    if (vendor.data) {
                        if (vendor.data.min_waiting_days) {
                            this.minStartDays = vendor.data.min_waiting_days;
                        }
                    }
                },
            ),
        );

        this.busyWatcher = watch(store.getState, 'busy');
        store.subscribe(
            this.busyWatcher((reason) => {
                this.busy = !!reason;
            }),
        );

        this.goToNextPhase = watch(store.getState, 'goToNextPhase');
        store.subscribe(
            this.goToNextPhase(({ data }) => {
                if (
                    !data ||
                    data?.snapshot_phase <= enums.SnapshotPhaseUserAgreement
                ) {
                    return;
                }
                store.dispatch(notBusy());
                this.snapshot = data;
                const amountOfMeteringPoints = this.shadowRoot.querySelector(
                    '#amountOfMeteringPoints',
                );
                if (!amountOfMeteringPoints) {
                    console.warn('Amount of metering points modal is missing');
                    return;
                }
                amountOfMeteringPoints.close();
            }),
        );

        this.getMeteringPoints = watch(store.getState, 'getMeteringPoints');
        store.subscribe(
            this.getMeteringPoints(({ data }) => {
                this.meteringPoints = data?.data?.snapshot?.meteringPoints;
                store.dispatch(notBusy());
            }),
        );

        this.snapshotWatcher = watch(store.getState, 'jwtSnapshots.snapshots');
        store.subscribe(
            this.snapshotWatcher(
                /**
                 * @param {Object} snapshots
                 * @return {void}
                 */
                (snapshots) => {
                    const otherSnapshots = [];
                    for (const snapshot of snapshots) {
                        if (snapshot.id === snapshotID) {
                            this.snapshot = snapshot;
                        } else {
                            otherSnapshots.push(snapshot);
                        }
                    }

                    if (this.snapshot && otherSnapshots) {
                        const retentionSnapshots = getSnapshotsForRetention(
                            otherSnapshots,
                            this.snapshot.delivery_location,
                        );
                        this.retentionSnapshot = retentionSnapshots[0];
                        if (
                            retentionSnapshots.length === 1 &&
                            this.snapshot.snapshot_phase === 1
                        ) {
                            this.openRetentionModal();

                            this.disableCustomerForm = true;
                            this.hideChoice = false;
                            return;
                        }
                    }

                    this.disableCustomerForm = false;
                    this.hideChoice = true;

                    if (this._showStateConfirm(this.snapshot)) {
                        window.store.dispatch(
                            hasConventionalMeter.run(this.snapshot.id),
                        );
                    }
                },
            ),
        );

        this.selectedSnapshotWatcher = watch(
            store.getState,
            'jwtSnapshots.agreedSnapshot',
        );
        store.subscribe(
            this.selectedSnapshotWatcher(
                /**
                 * @param {Object} snapshots
                 * @return {void}
                 */
                (agreedSnapshot) => {
                    this.set('snapshot', agreedSnapshot);
                    this._snapShotChanged();
                },
            ),
        );

        this.createSnapshotWatcher = watch(
            store.getState,
            'createUserSnapshot.*',
        );
        store.subscribe(
            this.createSnapshotWatcher(
                /**
                 * @param {Object} snapshot
                 * @return {void}
                 */
                (snapshot) => {
                    if (
                        this.snapshot === undefined ||
                        this.snapshot === null ||
                        this.snapshot.id !== snapshot.id
                    ) {
                        this.snapshot = snapshot;
                    }
                },
            ),
        );

        this.user = state.user;
        this.userWatcher = watch(store.getState, 'user');
        store.subscribe(
            this.userWatcher(
                /**
                 * @param {Object} user
                 * @return {void}
                 */
                (user) => {
                    this.user = user;
                },
            ),
        );

        this.vendorName = appSettings.appDomain;
    }

    openRetentionModal() {
        const retentionSupport =
            this.shadowRoot.querySelector('#retention_support');
        if (!retentionSupport) {
            console.warn(`couldn't find the retention support modal`);
            return;
        }

        retentionSupport.open();
    }

    openSupportModal() {
        const supportModal = this.shadowRoot.querySelector('#contact__support');
        if (!supportModal) {
            console.warn(`couldn't find the support modal`);
            return;
        }

        supportModal.open();
    }

    /**
     * called before routing
     */
    onBeforeEnter() {
        if (!this.readyOrNot) {
            this.ready();
        }
    }

    /**
     * On element ready
     */
    ready() {
        super.ready();
        window.store.dispatch(receiveSelf.run());
        this.readyOrNot = true;

        /** @type {Object} */
        const state = store.getState();

        this.collectives =
            state.collectives && Array.isArray(state.collectives.data)
                ? state.collectives.data
                : [];
        this.collectivesWatcher = watch(store.getState, 'collectives.data');
        store.subscribe(
            this.collectivesWatcher(
                /**
                 * @param {Object} collectives
                 * @return {void}
                 */
                (collectives) => {
                    this.collectives = Array.isArray(collectives)
                        ? collectives
                        : [];
                },
            ),
        );

        this.conventialMeterWatcher = watch(
            store.getState,
            'hasConventionalMeter',
        );
        store.subscribe(
            this.conventialMeterWatcher(({ data }) => {
                // gql wraps its data also in a data object, like redux. So you have 2 data objects in eachother
                if (data && data.data && data.data.HasConventionalMeter) {
                    this.hasConventionalMeter = data.data.HasConventionalMeter;
                }
            }),
        );

        this.orderWatcher = watch(store.getState, 'order');
        store.subscribe(
            this.orderWatcher(
                /**
                 * @param {Object} order
                 * @return {void}
                 */
                (order) => {
                    this.order = { ...order };
                    if (
                        this.order.collective &&
                        this.collectives.filter(
                            (c) => c && c.id === this.order.collective,
                        ).length > 0
                    ) {
                        this.selectedCollective = this.collectives.find(
                            (c) => c && c.id === this.order.collective,
                        );
                    } else {
                        this.selectedCollective = null;
                    }
                },
            ),
        );

        this.createUserPaymentAccountWatcher = watch(
            store.getState,
            'createUserPaymentAccount',
        );
        store.subscribe(
            this.createUserPaymentAccountWatcher(
                /**
                 * @param {Object} payment
                 * @return {void}
                 */
                (payment) => {
                    if (payment && payment.query && payment.query.metadata) {
                        const meta = JSON.parse(payment.query.metadata);
                        this.paymentDetails = meta;
                    }
                },
            ),
        );
        setTimeout(() => {
            this.readyOrNot = false;
        }, 5000);

        store.dispatch(vendorConfig.run(window.env.vendorId));
    }

    disconnectedCallback() {
        store.dispatch(deleteAllNotifications());

        // unsubscribe the store watchers
        this.vendorConfigWatcher();
        this.snapshotWatcher();
        this.selectedSnapshotWatcher();
        this.createSnapshotWatcher();
        this.userWatcher();
        this.collectivesWatcher();
        this.createUserPaymentAccountWatcher();

        super.disconnectedCallback();
    }

    /**
     * Fetch every 30 seconds for 5 minutes
     */
    _fetchFiveMins() {
        let count = 10;
        const f = () => {
            this.retrieveCurrentSnapshot(true);
            count--;
            if (count > 0) {
                setTimeout(f, 30000);
            }
        };
        f();
    }

    /**
     * Get observers
     */
    static get observers() {
        return ['_snapShotChanged(snapshot.*)'];
    }

    /**
     * Route user back to customer form
     */
    _changeData() {
        store.dispatch(uiProductListInteraction.run(changeOrder('wijzigen')));
        store.dispatch(resetCreateCompany());
        store.dispatch(resetCreateUserPaymentAccount());
        Router.go(routePaths.customerForm);
    }

    /**
     * Should show snapshot confirm screen
     * @param {Object?} snapshot
     * @return {Boolean}
     */
    _showStateConfirm(snapshot) {
        if (!snapshot) {
            return false;
        }

        const on = [
            enums.SnapshotPhaseUsageChecked,
            enums.SnapshotPhaseSmartMeterFound,
            enums.SnapshotPhaseFirstInvoiceSend,
            enums.SnapshotPhaseFirstPaymentDone,
            enums.SnapshotPhaseDeliveryPlanned,
            enums.SnapshotPhaseDeliveryStarted,
        ];
        this.confirmOrder =
            snapshot &&
            !Number.isNaN(snapshot.snapshot_phase) &&
            on.indexOf(parseInt(snapshot.snapshot_phase, 10)) >= 0;
        if (this.confirmOrder) {
            Router.go(
                `${routePaths.customer + routePaths.customerSubOverview}/${
                    this.location.params.id
                }${routePaths.customerSubSubSuccess}`,
            );
        }
        return this.confirmOrder;
    }

    /**
     * Should NOT show snapshot confirm screen
     * @param {Object?} snapshot
     * @return {Boolean}
     */
    _showStateNotConfirm(snapshot) {
        return !this._showStateConfirm(snapshot);
    }

    /**
     * Show show smart meter buy option
     * @param {Object?} snapshot
     * @return {Boolean}
     */
    _showBuySmartMeter(snapshot) {
        const on = [
            enums.SmartMeterChoiceTypeIDoNotHaveASmartmeterAndWantToOrderOne,
        ];
        const notOn = [
            enums.SnapshotPhaseSmartMeterFound,
            enums.SnapshotPhaseFirstInvoiceSend,
            enums.SnapshotPhaseFirstPaymentDone,
        ];
        return (
            snapshot &&
            Array.isArray(snapshot.required_actions) &&
            snapshot.required_actions.find(
                /**
                 * @param {Object} o
                 * @return {Boolean}
                 */
                (o) => Number(o.action_type) === 2,
            ) &&
            !isNaN(snapshot.smart_meter_choice_type) &&
            !isNaN(snapshot.snapshot_phase) &&
            on.indexOf(parseInt(snapshot.smart_meter_choice_type)) >= 0 &&
            notOn.indexOf(parseInt(snapshot.snapshot_phase)) < 0
        );
    }

    /**
     * Open popup to buy smart meter page
     */
    _buySmartMeter() {
        if (this.snapshot && Array.isArray(this.snapshot.required_actions)) {
            window.open(
                this.snapshot.required_actions.find(
                    /**
                     * @param {Object} o
                     * @return {Boolean}
                     */
                    (o) => Number(o.action_type) === 2,
                ).href,
                '_blank',
            );
            this._fetchFiveMins();
        }
    }

    /**
     * Show authorize payment account option
     * @param {Object?} snapshot
     * @return {Boolean}
     */
    _showAuthorizePayment(snapshot) {
        const on = [enums.PaymentMethodWithPaymentAccount];
        return (
            snapshot &&
            !snapshot.has_mandate &&
            Array.isArray(snapshot.required_actions) &&
            snapshot.required_actions.find(
                /**
                 * @param {Object} o
                 * @return {Boolean}
                 */
                (o) => Number(o.action_type) === 1,
            ) &&
            snapshot.paymentaccount &&
            snapshot.paymentaccount.id &&
            !isNaN(snapshot.payment_method) &&
            on.indexOf(parseInt(snapshot.payment_method)) >= 0
        );
    }

    /**
     * Action handler for go to app
     */
    _toApp() {
        store.dispatch(
            uiOrderOverviewInteraction.run(uiProceedToMoClickedDataLayer()),
        );
        store.dispatch(setSelectedSnapshot(this.snapshot));
        window.location.href = '/';
    }

    /**
     * Check if we should not allow user to go to app
     * @param {Object} snapshot
     * @return {Boolean}
     */
    _notAllowToApp(snapshot) {
        return (
            this._showAuthorizePayment(snapshot) ||
            this._showBuySmartMeter(snapshot)
        );
    }

    /**
     * Class setter for check if we should not allow user to go to app
     * @param {Object} snapshot
     * @return {String}
     */
    _classNotAllowToApp(snapshot) {
        return this._notAllowToApp(snapshot) ? ' grey' : '';
    }

    /**
     * Open popup to authorize payment page
     */
    _authorizePayment() {
        store.dispatch(uiAutomaticIncassoClicked.run());
        if (this.snapshot && Array.isArray(this.snapshot.required_actions)) {
            window.open(
                this.snapshot.required_actions.find(
                    /**
                     * @param {Object} o
                     * @return {Boolean}
                     */
                    (o) => Number(o.action_type) === 1,
                ).href,
                '_blank',
            );
            this._fetchFiveMins();
        }
    }

    /**
     * Get the user's email adres
     * @param {Object} user
     * @return {String}
     */
    _userEmail(user) {
        return user && user.email ? user.email : 'je e-mailadres';
    }

    /**
     * Get the final start date
     * @param {Object} snapshot
     * @return {String}
     */
    _finalStartDate(snapshot) {
        const date =
            snapshot &&
            snapshot.verified_snapshot_payload &&
            snapshot.verified_snapshot_payload.start_date
                ? new Date(snapshot.verified_snapshot_payload.start_date)
                : null;
        return date
            ? `${date.getDate()} ${
                  [
                      'januari',
                      'februari',
                      'maart',
                      'april',
                      'mei',
                      'juni',
                      'juli',
                      'augustus',
                      'september',
                      'oktober',
                      'november',
                      'december',
                  ][date.getMonth()]
              } ${date.getFullYear()}`
            : ' (zie e-mail).';
    }

    /**
     * Checks if user agrees with two checkboxes
     * @return {boolean}
     */
    _termschecked() {
        if (this.vendorCheckBoxActive) {
            return (
                this.termsChecked &&
                this.smartMeterChecked &&
                this.vendorDataChecked
            );
        }
        if (this.compactTerms) {
            return this.termsChecked;
        }
        return this.termsChecked && this.smartMeterChecked;
    }

    /**
     * Called when snapshot changed
     */
    _snapShotChanged() {
        if (!this.snapshot) {
            // accept contract button has been clicked
            return;
        }
        if (
            Number(this.snapshot.snapshot_phase) >=
            enums.SnapshotPhaseUserAgreement
        ) {
            this.smartMeterChecked = true;
            this.termsChecked = true;
            this.vendorDataChecked = true;
        } else if (
            !this._termschecked() &&
            Number(this.snapshot.snapshot_phase) <
                enums.SnapshotPhaseUserAgreement
        ) {
            // the snapshots terms has not been checked
            return;
        }

        switch (Number(this.snapshot.error_type)) {
            case enums.SnapshotErrorTypeNoError:
                if (
                    Number(this.snapshot.snapshot_phase) <
                    enums.SnapshotPhaseUserAgreement
                ) {
                    this._agreeContract();
                }
                break;
            case enums.SnapshotErrorTypeUserShouldAgree:
                this._agreeContract();
                break;
            case enums.SnapshotErrorTypeMultipleMeteringPointsFound:
            case enums.SnapshotErrorTypeProductInProductGroupCheckFailed:
            case enums.SnapshotErrorTypeVerificationRetrievalFailed:
                window.store.dispatch(
                    getMeteringPoints.run(
                        this.snapshot.id,
                        this.snapshot.vendor_id,
                    ),
                );
                const meteringPoints = this.shadowRoot.querySelector(
                    '#amountOfMeteringPoints',
                );
                if (meteringPoints) {
                    meteringPoints.open();
                    break;
                }

                console.warn('Multiple metering points modal missing');
                break;
            case enums.SnapshotErrorTypeStartDateHalfYearLater:
            case enums.SnapshotErrorTypeUserStartDateBeforeVerifiedStartDate:
            case enums.SnapshotErrorTypeStartDateBeforeTwoWeeksCreation:
                const startDate = this.shadowRoot.querySelector('#startDate');
                if (startDate) {
                    startDate.open();
                    break;
                }

                console.warn('Start date modal missing');
                break;
            case enums.SnapshotErrorTypeUsageOrCaptarIncorrect:
                const usageCheck = this.shadowRoot.querySelector('#usageCheck');
                if (usageCheck) {
                    usageCheck.open();
                    break;
                }
                this.shadowRoot.querySelector('#usageCheck').open();
                console.warn('Smart meter check modal missing');
                break;
            case enums.SnapshotErrorTypeDuplicateActiveFlexSnapshot:
                window.displayMessage(
                    'Er is al een nieuw contact aangevraagd voor dit adres. Neem eventueel contact op met onze klantenservice.',
                );
                break;
            default:
                console.warn('unhandled snapshot case');
        }
        if (this.snapshot.snapshot_phase === 12 && this.disableCustomerForm) {
            window.store.dispatch(receiveSelf.run());
        }
    }

    /**
     * Retrieve snapshot on user changed
     */
    _userChanged() {
        if (!this.user.id || !this.secSubRoute) {
            if (this.secSubRoute && this.subRoute === 'overview') {
                store.dispatch(
                    showLoginPrompt(
                        'Log in om de energie aanvraag te bekijken.',
                    ),
                );
            }
            return;
        }
        store.dispatch(hideLoginPrompt());
        this.retrieveCurrentSnapshot();
    }

    /**
     * Get order type
     * @param {Number} type type of order
     * @return {String} stringified type
     */
    _getOrderType(type) {
        if (Number(type) === 1) {
            return 'Privé';
        }
        return 'Zakelijk';
    }

    /**
     * Get transfer type
     * @param {Number} type type of transfer
     * @return {String} stringified type
     */
    _getTransferType(type) {
        if (Number(type) === 1) {
            return 'Ja, het betreft een overstap';
        }
        return 'Nee, het betreft een verhuizing';
    }

    /**
     * Formats birth date
     * @param {Date} birthDate date of birth
     * @return {String} stringified date
     */
    _formatBirthDate(birthDate) {
        return formatDate(birthDate);
    }

    /**
     * Check if is business
     * @param {Number} type type of order
     * @return {Boolean} indicating if is business
     */
    _isBusinessSnapshot(type) {
        if (Number(type) === 1) {
            return false;
        }
        return true;
    }

    /**
     * Get start date of snapshot
     * @param {Object} snapshot the current snapshot
     * @return {String} stringified start date
     */
    _getCurrentDate() {
        const startDate = window.store.getState().setStartDate.data;
        if (startDate) {
            return startDate;
        }
        console.error('Start date has not been set');
        return 'Zo snel mogelijk';
    }

    /**
     * Get start date of snapshot
     * @param {Date} date the current snapshot
     * @return {String} stringified start date
     */
    _getStartDate(date) {
        return formatDate(date);
    }

    /**
     * Check if living space
     * @param {Boolean} taxCreditRights indiciation if has tax credit rights
     * @return {String} stringified living space
     */
    _isLivingSpace(taxCreditRights) {
        if (taxCreditRights) {
            return 'Ja';
        }
        return 'Nee';
    }

    /**
     * Get elec usage
     * @param {Object} snapshot the current snapshot
     * @return {String} stringified elec usage
     */
    _getElecUsage(snapshot) {
        let elecUsage =
            snapshot &&
            snapshot.user_snapshot_payload &&
            snapshot.user_snapshot_payload.elec_usage_amount
                ? snapshot.user_snapshot_payload.elec_usage_amount
                : null;
        if (
            snapshot &&
            snapshot.snapshot_phase &&
            Number(snapshot.snapshot_phase) >= 4
        ) {
            elecUsage = snapshot.verified_snapshot_payload.elec_usage_amount;
        }
        return `${elecUsage} kWh`;
    }

    /**
     * Has elec usage
     * @param {Object} snapshot the current snapshot
     * @return {Boolean}
     */
    _hasElecUsage(snapshot) {
        let elecUsage =
            snapshot &&
            snapshot.user_snapshot_payload &&
            snapshot.user_snapshot_payload.elec_usage_amount
                ? snapshot.user_snapshot_payload.elec_usage_amount
                : null;
        if (
            snapshot &&
            snapshot.snapshot_phase &&
            Number(snapshot.snapshot_phase) >= 4
        ) {
            elecUsage = snapshot.verified_snapshot_payload.elec_usage_amount;
        }
        return !!elecUsage;
    }

    /**
     * Get elec prod
     * @param {Object} snapshot the current snapshot
     * @return {String} stringified elec prod
     */
    _getElecProd(snapshot) {
        let elecProd =
            snapshot &&
            snapshot.user_snapshot_payload &&
            snapshot.user_snapshot_payload.elec_prod_amount
                ? snapshot.user_snapshot_payload.elec_prod_amount
                : null;

        if (
            snapshot &&
            snapshot.snapshot_phase &&
            Number(snapshot.snapshot_phase) >= 4
        ) {
            elecProd = snapshot.verified_snapshot_payload.elec_prod_amount;
        }
        return `${elecProd} kWh`;
    }

    /**
     * Has elec prod
     * @param {Object} snapshot the current snapshot
     * @return {Boolean}
     */
    _hasElecProd(snapshot) {
        let elecProd =
            snapshot &&
            snapshot.user_snapshot_payload &&
            snapshot.user_snapshot_payload.elec_prod_amount
                ? snapshot.user_snapshot_payload.elec_prod_amount
                : null;

        if (
            snapshot &&
            snapshot.snapshot_phase &&
            Number(snapshot.snapshot_phase) >= 4
        ) {
            elecProd = snapshot.verified_snapshot_payload.elec_prod_amount;
        }
        return !!elecProd;
    }

    /**
     * Get gas usage
     * @param {Object} snapshot the current snapshot
     * @return {String} stringified gas usage
     */
    _getGasUsage(snapshot) {
        let gasUsage =
            snapshot &&
            snapshot.user_snapshot_payload &&
            snapshot.user_snapshot_payload.gas_usage_amount
                ? snapshot.user_snapshot_payload.gas_usage_amount
                : null;

        if (
            snapshot &&
            snapshot.snapshot_phase &&
            Number(snapshot.snapshot_phase) >= 4
        ) {
            gasUsage = snapshot.verified_snapshot_payload.gas_usage_amount;
        }
        return `${gasUsage} m³`;
    }

    /**
     * Has gas usage
     * @param {Object} snapshot the current snapshot
     * @return {Boolean}
     */
    _hasGasUsage(snapshot) {
        let gasUsage =
            snapshot &&
            snapshot.user_snapshot_payload &&
            snapshot.user_snapshot_payload.gas_usage_amount
                ? snapshot.user_snapshot_payload.gas_usage_amount
                : null;

        if (
            snapshot &&
            snapshot.snapshot_phase &&
            Number(snapshot.snapshot_phase) >= 4
        ) {
            gasUsage = snapshot.verified_snapshot_payload.gas_usage_amount;
        }
        return !!gasUsage;
    }

    /**
     * @param {Object} snapshot the current snapshot
     * @return {import('types/snapshot').CalculatedProductGroup} the product group
     */
    _getProductGroup(snapshot) {
        if (!snapshot) {
            return false;
        }
        if (
            snapshot &&
            snapshot.calculated_product_group_verified &&
            snapshot.calculated_product_group_verified.id !==
                '00000000-0000-0000-0000-000000000000'
        ) {
            return snapshot.calculated_product_group_verified;
        }
        return snapshot.calculated_product_group_user;
    }

    /**
     * Get collective id from snapshot
     * @param {Object} snapshot
     * @return {String}
     */
    _getCollectiveId(snapshot) {
        return snapshot && snapshot.collective_id
            ? snapshot.collective_id
            : null;
    }

    /**
     * Get the collective name based on the collective id in the snapshot
     * @param {Object} snapshot
     * @param {Object} collectives
     * @return {String}
     */
    _getCollectiveName(snapshot, collectives) {
        const collective = this._getCollectiveId(snapshot);
        const found = this._getCollective(collective, collectives);
        return found && found.name ? found.name : '';
    }

    /**
     * Get collective from collectives based on collective id
     * @param {String} collective
     * @param {Array} collectives
     * @return {Object}
     */
    _getCollective(collective, collectives = []) {
        return collectives.find((c) => c.id === collective);
    }

    /**
     * get payment method
     * @param {Number} type type of payment
     * @return {String} stringified payment method choice
     */
    _getPaymentMethod(type) {
        if (type === 1) {
            return 'Handmatig met iDEAL betalingen';
        }
        return `Automatische incasso \n ${
            this.paymentDetails && this.paymentDetails.iban
                ? this.paymentDetails.iban
                : ''
        }`;
    }

    /**
     * Checks if snapshot is collective
     * @param {String} collectiveID uuid of collective
     * @return {Boolean} indication if snapshot is for collective or not
     */
    _isCollective(collectiveID) {
        return collectiveID !== '00000000-0000-0000-0000-000000000000';
    }

    /**
     * Check if user has ordered smart meter
     * @param {Number} type type of smart meter choice
     * @return {Boolean} indiciation if user has order smart meter
     */
    _hasOrderedSmartMeter(type) {
        return (
            type ===
            enums.SmartMeterChoiceTypeIDoNotHaveASmartmeterAndWantToOrderOne
        );
    }

    /**
     * Confirm button pressed start verifying contract
     */
    _confirmContract() {
        if (
            this.compactTerms ||
            (this.vendorCheckBoxActive &&
                this.termsChecked &&
                this.smartMeterChecked &&
                this.vendorDataChecked) ||
            (this.termsChecked &&
                this.smartMeterChecked &&
                !this.vendorCheckBoxActive)
        ) {
            // check if the user has accepted (checkboxes) the required terms.
            if (
                this.snapshot &&
                this._termschecked() &&
                Number(this.snapshot.snapshot_phase) <
                    enums.SnapshotPhaseUserAgreement
            ) {
                this._snapShotChanged();
                return;
            }

            window.displayMessage(termsErrorMessage);
            store.dispatch(
                uiOrderOverviewInteraction.run(
                    purchaseEventConfirmation(
                        returnErrorOrEmptyString(false, termsErrorMessage),
                    ),
                ),
            );
            return;
        }
        window.displayMessage(termsAndPrivacyErrorMessage);
        store.dispatch(
            uiOrderOverviewInteraction.run(
                purchaseEventConfirmation(
                    returnErrorOrEmptyString(
                        false,
                        termsAndPrivacyErrorMessage,
                    ),
                ),
            ),
        );
    }

    /**
     * Popup helpers
     */

    /**
     * Indication if start date is half year later
     * @param {Number} type current error type
     * @return {Boolean} Inidication if is half year later
     */
    _startDateHalfYearLater(type) {
        if (type === enums.SnapshotErrorTypeStartDateHalfYearLater) {
            return true;
        }
        return false;
    }

    /**
     * Called when user confirms new start date
     */
    _confirmDateCheck() {
        this.closeStartDateModal();

        const userStartDate =
            this.snapshot?.user_snapshot_payload?.start_date ??
            new Date(Date.now() + 1000 * 60 * 60 * 24 * 15).toISOString();

        switch (Number(this.snapshot.error_type)) {
            case enums.SnapshotErrorTypeStartDateHalfYearLater:
                if (this.startDateCheckboxValue) {
                    this._setStartDateOfSnapshot(userStartDate);
                } else {
                    window.displayMessage(
                        'Helaas, maar we zullen de herinneringsmail sturen.',
                    );
                }
                break;
            case enums.SnapshotErrorTypeUserStartDateBeforeVerifiedStartDate:
                if (this.startDateCheckboxValue) {
                    this._setStartDateOfSnapshot(userStartDate);
                } else {
                    this._setStartDateOfSnapshot(
                        this.snapshot.verified_snapshot_payload.start_date,
                    );
                }
                break;
            default:
                window.displayMessage('Er ging iets mis aan onze kant.');
        }
    }

    /**
     * Called when user declined the new start date
     */
    declineDateCheck() {
        this.closeStartDateModal();
    }

    /**
     * Usage confirmed
     */
    _confirmUsageChanged() {
        this.closeUsageCheckModal();
        this._confirmUsage();
    }

    /**
     * Usage not confirmed
     */
    _dontConfirmUsageClicked() {
        this.closeUsageCheckModal();
        window.displayMessage(`Om je energiecontract te bevestigen moet je akkoord gaan met het 
        energieverbruik wat door ons is gevonden op het opgegeven adres.`);
    }

    /**
     * Smart meter confirmed
     */
    _confirmSmartMeterClicked() {
        this.closeSmartMeterModal();
        this._confirmSmartMeter();
    }

    /**
     * Smart meter not confirmed
     */
    _dontConfirmSmartMeterClicked() {
        this.closeSmartMeterModal();
        window.displayMessage(`Om je energiecontract te bevestigen moet je akkoord gaan met de 
        nieuwe contractvoorwaarde voor de slimme meter.`);
    }

    /**
     * Check if snapshot has elec differences
     * @param {Object} snapshot cur snapshot
     * @return {Boolean} inidication if has elec difference or not
     */
    _hasElecDifferences(snapshot) {
        if (!snapshot) {
            return false;
        }
        const verifiedPayload = snapshot.verified_snapshot_payload;
        if (!verifiedPayload || !verifiedPayload.has_elec) {
            return false;
        }
        return (
            this._hasElecTransmissionTypeDifference(snapshot) ||
            this._hasElecUsageDifference(snapshot)
        );
    }

    /**
     * Check if snapshot has transmission type elec differences
     * @param {Object} snapshot cur snapshot
     * @return {Boolean} inidication if has transmission type elec difference or not
     */
    _hasElecTransmissionTypeDifference(snapshot) {
        const userPayload = snapshot.user_snapshot_payload;
        const verifiedPayload = snapshot.verified_snapshot_payload;
        return (
            userPayload.elec_transmission_type !==
            verifiedPayload.elec_transmission_type
        );
    }

    /**
     * Check if snapshot has usage elec differences
     * @param {Object} snapshot cur snapshot
     * @return {Boolean} inidication if has usage elec difference or not
     */
    _hasElecUsageDifference(snapshot) {
        const userPayload = snapshot.user_snapshot_payload;
        const verifiedPayload = snapshot.verified_snapshot_payload;
        return (
            userPayload.elec_usage_amount !== verifiedPayload.elec_usage_amount
        );
    }

    /**
     * Check if snapshot has gas differences
     * @param {Object} snapshot cur snapshot
     * @return {Boolean} inidication if has gas difference or not
     */
    _hasGasDifferences(snapshot) {
        if (!snapshot) {
            return false;
        }
        const verifiedPayload = snapshot.verified_snapshot_payload;
        if (!verifiedPayload || !verifiedPayload.has_gas) {
            return false;
        }
        return (
            this._hasGasTransmissionTypeDifference(snapshot) ||
            this._hasGasUsageDifference(snapshot)
        );
    }

    /**
     * Check if snapshot has transmission type gas differences
     * @param {Object} snapshot cur snapshot
     * @return {Boolean} inidication if has transmission type gas difference or not
     */
    _hasGasTransmissionTypeDifference(snapshot) {
        const userPayload = snapshot.user_snapshot_payload;
        const verifiedPayload = snapshot.verified_snapshot_payload;
        return (
            userPayload.gas_transmission_type !==
            verifiedPayload.gas_transmission_type
        );
    }

    /**
     * Check if snapshot has usage gas differences
     * @param {Object} snapshot cur snapshot
     * @return {Boolean} inidication if has usage gas difference or not
     */
    _hasGasUsageDifference(snapshot) {
        const userPayload = snapshot.user_snapshot_payload;
        const verifiedPayload = snapshot.verified_snapshot_payload;
        return (
            userPayload.gas_usage_amount !== verifiedPayload.gas_usage_amount
        );
    }

    /**
     * @param {Number} n1
     * @param {Number} n2
     * @return {Number} difference
     */
    _getDifference(n1, n2) {
        return Math.abs(n1 - n2);
    }

    /**
     * @param {Number} n1
     * @param {Number} n2
     * @return {String} difference
     */
    _getHigherLower(n1, n2) {
        if (n1 > n2) {
            return 'hoger';
        }
        return 'lager';
    }

    /**
     * @param {Number} n1
     * @param {Number} n2
     * @return {String} difference
     */
    _getBiggerSmaller(n1, n2) {
        if (n1 > n2) {
            return 'grotere';
        }
        return 'kleinere';
    }

    /**
     * Retrieve test of transmission
     * @param {Number} type transmission type
     * @return {String} stingified transmission type
     */
    _getTransmissionText(type) {
        return enums.TransmissionValueType[type];
    }

    /**
     * API calls
     */

    /**
     * The customers confirms the contract
     */
    _agreeContract() {
        store.dispatch(isBusy('Finalising contract...'));
        const productGroup = this.snapshot.calculated_product_group_user;

        // loop over products in the product group and add price (the total price with vat included)
        productGroup.products.map((product) => {
            // eslint-disable-next-line no-param-reassign
            product.price = roundNumber(
                // sums all the cost + vat in the product
                product.cost.reduce((total, cost) => {
                    // check if cost has vat else use the default vat value
                    const { vat, err } = parseVatFloat(cost);
                    if (err) {
                        console.warn('vat does not exist for product cost');
                    }

                    const totalPrice = Number(cost.calculated_price) + vat;
                    return total + totalPrice;
                }, 0.0),
            );

            return product;
        });

        // Create the custom data for the purchase event
        // The rest of the object is added in the purchase event
        const purchaseEventOBJ = {
            tax: productGroup.vat,
            transaction_id: this.snapshot.id,
            value: productGroup.monthly_pricing,
            items: productGroup.products.map((item) => ({
                item_brand: themeSettings.vendor,
                item_id: item.id,
                item_name: item.name,
                item_variant: productGroup.name,
                price: item.price,
            })),
        };

        // dispatch purchase event to the GTM data layer
        store.dispatch(setPurchase.run(purchaseEventOBJ));
        store.dispatch(
            uiOrderOverviewInteraction.run(
                purchaseEventConfirmation(returnErrorOrEmptyString(true, '')),
            ),
        );

        // dispatch userAgreeSnapshot event that accepts the contract for the customer
        window.store.dispatch(
            userAgreesSnapshot.run(this.user.id, this.snapshot.id),
        );
    }

    /**
     * Confirm usage
     */
    _confirmUsage() {
        store.dispatch(isBusy('Energieaansluiting controleren...'));
        fetch(
            `${API_LINK}/v1/user/${this.user.id}/snapshot/${this.snapshot.id}/usage`,
            { method: 'PUT', headers: STDHeaders },
        )
            .then((res) => {
                store.dispatch(notBusy());
                return res.json();
            })
            // @ts-ignore
            .then((data) => {
                // @ts-ignore
                this.snapshot = data;
            })
            .catch((err) => console.warn(err));
    }

    /**
     * Confirm smartmeter
     */
    _confirmSmartMeter() {
        store.dispatch(isBusy('Slimme meter controleren...'));
        fetch(
            `${API_LINK}/v1/user/${this.user.id}/snapshot/${this.snapshot.id}/smartmeter`,
            { method: 'PUT', headers: STDHeaders },
        )
            .then((res) => {
                store.dispatch(notBusy());
                return res.json();
            })
            // @ts-ignore
            .then((data) => {
                // @ts-ignore
                this.snapshot = data;
            })
            .catch((err) => console.warn(err));
    }

    /**
     * Set start date and go further with phases
     * @param {Date} startDate cur startdate
     */
    _setStartDateOfSnapshot(startDate) {
        const startDatePayload = {
            start_date: new Date(startDate).toISOString(),
        };
        store.dispatch(isBusy('Startdatum controleren...'));
        fetch(
            `${API_LINK}/v1/user/${this.user.id}/snapshot/${this.snapshot.id}/startdate`,
            {
                method: 'PUT',
                headers: STDHeaders,
                body: JSON.stringify(startDatePayload),
            },
        )
            .then((res) => {
                store.dispatch(notBusy());
                return res.json();
            })
            // @ts-ignore
            .then((data) => {
                this.snapshot = data;
            })
            .catch((err) => console.warn(err));
    }

    /**
     * Retrieves the current snapshot by id
     * @param {Boolean} [quiet]
     */
    retrieveCurrentSnapshot(quiet) {
        if (!this.secSubRoute || this.subRoute !== 'overview') {
            return;
        }
        if (!quiet) {
            store.dispatch(isBusy('Energie aanvraag ophalen...'));
        }
        fetch(
            `${API_LINK}/v1/user/${this.user.id}/snapshot/${this.secSubRoute}`,
            {
                method: 'GET',
                headers: STDHeaders,
            },
        )
            .then((res) => {
                store.dispatch(notBusy());
                return res.json();
            })
            .then((data) => {
                this.snapshot = data;
            })
            .catch((err) => console.warn(err));
    }

    /**
     * Menu position variant selector
     * @param {String|Number} number
     * @return {Boolean}
     */
    _isMenuPositionVariant(number) {
        return (
            !Number.isNaN(Number(number)) &&
            // @ts-ignore
            ((themeSettings &&
                themeSettings.menuPositionVariant ===
                    parseInt(String(number), 10)) ||
                (!themeSettings && parseInt(String(number), 10) === 1))
        );
    }

    /**
     * Post agree variant selector
     * @param {String|Number} variant
     * @return {Boolean}
     */
    _isPostAgreeVariant(variant) {
        return (
            !Number.isNaN(Number(variant)) &&
            ((themeSettings &&
                themeSettings.postAgreeVariant ===
                    parseInt(String(variant), 10)) ||
                (!themeSettings && parseInt(String(variant), 10) === 1))
        );
    }

    /**
     * Convert number to price string
     * @param {Number|String} price
     * @return {String}
     */
    _numberToPrice(price) {
        return !Number.isNaN(Number(price))
            ? NumberToPrice(parseFloat(String(price)), null, 2, false)
            : 'niet beschikbaar';
    }

    /**
     * Gets template of class
     */
    static get template() {
        const cssTemplate = document.createElement('template');
        cssTemplate.innerHTML = provider.styles(css);
        const htmlTemplate = document.createElement('template');
        htmlTemplate.innerHTML = template;
        return html`<style include="iron-flex">
                ${cssTemplate}
            </style>
            ${htmlTemplate}`;
    }

    closeStartDateModal() {
        const startDate = this.shadowRoot.querySelector('#startDate');
        if (!startDate) {
            console.warn('Start date modal is missing');
            return;
        }

        startDate.close();
    }

    closeUsageCheckModal() {
        const usageCheck = this.shadowRoot.querySelector('#usageCheck');
        if (!usageCheck) {
            console.warn('Usage check modal is missing');
            return;
        }
        usageCheck.close();
    }

    closeSmartMeterModal() {
        const smartMeterCheck =
            this.shadowRoot.querySelector('#smartMeterCheck');
        if (!smartMeterCheck) {
            console.warn('Smart meteringpoint modal is missing');
            return;
        }
        smartMeterCheck.close();
    }

    /**
     * dispatch data layer event for read terms and conditions
     */
    _readTermsAndConditions() {
        store.dispatch(
            uiOrderOverviewInteraction.run(readTermsAndConditions()),
        );
    }

    /**
     * dispatch data layer event when the terms and condition checkboxes change
     * @param {import('@components/elements/base/checkbox-component/helpers/events').CheckboxEvent} e
     * @param {import('gtm-middleware/orderOverview'). OrderOverviewFields} field
     */
    _dispatchCheckboxEvent(e, field, errorMessage = '') {
        const isChecked = e?.composedPath()[0]?.checked;
        store.dispatch(
            uiOrderOverviewInteraction.run(
                conditionsChanged(
                    isChecked ? 'valid' : `invalid ${errorMessage}`,
                    field,
                ),
            ),
        );
    }

    _checkboxTermsChanged(e) {
        this._dispatchCheckboxEvent(
            e,
            'algemene-voorwaarden',
            termsErrorMessage,
        );
    }

    _checkboxSmartMeterChanged(e) {
        this._dispatchCheckboxEvent(
            e,
            'slimme-meter-voorwaarden',
            'Je hebt de voorwaarden voor de slimme meter nog niet geaccepteerd',
        );
    }

    _checkboxVendorChanged(e) {
        this._dispatchCheckboxEvent(
            e,
            'vendor-voorwaarden',
            'Je hebt de vendor voorwaarden nog niet geaccepteerd',
        );
    }

    _getincassoInformationURL() {
        return this.incassoInformationURL != null;
    }

    _checkboxDeliveryTermsChanged(e) {
        this._dispatchCheckboxEvent(
            e,
            'leverings-voorwaarden',
            termsAndPrivacyErrorMessage,
        );
    }
}

window.customElements.define('order-overview', EzOrderOverview);
