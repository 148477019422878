import { daysInMonth } from '../globals';
import vendorIds from '../helpers/vendors';

export const RECEIVE_USAGE = 'RECEIVE_USAGE';
// TODO: Raymon refactor property names.
const usageObject = {
    labels: new Array(0),
    datasets: [
        {
            data: new Array(0), // Private Usage
        },
        {
            data: new Array(0), // Estimated Usage
        },
        {
            data: new Array(0), // Business Usage
        },
    ],
    __attachedCostData: [
        {
            data: new Array(0), // Private costs
        },
        {
            data: new Array(0), // Business costs
        },
    ],
    __dataLabels: new Array(0),
    intervalType: 1,
};

const getCostPriceVatIncluded = (usage) => usage.cost + usage.vat;

const PRIVATE_COST = 0;
const BUSINESS_COST = 1;

const PRIVATE_USAGE = 0;
const ESTIMATED_USAGE = 1;
const BUSINESS_USAGE = 2;
/**
 * Prepare data for daily graph
 * @param {Object} data
 * @return {Usage}
 */
const prepareDayData = (data) => {
    /**
     * @type {Usage}
     */
    const usage = JSON.parse(JSON.stringify(usageObject));

    let usageCounter = 0;
    for (let i = 0; i < 24; i += 1) {
        if (i % 2 === 0) {
            usage.labels.push(`${i}:00`);
            // @ts-ignore
            usage.__dataLabels.push(`${i}:00`);
        } else {
            usage.labels.push('');
            // @ts-ignore
            usage.__dataLabels.push(`${i}:00`);
        }
    }
    let ff = true;

    if (
        data &&
        data.usages &&
        data.usages.data &&
        data.usages.data.length > 0
    ) {
        for (let i = 0; i < data.usages.data.length; i += 1) {
            const noGaps = data.usages.data.length === 24;
            const chargingSession =
                window.store.getState()?.jwtSnapshots?.selectedSnapshot
                    ?.vendor_id === vendorIds.leaseplan;
            const el = data.usages.data[i];
            // in case that there are no gaps in the usages and this is about chargingsessions, there is no need to fill gaps in the frontend.
            // the ff is part of an older fix, which skips steps if the data is business data. This nogaps and no charginsessions condition is used to
            // force the loop to not continue in this case.
            // You don't want steps to be skipped if there are always 24 usages. This is because at the time of writing,
            // Leaseplan (combined_extended && combined_leaseplan) uses a gap filling function in the backend, therefore there are always 24 usages.
            // in this case there is no need to fill any gaps in usages in the frontend.
            if (
                ff &&
                new Date(el.reading_date).getHours() > usageCounter &&
                !noGaps &&
                !chargingSession
            ) {
                continue;
            }

            const isBusiness = el.business !== undefined && el.business;
            // the same applies here. The else in this if statement will trigger filling from the starting hour to the first usage.
            // but because there are 24 usages, there is no need to do that.
            if (
                new Date(el.reading_date).getHours() === usageCounter ||
                noGaps ||
                chargingSession
            ) {
                ff = false;
                // @ts-ignore
                if (isBusiness) {
                    usage.__attachedCostData[BUSINESS_COST].data.push(
                        getCostPriceVatIncluded(el),
                    );
                    usage.__attachedCostData[PRIVATE_COST].data.push(0);

                    usage.datasets[PRIVATE_USAGE].data.push(0);
                    usage.datasets[BUSINESS_USAGE].data.push(
                        el.reading.toFixed(4),
                    );
                } else {
                    usage.datasets[PRIVATE_USAGE].data.push(
                        el.reading.toFixed(4),
                    );
                    usage.datasets[BUSINESS_USAGE].data.push(0);

                    usage.__attachedCostData[BUSINESS_COST].data.push(0);
                    usage.__attachedCostData[PRIVATE_COST].data.push(
                        getCostPriceVatIncluded(el),
                    );
                }
            } else {
                // usage.datasets[0].data.push(0);
                usage.datasets[PRIVATE_USAGE].data.push(0);
                usage.datasets[BUSINESS_USAGE].data.push(0);
                // @ts-ignore
                usage.__attachedCostData[BUSINESS_COST].data.push(0);
                usage.__attachedCostData[PRIVATE_COST].data.push(0);

                i -= 1;
            }
            usageCounter += 1;
            if (usageCounter === usage.labels.length) {
                break;
            }
        }
    }

    if (data.estimations) {
        let estimationCounter = 0;
        for (let y = 0; y < data.estimations.data.length; y += 1) {
            const el = data.estimations.data[y];
            if (new Date(el.reading_date).getHours() === estimationCounter) {
                usage.datasets[ESTIMATED_USAGE].data.push(
                    el.reading.toFixed(4),
                );
            } else {
                usage.datasets[ESTIMATED_USAGE].data.push(0);
                y -= 1;
            }
            estimationCounter += 1;
            if (estimationCounter === usage.labels.length) {
                break;
            }
        }
    }

    return usage;
};

/**
 * Prepare data for weekly graph
 * @param {Object} data result from fetch request
 * @return {Usage}
 */
const prepareWeekData = (data) => {
    /**
     * @type {Usage} defined in global declaration file
     */
    const usage = JSON.parse(JSON.stringify(usageObject));
    usage.labels = ['ma', 'di', 'wo', 'do', 'vr', 'za', 'zo'];
    // @ts-ignore
    usage.__dataLabels = ['ma', 'di', 'wo', 'do', 'vr', 'za', 'zo'];
    let counter = 0;
    let intermediateResultPrivate = 0;
    let intermediateResultBusiness = 0;
    let intermediateCostResultPrivate = 0.0;
    let intermediateCostResultBusiness = 0.0;
    let ff = true;
    if (
        data &&
        data.usages &&
        data.usages.data &&
        data.usages.data.length > 0
    ) {
        for (let i = 0; i < data.usages.data.length; i += 1) {
            const el = data.usages.data[i];
            const isBusiness = el.business !== undefined && el.business;

            if (new Date(el.reading_date).getDay() === 0 && ff) {
                continue;
            }

            if (
                new Date(el.reading_date).getDay() === counter + 1 ||
                (new Date(el.reading_date).getDay() === 0 && counter === 6)
            ) {
                if (isBusiness) {
                    intermediateResultBusiness += el.reading;
                    intermediateCostResultBusiness +=
                        getCostPriceVatIncluded(el);
                } else {
                    intermediateResultPrivate += el.reading;
                    intermediateCostResultPrivate +=
                        getCostPriceVatIncluded(el);
                }
                ff = false;
            } else {
                usage.datasets[PRIVATE_USAGE].data.push(
                    intermediateResultPrivate.toFixed(4),
                );
                usage.datasets[BUSINESS_USAGE].data.push(
                    intermediateResultBusiness.toFixed(4),
                );
                // @ts-ignore
                usage.__attachedCostData[BUSINESS_COST].data.push(
                    intermediateCostResultBusiness,
                );
                usage.__attachedCostData[PRIVATE_COST].data.push(
                    intermediateCostResultPrivate,
                );

                intermediateCostResultPrivate = 0.0;
                intermediateCostResultBusiness = 0.0;

                intermediateResultPrivate = 0;
                intermediateResultBusiness = 0;
                counter += 1;
                i -= 1;
            }
            if (counter === usage.labels.length) {
                usage.datasets[PRIVATE_USAGE].data.push(
                    intermediateResultPrivate.toFixed(4),
                );
                usage.datasets[BUSINESS_USAGE].data.push(
                    intermediateResultBusiness.toFixed(4),
                );
                usage.__attachedCostData[BUSINESS_COST].data.push(
                    intermediateCostResultBusiness,
                );
                usage.__attachedCostData[PRIVATE_COST].data.push(
                    intermediateCostResultPrivate,
                );

                break;
            }
        }
    }

    if (intermediateResultPrivate !== 0 || intermediateResultBusiness !== 0) {
        usage.datasets[PRIVATE_USAGE].data.push(
            intermediateResultPrivate.toFixed(4),
        );
        usage.datasets[BUSINESS_USAGE].data.push(
            intermediateResultBusiness.toFixed(4),
        );
        // @ts-ignore
        usage.__attachedCostData[PRIVATE_COST].data.push(
            intermediateCostResultPrivate,
        );
        usage.__attachedCostData[BUSINESS_COST].data.push(
            intermediateCostResultBusiness,
        );
    }

    if (data.estimations) {
        let estimationCounter = 0;
        let estimationintermediateResult = 0;
        for (let y = 0; y < data.estimations.data.length; y += 1) {
            const element = data.estimations.data[y];
            if (
                new Date(element.reading_date).getDay() ===
                    estimationCounter + 1 ||
                (new Date(element.reading_date).getDay() === 0 &&
                    estimationCounter === 6)
            ) {
                estimationintermediateResult += element.reading;
            } else {
                usage.datasets[ESTIMATED_USAGE].data.push(
                    estimationintermediateResult.toFixed(4),
                );
                // @ts-ignore
                estimationintermediateResult = 0;
                estimationCounter += 1;
                y -= 1;
            }
            if (estimationCounter === usage.labels.length) {
                usage.datasets[ESTIMATED_USAGE].data.push(
                    estimationintermediateResult.toFixed(4),
                );
                // @ts-ignore
                break;
            }
        }
        if (estimationintermediateResult !== 0) {
            usage.datasets[ESTIMATED_USAGE].data.push(
                estimationintermediateResult.toFixed(4),
            );
            // @ts-ignore
        }
    }

    if (
        usage.datasets[ESTIMATED_USAGE] &&
        usage.datasets[ESTIMATED_USAGE].data.length > 0
    ) {
        usage.datasets[ESTIMATED_USAGE].data.pop();
    }

    return usage;
};

/**
 * Prepare Usage data for monthly graph
 * @param {Object} data
 * @return {Usage}
 */
const prepareMonthlyData = (data) => {
    /**
     * @type {Usage} declared in global declaration file
     */
    const usage = JSON.parse(JSON.stringify(usageObject));

    let date = new Date();
    if (
        data &&
        data.estimations &&
        data.estimations.data &&
        data.estimations.data.length > 0
    ) {
        date = new Date(data.estimations.data[0].reading_date);
    } else if (
        data &&
        data.usages &&
        data.usages.data &&
        data.usages.data.length > 0
    ) {
        date = new Date(data.usages.data[0].reading_date);
    }
    const daysInDate = daysInMonth(date.getMonth() + 1, date.getFullYear());
    for (let i = 0; i < daysInDate; i += 1) {
        if (i % 2 === 0) {
            usage.labels.push((i + 1).toString());
            // @ts-ignore
            usage.__dataLabels.push((i + 1).toString());
        } else {
            usage.labels.push('');
            // @ts-ignore
            usage.__dataLabels.push((i + 1).toString());
        }
    }
    let counter = 1;
    let intermediateResultPrivate = 0.0;
    let intermediateResultBusiness = 0.0;
    let intermediateCostResultPrivate = 0.0;
    let intermediateCostResultBusiness = 0.0;

    let ff = true;
    if (
        data &&
        data.usages &&
        data.usages.data &&
        data.usages.data.length > 0
    ) {
        for (let i = 0; i < data.usages.data.length; i += 1) {
            const el = data.usages.data[i];
            const isBusiness = el.business !== undefined && el.business;

            if (new Date(el.reading_date).getDate() > counter && ff) {
                usage.__attachedCostData[PRIVATE_COST].data.push(0.0);
                usage.__attachedCostData[BUSINESS_COST].data.push(0.0);
                usage.datasets[PRIVATE_USAGE].data.push(0);
                usage.datasets[BUSINESS_USAGE].data.push(0);
                i -= 1;
                counter += 1;
                continue;
            }

            if (new Date(el.reading_date).getDate() === counter) {
                ff = false;
                if (isBusiness) {
                    intermediateResultBusiness += el.reading;

                    // The prepared cost price is now included it's own vat
                    intermediateCostResultBusiness +=
                        getCostPriceVatIncluded(el);
                } else {
                    intermediateResultPrivate += el.reading;

                    // The prepared cost price is now included it's own vat
                    intermediateCostResultPrivate +=
                        getCostPriceVatIncluded(el);
                }
            } else {
                usage.datasets[PRIVATE_USAGE].data.push(
                    intermediateResultPrivate.toFixed(4),
                );
                usage.datasets[BUSINESS_USAGE].data.push(
                    intermediateResultBusiness.toFixed(4),
                );
                // @ts-ignore
                usage.__attachedCostData[PRIVATE_COST].data.push(
                    intermediateCostResultPrivate,
                );
                usage.__attachedCostData[BUSINESS_COST].data.push(
                    intermediateCostResultBusiness,
                );
                intermediateResultPrivate = 0;
                intermediateResultBusiness = 0;
                intermediateCostResultPrivate = 0.0;
                intermediateCostResultBusiness = 0.0;
                counter += 1;
                i -= 1;
            }
            if (counter === usage.labels.length + 1) {
                usage.datasets[PRIVATE_USAGE].data.push(
                    intermediateResultPrivate.toFixed(4),
                );
                usage.datasets[BUSINESS_USAGE].data.push(
                    intermediateResultBusiness.toFixed(4),
                );
                // @ts-ignore
                usage.__attachedCostData[PRIVATE_COST].data.push(
                    intermediateCostResultPrivate,
                );
                usage.__attachedCostData[BUSINESS_COST].data.push(
                    intermediateCostResultBusiness,
                );
                break;
            }
        }
    }

    if (intermediateResultPrivate !== 0 || intermediateResultBusiness !== 0) {
        usage.datasets[PRIVATE_USAGE].data.push(
            intermediateResultPrivate.toFixed(4),
        );
        usage.datasets[BUSINESS_USAGE].data.push(
            intermediateResultBusiness.toFixed(4),
        );
        // @ts-ignore
        usage.__attachedCostData[PRIVATE_COST].data.push(
            intermediateCostResultPrivate,
        );
        usage.__attachedCostData[BUSINESS_COST].data.push(
            intermediateCostResultBusiness,
        );
    }

    if (data.estimations) {
        let estimationcounter = 1;
        let estimationintermediateResult = 0.0;
        if (
            data &&
            data.estimations &&
            data.estimations.data &&
            data.estimations.data.length > 0
        ) {
            for (let i = 0; i < data.estimations.data.length; i += 1) {
                const estimation = data.estimations.data[i];
                if (
                    new Date(estimation.reading_date).getDate() ===
                    estimationcounter
                ) {
                    estimationintermediateResult += estimation.reading;
                } else {
                    usage.datasets[ESTIMATED_USAGE].data.push(
                        estimationintermediateResult.toFixed(4),
                    );
                    // @ts-ignore
                    estimationintermediateResult = 0;
                    estimationcounter += 1;
                    i -= 1;
                }
                if (estimationcounter === usage.labels.length + 1) {
                    usage.datasets[ESTIMATED_USAGE].data.push(
                        estimationintermediateResult.toFixed(4),
                    );
                    // @ts-ignore
                    break;
                }
            }
        }
        if (estimationintermediateResult !== 0) {
            usage.datasets[ESTIMATED_USAGE].data.push(
                estimationintermediateResult.toFixed(4),
            );
            // @ts-ignore
        }
    }

    if (
        usage.datasets[ESTIMATED_USAGE] &&
        usage.datasets[ESTIMATED_USAGE].data.length >
            usage.datasets[PRIVATE_USAGE].data.length
    ) {
        usage.datasets[ESTIMATED_USAGE].data.pop();
    }
    return usage;
};

/**
 * Receive usage action for state reducer
 * @param {Object} usage
 * @return {Object}
 */
const receiveUsage = (usage) => ({
    type: RECEIVE_USAGE,
    usage,
});

/**
 * prepareData prepares data from API for one of the graph types
 * @param {Object} data response from API
 * @param {string} intervalType type of interval
 * @param {number} usageType enum
 * @return {any} dispatch to the store
 */
export const prepareData = (data, intervalType, usageType) => (dispatch) => {
    /**
     * @type {Usage} declared in global declaration file
     */
    let usage;
    if (intervalType === 'daily') {
        usage = prepareDayData(data);
    } else if (intervalType === 'weekly') {
        usage = prepareWeekData(data);
    } else {
        usage = prepareMonthlyData(data);
    }
    usage.usage_type = usageType;
    usage.intervalType = intervalType;

    return dispatch(receiveUsage(usage));
};
