export const TYPED_ACTION_ERROR = 'TYPED_ACTION_ERROR';

/**
 * Action generator for a typed action error
 * @param {String} type
 * @param {any} val
 * @param {String} prefix
 * @return {Object}
 */
const error = (type, val, prefix) => {
    const reduxMessage = `Expected ${type} but got ${val}`;
    const reduxType = `${prefix}TYPED_ACTION_ERROR`;
    /* eslint-disable no-console */
    console.groupCollapsed(reduxType);
    console.error(reduxMessage);
    console.error(val);
    console.trace();
    console.groupEnd();
    /* eslint-enable no-console */
    return {
        type: reduxType,
        message: reduxMessage,
        value: val,
    };
};

/**
 * Type checker and parser for integer
 * @param {any} val
 * @param {Function} cb
 * @param {String} prefix
 * @return {Object}
 */
export const int = (val, cb, prefix) =>
    !Number.isNaN(val) ? cb(parseInt(val, 10)) : error('Number', val, prefix);

/**
 * Type checker and parser for nullable integer
 * @param {?any} val
 * @param {Function} cb
 * @param {String} prefix
 * @return {Object}
 */
export const nullableInt = (val, cb, prefix) =>
    val === null ? cb(val) : int(val, cb, prefix);
