import { provider } from '@weavelab/frontend-connect';

import { PolymerElement, html } from '@polymer/polymer/polymer-element';

import { resetHook } from 'helpers/resetHook';
import { formSlotInjector } from 'helpers/formInjector';
import pcss from './reset-password.pcss';
import css from './style';

import template from './reset-password.html';

import '@polymer/paper-input/paper-input';
import '@polymer/iron-flex-layout/iron-flex-layout-classes';

import '../../elements/password-validations/PasswordValidations';
import { resetPassword } from '../../../async-reducers/user';

/**
 * Reset password class
 */
export default class EzResetPassword extends formSlotInjector('form')(
    PolymerElement,
) {
    /**
     * Gets properties of class
     */
    static get properties() {
        return {
            password: {
                type: String,
            },
            passwordRepeat: {
                type: String,
            },
            resetToken: {
                type: String,
            },
            disabled: {
                type: Boolean,
            },
        };
    }

    /**
     * Gets template of class
     */
    static get template() {
        const pcssTemplate = document.createElement('template');
        pcssTemplate.innerHTML = provider.styles(pcss);
        const cssTemplate = document.createElement('template');
        cssTemplate.innerHTML = css;
        const htmlTemplate = document.createElement('template');
        htmlTemplate.innerHTML = template;
        const CSSTemp = html`${pcssTemplate} ${cssTemplate}`;
        return html`<style include="iron-flex">
                ${CSSTemp}
            </style>
            ${htmlTemplate}`;
    }

    /**
     * Constructor of reset password
     */
    constructor() {
        super();
        /** @type {ShadowRoot} */
        // TODO linting: default value for shadowroot?
        this.shadowRoot;

        /** @type {Boolean} */
        this.disabled = true;

        /** @type {String} */
        this.resetToken = '';
    }

    /**
     * On connectedCallback
     */
    connectedCallback() {
        super.connectedCallback();
        this.resetToken = this.location.params.token;

        window.document.addEventListener('password-valid', (event) => {
            event.stopImmediatePropagation();
            this.disabled = !event.detail.valid;
        });

        resetHook.onReset = (password, repeat) => {
            this.password = password;
            this.passwordRepeat = repeat;
            this._reset();
        };

        resetHook.passwordInputChanged = (password) => {
            this.password = password;
        };
    }

    /**
     * Calls reset route with new password
     */
    _reset() {
        if (this.password === '' || this.passwordRepeat === '') {
            window.displayMessage('Vul twee keer je nieuwe wachtwoord in.');
            return;
        }
        if (this.password !== this.passwordRepeat) {
            window.displayMessage(
                'De ingevulde wachtwoorden zijn niet gelijk.',
            );
            return;
        }

        window.store.dispatch(
            resetPassword.run(this.resetToken, this.password),
        );

        this.password = '';
        this.passwordRepeat = '';
        this.resetToken = '';
    }
}

window.customElements.define('ez-reset-password', EzResetPassword);
