import { PolymerElement, html } from '@polymer/polymer/polymer-element';
import '@polymer/iron-pages/iron-pages';
import '@polymer/iron-selector/iron-selector';
import { connect } from 'pwa-helpers/connect-mixin';
import { currencyExtended } from 'helpers/currency';
import { isFlex } from 'helpers/snapshot';
import { VAT, VATPercentage } from 'helpers/vat';
import { store } from '../../../store';
import { monthNamesFull } from '../../../globals';
import css from './ez-data-table.pcss';
import template from './ez-data-table.html';

/**
 * Popup class
 */
export default class EzDataTable extends connect(store)(PolymerElement) {
    /**
     * Gets properties of class
     */
    static get properties() {
        return {
            init: {
                type: Boolean,
                value: false,
                observer: '_init',
            },
            elecData: {
                type: Array,
                value: [],
                observer: '_elecDataChanged',
            },
            elecDataHeaders: {
                type: Object,
                value: [],
            },
            gasData: {
                type: Array,
                value: [],
                observer: '_gasDataChanged',
            },
            gasDataHeaders: {
                type: Object,
                value: [],
            },
            prodData: {
                type: Array,
                value: [],
                observer: '_prodDataChanged',
            },
            prodDataHeaders: {
                type: Object,
                value: [],
            },
            selectedData: {
                type: String,
                value: 'elec',
            },
            selectedSnapshot: {
                type: Object,
            },
            // FIXME: Needs to be removed after a succesfull VAT release
            countErrors: {
                type: Number,
                value: 0,
            },
        };
    }

    /**
     * checks for data change in data arrays
     * @param {import('types/cost').CostType} cost
     * @param {Array} headers
     * @return {Array}
     */
    _toArray = (cost, headers) => {
        if (cost.total_per_setting) {
            const data = Object.keys(cost.total_per_setting).map((key) => ({
                name: key,
                value: currencyExtended(Number(cost.total_per_setting[key])),
            }));
            if (data.length > 0) {
                for (let index = 0; index < headers.length; index += 1) {
                    const header = headers[index];
                    if (data[index].name !== header) {
                        data.splice(index, 0, { name: 'nvm', value: 'nvt' });
                    }
                }
            } else {
                for (let index = 0; index < headers.length; index += 1) {
                    data.push({ name: 'nvt', value: 'nvt' });
                }
            }

            if (cost.vat == null) {
                if (this.countErrors < 2) {
                    console.error(
                        `'vat not included, fallback to ${VATPercentage} percentage`,
                        cost,
                    );
                    this.countErrors += 1;
                }
                cost.vat = cost.total_excl * VATPercentage;
            }
            if (cost.total_incl == null) {
                if (this.countErrors < 2) {
                    console.error(
                        `'price including vat is missing, fallback to cost.total_excl * VAT`,
                        cost,
                    );
                    this.countErrors += 1;
                }
                cost.total_incl = cost.total_excl * VAT;
            }

            data.push({
                name: 'BTW',
                value: currencyExtended(Number(cost.vat)),
            });
            data.push({
                name: 'Totaal',
                value: currencyExtended(Number(cost.total_incl)),
            });
            return data;
        }
        return [];
    };

    /**
     * Init loads data from state
     */
    _init() {
        const elecCosts = store.getState().elekUsageCost.data
            ? store.getState().elekUsageCost.data
            : [];
        const gasCosts = store.getState().gasUsageCost.data
            ? store.getState().gasUsageCost.data
            : [];
        const prodCosts = store.getState().elekProdCost.data
            ? store.getState().elekProdCost.data
            : [];
        this.set('elecData', elecCosts);
        this.set('gasData', gasCosts);

        this.set('prodData', prodCosts);

        this._initializeSelected();
    }

    _initializeSelected() {
        if (this._hasData(this.elecData)) {
            this.selectedData = 'elect';
            return;
        }

        if (this._hasData(this.gasData)) {
            this.selectedData = 'gas';
            return;
        }

        if (this._hasData(this.prodData)) {
            this.selectedData = 'prod';
        }
    }

    /**
     * checks if array had the data
     * @param {Array} data
     */
    _hasData(data) {
        return data && data.length > 0;
    }

    /**
     * checks for data change in data arrays
     * @param {Array} data
     */
    _elecDataChanged(data) {
        if (data.length > 0) {
            this.elecDataHeaders = this._setDataHeader(data);
        }
    }

    /**
     * checks for data change in data arrays
     * @param {Array} data
     */
    _gasDataChanged(data) {
        if (data.length > 0) {
            this.gasDataHeaders = this._setDataHeader(data);
        }
    }

    /**
     * checks for data change in data arrays
     * @param {Array} data
     */
    _prodDataChanged(data) {
        if (data.length > 0) {
            this.prodDataHeaders = this._setDataHeader(data);
        }
    }

    /**
     * Renames header
     * @param {string} name
     * @return {string}
     */
    _renameHeader = (name) => {
        if (name.indexOf('per') > -1) {
            if (name.indexOf('LEBA') > -1 || name.indexOf('EPEX') > -1) {
                return 'Leveringskosten';
            }
            return name.split('per')[0];
        }
        return name;
    };

    /**
     * check for biggest price object and set headers
     * @param {Array} data
     * @return {Object}
     */
    _setDataHeader = (data) => {
        let biggest = 0;
        let biggestObject = {};
        for (let index = 0; index < data.length; index += 1) {
            if (data[index].total_per_setting) {
                const obj = data[index].total_per_setting;
                if (Object.keys(obj).length > biggest) {
                    biggest = Object.keys(obj).length;
                    biggestObject = obj;
                }
            }
        }
        return Object.keys(biggestObject);
    };

    /**
     * Format string date to formated string date
     * @param {String} date
     * @return {String}
     */
    _formatDate = (date) => {
        const dateNew = new Date(date);
        const hours = dateNew.getHours();
        return `${dateNew.getDate()} ${
            monthNamesFull[dateNew.getMonth()]
        } ${dateNew.getUTCFullYear()} 
        ${hours < 10 ? `0${hours}` : hours}:00 `;
    };

    /**
     * Format usage formats the usage
     * @param {Float} usage
     * @return {String}
     */
    _formatUsage = (usage) => String(usage).replace(/\./g, ',');

    /**
     * Gets template of class
     */
    static get template() {
        const cssTemplate = document.createElement('template');
        cssTemplate.innerHTML = css;
        const htmlTemplate = document.createElement('template');
        htmlTemplate.innerHTML = template;
        return html`<style include="iron-flex">
                ${cssTemplate}</style
            >${htmlTemplate}`;
    }

    /**
     * _stateChanged
     */
    _stateChanged = () => {};

    /**
     * Check if contract is flex then more columns are required
     * @param {Snapshot} snapshot
     * @return {String}
     */
    isSnapshotFlex = (snapshot) => (isFlex(snapshot) ? 'flex-table' : '');
}

window.customElements.define('ez-data-table', EzDataTable);
