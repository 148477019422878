import { ReduxAsync } from '@weavedev/redux-async';
import axios from 'axios';
import { Vehicle, Vehicles } from 'types/vehicle';
import headers from '../sagas/helpers/headers';

// Get the vehicle initialization url from enode
const VEHICLE_AUTHENTICATE = 'VEHICLE_AUTHENTICATE';
const VEHICLE_AUTHENTICATE_SUCCESS = 'VEHICLE_AUTHENTICATE_SUCCESS';
const VEHICLE_AUTHENTICATE_FAILED = 'VEHICLE_AUTHENTICATE_FAILED';
export const vehicleAuthenticate = new ReduxAsync(
    VEHICLE_AUTHENTICATE,
    VEHICLE_AUTHENTICATE_SUCCESS,
    VEHICLE_AUTHENTICATE_FAILED,
    (snapshotID: string) =>
        axios
            .get(`/v1/vehicles/authenticate/${snapshotID}`, {
                baseURL: window.API_LINK,
                headers: headers(window.STDHeaders),
            })
            .then((response) => response.data),
);

// Get vehicle
export const VEHICLE_GET = 'VEHICLE_GET';
export const VEHICLE_GET_SUCCESS = 'VEHICLE_GET_SUCCESS';
export const VEHICLE_GET_FAILED = 'VEHICLE_GET_FAILED';
export const vehicleGet = new ReduxAsync(
    VEHICLE_GET,
    VEHICLE_GET_SUCCESS,
    VEHICLE_GET_FAILED,
    (snapshotID: string, vehicleID?: string) =>
        axios
            .get<Vehicle>(`v1/vehicles/${vehicleID}`, {
                baseURL: window.API_LINK,
                headers: headers(window.STDHeaders),
                params: {
                    snapshot_id: snapshotID,
                },
            })
            .then((response) => response.data),
);

// List vehicles
const VEHICLE_LIST = 'VEHICLE_LIST';
export const VEHICLE_LIST_SUCCESS = 'VEHICLE_LIST_SUCCESS';
const VEHICLE_LIST_FAILED = 'VEHICLE_LIST_FAILED';
export const vehicleList = new ReduxAsync(
    VEHICLE_LIST,
    VEHICLE_LIST_SUCCESS,
    VEHICLE_LIST_FAILED,
    (snapshotID: string) =>
        axios
            .get<Vehicles>('v1/vehicles', {
                baseURL: window.API_LINK,
                headers: headers(window.STDHeaders),
                params: {
                    snapshot_id: snapshotID,
                },
            })
            .then((response) => response.data),
);

// Start stop charging vehicle
const VEHICLE_START_STOP_CHARGING = 'VEHICLE_START_STOP_CHARGING';
const VEHICLE_START_STOP_CHARGING_SUCCESS =
    'VEHICLE_START_STOP_CHARGING_SUCCESS';
const VEHICLE_START_STOP_CHARGING_FAILED = 'VEHICLE_START_STOP_CHARGING_FAILED';
export const vehicleStartStopCharging = new ReduxAsync(
    VEHICLE_START_STOP_CHARGING,
    VEHICLE_START_STOP_CHARGING_SUCCESS,
    VEHICLE_START_STOP_CHARGING_FAILED,
    (
        action: 'ACTION_STOP' | 'ACTION_START',
        vehicleID: string,
        snapshotID: string,
    ) =>
        axios.put(
            `v1/vehicles/${vehicleID}/charging`,
            {
                action,
                snapshot_id: snapshotID,
                vehicle_id: vehicleID,
            },
            {
                baseURL: window.API_LINK,
                headers: headers(window.STDHeaders),
            },
        ),
);
