import { LionButton } from '@lion/button';
import { property } from 'lit-element';
import { css, CSSResult } from 'lit';
import { customElement } from 'lit/decorators/custom-element.js';
import { whitelabel } from '@weavelab/whitelabel';
import { _defaults } from '../helpers/_defaults';

type themeType = 'primary' | 'secondary';

@customElement('hv-button')
@whitelabel({ name: 'hv-button' })
export class HvButton extends LionButton {
    @property({
        attribute: 'theme',
    })
    public theme: themeType = 'primary';

    @whitelabel()
    public static get styles(): CSSResult[] {
        return [
            ...super.styles,
            _defaults as unknown as CSSResult,
            css`
                :host,
                :host(:hover),
                :host(:active),
                :host([active]) {
                    overflow: hidden;

                    padding: 15px 40px;

                    font-family: var(--primary-font-family);
                    line-height: 18px;
                    text-align: center;

                    justify-content: center;
                    cursor: pointer;
                    position: relative;

                    /* General */
                    --hv-button-border-radius: var(--border-radius, 27px);
                    --hv-button-font-weight: 600;
                    --hv-button-font-size: 16px;
                    --hv-button-text-transform: lowercase;
                    --hv-button-height: 40px;

                    /* Primary */
                    --hv-button-primary-color: var(
                        --primary-button-color,
                        orange
                    );
                    --hv-button-primary-font-color: var(--font-color, #000);
                    --hv-button-primary-border-left-color: var(
                        --app-border-color
                    );
                    --hv-button-primary-border-bottom: 6px solid
                        var(--primary-button-color-dark);
                    --hv-button-primary-border-right-color: var(
                        --app-border-color
                    );
                    --hv-button-primary-border-top-color: var(
                        --app-border-color
                    );
                    /* Secondary */
                    --hv-button-secondary-color: var(
                        --background-color-light,
                        gray
                    );
                    --hv-button-secondary-font-color: var(--font-color, #000);
                    --hv-button-secondary-border-left-color: var(
                        --app-border-color
                    );
                    --hv-button-secondary-border-bottom: 6px solid var(--grey);
                    --hv-button-secondary-border-right-color: var(
                        --app-border-color
                    );
                    --hv-button-secondary-border-top-color: var(
                        --app-border-color
                    );
                    --hv-button-secondary-color: var(
                        --background-color-light,
                        gray
                    );

                    border-radius: var(--hv-button-border-radius);
                    height: var(--hv-button-height);
                    font-weight: var(--hv-button-font-weight);
                    text-transform: var(--hv-button-text-transform);
                    font-size: var(--hv-button-font-size);
                }

                :host([disabled]) {
                    filter: grayscale(1);
                    opacity: 0.2;
                }

                @media (hover: hover) {
                    :host(:hover) {
                        filter: brightness(1.2);
                    }
                }

                :host(:active),
                :host([active]) {
                    filter: brightness(0.9);
                }

                :host([disabled]),
                :host([busy]) {
                    background-color: #f6f8f9;
                    color: #95a4ac;
                    filter: none;

                    cursor: default;
                }

                :host([outline]) {
                    border: 2px solid #6b2fad;
                    background-color: transparent;
                    padding: 9px 38px;
                    color: #6b2fad;
                }

                :host([theme='primary']) {
                    background: var(--hv-button-primary-color);
                    color: var(--hv-button-primary-font-color);
                    border-left-color: var(
                        --hv-button-primary-border-left-color
                    );
                    border-bottom: var(--hv-button-primary-border-bottom);
                    border-right-color: var(
                        --hv-button-primary-border-right-color
                    );
                    border-top-color: var(--hv-button-primary-border-top-color);
                }

                :host([theme='secondary']) {
                    background: var(--hv-button-secondary-color);
                    color: var(--hv-button-secondary-font-color);
                    border-left-color: var(
                        --hv-button-secondary-border-left-color
                    );
                    border-bottom: var(--hv-button-secondary-border-bottom);
                    border-right-color: var(
                        --hv-button-secondary-border-right-color
                    );
                    border-top-color: var(
                        --hv-button-secondary-border-top-color
                    );
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        'hv-button': HvButton;
    }
}
