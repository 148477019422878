import {
    PropertyValues,
    TemplateResult,
    customElement,
    html,
    property,
} from 'lit-element';

import { currency } from 'helpers/currency';

import { PAGESIZE } from '@containers/invoice-grid/InvoiceGrid';
import enums from 'enums';
import { watch } from 'lit-redux-watch';
import {
    ExtendedDocument,
    InvoiceInfo,
    InvoiceInvoices,
} from 'types/documents';
import { Snapshot } from 'types/snapshot';
import '../../../../components/elements/grid-component/GridComponent';
import { ListInvoicesDocumentsUserRequestObject } from '../../../../resources/ListInvoicesDocumentsUser';
import {
    listInvoicesDocumentsBundleUser,
    listInvoicesDocumentsUser,
    store,
} from '../../../../store';
import { renderTemplateDate } from '../../helpers/GridRenders';
import { BaseTab } from '../base-tab/BaseTab';

@customElement('invoice-tab')
export class InvoiceTab extends BaseTab {
    @property({ type: String })
    public paymentStatus?: string;

    @property({ type: Boolean })
    public bundle: boolean = false;

    @watch('listInvoicesDocumentsUser.data')
    private invoiceInformation?: InvoiceInvoices;

    @watch('listInvoicesDocumentsBundleUser.data')
    private invoiceBundleInformation?: InvoiceInvoices;

    public render = (): TemplateResult => {
        const { loading } = this;
        return html`
            <style>
                :host {
                    display: flex;
                    flex-direction: column;
                    flex-grow: 1;
                }
                grid-component {
                    --grid__font-size: 14px;
                    min-height: 500px;
                    display: flex;
                    flex-grow: 1;
                }
            </style>
            <grid-component
                title-plural="facturen"
                ?loading="${loading}"
                ?error="${this.error}"
                .items=${this.items}
                .itemsTotal=${this.total}
                id="invoice__grid"
            >
                <grid-column
                    path="document.created_at"
                    header="Periode"
                    .onRender=${renderTemplateDate}
                ></grid-column>
                <grid-column
                    header="Nummer"
                    path="document.invoice_number"
                    ?hidden=${this.isMobile}
                    width="150"
                ></grid-column>
                <grid-column
                    header="Betaald"
                    path="document.total_incl"
                    id="total__paid"
                    .onRender=${this.renderTotalPaid}
                    ?hidden="${this.hideDetailsForMobile}"
                    width="200"
                ></grid-column>
                <grid-column
                    header="Status"
                    path="document.status"
                    id="doc__status"
                    .onRender=${this.renderStatus}
                    width="200"
                ></grid-column>
                <grid-column
                    header="Actie"
                    path="download_links.link"
                    width="140"
                    .onRender=${this.renderTemplateAction}
                ></grid-column>
                <grid-column
                    path="download_links.link"
                    width="140"
                    .onRender=${this.renderTemplateButton}
                ></grid-column>
            </grid-component>
        `;
    };

    protected updated(changedProperties: PropertyValues) {
        super.updated(changedProperties);
        if (
            changedProperties.has('invoiceInformation') &&
            this.invoiceInformation &&
            !this.bundle
        ) {
            this.setInvoiceAndPagiantionData(
                this.invoiceInformation.invoices,
                this.invoiceInformation.pagination,
            );

            this.total = this.invoiceInformation.pagination.total;
        }

        // if invoice bundle needs to be called.
        if (
            changedProperties.has('invoiceBundleInformation') &&
            this.invoiceBundleInformation &&
            this.bundle
        ) {
            this.setInvoiceAndPagiantionData(
                this.invoiceBundleInformation.invoices,
                this.invoiceBundleInformation.pagination,
            );

            this.total = this.invoiceBundleInformation.pagination.total;
        }

        // required for payment notifications
        if (
            changedProperties.has('paymentStatus') &&
            this.paymentStatus != null &&
            this.paymentStatus !== ''
        ) {
            this.paymentStatusChanged(this.paymentStatus);
        }
    }

    // Render logic
    private renderTotalPaid = (
        totalIncl: number,
        invoice: InvoiceInfo,
    ): TemplateResult => {
        const { status } = invoice.document as ExtendedDocument;
        if (status == null || totalIncl == null) {
            return html`${totalIncl}`;
        }

        if (
            enums.InvoiceStatusToBundle === status ||
            enums.InvoiceStatusBundled === status
        ) {
            return html`-`;
        }
        return html`
            <style>
                :host {
                    margin-right: 1.2em;
                }
                div {
                    flex: 1 1 100%;
                }
                div:nth-of-type(2) {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            </style>
            <div>
                ${currency(status === enums.InvoiceStatusPaid ? totalIncl : 0)}
            </div>
            <div>van</div>
            <div>${currency(totalIncl)}</div>
        `;
    };

    // Render logic
    private renderStatus = (status: number): TemplateResult => {
        if (
            enums.InvoiceStatusToBundle === status ||
            enums.InvoiceStatusBundled === status
        ) {
            return html`-`;
        }
        return html`
            <style>
                :host {
                    margin-right: 1.2em;
                }
                div {
                    flex: 1 1 100%;
                }
                div:nth-of-type(2) {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            </style>
            <div>${this.translateStatus(status)}</div>
        `;
    };

    private translateStatus = (status: number): string => {
        switch (status) {
            case 1:
            case 7:
            case 10:
                return `Open`;
            case 4:
                return `Betaald`;
            case 9:
                return `Betaald`;
            case 11:
                return `Gecrediteerd en verrekend`;
            case 12:
                return `In uitbetaling`;
            default:
                return enums.InvoiceStatus[status];
        }
    };

    // function that gets called to fetch the invoice data
    public fetchGridData = (
        requestObj: ListInvoicesDocumentsUserRequestObject,
    ) => {
        const payload = requestObj;
        payload.bundle = this.bundle;

        if (!this.bundle) {
            store.dispatch(listInvoicesDocumentsUser.request(payload));

            return;
        }

        store.dispatch(listInvoicesDocumentsBundleUser.request(payload));
    };

    // render action template if needed
    protected renderTemplateAction = (
        _: string,
        bd: InvoiceInfo,
    ): TemplateResult => {
        if (!this.snapshot) {
            return html``;
        }
        const action = this.isActionRequired(bd, this.snapshot);
        if (!action) {
            return html``;
        }
        return html`
            <style>
                .grid-button {
                    max-width: 116px;
                    max-height: 32px;
                }
                button-component {
                    --button-component--margin: 7px 0 8px;
                    --button-component--height: 32px;
                    --button-component--font-size: 14px;
                }
            </style>
            <button-component
                class="grid-button"
                label="Betalen"
                theme="primary"
                @click="${(e: MouseEvent) => this.handleInvoicePayment(e, bd)}"
            ></button-component>
        `;
    };

    // render button logic
    protected renderTemplateButton = (
        _: string,
        bd: InvoiceInfo,
    ): TemplateResult => html`
        <style>
            .grid-button {
                max-width: 116px;
                max-height: 32px;
            }
            button-component {
                --button-component--margin: 7px 0 8px;
                --button-component--height: 32px;
                --button-component--font-size: 14px;
            }
        </style>
        <button-component
            class="grid-button"
            label="Download"
            theme="primary"
            @click="${(e: MouseEvent) => this.downloadInvoice(e, bd)}"
        ></button-component>
    `;

    // checks if the status requires a action.
    private isActionRequired = (
        bd: InvoiceInfo,
        snapshot: Snapshot,
    ): boolean => {
        if ((bd.document as ExtendedDocument)?.status) {
            const doc = bd.document as ExtendedDocument;
            const { status } = doc;
            if (
                status !== enums.InvoiceStatusPaid &&
                this.snapshot?.payment_method === enums.PaymentMethodIdealLink
            ) {
                return true;
            }
            if (
                !snapshot.has_mandate &&
                snapshot.payment_method ===
                    enums.PaymentMethodWithPaymentAccount
            ) {
                return true;
            }
        }

        return false;
    };

    private handleInvoicePayment = (
        e: MouseEvent,
        invoice: InvoiceInfo,
    ): void => {
        e.stopImmediatePropagation();
        try {
            window.open(
                `${window.API_LINK}/v1/payments/invoice/${invoice.document.id}?returnURL=true`,
                '_blank',
            );
        } catch {
            window.displayMessage(
                'Kon betaallink niet openen, probeer later opnieuw.',
            );
        }
    };

    // status for the current payment
    private paymentStatusChanged = (status: string): void => {
        switch (status) {
            case 'success':
                window.displayMessage('Je betaling is met succes voltooid.');
                break;
            default:
                window.displayMessage(
                    'Er is iets fout gegaan tijdens het betalen, probeer het later opnieuw.',
                );
        }

        this.paymentStatus = undefined;
        this.fetchGridData({
            pageNumber: 1,
            pageSize: PAGESIZE,
            userId: this.user?.id,
            snapshotId: this.snapshot?.id,
        });
    };
}

declare global {
    interface HTMLElementTagNameMap {
        'invoice-tab': InvoiceTab;
    }
}
