import { UUID } from 'goagen-js-resource';
import {
    setSelectedSnapshot,
    SetSelectedSnapshot,
    SetSnapshots,
    SET_JWT_SNAPSHOTS,
    SET_SELECTED_SNAPSHOT,
} from '@actions/snapshot';
import { getUsages } from '@async-reducers/usages';
import { Router } from '@vaadin/router';
import { routePaths } from 'data-access/router';
import enums from 'enums';
import { put, takeLatest } from 'redux-saga/effects';
import { Snapshot } from 'types/snapshot';
import settings from 'internationalization/settings';
import { showOnboarding } from '../async-reducers/settings';
import { vehicleList } from '../async-reducers/vehicle';
import { contractFactory } from '../helpers/classes/Contract';
import { MonthlyValues } from '../helpers/classes/MonthlyValues';
import { datesAreOnSameMonth, getFirstDayOfTheMonth } from '../helpers/dates';
import { isSmartCharging } from '../helpers/smartCharging';
import { latestAllowedPhaseSnapshots } from '../helpers/snapshot';

const routeToPage = (selectedSnapshot: Snapshot): void => {
    // @ts-ignore
    const { searchParams } = new URL(window.location);
    // checks if auth param exists
    if (!searchParams.has('auth')) {
        return;
    }
    if (!selectedSnapshot) {
        return;
    }
    const contract = contractFactory(selectedSnapshot);
    if (contract.contractType !== 'ODA') {
        Router.go(routePaths.login);
        return;
    }

    contract.RouteToPage();
};

/**
 * doSelectedSnapshotCheck
 * @param {Object} action
 */
function* doSelectedSnapshotCheck(action: SetSnapshots) {
    const { selectedSnapshot } = window.store.getState().jwtSnapshots;

    if (action.snapshots.length > 0) {
        let energySnapshots = action.snapshots?.filter(
            (snapshot) => !isSmartCharging(snapshot),
        );
        const smartChargingSnapshots = action.snapshots?.filter((snapshot) =>
            isSmartCharging(snapshot),
        );

        // Fetch data for smart charging contract
        if (smartChargingSnapshots.length > 0) {
            yield put(vehicleList.run(smartChargingSnapshots[0].id));
        }

        energySnapshots = latestAllowedPhaseSnapshots(energySnapshots);
        if (energySnapshots.length > 0) {
            if (selectedSnapshot) {
                // if dashboard id has been passed in the url set the selected snapshot by dashboard snapshot id
                const snapshotID =
                    window.store.getState().jwtSnapshots.dashboardSnapshotID;

                const found = energySnapshots.find(
                    (s) =>
                        s.id ===
                        (snapshotID !== null
                            ? snapshotID
                            : selectedSnapshot.id),
                );
                if (found) {
                    yield put(setSelectedSnapshot(found));
                    return;
                }
                yield put(setSelectedSnapshot(energySnapshots[0]));
                return;
            }
            yield put(setSelectedSnapshot(energySnapshots[0]));
        }
        yield routeToPage(selectedSnapshot);
    }
}

const fetchUsages = (snapshot: Snapshot) => {
    if (
        snapshot == null ||
        snapshot.snapshot_phase === 900 ||
        snapshot.snapshot_phase < enums.SnapshotPhaseSmartMeterChecked
    ) {
        return;
    }
    // prevent fetching usages if usages is already busy for the same snapshot
    const usages = window.store.getState().getUsages;
    if (
        usages &&
        ((usages.query && usages.query[0].snapshotID === snapshot.id) ||
            usages.data?.requestObject?.snapshotID === snapshot.id)
    ) {
        return;
    }

    const today = new Date(Date.now());
    const endDate = snapshot.verified_snapshot_payload.end_date
        ? new Date(snapshot.verified_snapshot_payload.end_date)
        : new Date(today.getFullYear(), today.getMonth() + 1, 0);
    const startDate = new Date(snapshot.verified_snapshot_payload.start_date);
    const monthlyValues = new MonthlyValues(snapshot.id, startDate, endDate);
    const params = monthlyValues.get();
    // check to only fetch usages from the current month
    if (!datesAreOnSameMonth(params.dateFrom, new Date())) {
        params.dateFrom = getFirstDayOfTheMonth(new Date()).toISOString();
    }
    window.store.dispatch(getUsages.run(params));
};

const selectedSnapshotTypeCheck = (snapshot: Snapshot) => {
    const contract = contractFactory(snapshot);
    const { skipped } = window.store.getState().setOdaOnboarding.data;
    switch (contract.contractType) {
        case 'ODA':
            contract.RouteToPage(skipped);
            break;
        default:
            break;
    }
};

function* handleSelectedSnapshot(action: SetSelectedSnapshot) {
    const selectedSnapshot = action.snapshot;
    yield selectedSnapshotTypeCheck(selectedSnapshot);
    yield fetchUsages(selectedSnapshot);

    // check if the vendor has its own supplier and if the selected snapshot his vendorID is allowed to create a contract in the onboarding
    if (selectedSnapshot && settings.exceptOnlyVendorIDs.length) {
        const ownSupplierFound = settings.exceptOnlyVendorIDs.find(
            (supplierID) =>
                supplierID === (selectedSnapshot.vendor_id as UUID | unknown),
        );
        yield put(showOnboarding.run(!!ownSupplierFound));
        return;
    }

    yield put(showOnboarding.run(true));
}

/**
 * Watcher for SET_JWT_SNAPSHOTS action
 */
export function* watchSetJWTSnapshots() {
    yield takeLatest(SET_JWT_SNAPSHOTS, doSelectedSnapshotCheck);
}
/**
 * Watcher for SET_SELECTED_SNAPSHOT action
 */
export function* watchSetSelectedSnapshot() {
    yield takeLatest(SET_SELECTED_SNAPSHOT, handleSelectedSnapshot);
}
