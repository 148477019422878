import { css } from 'lit-element';

export default css`
    :host {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        overflow: auto;
        width: 100%;

        --tab--color: inherit;
        --tab--height: inherit;
        --tab__font--color: inherit;

        --tab__font--size: 14px;
        --tab__border: 1px solid #ccc;
        --tab__border--bottom: inherit;
        --tab__padding: 14px 16px;
        --tab__background--color: #eef4f9;

        /* hover states */
        --tab__hover--background--color: #ddd;
        --tab__font--hover-color: inherit;

        /* active states */
        --tab__font--active-color: inherit;
        --tab__font--active-weight: 400;
        --tab__active--color: #ccc;
        --tab__border--active: inherit;
        --tab__border--bottom--active: inherit;
    }

    :host([hidden]) main {
        visibility: hidden;
        height: 0;
    }
    :host([hidden]) invoice-grid {
        visibility: hidden;
        height: 0;
    }

    :host([theme='center']) header .tab {
        justify-content: center;
        align-items: center;
    }
    /**
     * Style the header.
     */
    header {
        display: flex;
        height: var(--tab--height);
    }
    header .tab {
        display: flex;
        flex-grow: 1;
        overflow: hidden;
        border: var(--tab__border);
        background-color: var(--tab--color);
        float: left;
        outline: none;
        cursor: pointer;
        padding: var(--tab--padding);
        transition: 0.3s;
        font-size: var(--tab__font--size);
        border-bottom: var(--tab__border--bottom);
        text-decoration: none;
        width: 100%;
        color: var(--tab__font--color);
    }

    /* Change background color of buttons on hover */
    header .tab:hover {
        background-color: var(--tab__hover--background--color);
        color: var(--tab__font--hover-color);
    }

    /* Create an active/current tablink class */
    header .tab__state--active {
        background-color: var(--tab__active--color);
        color: var(--tab__font--active-color);
        font-weight: var(--tab__font--active-weight);
        border: var(--tab__border--active);
        border-bottom: var(--tab__border--bottom--active);
    }

    /**
     * Style the main content
     */

    main {
        overflow-y: auto;
        // overflow-x: hidden;
    }
    /* Selects any element placed inside a slot */
    main ::slotted(*) {
        display: none;
        animation: fade-out 5s;
    }

    main ::slotted(.slot__active) {
        display: block;
        animation: fade-in 1s;
    }

    main ::slotted(*)[hidden] {
        display: none;
        height: 0;
    }

    /* Fade in tabs */
    @keyframes fade-in {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    @keyframes fade-out {
        from {
            opacity: 1;
        }
        to {
            opacity: 0;
        }
    }
`;
