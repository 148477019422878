import { Router } from '@vaadin/router';
import { provider } from '@weavelab/frontend-connect';
import enums from '../enums';
import { routePaths } from '../helpers/routing';
import { store } from '../store';
import { updateUserAuthenticated } from './app';
import { setError } from './error';
import { awaitLogin } from './routing';
import { resetSnapshots, setSnapshots } from './snapshot';

export const REQUEST_USER = 'REQUEST_USER';
export const RECEIVE_USER = 'RECEIVE_USER';
export const HAS_DATA_USER = 'HAS_DATA_USER';
export const USER_LOG_OUT = 'USER_LOG_OUT';
const settings = provider.settings('app');

export const PREFIX = 'User: ';

export const AuthAction = 'AUTH';
export const AuthActionLogin = 'LOGIN';
export const AuthActionLogout = 'LOGOUT';
const AuthActionLoggingIn = 'LOGGING_IN';

export const unAuthAction = {
    type: AuthAction,
    action: AuthActionLogout,
};

export const authActionStart = {
    type: AuthAction,
    action: AuthActionLoggingIn,
};

/**
 * authenticatedAction dispatch results into setting local storage and STDHeaders
 * @param {String} bearer for API requests
 * @returns {Object}
 */
export const authenticatedAction = (bearer) => ({
    type: AuthAction,
    action: AuthActionLogin,
    bearer,
});

/**
 * Receive user action for state reducer
 * @param {User} user
 * @return {Object}
 */
export const receiveUser = (user) => ({
    type: RECEIVE_USER,
    user,
});

export const userHasData = () => ({
    type: HAS_DATA_USER,
});

export const logOut = () => ({
    type: USER_LOG_OUT,
});

/**
 * Force user to open snapshot if found and return true if routed
 * @param {Array?} snapshots
 * @return {boolean}
 */
export const forcedUserToSnapshotIfOpen = (snapshots) => {
    if (snapshots && Array.isArray(snapshots)) {
        /** @type {boolean} */
        let foundAbovePhaseUserAgreement = false;
        /** @type {boolean} */
        let foundAbovePhaseUsageChecked = false;
        /** @type {string?} */
        let gotoSnapshotId = null;
        snapshots.forEach(
            /**
             * @param {Object} snapshot
             */
            (snapshot) => {
                const localAbovePhaseUserAgreement =
                    snapshot.snapshot_phase >= enums.SnapshotPhaseUserInput;
                const localAbovePhaseUsageChecked =
                    snapshot.snapshot_phase >= enums.SnapshotPhaseUsageChecked;

                foundAbovePhaseUserAgreement =
                    localAbovePhaseUserAgreement ||
                    foundAbovePhaseUserAgreement;
                foundAbovePhaseUsageChecked =
                    localAbovePhaseUsageChecked || foundAbovePhaseUsageChecked;

                if (
                    localAbovePhaseUserAgreement &&
                    !localAbovePhaseUsageChecked
                ) {
                    gotoSnapshotId = snapshot.id;
                }
            },
        );
        if (foundAbovePhaseUserAgreement && !foundAbovePhaseUsageChecked) {
            Router.go(`${routePaths.customerOverview}/${gotoSnapshotId}`);

            return true;
        }
    }
    return false;
};

/**
 * Updates user onboarding, state reducer action
 * @param {User} user
 * @param {Object} [snapshots]
 * @return {function}
 */
export const userOnboardingFinished =
    (user) =>
    // @ts-ignore
    (dispatch) => {
        // Todo: refactor onboarding
        user.onboarding_finished = true;
        dispatch(receiveUser(user));
        dispatch(updateUserAuthenticated(true));
    };
// TODO: Deprecate afther this sprint (5)
export const receiveSelf =
    () =>
    // @ts-ignore
    (dispatch) => {
        console.warn('OLD Route: receive self');
        dispatch(authActionStart);
        fetch(`${API_LINK}/v1/jwt/refresh`, {
            method: 'GET',
            headers: STDHeaders,
        })
            .then((res) => {
                if (
                    localStorage.getItem('auth') != null &&
                    (res.status < 200 || res.status > 299)
                ) {
                    dispatch(unAuthAction);
                } else if (res.status >= 200 && res.status < 300) {
                    const bearer = res.headers.get('authorization');
                    if (bearer != null) {
                        dispatch(updateUserAuthenticated(false));
                        // dispatch(authenticatedAction(bearer));
                    } else {
                        dispatch(updateUserAuthenticated(false));
                    }
                    return res.json();
                } else if (res.status === 401) {
                    dispatch(unAuthAction);
                }
            })
            .then((appState) => {
                if (
                    appState === undefined ||
                    appState.user === undefined ||
                    appState.user.email === undefined
                ) {
                    return;
                }
                const { user } = appState;
                const { snapshots } = appState;
                const { contracts } = appState;

                dispatch(receiveUser(user));
                if (contracts && contracts.length > 0) {
                    dispatch(userOnboardingFinished(user));
                }
                if (appState && Array.isArray(snapshots)) {
                    dispatch(setSnapshots(snapshots));
                } else {
                    dispatch(resetSnapshots());
                }
            })
            .catch((e) => {
                console.warn('error recieve self', e);
                dispatch(setError('Ophalen van gebruikers informatie mislukt'));
            });
    };

/**
 * Sign in action for user, updates state
 * @param {string} email
 * @param {string} password
 * @param {typeof routePaths} page
 * @return {Promise<any>}
 */
// TODO: Deprecate afther this sprint (5)
export const signIn =
    (email, password, page = routePaths.login) =>
    async (dispatch) => {
        console.warn('OLD Route: signIn');
        // in de store
        const loginHeaders = new Headers();
        loginHeaders.append('Accept', 'application/json');
        loginHeaders.append('Content-type', 'application/json');
        loginHeaders.append(
            'Authorization',
            `Basic ${btoa(`${email}:${password}`)}`,
        );

        let params = '';
        const signinEndPoint = `${API_LINK}/v1/jwt/signin`;
        if (settings?.appDomain && settings.appDomain !== '') {
            params += `EZVendor=${settings.appDomain}`;
        } else if (settings?.appDomain && settings.appTitle !== '') {
            params += `EZVendor=${settings.appTitle}`;
        }
        if (settings?.vendorID && settings.vendorID !== '') {
            if (params.length > 0) {
                params = `?${params}&`;
            }
            if (params.length === 0) {
                params = '?';
            }
            params += `vendorID=${settings.vendorID}`;
        }

        try {
            dispatch(authActionStart);
            const res = await fetch(signinEndPoint + params, {
                method: 'POST',
                headers: loginHeaders,
            });
            if (res.status >= 200 && res.status < 300) {
                const bearer = res.headers.get('authorization');
                if (bearer != null) {
                    dispatch(updateUserAuthenticated(true));
                    // dispatch(authenticatedAction(bearer));
                }
            } else {
                if (page === routePaths.lander) {
                    Router.go(routePaths.lander);
                    return;
                }
                Router.go(routePaths.overview);
            }
            // wait for the json response.
            const appState = await res.json();
            const { user, snapshots, contracts } = appState;
            user.contracts = contracts;

            if (appState.code === 'unauthorized') {
                return dispatch(setError('E-mail en/of wachtwoord onjuist'));
            }
            if (user && user.id && Array.isArray(snapshots)) {
                user = { ...user, snapshots };
            }
            if (user.contracts && user.contracts.length > 0) {
                dispatch(userOnboardingFinished(user));
            }
            if (!user.contracts && !user.snapshots) {
                dispatch(updateUserAuthenticated(false));
            }
            if (appState && Array.isArray(snapshots)) {
                dispatch(setSnapshots(snapshots));
            } else {
                dispatch(resetSnapshots());
            }
            setTimeout(() => {
                store.dispatch(setReceiveSelfBusy(false));
            }, 1000);
            store.dispatch(awaitLogin(false));
            return dispatch(receiveUser(user));
        } catch (e) {
            setTimeout(() => {
                store.dispatch(setReceiveSelfBusy(false));
            }, 1000);
            store.dispatch(awaitLogin(false));
            return dispatch(setError('Inloggen mislukt, probeer opnieuw!'));
        }
    };

/**
 * Update user action for state reducer
 * @param {Object} userPayload
 * @param {string} userID
 * @return {Promise<any>}
 */
export const updateUser = (userPayload, userID) => async (dispatch) => {
    try {
        const res = await fetch(`${API_LINK}/v1/user/update/${userID}`, {
            method: 'POST',
            headers: STDHeaders,
            body: JSON.stringify(userPayload),
        });
        const data = await res.json();
        if (data.code === 'BadRequest') {
            return dispatch(
                setError('Unable to update user, please try again'),
            );
        }
        return dispatch(receiveUser(data));
    } catch (e) {
        return dispatch(setError('Unable to update user, please try again'));
    }
};

/**
 * Action that can be resolved for state reducer
 * @param {User} user
 * @return {function}
 */
export const setUser =
    (user) =>
    // @ts-ignore
    (dispatch) =>
        dispatch(receiveUser(user));

/**
 * @typedef {Object} CreateUserPayload
 */

export const CLEAR_CREATE_USER_QUERY = `${PREFIX}CLEAR_CREATE_USER_QUERY`;
/**
 * Action generator to clear the sent create user query
 * @return {Object}
 */
export const clearCreateUserQuery = () => ({ type: CLEAR_CREATE_USER_QUERY });

export const REQUEST_CREATE_USER = `${PREFIX}REQUEST_CREATE_USER`;
/**
 * Action generator for request create user
 * @param {CreateUserPayload} payload
 * @param {String} vendorId
 * @param {Number} userType
 * @return {Object}
 */
export const requestCreateUser = (
    payload,
    vendorId,
    userType = enums.UserTypeConsumer,
) => ({
    type: REQUEST_CREATE_USER,
    payload,
    vendorId,
    userType,
});

export const REQUEST_CREATE_USER_SUCCESS = `${PREFIX}REQUEST_CREATE_USER_SUCCESS`;
/**
 * Success callback action for create user
 * @param {Object} data
 * @return {Object}
 */
export const requestCreateUserSuccess = (data) => ({
    type: REQUEST_CREATE_USER_SUCCESS,
    data,
});

export const REQUEST_CREATE_USER_FAILED = `${PREFIX}REQUEST_CREATE_USER_FAILED`;
/**
 * Error callback action for create user
 * @param {Object} error
 * @return {Object}
 */
export const requestCreateUserFailed = (error) => ({
    type: REQUEST_CREATE_USER_FAILED,
    error,
});

export const RESET_CREATE_USER = `${PREFIX}RESET_CREATE_USER`;
/**
 * Action generator to reset create user
 * @return {Object}
 */
export const resetCreateUser = () => ({ type: RESET_CREATE_USER });
