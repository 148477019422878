import {
    REQUEST_INVOICES,
    REQUEST_INVOICES_SUCCESS,
    REQUEST_INVOICES_FAILED,
} from '../actions/invoice';

const defaultInvoices = { data: [], error: null };

/**
 * State reducer for invoices request
 * @param {Object} state
 * @param {Object} action
 * @return {Object}
 */
export const invoices = (state = { ...defaultInvoices }, action) => {
    switch (action.type) {
        case REQUEST_INVOICES:
            return state;
        case REQUEST_INVOICES_SUCCESS:
            return { ...state, data: action.data, error: null };
        case REQUEST_INVOICES_FAILED:
            return { ...state, error: action.error };
        default:
            return state;
    }
};
