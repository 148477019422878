import {
    customElement,
    property,
    PropertyValues,
    query,
    queryAll,
    state,
} from 'lit-element';

import { css, html, ifDefined, TemplateResult } from '@lion/core';

import { connect, watch } from 'lit-redux-watch';

import '@templates/oda-onboarding-step-wrapper/OdaOnboardingStepWrapper';
import { WeaveStep } from '@components/organism/stepper/WeaveStep';
import { Snapshot } from 'types/snapshot';
import '@lion/form';
import '@lion/fieldset/lion-fieldset';
import { geoCodeCheck } from '@async-reducers/geocode';
import { Pattern, MinLength, MaxLength, Required } from '@lion/form-core';
import { geocodecheck } from 'types/geocode';
import { selectOdaSnapshot } from '@selectors/snapshot';
import '@atoms/Input';
import { initOda } from '@async-reducers/oda';
import { asyncReducerResolver } from '@async-reducers/helpers/asyncReducerResolver';
import {
    POSTAL_CODE_PATTERN,
    HOUSE_NUMBER_ADDITION_PATTERN,
} from '../../helpers/regex';
import { store } from '../../store';
import { debounce } from '../../helpers/functions';

const HOUSE_NUMBER_MIN_LENGTH = 1;
const HOUSE_NUMBER_MAX_LENGTH = 5;

@customElement('oda-postcode-step')
export class OdaPostcodeStep extends connect(store)(WeaveStep) {
    @watch(selectOdaSnapshot)
    private snapshot?: Snapshot;

    @watch('geoCodeCheck.error.message')
    public geoCodeResultError?: string;

    @watch('geoCodeCheck.data')
    public geoCodeResultData?: geocodecheck;

    @watch('initOda.busy')
    public busy: boolean = false;

    @watch('initOda.error.message')
    public initOdaError?: string;

    @property({ type: Boolean })
    private hasData: boolean = false;

    @property({ type: Boolean })
    private disabledState: boolean = true;

    @watch('initOda.data.smp_id')
    public initOdaDataSmpID?: string;

    @state()
    private postalCode: string = '';

    @state()
    private houseNumber: string = '';

    @state()
    private houseNumberAddition: string = '';

    @queryAll('hv-input')
    private inputElements?: HTMLElementTagNameMap['hv-input'][];

    @query('hv-input[name="postalCode"]')
    private postalCodeInput!: HTMLElementTagNameMap['hv-input'];

    @query('hv-input[name="houseNumber"]')
    private houseNumberInput!: HTMLElementTagNameMap['hv-input'];

    @query('hv-input[name="houseNumberAddition"]')
    private houseNumberAdditionInput!: HTMLElementTagNameMap['hv-input'];

    // When appSettings onboarding is false we should disable the form fields
    @property({ type: Boolean })
    private formDisabled: boolean = false;

    public debouncedFetch = debounce(1000, () => {
        this.fetchAddress();
    });

    static get styles() {
        return [
            ...super.styles,
            css`
                :host {
                    --oda-onboarding-helper-text-font-size: 14px;
                    --oda-onboarding-helper-margin-top: 15px;
                }
                lion-fieldset {
                    display: flex;
                    flex-direction: row;
                }

                #postalCode,
                #houseNumber {
                    margin-right: 12px;
                }

                #postalCode {
                    --hv-input_-_input_-_max-width: 201px;
                }

                #houseNumber,
                #houseNumberAddition {
                    --hv-input_-_input_-_max-width: 110px;
                }

                form {
                    display: flex;
                }

                .success,
                .error,
                .helper {
                    font-size: var(--oda-onboarding-helper-text-font-size);
                    line-height: 20px;
                }

                .success {
                    color: #9ccc65;
                }

                .error {
                    color: #ef5350;
                }

                .warning {
                    color: #ea9700;
                }
                .helper {
                    margin-top: var(--oda-onboarding-helper-margin-top);
                    color: #3aad3a;
                }
                .helper.error {
                    color: #ea9700;
                }
            `,
        ];
    }

    connectedCallback(): void {
        super.connectedCallback();
        this.addEventListener('enter', this.handleStepEnter);
    }

    disconnectedCallback(): void {
        this.removeEventListener('enter', this.handleStepEnter);
        super.disconnectedCallback();
    }

    handleStepEnter(): void {
        if (
            this.snapshot?.delivery_location?.area_code &&
            this.snapshot?.delivery_location?.house_number
        ) {
            this.geoCodeResultData = undefined;
            window.displayMessage(
                'Je leveringsadres is al bekend in ons systeem',
                'succes',
            );

            this.debouncedFetch();
        }
    }

    renderHelper = () => {
        if (!this.hasData) {
            return html``;
        }
        // if this specific error, show this message
        if (this.initOdaError === 'failed because edsn is down') {
            return html`<p class="helper error">
                Helaas, het landelijk energiesysteem is momenteel niet
                bereikbaar voor ons. We kunnen nu geen auhtenticatie van het
                opgegeven meternummer doen. Klik op “Dit doe ik later” en
                probeer het later opnieuw.
            </p>`;
        }
        // for any other
        if (this.initOdaError?.length) {
            return html`<p class="helper error">
                Er is helaas iets mis gegaan. Probeer het later nog eens.
            </p>`;
        }

        return html``;
    };

    public render = (): TemplateResult => {
        const { busy, formDisabled, geoCodeResultData, geoCodeResultError } =
            this;
        const {
            postalCodeChange,
            houseNumberChange,
            houseNumberAdditionChange,
            preventDefault,
            successRender,
            errorRender,
            renderHelper,
        } = this;
        const isSuccessful = this.postcodeErrorAndSuccesCheck(
            geoCodeResultData,
            geoCodeResultError,
        );
        return html`
            <oda-onboarding-step-wrapper
                nextText="Adres toevoegen"
                ?disable-navigation=${this.disabledState}
            >
                <main slot="title">Vul je leveringsadres in</main>
                <main slot="introduction">
                    Geef het adres op waarvan je het energieverbruik wil volgen.
                    Vul hier je postcode en huisnummer in en we vullen de rest
                    van de adresgegevens automatisch aan.
                </main>
                <div class="content">
                    <h4>Wat is je adres?</h4>
                    <lion-form>
                        <form class="signInForm" @submit=${preventDefault}>
                            <hv-input
                                id="postalCode"
                                label="Postcode"
                                name="postalCode"
                                type="text"
                                placeholder="1234AB"
                                autocomplete="postalCode"
                                autocapitalize="on"
                                ?disabled="${busy || formDisabled}"
                                ?succes="${ifDefined(isSuccessful)}"
                                ?warning="${ifDefined(!isSuccessful)}"
                                @user-input-changed="${postalCodeChange}"
                                .validators="${[
                                    new Required('', {
                                        getMessage: async () => 'Verplicht',
                                    }),
                                    new Pattern(POSTAL_CODE_PATTERN, {
                                        getMessage: async () =>
                                            'Vul een geldig postcode in. Bestaande uit 4 cijfers en 2 letters. Voorbeeld: 1234AB',
                                    }),
                                ]}"
                            >
                            </hv-input>
                            <hv-input
                                id="houseNumber"
                                label="Huisnr."
                                name="houseNumber"
                                type="number"
                                autocomplete="houseNumber"
                                placeholder="123"
                                ?disabled="${busy || formDisabled}"
                                ?succes="${ifDefined(isSuccessful)}"
                                ?warning="${ifDefined(!isSuccessful)}"
                                @user-input-changed="${houseNumberChange}"
                                .validators="${[
                                    new Required('', {
                                        getMessage: async () => 'Verplicht',
                                    }),
                                    new MinLength(HOUSE_NUMBER_MIN_LENGTH, {
                                        getMessage: async () =>
                                            'Vul een geldig huisnummer in.',
                                    }),
                                    new MaxLength(HOUSE_NUMBER_MAX_LENGTH, {
                                        getMessage: async () =>
                                            'Het huisnummer mag maximaal bestaan uit 5 cijfers.',
                                    }),
                                ]}"
                            >
                            </hv-input>
                            <hv-input
                                id="houseNumberAddition"
                                label="Toev."
                                name="houseNumberAddition"
                                type="text"
                                placeholder="A"
                                autocomplete="houseNumberAddition"
                                ?disabled="${busy || formDisabled}"
                                ?succes="${ifDefined(isSuccessful)}"
                                ?warning="${ifDefined(!isSuccessful)}"
                                @user-input-changed="${houseNumberAdditionChange}"
                                .validators="${[
                                    new Pattern(HOUSE_NUMBER_ADDITION_PATTERN, {
                                        getMessage: async () =>
                                            'Huisnummertoevoeging mag alleen bestaan uit cijfers en letters.',
                                    }),
                                ]}"
                            >
                            </hv-input>
                        </form>
                    </lion-form>
                    ${successRender(geoCodeResultData)}
                    ${errorRender(geoCodeResultError)} ${renderHelper()}
                </div>
            </oda-onboarding-step-wrapper>
        `;
    };

    // checks if succes is true or if there is an error
    private postcodeErrorAndSuccesCheck = (
        geoCodeResultData: geocodecheck | undefined,
        geoCodeResultError: string | undefined,
    ): boolean | undefined => {
        if (geoCodeResultError && geoCodeResultError !== '') {
            return false;
        }
        if (geoCodeResultData && Object.keys(geoCodeResultData).length !== 0) {
            return true;
        }

        return undefined;
    };

    /**
     * Fetch address triggers the geo code check action
     */
    private fetchAddress = (): void => {
        store.dispatch(
            geoCodeCheck.run(
                this.postalCode,
                this.houseNumber,
                this.houseNumberAddition,
            ),
        );
    };

    private successRender = (
        geoCodeResultData?: geocodecheck,
    ): TemplateResult | null =>
        geoCodeResultData
            ? html`
                  <span class="success"
                      >${geoCodeResultData.street_name}
                      ${geoCodeResultData.house_number}
                      ${this.houseNumberAddition}, ${this.postalCode}
                      ${geoCodeResultData.city}</span
                  >
              `
            : null;

    private errorRender = (error?: string): TemplateResult | null =>
        error
            ? html`<span class="warning"
                  >Het opgegevens adres is niet gevonden in het landelijk
                  adressysteem. Controleer nogmaals of alles goed is
                  ingevuld.</span
              >`
            : null;

    /**
     * Lifecyle
     */
    public updated = (changedProperties: PropertyValues): void => {
        // When the snapshot changes make sure we use the delivery location values and prefilled form values
        if (
            changedProperties.has('snapshot') &&
            this.snapshot &&
            this.snapshot?.delivery_location?.area_code &&
            this.snapshot?.delivery_location?.house_number &&
            !this.geoCodeResultData
        ) {
            this.houseNumber =
                this.snapshot.delivery_location.house_number.toString();
            this.houseNumberInput.modelValue =
                this.snapshot.delivery_location.house_number.toString();

            this.postalCode = this.snapshot.delivery_location.area_code;
            this.postalCodeInput.modelValue =
                this.snapshot.delivery_location.area_code;

            this.houseNumberAddition =
                this.snapshot.delivery_location.house_addition;
            this.houseNumberAdditionInput.modelValue =
                this.snapshot.delivery_location.house_addition;

            window.displayMessage(
                'Je leveringsadres is al bekend in ons systeem.',
                'succes',
            );

            this.debouncedFetch();
        }

        if (
            (changedProperties.has('snapshot') ||
                changedProperties.has('geoCodeResultData')) &&
            this.snapshot &&
            this.geoCodeResultData
        ) {
            if (
                this.snapshot?.delivery_location?.area_code &&
                this.snapshot?.delivery_location?.house_number &&
                this.geoCodeResultData
            ) {
                this.disabledState = false;
                this.formDisabled = true;
            }
        }
    };

    /**
     * Weave step code implemented
     */
    validate = async (): Promise<boolean> => {
        if (!this.snapshot) {
            return false;
        }
        if (this.geoCodeResultData !== undefined && !this.geoCodeResultError) {
            window.displayMessage(
                `${this.geoCodeResultData.street_name} ${this.geoCodeResultData.house_number} ${this.houseNumberAddition} in ${this.geoCodeResultData.city} is succesvol toegevoegd.`,
                'succes',
            );

            window.store.dispatch(initOda.run(this.snapshot.id));
            await asyncReducerResolver(this);
            this.hasData = true;
            if (this.initOdaDataSmpID?.length) {
                return true;
            }
        }
        return false;
    };

    /**
     * Input watchers
     */
    public triggerFetch = (_el: HTMLElementTagNameMap['hv-input']): void => {
        if (!this.inputElements) {
            return;
        }
        for (const inputEl of this.inputElements) {
            if (inputEl.hasFeedbackFor.includes('error')) {
                this.disabledState = true;
                return;
            }
        }
        this.disabledState = false;
        this.debouncedFetch();
    };

    private postalCodeChange = (): void => {
        this.postalCode = this.postalCodeInput.modelValue;
        this.triggerFetch(this.postalCodeInput);
    };

    private houseNumberChange = (): void => {
        this.houseNumber = this.houseNumberInput.modelValue;
        this.triggerFetch(this.houseNumberInput);
    };

    private houseNumberAdditionChange = (): void => {
        this.houseNumberAddition = this.houseNumberAdditionInput.modelValue;
        this.triggerFetch(this.houseNumberAdditionInput);
    };

    private preventDefault = (e: Event): void => {
        e.preventDefault();
    };
}

declare global {
    interface HTMLElementTagNameMap {
        'oda-postcode-step': OdaPostcodeStep;
    }
}
