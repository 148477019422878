import { css } from 'lit-element';

export default css`
    :host {
        --hv-notification--font-size: 15px;
        --hv-notification-icon-size: 25px;
        --hv-notification-background-color: #ffe896;
        --hv-notification--animation-speed: 250ms;
        --hv-notification--margin: 0;
        --hv-notification--padding: 24px;
        --hv-notification--width: 100%;
        --hv-notification--icon--width: 24px;
        --hv-notification--justify-content: left;
        --hv-notification-background-color--success: #f2faf2;
        --hv-notification-background-color--info: #dbeeff;
        --hv-notification-background-color--warning: #fff6e5;
        --hv-notification-text-color--success: #3aad3a;
        --hv-notification-text-color--info: #006bce;
        --hv-notification-text-color--warning: #ea9700;
        --hv-notification-font-weight: 500;
        --hv-notification-line-height: 1.3;
    }

    .notification-bar {
        width: var(--hv-notification--width);
        margin: var(--hv-notification--margin);
        padding: var(--hv-notification--padding);
        line-height: 1;
        box-sizing: border-box;
        color: var(--broken-black);
        font-size: var(--hv-notification--font-size);
        background-color: var(--hv-notification-background-color);
        transition: var(--notification--animation);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: var(--hv-notification--justify-content);
        animation: fadein var(--hv-notification--animation-speed);
        -webkit-animation: fadein var(--hv-notification--animation-speed); /* Safari and Chrome */
    }

    p {
        line-height: var(--hv-notification-line-height);
        font-size: var(--hv-notification--font-size);
    }

    .notification-bar[type='success'] {
        --hv-notification-background-color: var(
            --hv-notification-background-color--success
        );
    }

    .notification-bar[type='info'] {
        --hv-notification-background-color: var(
            --hv-notification-background-color--info
        );
    }

    .notification-bar[type='warning'] {
        --hv-notification-background-color: var(
            --hv-notification-background-color--warning
        );
    }

    @-webkit-keyframes fadein {
        from {
            padding: 0;
            height: 0;
            -webkit-opacity: 0;
            -webkit-transform: translateY(-50px);
        }
        to {
            padding: var(--hv-notification--padding);
            height: auto;
            -webkit-opacity: 1;
            -webkit-transform: translateY(0);
        }
    }
    @keyframes fadein {
        /* Firefox */
        from {
            padding: 0;
            height: 0;
            opacity: 0;
            transform: translateY(-50px);
        }
        to {
            padding: var(--hv-notification--padding);
            height: auto;
            opacity: 1;
            transform: translateY(0);
        }
    }

    .notification-bar([hidden]) {
        padding: 0;
        animation: fadeOut ease var(--hv-notification--animation-speed);
        transition-timing-function: ease-out;
        /* A litttttle slower on the way in */
        transition: 0.25s;
        /* Move into place */
        transform: translateY(0);
    }

    ::slotted(*) {
        color: var(--broken-black) !important;
    }

    ::slotted(a:hover) {
        opacity: 0.5;
        transition: var(--notification--animation);
    }

    ::slotted(a:focus) {
        opacity: 1;
        transition: var(--notification--animation);
    }

    .icon[type='default'] {
        margin-right: var(--hv-notification--icon--width);
    }

    .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 15px;
    }

    .notification-bar__body {
        display: flex;
        align-items: center;
        padding: 0 10px;
    }

    .notification-bar__body p[type='success'] {
        color: var(--hv-notification-text-color--success);
        font-weight: var(--hv-notification-font-weight);
    }

    .notification-bar__body p[type='info'] {
        color: var(--hv-notification-text-color--info);
        font-weight: var(--hv-notification-font-weight);
    }

    .notification-bar__body p[type='warning'] {
        color: var(--hv-notification-text-color--warning);
        font-weight: var(--hv-notification-font-weight);
    }

    button-component {
        --button-component--font-size: 15px;
        --button-component--font-weight: 600;
        --button-component--border-radius: 0px;
    }

    button-component[type='success'] {
        --button-component--background: var(
            --hv-notification-text-color--success
        );
    }

    button-component[type='info'] {
        --button-component--background: var(--hv-notification-text-color--info);
    }

    button-component[type='warning'] {
        --button-component--background: var(
            --hv-notification-text-color--warning
        );
    }

    hv-icon {
        --hv-icon-font-size: var(--hv-notification-icon-size);
    }

    hv-icon[class='thumb'] {
        --hv-icon-color: var(--hv-notification-text-color--success);
    }

    hv-icon[class='info'] {
        --hv-icon-color: var(--hv-notification-text-color--info);
    }

    hv-icon[class='warning'] {
        --hv-icon-color: var(--hv-notification-text-color--warning);
    }
`;
