import {
    LitElement,
    TemplateResult,
    customElement,
    html,
    css,
} from 'lit-element';

import '@organism/stepper/WeaveStepper';
import '@organism/stepper/WeaveStep';

import '@views/smart-charging/onboarding-steps/CarConnectExplanation';
import '@views/smart-charging/onboarding-steps/CarConnectOauth';
import '@views/smart-charging/onboarding-steps/CarConnectSuccessfully';

/**
 *  View responsible for showing the smart charging onboarding stepper
 *  This component should probably connect to redux state
 *
 *  The stepper works not with page routing out of the box. You cloud
 *  hook up routing with stepper by catching route and translating the route name
 *  to this.startingStep. Maybe with a look up tabel "/routname" : 0, "/routname2" : 1
 */

@customElement('view-smart-charging-onboarding')
export class SmartChargingOnboardStepper extends LitElement {
    // Sets the starting step for the stepper (starts from 0),
    // example if you want to skip the first 2 step startingStep = 2,
    // TODO Connect this property to redux state,
    private startingStep: number = 0;

    static get styles() {
        return [
            css`
                :host,
                weave-stepper {
                    display: flex;
                }
            `,
        ];
    }

    /**
     * Validator function for a step example: <weave-step .validate="${this.step1validator></weave-step
     * This function gets called before you go to the next step. Must return true to go to the next step.
     * If it returns false then it will halt.
     *
     * You should connect this component to redux to get validation state from one step
     *
     * If the validator function gets bloated and needs lots of redux properties the a
     * step should gets its own component each with its own validator function
     */
    validatorViewCarConnectExplanation(): boolean {
        console.log('validatorViewCarConnectExplanation');
        return true;
    }

    validatorViewCarConnectOauth(): boolean {
        console.log('validatorViewCarConnectOauth');
        return true;
    }

    validatorCarConnectSuccessfully(): boolean {
        console.log('validatorCarConnectSuccessfully');
        return true;
    }

    /**
     * Stepper structure is like
     *    <weave-stepper/>
     *         <weave-step .validate=your_func />, has <slot/>    // <- catches and validates next/previous events
     *              <your ui view/>                               // <- your ui will dispatch next/previous events
     */
    public render = (): TemplateResult => {
        return html`
            <weave-stepper startingStep=${this.startingStep}>
                <weave-step
                    .validate="${this.validatorViewCarConnectExplanation}"
                >
                    <view-car-connect-explanation></view-car-connect-explanation>
                </weave-step>
                <weave-step .validate="${this.validatorViewCarConnectOauth}">
                    <view-car-connect-oauth></view-car-connect-oauth>
                </weave-step>
                <weave-step .validate="${this.validatorCarConnectSuccessfully}">
                    <view-car-connect-successfully></view-car-connect-successfully>
                </weave-step>
            </weave-stepper>
        `;
    };
}

declare global {
    interface HTMLElementTagNameMap {
        'view-smart-charging-onboarding': SmartChargingOnboardStepper;
    }
}
