import { hvIconFamily } from '@atoms/icon/icon';
import { ReduxLazy } from '@weavedev/redux-lazy';

export interface IconState {
    family: hvIconFamily;
}

export const iconState: IconState = {
    family: 'material',
};

const ICON = 'ICON';
export const setIconFamily = new ReduxLazy(ICON, iconState);
