import {
    LitElement,
    TemplateResult,
    customElement,
    html,
    property,
    query,
} from 'lit-element';

import { provider } from '@weavelab/frontend-connect';
import { whitelabel } from '@weavelab/whitelabel';
import style from './style';

@customElement('tool-tip')
@whitelabel({ name: 'tool-tip' })
export class Tooltip extends LitElement {
    @property({ type: String })
    public value?: string | string[];

    @property({ type: Object })
    private asset: any = provider.asset;

    @property({ type: Boolean, reflect: true })
    public hidden: boolean = false;

    @property({ type: Number })
    public tooltipTop: number = 30;

    @query('.tooltiptext')
    public tooltiptext?: HTMLElementTagNameMap['span'];

    private tooltipTextTop?: string;

    private tooltipLeft?: string;

    @whitelabel() // Allow styles to be injected from theme
    static get styles() {
        return [style];
    }

    // add touch / hover events to repsition the tooltip when needed.
    public connectedCallback() {
        super.connectedCallback();
        this.addEventListener('mouseover', this.windowSizeDebounced);
        this.addEventListener('touchend', this.windowSizeDebounced);
    }

    // remoeve touch / hover events to prevent memory leaks
    public disconnectedCallback() {
        this.removeEventListener('mouseover', this.windowSizeDebounced, true);
        this.removeEventListener('touchend', this.windowSizeDebounced, true);
        super.disconnectedCallback();
    }

    // check the type of value and add enter if value is array.
    private constructToolTipText(
        value: String | String[],
    ): TemplateResult | TemplateResult[] {
        if (Array.isArray(value)) {
            return value.map((toolTipText, i) => {
                // on last iteration, don't add a linebreak
                if (i === value.length - 1) {
                    return html`${toolTipText}`;
                }
                // add a line break after every textblock
                return html`${toolTipText}<br />`;
            });
        }
        // if not an array, just return the text as is
        return html`${value}`;
    }

    public render = (): TemplateResult => html` <span class="tooltiptext">
            ${this.value && this.constructToolTipText(this.value)}
        </span>
        <img src="${this.asset('icons/e-tooltip-default.svg')}" />`;

    /**
     * Function to check if the tooltip styling is within the window.
     * Else adjust the tooltip to fit within the window.
     */
    public windowSizeDebounced = (e: any): void => {
        (e as CustomEvent).stopImmediatePropagation();
        const tooltipText = this.tooltiptext;
        if (tooltipText) {
            tooltipText.style.removeProperty('top');
            tooltipText.style.removeProperty('bottom');
            const windowWidth: number = window.innerWidth;
            const tooltipBounding: ClientRect =
                tooltipText.getBoundingClientRect();
            // get the tooltips position on the window and take into account the scroll position
            const tooltipLeft = tooltipBounding.left + window.scrollX;
            const tooltipRight = tooltipBounding.right + window.scrollX;
            const tooltipTop = tooltipBounding.top + window.scrollY;

            // reset values before checking the toolTips position
            if (this.tooltipTextTop) {
                tooltipText.style.top = this.tooltipTextTop;
                this.tooltipTextTop = undefined;
            }
            if (this.tooltipLeft) {
                tooltipText.style.left = this.tooltipLeft;
                this.tooltipLeft = undefined;
            }
            // end reset values

            // if tooltip top touches the navigation bar
            if (tooltipTop < 100) {
                this.tooltipTextTop = tooltipText.style.top;
                tooltipText.style.top = `${this.tooltipTop}px`;
            }

            // if the screensize is to small make the tooltip full screen.
            if (window.outerWidth < 500) {
                tooltipText.style.left = `-${
                    this.getBoundingClientRect().left
                }px`;
                tooltipText.style.right = 'unset';
                return;
            }

            // if tooltip is off the window on the right
            const tooltipLeftPosition = tooltipLeft < 0 ? 0 : tooltipLeft;
            if (tooltipLeftPosition + tooltipRight > windowWidth) {
                const tooltipPadding: number = Number(
                    `var(--tooltip--side-padding, 0)`
                        .replace(/[^0-9]/g, '')
                        .split(' ')[0],
                );
                tooltipText.style.right = `-${
                    window.outerWidth - tooltipRight - tooltipPadding
                }px`;
            }
            // if tooltip is off the window on the left
            if (tooltipLeft < 0) {
                this.tooltipLeft = tooltipText.style.left;
                tooltipText.style.left = `-${
                    this.getBoundingClientRect().left
                }px`;
            }
        }
    };
}

declare global {
    interface HTMLElementTagNameMap {
        'tool-tip': Tooltip;
    }
}
