/**
 * @typedef {Object} ResourceDataTypeClass
 * @property {string} name
 * @property {Function} validate
 */

/**
 * Type object for usage in Resource objects
 */
export class ResourceDataType {
    /**
     * Constructor
     * @param {any} value
     * @param {boolean?} [nullable]
     */
    constructor(value, nullable) {
        /** @type {boolean} */
        this._nullable = !!nullable;
    }

    /**
     * Get value
     * @abstract
     * @return {any}
     */
    getValue() {
        throw new Error(
            `getValue() not implemented in ${this.constructor.name} class`,
        );
    }

    /**
     * Get value
     * @abstract
     * @return {boolean}
     */
    isSet() {
        throw new Error(
            `isSet() not implemented in ${this.constructor.name} class`,
        );
    }

    /**
     * Set value
     * @abstract
     * @param {any} value
     */
    setValue(value) {
        console.warn('Called setValue with', value);
        throw new Error(
            `setValue(value) not implemented in ${this.constructor.name} class`,
        );
    }

    /**
     * Get value as a format string
     * @abstract
     * @return {string}
     */
    toFormatString() {
        throw new Error(
            `toFormatString() not implemented in ${this.constructor.name} class`,
        );
    }

    /**
     * Get value as string
     * @abstract
     * @return {string}
     */
    toString() {
        throw new Error(
            `toString() not implemented in ${this.constructor.name} class`,
        );
    }

    /**
     * Validator
     * @abstract
     * @param {string} value
     * @return {boolean}
     */
    static validate(value) {
        console.warn('Called validate with', value);
        throw new Error(
            `static validate(value) not implemented in ${this.name} class`,
        );
    }
}
