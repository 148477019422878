export const PREFIX = 'REDIRECT ';

export const SET_REDIRECT = `${PREFIX}SET_REDIRECT`;

export const CLEAR_REDIRECT = `${PREFIX}CLEAR_REDIRECT`;

/**
 * setRedirect will dispatch the redirect state action
 * @param {string} redirectURL
 * @return {{type: string, to: string, till: string}}
 */
export const setRedirect = (redirectURL) => ({
    type: SET_REDIRECT,
    to: redirectURL,
    till: new Date(Date.now()).toISOString(),
});

/**
 * clearRedirect will dispatch clear the redirect state
 * @return {{type: CLEAR_REDIRECT, to: string, till: string}}
 */
export const clearRedirect = () => ({ type: CLEAR_REDIRECT, to: '', till: '' });
