import {
    LitElement,
    TemplateResult,
    customElement,
    html,
    property,
} from 'lit-element';

import '@components/elements/dropdown-selector-component/DropdownSelector';
import { ironFlex } from '@components/helpers/iron-flex-layout/iron-flex';
import { whitelabel } from '@weavelab/whitelabel';
import '../../containers/invoice-grid/InvoiceGrid';
import InvoicesStyle from './InvoicesStyle';

@customElement('ez-invoices')
@whitelabel({ name: 'invoices' })
export class Invoices extends LitElement {
    @property({ type: String })
    public paymentStatus: string = '';

    @whitelabel()
    static get styles() {
        return [ironFlex, InvoicesStyle];
    }

    public render = (): TemplateResult => html`
        <div class="invoicesComponentWrapper">
            <div
                class="invoicesListWrapper invoicesComponent"
                loading$="[[busy]]"
            >
                <invoice-grid
                    payment-status$="[[paymentStatus]]"
                ></invoice-grid>
            </div>
        </div>
    `;

    /**
     * onBeforeEnter is a Vaadin router lifecycle method
     * @param {RouterLocation} location
     * @param {PreventAndRedirectCommands} commands
     * @param {Router} router
     * @return {any}
     */
    onAfterEnter = () => {
        this.setParams();
    };

    /**
     * @param {RouterLocation} location
     */
    setParams() {
        // @ts-ignore
        const { searchParams } = new URL(window.location);
        if (searchParams.has('payment_status')) {
            this.paymentStatus = searchParams.has('payment_status')
                ? searchParams.get('payment_status')!
                : '';
            // history change without reloading the page.
            window.history.replaceState(
                {},
                document.title,
                window.location.pathname,
            );
            window.history.pushState(
                {},
                document.title,
                window.location.pathname,
            );
        } else {
            this.paymentStatus = '';
        }
    }
}

declare global {
    interface HTMLElementTagNameMap {
        'ez-invoices': Invoices;
    }
}
