import {
    purchaseEvent,
    purchaseEventConfirmation,
    Purchase,
} from 'gtm-middleware/orderOverview';
import { agreedSnapshot, setSelectedSnapshot } from '@actions/snapshot';
import { ErrorAction, SuccessAction } from 'goagen-js-resource';
import { ForkEffect, put, takeLatest } from 'redux-saga/effects';
import { notBusy } from '@actions/busy';
import { returnErrorOrEmptyString } from 'gtm-middleware/helpers/actionValue';
import { setError } from '@actions/error';
import { setLoader } from '@actions/loader';
import { uiOrderOverviewInteraction } from '@actions/ui';
import { AgreeSnapshotError } from './helpers/errors';
import { EnergysplitterLogs } from '../types/energysplitter';
// eslint-disable-next-line import/no-cycle
import { listSplitBusiness, updateSnapshot } from '../store';
import { setEnergysplitterLogs } from '../actions/energysplitter';
import { Snapshot } from '../types/snapshot';
import { userAgreesSnapshot } from '../async-reducers/snapshot';

const errorCreatingContract =
    'Bij het aanmaken van je account ging er iets fout, probeer het later nog eens of neem contact met ons op.';

/**
 *
 */
function* agreeSucces(result: SuccessAction) {
    const snapshot = result.data as Snapshot;
    if (snapshot) {
        yield put(agreedSnapshot(snapshot));
        yield put(notBusy());
        yield put(setLoader(false));

        const purchaseEventOBJ = window.store.getState().setPurchase.data;
        if (purchaseEventOBJ) {
            window.store.dispatch(
                uiOrderOverviewInteraction.run(
                    // @ts-ignore
                    purchaseEvent(purchaseEventOBJ as Purchase),
                ),
            );
        }
    }
}

/**
 * If snapshot gets updated update the selected snapshot with the returend snapshot.
 */
function* snapshotUpdated(result: SuccessAction) {
    const snapshot = result.data as Snapshot;
    if (snapshot) {
        yield put(setSelectedSnapshot(snapshot));
    }
}

function* energysplitterUpdated(result: SuccessAction) {
    const energysplitterLogs = result.data as EnergysplitterLogs;
    if (energysplitterLogs) {
        yield put(setEnergysplitterLogs(energysplitterLogs));
    }
}

/*
 * Failed
 */

function* agreeFailed(result: ErrorAction) {
    yield put(notBusy());
    yield put(setLoader(false));
    yield put(setError(errorCreatingContract));

    window.store.dispatch(
        uiOrderOverviewInteraction.run(
            purchaseEventConfirmation(
                returnErrorOrEmptyString(false, 'errorCreatingContract'),
            ),
        ),
    );
    throw new AgreeSnapshotError(result.error?.data?.snapshotID as string);
}

/**
 * Watcher for REQUEST_GRAPH_SUCCESS action
 */
export function* watchSnapshot(): IterableIterator<ForkEffect> {
    // success
    yield takeLatest(updateSnapshot.REQUEST_SUCCESS, snapshotUpdated);
    yield takeLatest(listSplitBusiness.REQUEST_SUCCESS, energysplitterUpdated);
    yield takeLatest(userAgreesSnapshot.actionTypeMap.callback, agreeSucces);
    // failed
    yield takeLatest(userAgreesSnapshot.actionTypeMap.error, agreeFailed);
}
