import '../behavior/error-handling/ErrorHandling';

import {
    CSSResultArray,
    customElement,
    html,
    property,
    query,
} from 'lit-element';
import { TemplateResult } from 'lit-html';
import { ifDefined } from 'lit-html/directives/if-defined';
import { whitelabel } from '@weavelab/whitelabel';

import style from '../behavior/text-component-behavior/style';
import { TextComponentBehavior } from '../behavior/text-component-behavior/TextComponentBehavior';

import styles from './style';

type Width = 'small' | '';

export interface InputChangedEvent extends CustomEvent {
    detail: {
        value: any;
        id: string;
    };
}

@customElement('input-component')
@whitelabel({ name: 'input-component' })
export class InputComponent extends TextComponentBehavior {
    @property({
        attribute: 'dragging',
    })
    public dragging: 'true' | 'false' = 'false';

    @query('input')
    public inputElement?: HTMLInputElement;

    @query('.input-container')
    public inputContainer?: HTMLDivElement;

    @property({ type: String })
    public inputWrapperStyle?: string;

    @property({ type: String })
    public label?: string;

    @property({ type: String })
    public widthKind: Width = '';

    @property({ type: Boolean })
    public disabled?: boolean = false;

    private needToBeValidatedAgain: boolean = false;

    @property({ attribute: 'validate-on-type', type: Boolean })
    public validateOnType: boolean = false;

    @whitelabel() // Allow styles to be injected from theme
    static get styles(): CSSResultArray {
        return [style, styles];
    }

    public focus = (): void => {
        this.inputElement!.focus();
    };

    public render = (): TemplateResult => {
        const { id, placeholder, src, suffix, inputSuffix } = this;

        return html`
            <div class="${ifDefined(this.label) && 'input-label'} ">
                ${this.label}
            </div>
            <div ?error=${this.error} class="input-container">
                ${src != null
                    ? html`
                          <div class="icon">
                              <img
                                  height="${this.imageHeight
                                      ? this.imageHeight
                                      : ''}"
                                  width="${this.imageWidth
                                      ? this.imageWidth
                                      : ''}"
                                  src="${src}"
                                  alt="${id}"
                              />
                          </div>
                      `
                    : null}
                <div
                    class="input-wrapper ${this.disabled ? 'disabled' : ''}"
                    widthKind="${this.widthKind}"
                    style="${ifDefined(this.inputWrapperStyle)}"
                >
                    <input
                        dragging=${ifDefined(this.dragging)}
                        id="${id}"
                        ?disabled=${this.disabled}
                        class="${src != null ? '' : 'pdl20'}"
                        placeholder="${placeholder}"
                        .value="${this.value}"
                        type=${this.type as any}
                        suffix=${ifDefined(this.suffix)}
                        pattern=${ifDefined(this.pattern)}
                        .required=${this.required}
                        area-label="input ${this.value}"
                        min="${ifDefined(this.min)}"
                        max="${ifDefined(this.max)}"
                        maxlength="${ifDefined(this.maxlength)}"
                        @invalid="${(e: Event) => e.preventDefault()}"
                        @focus=${() =>
                            this.inputContainer!.classList.add('active')}
                        @blur=${(e: FocusEvent) => {
                            this.inputContainer!.classList.remove('active');
                            this.changedInput(e);
                        }}
                        .error=${this.error}
                        error-message=${ifDefined(this.errorMessage)}
                    />
                    ${inputSuffix &&
                    html` <div class="input__suffix">${inputSuffix}</div>`}
                </div>
                ${suffix ? html` <div class="suffix">${suffix}</div> ` : null}
            </div>
            <error-handling
                .error=${this.error}
                error-message=${ifDefined(this.errorMessage)}
            ></error-handling>
        `;
    };

    // throttle function to throttle a function call by ms.
    protected throttle = (
        fn: (...arg: any[]) => void,
        ms: number,
    ): ((...arg: any[]) => any) => {
        let timer: number | any = 0;

        // @ts-ignore
        return (...args: any[]): any => {
            if (typeof timer === 'number') {
                clearTimeout(timer);
            }
            timer = setTimeout(fn.bind(this, ...args), ms || 0);
        };
    };

    public firstUpdated() {
        if (this.inputElement) {
            if (this.throttleValue) {
                // event lisnter with a throttle call.
                this.inputElement.addEventListener(
                    'keyup',
                    this.throttle(this.inputKeyUp, 500) as EventListener,
                );
            } else {
                // without throttle
                this.inputElement.addEventListener('keyup', ((
                    e: KeyboardEvent,
                ) => this.inputKeyUp(e, true)) as EventListener);
            }
        }
    }

    private inputKeyUp = (e: KeyboardEvent, bool: boolean = false): void => {
        e.preventDefault();
        // Validate input if error is true.
        if (this.error || this.needToBeValidatedAgain) {
            this.needToBeValidatedAgain = !!this.validate();
        }
        // type = number still allows comma's
        if (this.rounded && this.inputElement?.value.includes('.')) {
            this.inputElement.value = Math.round(
                parseInt(this.inputElement!.value, 10),
            ).toString();
        }

        if (this.validateOnType && this.inputElement) {
            if (
                this.min !== undefined &&
                parseInt(this.inputElement.value, 10) <= this.min
            ) {
                this.inputElement.value = `${this.min}`;
            }
            if (
                this.max !== undefined &&
                parseInt(this.inputElement.value, 10) > this.max
            ) {
                this.inputElement.value = `${this.max}`;
            }
        }

        if (this.slot || bool) {
            // Only change input when slotted (formvalidatable)
            this.value = this.inputElement?.value;

            this.changedInput(undefined, this.value);
        }
        this.dispatchEvent(
            new CustomEvent('input-value-changed', {
                detail: { value: this.inputElement!.value, id: this.id },
            } as InputChangedEvent),
        );
    };
}

declare global {
    interface HTMLElementTagNameMap {
        'input-component': InputComponent;
    }
}
