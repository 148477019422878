import {
    Bearer,
    BearerSupportedTypes,
    ParameterDefinitionsObject,
    ParameterIn,
    RequestAction,
    RequestOptions,
    RequestSagaIterableIterator,
    ResolvableOrType,
    Resource,
    ResourceOptions,
    UUID,
    UUIDSupportedTypes,
} from 'goagen-js-resource';
import { Method } from 'axios';

export interface InitOdaRequestObject {
    // (UUID)
    snapshotId?: UUIDSupportedTypes;
    // Injected Bearer header (Bearer)
    xAuth?: BearerSupportedTypes;
}

export interface ResolvableInitOdaRequestObject {
    // (UUID)
    snapshotId?: ResolvableOrType<InitOdaRequestObject['snapshotId']>;
    // Injected Bearer header (Bearer)
    xAuth?: ResolvableOrType<InitOdaRequestObject['xAuth']>;
}

/**
 * Store member management object for init oda
 */
export class InitOda extends Resource<
    InitOdaRequestObject,
    ResolvableInitOdaRequestObject
> {
    /**
     * Create new InitOda
     *
     * | Parameter |  | Target |  |  |
     * | --- | --- | --- | --- | --- |
     * | `snapshotId` * | `UUID` | path | `snapshotID` |  |
     * | `xAuth` | `Bearer` | header | `X-Auth` | Injected Bearer header |
     */
    constructor(
        storeName: string,
        options: ResourceOptions<ResolvableInitOdaRequestObject> = {},
    ) {
        super(storeName, options);
    }

    /**
     * Generate request action
     *
     * | Parameter |  | Target |  |  |
     * | --- | --- | --- | --- | --- |
     * | `snapshotId` * | `UUID` | path | `snapshotID` |  |
     * | `xAuth` | `Bearer` | header | `X-Auth` | Injected Bearer header |
     */
    public request(
        requestObject: InitOdaRequestObject = {},
        requestOptions: RequestOptions = {},
    ): RequestAction<InitOdaRequestObject> {
        return super.request(requestObject, requestOptions);
    }

    /**
     * Run request as saga
     *
     * | Parameter |  | Target |  |  |
     * | --- | --- | --- | --- | --- |
     * | `snapshotId` * | `UUID` | path | `snapshotID` |  |
     * | `xAuth` | `Bearer` | header | `X-Auth` | Injected Bearer header |
     */
    // @ts-ignore
    public get requestSaga(): (
        requestObject?: InitOdaRequestObject,
        requestOptions?: RequestOptions,
    ) => RequestSagaIterableIterator<RequestAction<InitOdaRequestObject>> {
        return super.requestSaga;
    }

    /**
     * Action string
     */
    static get action(): string {
        return 'init';
    }

    /**
     * Description string
     */
    static get description(): string {
        return "initialises user for ODA 'gratis inzicht app'";
    }

    /**
     * Request method
     */
    static get method(): Method {
        return 'post';
    }

    /**
     * Resource string
     */
    static get resource(): string {
        return 'oda';
    }

    /**
     * Route string
     */
    static get route(): string {
        return '/oda/init/{snapshotID}';
    }

    /**
     * Unique name string
     */
    static get uniqueName(): string {
        return 'InitOda';
    }

    /**
     * Parameters object
     */
    static get parameters(): ParameterDefinitionsObject {
        return {
            snapshotId: {
                in: ParameterIn.path,
                name: 'snapshotID',
                required: true,
                type: UUID,
            },
            xAuth: {
                description: 'Injected Bearer header',
                in: ParameterIn.header,
                name: 'X-Auth',
                type: Bearer,
            },
        };
    }
}
