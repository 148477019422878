import { signIn } from '@async-reducers/authentication';
import { store } from '../store';

/**
 * @param {string} email
 * @param {string} password
 * @returns any
 */
export const doSignInCall = (email: string, password: string): Promise<any> =>
    new Promise(async (resolve, reject) => {
        try {
            const res = await Promise.resolve(
                store.dispatch(signIn.run(email, password)),
            );
            resolve([res, null]);
        } catch (e) {
            reject([null, e]);
        }
    });
