import { ResourceDataType } from './ResourceDataType';

/**
 * ISODate object
 */
export class ISODate extends ResourceDataType {
    /**
     * Constructor to create an ISODate object
     * @param {null|string|ISODate|Date|Number} [value]
     * @param {boolean} [nullable] Flag to allow for null
     */
    constructor(value, nullable) {
        super(nullable);
        /** @type {Date?} */
        this.value = new Date();

        if (value === undefined) {
            this.setValue(ISODate._new());
        } else {
            this.setValue(value instanceof ISODate ? value.getValue() : value);
        }
    }

    /**
     * Get ISODate value
     * @return {Date?}
     */
    getValue() {
        return this.value;
    }

    /**
     * Set ISODate value
     * @param {string|null|Date|number} [value]
     */
    setValue(value) {
        if (value === null || value === undefined) {
            if (!this._nullable) {
                console.warn(
                    'To allow for nullable ISODates use',
                    'new ISODate(null, true)',
                );
                throw new Error('Parameter is not a valid ISODate');
            }
            this.value = null;
        } else if (
            value instanceof Date ||
            typeof value === 'number' ||
            !Number.isNaN(Date.parse(value))
        ) {
            this.value = new Date(value);
        } else {
            throw new Error('Parameter is not a valid ISODate');
        }
    }

    /**
     * Check if the value is set
     * @return {boolean}
     */
    isSet() {
        return !!this.value;
    }

    /**
     * Get ISODate as a format string
     * @return {string}
     */
    toFormatString() {
        return `"${this.toString()}"`;
    }

    /**
     * Get ISODate as string
     * @return {string}
     */
    toString() {
        return this.value
            ? this.value.toISOString()
            : new Date(0).toISOString();
    }

    /**
     * Generate a new ISODate string
     * @return {string}
     */
    static _new() {
        return new Date().toISOString();
    }

    /**
     * Validate a ISODate string
     * @param {string} value
     * @return {boolean}
     */
    static validate(value) {
        return !Number.isNaN(Date.parse(value));
    }
}
