import { css } from 'lit-element';

export default css`
    :host {
        display: flex;
        position: relative;
        flex-direction: row;
        align-items: center;
        white-space: nowrap;
        text-overflow: ellipsis;
        height: 18px !important;
    }

    :host([hidden]) {
        visibility: hidden;
        height: 0;
    }
    span {
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;
