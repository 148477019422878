import { LionInput } from '@lion/input';
import { css, CSSResultArray } from '@lion/core';
import { customElement } from 'lit/decorators/custom-element';
import { whitelabel } from '@weavelab/whitelabel';

@customElement('hv-input')
@whitelabel({ name: 'hv-input' })
export class Input extends LionInput {
    public static get customStyles(): CSSResultArray {
        return [
            css`
                :host {
                    /**
                    * General styling variables used as default between parts
                    */
                    --hv-input-font-size: var(--font-size, 14px);
                    --hv-input-font-family: var(--primary-font-family, 'Arial');
                    --hv-input-font-weight: normal;
                    --hv-input-font-style: normal;
                    --hv-input-font-color: #1e2129;
                    --hv-input-line-height: 20px;

                    --hv-input_-_input_-_border-radius: 3px;
                    --hv-input_-_input_-_border-width: 2px;
                    --hv-input_-_input_-_border-color: #f2f2f2;
                    --hv-input_-_input_-_border: var(
                            --hv-input_-_input_-_border-width
                        )
                        solid var(--hv-input_-_input_-_border-color);
                    --hv-input_-_input_-_max-width: 400px;
                    --hv-input_-_input_-_width: 100%;
                    --hv-input_-_input_-_font-weight: var(
                        --hv-input-font-weight
                    );
                    --hv-input_-_input_-_font-size: var(--hv-input-font-size);
                    --hv-input_-_input_-_font-style: var(--hv-input-font-style);
                    --hv-input_-_input_-_line-height: var(
                        --hv-input-line-height
                    );
                    --hv-input_-_input_-_color: var(--hv-input-font-color);

                    --hv-input_-_label_-_font-weight: var(
                        --hv-input-font-weight
                    );
                    --hv-input_-_label_-_font-style: var(--hv-input-font-style);
                    --hv-input_-_label_-_font-size: var(--hv-input-font-size);
                    --hv-input_-_label_-_line-height: var(
                        --hv-input-line-height
                    );
                    --hv-input_-_label_-_color: var(--hv-input-font-color);

                    max-width: var(--hv-input_-_input_-_max-width);
                    width: var(--hv-input_-_input_-_width);
                    --hv-input-border-succes: 1px solid #3aad3a;
                    --hv-input-border-warning: 1px solid #f2f2f2;
                }

                :host([succes]) ::slotted(input) {
                    border: var(--hv-input-border-succes);
                }

                :host([warning]) ::slotted(input) {
                    border: var(--hv-input-border-warning);
                }

                /* Webkit browsers like Safari and Chrome */
                ::slotted(input[type='number']::-webkit-outer-spin-button),
                ::slotted(input[type='number']::-webkit-inner-spin-button) {
                    -webkit-appearance: none;
                    margin: 0;
                }

                ::slotted(input:focus) {
                    outline-color: #000;
                }

                ::slotted(label) {
                    font-family: var(--hv-input_-_label_-_font-family);
                    font-size: var(--hv-input_-_label_-_font-size);
                    font-style: var(--hv-input_-_label_-_font-style);
                    font-weight: var(--hv-input_-_label_-_label-font-weight);
                    line-height: var(--hv-input_-_label_-_line-height);
                    color: var(--hv-input_-_label_-_color);
                }

                /* Only apply padding if label has content */
                ::slotted(label:not(:empty)) {
                    padding-bottom: 14px;
                    display: block;
                }

                ::slotted(input) {
                    border: var(--hv-input_-_input_-_border);
                    border-radius: var(--hv-input_-_input_-_radius);
                    padding: 11px 19px;
                    width: 100%;
                    -moz-appearance: textfield;
                    -webkit-appearance: none;
                    margin: 0;

                    font-family: var(--hv-input_-_input_-_font-family);
                    font-size: var(--hv-input_-_input_-_font-size);
                    font-style: var(--hv-input_-_input_-_font-style);
                    font-weight: var(--hv-input_-_input_-_font-weight);
                    line-height: var(--hv-input_-_input_-_line-height);
                    color: var(--hv-input_-_input_-_color);
                }

                ::slotted(lion-validation-feedback) {
                    font-family: var(--hv-input_-_input_-_font-family);
                    font-size: var(--hv-input_-_input_-_font-size);
                    font-style: var(--hv-input_-_input_-_font-style);
                    font-weight: var(--hv-input_-_input_-_font-weight);
                    line-height: var(--hv-input_-_input_-_line-height);
                    color: #f93512;
                    display: block;
                    padding-top: 10px;
                }

                .form-field__group-one {
                    display: flex;
                    justify-content: space-between;
                }

                .form-field__help-text {
                    display: block;
                    min-width: 0;
                    flex-grow: 1;
                    flex-basis: 0;
                    text-align: right;
                }
            `,
        ];
    }

    @whitelabel()
    public static get styles(): CSSResultArray {
        return [...super.styles, ...Input.customStyles];
    }

    public connectedCallback(): void {
        super.connectedCallback();
    }

    public disconnectedCallback(): void {
        super.disconnectedCallback();
    }
}

declare global {
    interface HTMLElementTagNameMap {
        'hv-input': Input;
    }
}
