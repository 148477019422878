import axios from 'axios';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
    REQUEST_CREATE_COMPANY,
    requestCreateCompanySuccess,
    requestCreateCompanyFailed,
} from '../actions/company';
import headers from './helpers/headers';

/**
 * Create company fetcher
 * @param {Object} action
 */
function* fetchCreateCompany(action) {
    try {
        const result = yield call(async () => {
            const response = await axios.post(
                '/v1/company/create',
                { ...action.payload },
                {
                    baseURL: window.API_LINK,
                    headers: headers(STDHeaders),
                },
            );
            if (response.statusText === 'OK' || response.status === 200) {
                return { data: response.data };
            }
            throw response.status;
        });
        yield put(requestCreateCompanySuccess(result.data));
    } catch (e) {
        yield put(requestCreateCompanyFailed(e));
    }
}

/**
 * Watcher for REQUEST_CREATE_COMPANY action
 */
export function* watchRequestCreateCompany() {
    yield takeLatest(REQUEST_CREATE_COMPANY, fetchCreateCompany);
}
