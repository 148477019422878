import { setNotification } from '@actions/notification';
import '@components/atoms/Placeholder';
import { Snapshot } from 'types/snapshot';
import {
    customElement,
    html,
    LitElement,
    property,
    PropertyValues,
    TemplateResult,
} from 'lit-element';
import { connect, watch } from 'lit-redux-watch';
import { Contract, contractFactory } from '../../../helpers/classes/Contract';
import DashboardStyle from './DashboardStyle';
import { Usages } from '@async-reducers/usages';

@customElement('view-dashboard')
export class Dashboard extends connect(window.store)(LitElement) {
    @watch('jwtSnapshots.selectedSnapshot')
    public snapshot?: Snapshot;

    @watch('setOdaOnboarding.data.skipped')
    public skipped?: boolean;

    @property({ type: Object, reflect: true })
    public usages?: Usages;

    @property({ type: Object })
    public contract?: Contract;

    @property({ attribute: 'power-grid-operator' })
    public powerGridOperator: string = '';

    static get styles() {
        return [DashboardStyle];
    }

    public render = (): TemplateResult => {
        return html`
            <hv-page-placeholder
                icon="house"
                title="Overzicht"
                subtitle="Zodra de eerste verbruiksdata bekend is, verschijnt hier per categorie een uitgebreid energieinzicht."
            ></hv-page-placeholder>
        `;
    };

    protected updated(changedProperties: PropertyValues) {
        if (changedProperties.has('snapshot') && this.snapshot) {
            this.contract = contractFactory(this.snapshot);

            const supplier = this.snapshot.external_supplier?.name;
            if (supplier) {
                this.powerGridOperator = this.snapshot.external_supplier.name;
            }
        }
        if (changedProperties.has('usages') && this.usages && this.snapshot) {
            if (
                this.snapshot.snapshot_phase === this.contract?.currentPhase &&
                !this.usages.hasElec &&
                !this.usages.hasGas
            ) {
                this.showWaitingForData();
            }
        }
    }

    // TODO: Future move this to monthly values class that implements a snapshot.
    // When the snapshot is set it should automatticly detects if a notification needs to be show.
    // the monthly values needs to be an factory class so that other data classes could use the same logic.
    private readonly showWaitingForData = (): void => {
        // Delete notification and place
        window.store.dispatch(
            setNotification(
                `Voor je verbruiksdata zijn we in afwachting van de netbeheerder${
                    this.powerGridOperator !== ''
                        ? ` ${this.powerGridOperator}`
                        : this.powerGridOperator
                }. Dit duurt meestal vijf werkdagen. Zodra dit is goedgekeurd, ontvang je van ons een e-mail om je eerste verbruiksdata in te zien.`,
                undefined,
                undefined,
                { ms: 0, fixed: true },
                'success',
            ),
        );
    };
}

declare global {
    interface HTMLElementTagNameMap {
        'view-dashboard': Dashboard;
    }
}
