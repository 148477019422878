import { LitElement, TemplateResult, customElement, html } from 'lit-element';

import odaPageStyles from './OdaPageStyles';

import '@components/atoms/HvLoader/HvLoader';
import { _defaults } from '@components/helpers/_defaults';
import { ironFlex } from '@components/helpers/iron-flex-layout/iron-flex';

import '@views/oda-onboarding/OdaOnboarding';

@customElement('oda-page')
export class OdaPage extends LitElement {
    static get styles() {
        return [_defaults, ironFlex, odaPageStyles];
    }

    public render = (): TemplateResult => {
        return html` <view-oda-onboarding></view-oda-onboarding> `;
    };
}

declare global {
    interface HTMLElementTagNameMap {
        'oda-page': OdaPage;
    }
}
