import {
    LitElement,
    customElement,
    html,
    property,
    CSSResult,
} from 'lit-element';
import { whitelabel } from '@weavelab/whitelabel';
import style from './HvRadioButtonStyle';

@customElement('hv-radio-button')
export class HvRadioButton extends LitElement {
    @property({ type: Boolean })
    checked: boolean = false;

    @property({ type: String })
    label: string = '';

    @whitelabel()
    public static get styles(): CSSResult[] {
        return [style];
    }

    render() {
        return html`<div ?checked="${this.checked}"></div>`;
    }
}
