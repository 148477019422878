import {
    css,
    customElement,
    html,
    LitElement,
    PropertyValues,
    TemplateResult,
} from 'lit-element';
import { property } from 'lit/decorators/property.js';
import { ChargingState } from 'types/vehicle';
import { ClassInfo, classMap } from 'lit-html/directives/class-map';
import { whitelabel } from '@weavelab/whitelabel';

@customElement('charging-battery')
@whitelabel({ name: 'spinner' })
export class ChargingBattery extends LitElement {
    @whitelabel()
    static get styles() {
        return [
            css`
                .battery {
                    background: var(--thin-grey);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    min-height: 2rem;
                    padding: 6px 12px;
                    position: relative;
                }

                .battery::after {
                    content: '';
                    background: var(--thin-grey);
                    height: 1rem;
                    width: 6px;
                    margin-top: -0.5rem;
                    position: absolute;
                    top: 50%;
                    left: 100%;
                }

                .battery .capacity {
                    background: var(--light-green);
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                }

                .battery.paused .capacity {
                    background: var(--google-blue-300);
                }

                :host([low]) .capacity {
                    background: var(--app-primary-lighter-variant);
                }

                .battery.charging .capacity {
                    animation: blinker 2s ease-in-out infinite;
                }

                @-webkit-keyframes blinker {
                    50% {
                        opacity: 0.4;
                    }
                }

                .battery span,
                .battery ::slotted(*) {
                    position: relative;
                    z-index: 1;
                }

                :host([low]) .battery + ::slotted(*),
                .battery.loading + ::slotted(*) {
                    color: red;
                }
            `,
        ];
    }

    @property()
    public state?: ChargingState;

    protected updated(changedProperties: PropertyValues) {
        super.updated(changedProperties);

        if (changedProperties.has('state')) {
            if (this.state && this.state.battery_level < 20) {
                this.setAttribute('low', '');
            } else if (this.getAttribute('low')) {
                this.removeAttribute('low');
            }
        }
    }

    public render = (): TemplateResult => {
        return html`
            <div class="battery ${classMap(this.getStateClasses(this.state))}">
                ${!this.state
                    ? html`<div class="PopupLoader"></div>`
                    : html`
                          <div
                              class="capacity"
                              style="width: ${this.state.battery_level}%"
                          ></div>
                          <slot name="battery">
                              <span
                                  >${this.state.battery_level}%
                                  ~${this.state.range}km</span
                              >
                          </slot>
                      `}
            </div>
            <slot></slot>
        `;
    };

    protected getStateClasses(state?: ChargingState): ClassInfo {
        return {
            charging: !!state?.is_charging,
            loading: !state,
            paused: !!state?.is_plugged_in && !!state?.is_charging,
            plugged: !!state?.is_plugged_in && !state?.is_charging,
        };
    }
}
