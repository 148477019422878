export const personalDataComponent = 'persoonlijke-gegevens';

type personalDataComponents = typeof personalDataComponent;

export const userInteractionEvent = 'user_interaction';

type eventTypes = 'button' | 'select' | 'link' | 'form';
type eventActionTypes = 'change' | 'click' | 'select';
type eventActionValueTypes = 'valid' | 'invalid' | string;

export type ProductListFields =
    | '00.button-existing-customer'
    | '01.form-field-private-or-business'
    | '02.form-field-company-name'
    | '03.form-field-kvk-number'
    | '04.form-field-vat'
    | '05.form-field-initials'
    | '06.form-field-surnamePrefix'
    | '07.form-field-surname'
    | '08.form-field-email'
    | '09.form-field-telephone'
    | '10.form-field-birth-date-field'
    | '11.form-field-buildingoccupied'
    | '12.form-field-movereason'
    | '13.form-field-startdatetype'
    | '14.form-field-startdate'
    | '15.form-field-smartmeter'
    | '16.form-field-invoiceaddress'
    | '17.form-field-invoiceaddress-postalcode'
    | '18.form-field-invoiceaddress-housenumber'
    | '19.form-field-invoiceaddress-housenumber-addition'
    | '20.form-field-payment-method'
    | '21.form-field-iban-number'
    | '22.button-confirm-personal-data'
    | '23.form-personal-data-denied'
    | 'start-een-nieuwe-aanvraag'
    | 'login-of-vraag-wachtwoord-opnieuw';

export interface CustomerFormEventType {
    event: typeof userInteractionEvent;
    type: eventTypes;
    field: ProductListFields;
    component: personalDataComponents;
    action: eventActionTypes;
    actionValue: eventActionValueTypes;
}

export const newProductListEvent = (
    component: personalDataComponents,
    type: eventTypes,
    field: ProductListFields,
    action: eventActionTypes,
    actionValue: eventActionValueTypes = '',
): CustomerFormEventType => ({
    event: userInteractionEvent,
    type,
    component,
    field,
    action,
    actionValue,
});
export const personalDataInputChanged = (
    actionValue: string,
    productListField: ProductListFields,
): CustomerFormEventType =>
    newProductListEvent(
        'persoonlijke-gegevens',
        'form',
        productListField,
        'change',
        actionValue,
    );

// custom event functions for the customer form
export const existingCustomerSignIN = (): CustomerFormEventType =>
    newProductListEvent(
        'persoonlijke-gegevens',
        'link',
        '00.button-existing-customer',
        'click',
        'valid',
    );
export const choosePrivateOrBusinessSelected = (
    actionValue: 'private' | 'business',
): CustomerFormEventType =>
    newProductListEvent(
        'persoonlijke-gegevens',
        'form',
        '01.form-field-private-or-business',
        'change',
        actionValue,
    );
export const formFieldBuildingOccupiedChanged = (
    actionValue: 'occupied' | 'not-occupied',
): CustomerFormEventType =>
    newProductListEvent(
        'persoonlijke-gegevens',
        'form',
        '11.form-field-buildingoccupied',
        'change',
        actionValue,
    );
export const formFieldMoveReasonChanged = (
    actionValue: 'change-provider' | 'new-address',
): CustomerFormEventType =>
    newProductListEvent(
        'persoonlijke-gegevens',
        'form',
        '12.form-field-movereason',
        'change',
        actionValue,
    );
export const formFieldStartdateTypeChanged = (
    actionValue: 'as-soon-as-possible' | 'pick-date',
): CustomerFormEventType =>
    newProductListEvent(
        'persoonlijke-gegevens',
        'form',
        '13.form-field-startdatetype',
        'change',
        actionValue,
    );
export const formFieldInvoiceaddressChanged = (
    actionValue: 'same-as-delivery' | 'different-adress',
): CustomerFormEventType =>
    newProductListEvent(
        'persoonlijke-gegevens',
        'form',
        '16.form-field-invoiceaddress',
        'change',
        actionValue,
    );
export const formFieldPaymentMethodChanged = (
    actionValue: 'automatic-collection' | 'manual',
): CustomerFormEventType =>
    newProductListEvent(
        'persoonlijke-gegevens',
        'form',
        '20.form-field-payment-method',
        'change',
        actionValue,
    );
export const personalDataConfirmed = (
    actionValue: string,
): CustomerFormEventType =>
    newProductListEvent(
        'persoonlijke-gegevens',
        'button',
        '22.button-confirm-personal-data',
        'click',
        actionValue,
    );
export const personalDataDenied = (
    actionValue: string,
): CustomerFormEventType =>
    newProductListEvent(
        'persoonlijke-gegevens',
        'form',
        '23.form-personal-data-denied',
        'click',
        actionValue,
    );

export const uiNewRequestClickedDataLayer = (): CustomerFormEventType =>
    newProductListEvent(
        'persoonlijke-gegevens',
        'link',
        'start-een-nieuwe-aanvraag',
        'click',
        'valid',
    );

export const uiLoginOrResetPasswordClickedDataLayer =
    (): CustomerFormEventType =>
        newProductListEvent(
            'persoonlijke-gegevens',
            'link',
            'login-of-vraag-wachtwoord-opnieuw',
            'click',
            'valid',
        );
