import {
    REQUEST_COST_VALUES,
    REQUEST_COST_VALUES_FAILED,
    REQUEST_COST_VALUES_SUCCESS,
} from '../actions/costValues';

const defaultMonthlyValues = {
    data: {
        curMonthReal: -1,
        savingsTillNowExclCurMonth: -1,
        yearSavings: -1,
        tillDate: null,
    },
    requestObject: {},
    error: null,
    busy: false,
};

/**
 * State reducer for invoices request
 * @param {Object} state
 * @param {Object} action
 * @return {Object}
 */
export const costValues = (state = { ...defaultMonthlyValues }, action) => {
    switch (action.type) {
        case REQUEST_COST_VALUES:
            return {
                ...state,
                busy: true,
            };
        case REQUEST_COST_VALUES_SUCCESS:
            return {
                ...state.requestObject,
                data: {
                    curMonthReal: action.cur_month_real,
                    savingsTillNowExclCurMonth:
                        action.savings_till_now_excl_cur_month,
                    yearSavings: action.year_savings,
                    tillDate: action.tillDate,
                },
                error: null,
                busy: false,
            };
        case REQUEST_COST_VALUES_FAILED:
            return { ...state, error: action.error, busy: false };
        default:
            return state;
    }
};
