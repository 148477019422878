import { css } from 'lit-element';

export default css`
    @import '../../../global-style/shared-styles.pcss';
    #INJECT__themeStyle_invoices {
        content: 'invoices';
    }

    :host {
        display: block;
        height: calc(100vh - 106px);
        position: relative;
    }

    .invoicesComponent {
        border-radius: 4px;
        padding-bottom: 0;
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.15);
        overflow: hidden;
    }

    .invoicesComponentWrapper {
        display: flex;
        flex-grow: 1;
    }

    .gridHeader {
        font-family: var(--primary-font-family);
        padding: 24px 23.75px;
        font-size: 18px;
        line-height: 1.06;
        color: var(--greyish);
    }

    .invoicesFileButton {
        font-family: var(--primary-font-family);
        font-size: 14px;
        font-weight: 300;
        line-height: 1.58;
        float: left;
        background: none;
        padding: 0;
        color: var(--greyish);
        text-decoration: underline;
    }

    .invoicesMessageButton {
        display: block;
        width: 90px;
        padding: 0;
        height: 24px;
        line-height: 24px;
        margin: 0;
    }

    /**
     * Tabs
     */
    .tabs {
        display: flex;
        flex-direction: column;
        padding: 24px;
    }

    .tabs .tabs-pages {
        display: flex;
        flex-direction: row;
    }

    .tabs .tabs-pages .tab-button {
        padding: 12px 34px;
        transition: 0.15s ease-in-out all;
    }

    .tabs .tabs-pages .tab-button.active {
        background: var(--app-border-color);
        border-bottom: 1px solid var(--app-dark-primary);
    }

    .tabs .tabs-pages .tab-button:hover {
        background: var(--app-border-color);
        border-bottom: 1px solid var(--app-dark-primary);
    }

    .tabs .tabs-content {
        display: flex;
        flex-direction: column;
        border-top: 1px solid var(--app-border-color);
    }

    .tabs .tabs-content .tab {
        display: none;
    }

    .tabs .tabs-content .tab.visible {
        display: block;
    }

    .tabs > #contract[hidden] {
        display: none;
    }

    /**
     * Header logic
     * TODO move this styling to the modulare litCss component
    */
    #contract {
        display: flex;
        flex-grow: 1;
        flex-direction: row;
        min-height: 55px;
        border-bottom: 2px solid #e1e1e1;
    }
    #contract .description {
        flex-grow: 1;
        display: flex;
        align-items: center;
    }

    /**
     * contract dorpdown
    */
    #contract__dropdown {
        display: flex;
        align-items: center;
        cursor: pointer;
    }

    /* contract dropdown content */
    .contract__content {
        display: none;
        justify-content: center;
    }
    .contract__content ul {
        margin: 0;
        padding: 0;
        background-color: var(--white);
        list-style-type: none;
        flex-grow: 1;
    }

    .contract__content li {
        height: 55px;
        display: flex;
        align-items: center;
        padding: 0 25px 0 30px;
        cursor: pointer;
        font-size: 14px;
        font-weight: 600;
        color: var(--darkish-purple);
    }

    .dropdown-selector-component {
        width: 370px;
    }

    .supplier__logo[hidden] {
        display: none;
    }
    .supplier__logo {
        display: flex;
        align-items: center;
    }

    .supplier__logo img {
        max-height: 45px;
    }

    .supplier__logo p {
        color: var(--black);
        font-size: 14px;
    }

    @media only screen and (max-device-width: 480px) {
        .invoicesListWrapper {
            width: calc(100vw - 35px);
        }
    }

    @media only screen and (max-width: 780px) {
        .invoicesListWrapper {
            width: calc(100vw - 35px);
        }
    }
`;
