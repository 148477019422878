import {
    LitElement,
    customElement,
    html,
    CSSResult,
    property,
} from 'lit-element';
import { whitelabel } from '@weavelab/whitelabel';
import style from './HvRadioLabelStyle';
import '@atoms/icon/HvIcon';

@customElement('hv-radio-label')
export class HvRadioLabel extends LitElement {
    @property({ type: String })
    recommend: string = '';

    @property({ type: Boolean })
    checked: boolean = false;

    @whitelabel()
    public static get styles(): CSSResult[] {
        return [style];
    }

    render() {
        return html`<div>
            <slot></slot>
            ${this.recommend === 'true'
                ? html` <span>
                      <hv-icon name="thumb-up"></hv-icon>
                      Aanbevolen keuze
                  </span>`
                : null}
        </div>`;
    }
}
