import { Contract, contractFactory } from '../../helpers/classes/Contract';
import {
    LitElement,
    TemplateResult,
    customElement,
    html,
    property,
    PropertyValues,
} from 'lit-element';
import { connect, watch } from 'lit-redux-watch';
import { Snapshot } from 'types/snapshot';

import './dashboard/Dashboard';
import '../../components/containers/ez-dashboard/dashboard';
import DashboardWrappertyle from './DashboardWrapperStyle';
import { Usages } from '@async-reducers/usages';
import enums from 'enums';
import { deleteAllNotifications, setNotification } from '@actions/notification';
import { OdaOnboardingState, setOdaOnboarding } from '@async-reducers/oda';
import { Commands, Context, RedirectResult } from '@vaadin/router';
import { routePaths } from 'data-access/router';

@customElement('dashboard-wrapper')
export class DashboardWrapper extends connect(window.store)(LitElement) {
    @watch('getUsages.busy')
    public busy?: boolean = false;

    @watch('getUsages.data')
    public usages?: Usages;

    @watch('setOdaOnboarding.data.skipped')
    public skipped?: boolean;

    @watch('jwtSnapshots.selectedSnapshot')
    public snapshot?: Snapshot;

    @property({ type: Object })
    public contract?: Contract;

    static get styles() {
        return [DashboardWrappertyle];
    }

    public render = (): TemplateResult => {
        const selectDashboard = this.decideIfOldDashboardShouldBeRendered(
            this.contract,
        );
        return selectDashboard
            ? html`<ez-dashboard></ez-dashboard>`
            : html`<view-dashboard
                  ?skipped=${this.skipped}
                  .contract=${this.contract}
                  .usages=${this.usages}
              ></view-dashboard>`;
    };

    protected updated(changedProperties: PropertyValues) {
        if (changedProperties.has('snapshot') && this.snapshot) {
            if (
                this.snapshot.id !=
                (changedProperties.get('snapshot') as Snapshot)?.id
            ) {
                this.contract = contractFactory(this.snapshot);
                this.checkIfNotificationIsRequired(this.snapshot);
            }
        }
    }

    private checkIfNotificationIsRequired = (
        snapshot: Snapshot | undefined,
    ) => {
        if (
            snapshot?.snapshot_phase ===
                enums.SnapshotPhaseDemoContractCreated &&
            this.skipped
        ) {
            window.store.dispatch(deleteAllNotifications());
            window.store.dispatch(
                setNotification(
                    'Het proces om de app te activeren is niet afgerond. Deze moet voltooid worden om verbruiksdata binnen te krijgen.',
                    undefined,
                    {
                        text: 'Activatie voltooien',
                        callback: () => {
                            window.store.dispatch(
                                setOdaOnboarding.run({
                                    skipped: !this.skipped,
                                } as OdaOnboardingState),
                            );
                            if (this.contract) {
                                this.contract.RouteToPage(false);
                            }
                            window.store.dispatch(deleteAllNotifications());
                        },
                    },
                    { ms: 0, fixed: true },
                    'warning',
                ),
            );
        }
    };

    public onBeforeEnter = (
        _: Context,
        commands: Commands,
    ): RedirectResult | undefined => {
        if (!this.snapshot) {
            return;
        }
        if (this.snapshot && this.contract == null) {
            this.contract = contractFactory(this.snapshot);
        }

        if (this.contract) {
            if (
                this.snapshot?.snapshot_phase ===
                    enums.SnapshotPhaseDemoContractCreated &&
                !this.skipped
            ) {
                return commands.redirect(routePaths.dashboard + routePaths.oda);
            }
        }
        return undefined;
    };

    private decideIfOldDashboardShouldBeRendered = (
        contract?: Contract,
    ): boolean => {
        if (contract) {
            // if contract isnt oda load old dashboard
            if (contract.contractType !== 'ODA') {
                return true;
            }

            // if onboarding is skipped load new dashboard
            if (this.skipped) {
                return false;
            } else if (this.usages) {
                // check if snapshot has elek or gas to check if new dashboard should be loaded
                const verified = this.snapshot!.verified_snapshot_payload;

                if (verified.has_elec) {
                    return this.usages.hasElec;
                } else if (verified.has_gas) {
                    return this.usages.hasGas;
                }
            }
        }

        return true;
    };
}

declare global {
    interface HTMLElementTagNameMap {
        'dashboard-wrapper': DashboardWrapper;
    }
}
