import {
    LitElement,
    TemplateResult,
    customElement,
    html,
    property,
} from 'lit-element';

import HvLoaderModalStyles from './HvLoaderModalStyles';
import '@atoms/HvLoader/HvLoader';

@customElement('hv-loader-modal')
export class HvLoaderModal extends LitElement {
    @property({ type: Boolean, reflect: true })
    public busy: boolean = false;

    @property({ type: Boolean })
    public overlay: boolean = true;

    @property({ type: String, reflect: true })
    public loaderTitle?: String;

    @property({ type: String, reflect: true })
    public loaderDescription?: String;

    static get styles() {
        return [HvLoaderModalStyles];
    }

    public render = (): TemplateResult => {
        return html`
            <div class="${this.overlay ? 'overlay' : ''}">
                <hv-loader loading>
                    ${this.loaderTitle
                        ? html`<slot slot="title">${this.loaderTitle}</slot>`
                        : null}
                    ${this.loaderDescription
                        ? html`<slot slot="description"
                              >${this.loaderDescription}</slot
                          >`
                        : null}
                </hv-loader>
            </div>
        `;
    };
}

declare global {
    interface HTMLElementTagNameMap {
        'hv-loader-modal': HvLoaderModal;
    }
}
