import { connect, watch } from 'lit-redux-watch';
import { customElement } from 'lit/decorators/custom-element';
import {
    html,
    LitElement,
    property,
    query,
    state,
    TemplateResult,
} from 'lit-element';
import '@elements/base/button/ButtonComponent';
import '@atoms/HvInputCode.ts';
import '@molecules/EanConfirm';
import { MeteringPoint } from 'types/meteringPoint';
import { Snapshot } from 'types/snapshot';
import { EanUsageType } from 'atoms/EANInput';
import { PropertyValues } from 'lit';
import '@atoms/HvLoader/HvLoader';
import { store } from 'data-access/store';
import { sendMeteringPointsReminderMail } from '@async-reducers/meteringPoint';
import { getEanPrefixForUsage } from 'helpers/ean';
import style from './MultipleEanModalStyle';

@customElement('multiple-ean-modal')
export class MultipleEanModal extends connect(window.store)(LitElement) {
    @query('modal-component')
    public modalComponent?: HTMLElementTagNameMap['modal-component'];

    @watch('busy')
    public busy?: string;

    @property({ type: Array })
    public meteringPoints?: MeteringPoint[];

    @property({ type: Object })
    private snapshot?: Snapshot;

    @property({ type: Number })
    private usageType: EanUsageType = EanUsageType.ELEC;

    @state()
    private eanPrefix: string = '';

    static get styles() {
        return [style];
    }

    protected updated(_changedProperties: PropertyValues) {
        super.updated(_changedProperties);

        if (_changedProperties.has('meteringPoints') && this.meteringPoints) {
            this.eanPrefix = getEanPrefixForUsage(
                this.meteringPoints,
                this.usageType,
            );
        }
    }

    public open = (): void => {
        this.modalComponent?.open();
    };

    public close = (): void => {
        this.modalComponent?.close();
    };

    sendMeteringReminder() {
        if (this.snapshot) {
            store.dispatch(
                sendMeteringPointsReminderMail.run(this.snapshot.id),
            );

            return;
        }

        console.warn('Unable to send metering reminder, snapshot is missing');
        window.displayMessage(
            'Excuus er ging iets fout bij het aanmaken van de reminder, probeert u het later nog eens of neem contact met ons op',
        );
    }

    eanConfirmSection(): TemplateResult {
        if (this.busy) {
            return html`<hv-loader
                ?loading="${this.busy || !this.meteringPoints}"
            ></hv-loader>`;
        }

        return html`
            <ean-confirm
                .meteringPoints="${this.meteringPoints}"
                .usageType="${this.usageType}"
                .snapshot="${this.snapshot}"
            ></ean-confirm>
        `;
    }

    public render(): TemplateResult {
        return html`
            <modal-component
                id="amountOfMeteringPoints"
                header-title="Meerdere aansluitingen gevonden"
                hide-close-button
                prevent-closing-outside
            >
                <div class="messageBox warning">
                    In het Centraal Aansluitingenregister zijn soms meerdere
                    aansluitingen geregistreerd op hetzelfde adres. Dit is op
                    jouw adres het geval.
                </div>
                <div class="popupPrimaryText">
                    Om zeker te zijn dat we op de juiste energieaansluiting(en)
                    gaan leveren, hebben we daarvan de EAN-code nodig. Deze vind
                    je op de jaarnota van de vorige energieleverancier of, in
                    het geval van nieuwbouw, in de papieren van je netbeheerder.
                    De code bestaat uit 18 cijfers en begint altijd met
                    ${this.eanPrefix}.
                </div>

                ${this.eanConfirmSection()}

                <button-component
                    id="reminder-btn"
                    label="Stuur mij een herinnering"
                    theme="secundary"
                    custom-style="width:100%; border:2px solid #CCCCCC; color:#777777;"
                    @click="${this.sendMeteringReminder}"
                ></button-component>

                <div class="messageBox notify">
                    Let op! Het energiecontract is pas compleet als we de juiste
                    energieaansluiting hebben gekoppeld.
                </div>
            </modal-component>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        'multiple-ean-modal': MultipleEanModal;
    }
}
