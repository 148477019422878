import { customElement, property } from 'lit-element';

import '@templates/oda-onboarding-step-wrapper/OdaOnboardingStepWrapper';
import '@molecules/HvCheckboxGroup';
import '@molecules/HvCheckbox';
import { whitelabel } from '@weavelab/whitelabel';
import { connect, watch } from 'lit-redux-watch';
import { WeaveStep } from '@components/organism/stepper/WeaveStep';
import { css, html, TemplateResult, CSSResult } from '@lion/core';
import { acceptOda } from '@async-reducers/oda';
import { selectOdaSnapshot } from '../../selectors/snapshot';
import { Snapshot } from 'types/snapshot';
import { asyncReducerResolver } from '@async-reducers/helpers/asyncReducerResolver';

interface TermsCheckedDetail {
    all: boolean;
}
export interface TermsCheckedEvent extends Event {
    detail: TermsCheckedDetail;
}

@whitelabel({ name: 'oda-onboarding' })
@customElement('accept-terms-step')
export class OdaOnboardingAcceptTerms extends connect(window.store)(WeaveStep) {
    @watch('acceptOda.error.message')
    public acceptOdaError?: string;

    @watch('acceptOda.busy')
    public busy: boolean = false;

    @watch('initOda.data.smp_id')
    public odaSmpID?: string;

    @watch('setOdaOnboarding.data.hasGas')
    public hasGas: boolean = false;

    @watch(selectOdaSnapshot)
    public snapshot?: Snapshot;

    @property({ type: Boolean })
    public disabledState?: boolean = true;

    @whitelabel()
    static vendorName?: string;

    @whitelabel()
    static productName?: string;

    @whitelabel()
    static odaPrivacyUrl?: string;

    @whitelabel()
    static odaTermsUrl?: string;

    validate = async (): Promise<boolean> => {
        if (this.chosen !== this.terms.length) {
            return false;
        }
        if (!this.snapshot || !this.odaSmpID) {
            console.warn('no snapshot or oda smp id');
            return false;
        }
        window.store.dispatch(
            acceptOda.run(this.odaSmpID, this.snapshot.id, this.hasGas),
        );
        await asyncReducerResolver(this);
        if (this.acceptOdaError) {
            window.displayMessage(
                'Er is iets mis gegaan tijdens de activatie',
                'error',
            );
            return false;
        }
        window.displayMessage('Activatie voltooid', 'succes');
        return true;
    };

    @property({ type: Object })
    public terms: TemplateResult[] = [
        html`<p class="conditions">
            Ja, ik ga akkoord met de inhoud van onderstaande documenten.
            <div class="buttons">
                ${
                    OdaOnboardingAcceptTerms.odaPrivacyUrl
                        ? html`<a
                              @click=${OdaOnboardingAcceptTerms.preventDefault}
                              href=${OdaOnboardingAcceptTerms.odaPrivacyUrl}
                              target="_blank"
                              rel="noopener noreferrer"
                              >Privacybeleid</a
                          >`
                        : ''
                }
                ${
                    OdaOnboardingAcceptTerms.odaTermsUrl
                        ? html`<a
                              @click=${OdaOnboardingAcceptTerms.preventDefault}
                              href=${OdaOnboardingAcceptTerms.odaTermsUrl}
                              target="_blank"
                              rel="noopener noreferrer"
                              >Voorwaarden</a
                          >`
                        : ''
                }
            </div>
        </p>`,

        html`<p>
            Ik geef toestemming aan <b>EnergyZero</b> en
            <b>${OdaOnboardingAcceptTerms.vendorName}</b> om mijn verbruiksdata
            uit te lezen, te berekenen en te tonen in de app.
        </p>`,
    ];

    @property({ type: Number })
    private chosen: number = 0;

    @property({ type: Boolean })
    public gas: boolean = false;

    public static preventDefault = (e: CustomEvent) => {
        e.preventDefault();
        e.stopImmediatePropagation();
        open((e.target as HTMLAnchorElement).href);
    };

    onTermChange = (e: Event) => {
        const { checked } = e.target as HTMLElementTagNameMap['hv-checkbox'];
        if (!checked) {
            this.chosen = this.chosen + 1;
        } else if (checked) {
            this.chosen = this.chosen - 1;
        }
        if (this.chosen < 0) {
            console.warn(`Chosen couldn't be less than zero!`);
            this.chosen = 0;
        }

        this.disabledState = this.chosen !== this.terms?.length;
    };

    render = () => {
        return html`
            <oda-onboarding-step-wrapper
                nextText="Activatie voltooien"
                ?disable-navigation=${this.disabledState}
            >
                <main slot="title">Accepteer onze voorwaarden</main>
                <span slot="introduction">
                    Nu je stroom ${this.hasGas ? 'en gas' : null} hebt
                    toegevoegd voor je gratis
                    ${OdaOnboardingAcceptTerms.productName}, vragen we je alleen
                    nog om mee te gaan in het onderstaande voorstel.
                </span>
                <div class="content">
                    <div class="terms">
                        <hv-checkbox-group
                            name="terms"
                            label="Ben je het eens met de voorwaarden?"
                        >
                            ${this.terms.map((term, index) => {
                                return html`<hv-checkbox
                                    .choiceValue="${index}"
                                    @user-input-changed=${this.onTermChange}
                                    ><div slot="label" class="checkbox-text">
                                        ${term}
                                    </div></hv-checkbox
                                >`;
                            })}
                        </hv-checkbox-group>
                        <p class="checkbox-helper">
                            Alle velden zijn verplicht
                        </p>
                    </div>
                </div>
            </oda-onboarding-step-wrapper>
        `;
    };

    static get styles(): CSSResult[] {
        return [
            ...super.styles,
            css`
                :host {
                    --oda-onboarding-content-padding: 20px;
                    --oda-onboarding-checkbox-font-size: 13px;
                    --oda-onboarding-checkbox-font-weight: 600;
                    --oda-onboarding-checkbox-padding: 10px 0 15px;
                    --oda-onboarding-checkbox-margin: 0;
                    --oda-onboarding-checkbox-color: #77777;
                }
                .terms {
                    display: flex;
                    flex-direction: column;
                    border: var(--oda-onboarding-terms-border);
                    padding: var(--oda-onboarding-terms-padding);
                }
                .buttons {
                    display: flex;
                    margin-top: 7px;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    align-content: center;
                    justify-content: flex-start;
                    align-items: flex-start;
                }

                p {
                    margin-block: unset;
                }

                .buttons a {
                    background-color: rgba(0, 0, 0, 0.06);
                    font-weight: 600;
                    padding: 5px 10px;
                    text-decoration: none;
                    color: #000;
                }
                .buttons a:first-child {
                    margin-right: 5px;
                }
                .checkbox-helper {
                    font-size: var(--oda-onboarding-checkbox-font-size);
                    color: var(--oda-onboarding-checkbox-color);
                    font-weight: var(--oda-onboarding-checkbox-font-weight);
                    padding: var(--oda-onboarding-checkbox-padding);
                    margin: var(--oda-onboarding-checkbox-margin);
                }
                .checkbox-text {
                    font-size: var(--oda-onboarding-checkbox-font-size);
                    margin: var(--oda-onboarding-checkbox-margin);
                }
                .term {
                    text-decoration: underline;
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        'accept-terms-step': OdaOnboardingAcceptTerms;
    }
}
