export const PREFIX = 'Invoices: ';

export const REQUEST_INVOICES = `${PREFIX}REQUEST_INVOICES`;
/**
 * Action generator for request fetch invoices
 * @param {String} userId
 * @return {Object}
 */
export const requestInvoices = (userId) => ({ type: REQUEST_INVOICES, userId });

export const REQUEST_INVOICES_SUCCESS = `${PREFIX}REQUEST_INVOICES_SUCCESS`;
/**
 * Success callback action for fetch invoices
 * @param {Object} data
 * @return {Object}
 */
export const requestInvoicesSuccess = (data) => ({
    type: REQUEST_INVOICES_SUCCESS,
    data,
});

export const REQUEST_INVOICES_FAILED = `${PREFIX}REQUEST_INVOICES_FAILED`;
/**
 * Error callback action for fetch invoices
 * @param {Object} error
 * @return {Object}
 */
export const requestInvoicesFailed = (error) => ({
    type: REQUEST_INVOICES_FAILED,
    error,
});
