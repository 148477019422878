import { ReduxAsync } from '@weavedev/redux-async';
import axios from 'axios';

// geoCodeCheck
const GEO_CODE_CHECK = 'GEO_CODE_CHECK';
const GEO_CODE_CHECK_SUCCESS = 'GEO_CODE_CHECK_SUCCESS';
const GEO_CODE_CHECK_FAILED = 'GEO_CODE_CHECK_FAILED';
export const geoCodeCheck = new ReduxAsync(
    GEO_CODE_CHECK,
    GEO_CODE_CHECK_SUCCESS,
    GEO_CODE_CHECK_FAILED,
    async (
        postalCode: string,
        houseNumber: string,
        houseNumberAddition?: string,
    ): Promise<any> => {
        const response = await axios.post(
            `/v2/metering-point/location/check?area_code=${postalCode}&house_number=${houseNumber}${
                houseNumberAddition
                    ? `&house_addition=${houseNumberAddition}`
                    : ''
            }`,
            {},
            {
                baseURL: window.API_LINK,
            },
        );
        return response.data;
    },
);

// geoCodeCheck edsn check
const GEO_CODE_EDSN_CHECK = 'GEO_CODE_EDSN_CHECK';
const GEO_CODE_EDSN_CHECK_SUCCESS = 'GEO_CODE_EDSN_CHECK_SUCCESS';
const GEO_CODE_EDSN_CHECK_FAILED = 'GEO_CODE_EDSN_CHECK_FAILED';
export const geoCodeEDSNCheck = new ReduxAsync(
    GEO_CODE_EDSN_CHECK,
    GEO_CODE_EDSN_CHECK_SUCCESS,
    GEO_CODE_EDSN_CHECK_FAILED,
    async (areaCode: string, houseNumber: string): Promise<any> => {
        const response = await axios.post(
            `/v2/metering-point/location/check?area_code=${areaCode}&house_number=${houseNumber}`,
            {},
            {
                baseURL: window.API_LINK,
            },
        );
        return response.data;
    },
);
