import { provider } from '@weavelab/frontend-connect';
import { UUID } from 'goagen-js-resource';

const vendorSettings = provider.settings('settings');
const appSettings = provider.settings('app');
const orderSettings = provider.settings('order-overview');

// Future TODO:
/*
 * hex type (regex)
 *    https://github.com/DefinitelyTyped/DefinitelyTyped/blob/master/types/color/v2/index.d.ts
 * phone type (regex)
 * url type (URL)
 */
export interface ISettings {
    appDomain: string;
    appTitle: string;
    electricityAccentColor: string;
    energyCostAccentColor: string;
    electricityProductionAccentColor: string;
    exceptOnlyVendorIDs: UUID[];
    gasAccentColor: string;
    logoRedirect: string;
    logoAppLink: string;
    phoneNumber: string;
    savingsAccentColor: string;
    subtitle: string;
    termsOfServiceURL: string;
    vendorID: UUID;
    // page settings
    splitBusiness?: boolean;
    vendorChargingSessions?: boolean;
    // page custom settings
    energyCostBusinessAccentColor?: string;
}

const defaultColor = '#FAED27';

export const defaultText = 'Nog in te vullen';

// Default settings:
const defaultSettings: ISettings = {
    appDomain: defaultText,
    appTitle: defaultText,
    electricityAccentColor: defaultColor,
    energyCostAccentColor: defaultColor,
    electricityProductionAccentColor: defaultColor,
    exceptOnlyVendorIDs: [],
    gasAccentColor: defaultColor,
    logoRedirect: '/',
    logoAppLink: '/',
    phoneNumber: '000 000 0000',
    savingsAccentColor: defaultColor,
    subtitle: defaultText,
    termsOfServiceURL: '/',
    vendorID: new UUID(null, true),
    /* 
     / custom pages
     */
    splitBusiness: false,
    vendorChargingSessions: false,
};

// map the correct settings
export const getSetting = <T extends keyof ISettings>(key: T): ISettings[T] => {
    if (vendorSettings[key]) return vendorSettings[key];
    if (appSettings[key]) return appSettings[key];
    if (orderSettings[key]) return orderSettings[key];
    // fallback default settings
    return defaultSettings[key];
};

const Settings: ISettings = {
    appDomain: getSetting('appDomain'),
    appTitle: getSetting('appTitle'),
    electricityAccentColor: getSetting('electricityAccentColor'),
    energyCostAccentColor: getSetting('energyCostAccentColor'),
    energyCostBusinessAccentColor: getSetting('energyCostBusinessAccentColor'),
    electricityProductionAccentColor: getSetting(
        'electricityProductionAccentColor',
    ),
    exceptOnlyVendorIDs: getSetting('exceptOnlyVendorIDs'),
    gasAccentColor: getSetting('gasAccentColor'),
    phoneNumber: getSetting('phoneNumber'),
    savingsAccentColor: getSetting('savingsAccentColor'),
    subtitle: getSetting('energyCostAccentColor'),
    splitBusiness: getSetting('splitBusiness'),
    logoRedirect: getSetting('logoRedirect'),
    logoAppLink: getSetting('logoAppLink'),
    termsOfServiceURL: getSetting('termsOfServiceURL'),
    vendorID: new UUID(getSetting('vendorID'), true),
    vendorChargingSessions: getSetting('vendorChargingSessions'),
};

export default Settings;
