/** @typedef {import('types/cost').CostData} CostData */

export const REQUEST_SAVINGS = 'REQUEST_SAVINGS';
/**
 * Action generator for request fetch collectives
 * @param {String} dateFrom
 * @param {String} dateTill
 * @return {Object}
 */
export const requestSavings = (dateFrom, dateTill) => ({
    type: REQUEST_SAVINGS,
    dateFrom,
    dateTill,
});

export const REQUEST_SAVINGS_FETCHED = 'REQUEST_SAVINGS_FETCHED';
/**
 * Success callback action for fetch collectives
 * @param {Object} data
 * @return {Object}
 */
export const requestSavingsFetched = (data) => ({
    type: REQUEST_SAVINGS_FETCHED,
    data,
});

export const REQUEST_SAVINGS_SUCCESS = 'REQUEST_SAVINGS_SUCCESS';
/**
 * Success callback action for fetch collectives
 * @param {CostData} data
 * @return {CostData}
 */
export const requestSavingsSuccess = (data) => ({
    type: REQUEST_SAVINGS_SUCCESS,
    data,
});

export const REQUEST_SAVINGS_FAILED = 'REQUEST_SAVINGS_FAILED';
/**
 * Error callback action for fetch collectives
 * @param {Object} error
 * @return {Object}
 */
export const requestSavingsFailed = (error) => ({
    type: REQUEST_SAVINGS_FAILED,
    error,
});
