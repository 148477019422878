import { MessageTypes } from '../../index';
import {
    LitElement,
    customElement,
    property,
    TemplateResult,
    html,
} from 'lit-element';
import { whitelabel } from '@weavelab/whitelabel';
import { _defaults } from '../helpers/_defaults';
import { ironFlex } from '../helpers/iron-flex-layout/iron-flex';
import { provider } from '@weavelab/frontend-connect';

const themeSettings = provider.settings('toast');

type kvArray = [MessageTypes, string];
const kvColorArray: kvArray[] = [
    ['error', '#ef5350'],
    ['info', '#29b6f6'],
    ['succes', '#9ccc65'],
    ['warning', '#ffc107'],
];
const duration = 500;

@customElement('ez-toast')
@whitelabel({ name: 'ez-toast' })
export class Toast extends LitElement {
    @property({ type: String })
    public text: string = '';

    @property({ type: String })
    public type: MessageTypes = 'info';

    @whitelabel()
    public static colors: Map<MessageTypes, string> = new Map<
        MessageTypes,
        string
    >(kvColorArray);

    public animation: Animation | undefined;

    @whitelabel()
    static get styles() {
        return [_defaults, ironFlex];
    }

    constructor() {
        super();
        // overwrite a color in the colors for a vendor theme
        const colors = themeSettings.colorsKeyValue;
        if (themeSettings && colors) {
            Object.entries(colors).forEach(([key, value]) => {
                if (Toast.colors.has(key as MessageTypes)) {
                    Toast.colors.set(key as MessageTypes, value as string);
                }
            });

            Toast.colors = new Map(Toast.colors);
        }
    }

    public render = (): TemplateResult => {
        return html`<div
            id="toastWrapper"
            class="layout horizontal center-justified"
        >
            <div id="toast">${this.text}</div>
        </div>`;
    };

    /**
     * Show message
     * @param {Number} duration duration of toast
     */
    protected showMessage = (duration: number) => {
        this.style.visibility = 'visible';
        const toast = this as HTMLElementTagNameMap['ez-toast'];
        if (!(toast instanceof Toast)) {
            return;
        }
        // set toast color by type if the color exists
        const type = toast.type;
        const colors = Toast.colors;
        if (toast && type && colors.has(type)) {
            const color = colors.get(type);
            if (color) {
                const innerToast = toast.shadowRoot!.querySelector(
                    '#toast',
                ) as HTMLDivElement;
                if (innerToast) {
                    innerToast.style.backgroundColor = color;
                }
            } else {
                console.warn(`color doesn't exist for type ${type}`);
            }
        }
        const firstKeyFrame = {
            transform: `translate3d(0, ${String(
                -1 * toast.offsetHeight,
            )}px, 0)`,
        };
        const secondKeyFrame = {
            transform: `translate3d(0, ${String(20)}px, 0)`,
        };

        this.animation = this.animate([firstKeyFrame, secondKeyFrame], {
            fill: 'forwards',
            easing: 'cubic-bezier(0.465, 0.825, 0.565, 1.000)',
            duration: duration,
        });
        this.animation.onfinish = () => {
            setTimeout(() => {
                this.fadeOut();
            }, duration);
        };
    };

    /**
     * Fades out the toast
     */
    fadeOut() {
        const keyframes = [{ opacity: 1 }, { opacity: 0 }];
        const timing = {
            duration: duration,
            fill: 'forwards',
        } as KeyframeAnimationOptions;

        this.animation = this.animate(keyframes, timing);

        this.animation.onfinish = () => {
            this.style.visibility = 'hidden';
            // Reset opacity for next display
            const kFrame = [{ opacity: 0 }, { opacity: 1 }];
            const tming = {
                duration: 0,
                fill: 'forwards',
            } as KeyframeAnimationOptions;
            this.animation = this.animate(kFrame, tming);
        };
    }
}

declare global {
    interface HTMLElementTagNameMap {
        'ez-toast': Toast;
    }
}
