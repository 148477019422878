import { provider } from '@weavelab/frontend-connect';
import { PolymerElement, html } from '@polymer/polymer/polymer-element';

import watch from 'redux-watch';
import css from './epic-flow.pcss';
import template from './epic-flow.html';

import '@polymer/iron-flex-layout/iron-flex-layout-classes';

// Sub pages
import './calculation-tool/calculation-tool';
import './customer-form/customer-form';
import './product-list/product-list';
import './order-overview/order-overview';

import { store } from '../../../store';
// @ts-ignore

/**
 * EzEpicFlow class
 */
export default class EzEpicFlow extends PolymerElement {
    /**
     * Gets properties of class
     */
    static get properties() {
        return {
            user: {
                type: Object,
                value: {},
            },
            subRoute: {
                type: String,
                observer: '_subRouteChanged',
            },
            secSubRoute: {
                type: String,
            },
        };
    }

    /**
     *
     */
    ready() {
        super.ready();
        this.user = store.getState().user;
        const watchUser = watch(store.getState, 'user');
        store.subscribe(
            watchUser(
                /**
                 * @param {Object} user
                 */
                (user) => {
                    this.user = JSON.parse(JSON.stringify(user));
                },
            ),
        );
    }

    /**
     * Observer called when subroute changed
     */
    _subRouteChanged() {
        if (this.subRoute === '') {
            this.subRoute = 'collective';
        }
    }

    /**
     * Gets template of class
     */
    static get template() {
        const cssTemplate = document.createElement('template');
        cssTemplate.innerHTML = provider.styles(css);
        const htmlTemplate = document.createElement('template');
        htmlTemplate.innerHTML = template;
        return html`<style include="iron-flex">
                ${cssTemplate}
            </style>
            ${htmlTemplate}`;
    }

    /**
     * Global state in store changed
     */
    _stateChanged = () => {};
}

window.customElements.define('ez-epic-flow', EzEpicFlow);
