import { ReduxLazy } from '@weavedev/redux-lazy';
import { Offers } from 'types/offers';
import { Order } from 'types/order';
import { Snapshot } from 'types/snapshot';

// request retention order offer
export interface OrderRetentionOfferState {
    snapshot?: Snapshot;
    offers?: Offers;
    order?: Order;
    enabled: boolean;
    busy: boolean;
}

export const ORDER_RETENTION_OFFER = 'ORDER_RETENTION_OFFER';
const orderRetentionOfferState: OrderRetentionOfferState = {
    snapshot: undefined,
    order: undefined,
    offers: undefined,
    enabled: false,
    busy: false,
};

export const orderRetentionOffer = new ReduxLazy(
    ORDER_RETENTION_OFFER,
    orderRetentionOfferState,
);
