import { css } from 'lit-element';

export default css`
    :host {
        --dropdown-selector--background: var(--white);
        --dropdown-selector--padding: 0 20px;
        --dropdown-selector--icon__padding: 0 0 0 20px;
        --dropdown-cell-height: 55px;
        --dropdown-cell-padding: 0 0 0 30px;
        --dropdown-cell__hover--color: #f6f6f6;
        --dropdown-cell__active--color: var(--primary-color);
        --dropdown-cell__active--font-weight: 600;
        --dropdown-cell__active--text-decoration: underline;

        cursor: pointer;
        display: flex;
        flex-grow: 1;
        position: relative;
        height: 55px;
        align-items: center;
        visibility: visible;
        transition: opacity 800ms, height 500ms;
    }

    :host([hidden]) {
        visibility: hidden;
        opacity: 0;
        max-height: 0;
        transition: opacity 300ms, max-height 5ms;
    }

    :host([state]) .arrow-down {
        transform: rotate(180deg);
    }

    :host([disabled]) {
        cursor: not-allowed;
        pointer-events: none;
        opacity: 0.5;
    }

    dropdown-cell {
        --cell-height: var(--dropdown-cell-height);
        --cell-padding: var(--dropdown-cell-padding);
        --cell__hover--color: var(--dropdown-cell__hover--color);
        --cell__active--color: var(--dropdown-cell__active--color);
        --cell__active--font-weight: var(--dropdown-cell__active--font-weight);
        --cell__active--text-decoration: var(
            --dropdown-cell__active--text-decoration
        );
    }

    :host([disabled]) {
        pointer-events: none;
    }

    :host([disabled]) img {
        display: none;
    }

    slot[name='menu'] {
        display: flex;
        flex-direction: row;
        flex-grow: 1;
        align-items: center;
        padding: var(--dropdown-selector--padding);
    }

    slot[name='sub-menu'] {
        max-height: 245px;
        overflow-x: hidden;
        overflow-y: auto;
        background: var(--dropdown-selector--background);
    }

    [theme='primary'] {
        color: var(--ew-gray-dark);
        background: var(--ew-white);
    }

    .dropdown {
        height: inherit;
        align-items: center;
        background-color: var(--dropdown-selector--background);
        display: flex;
        flex-grow: 1;
    }

    placeholder-component {
        width: 100%;
    }

    .dropdown[error] {
        border: 1px solid var(--ew-red);
    }
    .dropdown-content {
        display: flex;
        position: absolute;
        right: 0;
        flex-direction: column;
    }
    #arrow {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: var(--dropdown-selector--icon__padding) !important;
    }
    .arrow-down {
        height: 16px;
        transition: all 400ms;
    }

    .icon {
        height: 18px;
        margin-right: 14px;
        width: 24px;
    }

    #label {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        margin: 0;
        font-size: 14px;
    }
    #label span {
        color: var(--primary-color);
        font-size: 18px;
        line-height: 20px;
    }
`;
