import { ReduxAsync } from '@weavedev/redux-async';
import axios, { AxiosResponse } from 'axios';
import {
    Vacation,
    Schedule,
    ScheduleEntriesByDay,
    ScheduleEntry,
    WorkDay,
} from 'types/schedule';

// @ts-ignore
import headers from '../sagas/helpers/headers';

import { convertNumberToString } from '../helpers/string';
import { ListWorkDaysScheduleRequestObject } from '../resources/ListWorkDaysSchedule';
import { listWorkDaysSchedule } from '../store';
import { Snapshot } from 'types/snapshot';

/**
 * CREATE
 */

// Create work schedule
const CREATE_WORK_SCHEDULE = 'CREATE_WORK_SCHEDULE';
const CREATE_WORK_SCHEDULE_SUCCESS = 'CREATE_WORK_SCHEDULE_SUCCESS';
const CREATE_WORK_SCHEDULE_FAILED = 'CREATE_WORK_SCHEDULE_FAILED';
export const createWorkSchedule = new ReduxAsync(
    CREATE_WORK_SCHEDULE,
    CREATE_WORK_SCHEDULE_SUCCESS,
    CREATE_WORK_SCHEDULE_FAILED,
    async (
        snapshotID: string,
        newSchedule: ScheduleEntriesByDay,
    ): Promise<Schedule[]> => {
        const response: AxiosResponse = await axios.post(
            `/v1/schedule`,
            {
                snapshot_id: snapshotID,
                monday: newSchedule.monday,
                tuesday: newSchedule.tuesday,
                wednesday: newSchedule.wednesday,
                thursday: newSchedule.thursday,
                friday: newSchedule.friday,
                saturday: newSchedule.saturday,
                sunday: newSchedule.sunday,
            },
            {
                baseURL: window.API_LINK,
                headers: headers(window.STDHeaders),
            },
        );
        if (response.statusText === 'OK' || response.status === 200) {
            return response.data as Schedule[];
        }
        throw response.status;
    },
);

// Create vacation schedule
const CREATE_VACATION_SCHEDULE = 'CREATE_VACATION_SCHEDULE';
const CREATE_VACATION_SCHEDULE_SUCCESS = 'CREATE_VACATION_SCHEDULE_SUCCESS';
const CREATE_VACATION_SCHEDULE_FAILED = 'CREATE_VACATION_SCHEDULE_FAILED';
export const createVacation = new ReduxAsync(
    CREATE_VACATION_SCHEDULE,
    CREATE_VACATION_SCHEDULE_SUCCESS,
    CREATE_VACATION_SCHEDULE_FAILED,
    async (
        snapshotID: string,
        startDate: string,
        endDate?: string,
    ): Promise<Schedule[]> => {
        const payload = {
            snapshot_id: snapshotID,
            start_date: startDate,
        };
        if (endDate) {
            Object.assign(payload, { end_date: endDate });
        }
        const response: AxiosResponse = await axios.post(
            `/v1/schedule/vacation`,
            payload,
            {
                baseURL: window.API_LINK,
                headers: headers(window.STDHeaders),
            },
        );
        if (response.statusText === 'OK' || response.status === 200) {
            return response.data;
        }
        throw response.status;
    },
);

const CREATE_WORKDAY = 'CREATE_WORKDAY';
export const CREATE_WORKDAY_SUCCESS = 'CREATE_WORKDAY_SUCCESS';
export const CREATE_WORKDAY_FAILED = 'CREATE_WORKDAY_FAILED';
export const createWorkday = new ReduxAsync(
    CREATE_WORKDAY,
    CREATE_WORKDAY_SUCCESS,
    CREATE_WORKDAY_FAILED,
    async (
        snapshotID: string,
        date: Date,
        entries: ScheduleEntry[],
    ): Promise<WorkDay[]> => {
        let workdayDate: Date | string = new Date(date);
        workdayDate = new Date(workdayDate.setHours(2, 0, 0, 0)).toISOString();
        const payloadEntries = [];
        for (const entry of entries) {
            if (
                new Date(entry.end_time).getHours() <=
                new Date(entry.start_time).getHours()
            ) {
                window.displayMessage(
                    `Eindtijd: ${new Date(
                        entry.end_time,
                    ).getHours()} mag niet eerder of gelijk zijn aan ${new Date(
                        entry.start_time,
                    ).getHours()}`,
                );
                continue;
            }
            const startHour: string = convertNumberToString(
                new Date(entry.start_time).getHours(),
            );
            const endHour: string = convertNumberToString(
                new Date(entry.end_time).getHours(),
            );
            payloadEntries.push({
                start_time: `${startHour}:00`,
                end_time: `${endHour}:00`,
            });
        }

        // create payload for a new workDay
        const payload = {
            snapshot_id: `${snapshotID}`,
            date: `${workdayDate}`,
            entries: payloadEntries,
        };

        // create post body.
        const response: AxiosResponse = await axios.post(
            `/v1/schedule/workday`,
            JSON.stringify(payload),
            {
                baseURL: window.API_LINK,
                headers: headers(window.STDHeaders),
            },
        );
        if (response.statusText === 'OK' || response.status === 200) {
            return response.data;
        }
        throw response.status;
    },
);

/**
 * REQUEST
 */
const REQUEST_SCHEDULE_CURRENT = 'REQUEST_SCHEDULE_CURRENT';
const REQUEST_SCHEDULE_CURRENT_SUCCESS = 'REQUEST_SCHEDULE_CURRENT_SUCCESS';
const REQUEST_SCHEDULE_CURRENT_FAILED = 'REQUEST_SCHEDULE_CURRENT_FAILED';
export const getCurrentSchedule = new ReduxAsync(
    REQUEST_SCHEDULE_CURRENT,
    REQUEST_SCHEDULE_CURRENT_SUCCESS,
    REQUEST_SCHEDULE_CURRENT_FAILED,
    async (snapshotID: string): Promise<Schedule[]> => {
        const response: AxiosResponse = await axios.get(
            `/v1/schedule?snapshotID=${snapshotID}`,
            {
                baseURL: window.API_LINK,
                headers: headers(window.STDHeaders),
            },
        );
        if (response.statusText === 'OK' || response.status === 200) {
            return response.data;
        }
        throw response.status;
    },
);

const REQUEST_WORKDAYS = 'REQUEST_WORKDAYS';
const REQUEST_WORKDAYS_SUCCESS = 'REQUEST_WORKDAYS_SUCCESS';
const REQUEST_WORKDAYS_FAILED = 'REQUEST_WORKDAYS_FAILED';
export const getListWorkdays = new ReduxAsync(
    REQUEST_WORKDAYS,
    REQUEST_WORKDAYS_SUCCESS,
    REQUEST_WORKDAYS_FAILED,
    async (snapshot: Snapshot, settled: boolean = false): Promise<void> => {
        if (snapshot == null) {
            throw new Error('error missing snapshot for fetching workdays');
        }
        let startDate = snapshot.verified_snapshot_payload.start_date;
        const currentStart = snapshot.verified_snapshot_payload.current_start;
        if (currentStart) {
            startDate = currentStart;
        }
        const payload = {
            snapshotId: snapshot.id,
            endDate: new Date().toISOString(),
            settled,
            startDate: new Date(startDate).toISOString(),
        } as ListWorkDaysScheduleRequestObject;
        // fetch all unsettled workdays
        window.store.dispatch(listWorkDaysSchedule.request(payload));
    },
);

/**
 * Lists
 */
const REQUEST_SCHEDULE_VACATIONS = 'REQUEST_SCHEDULE_VACATIONS';
const REQUEST_SCHEDULE_VACATIONS_SUCCESS = 'REQUEST_SCHEDULE_VACATIONS_SUCCESS';
const REQUEST_SCHEDULE_VACATIONS_FAILED = 'REQUEST_SCHEDULE_VACATIONS_FAILED';
export const getScheduleVacationsList = new ReduxAsync(
    REQUEST_SCHEDULE_VACATIONS,
    REQUEST_SCHEDULE_VACATIONS_SUCCESS,
    REQUEST_SCHEDULE_VACATIONS_FAILED,
    async (
        snapshotID: string,
        startDate?: string,
        endDate?: string,
    ): Promise<Schedule[]> => {
        let additionalParams: string = '';
        if (startDate) {
            additionalParams += `&startDate=${startDate}`;
        }
        if (endDate) {
            additionalParams += `&endDate=${endDate}`;
        }
        const response: AxiosResponse = await axios.get(
            `/v1/schedule/vacation?snapshotID=${snapshotID}${additionalParams}`,
            {
                baseURL: window.API_LINK,
                headers: headers(window.STDHeaders),
            },
        );
        if (response.statusText === 'OK' || response.status === 200) {
            return response.data;
        }
        throw response.status;
    },
);

/**
 * DELETE
 */
const DELETE_VACATION = 'DELETE_VACATION';
const DELETE_VACATION_SUCCESS = 'DELETE_VACATION_SUCCESS';
const DELETE_VACATION_FAILED = 'DELETE_VACATION_FAILED';
export const deleteVacation = new ReduxAsync(
    DELETE_VACATION,
    DELETE_VACATION_SUCCESS,
    DELETE_VACATION_FAILED,
    async (vacationID: string): Promise<Vacation[]> => {
        // create post body.
        const response: AxiosResponse = await axios.delete(
            `/v1/schedule/vacation/${vacationID}`,
            {
                baseURL: window.API_LINK,
                headers: headers(window.STDHeaders),
            },
        );
        if (
            response.statusText === 'OK' ||
            response.status === 200 ||
            response.status === 204
        ) {
            return response.data;
        }
        throw response.status;
    },
);

const DELETE_WORKDAY = 'DELETE_WORKDAY';
export const DELETE_WORKDAY_SUCCESS = 'DELETE_WORKDAY_SUCCESS';
export const DELETE_WORKDAY_FAILED = 'DELETE_WORKDAY_FAILED';
export const deleteWorkday = new ReduxAsync(
    DELETE_WORKDAY,
    DELETE_WORKDAY_SUCCESS,
    DELETE_WORKDAY_FAILED,
    async (workdayID: string): Promise<WorkDay> => {
        // create post body.
        const response: AxiosResponse = await axios.delete(
            `/v1/schedule/workday/${workdayID}`,
            {
                baseURL: window.API_LINK,
                headers: headers(window.STDHeaders),
            },
        );
        if (
            response.statusText === 'OK' ||
            response.status === 200 ||
            response.status === 204
        ) {
            return { snapshot_id: workdayID } as WorkDay;
        }
        throw response.status;
    },
);

/**
 * UPDATE
 */
const UPDATE_VACATION = 'UPDATE_VACATION';
const UPDATE_VACATION_SUCCESS = 'UPDATE_VACATION_SUCCESS';
const UPDATE_VACATION_FAILED = 'UPDATE_VACATION_FAILED';
export const updateVacation = new ReduxAsync(
    UPDATE_VACATION,
    UPDATE_VACATION_SUCCESS,
    UPDATE_VACATION_FAILED,
    async (vacationID: string, endDate: string): Promise<Vacation> => {
        const response: AxiosResponse = await axios.put(
            `/v1/schedule/vacation/${vacationID}`,
            {
                end_date: `${endDate}`,
            },
            {
                baseURL: window.API_LINK,
                headers: headers(window.STDHeaders),
            },
        );
        if (response.statusText === 'OK' || response.status === 200) {
            return response.data;
        }
        throw response.status;
    },
);
