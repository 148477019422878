import {
    LitElement,
    TemplateResult,
    customElement,
    html,
    css,
    property,
    query,
} from 'lit-element';

import { ifDefined } from 'lit/directives/if-defined.js';

import '@atoms/ProgressBar';
import '@atoms/HvButton';
import '@atoms/HvProgressBarLabel';
import '@organism/stepper/WeaveStepper';
import '@organism/stepper/WeaveStep';

import '@molecules/HvLoaderModal/HvLoaderModal';

import '@views/oda-onboarding/HasGasStep';
import '@views/oda-onboarding/AcceptTermsStep';
import '@views/oda-onboarding/MeteringPointStep';
import '@views/oda-onboarding/SuccessStep';
import '@views/oda-onboarding/OdaPostcodeStep';

import { odaStateBusy } from '@selectors/oda';

import { WeaveStepper } from '@organism/stepper/WeaveStepper';
import { OdaOnboardingState, setOdaOnboarding } from '@async-reducers/oda';
import { connect, watch } from 'lit-redux-watch';
import { whitelabel } from '@weavelab/whitelabel';
@whitelabel({ name: 'oda-onboarding' })
@customElement('view-oda-onboarding')
export class OdaOnboarding extends connect(window.store)(LitElement) {
    @watch(odaStateBusy)
    public isOdaLoading: boolean = false;

    @whitelabel()
    static vendorName: string = '';

    @whitelabel()
    static productName: string = '';

    @property({ type: Number })
    public currentStep: number = 0;

    @property({ type: Number })
    public totalSteps?: number;

    @query('#modal')
    public modal?: HTMLElementTagNameMap['modal-component'];

    public render = (): TemplateResult => {
        return html`
            <hv-progress-bar-label
                ?hidden=${this.currentStep + 1 === this.totalSteps}
            >
                Activeer de gratis ${OdaOnboarding.productName} app
            </hv-progress-bar-label>
            <hv-progress-bar
                currentstep=${this.currentStep + 1}
                totalsteps=${ifDefined(this.totalSteps)}
                ?hidden=${this.currentStep + 1 === this.totalSteps}
            >
            </hv-progress-bar>
            <weave-stepper
                @transition=${this.handleTransitionEvent}
                @quit=${() => this.handleQuitEvent(false)}
                @initial-step-changed=${this.handleInitialStepChangedEvent}
                startingStep=${this.currentStep}
            >
                <oda-postcode-step></oda-postcode-step>
                <metering-point-step></metering-point-step>
                <has-gas-step></has-gas-step>
                <accept-terms-step></accept-terms-step>
                <oda-success></oda-success>
            </weave-stepper>
            <modal-component
                header-title="Activatie afbreken?"
                id="modal"
                prevent-closing-outside
            >
                Je staat op het punt om de activatie af te breken. Zonde, want
                het is in een minuut geregeld. Wil je dit echt later doen?
                <hv-button @click=${() => this.modal?.close()} theme="primary">
                    Activatie voltooien
                </hv-button>
                <hv-button
                    @click=${() => this.handleQuitEvent(true)}
                    theme="secondary"
                >
                    Dit doe ik later
                </hv-button>
            </modal-component>
            <hv-loader-modal
                ?busy=${this.isOdaLoading}
                loadertitle="Laden..."
                loaderdescription="We laden het gratis Verbruiksoverzicht met alle nieuwe gegevens uit het systeem."
            ></hv-loader-modal>
        `;
    };

    // handleTransitionEvent handles the transition event from one step to another
    private handleTransitionEvent(event: CustomEvent): void {
        this.currentStep = event.detail?.toStep?.number;
        this.totalSteps = (event.target as WeaveStepper)?.steps?.length;
    }

    private handleQuitEvent(confirmed: boolean): void {
        if (confirmed) {
            this.modal?.close();
            window.store.dispatch(
                setOdaOnboarding.run({
                    active: false,
                    skipped: true,
                } as OdaOnboardingState),
            );
            return;
        }

        this.modal?.open();
    }

    // handleInitialStepChangedEvent handles the initial step changed event
    private handleInitialStepChangedEvent(event: CustomEvent): void {
        this.totalSteps = (event.target as WeaveStepper)?.steps?.length;
    }

    static get styles() {
        return [
            css`
                :host,
                weave-stepper {
                    display: flex;
                    flex-direction: column;
                }

                hv-progress-bar {
                    --hv-progress-bar-height: 10px;
                    --hv-progress-bar-background-color: #f1f1f1;
                    --hv-progress-bar-indicator-color: var(--success-green);
                }

                hv-loader-modal {
                    --hv-loader-modal-loader-background: var(--white);
                    --hv-loader-modal-loader-padding: 36px 18px;
                }

                hv-button:first-child {
                    margin-top: 40px;
                }

                modal-component {
                    --modal__header--padding: 0 25px;
                    --modal__header--text-align: left;
                    --modal__close--font-size: 44px;
                    --modal__close--margin-top: 9px;
                }

                hv-progress-bar-label[hidden] {
                    display: none;
                }

                @media only screen and (max-height: 565px) {
                    hv-progress-bar-label {
                        display: none;
                    }
                }

                hv-button:last-of-type {
                    margin-top: 16px;
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        'view-oda-onboarding': OdaOnboarding;
    }
}
