export const calculatorComponent = 'calculate-monthly-fee-widget';
export const userInteractionEvent = 'user_interaction';
export const formEventType = 'form';

type eventActionTypes = 'change' | 'click' | 'hover';
type eventActionValueTypes = 'valid' | 'invalid' | string;

type calculatorFields =
    | 'form-field-postalCode'
    | 'form-field-house-number'
    | 'form-field-number-suffix'
    | 'form-field-power-usage'
    | 'form-field-gas-usage'
    | 'ik-heb-geen-stroom'
    | 'ik-wil-ook-stroom'
    | 'ik-heb-geen-gas'
    | 'ik-wil-ook-gas'
    | 'help-mij-met-jaarverbruik-invullen'
    | 'wek-je-stroom-op-?'
    | 'wek-je-stroom-op-ja'
    | 'wek-je-stroom-op-nee'
    | 'form-field-power-generate-kwh'
    | 'form-field-power-generate-panels'
    | 'klik-hier-het-aantal-kwhs-in-te-vullen'
    | 'klik-hier-en-vul-het-aantal-zonnepanelen-in'
    | 'hoeveel-bewoners-zijn-er-?'
    | 'wat-is-het-type-van-de-woning-?'
    | 'hoeveel-bewoners-zijn-er-?'
    | 'welke-energieproducten-wil-je-afnemen-?'
    | 'bereken-energieverbruik'
    | 'bereken-maandbedrag';

export interface CalculatorEventType {
    event: typeof userInteractionEvent;
    type: typeof formEventType;
    field: calculatorFields;
    component: typeof calculatorComponent;
    action: eventActionTypes;
    actionValue: eventActionValueTypes;
}

export const newCalculatorEvent = (
    field: calculatorFields,
    action: eventActionTypes,
    actionValue: eventActionValueTypes = '',
): CalculatorEventType => ({
    event: userInteractionEvent,
    type: formEventType,
    component: calculatorComponent,
    field,
    action,
    actionValue,
});

export const postalCodeChanged = (valid: boolean): CalculatorEventType =>
    newCalculatorEvent(
        'form-field-postalCode',
        'change',
        valid ? 'valid' : 'invalid',
    );
export const houseNumberChanged = (valid: boolean): CalculatorEventType =>
    newCalculatorEvent(
        'form-field-house-number',
        'change',
        valid ? 'valid' : 'invalid',
    );
export const numberSuffixChanged = (valid: boolean): CalculatorEventType =>
    newCalculatorEvent(
        'form-field-number-suffix',
        'change',
        valid ? 'valid' : 'invalid',
    );
export const powerUsageChanged = (valid: boolean): CalculatorEventType =>
    newCalculatorEvent(
        'form-field-power-usage',
        'change',
        valid ? 'valid' : 'invalid',
    );
export const gasUsageChanged = (valid: boolean): CalculatorEventType =>
    newCalculatorEvent(
        'form-field-gas-usage',
        'change',
        valid ? 'valid' : 'invalid',
    );
export const noPowerAvailableClicked = (): CalculatorEventType =>
    newCalculatorEvent('ik-heb-geen-stroom', 'click', 'valid');
export const powerAvailableClicked = (): CalculatorEventType =>
    newCalculatorEvent('ik-wil-ook-stroom', 'click', 'valid');
export const noGasAvailableClicked = (): CalculatorEventType =>
    newCalculatorEvent('ik-heb-geen-gas', 'click', 'valid');
export const gasAvailableClicked = (): CalculatorEventType =>
    newCalculatorEvent('ik-wil-ook-gas', 'click', 'valid');
export const helpWithUsageEstimationClicked = (): CalculatorEventType =>
    newCalculatorEvent('help-mij-met-jaarverbruik-invullen', 'click', 'valid');
export const powerDeliveryToolTipHovered = (): CalculatorEventType =>
    newCalculatorEvent('wek-je-stroom-op-?', 'hover', 'valid');
export const powerDeliveryQuestionYesClicked = (): CalculatorEventType =>
    newCalculatorEvent('wek-je-stroom-op-ja', 'click', 'valid');
export const powerDeliveryQuestionNoClicked = (): CalculatorEventType =>
    newCalculatorEvent('wek-je-stroom-op-nee', 'click', 'valid');
export const powerDeliveryAmountOfPanelsChanged = (
    valid: boolean,
): CalculatorEventType =>
    newCalculatorEvent(
        'form-field-power-generate-panels',
        'change',
        valid ? 'valid' : 'invalid',
    );
export const powerDeliveryAmountOfkWhsChanged = (
    valid: boolean,
): CalculatorEventType =>
    newCalculatorEvent(
        'form-field-power-generate-kwh',
        'change',
        valid ? 'valid' : 'invalid',
    );
export const powerDeliveryAmountOfPanelsClicked = (): CalculatorEventType =>
    newCalculatorEvent(
        'klik-hier-en-vul-het-aantal-zonnepanelen-in',
        'click',
        'valid',
    );
export const powerDeliveryAmountOfkWhsClicked = (): CalculatorEventType =>
    newCalculatorEvent(
        'klik-hier-het-aantal-kwhs-in-te-vullen',
        'click',
        'valid',
    );
export const helpWithUsageEstimationTypeOfBuildingClicked = (
    value: eventActionValueTypes,
): CalculatorEventType =>
    newCalculatorEvent('wat-is-het-type-van-de-woning-?', 'click', value);
export const helpWithUsageEstimationAmountInHouseholdsClicked = (
    value: eventActionValueTypes,
): CalculatorEventType =>
    newCalculatorEvent('hoeveel-bewoners-zijn-er-?', 'click', value);
export const helpWithUsageEstimationTypeOfProductsClicked = (
    value: eventActionValueTypes,
): CalculatorEventType =>
    newCalculatorEvent(
        'welke-energieproducten-wil-je-afnemen-?',
        'click',
        value,
    );
export const calculateEnergyEstimationsClicked = (): CalculatorEventType =>
    newCalculatorEvent('bereken-energieverbruik', 'click', 'valid');
export const calculateOfferClicked = (
    value: eventActionValueTypes,
): CalculatorEventType =>
    newCalculatorEvent('bereken-maandbedrag', 'click', value);
