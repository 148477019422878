import axios from 'axios';
import { call, put, takeLatest } from 'redux-saga/effects';
import { Router } from '@vaadin/router';
import {
    REQUEST_CREATE_USER_SNAPSHOT,
    REROUTE_USER,
    requestCreateUserSnapshotSuccess,
    requestCreateUserSnapshotFailed,
} from '../actions/userSnapshot';
import headers from './helpers/headers';

/**
 * Create userSnapshot fetcher
 * @param {Object} action
 */
function* fetchCreateUserSnapshot(action) {
    const { user, order } = window.store.getState();

    /** @type {String} */
    const userId = user.id;

    let res = '';
    if (order && order.labels) {
        for (let index = 0; index < order.labels.length; index += 1) {
            if (index === 0) {
                res += `?${order.labels[index][0]}=${order.labels[index][1]}`;
            } else {
                res += `&${order.labels[index][0]}=${order.labels[index][1]}`;
            }
        }
    }

    try {
        if (!userId) throw user;
        const result = yield call(async () => {
            const response = await axios.post(
                `/v1/user/${userId}/snapshot/create${res}`,
                { ...action.payload },
                {
                    baseURL: window.API_LINK,
                    headers: headers(STDHeaders),
                },
            );
            if (response.statusText === 'OK' || response.status === 200) {
                return { data: response.data };
            }
            throw response.status;
        });
        yield put(requestCreateUserSnapshotSuccess(result.data));
    } catch (e) {
        yield put(requestCreateUserSnapshotFailed(e));
    }
}

/**
 * Watcher for REQUEST_CREATE_USER_SNAPSHOT action
 */
export function* watchRequestCreateUserSnapshot() {
    yield takeLatest(REQUEST_CREATE_USER_SNAPSHOT, fetchCreateUserSnapshot);
}

/**
 * Callback for rerouting
 * @param {Object} action
 */
function* reRoute(action) {
    yield put({ type: 'REROUTED_USER', route: action.route });
    Router.go(action.route);
}

/**
 * Watcher for REROUTE_USER action
 */
export function* watchRerouteUser() {
    yield takeLatest(REROUTE_USER, reRoute);
}
