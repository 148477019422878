import { takeLatest, ForkEffect } from 'redux-saga/effects';
import { Capacitor } from '@capacitor/core';
import { downloadDocument } from '../store';
import { handleDownload } from '../helpers/download';
import { SuccessAction } from 'goagen-js-resource';

export type downloadObject = {
    id: string;
    name: string;
    pdf: string;
};

/**
 * When downloading a pdf is failed
 */
const downloadFailed = (): void => {
    console.warn('Downloading the document has failed.');
    // @ts-ignore
    window.displayMessage(
        'Kan PDF niet downloaden, probeer het later nog eens.',
    );
};

/**
 * When download pdf is a succes
 */
const downloadSucces = (docSucces: SuccessAction): void => {
    const docDownload = docSucces.data as downloadObject;
    const anchor = document.createElement('a');
    anchor.href = `data:application/pdfoctet-stream;base64,${docDownload.pdf}`;
    anchor.target = '_blank';
    if (anchor.download) {
        anchor.download = docDownload.name;
    }
    const isNative = Capacitor.isNativePlatform();

    if (isNative) {
        anchor.addEventListener('click', (e) => handleDownload(e));
    }

    if (!isNative && (anchor.download == null || anchor.download === '')) {
        if (docDownload.name && docDownload.name !== '') {
            anchor.download = docDownload.name;
        } else {
            anchor.download = `${docDownload.name}_${docDownload.id}.pdf`;
        }
    }

    anchor.click();
};

/**
 * Watcher for watchDownload.REQUEST_FAILED and watchDownload.REQUEST_SUCCESS action
 */
export function* watchDownload(): IterableIterator<ForkEffect> {
    yield takeLatest(downloadDocument.REQUEST_FAILED, downloadFailed);
    yield takeLatest(downloadDocument.REQUEST_SUCCESS, downloadSucces);
}
