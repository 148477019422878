/**
 * Create down-facing triangle div
 * @return {HTMLDivElement} div with down-facing triangle
 */
const createTriangleDiv = () => {
    const triangle = document.createElement('div');
    triangle.id = 'triangle';
    triangle.classList.add('arrow-down');

    const triangleWrapper = document.createElement('div');
    triangleWrapper.id = 'triangle-wrapper';
    triangleWrapper.appendChild(triangle);

    return triangleWrapper;
};

/**
 * Create element on first render
 * @param {HTMLElement|null} tooltipEl div gotten from the DOM
 * @return {HTMLElement} div creaeted if not existed already
 */
const createTooltipIfNotExisting = (tooltipEl) => {
    let _tooltipEl = tooltipEl;
    if (!tooltipEl) {
        _tooltipEl = document.createElement('div');
        _tooltipEl.id = 'chartjs-tooltip';
        _tooltipEl.innerHTML = '<table></table>';
        _tooltipEl.appendChild(createTriangleDiv());
        document.body.appendChild(_tooltipEl);
    }
    return _tooltipEl;
};

const topCorrection = 105;
/**
 * Positions the tooltip div correctly above bar chart
 * @param {Object} tooltipModel currently selected model from chart
 * @param {HTMLElement} tooltipEl div on the page
 * @param {Number} top currentTop of bar chart
 * @param {Number} extraMargin additional margin in case menu is open
 */
const positionToolTipIfBiggerThanInnerWidth = (
    tooltipModel,
    tooltipEl,
    top,
    extraMargin,
) => {
    const _tooltipEl = tooltipEl; // create second reference so that original doesnt mutate.
    const offSetRightOffScreen = -2; // 35 = offset triangle from tooltip left, 95 = tooltip height + 15
    const minusExtraToStayInScreen =
        tooltipModel.caretX + _tooltipEl.offsetWidth - window.innerWidth;
    const toolTipLeft =
        offSetRightOffScreen +
        tooltipModel.caretX -
        minusExtraToStayInScreen +
        extraMargin;
    _tooltipEl.style.left = `${toolTipLeft}px`;
    _tooltipEl.style.top = `${top + tooltipModel.caretY - topCorrection}px`;
    const triangle = document.getElementById('triangle');
    const chartTooltip = document.getElementById('chartjs-tooltip');
    if (chartTooltip != null) {
        const maxLeft = chartTooltip.offsetWidth - 51;
        const triangleLeft =
            tooltipModel.caretX - toolTipLeft - 35 < maxLeft
                ? tooltipModel.caretX - toolTipLeft - 35
                : maxLeft;
        if (triangle != null) {
            triangle.style.left = `${triangleLeft}px`;
        }
    }
};

/**
 * Positions the tooltip div correctly above bar chart
 * @param {Object} tooltipModel currently selected model from chart
 * @param {HTMLElement} tooltipEl div on the page
 * @param {Number} top currentTop of bar chart
 * @param {Number} canvasViewportOffset additional margin in case menu is open
 */
const positionTooltipSmallerThanInnerWidth = (
    tooltipModel,
    tooltipEl,
    top,
    canvasViewportOffset,
) => {
    const _tooltipEl = tooltipEl; // create second reference so that original doesnt mutate.
    const triangle = document.getElementById('triangle');
    let triangleLeft = tooltipModel.caretX - 45; // 45 = 10 + 35 extra offset triangle from tooltip left
    if (triangleLeft < -20) {
        // -20 is half of triangle border
        triangleLeft = -20;
    }
    if (triangle != null) {
        triangle.style.left = `${triangleLeft}px`;
    }
    _tooltipEl.style.left = `${canvasViewportOffset + 10}px`;
    _tooltipEl.style.top = `${top + tooltipModel.caretY - topCorrection}px`; // 95 = tooltip height + 15
};

/**
 * Calculate the offset of the triangle relative to the graph
 * @return {{left: number, width: number, height: number}}
 */
const getTriangleOffset = () => {
    const triangleEl = document.getElementById('triangle');
    if (!triangleEl || !triangleEl.parentElement) {
        return { left: 0, width: 0, height: 0 };
    }
    return {
        left: triangleEl.parentElement.offsetLeft + triangleEl.offsetWidth / 2,
        width: triangleEl.offsetWidth,
        height: triangleEl.offsetHeight,
    };
};

/**
 * Positions the tooltip div correctly above bar chart
 * @param {Object} tooltipModel currently selected model from chart
 * @param {HTMLElement} tooltipEl div on the page
 * @param {Number} top currentTop of bar chart
 * @param {Number} canvasViewportOffset additional margin in case menu is open
 */
const positionTooltipDynamically = (
    tooltipModel,
    tooltipEl,
    top,
    canvasViewportOffset,
) => {
    const _tooltipEl = tooltipEl; // create second reference so that original doesnt mutate.
    // LEFT POSITION
    const trianglePosition = getTriangleOffset(); // Offset triangle relative to the tooltip
    // MOBILE ONLY
    const toolTipPosition =
        canvasViewportOffset +
        tooltipModel.caretX -
        trianglePosition.left +
        _tooltipEl.offsetWidth;
    if (window.innerWidth > toolTipPosition) {
        const mobileOffset =
            window.innerWidth -
            canvasViewportOffset +
            tooltipModel.caretX -
            trianglePosition.left +
            _tooltipEl.offsetWidth;
        _tooltipEl.style.left = `${toolTipPosition - mobileOffset}px`; //  Position for Desktop
    }
    _tooltipEl.style.left = `${
        canvasViewportOffset + tooltipModel.caretX - trianglePosition.left
    }px`; //  Position for Desktop
    // TOP POSITION
    _tooltipEl.style.top = `${
        top -
        _tooltipEl.offsetHeight -
        trianglePosition.height / 2 +
        tooltipModel.caretY
    }px`;
};
/**
 * Positions the tooltip div correctly above bar chart
 * @param {Object} tooltipModel currently selected model from chart
 * @param {HTMLElement} tooltipEl div on the page
 * @param {Object} position Current chart Bounding rectangle
 * @param {HTMLElement} canvas the canvas html element
 */
const positionToolTip = (tooltipModel, tooltipEl, position, canvas) => {
    const canvasViewportOffset = canvas.getBoundingClientRect().left;
    if (tooltipModel.caretX + tooltipEl.offsetWidth > window.innerWidth) {
        positionToolTipIfBiggerThanInnerWidth(
            tooltipModel,
            tooltipEl,
            position.top,
            canvasViewportOffset,
        );
    } else if (tooltipModel.caretX < 40) {
        positionTooltipSmallerThanInnerWidth(
            tooltipModel,
            tooltipEl,
            position.top,
            canvasViewportOffset,
        );
    } else {
        positionTooltipDynamically(
            tooltipModel,
            tooltipEl,
            position.top,
            canvasViewportOffset,
        );
    }
};

export function getBody(bodyItem) {
    return bodyItem.lines;
}

const setElementText = (tooltipModel, tooltipEl, reverse) => {
    // Set Text
    if (tooltipModel.body) {
        let titleLines = tooltipModel.title || [];
        const bodyLines = tooltipModel.body.map(getBody);
        const { labelColors } = tooltipModel;
        if (reverse) {
            bodyLines.reverse();
            labelColors.reverse();
            titleLines = titleLines.reverse();
        }

        let innerHtml = '<thead>';

        titleLines.forEach((title) => {
            innerHtml += `<tr><th>${title}</th></tr>`;
        });
        innerHtml += '</thead><tbody>';

        const b = tooltipModel.body;
        let costIndex = 1;
        let cost = b.length > 1 ? b[1].cost : null;
        // checks if dataset has cost to true to set the after-cost class
        if (cost == null) {
            for (let j = 0; j < b.length; j += 1) {
                if (b[j].cost) {
                    costIndex = j;
                    cost = true;
                    break;
                }
            }
        }
        bodyLines.forEach((body, i) => {
            if (typeof body[0] !== 'string' || body[0].trim() !== '€ 0,00') {
                let index = i;
                const costAndReverse = cost && reverse && i > 0;
                if (costAndReverse && i === 1) {
                    index = 0;
                } else if (costAndReverse) {
                    index = i - 1;
                }
                // fallback for index out of range.
                if (index >= labelColors.length) {
                    index -= 1;
                }
                const colors = labelColors[index];
                const style = `color:${colors.backgroundColor}`;
                const costClass = cost && i > costIndex ? 'after-cost' : '';
                const span = `<span style="${style}" class="${costClass}">`;
                innerHtml += `<tr><td>${span}${body}</span></td></tr>`;
            }
        });
        innerHtml += '</tbody>';

        const tableRoot = tooltipEl.querySelector('table');
        tableRoot.innerHTML = innerHtml;
    }
};

export const hideToolTip = () => {
    const tooltipEl = document.getElementById('chartjs-tooltip');
    if (tooltipEl) {
        tooltipEl.style.opacity = 0;
    }
};

/**
 * Fixes labels for graph
 * @param {Object} tooltip model for tooltips in graph
 * @param {String[]} color is the hex of a color the tooltips should be
 * @param {Object} currentChart current created chart on the page
 * @param {HTMLCanvasElement} canvas the canvas html element
 */
export const customToolTip = (
    tooltipModel,
    color,
    currentChart,
    canvas,
    reverse = true,
) => {
    // Tooltip Element
    let tooltipEl = document.getElementById('chartjs-tooltip');
    tooltipEl = createTooltipIfNotExisting(tooltipEl);

    // Hide if no tooltip
    if (tooltipModel.opacity === 0) {
        tooltipEl.classList.add('hide-tooltip');
        tooltipEl.style.opacity = 0;
        return;
    }
    if (tooltipEl.classList.contains('hide-tooltip')) {
        tooltipEl.classList.remove('hide-tooltip');
    }

    // Add body
    setElementText(tooltipModel, tooltipEl, reverse);

    // Set caret Position
    tooltipEl.classList.remove('above', 'below', 'no-transform');
    if (tooltipModel.yAlign) {
        tooltipEl.classList.add(tooltipModel.yAlign);
    } else {
        tooltipEl.classList.add('no-transform');
    }
    if (!tooltipEl) {
        console.warn('undefined tooltip element');
        return;
    }

    // Display, position, and set styles for font
    tooltipEl.style.opacity = 1;

    const position = currentChart.canvas.getBoundingClientRect();
    positionToolTip(tooltipModel, tooltipEl, position, canvas);
};
