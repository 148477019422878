import { CostType } from 'types/cost';
import enums from '../enums';
import { VAT } from './vat';

const usageReducer = (accumulator, currentValue) =>
    accumulator + currentValue.reading;

// Get cost price with btw included and fallback to default vat if not exists
const getCostTotalIncl = (cost) => {
    if (!cost.total_incl) {
        console.warn(
            `Missing total inclusief for cost, use default VAT ${VAT}`,
        );
    }
    return cost.total_incl || cost.total_excl + cost.total_excl * VAT;
};

const getReadingDate = (elecItem, gasItem, prodItem) => {
    /**
     * @typedef CostType
     */
    const costItem = elecItem ?? gasItem ?? prodItem;

    if (!costItem) {
        throw new Error('cost item');
    }

    return costItem.reading_date;
};

export const multipleCostsToUsage = (elec, gas, prod) => {
    const maxLength = Math.max(elec.length, gas.length, prod.length);
    if (maxLength <= 0) return [];
    return Array(maxLength)
        .fill(0)
        .map((_, index) => {
            const elecItem = elec[index];
            const gasItem = gas[index];
            const prodItem = prod[index];

            const reading_date = getReadingDate(elecItem, gasItem, prodItem);

            let calculatedCostExcl = 0;
            let calculatedCostIncl = 0;

            if (elecItem?.total_excl) {
                calculatedCostExcl += elecItem.total_excl;
                calculatedCostIncl += getCostTotalIncl(elecItem);
            }
            if (gasItem?.total_excl) {
                calculatedCostExcl += gasItem.total_excl;
                calculatedCostIncl += getCostTotalIncl(gasItem);
            }
            if (prodItem?.total_excl) {
                calculatedCostExcl -= prodItem.total_excl;
                calculatedCostIncl -= getCostTotalIncl(prodItem);
            }

            return {
                business: !!elecItem?.business,
                cost: calculatedCostExcl,
                reading: calculatedCostIncl,
                reading_date,
                usage_type: enums.UsageTypeCostSavings,
            };
        });
};

/**
 * Header to obj
 * @param {Array} usages
 * @return {String}
 */
export const calculateMonthlyUsages = (usages) => {
    const totalUsages = usages.reduce(usageReducer, 0);
    return totalUsages;
};

/**
 * Header to obj
 * @param {String} cost
 * @param {String} estimate
 * @return {String}
 */
export const calculateTotalSavings = (cost, estimate) =>
    (Number(cost) - Number(estimate)).toString();
