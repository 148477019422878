import { awaitLogin } from '@actions/routing';
import { authCheck, receiveSelf, signIn } from '@async-reducers/authentication';
import {
    Commands,
    ComponentResult,
    Context,
    RedirectResult,
    Route,
} from '@vaadin/router';
import { provider } from '@weavelab/frontend-connect';
import enums from 'enums';
import { Offers } from 'types/offers';
import { Order } from 'types/order';
import { Location, Snapshot } from 'types/snapshot';
import { injectForm } from 'helpers/formInjector';
import { setShowMenu } from '../actions/menu';
import { orderRetentionOffer } from '../actions/orderRetentionOffer';
import { setSelectedSnapshotID } from '../actions/snapshot';
import { store } from '../store';
import { contractFactory } from './classes/Contract';
import { getSnapshotsForRetention } from './snapshot';

// Forms
import '../forms/ChangePassword';
import '../forms/Forgot';
import '../forms/Login';
import '../forms/Reset';
import { datesAreOnSameMonth } from './dates';

const themeSettings = provider.settings('app');

export const routePaths = {
    start: '/start',
    explanation: '/explanation',
    forgot: '/forgot',
    resetPassword: '/resetpw',
    // protectedRoute
    overview: '/',
    electricity: '/stroom',
    energyCost: '/energiekosten',
    energySavings: '/besparing',
    energyStandings: '/tussenstand',
    smartCharging: '/slim-laden',
    gas: '/gas',
    production: '/teruglevering',
    settings: '/settings',
    editSettings: '/settings/edit',
    editContactDetailsSettings: '/settings/edit/contact-details',
    editCompetitionAliasSettings: '/settings/edit/competition-alias',
    editAddressSettings: '/settings/edit/address',
    editMonthlyPaymentSettings: '/settings/edit/monthly-payment',
    editPasswordSettings: '/settings/edit/password',
    editPaymentMethodSettings: '/settings/edit/payment-method',
    alreadyCustomer: '/al-klant',
    lander: '/lander',
    login: '/login',
    invoices: '/facturen',
    energySplitter: '/energiesplitter',
    customerOld: '/klantoud',
    customer: '/klant',
    customerProductList: '/klant/product-list',
    customerForm: '/klant/customer-form',
    customerOverview: '/klant/overview',
    customerSubOverview: '/overview',
    customerSubSubSuccess: '/success',
    chargingSessions: '/laadsessies',
    hourPrices: '/uurtarieven',
    currentPrices: '/actuele-tarieven',
    excuse: '/excuus',
    dashboard: '/dashboard',
    oda: '/oda',
};

interface Pages {
    orderOverview: ComponentResult | undefined;
}

export const pages: Pages = {
    orderOverview: undefined,
};

const customerChildRoutes: Route[] = [
    {
        action: async (_: Context, commands: Commands) => {
            import(
                /* webpackChunkName: "epicFlow" */ '../components/containers/ez-epic-flow/customer-form/customer-form'
            );
            return await shouldSkipCustomerFormCheck(_, commands);
        },
        component: 'customer-form',
        path: '/customer-form',
    },
    {
        action: () => {
            import(
                /* webpackChunkName: "epicFlow" */ '../components/containers/ez-epic-flow/product-list/product-list'
            );
        },
        component: 'product-list',
        path: '/product-list',
    },
    {
        action: (_, commands: Commands) => {
            import(
                /* webpackChunkName: "epicFlow" */ '../components/containers/ez-epic-flow/order-overview/order-overview'
            );
            if (!pages.orderOverview) {
                pages.orderOverview = commands.component('order-overview');
            }
            return pages.orderOverview;
        },
        path: `${routePaths.customerSubOverview}/:id`,
    },
    {
        action: (_, commands: Commands) => {
            import(
                /* webpackChunkName: "epicFlow" */ '../components/containers/ez-epic-flow/order-overview/order-overview'
            );
            if (!pages.orderOverview) {
                pages.orderOverview = commands.component('order-overview');
            }
            return pages.orderOverview;
        },
        path: `${routePaths.customerSubOverview}/:id${routePaths.customerSubSubSuccess}`,
    },
    {
        action: (_, commands: Commands) => {
            import(
                /* webpackChunkName: "epicFlow" */ '../components/containers/ez-epic-flow/order-overview/order-overview'
            );
            if (!pages.orderOverview) {
                pages.orderOverview = commands.component('order-overview');
            }
            return pages.orderOverview;
        },
        path: routePaths.customerSubOverview,
    },
    {
        action: () => {
            import(
                /* webpackChunkName: "epicFlow" */ '../components/containers/ez-epic-flow/calculation-tool/calculation-tool'
            );
        },
        component: 'calculation-tool',
        path: '(.*)',
    },
];

const editSettingChilds: Route[] = [
    {
        action: async (_: Context, commands: Commands) => {
            import(
                /* webpackChunkName: "settings" */ '../components/molecules/settingsEdit'
            );

            return await protectedRouteCheck(_, commands);
        },
        path: routePaths.overview,
        component: 'settings-edit',
    },
    {
        action: async (_: Context, commands: Commands) => {
            import(
                /* webpackChunkName: "settings" */ '../components/containers/ez-user-settings/user-settings-edit/user-settings/contact-details/index'
            );
            return await protectedRouteCheck(_, commands);
        },
        path: '/contact-details',
        component: 'settings-edit-contact-details',
    },
    {
        action: async (_: Context, commands: Commands) => {
            import(
                /* webpackChunkName: "settings" */ '../components/containers/ez-user-settings/user-settings-edit/user-settings/address/index'
            );
            return await protectedRouteCheck(_, commands);
        },
        path: '/address',
        component: 'settings-edit-address',
    },
    {
        action: async (_: Context, commands: Commands) => {
            import(
                /* webpackChunkName: "settings" */ '../components/containers/ez-user-settings/user-settings-edit/user-settings/competition-alias/index'
            );
            return await protectedRouteCheck(_, commands);
        },
        path: '/competition-alias',
        component: 'settings-edit-competition-alias',
    },

    {
        action: async (_: Context, commands: Commands) => {
            import(
                /* webpackChunkName: "settings" */ '../containers/monthly-payments/index'
            );
            return await protectedRouteCheck(_, commands);
        },
        path: '/monthly-payment',
        component: 'settings-edit-monthly-payment',
    },
    {
        action: async (_: Context, commands: Commands) => {
            import(
                /* webpackChunkName: "settings" */ '../components/containers/ez-user-settings/user-settings-edit/user-settings/payment-method/index'
            );
            return await protectedRouteCheck(_, commands);
        },
        path: '/payment-method',
        component: 'settings-edit-payment-method',
    },
    {
        action: async (_: Context, commands: Commands) => {
            import(
                /* webpackChunkName: "settings" */ '../components/containers/ez-user-settings/user-settings-edit/user-settings/password/index'
            );
            injectForm('ice-change-password');
            return await protectedRouteCheck(_, commands);
        },
        path: '/password',
        component: 'settings-edit-password',
    },
];

export const mainRoutes: Route[] = [
    // unprotected routes
    {
        action: async (_: Context, commands: Commands) => {
            import(
                /* webpackChunkName: "lander" */ '../components/containers/ez-lander/lander'
            );
            injectForm('ice-login');
            return await protectedRouteCheck(_, commands);
        },
        component: 'ez-lander',
        path: routePaths.login,
    },
    {
        path: routePaths.lander,
        action: async () => {
            import(
                /* webpackChunkName: "lander" */ '../components/containers/ez-login/login'
            );
            injectForm('ice-login');
        },
        component: 'ez-login',
    },
    // TODO: refactor klant onboarding
    {
        // Independer middleware
        action: async (context: Context, commands: Commands) =>
            await onboardingMiddleware(context, commands),
        path: routePaths.customer,
        children: customerChildRoutes,
    },
    {
        action: async (_, commands: Commands) => {
            import(
                /* webpackChunkName: "epic-flow" */ '../components/containers/ez-epic-flow/epic-flow'
            );
            return await protectedRouteCheck(_, commands);
        },
        component: 'ez-epic-flow',
        path: routePaths.start,
    },
    {
        action: async () => {
            import(
                /* webpackChunkName: "forgot-password" */ '../views/pages/ForgotPassword'
            );
            injectForm('ice-forgot');
        },
        component: 'forgot-password',
        path: routePaths.forgot,
    },
    {
        action: async (context: Context, commands: Commands) => {
            import(
                /* webpackChunkName: "reset-password" */ '../components/containers/ez-reset-password/reset-password'
            );
            injectForm('ice-reset');
            return await onboardingMiddleware(context, commands);
        },
        component: 'ez-reset-password',
        path: `${routePaths.resetPassword}/:token`,
    },
    // proctected routes
    {
        action: async (_: Context, commands: Commands) => {
            import(
                /* webpackChunkName: "electricity" */ '../components/containers/ez-electricity/electricity'
            );
            return await protectedRouteCheck(_, commands);
        },
        component: 'ez-electricity',
        path: routePaths.electricity,
    },
    {
        action: async (_: Context, commands: Commands) => {
            import(
                /* webpackChunkName: "costs" */ '../components/containers/ez-energy-costs/ez-energy-costs'
            );
            return await protectedRouteCheck(_, commands);
        },
        component: 'ez-energy-costs',
        path: routePaths.energyCost,
    },
    {
        action: async (_: Context, commands: Commands) =>
            commands.redirect(routePaths.energyStandings),
        path: routePaths.energySavings,
    },
    {
        action: async (_: Context, commands: Commands) => {
            import(
                /* webpackChunkName: "standings" */ '../components/containers/ez-standings/ez-standings'
            );
            // prevent loading the page if the snapshot started within the current month
            const { selectedSnapshot } = window.store.getState().jwtSnapshots;
            if (selectedSnapshot) {
                const snapshotJustStarted = datesAreOnSameMonth(
                    new Date(),
                    selectedSnapshot.verified_snapshot_payload.start_date,
                );
                if (snapshotJustStarted) {
                    return commands.redirect(routePaths.overview);
                }
            }
            return await protectedRouteCheck(_, commands);
        },
        component: 'ez-standings',
        path: routePaths.energyStandings,
    },
    {
        action: async (_: Context, commands: Commands) => {
            import(
                /* webpackChunkName: "gas" */ '../components/containers/ez-gas/gas'
            );
            return await protectedRouteCheck(_, commands);
        },
        component: 'ez-gas',
        path: routePaths.gas,
    },
    {
        action: async (_: Context, commands: Commands) => {
            import(
                /* webpackChunkName: "production" */ '../components/containers/ez-production/production'
            );
            return await protectedRouteCheck(_, commands);
        },
        component: 'ez-production',
        path: routePaths.production,
    },
    {
        action: async (_: Context, commands: Commands) => {
            import(
                /* webpackChunkName: "smartcharge" */ '../views/pages/SmartCharging'
            );
            return await protectedRouteCheck(_, commands);
        },
        component: 'smart-charging',
        path: routePaths.smartCharging,
    },
    {
        children: [
            {
                path: routePaths.overview,
                component: 'settings-display-container',
                action: async (_: Context, commands: Commands) => {
                    import(
                        /* webpackChunkName: "settings" */ '../components/containers/ez-user-settings/user-settings-display/settingsDisplayContainer'
                    );
                    return await protectedRouteCheck(_, commands);
                },
            },
            {
                children: editSettingChilds,
                path: '/edit',
            },
        ],
        path: routePaths.settings,
    },
    {
        action: async (_: Context, commands: Commands) => {
            import(
                /* webpackChunkName: "settings" */ '../components/containers/ez-user-settings/user-settings-display/settingsDisplayContainer'
            );
            return await protectedRouteCheck(_, commands);
        },
        component: 'settings-display-container',
        path: routePaths.settings,
    },
    {
        action: async (_: Context, commands: Commands) => {
            import(
                /* webpackChunkName: "invoices" */ '../views/pages/Invoices'
            );
            return await protectedRouteCheck(_, commands);
        },
        component: 'ez-invoices',
        path: routePaths.invoices,
    },
    {
        action: async (_: Context, commands: Commands) => {
            import(
                /* webpackChunkName: "charging-sessions" */ '../components/containers/ez-charging-sessions/chargingSessions'
            );
            return await protectedRouteCheck(_, commands);
        },
        component: 'charging-sessions',
        path: routePaths.chargingSessions,
    },
    {
        action: async (_: Context) => {
            import(/* webpackChunkName: "excuse" */ '../views/pages/Excuse');
            return undefined;
        },
        component: 'hv-excuse',
        path: routePaths.excuse,
    },

    /**
     * ODA
     */
    {
        path: routePaths.dashboard,
        children: [
            {
                action: async (context: Context, commands: Commands) => {
                    import(
                        /* webpackChunkName: "dashboard-wrapper" */ '../views/pages/DashboardWrapper'
                    );
                    return await odaMiddleware(
                        context,
                        commands,
                        routePaths.dashboard,
                    );
                },
                path: '/',
                component: 'dashboard-wrapper',
            },
            {
                action: async (context: Context, commands: Commands) => {
                    import(
                        /* webpackChunkName: "oda" */ '../views/pages/OdaPage'
                    );
                    return await odaMiddleware(
                        context,
                        commands,
                        routePaths.oda,
                    );
                },
                path: '/oda',
                component: 'oda-page',
            },
        ],
    },
];

export const hasAuthParam = (
    location: string | null,
    awLogin: boolean,
): boolean => {
    if (location == null) {
        return false;
    }
    // fallback for old urls
    const loc = location.replace('/#/', '/');
    const { searchParams } = new URL(loc);
    // prevent fetching for the second time if sign has an error or is waiting
    if (awLogin || store.getState().signIn.error > 300) {
        return true;
    }
    if (searchParams.has('auth')) {
        // checks if auth param exists
        store.dispatch(awaitLogin(true));
        localStorage.clear();
        localStorage.setItem('location', JSON.stringify(location));
        const decoded = atob(searchParams.get('auth')!);
        const authDetails = JSON.parse(decoded);
        if (authDetails && authDetails.email && authDetails.password) {
            // if snapshot id is given activate that specific snapshot
            if (searchParams.has('snapshot_id')) {
                const snapshotID = searchParams.get('snapshot_id');
                if (snapshotID) {
                    store.dispatch(setSelectedSnapshotID(snapshotID));
                }
            }
            store.dispatch(authCheck.run(true));
            store.dispatch(signIn.run(authDetails.email, authDetails.password));
            return true;
        }
    }
    return false;
};

const checkAuth = (
    context: Context,
    commands: Commands,
    showMenu: boolean,
): RedirectResult | null | undefined | '' => {
    const awLogin = window.store.getState().awaitLogin;
    const hasAuth = hasAuthParam(window.location.href, awLogin);
    const auth = localStorage.getItem('auth');
    // if auth is missing redirect to the login page and hide menu
    if (auth === null && hasAuth) {
        return undefined;
    }
    if (auth == null) {
        if (showMenu) {
            window.store.dispatch(setShowMenu(false));
        }

        if (context.pathname.includes('login')) {
            // prevent update if page is login
            return null;
        }
        // redirect if page is not login.
        return commands.redirect(routePaths.login);
    }
    if (auth && !awLogin) {
        // only receive self if auth exists and authCheck is false.
        const authC = store.getState().authCheck;
        if (!authC.data && !authC.busy) {
            window.store.dispatch(authCheck.run(true));
            if (showMenu) {
                window.store.dispatch(setShowMenu(false));
            }
            window.store.dispatch(receiveSelf.run());
            return null;
        }
    }

    return '';
};

export const protectedRouteCheck = async (
    context: Context,
    commands: Commands,
) => {
    if (window.store.getState().awaitLogin) {
        return commands.prevent();
    }
    const { showMenu } = window.store.getState();
    const auth = checkAuth(context, commands, showMenu);
    if (auth !== '') {
        return auth;
    }

    const disableMenu = ['klant', 'login', 'dashboard'];
    if (
        !showMenu &&
        !disableMenu.includes(window.location.pathname.substring(0))
    ) {
        window.store.dispatch(setShowMenu(true));
    } else if (!showMenu) {
        window.store.dispatch(setShowMenu(false));
    }

    // TODO: validate if still required
    if (context.pathname === '/') {
        const { selectedSnapshot } = window.store.getState().jwtSnapshots;
        if (!selectedSnapshot) {
            return undefined;
        }
        const contract = contractFactory(selectedSnapshot);
        if (contract.contractType === 'ODA') {
            return commands.redirect(routePaths.dashboard);
        }
    }

    return undefined;
};

/**
 * shouldSkipCustomerFormCheck checks wether a customer is renewing its contract for an existing address
 * in this case we need to skip the customer form to make the onboarding faster and easier for the user
 * the contract details can always be changed afterward in the customer dashboard settings
 */
export const shouldSkipCustomerFormCheck = async (
    context: Context,
    commands: Commands,
) => {
    const {
        offers,
        order,
        jwtSnapshots,
        routing,
    }: { offers: Offers; order: Order; jwtSnapshots: any; routing: any } =
        window.store.getState();
    // If customer is coming from the overview/{snapshot_id} route we should
    // redirect to product offerings list instead
    if (
        context?.pathname?.includes('/klant/customer-form') &&
        routing?.location?.pathname?.includes('/klant/overview') &&
        routing?.location?.params?.id?.length > 0
    ) {
        return commands.redirect(routePaths.customerProductList);
    }

    const geoInfo = order.geoInformation;
    const orderLocation: Location = {
        area_code: order.postalCode?.toUpperCase(),
        house_addition: order.houseNumberSuffix?.toUpperCase(),
        house_number: order.houseNumber,
        city: geoInfo ? geoInfo.city : '',
        id: '',
        street_name: geoInfo ? geoInfo.street_name : '',
    };

    // Check user state if a retention contract onboarding is going on
    const { snapshots } = jwtSnapshots;
    const retentionSnapshots = getSnapshotsForRetention(
        snapshots,
        orderLocation,
    );

    // If a retention onboarding snapshot is found trigger the state and saga will handle the rest
    if (retentionSnapshots.length === 1) {
        // Create new snapshot with chosen offer
        window.store.dispatch(
            orderRetentionOffer.run({
                snapshot: retentionSnapshots[0],
                offers,
                order,
                busy: true,
                enabled: true,
            }),
        );
        return undefined;
    }
    if (retentionSnapshots.length > 1) {
        const houseNumber = `${order.houseNumber}${
            order.houseNumberSuffix != null ? order.houseNumberSuffix : ''
        }`;
        window.displayMessage(
            `Er is al een contract met geplande levering voor adres ${order.postalCode} ${houseNumber}!`,
            'error',
        );
        return commands.redirect(routePaths.overview);
    }
    return undefined;
};

const whitelistedComponentsForOnboardingMiddleware = [
    'ez-reset-password',
    'ice-reset',
    'ice-forgot',
];

const onboardingMiddleware = (context: Context, commands: Commands) => {
    if (themeSettings && themeSettings.onboardingStopExcuse) {
        return commands.redirect(routePaths.excuse);
    }

    const { showOnboarding } = window.store.getState();
    if (!showOnboarding.data) {
        if (
            context?.route?.component &&
            whitelistedComponentsForOnboardingMiddleware.includes(
                context.route.component,
            )
        ) {
            return undefined;
        }
        return commands.redirect(routePaths.login);
    }
    return undefined;
};

const odaMiddleware = async (
    context: Context,
    commands: Commands,
    route: string,
) => {
    const { showMenu } = window.store.getState();
    const auth = checkAuth(context, commands, showMenu);
    if (auth !== '') {
        return auth;
    }

    const { selectedSnapshot } = window.store.getState().jwtSnapshots;
    if (!selectedSnapshot) {
        return commands.redirect(routePaths.login);
    }
    const contract = contractFactory(selectedSnapshot);
    if (contract.contractType !== 'ODA') {
        return commands.redirect(routePaths.login);
    }

    // prevent loading oda onboarding if contract hasn't the right snapshot phase.
    if (
        route === routePaths.dashboard &&
        (selectedSnapshot as Snapshot).snapshot_phase ===
            enums.SnapshotPhaseDemoContractCreated
    ) {
        const { skipped } = window.store.getState().setOdaOnboarding.data;
        if (!skipped) {
            return commands.redirect(routePaths.dashboard + routePaths.oda);
        }
    }
    if (route === routePaths.dashboard) {
        if (!showMenu) {
            window.store.dispatch(setShowMenu(true));
        }
    } else if (showMenu) {
        window.store.dispatch(setShowMenu(false));
    }
    return undefined;
};
