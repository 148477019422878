import { LitElement, TemplateResult, customElement, html } from 'lit-element';

import { faLock } from '@fortawesome/free-solid-svg-icons/faLock';
import { litFontawesome } from '@weavedev/lit-fontawesome';

@customElement('lock-icon')
export class LockIcon extends LitElement {
    public render = (): TemplateResult => {
        return html`
            <style>
                :host {
                    height: 24px;
                    color: #6b2fad;
                }
                .fa-lock {
                    width: 24px;
                    height: 24px;
                }
            </style>
            ${litFontawesome(faLock)}
        `;
    };
}

declare global {
    interface HTMLElementTagNameMap {
        'lock-icon': LockIcon;
    }
}
