import {
    REQUEST_SAVINGS,
    REQUEST_SAVINGS_FAILED,
    REQUEST_SAVINGS_SUCCESS,
    REQUEST_SAVINGS_FETCHED,
} from '../actions/savings';

const defaultGraphs = { data: [], raw: [], error: null, busy: false };

/**
 * State reducer for invoices request
 * @param {Object} state
 * @param {Object} action
 * @return {Object}
 */
export const savings = (state = { ...defaultGraphs }, action) => {
    switch (action.type) {
        case REQUEST_SAVINGS:
            return { ...state, busy: true };
        case REQUEST_SAVINGS_SUCCESS:
            return { ...state, data: action.data, busy: false, error: null };
        case REQUEST_SAVINGS_FETCHED:
            return { ...state, raw: action.data, busy: true, error: null };
        case REQUEST_SAVINGS_FAILED:
            return { ...state, busy: false, error: action.error };
        default:
            return state;
    }
};
