export const PREFIX = 'Collective: ';

export const REQUEST_COLLECTIVES = `${PREFIX}REQUEST_COLLECTIVES`;
/**
 * Action generator for request fetch collectives
 * @param {String} vendorId
 * @return {Object}
 */
export const requestCollectives = (vendorId) => ({
    type: REQUEST_COLLECTIVES,
    vendorId,
});

export const REQUEST_COLLECTIVES_SUCCESS = `${PREFIX}REQUEST_COLLECTIVES_SUCCESS`;
/**
 * Success callback action for fetch collectives
 * @param {Object} data
 * @return {Object}
 */
export const requestCollectivesSuccess = (data) => ({
    type: REQUEST_COLLECTIVES_SUCCESS,
    data,
});

export const REQUEST_COLLECTIVES_FAILED = `${PREFIX}REQUEST_COLLECTIVES_FAILED`;
/**
 * Error callback action for fetch collectives
 * @param {Object} error
 * @return {Object}
 */
export const requestCollectivesFailed = (error) => ({
    type: REQUEST_COLLECTIVES_FAILED,
    error,
});
