import { customElement } from 'lit-element';

import '@templates/oda-onboarding-step-wrapper/OdaOnboardingStepWrapper';
import '@molecules/HvCheckboxGroup';
import '@molecules/HvCheckbox';
import { WeaveStep } from '@components/organism/stepper/WeaveStep';
import { html, css, TemplateResult } from '@lion/core';
import { Router } from '@vaadin/router';
import { routePaths } from 'data-access/router';
import { receiveSelf } from '@async-reducers/authentication';
import { whitelabel } from '@weavelab/whitelabel';

@whitelabel({ name: 'oda-onboarding' })
@customElement('oda-success')
export class OdaOnboardingSuccess extends WeaveStep {
    @whitelabel()
    static productName?: string;

    constructor() {
        super();
        this.addEventListener('weave-step-next', () => {
            window.store.dispatch(receiveSelf.run());
            Router.go(routePaths.overview);
        });
    }

    static get styles() {
        return [
            ...super.styles,
            css`
                :host {
                    --oda-onboarding-success-top-content-height: 210px;
                    --oda-onboarding-success-top-content-background: #3aad3a;
                    --oda-onboarding-success-top-content-icon-container-width-height: 100px;
                    --oda-onboarding-success-top-content-icon-container-border-radius: 50%;
                    --oda-onboarding-success-top-content-icon-container-background: #fff;
                    --oda-onboarding-success-top-content-icon-container-padding-bottom: 5px;
                    --oda-onboarding-success-top-content-icon-margin-bottom: 3px;
                }

                .top-content {
                    height: var(--oda-onboarding-success-top-content-height);
                    display: flex;
                    background: var(
                        --oda-onboarding-success-top-content-background
                    );
                    justify-content: center;
                    align-items: center;
                }
                .bold {
                    font-weight: 700;
                }
                .icon-container {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: var(
                        --oda-onboarding-success-top-content-icon-container-width-height
                    );
                    width: var(
                        --oda-onboarding-success-top-content-icon-container-width-height
                    );
                    border-radius: var(
                        --oda-onboarding-success-top-content-icon-container-border-radius
                    );
                    background: var(
                        --oda-onboarding-success-top-content-icon-container-background
                    );
                    padding-bottom: var(
                        --oda-onboarding-success-top-content-icon-container-padding-bottom
                    );
                }
                svg {
                    margin-bottom: var(
                        --oda-onboarding-success-top-content-icon-margin-bottom
                    );
                }
            `,
        ];
    }

    public render = (): TemplateResult => html`
        <oda-onboarding-step-wrapper
            .nextText="${OdaOnboardingSuccess.productName} openen"
            hidePrevious
        >
            <div slot="top-content" class="top-content">
                <div class="icon-container">
                    <svg
                        width="63"
                        height="60"
                        viewBox="0 0 63 60"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M34.9965 12.4L33.201 22.0333C32.823 24 33.327 26.0333 34.524 27.5667C35.721 29.1 37.485 30 39.375 30H56.7V33.6L48.6045 53.3333H23.121C22.554 53.3333 22.05 52.8 22.05 52.2V26.0667L34.9965 12.4ZM37.8 0L17.6085 21.3667C16.4115 22.6333 15.75 24.3333 15.75 26.1V52.2C15.75 56.5 19.0575 60 23.121 60H48.636C50.8725 60 52.92 58.7667 54.054 56.7667L62.4645 36.2667C62.811 35.4333 63 34.5333 63 33.6V30C63 26.3333 60.165 23.3333 56.7 23.3333H39.375L42.273 7.83333C42.4305 7.1 42.336 6.3 42.021 5.63333C41.2965 4.13333 40.383 2.76667 39.249 1.56667L37.8 0ZM6.3 23.3333H0V60H6.3C8.0325 60 9.45 58.5 9.45 56.6667V26.6667C9.45 24.8333 8.0325 23.3333 6.3 23.3333Z"
                            fill="#3AAD3A"
                        />
                    </svg>
                </div>
            </div>
            <main slot="title">Super, je bent klaar!</main>
            <main slot="introduction">
                Alles wordt nog een keer gecheckt bij je netbeheerder.
                <span class="bold">Dit duurt meestal vijf werkdagen.</span>
                Zodra dit is goedgekeurd, ontvang je van ons een e-mail om je
                eerste verbruiksdata in te zien. Bekijk intussen alvast de app.
            </main>
        </oda-onboarding-step-wrapper>
    `;
}

declare global {
    interface HTMLElementTagNameMap {
        'oda-success': OdaOnboardingSuccess;
    }
}
