/**
 * @typedef {Object} ResourceSettings
 * @property {string} apiBaseURL
 */

/** @type {ResourceSettings} */
const resourceSettings = {
    apiBaseURL: window.API_LINK,
};
export default resourceSettings;
