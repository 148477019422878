import {
    START_CALCULATOR,
    PROMPT_CALCULATOR,
    FINISH_CALCULATOR,
    RESET_CALCULATOR,
} from '../actions/calculator';

/**
 * State reducer for calculator UI
 * @param {Object} state
 * @param {Object} action
 * @return {Object}
 */
export const calculator = (
    state = { busy: false, error: false, address: '' },
    action,
) => {
    switch (action.type) {
        case START_CALCULATOR:
            return { ...state, busy: true };
        case PROMPT_CALCULATOR:
            return {
                ...state,
                busy: false,
                error: true,
                address: action.address,
            };
        case FINISH_CALCULATOR:
            return { ...state, error: false, busy: false, address: '' };
        case RESET_CALCULATOR:
            return { ...state, error: false, busy: false };
        default:
            return state;
    }
};
