import {
    LitElement,
    PropertyValues,
    TemplateResult,
    customElement,
    html,
    property,
    query,
    queryAll,
} from 'lit-element';
import { ifDefined } from 'lit-html/directives/if-defined';

import { checkIfStringValueExistWithinAStringArray } from '../helpers/data';
import { rendererConverter, valueType } from './DropdownSelector';
import DropdownCellStyle from './StyleDropdownCell';

interface CellDetail {
    element: DropdownCell;
    depth: number | undefined;
    prevElement: DropdownCell;
}
export interface DefaultCellEvent extends Event {
    detail: CellDetail;
}

@customElement('dropdown-cell')
export class DropdownCell extends LitElement {
    @property()
    public value?: valueType;

    @property({ type: String, reflect: true })
    public item: any;

    @property({ attribute: 'item-value' })
    public itemValue?: string;

    @property({ attribute: 'item-label' })
    public itemLabel?: string;

    @property({ attribute: 'items-prop' })
    public itemsProp?: string;

    @property({ type: Number })
    public depth: number = 0;

    @property({
        attribute: 'multi-selector',
        type: Boolean,
        reflect: true,
    })
    public multiSelector: boolean = false;

    @property({
        attribute: 'is-collapsed',
        type: Boolean,
        reflect: true,
        hasChanged(newVal: boolean, oldVal: boolean) {
            return newVal !== oldVal;
        },
    })
    public isCollapsed: boolean = false;

    @property({
        attribute: 'is-collapsed-selectable',
        type: Boolean,
        reflect: true,
    })
    public isCollapsableSelectable: boolean = false;

    @property({
        attribute: 'collapsable',
        type: Boolean,
    })
    public collapsable: boolean = false;

    @property({
        attribute: 'item-selected',
    })
    public itemSelected?: string | string[];

    @property({ type: Boolean, reflect: true })
    public active: boolean = false;

    @property({
        attribute: 'active-path',
        type: Boolean,
        reflect: true,
    })
    public activePath: boolean = false;

    @property({ type: Boolean, reflect: true })
    public activeChildPath: boolean = false;

    @property({ type: Object })
    protected parentCell?: DropdownCell;

    @property({
        type: Boolean,
        hasChanged(newVal) {
            return newVal != null;
        },
    })
    private hasChildren: boolean = false;

    @property({ type: String })
    public rendererdValue?: string;

    @property({ attribute: 'prevent-inactive-selected-item', type: Boolean })
    public preventInactiveSelectedItem: boolean = false;
    @property({ type: Boolean })
    public preventDispatchSelectedValue: boolean = false;

    @query('.dropdown-cell-content')
    private dropdownCellContent?: HTMLDivElement;

    @query('checkbox-component')
    private checkboxComponent?: HTMLElementTagNameMap['checkbox-component'];

    @queryAll('dropdown-cell')
    private allDropdownCells?: DropdownCell[];

    @property({ converter: rendererConverter })
    public onRender?: (value: any, data: any) => TemplateResult;

    // Different collapsable binding state for dropdown cells.
    private childItemCollapsable: boolean = false;
    // this property is needed to prevent binding down to other dropdown cells if needed.
    private bindChildCollapsedValue: boolean = true;

    static get styles() {
        return [DropdownCellStyle];
    }

    protected render = (): TemplateResult => {
        return html`
            <style>
                .dropdown-header .icon-collapse {
                    max-width: 14px;
                    max-height: 8px;
                    background-color: var(--ew-gray-dark);
                    -webkit-mask: url(assets/icons/collapse-${this.isCollapsed
                            ? 'open'
                            : 'closed'}.svg)
                        no-repeat center;
                    mask: url(assets/icons/collapse-${this.isCollapsed
                            ? 'open'
                            : 'closed'}.svg)
                        no-repeat center;
                }
            </style>
            <div
                class="dropdown-header"
                @click="${(e: MouseEvent) =>
                    this.collapseContentInformation(e, true)}"
            >
                ${this.renderCellHeader(
                    this.depth,
                    this.hasChildren,
                    this.active,
                )}
            </div>
            ${this.itemsProp
                ? html`<div class="dropdown-cell-content">
                      ${this.renderChildren()}
                  </div>`
                : null}
        `;
    };

    protected updated(changedProperties: PropertyValues) {
        if (changedProperties.has('depth')) {
            if (this.depth) {
                this.addDepthToDropdownCell(this.depth);
            }
        }
        if (changedProperties.has('value')) {
            this.checkIfItemIsEqualToValueAndSetItActive();
        }
        if (changedProperties.has('activePath')) {
            if (!this.activePath) {
                if (this.allDropdownCells) {
                    this.allDropdownCells.forEach(
                        (dropdownCell) => (dropdownCell.activePath = false),
                    );
                }
            }
        }
        if (changedProperties.has('item') && this.item !== undefined) {
            const { item, itemLabel } = this;
            // Renererdvalue will be dispatched for current selectedItem.
            const val = itemLabel && item[itemLabel] ? item[itemLabel] : item;
            this.rendererdValue = val;
            // if the value is the same as item value set cell to active.
            this.checkIfItemIsEqualToValueAndSetItActive();
        }

        if (changedProperties.has('isCollapsed')) {
            /**
             * if cell has child cells.
             * Do a collapsed check if collapse for this cell is needed, then toggle collapse.
             * Also check if
             */
            if (
                this.collapsable &&
                this.hasChildren &&
                this.dropdownCellContent
            ) {
                // check if the dropdown cell content (child dropdown cells) contains the collapse class.
                const checkContain =
                    this.dropdownCellContent.classList.contains('collapse');
                // only collapse if state changes else do nothing.
                if (
                    (this.isCollapsed && !checkContain) ||
                    (!this.isCollapsed && checkContain)
                ) {
                    this.dropdownCellContent.classList.toggle('collapse');
                }

                // logic for the child element that prevents collapsed being bind downwards if isCollapsed is set to true.
                if (this.bindChildCollapsedValue) {
                    this.childItemCollapsable = this.isCollapsed;
                } else {
                    this.childItemCollapsable = !this.isCollapsed;
                    this.bindChildCollapsedValue =
                        !this.bindChildCollapsedValue;
                }
            }
        }
    }

    // This checks if the new value or item needs to be active.
    // if not set inactive.
    public checkIfItemIsEqualToValueAndSetItActive = (): void => {
        if (
            (this.itemValue && this.value === this.item[this.itemValue]) ||
            (this.itemValue &&
                Array.isArray(this.value) &&
                this.value.includes(this.item[this.itemValue]))
        ) {
            if (this.rendererdValue !== undefined) {
                this.active = true;
                // This dispatch to parent element that the cells below needs to be closed.
                if (!this.multiSelector) {
                    this.dispatchcollapsableStateOpen(this.depth);
                }

                if (this.itemSelected == null && this.multiSelector) {
                    if (this.checkboxComponent) {
                        this.checkboxComponent.value =
                            !this.checkboxComponent.value;
                    }
                }

                // This dispatch the new selected cell (itemSelected) to the dropdown-selector.
                if (Array.isArray(this.itemSelected)) {
                    if (!this.itemSelected.includes(this.rendererdValue)) {
                        this.dispatchActiveCell();
                    }
                } else if (this.itemSelected !== this.rendererdValue) {
                    // This dispatch the new selected cell (itemSelected) to the dropdown-selector.
                    if (!this.preventDispatchSelectedValue) {
                        this.dispatchActiveCell();
                    }
                }
            }
        } else if (this.active) {
            this.active = false;
            this.item = { ...this.item };
            // This dispatch the previous selected cell to set it to inactive.
            this.dispatchEvent(
                new CustomEvent('dropdown-selector-cell-incative', {
                    bubbles: true,
                    composed: true,
                    detail: {
                        value: this.rendererdValue,
                    },
                }),
            );
        }
    };

    protected firstUpdated(changedProperties: PropertyValues) {
        if (this.depth === 0) {
            this.addDepthToDropdownCell(this.depth);
        }
        if (changedProperties.has('item') && this.item !== undefined) {
            this.hasChildren =
                this.itemsProp &&
                this.item[this.itemsProp] &&
                (this.item[this.itemsProp] as any[]).length > 0;
        }
    }

    protected renderer = (
        value: string | object | undefined,
        items: any,
    ): TemplateResult => {
        if (this.onRender !== undefined) {
            try {
                if (this.itemLabel) {
                    return html`${this.onRender(value, items)}`;
                } else {
                    throw new Error('Item label must be set!');
                }
            } catch (error) {
                console.warn('Err rendering custom function', error);
            }
        }
        return html`${value}`;
    };

    private addDepthToDropdownCell = (depth: number): void => {
        this.classList.add(`depth${depth}`);
        this.setAttribute(`depth${depth}`, 'true');
    };

    private renderChildren = (): TemplateResult | null => {
        if (
            this.itemsProp !== undefined &&
            this.item[this.itemsProp] &&
            (this.item[this.itemsProp] as any[]).length > 0
        ) {
            return html`
                ${(this.item[this.itemsProp] as any[]).map(
                    (subItem: any) => html` <dropdown-cell
                        .depth="${this.depth === 2 ? 2 : this.depth + 1}"
                        .item="${subItem}"
                        .value=${this.value}
                        item-label=${ifDefined(this.itemLabel)}
                        item-value=${ifDefined(this.itemValue)}
                        items-prop=${ifDefined(this.itemsProp)}
                        .itemSelected=${this.itemSelected}
                        ?collapsable=${this.collapsable}
                        ?is-collapsed=${this.childItemCollapsable}
                        ?is-collapsed-selectable=${this.isCollapsableSelectable}
                        ?active-path=${this.activeChildPath}
                        ?multi-selector=${this.multiSelector}
                        .parentCell=${this}
                        @dropdown-cell-open-parent="${this
                            .setParentCollapsableStateOpen}"
                    ></dropdown-cell>`,
                )}
            `;
        }
        return null;
    };

    private renderCellHeader = (
        depth: number,
        hasChild: boolean,
        active: boolean,
    ): TemplateResult => {
        return html`
            ${depth !== 0
                ? html`
                      <img
                          class="icon-indicator noselect"
                          draggable="false"
                          src="assets/icons/indent.svg"
                          alt="Indent indicator"
                      />
                  `
                : null}
            <!-- Render header selector icon -->
            <!-- ${this.renderSelector(
                this.rendererdValue,
                hasChild,
                active,
            )} -->
            <!-- Render header title -->
            <p class="header-title">
                ${this.renderer(this.rendererdValue, this.item)}
            </p>
            <!-- Render header collapse button -->
            ${this.collapsable && hasChild
                ? html` <img
                      class="icon-collapse noselect"
                      draggable="false"
                      @click="${(e: MouseEvent) =>
                          this.collapseContentInformation(e, false)}"
                      src="assets/icons/collapse-${this.isCollapsed
                          ? 'open'
                          : 'closed'}.svg"
                  />`
                : null}
        `;
    };

    private renderSelector = (
        rendererdValue: string | undefined,
        hasChild: boolean,
        active: boolean,
    ): TemplateResult => {
        // do not render selected if dropdown-cell has items
        return html`
            ${(this.collapsable && !hasChild) ||
            !this.collapsable ||
            this.isCollapsableSelectable
                ? !this.multiSelector
                    ? html` ${active
                          ? html`
                                <img
                                    class="icon-selector noselect"
                                    draggable="false"
                                    src="assets/icons/selected.svg"
                                    alt="${rendererdValue} is selected"
                                />
                            `
                          : html`
                                <img
                                    class="icon-selector noselect"
                                    draggable="false"
                                    src="assets/icons/deselected.svg"
                                    alt="${rendererdValue} could be selected"
                                />
                            `}`
                    : this.multiSelector
                    ? html`<checkbox-component
                          class="icon-selector"
                          .selected=${active}
                          .item=${this.item}
                          hide-text
                          @click="${(e: MouseEvent) =>
                              this.collapseContentInformation(e, true)}"
                      ></checkbox-component>`
                    : null
                : null}
        `;
    };

    // this function dispatches the newly itemSelected to the dropdown-selector.
    private dispatchSelectedCell = (e?: MouseEvent): void => {
        if (e) {
            // this is to prevent the default behavior of the overlay component.
            e.stopImmediatePropagation();
        }
        // This dispatch to parent element that the cells below needs to be closed.
        if (!this.multiSelector) {
            this.dispatchcollapsableStateOpen(this.depth);
        }
        this.dispatchActiveCell();
    };

    // When the elements header gets clicked change the collapsable state.
    private collapseContentInformation = (e: MouseEvent, bool: boolean) => {
        e.stopImmediatePropagation();
        if (this.multiSelector) {
            if (
                this.itemSelected != null &&
                this.rendererdValue &&
                Array.isArray(this.itemSelected)
            ) {
                this.checkCollapsableStateBeforeDispatching(
                    !checkIfStringValueExistWithinAStringArray(
                        this.rendererdValue,
                        this.itemSelected,
                    ),
                    bool,
                );

                // sets checkbox to the active state if multi-select is true.
                if (this.checkboxComponent) {
                    this.checkboxComponent.value = this.active;
                }
            }
        } else {
            // if the element collapsable then only remove the collapse state.
            this.checkCollapsableStateBeforeDispatching(!this.active, bool);
        }
    };

    private checkCollapsableStateBeforeDispatching = (
        active: boolean,
        bool: boolean,
    ): void => {
        // if the element collapsable then only remove the collapse state.
        if (this.collapsable && this.hasChildren && this.dropdownCellContent) {
            if (this.isCollapsableSelectable && bool) {
                this.setActiveStateAnddispatch(active);
                this.isCollapsed = false;
            } else {
                this.isCollapsed = !this.isCollapsed;
            }
        } else {
            this.setActiveStateAnddispatch(active);
        }
    };

    private setActiveStateAnddispatch = (active: boolean): void => {
        if (!this.preventInactiveSelectedItem) {
            this.active = active;
        }
        this.dispatchSelectedCell();
    };

    // If this parent function gets called if it has children,
    // the parent will collapse all the child dropdown cells within the dropdown-cell-content.
    private collapseCellFromParent = (e: DefaultCellEvent): void => {
        e.stopImmediatePropagation();
        if (this.hasChildren) {
            this.childItemCollapsable = !this.isCollapsed;
            if (this.allDropdownCells) {
                this.allDropdownCells.forEach((dropdownCell) => {
                    dropdownCell.isCollapsed = true;
                    if (e.detail.prevElement !== dropdownCell) {
                        dropdownCell.activePath = false;
                    }
                });
            }
        }
    };

    // This check if depth is not undefined then all child cells need to be collapsed.
    // It also set the collapsed items to false (open/ visible).
    // Until it reaches the last parent wich is this function within the dropdownSelector class.
    private setParentCollapsableStateOpen = (e?: DefaultCellEvent): void => {
        if (e) {
            e.stopImmediatePropagation();
            if (e.detail.element === this) {
                // prevents databinding to child cells.
                this.bindChildCollapsedValue = false;
                // sets this component collapsed state to (open/visible)
                this.isCollapsed = false;

                // if depth is not undefined all the dropdownCell childs will be collapsed (closed/invisible).
                if (e.detail.depth) {
                    // if depth is not undefined all the dropdownCell childs will be collapsed (closed/invisible).
                    this.collapseCellFromParent(e);
                }
                // this dispatch to parent element that the parent isCollapsed needs to (open/visible) as well.
                this.dispatchcollapsableStateOpen(undefined);

                // Set active path
                this.activePath = true;
            } else {
                if (this.activePath) {
                    this.activeChildPath = false;
                }
            }
        }
    };

    // This dispatch to the element above that cells below need to be collapsed.
    private dispatchcollapsableStateOpen = (
        depth: number | undefined,
    ): void => {
        // only execute when dropdown-selector is collapsable.
        if (this.collapsable) {
            this.dispatchEvent(
                new CustomEvent('dropdown-cell-open-parent', {
                    bubbles: true,
                    composed: true,
                    detail: {
                        element: this.parentCell,
                        depth,
                        prevElement: this,
                    } as CellDetail,
                }) as DefaultCellEvent,
            );
        }
    };

    private dispatchActiveCell = (): void => {
        this.dispatchEvent(
            new CustomEvent('dropdown-selector-cell-active', {
                bubbles: true,
                composed: true,
                detail: {
                    value: this.rendererdValue,
                    element: this,
                    active: this.active,
                    state: !this.multiSelector,
                },
            }),
        );
    };
}

declare global {
    interface HTMLElementTagNameMap {
        'dropdown-cell': DropdownCell;
    }
}
