import { Resource } from './helpers/Resource';
/**
 * @typedef {Object} CreateLeadRequestObject
 */

/**
 * Store member management object for Create lead
 */
export class CreateLead extends Resource {
    /* eslint-disable valid-jsdoc */
    /**
     * Create new CreateLead
     * @param {Object} [options]
     */
    constructor(storeName, options = {}) {
        super(storeName, options);
    }

    /**
     * Generate request action
     * @param {CreateLeadRequestObject} requestObject
     * @return {Object}
     */
    request(requestObject = {}, requestOptions = {}) {
        return super.request(requestObject, requestOptions);
    }

    /**
     * Run request as saga
     * @return {function}
     */
    get requestSaga() {
        return super.requestSaga;
    }

    /**
     * Action string
     * @return {string}
     */
    static get action() {
        return 'Create';
    }

    /**
     * Description string
     * @return {string}
     */
    static get description() {
        return 'List schedules for a specific snapshot';
    }

    /**
     * Request method
     * @return {string}
     */
    static get method() {
        return 'post';
    }

    /**
     * Resource string
     * @return {string}
     */
    static get resource() {
        return 'lead';
    }

    /**
     * Route string
     * @return {string}
     */
    static get route() {
        return '/lead';
    }

    /**
     * Unique name string
     * @return {string}
     */
    static get uniqueName() {
        return 'CreateLead';
    }

    /**
     * Parameters object
     * @return {Object}
     */
    static get parameters() {
        return {};
    }

    /**
     * Parameters object
     * @return {Object}
     */
    static get payload() {
        return requestObject.payload;
    }
}
