import { customElement } from 'lit/decorators/custom-element.js';
import { LionProgressIndicator } from '@lion/progress-indicator';

import { css, html } from '@lion/core';

@customElement('hv-loader')
export class HvLoader extends LionProgressIndicator {
    static get styles() {
        return [
            css`
                :host {
                    display: none;
                    flex-direction: column;
                    align-items: center;
                    --hv-loader-width: 48px;
                    --hv-loader-height: 48px;
                    --hv-loader-loader-color: var(--primary-color, black);
                    --hv-loader-background: transparent;
                    --hv-loader-padding: 0;
                    --hv-loader-font-weight-title: bold;
                    --hv-loader-font-weight-description: normal;
                    --hv-loader-font-size-description: var(--font-size, 14px);
                    --hv-loader-font-size-title: var(--font-size-large: 22px;);
                    --hv-loader-font-family: var(
                        --primary-font-family,
                        'Arial'
                    );
                    --hv-loader-font-weight: normal;
                    --hv-loader-font-style: normal;
                    --hv-loader-line-height: 20px;
                    --hv-loader-spacing: 0 0 10px 0;
                    --hv-loader-title-color: var(--app-dark-primary);
                    --hv-loader-description-color: var(--greyish-brown);

                    max-width: 295px;
                    text-align: center;
                    margin: 0 auto;
                }

                :host([loading]) {
                    display: flex;
                    background: var(--hv-loader-background);
                    padding: var(--hv-loader-padding);
                }

                .title {
                    font-size: var(--hv-loader-font-size-title);
                    font-family: var(--hv-loader-font-family);
                    color: var(--hv-loader-title-color);
                    font-weight: var(--hv-loader-font-weight-title);
                    margin: var(--hv-loader-spacing);
                }

                .description {
                    font-size: var(--hv-loader-font-size-description);
                    font-family: var(--hv-loader-font-family);
                    color: var(--hv-loader-description-color);
                    font-weight: var(--hv-loader-font-weight-description);
                }

                .loader-svg {
                    display: inline-block;
                    width: var(--hv-loader-width);
                    height: var(--hv-loader-height);
                    animation: spinner-rotate 2s linear infinite;
                }

                .loader-svg-cirle {
                    animation: spinner-dash 1.35s ease-in-out infinite;
                    fill: none;
                    stroke-width: 4px;
                    stroke: var(--hv-loader-loader-color);
                    stroke-dasharray: 100, 28; /* This is a fallback for IE11 */
                }

                @keyframes spinner-rotate {
                    to {
                        transform: rotate(360deg);
                    }
                }

                @keyframes spinner-dash {
                    0% {
                        stroke-dasharray: 6, 122;
                        stroke-dashoffset: 0;
                    }
                    50% {
                        stroke-dasharray: 100, 28;
                        stroke-dashoffset: -16;
                    }
                    100% {
                        stroke-dasharray: 6, 122;
                        stroke-dashoffset: -127;
                    }
                }
            `,
        ];
    }

    protected _graphicTemplate(): symbol {
        return html`
            <div class="loader">
                <slot name="loader">
                    <svg class="loader-svg" viewBox="20 20 47 47">
                        <circle
                            class="loader-svg-cirle"
                            cx="44"
                            cy="44"
                            r="20.2"
                        />
                    </svg>
                </slot>
            </div>
            <div class="title">
                <slot name="title"></slot>
            </div>
            <div class="description">
                <slot name="description"></slot>
            </div>
        ` as unknown as symbol;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        'hv-loader': HvLoader;
    }
}
