import { takeLatest, ForkEffect } from 'redux-saga/effects';
import { ErrorAction } from 'goagen-js-resource';
import { notifyEdsnLive } from '../store';
/**
 * Watcher for notifyEdsnLive.REQUEST_FAILED and notifyEdsnLive.REQUEST_SUCCESS action
 */
export function* watchNotifyEDSNLive(): IterableIterator<ForkEffect> {
    yield takeLatest([notifyEdsnLive.REQUEST_FAILED], notifyEdsnFailed);
    yield takeLatest([notifyEdsnLive.REQUEST_SUCCESS], notifyEdsnSuccess);
}

/**
 * If setting a notify message for edsn down Failed
 */
const notifyEdsnFailed: (result: ErrorAction) => void = (
    _: ErrorAction,
): void => {
    window.displayMessage('Verzoek is mislukt.');
};

/**
 * If setting a notify message for edsn down Succeeds
 */
const notifyEdsnSuccess: (result: ErrorAction) => void = (
    _: ErrorAction,
): void => {
    window.displayMessage(
        'Bedankt voor je verzoek. Hopelijk kunnen we je snel een seintje toesturen.',
    );
};
