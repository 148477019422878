import { whitelabel } from '@weavelab/whitelabel';
import {
    CSSResult,
    LitElement,
    PropertyValues,
    TemplateResult,
    customElement,
    html,
    property,
} from 'lit-element';

import style from './style/tabsStyles';

export interface AllowTab {
    element: HTMLElement;
    allow: boolean;
}

@customElement('tabs-component')
@whitelabel({ name: 'tabs-component' })
export class TabsComponent extends LitElement {
    @property({ type: Array })
    public preventNamesToRender: string[] = [];

    @property({ type: Array })
    private tabTitles?: string[];

    @property({ type: String })
    private activeTab: string = '';

    @property({ type: Array })
    private slottedElements: Element[] = [];

    @property({ type: String, reflect: true })
    protected theme: 'default' | 'center' = 'default';

    @property({ type: Array })
    private preventNames: string[] = [];

    @whitelabel() // Allow styles to be injected from theme
    static get styles(): CSSResult[] {
        return [style];
    }

    connectedCallback() {
        super.connectedCallback();
        addEventListener(
            'allow-tab-render',
            this.allowTabAfterRender as EventListener,
        );
    }

    disconnectedCallback() {
        removeEventListener(
            'allow-tab-render',
            this.allowTabAfterRender as EventListener,
        );
        this.preventNames = this.preventNamesToRender;
        super.disconnectedCallback();
    }

    public render = (): TemplateResult => html`
        <header>
            ${this.tabTitles &&
            this.tabTitles.map(
                (title: string) => html`
                    <button
                        class="tab ${this.activeTab === title
                            ? 'tab__state--active'
                            : null}"
                        @click="${(event: MouseEvent) =>
                            this.showElement(event, title)}"
                    >
                        ${title}
                    </button>
                `,
            )}
        </header>
        <main>
            <slot @slotchange=${this.handleSlotchangeEvent}></slot>
        </main>
    `;

    protected updated(changedProperties: PropertyValues) {
        if (changedProperties.has('preventNamesToRender')) {
            this.preventNames = this.preventNamesToRender;
            this.checkIfActiveTabExist();
        }

        if (
            changedProperties.has('preventNames') &&
            this.slottedElements.length
        ) {
            this.setTabTitles(this.slottedElements);
        }
    }

    // hide all elements expect the clicked componentName
    private showElement = (event: MouseEvent, componentName: string): void => {
        event.stopImmediatePropagation();
        // Set current activetab.
        this.activeTab = componentName;

        // get all slotted elelement
        this.setSlottedElements(componentName);

        this.dispatchActiveTab();
    };

    private setSlottedElements = (name: string): void => {
        this.slottedElements.forEach((component: Element) => {
            const currentComponentName = component.hasAttribute('name')
                ? component.getAttribute('name')
                : component.tagName;
            if (currentComponentName !== name) {
                if (component.classList.contains('slot__active')) {
                    component.classList.remove('slot__active');
                }
                if (component.hasAttribute('active')) {
                    component.removeAttribute('active');
                }
            } else {
                component.classList.add('slot__active');
                component.setAttribute('active', 'true');
            }
        });
    };

    private dispatchActiveTab = (): void => {
        this.dispatchEvent(
            new CustomEvent('tabs-active-name', {
                detail: this.activeTab,
            }),
        );
    };

    // gets element name and checks if preventNames has name to remove it from the preventNames
    private allowTabAfterRender = (e: CustomEvent): void => {
        e.stopImmediatePropagation();
        const detail = e.detail as AllowTab;
        if (detail.element.hasAttribute('name')) {
            const name = detail.element.getAttribute('name')!;
            if (!this.preventNamesToRender.includes(name)) {
                return;
            }
            const preventHasName = this.preventNames.includes(name);
            // if tab needs to be hidden.
            if (!detail.allow) {
                if (!preventHasName) {
                    this.preventNames = [...this.preventNames, name];
                }

                return;
            }
            // tab needs to be displayed
            if (preventHasName) {
                this.preventNames = this.preventNames.filter(
                    (str: string) => str !== name,
                );
            }
        }
    };

    // When slotted elements change set the tab titles.
    private handleSlotchangeEvent(event: MouseEvent): void {
        event.stopImmediatePropagation();
        // handle slot change logic.
        const slot = event.target as HTMLSlotElement;
        if (slot && slot.assignedNodes()) {
            const slottedElements: Element[] = slot.assignedElements();
            this.setTabTitles(slottedElements, true);

            // set all slotted elements.
            this.slottedElements = slottedElements;

            this.showElement(event, this.activeTab);
        }
    }

    // set all tab titles and active tap when required
    private setTabTitles = (
        slottedElements: Element[],
        setActiveTab: boolean = false,
    ): void => {
        // get all slotted elements to set the tab titles
        const titles: string[] = [];
        slottedElements.forEach((component: Element) => {
            // get component name
            let name = component.tagName;
            if (component.hasAttribute('name')) {
                name = component.getAttribute('name')!;
            }
            // required to remove hidden if preventNames is used
            if (
                this.preventNames.length &&
                component.hasAttribute('hidden') &&
                !this.preventNames.includes(name)
            ) {
                component.removeAttribute('hidden');
            }

            // if preventNames or component is hidden return
            if (
                this.preventNames.includes(name) ||
                component.hasAttribute('hidden')
            ) {
                return;
            }

            if (name !== this.activeTab && component.hasAttribute('active')) {
                // @ts-ignore
                component.active = false;
            }

            titles.push(name);
        });
        // set all tab titles.
        this.tabTitles = titles;
        this.checkIfActiveTabExist();

        if (!setActiveTab) {
            return;
        }
        // set first tab as active
        this.activeTab = titles[0];
    };

    private checkIfActiveTabExist = (): void => {
        if (
            this.preventNames &&
            this.preventNames.includes(this.activeTab) &&
            this.tabTitles
        ) {
            this.activeTab = this.tabTitles[0];
            this.setSlottedElements(this.activeTab);
        }
    };
}

declare global {
    interface HTMLElementTagNameMap {
        'tabs-component': TabsComponent;
    }
}
