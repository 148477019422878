import enums from 'enums';
import { Snapshot } from 'types/snapshot';

const smartContractEnums: number[] = [
    enums.SnapshotPhaseSmartChargingContractCreated,
    enums.SnapshotPhaseSmartChargingCarAttached,
    enums.SnapshotPhaseSmartChargingActive,
    enums.SnapshotPhaseSmartChargingStopped,
];

// isSmartCharging is a helper function to determine if a snapshot is a smart charging snapshot
export const isSmartCharging = (snapshot: Snapshot): boolean => {
    if (smartContractEnums.includes(snapshot.snapshot_phase)) {
        return true;
    }
    return false;
};

// isCarConnected checks wether a user completed the car connect flow, used to show onboard y or n
export const isCarConnected = (snapshot: Snapshot): boolean =>
    snapshot.snapshot_phase > enums.SnapshotPhaseSmartChargingContractCreated &&
    snapshot.snapshot_phase <= enums.SnapshotPhaseSmartChargingStopped;

// hasSmartCharging is a helper function to determine if more snapshots contain has a smart charging snapshot
export const hasSmartCharging = (snapshots: Snapshot[]) => {
    for (let i = 0; i < snapshots.length; i++) {
        if (isSmartCharging(snapshots[i])) {
            return true;
        }
    }

    return false;
};
