// AccuracyType Enum
const enums: any = {};

enums.AccuracyType = [];
enums.AccuracyType[1] = 'P4';
enums.AccuracyTypeP4 = 1;
enums.AccuracyType[2] = 'ProfileEstimation';
enums.AccuracyTypeProfileEstimation = 2;
enums.AccuracyType[3] = 'Approximation';
enums.AccuracyTypeApproximation = 3;

// ActionKind Enum

enums.ActionKind = [];
enums.ActionKind[1] = 'CustomerTaxDepositAprove';
enums.ActionKindCustomerTaxDepositAprove = 1;
enums.ActionKind[2] = 'CustomerTaxDepositReject';
enums.ActionKindCustomerTaxDepositReject = 2;
enums.ActionKind[3] = 'CustomerTaxDepositEdit';
enums.ActionKindCustomerTaxDepositEdit = 3;
enums.ActionKind[4] = 'CustomerTaxDepositRetract';
enums.ActionKindCustomerTaxDepositRetract = 4;
enums.ActionKind[5] = 'CredentialsChangePassword';
enums.ActionKindCredentialsChangePassword = 5;
enums.ActionKind[6] = 'CredentialsRequestNewPassword';
enums.ActionKindCredentialsRequestNewPassword = 6;
enums.ActionKind[7] = 'CommonSendFeedback';
enums.ActionKindCommonSendFeedback = 7;
enums.ActionKind[8] = 'BankRegistrationValidation';
enums.ActionKindBankRegistrationValidation = 8;
enums.ActionKind[13] = 'WorkflowGeneric';
enums.ActionKindWorkflowGeneric = 13;
enums.ActionKind[15] = 'LinkPaymentItems';
enums.ActionKindLinkPaymentItems = 15;
enums.ActionKind[16] = 'UnlinkPayment';
enums.ActionKindUnlinkPayment = 16;
enums.ActionKind[17] = 'Book';
enums.ActionKindBook = 17;
enums.ActionKind[18] = 'ConvertToSalesInvoice';
enums.ActionKindConvertToSalesInvoice = 18;
enums.ActionKind[19] = 'Correct';
enums.ActionKindCorrect = 19;
enums.ActionKind[20] = 'BankExport';
enums.ActionKindBankExport = 20;
enums.ActionKind[21] = 'CustomerSetAsVendor';
enums.ActionKindCustomerSetAsVendor = 21;
enums.ActionKind[22] = 'VendorSetAsCustomer';
enums.ActionKindVendorSetAsCustomer = 22;
enums.ActionKind[23] = 'CustomerTaxDepositAproveWithAdoptionDate';
enums.ActionKindCustomerTaxDepositAproveWithAdoptionDate = 23;
enums.ActionKind[24] = 'VendorCreateFromCrowd';
enums.ActionKindVendorCreateFromCrowd = 24;
enums.ActionKind[25] = 'VendorLinkToServiceProvider';
enums.ActionKindVendorLinkToServiceProvider = 25;
enums.ActionKind[26] = 'RegisterDocumentPrinted';
enums.ActionKindRegisterDocumentPrinted = 26;
enums.ActionKind[27] = 'RegisterDocumentViewed';
enums.ActionKindRegisterDocumentViewed = 27;
enums.ActionKind[28] = 'EmailDocument';
enums.ActionKindEmailDocument = 28;
enums.ActionKind[29] = 'BankImport';
enums.ActionKindBankImport = 29;
enums.ActionKind[30] = 'DeclareVat';
enums.ActionKindDeclareVat = 30;
enums.ActionKind[31] = 'DeclareAndSendVat';
enums.ActionKindDeclareAndSendVat = 31;
enums.ActionKind[32] = 'StitchScans';
enums.ActionKindStitchScans = 32;
enums.ActionKind[33] = 'UnStitchScans';
enums.ActionKindUnStitchScans = 33;
enums.ActionKind[34] = 'Settle';
enums.ActionKindSettle = 34;
enums.ActionKind[35] = 'CreditSalesInvoice';
enums.ActionKindCreditSalesInvoice = 35;
enums.ActionKind[36] = 'CancelSalesInvoice';
enums.ActionKindCancelSalesInvoice = 36;
enums.ActionKind[37] = 'CustomerImport';
enums.ActionKindCustomerImport = 37;
enums.ActionKind[38] = 'VendorImport';
enums.ActionKindVendorImport = 38;
enums.ActionKind[39] = 'ProductImport';
enums.ActionKindProductImport = 39;
enums.ActionKind[40] = 'DoReminderRun';
enums.ActionKindDoReminderRun = 40;
enums.ActionKind[41] = 'ChangeSubscription';
enums.ActionKindChangeSubscription = 41;
enums.ActionKind[42] = 'CopySalesInvoice';
enums.ActionKindCopySalesInvoice = 42;
enums.ActionKind[43] = 'VendorUnlinkFromServiceProvider';
enums.ActionKindVendorUnlinkFromServiceProvider = 43;
enums.ActionKind[44] = 'VendorUpdateLinkToServiceProvider';
enums.ActionKindVendorUpdateLinkToServiceProvider = 44;
enums.ActionKind[45] = 'Finalize';
enums.ActionKindFinalize = 45;
enums.ActionKind[46] = 'TransferDocumentsToEntity';
enums.ActionKindTransferDocumentsToEntity = 46;
enums.ActionKind[47] = 'EnableStock';
enums.ActionKindEnableStock = 47;
enums.ActionKind[48] = 'DisableStock';
enums.ActionKindDisableStock = 48;
enums.ActionKind[49] = 'CopyLedger';
enums.ActionKindCopyLedger = 49;
enums.ActionKind[50] = 'ConvertToPurchaseInvoice';
enums.ActionKindConvertToPurchaseInvoice = 50;
enums.ActionKind[51] = 'ConvertToManualJournal';
enums.ActionKindConvertToManualJournal = 51;
enums.ActionKind[52] = 'CopyReportLayout';
enums.ActionKindCopyReportLayout = 52;
enums.ActionKind[53] = 'ReminderFromInvoice';
enums.ActionKindReminderFromInvoice = 53;
enums.ActionKind[54] = 'Archive';
enums.ActionKindArchive = 54;
enums.ActionKind[55] = 'EditMollieCredentials';
enums.ActionKindEditMollieCredentials = 55;
enums.ActionKind[56] = 'TestCredentials';
enums.ActionKindTestCredentials = 56;
enums.ActionKind[57] = 'Restore';
enums.ActionKindRestore = 57;
enums.ActionKind[58] = 'SendEInvoice';
enums.ActionKindSendEInvoice = 58;
enums.ActionKind[59] = 'CorrectDeclaration';
enums.ActionKindCorrectDeclaration = 59;
enums.ActionKind[60] = 'CloseDeclaration';
enums.ActionKindCloseDeclaration = 60;
enums.ActionKind[61] = 'PayDeclaration';
enums.ActionKindPayDeclaration = 61;
enums.ActionKind[62] = 'OpenPeriod';
enums.ActionKindOpenPeriod = 62;
enums.ActionKind[63] = 'PartialClosePeriod';
enums.ActionKindPartialClosePeriod = 63;
enums.ActionKind[64] = 'ClosePeriod';
enums.ActionKindClosePeriod = 64;
enums.ActionKind[65] = 'ExportRemittance';
enums.ActionKindExportRemittance = 65;
enums.ActionKind[66] = 'RegisterToken';
enums.ActionKindRegisterToken = 66;
enums.ActionKind[67] = 'CopyDocumentCategory';
enums.ActionKindCopyDocumentCategory = 67;
enums.ActionKind[68] = 'SetAccountantGroup';
enums.ActionKindSetAccountantGroup = 68;
enums.ActionKind[69] = 'DeleteAccountantGroup';
enums.ActionKindDeleteAccountantGroup = 69;
enums.ActionKind[70] = 'JoinAdministrationGroup';
enums.ActionKindJoinAdministrationGroup = 70;
enums.ActionKind[71] = 'LeaveAdministrationGroup';
enums.ActionKindLeaveAdministrationGroup = 71;
enums.ActionKind[72] = 'JoinAdministration';
enums.ActionKindJoinAdministration = 72;
enums.ActionKind[73] = 'LeaveAdministration';
enums.ActionKindLeaveAdministration = 73;
enums.ActionKind[74] = 'CreateEmployeeLogin';
enums.ActionKindCreateEmployeeLogin = 74;
enums.ActionKind[75] = 'UpdateEmployeeLogin';
enums.ActionKindUpdateEmployeeLogin = 75;
enums.ActionKind[76] = 'DeleteEmployeeLogin';
enums.ActionKindDeleteEmployeeLogin = 76;
enums.ActionKind[77] = 'CreateSynchronizationCode';
enums.ActionKindCreateSynchronizationCode = 77;
enums.ActionKind[78] = 'ChangeAccountantGroupCode';
enums.ActionKindChangeAccountantGroupCode = 78;
enums.ActionKind[79] = 'CreateInvoiceFromTimesheet';
enums.ActionKindCreateInvoiceFromTimesheet = 79;
enums.ActionKind[80] = 'BookCashrOrder';
enums.ActionKindBookCashrOrder = 80;
enums.ActionKind[81] = 'CreateInvoiceFromCashrOrder';
enums.ActionKindCreateInvoiceFromCashrOrder = 81;
enums.ActionKind[82] = 'SetPermissions';
enums.ActionKindSetPermissions = 82;
enums.ActionKind[83] = 'InvoiceUserSubscription';
enums.ActionKindInvoiceUserSubscription = 83;
enums.ActionKind[84] = 'SalesInvoiceImport';
enums.ActionKindSalesInvoiceImport = 84;
enums.ActionKind[85] = 'ManualJournalImport';
enums.ActionKindManualJournalImport = 85;
enums.ActionKind[86] = 'TaxonomyImport';
enums.ActionKindTaxonomyImport = 86;
enums.ActionKind[87] = 'SetFiscalPeriodType';
enums.ActionKindSetFiscalPeriodType = 87;
enums.ActionKind[88] = 'CreateAccountantLogin';
enums.ActionKindCreateAccountantLogin = 88;
enums.ActionKind[89] = 'UpdateAccountantLogin';
enums.ActionKindUpdateAccountantLogin = 89;
enums.ActionKind[90] = 'DeleteAccountantLogin';
enums.ActionKindDeleteAccountantLogin = 90;
enums.ActionKind[91] = 'AddTimeEntriesToTimesheet';
enums.ActionKindAddTimeEntriesToTimesheet = 91;
enums.ActionKind[92] = 'CreateWelcomeMessage';
enums.ActionKindCreateWelcomeMessage = 92;
enums.ActionKind[93] = 'ActivateContactPerson';
enums.ActionKindActivateContactPerson = 93;
enums.ActionKind[94] = 'ReactivateContactPerson';
enums.ActionKindReactivateContactPerson = 94;
enums.ActionKind[95] = 'RequirePasswordChangeContactPerson';
enums.ActionKindRequirePasswordChangeContactPerson = 95;
enums.ActionKind[96] = 'CustomerTaxDepositResendLastNotification';
enums.ActionKindCustomerTaxDepositResendLastNotification = 96;
enums.ActionKind[97] = 'CustomerTaxDepositExternalRequest';
enums.ActionKindCustomerTaxDepositExternalRequest = 97;
enums.ActionKind[98] = 'SetLogin';
enums.ActionKindSetLogin = 98;
enums.ActionKind[99] = 'DeleteLogin';
enums.ActionKindDeleteLogin = 99;
enums.ActionKind[100] = 'CustomerTaxDepositAproveInternal';
enums.ActionKindCustomerTaxDepositAproveInternal = 100;
enums.ActionKind[101] = 'AssignVascoSoftToken';
enums.ActionKindAssignVascoSoftToken = 101;
enums.ActionKind[102] = 'CreateCustomerAdministration';
enums.ActionKindCreateCustomerAdministration = 102;
enums.ActionKind[103] = 'CreateTemplateAdministration';
enums.ActionKindCreateTemplateAdministration = 103;
enums.ActionKind[104] = 'BankReImport';
enums.ActionKindBankReImport = 104;
enums.ActionKind[105] = 'DeleteBankImport';
enums.ActionKindDeleteBankImport = 105;
enums.ActionKind[106] = 'CorrectStock';
enums.ActionKindCorrectStock = 106;
enums.ActionKind[107] = 'DossierPropagateAccess';
enums.ActionKindDossierPropagateAccess = 107;
enums.ActionKind[108] = 'SetLogoAccountantGroup';
enums.ActionKindSetLogoAccountantGroup = 108;
enums.ActionKind[109] = 'CopyPurchaseInvoice';
enums.ActionKindCopyPurchaseInvoice = 109;
enums.ActionKind[110] = 'ImportCertificate';
enums.ActionKindImportCertificate = 110;
enums.ActionKind[111] = 'ProcessProductPriceChanges';
enums.ActionKindProcessProductPriceChanges = 111;
enums.ActionKind[112] = 'DepreciateFixedAsset';
enums.ActionKindDepreciateFixedAsset = 112;
enums.ActionKind[113] = 'CustomerTaxDepositXbrlSign';
enums.ActionKindCustomerTaxDepositXbrlSign = 113;
enums.ActionKind[114] = 'PaySalesInvoice';
enums.ActionKindPaySalesInvoice = 114;
enums.ActionKind[115] = 'CancelPayment';
enums.ActionKindCancelPayment = 115;
enums.ActionKind[116] = 'LinkBatch';
enums.ActionKindLinkBatch = 116;
enums.ActionKind[117] = 'PayDeclarationByBelastingdienstInternetKassa';
enums.ActionKindPayDeclarationByBelastingdienstInternetKassa = 117;
enums.ActionKind[118] = 'CopyManualJournal';
enums.ActionKindCopyManualJournal = 118;
enums.ActionKind[119] = 'DeclareAndSendVatDirectly';
enums.ActionKindDeclareAndSendVatDirectly = 119;
enums.ActionKind[120] = 'DeclareAndSendToPortal';
enums.ActionKindDeclareAndSendToPortal = 120;
enums.ActionKind[122] = 'CompleteMissingCashrOrderPayment';
enums.ActionKindCompleteMissingCashrOrderPayment = 122;
enums.ActionKind[123] = 'ArchiveCashrOrder';
enums.ActionKindArchiveCashrOrder = 123;

// ActionType Enum

enums.ActionType = [];
enums.ActionType[1] = 'ShouldCreateSettlementInvoice';
enums.ActionTypeShouldCreateSettlementInvoice = 1;
enums.ActionType[2] = 'SettlementInvoiceShouldBeSend';
enums.ActionTypeSettlementInvoiceShouldBeSend = 2;
enums.ActionType[3] = 'SettlementInvoiceCreationFailed';
enums.ActionTypeSettlementInvoiceCreationFailed = 3;
enums.ActionType[4] = 'TMRFirstNotFound';
enums.ActionTypeTMRFirstNotFound = 4;
enums.ActionType[5] = 'TMREndNotFound';
enums.ActionTypeTMREndNotFound = 5;
enums.ActionType[6] = 'SnapshotDoesNotHaveTwoWeeksOfUsages';
enums.ActionTypeSnapshotDoesNotHaveTwoWeeksOfUsages = 6;
enums.ActionType[7] = 'RequestingEDSNInternalServer';
enums.ActionTypeRequestingEDSNInternalServer = 7;
enums.ActionType[8] = 'RequestingEDSNBadRequest';
enums.ActionTypeRequestingEDSNBadRequest = 8;
enums.ActionType[9] = 'RequestingEDSNTimedOut';
enums.ActionTypeRequestingEDSNTimedOut = 9;
enums.ActionType[10] = 'RequestingEDSNTemporaryError';
enums.ActionTypeRequestingEDSNTemporaryError = 10;
enums.ActionType[11] = 'RequestingEDSNRejectionMoveInSwitchError';
enums.ActionTypeRequestingEDSNRejectionMoveInSwitchError = 11;
enums.ActionType[12] = 'RequestingEDSNRejectionMoveOutEOSError';
enums.ActionTypeRequestingEDSNRejectionMoveOutEOSError = 12;
enums.ActionType[20] = 'CancelAndCreditTransitionSnapshotFailed';
enums.ActionTypeCancelAndCreditTransitionSnapshotFailed = 20;
enums.ActionType[21] = 'NotDeliveryWhileSnapshotPhaseIsDeliveryStarted';
enums.ActionTypeNotDeliveryWhileSnapshotPhaseIsDeliveryStarted = 21;
enums.ActionType[22] = 'RequestingLeasePlanContractCreateFailed';
enums.ActionTypeRequestingLeasePlanContractCreateFailed = 22;
enums.ActionType[23] = 'RequestingLeasePlanContractUpdateFailed';
enums.ActionTypeRequestingLeasePlanContractUpdateFailed = 23;

// AddressType Enum

enums.AddressType = [];
enums.AddressType[1] = 'OfficeAddress';
enums.AddressTypeOfficeAddress = 1;
enums.AddressType[2] = 'BillingAddress';
enums.AddressTypeBillingAddress = 2;
enums.AddressType[3] = 'ShippingAddress';
enums.AddressTypeShippingAddress = 3;
enums.AddressType[4] = 'HomeAddress';
enums.AddressTypeHomeAddress = 4;

// BatchStatus Enum

enums.BatchStatus = [];
enums.BatchStatus[1] = 'Pending';
enums.BatchStatusPending = 1;
enums.BatchStatus[2] = 'Processed';
enums.BatchStatusProcessed = 2;
enums.BatchStatus[3] = 'Reversed';
enums.BatchStatusReversed = 3;
enums.BatchStatus[4] = 'Failed';
enums.BatchStatusFailed = 4;

// BatchType Enum

enums.BatchType = [];
enums.BatchType[1] = 'Payout';
enums.BatchTypePayout = 1;
enums.BatchType[2] = 'Collect';
enums.BatchTypeCollect = 2;

// CSVType Enum

enums.CSVType = [];
enums.CSVType[1] = 'Independer';
enums.CSVTypeIndepender = 1;
enums.CSVType[2] = 'ChargingPole';
enums.CSVTypeChargingPole = 2;
enums.CSVType[3] = 'EstimationsElec';
enums.CSVTypeEstimationsElec = 3;
enums.CSVType[4] = 'EstimationsGas';
enums.CSVTypeEstimationsGas = 4;
enums.CSVType[5] = 'EstimationsProduction';
enums.CSVTypeEstimationsProduction = 5;

// CaptarType Enum

enums.CaptarType = [];
enums.CaptarType[1] = 'Electric';
enums.CaptarTypeElectric = 1;
enums.CaptarType[2] = 'Gas';
enums.CaptarTypeGas = 2;

// ConsumerType Enum

enums.ConsumerType = [];
enums.ConsumerType[1] = 'Consumer';
enums.ConsumerTypeConsumer = 1;
enums.ConsumerType[2] = 'Company';
enums.ConsumerTypeCompany = 2;

// ContractTransferType Enum

enums.ContractTransferType = [];
enums.ContractTransferType[1] = 'Switch';
enums.ContractTransferTypeSwitch = 1;
enums.ContractTransferType[2] = 'Rehousing';
enums.ContractTransferTypeRehousing = 2;

// CostEnumType Enum

enums.CostEnumType = [];
enums.CostEnumType[1] = 'RealCosts';
enums.CostEnumTypeRealCosts = 1;
enums.CostEnumType[2] = 'EstimatedCosts';
enums.CostEnumTypeEstimatedCosts = 2;
enums.CostEnumType[3] = 'Savings';
enums.CostEnumTypeSavings = 3;

// CostType Enum

enums.CostType = [];
enums.CostType[1] = 'Elec Unit';
enums.CostTypeElecUnit = 1;
enums.CostType[2] = 'Gas Unit';
enums.CostTypeGasUnit = 2;
enums.CostType[4] = 'Per Product';
enums.CostTypePerProduct = 4;
enums.CostType[5] = 'Elec Unit Tax';
enums.CostTypeElecUnitTax = 5;
enums.CostType[6] = 'Elec Storage Tax';
enums.CostTypeElecStorageTax = 6;
enums.CostType[7] = 'Gas Unit Tax';
enums.CostTypeGasUnitTax = 7;
enums.CostType[8] = 'Gas Storage Tax';
enums.CostTypeGasStorageTax = 8;
enums.CostType[9] = 'Tax Credit';
enums.CostTypeTaxCredit = 9;
enums.CostType[10] = 'Grid Operator Elec';
enums.CostTypeGridOperatorElec = 10;
enums.CostType[11] = 'Grid Operator Gas';
enums.CostTypeGridOperatorGas = 11;
enums.CostType[12] = 'Elec Epex';
enums.CostTypeElecEpex = 12;
enums.CostType[13] = 'Gas Leba';
enums.CostTypeGasLeba = 13;
enums.CostType[14] = 'Gas Transport Cost';
enums.CostTypeGasTransportCost = 14;
enums.CostType[15] = 'Gulf elec unit normal';
enums.CostTypeGulfElecUnitNormal = 15;
enums.CostType[16] = 'Gulf elec unit low';
enums.CostTypeGulfElecUnitLow = 16;
enums.CostType[17] = 'Gulf gas';
enums.CostTypeGulfGas = 17;
enums.CostType[18] = 'Elec unit low';
enums.CostTypeElecUnitLow = 18;
enums.CostType[19] = 'Elec unit normal';
enums.CostTypeElecUnitNormal = 19;
enums.CostType[20] = 'Payment difference';
enums.CostTypePaymentDifference = 20;
enums.CostType[21] = 'EOS fine';
enums.CostTypeEOSFine = 21;
enums.CostType[22] = 'Collection';
enums.CostTypeCollection = 22;
enums.CostType[23] = 'Bonus';
enums.CostTypeBonus = 23;
enums.CostType[24] = 'Fixed Delivery';
enums.CostTypeFixedDelivery = 24;
enums.CostType[25] = 'Dynamic Purchasing';
enums.CostTypeDynamicPurchasing = 25;
enums.CostType[26] = 'Per Product Non Energy';
enums.CostTypePerProductNonEnergy = 26;

// CustomerKind Enum

enums.CustomerKind = [];
enums.CustomerKind[1] = 'Customer';
enums.CustomerKindCustomer = 1;
enums.CustomerKind[2] = 'Vendor';
enums.CustomerKindVendor = 2;
enums.CustomerKind[3] = 'Employee';
enums.CustomerKindEmployee = 3;

// DocumentType Enum

enums.DocumentType = [];
enums.DocumentType[1] = 'Invoice';
enums.DocumentTypeInvoice = 1;
enums.DocumentType[2] = 'Settlement';
enums.DocumentTypeSettlement = 2;
enums.DocumentType[3] = 'Snapshot';
enums.DocumentTypeSnapshot = 3;
enums.DocumentType[4] = 'Vko';
enums.DocumentTypeVko = 4;
enums.DocumentType[5] = 'BusinessDeclaration';
enums.DocumentTypeBusinessDeclaration = 5;

// EmailStatusType Enum

enums.EmailStatusType = [];
enums.EmailStatusType[1] = 'Processed';
enums.EmailStatusTypeProcessed = 1;
enums.EmailStatusType[2] = 'Delivered';
enums.EmailStatusTypeDelivered = 2;
enums.EmailStatusType[3] = 'Opened';
enums.EmailStatusTypeOpened = 3;
enums.EmailStatusType[4] = 'Clicked';
enums.EmailStatusTypeClicked = 4;
enums.EmailStatusType[5] = 'Bounce';
enums.EmailStatusTypeBounce = 5;

// EnodeEndpoint Enum

enums.EnodeEndpoint = [];
enums.EnodeEndpoint[1] = 'ChargersList';
enums.EnodeEndpointChargersList = 1;
enums.EnodeEndpoint[2] = 'ChargersGet';
enums.EnodeEndpointChargersGet = 2;
enums.EnodeEndpoint[3] = 'ChargersControlCharging';
enums.EnodeEndpointChargersControlCharging = 3;
enums.EnodeEndpoint[4] = 'ChargingLocationsList';
enums.EnodeEndpointChargingLocationsList = 4;
enums.EnodeEndpoint[5] = 'ChargingLocationsCreate';
enums.EnodeEndpointChargingLocationsCreate = 5;
enums.EnodeEndpoint[6] = 'ChargingLocationsGet';
enums.EnodeEndpointChargingLocationsGet = 6;
enums.EnodeEndpoint[7] = 'ChargingLocationsDelete';
enums.EnodeEndpointChargingLocationsDelete = 7;
enums.EnodeEndpoint[8] = 'ChargingLocationsUpdate';
enums.EnodeEndpointChargingLocationsUpdate = 8;
enums.EnodeEndpoint[9] = 'ChargingLocationsAssociateWithTariff';
enums.EnodeEndpointChargingLocationsAssociateWithTariff = 9;
enums.EnodeEndpoint[10] = 'ChargingLocationsGetTariff';
enums.EnodeEndpointChargingLocationsGetTariff = 10;
enums.EnodeEndpoint[11] = 'MeGet';
enums.EnodeEndpointMeGet = 11;
enums.EnodeEndpoint[12] = 'MeDisconnectVendor';
enums.EnodeEndpointMeDisconnectVendor = 12;
enums.EnodeEndpoint[13] = 'ServiceHealthCheckServiceReadiness';
enums.EnodeEndpointServiceHealthCheckServiceReadiness = 13;
enums.EnodeEndpoint[14] = 'ServiceHealthCheckAvailableVehicleVendors';
enums.EnodeEndpointServiceHealthCheckAvailableVehicleVendors = 14;
enums.EnodeEndpoint[15] = 'ServiceHealthCheckAvailableChargerVendors';
enums.EnodeEndpointServiceHealthCheckAvailableChargerVendors = 15;
enums.EnodeEndpoint[16] = 'StatisticsGet';
enums.EnodeEndpointStatisticsGet = 16;
enums.EnodeEndpoint[17] = 'TariffsSendTariffInformation';
enums.EnodeEndpointTariffsSendTariffInformation = 17;
enums.EnodeEndpoint[18] = 'UserManagementLinkUser';
enums.EnodeEndpointUserManagementLinkUser = 18;
enums.EnodeEndpoint[19] = 'UserManagementUnlinkUser';
enums.EnodeEndpointUserManagementUnlinkUser = 19;
enums.EnodeEndpoint[20] = 'UserManagementDeauthorizeUser';
enums.EnodeEndpointUserManagementDeauthorizeUser = 20;
enums.EnodeEndpoint[21] = 'VehicleList';
enums.EnodeEndpointVehicleList = 21;
enums.EnodeEndpoint[22] = 'VehicleGet';
enums.EnodeEndpointVehicleGet = 22;
enums.EnodeEndpoint[23] = 'VehicleGetLocation';
enums.EnodeEndpointVehicleGetLocation = 23;
enums.EnodeEndpoint[24] = 'VehicleGetChargeState';
enums.EnodeEndpointVehicleGetChargeState = 24;
enums.EnodeEndpoint[25] = 'VehicleGetInformation';
enums.EnodeEndpointVehicleGetInformation = 25;
enums.EnodeEndpoint[26] = 'VehicleGetOdometer';
enums.EnodeEndpointVehicleGetOdometer = 26;
enums.EnodeEndpoint[27] = 'VehicleStartStopCharging';
enums.EnodeEndpointVehicleStartStopCharging = 27;
enums.EnodeEndpoint[28] = 'VehicleGetSmartChargingPolicy';
enums.EnodeEndpointVehicleGetSmartChargingPolicy = 28;
enums.EnodeEndpoint[29] = 'VehicleUpdateSmartChargingPolicy';
enums.EnodeEndpointVehicleUpdateSmartChargingPolicy = 29;
enums.EnodeEndpoint[30] = 'VehicleGetSmartChargingStatus';
enums.EnodeEndpointVehicleGetSmartChargingStatus = 30;
enums.EnodeEndpoint[31] = 'VehicleGetSmartChargingPlan';
enums.EnodeEndpointVehicleGetSmartChargingPlan = 31;
enums.EnodeEndpoint[32] = 'WebhooksTestFirehose';
enums.EnodeEndpointWebhooksTestFirehose = 32;
enums.EnodeEndpoint[33] = 'WebhooksUpdateFirehose';
enums.EnodeEndpointWebhooksUpdateFirehose = 33;
enums.EnodeEndpoint[34] = 'WebhooksDeleteFirehose';
enums.EnodeEndpointWebhooksDeleteFirehose = 34;
enums.EnodeEndpoint[35] = 'VehicleCreateExternalStart';
enums.EnodeEndpointVehicleCreateExternalStart = 35;
enums.EnodeEndpoint[36] = 'VehicleEndExternalStart';
enums.EnodeEndpointVehicleEndExternalStart = 36;

// EstimationType Enum

enums.EstimationType = [];
enums.EstimationType[1] = 'Elec';
enums.EstimationTypeElec = 1;
enums.EstimationType[2] = 'Gas';
enums.EstimationTypeGas = 2;
enums.EstimationType[3] = 'Production';
enums.EstimationTypeProduction = 3;

// EstimationValueType Enum

enums.EstimationValueType = [];
enums.EstimationValueType[1] = 'E1A';
enums.EstimationValueTypeE1A = 1;
enums.EstimationValueType[2] = 'E1B';
enums.EstimationValueTypeE1B = 2;
enums.EstimationValueType[3] = 'E1C';
enums.EstimationValueTypeE1C = 3;
enums.EstimationValueType[4] = 'E2A';
enums.EstimationValueTypeE2A = 4;
enums.EstimationValueType[5] = 'E2B';
enums.EstimationValueTypeE2B = 5;
enums.EstimationValueType[6] = 'E3A';
enums.EstimationValueTypeE3A = 6;
enums.EstimationValueType[7] = 'E3B';
enums.EstimationValueTypeE3B = 7;
enums.EstimationValueType[8] = 'E3C';
enums.EstimationValueTypeE3C = 8;
enums.EstimationValueType[9] = 'E3D';
enums.EstimationValueTypeE3D = 9;
enums.EstimationValueType[10] = 'G1A';
enums.EstimationValueTypeG1A = 10;
enums.EstimationValueType[11] = 'G2A';
enums.EstimationValueTypeG2A = 11;
enums.EstimationValueType[12] = 'G2C';
enums.EstimationValueTypeG2C = 12;
enums.EstimationValueType[13] = 'E4A';
enums.EstimationValueTypeE4A = 13;
enums.EstimationValueType[14] = 'P';
enums.EstimationValueTypeP = 14;

// ExactEndpoint Enum

enums.ExactEndpoint = [];
enums.ExactEndpoint[1] = 'DebtorGet';
enums.ExactEndpointDebtorGet = 1;
enums.ExactEndpoint[2] = 'DebtorUpdate';
enums.ExactEndpointDebtorUpdate = 2;
enums.ExactEndpoint[3] = 'DebtorCreate';
enums.ExactEndpointDebtorCreate = 3;
enums.ExactEndpoint[4] = 'InvoiceGet';
enums.ExactEndpointInvoiceGet = 4;
enums.ExactEndpoint[5] = 'InvoiceCreate';
enums.ExactEndpointInvoiceCreate = 5;
enums.ExactEndpoint[6] = 'InvoiceUpdate';
enums.ExactEndpointInvoiceUpdate = 6;
enums.ExactEndpoint[7] = 'MemorialGet';
enums.ExactEndpointMemorialGet = 7;
enums.ExactEndpoint[8] = 'MemorialCreate';
enums.ExactEndpointMemorialCreate = 8;
enums.ExactEndpoint[9] = 'MemorialUpdate';
enums.ExactEndpointMemorialUpdate = 9;
enums.ExactEndpoint[10] = 'MemorialProcess';
enums.ExactEndpointMemorialProcess = 10;
enums.ExactEndpoint[11] = 'InvoiceProcess';
enums.ExactEndpointInvoiceProcess = 11;

// GainType Enum

enums.GainType = [];
enums.GainType[1] = 'AutoRetention';
enums.GainTypeAutoRetention = 1;
enums.GainType[2] = 'ManualRetention';
enums.GainTypeManualRetention = 2;
enums.GainType[3] = 'MoveOutTool';
enums.GainTypeMoveOutTool = 3;

// GenderType Enum

enums.GenderType = [];
enums.GenderType[1] = 'Male';
enums.GenderTypeMale = 1;
enums.GenderType[2] = 'Female';
enums.GenderTypeFemale = 2;
enums.GenderType[3] = 'Unknown';
enums.GenderTypeUnknown = 3;

// GulfDataType Enum

enums.GulfDataType = [];
enums.GulfDataType[1] = 'ElectricNormal';
enums.GulfDataTypeElectricNormal = 1;
enums.GulfDataType[2] = 'ElectricLow';
enums.GulfDataTypeElectricLow = 2;
enums.GulfDataType[3] = 'Gas';
enums.GulfDataTypeGas = 3;

// InteractionType Enum

enums.InteractionType = [];
enums.InteractionType[1] = 'Sent';
enums.InteractionTypeSent = 1;
enums.InteractionType[2] = 'Received';
enums.InteractionTypeReceived = 2;

// IntervalType Enum

enums.IntervalType = [];
enums.IntervalType[1] = '10s';
enums.IntervalType10s = 1;
enums.IntervalType[2] = '15m';
enums.IntervalType15m = 2;
enums.IntervalType[3] = 'Hourly';
enums.IntervalTypeHourly = 3;
enums.IntervalType[4] = 'Daily';
enums.IntervalTypeDaily = 4;
enums.IntervalType[5] = 'Monthly';
enums.IntervalTypeMonthly = 5;
enums.IntervalType[6] = 'Yearly';
enums.IntervalTypeYearly = 6;
enums.IntervalType[7] = 'TwoYearly';
enums.IntervalTypeTwoYearly = 7;
enums.IntervalType[8] = 'ThreeYearly';
enums.IntervalTypeThreeYearly = 8;
enums.IntervalType[9] = 'Weekly';
enums.IntervalTypeWeekly = 9;

// InvoiceBatchStatus Enum

enums.InvoiceBatchStatus = [];
enums.InvoiceBatchStatus[1] = 'Pending';
enums.InvoiceBatchStatusPending = 1;
enums.InvoiceBatchStatus[2] = 'Success';
enums.InvoiceBatchStatusSuccess = 2;
enums.InvoiceBatchStatus[3] = 'Failed';
enums.InvoiceBatchStatusFailed = 3;

// InvoiceDocumentStatus Enum

enums.InvoiceDocumentStatus = [];
enums.InvoiceDocumentStatus[1] = 'Tentative';
enums.InvoiceDocumentStatusTentative = 1;
enums.InvoiceDocumentStatus[2] = 'Open';
enums.InvoiceDocumentStatusOpen = 2;
enums.InvoiceDocumentStatus[3] = 'Closed';
enums.InvoiceDocumentStatusClosed = 3;

// InvoiceDocumentType Enum

enums.InvoiceDocumentType = [];
enums.InvoiceDocumentType[1] = 'PIH';
enums.InvoiceDocumentTypePIH = 1;
enums.InvoiceDocumentType[2] = 'OPB';
enums.InvoiceDocumentTypeOPB = 2;
enums.InvoiceDocumentType[7] = 'STX';
enums.InvoiceDocumentTypeSTX = 7;
enums.InvoiceDocumentType[10] = 'INV';
enums.InvoiceDocumentTypeINV = 10;
enums.InvoiceDocumentType[11] = 'IBK';
enums.InvoiceDocumentTypeIBK = 11;
enums.InvoiceDocumentType[18] = 'BSM';
enums.InvoiceDocumentTypeBSM = 18;
enums.InvoiceDocumentType[19] = 'BMC';
enums.InvoiceDocumentTypeBMC = 19;
enums.InvoiceDocumentType[20] = 'FAH';
enums.InvoiceDocumentTypeFAH = 20;
enums.InvoiceDocumentType[21] = 'COH';
enums.InvoiceDocumentTypeCOH = 21;
enums.InvoiceDocumentType[22] = 'SLH';
enums.InvoiceDocumentTypeSLH = 22;
enums.InvoiceDocumentType[23] = 'CBH';
enums.InvoiceDocumentTypeCBH = 23;
enums.InvoiceDocumentType[24] = 'CTD';
enums.InvoiceDocumentTypeCTD = 24;

// InvoiceEmail Enum

enums.InvoiceEmail = [];
enums.InvoiceEmail[1] = 'Sent';
enums.InvoiceEmailSent = 1;
enums.InvoiceEmail[2] = 'FirstReminder';
enums.InvoiceEmailFirstReminder = 2;
enums.InvoiceEmail[3] = 'SecondReminder';
enums.InvoiceEmailSecondReminder = 3;
enums.InvoiceEmail[4] = 'ThirdReminder';
enums.InvoiceEmailThirdReminder = 4;
enums.InvoiceEmail[5] = 'Paid';
enums.InvoiceEmailPaid = 5;
enums.InvoiceEmail[6] = 'Storno';
enums.InvoiceEmailStorno = 6;
enums.InvoiceEmail[7] = 'EndSupply';
enums.InvoiceEmailEndSupply = 7;

// InvoiceLineType Enum

enums.InvoiceLineType = [];
enums.InvoiceLineType[1] = 'SERVICE';
enums.InvoiceLineTypeSERVICE = 1;
enums.InvoiceLineType[2] = 'EXPENSE_NO_VAT';
enums.InvoiceLineTypeEXPENSENOVAT = 2;
enums.InvoiceLineType[3] = 'PURCHASE';
enums.InvoiceLineTypePURCHASE = 3;
enums.InvoiceLineType[4] = 'PRODUCT';
enums.InvoiceLineTypePRODUCT = 4;
enums.InvoiceLineType[5] = 'EXPENSE_VAT';
enums.InvoiceLineTypeEXPENSEVAT = 5;
enums.InvoiceLineType[6] = 'COMMENT';
enums.InvoiceLineTypeCOMMENT = 6;
enums.InvoiceLineType[7] = 'FIXED_ASSET';
enums.InvoiceLineTypeFIXEDASSET = 7;
enums.InvoiceLineType[8] = 'CUSTOMER_ORDER';
enums.InvoiceLineTypeCUSTOMERORDER = 8;
enums.InvoiceLineType[9] = 'SUBSCRIPTION';
enums.InvoiceLineTypeSUBSCRIPTION = 9;
enums.InvoiceLineType[10] = 'SUBSCRIPTION_COLLECTED';
enums.InvoiceLineTypeSUBSCRIPTIONCOLLECTED = 10;
enums.InvoiceLineType[11] = 'STOCKSUPPLY';
enums.InvoiceLineTypeSTOCKSUPPLY = 11;
enums.InvoiceLineType[12] = 'WITHHOLD_STOPAJ';
enums.InvoiceLineTypeWITHHOLDSTOPAJ = 12;
enums.InvoiceLineType[13] = 'WITHHOLD_TDS';
enums.InvoiceLineTypeWITHHOLDTDS = 13;
enums.InvoiceLineType[14] = 'WITHHOLD_TCS';
enums.InvoiceLineTypeWITHHOLDTCS = 14;
enums.InvoiceLineType[15] = 'ADDITIONAL_TAX_CHARGE';
enums.InvoiceLineTypeADDITIONALTAXCHARGE = 15;
enums.InvoiceLineType[16] = 'ADDITIONAL_EXCISE';
enums.InvoiceLineTypeADDITIONALEXCISE = 16;
enums.InvoiceLineType[17] = 'MANUALJOURNAL';
enums.InvoiceLineTypeMANUALJOURNAL = 17;
enums.InvoiceLineType[18] = 'DOCUMENTROUNDING';
enums.InvoiceLineTypeDOCUMENTROUNDING = 18;
enums.InvoiceLineType[19] = 'AutoCorrect';
enums.InvoiceLineTypeAutoCorrect = 19;

// InvoiceOrigin Enum

enums.InvoiceOrigin = [];
enums.InvoiceOrigin[1] = 'Receipt';
enums.InvoiceOriginReceipt = 1;
enums.InvoiceOrigin[2] = 'Document';
enums.InvoiceOriginDocument = 2;

// InvoiceStatus Enum

enums.InvoiceStatus = [];
enums.InvoiceStatus[1] = 'Created';
enums.InvoiceStatusCreated = 1;
enums.InvoiceStatus[2] = 'Draft';
enums.InvoiceStatusDraft = 2;
enums.InvoiceStatus[3] = 'Expired';
enums.InvoiceStatusExpired = 3;
enums.InvoiceStatus[4] = 'Paid';
enums.InvoiceStatusPaid = 4;
enums.InvoiceStatus[5] = 'Credited';
enums.InvoiceStatusCredited = 5;
enums.InvoiceStatus[6] = 'ToBundle';
enums.InvoiceStatusToBundle = 6;
enums.InvoiceStatus[7] = 'Bundled';
enums.InvoiceStatusBundled = 7;
enums.InvoiceStatus[8] = 'Invalid';
enums.InvoiceStatusInvalid = 8;
enums.InvoiceStatus[9] = 'BundledPaid';
enums.InvoiceStatusBundledPaid = 9;
enums.InvoiceStatus[10] = 'PaymentExpected';
enums.InvoiceStatusPaymentExpected = 10;
enums.InvoiceStatus[11] = 'Settled';
enums.InvoiceStatusSettled = 11;
enums.InvoiceStatus[12] = 'Payout';
enums.InvoiceStatusPayout = 12;
enums.InvoiceStatus[13] = 'PartiallyPaid';
enums.InvoiceStatusPartiallyPaid = 13;

// InvoiceSubStatus Enum

enums.InvoiceSubStatus = [];
enums.InvoiceSubStatus[1] = 'ToCredit';
enums.InvoiceSubStatusToCredit = 1;
enums.InvoiceSubStatus[2] = 'PaidToCredit';
enums.InvoiceSubStatusPaidToCredit = 2;
enums.InvoiceSubStatus[3] = 'OpenToCredit';
enums.InvoiceSubStatusOpenToCredit = 3;

// InvoiceType Enum

enums.InvoiceType = [];
enums.InvoiceType[1] = 'Upfront';
enums.InvoiceTypeUpfront = 1;
enums.InvoiceType[2] = 'OneOff';
enums.InvoiceTypeOneOff = 2;
enums.InvoiceType[3] = 'Year';
enums.InvoiceTypeYear = 3;
enums.InvoiceType[4] = 'End';
enums.InvoiceTypeEnd = 4;
enums.InvoiceType[5] = 'SmartMeter';
enums.InvoiceTypeSmartMeter = 5;
enums.InvoiceType[6] = 'Monthly';
enums.InvoiceTypeMonthly = 6;
enums.InvoiceType[7] = 'Credit';
enums.InvoiceTypeCredit = 7;
enums.InvoiceType[8] = 'Collection';
enums.InvoiceTypeCollection = 8;
enums.InvoiceType[9] = 'MonthlySettle';
enums.InvoiceTypeMonthlySettle = 9;

// LeadStatusType Enum

enums.LeadStatusType = [];
enums.LeadStatusType[1] = 'Open';
enums.LeadStatusTypeOpen = 1;
enums.LeadStatusType[2] = 'Qualified';
enums.LeadStatusTypeQualified = 2;
enums.LeadStatusType[3] = 'Disqualified';
enums.LeadStatusTypeDisqualified = 3;

// LeasePlanContractStatus Enum

enums.LeasePlanContractStatus = [];
enums.LeasePlanContractStatus[1] = 'sent';
enums.LeasePlanContractStatusSent = 1;
enums.LeasePlanContractStatus[2] = 'qualified';
enums.LeasePlanContractStatusQualified = 2;
enums.LeasePlanContractStatus[3] = 'disqualified';
enums.LeasePlanContractStatusDisqualified = 3;

// LedgerCodeType Enum

enums.LedgerCodeType = [];
enums.LedgerCodeType[1] = 'Correction';
enums.LedgerCodeTypeCorrection = 1;
enums.LedgerCodeType[2] = 'Solar';
enums.LedgerCodeTypeSolar = 2;

// LossEmail Enum

enums.LossEmail = [];
enums.LossEmail[1] = 'EndDelivery';
enums.LossEmailEndDelivery = 1;
enums.LossEmail[2] = 'EndDeliveryCauseDefault';
enums.LossEmailEndDeliveryCauseDefault = 2;

// LossType Enum

enums.LossType = [];
enums.LossType[1] = 'MoveOut';
enums.LossTypeMoveOut = 1;
enums.LossType[2] = 'SwitchLv';
enums.LossTypeSwitchLv = 2;
enums.LossType[3] = 'MoveIn';
enums.LossTypeMoveIn = 3;
enums.LossType[4] = 'Retention';
enums.LossTypeRetention = 4;
enums.LossType[5] = 'EndOfSupplyDashboard';
enums.LossTypeEndOfSupplyDashboard = 5;
enums.LossType[6] = 'LossMessageEdsn';
enums.LossTypeLossMessageEdsn = 6;

// MandateEmail Enum

enums.MandateEmail = [];
enums.MandateEmail[1] = 'FirstReminder';
enums.MandateEmailFirstReminder = 1;
enums.MandateEmail[2] = 'SecondReminder';
enums.MandateEmailSecondReminder = 2;
enums.MandateEmail[3] = 'ThirdReminder';
enums.MandateEmailThirdReminder = 3;

// MeteringPointType Enum

enums.MeteringPointType = [];
enums.MeteringPointType[1] = 'Home EAN Electric';
enums.MeteringPointTypeHomeEANElectric = 1;
enums.MeteringPointType[2] = 'Home EAN Gas';
enums.MeteringPointTypeHomeEANGas = 2;
enums.MeteringPointType[3] = 'Grid Operator';
enums.MeteringPointTypeGridOperator = 3;
enums.MeteringPointType[99] = 'Unknown';
enums.MeteringPointTypeUnknown = 99;

// NotificationType Enum

enums.NotificationType = [];
enums.NotificationType[1] = 'DataReceivedNotification';
enums.NotificationTypeDataReceivedNotification = 1;

// OSType Enum

enums.OSType = [];
enums.OSType[1] = 'Android';
enums.OSTypeAndroid = 1;
enums.OSType[2] = 'IOS';
enums.OSTypeIOS = 2;

// PDFTemplateType Enum

enums.PDFTemplateType = [];
enums.PDFTemplateType[1] = 'Invoice';
enums.PDFTemplateTypeInvoice = 1;
enums.PDFTemplateType[2] = 'Contract';
enums.PDFTemplateTypeContract = 2;
enums.PDFTemplateType[3] = 'ProductInvoice';
enums.PDFTemplateTypeProductInvoice = 3;
enums.PDFTemplateType[4] = 'Settlement';
enums.PDFTemplateTypeSettlement = 4;
enums.PDFTemplateType[5] = 'Vko';
enums.PDFTemplateTypeVko = 5;
enums.PDFTemplateType[6] = 'Sepa';
enums.PDFTemplateTypeSepa = 6;
enums.PDFTemplateType[7] = 'BundleInvoice';
enums.PDFTemplateTypeBundleInvoice = 7;
enums.PDFTemplateType[8] = 'BundleSettlement';
enums.PDFTemplateTypeBundleSettlement = 8;
enums.PDFTemplateType[9] = 'Offer';
enums.PDFTemplateTypeOffer = 9;
enums.PDFTemplateType[10] = 'BusinessDeclaration';
enums.PDFTemplateTypeBusinessDeclaration = 10;

// PaymentAccountType Enum

enums.PaymentAccountType = [];
enums.PaymentAccountType[1] = 'SEPA Direct Debit';
enums.PaymentAccountTypeSEPADirectDebit = 1;

// PaymentInterval Enum

enums.PaymentInterval = [];
enums.PaymentInterval[1] = 'Weekly';
enums.PaymentIntervalWeekly = 1;
enums.PaymentInterval[2] = 'Monthly';
enums.PaymentIntervalMonthly = 2;
enums.PaymentInterval[3] = 'Yearly';
enums.PaymentIntervalYearly = 3;

// PaymentMethod Enum

enums.PaymentMethod = [];
enums.PaymentMethod[1] = 'IdealLink';
enums.PaymentMethodIdealLink = 1;
enums.PaymentMethod[2] = 'WithPaymentAccount';
enums.PaymentMethodWithPaymentAccount = 2;
enums.PaymentMethod[3] = 'NotSpecified';
enums.PaymentMethodNotSpecified = 3;

// PaymentProviderType Enum

enums.PaymentProviderType = [];
enums.PaymentProviderType[1] = 'Buckaroo';
enums.PaymentProviderTypeBuckaroo = 1;
enums.PaymentProviderType[2] = 'Manual';
enums.PaymentProviderTypeManual = 2;
enums.PaymentProviderType[3] = 'BankstatementMatch';
enums.PaymentProviderTypeBankstatementMatch = 3;

// PaymentStatus Enum

enums.PaymentStatus = [];
enums.PaymentStatus[1] = 'Created';
enums.PaymentStatusCreated = 1;
enums.PaymentStatus[2] = 'Paid';
enums.PaymentStatusPaid = 2;
enums.PaymentStatus[3] = 'Reverted';
enums.PaymentStatusReverted = 3;

// PaymentType Enum

enums.PaymentType = [];
enums.PaymentType[1] = 'InAdvance';
enums.PaymentTypeInAdvance = 1;
enums.PaymentType[2] = 'Exact';
enums.PaymentTypeExact = 2;

// PortalStatusType Enum

enums.PortalStatusType = [];
enums.PortalStatusType[1] = 'Sent';
enums.PortalStatusTypeSent = 1;
enums.PortalStatusType[2] = 'Received';
enums.PortalStatusTypeReceived = 2;
enums.PortalStatusType[3] = 'Error';
enums.PortalStatusTypeError = 3;

// PostmarkEndpoint Enum

enums.PostmarkEndpoint = [];
enums.PostmarkEndpoint[1] = 'MessageGet';
enums.PostmarkEndpointMessageGet = 1;

// PriceSetting Enum

enums.PriceSetting = [];
enums.PriceSetting[1] = 'Static';
enums.PriceSettingStatic = 1;
enums.PriceSetting[2] = 'Dynamic';
enums.PriceSettingDynamic = 2;
enums.PriceSetting[3] = 'StaticAndDynamic';
enums.PriceSettingStaticAndDynamic = 3;

// ProductDuration Enum

enums.ProductDuration = [];
enums.ProductDuration[1] = 'OneOff';
enums.ProductDurationOneOff = 1;
enums.ProductDuration[2] = 'Hourly';
enums.ProductDurationHourly = 2;
enums.ProductDuration[3] = 'Daily';
enums.ProductDurationDaily = 3;
enums.ProductDuration[4] = 'Weekly';
enums.ProductDurationWeekly = 4;
enums.ProductDuration[5] = 'Month';
enums.ProductDurationMonth = 5;
enums.ProductDuration[6] = 'Year';
enums.ProductDurationYear = 6;
enums.ProductDuration[7] = 'TwoYears';
enums.ProductDurationTwoYears = 7;
enums.ProductDuration[8] = 'ThreeYears';
enums.ProductDurationThreeYears = 8;
enums.ProductDuration[9] = 'HalfYear';
enums.ProductDurationHalfYear = 9;
enums.ProductDuration[10] = 'FiveYears';
enums.ProductDurationFiveYears = 10;

// ProductType Enum

enums.ProductType = [];
enums.ProductType[1] = 'ElekUsage';
enums.ProductTypeElekUsage = 1;
enums.ProductType[2] = 'GasUsage';
enums.ProductTypeGasUsage = 2;
enums.ProductType[3] = 'Other';
enums.ProductTypeOther = 3;
enums.ProductType[4] = 'Additional';
enums.ProductTypeAdditional = 4;
enums.ProductType[5] = 'ElekProduction';
enums.ProductTypeElekProduction = 5;
enums.ProductType[6] = 'Inserted';
enums.ProductTypeInserted = 6;

// PromCounterType Enum

enums.PromCounterType = [];
enums.PromCounterType[1] = 'EDSN Request count';
enums.PromCounterTypeEDSNRequestCount = 1;
enums.PromCounterType[2] = 'EDSN Mail count';
enums.PromCounterTypeEDSNMailCount = 2;
enums.PromCounterType[3] = 'P4 insertion count';
enums.PromCounterTypeP4InsertionCount = 3;
enums.PromCounterType[4] = 'New usage count';
enums.PromCounterTypeNewUsageCount = 4;
enums.PromCounterType[5] = 'Usage insertion count';
enums.PromCounterTypeUsageInsertionCount = 5;
enums.PromCounterType[6] = 'Mail count';
enums.PromCounterTypeMailCount = 6;
enums.PromCounterType[7] = 'Snapshot create count';
enums.PromCounterTypeSnapshotCreateCount = 7;
enums.PromCounterType[8] = 'Snapshot phase change count';
enums.PromCounterTypeSnapshotPhaseChangeCount = 8;
enums.PromCounterType[9] = 'PDF creation count';
enums.PromCounterTypePDFCreationCount = 9;
enums.PromCounterType[10] = 'Transaction status count';
enums.PromCounterTypeTransactionStatusCount = 10;
enums.PromCounterType[11] = 'Usage Cache count';
enums.PromCounterTypeUsageCacheCount = 11;
enums.PromCounterType[12] = 'Ileco client count';
enums.PromCounterTypeIlecoClientCount = 12;
enums.PromCounterType[13] = 'Service Calls count';
enums.PromCounterTypeServiceCallsCount = 13;
enums.PromCounterType[14] = 'Enode request count';
enums.PromCounterTypeEnodeRequestCount = 14;
enums.PromCounterType[15] = 'Exact request count';
enums.PromCounterTypeExactRequestCount = 15;
enums.PromCounterType[16] = 'snapshot loss request count';
enums.PromCounterTypeSnapshotLossRequestCount = 16;
enums.PromCounterType[17] = 'Postmark request count';
enums.PromCounterTypePostmarkRequestCount = 17;
enums.PromCounterType[18] = 'Payt event count';
enums.PromCounterTypePaytEventCount = 18;
enums.PromCounterType[19] = 'Payt sent invoices count';
enums.PromCounterTypePaytSentInvoicesCount = 19;

// ReadingInsertingType Enum

enums.ReadingInsertingType = [];
enums.ReadingInsertingType[1] = 'Physical reading Balance Supplier';
enums.ReadingInsertingTypePhysicalReadingBalanceSupplier = 1;
enums.ReadingInsertingType[2] = 'Estimated-Calculated reading Balance Supplier';
enums.ReadingInsertingTypeEstimatedCalculatedReadingBalanceSupplier = 2;
enums.ReadingInsertingType[3] = 'Physical reading (Fysieke opname)';
enums.ReadingInsertingTypePhysicalReadingFysiekeOpname = 3;
enums.ReadingInsertingType[4] = 'Smart Meter Reading (P4-stand)';
enums.ReadingInsertingTypeSmartMeterReadingP4stand = 4;
enums.ReadingInsertingType[5] = 'Calculated Reading (Berekend)';
enums.ReadingInsertingTypeCalculatedReadingBerekend = 5;
enums.ReadingInsertingType[6] = 'Estimated Reading';
enums.ReadingInsertingTypeEstimatedReading = 6;
enums.ReadingInsertingType[9] = 'Best guess reading';
enums.ReadingInsertingTypeBestGuessReading = 9;
enums.ReadingInsertingType[22] = 'Customer Reading (Klantstand)';
enums.ReadingInsertingTypeCustomerReadingKlantstand = 22;
enums.ReadingInsertingType[90] = 'Physical reading Meter Responsible';
enums.ReadingInsertingTypePhysicalReadingMeterResponsible = 90;
enums.ReadingInsertingType[91] =
    'Estimated-Calculated reading (Meter Responsible)';
enums.ReadingInsertingTypeEstimatedCalculatedReadingMeterResponsible = 91;
enums.ReadingInsertingType[92] = 'Physical reading Grid Operator';
enums.ReadingInsertingTypePhysicalReadingGridOperator = 92;
enums.ReadingInsertingType[93] = 'Estimated-Calculated reading Grid Operator';
enums.ReadingInsertingTypeEstimatedCalculatedReadingGridOperator = 93;
enums.ReadingInsertingType[98] = 'Dispute insertion';
enums.ReadingInsertingTypeDisputeInsertion = 98;
enums.ReadingInsertingType[99] = 'Manual inserted';
enums.ReadingInsertingTypeManualInserted = 99;
enums.ReadingInsertingType[100] = 'TMR First Reading';
enums.ReadingInsertingTypeTMRFirstReading = 100;
enums.ReadingInsertingType[101] = 'TMR Last Reading';
enums.ReadingInsertingTypeTMRLastReading = 101;
enums.ReadingInsertingType[102] = 'Agreed Reading (Overeengekomen)';
enums.ReadingInsertingTypeAgreedReadingOvereengekomen = 102;

// ReadingMeasurementType Enum

enums.ReadingMeasurementType = [];
enums.ReadingMeasurementType[1] = 'Electric delivery total (Wh)';
enums.ReadingMeasurementTypeElectricDeliveryTotalWh = 1;
enums.ReadingMeasurementType[2] = 'Electric usage low';
enums.ReadingMeasurementTypeElectricUsageLow = 2;
enums.ReadingMeasurementType[3] = 'Electric usage normal';
enums.ReadingMeasurementTypeElectricUsageNormal = 3;
enums.ReadingMeasurementType[4] = 'Electric return total';
enums.ReadingMeasurementTypeElectricReturnTotal = 4;
enums.ReadingMeasurementType[5] = 'Electric return low';
enums.ReadingMeasurementTypeElectricReturnLow = 5;
enums.ReadingMeasurementType[6] = 'Electric return normal';
enums.ReadingMeasurementTypeElectricReturnNormal = 6;
enums.ReadingMeasurementType[7] = 'Gas';
enums.ReadingMeasurementTypeGas = 7;

// ReadingMeasurementUnitType Enum

enums.ReadingMeasurementUnitType = [];
enums.ReadingMeasurementUnitType[1] = 'Wh';
enums.ReadingMeasurementUnitTypeWh = 1;
enums.ReadingMeasurementUnitType[2] = 'KWh';
enums.ReadingMeasurementUnitTypeKWh = 2;
enums.ReadingMeasurementUnitType[3] = 'MTQ';
enums.ReadingMeasurementUnitTypeMTQ = 3;
enums.ReadingMeasurementUnitType[4] = 'DM3';
enums.ReadingMeasurementUnitTypeDM3 = 4;

// ReadingType Enum

enums.ReadingType = [];
enums.ReadingType[1] = 'Low - reading';
enums.ReadingTypeLowReading = 1;
enums.ReadingType[2] = 'Normal - reading';
enums.ReadingTypeNormalReading = 2;
enums.ReadingType[3] = 'Low - production';
enums.ReadingTypeLowProduction = 3;
enums.ReadingType[4] = 'Normal - production';
enums.ReadingTypeNormalProduction = 4;
enums.ReadingType[5] = 'Gas';
enums.ReadingTypeGas = 5;
enums.ReadingType[99] = 'Unknown';
enums.ReadingTypeUnknown = 99;

// RedirectType Enum

enums.RedirectType = [];
enums.RedirectType[1] = 'IndependerIdin';
enums.RedirectTypeIndependerIdin = 1;

// ReeleezeeInvoiceType Enum

enums.ReeleezeeInvoiceType = [];
enums.ReeleezeeInvoiceType[1] = 'Standard';
enums.ReeleezeeInvoiceTypeStandard = 1;
enums.ReeleezeeInvoiceType[2] = 'ProForma';
enums.ReeleezeeInvoiceTypeProForma = 2;
enums.ReeleezeeInvoiceType[3] = 'Offering';
enums.ReeleezeeInvoiceTypeOffering = 3;
enums.ReeleezeeInvoiceType[7] = 'FreightBill';
enums.ReeleezeeInvoiceTypeFreightBill = 7;
enums.ReeleezeeInvoiceType[9] = 'CashRegisterReceipt';
enums.ReeleezeeInvoiceTypeCashRegisterReceipt = 9;
enums.ReeleezeeInvoiceType[10] = 'SalesTarget';
enums.ReeleezeeInvoiceTypeSalesTarget = 10;
enums.ReeleezeeInvoiceType[11] = 'CashRegisterInternalUsage';
enums.ReeleezeeInvoiceTypeCashRegisterInternalUsage = 11;
enums.ReeleezeeInvoiceType[101] = 'Iade';
enums.ReeleezeeInvoiceTypeIade = 101;
enums.ReeleezeeInvoiceType[102] = 'Reklamasyon';
enums.ReeleezeeInvoiceTypeReklamasyon = 102;

// ReeleezeeServiceAction Enum

enums.ReeleezeeServiceAction = [];
enums.ReeleezeeServiceAction[1] = 'Created';
enums.ReeleezeeServiceActionCreated = 1;
enums.ReeleezeeServiceAction[2] = 'AddedPaymentRef';
enums.ReeleezeeServiceActionAddedPaymentRef = 2;
enums.ReeleezeeServiceAction[3] = 'Failed';
enums.ReeleezeeServiceActionFailed = 3;

// ReferenceType Enum

enums.ReferenceType = [];
enums.ReferenceType[1] = 'BusinessDeclaration';
enums.ReferenceTypeBusinessDeclaration = 1;

// ScopeType Enum

enums.ScopeType = [];
enums.ScopeType[1] = 'access';
enums.ScopeTypeAccess = 1;
enums.ScopeType[2] = 'reseller';
enums.ScopeTypeReseller = 2;
enums.ScopeType[3] = 'support';
enums.ScopeTypeSupport = 3;
enums.ScopeType[4] = 'vendor';
enums.ScopeTypeVendor = 4;
enums.ScopeType[5] = 'admin';
enums.ScopeTypeAdmin = 5;
enums.ScopeType[6] = 'external';
enums.ScopeTypeExternal = 6;

// ServiceType Enum

enums.ServiceType = [];
enums.ServiceType[1] = 'EdsnContractData';
enums.ServiceTypeEdsnContractData = 1;
enums.ServiceType[2] = 'EdsnGetScmpInformation';
enums.ServiceTypeEdsnGetScmpInformation = 2;
enums.ServiceType[3] = 'EdsnGetMeteringPointMp';
enums.ServiceTypeEdsnGetMeteringPointMp = 3;
enums.ServiceType[4] = 'EdsnContractNotice';
enums.ServiceTypeEdsnContractNotice = 4;
enums.ServiceType[5] = 'EdsnCkIdentification';
enums.ServiceTypeEdsnCkIdentification = 5;
enums.ServiceType[6] = 'EdsnFileExchange';
enums.ServiceTypeEdsnFileExchange = 6;
enums.ServiceType[7] = 'EdsnMasterDataUpdate';
enums.ServiceTypeEdsnMasterDataUpdate = 7;
enums.ServiceType[8] = 'EdsnMoveIn';
enums.ServiceTypeEdsnMoveIn = 8;
enums.ServiceType[9] = 'EdsnSwitchSUpplier';
enums.ServiceTypeEdsnSwitchSUpplier = 9;
enums.ServiceType[10] = 'EdsnSearchMeteringPoint';
enums.ServiceTypeEdsnSearchMeteringPoint = 10;
enums.ServiceType[11] = 'EdsnLoss';
enums.ServiceTypeEdsnLoss = 11;
enums.ServiceType[12] = 'EdsnGain';
enums.ServiceTypeEdsnGain = 12;
enums.ServiceType[13] = 'EdsnEndOfSupplyNotice';
enums.ServiceTypeEdsnEndOfSupplyNotice = 13;
enums.ServiceType[14] = 'EdsnEndOfSupply';
enums.ServiceTypeEdsnEndOfSupply = 14;
enums.ServiceType[15] = 'EdsnMeterReadingExchange';
enums.ServiceTypeEdsnMeterReadingExchange = 15;
enums.ServiceType[16] = 'EdsnMeterReadingNotification';
enums.ServiceTypeEdsnMeterReadingNotification = 16;
enums.ServiceType[17] = 'EdsnRejectionMeterReading';
enums.ServiceTypeEdsnRejectionMeterReading = 17;
enums.ServiceType[18] = 'EdsnMoveOut';
enums.ServiceTypeEdsnMoveOut = 18;
enums.ServiceType[19] = 'EdsnContractCancellation';
enums.ServiceTypeEdsnContractCancellation = 19;
enums.ServiceType[20] = 'EdsnContractLoss';
enums.ServiceTypeEdsnContractLoss = 20;
enums.ServiceType[21] = 'EdsnMeasureCorrection';
enums.ServiceTypeEdsnMeasureCorrection = 21;
enums.ServiceType[22] = 'EdsnMeasureCorrectionResultNotification';
enums.ServiceTypeEdsnMeasureCorrectionResultNotification = 22;
enums.ServiceType[23] = 'EdsnNameChange';
enums.ServiceTypeEdsnNameChange = 23;
enums.ServiceType[24] = 'EdsnChangeAllocation';
enums.ServiceTypeEdsnChangeAllocation = 24;
enums.ServiceType[25] = 'HistoricalData';
enums.ServiceTypeHistoricalData = 25;

// SettlementEmail Enum

enums.SettlementEmail = [];
enums.SettlementEmail[1] = 'MoveOut';
enums.SettlementEmailMoveOut = 1;
enums.SettlementEmail[2] = 'AcceptLoss';
enums.SettlementEmailAcceptLoss = 2;
enums.SettlementEmail[3] = 'HardLoss';
enums.SettlementEmailHardLoss = 3;

// SettlementInvoiceEmail Enum

enums.SettlementInvoiceEmail = [];
enums.SettlementInvoiceEmail[1] = 'Sent';
enums.SettlementInvoiceEmailSent = 1;
enums.SettlementInvoiceEmail[2] = 'FirstReminder';
enums.SettlementInvoiceEmailFirstReminder = 2;
enums.SettlementInvoiceEmail[3] = 'SecondReminder';
enums.SettlementInvoiceEmailSecondReminder = 3;
enums.SettlementInvoiceEmail[4] = 'ThirdReminder';
enums.SettlementInvoiceEmailThirdReminder = 4;
enums.SettlementInvoiceEmail[5] = 'StornoReminder';
enums.SettlementInvoiceEmailStornoReminder = 5;
enums.SettlementInvoiceEmail[6] = 'CollectionNotification';
enums.SettlementInvoiceEmailCollectionNotification = 6;

// SettlementType Enum

enums.SettlementType = [];
enums.SettlementType[1] = 'Yearly';
enums.SettlementTypeYearly = 1;
enums.SettlementType[2] = 'Final';
enums.SettlementTypeFinal = 2;

// SmartMeterChoiceType Enum

enums.SmartMeterChoiceType = [];
enums.SmartMeterChoiceType[1] = 'I got a smartmeter';
enums.SmartMeterChoiceTypeIGotASmartmeter = 1;
enums.SmartMeterChoiceType[2] = 'I get a smartmeter soon';
enums.SmartMeterChoiceTypeIGetASmartmeterSoon = 2;
enums.SmartMeterChoiceType[3] =
    'I do not have a smartmeter and want to order one';
enums.SmartMeterChoiceTypeIDoNotHaveASmartmeterAndWantToOrderOne = 3;
enums.SmartMeterChoiceType[4] =
    'I do not have a smartmeter and do not want one';
enums.SmartMeterChoiceTypeIDoNotHaveASmartmeterAndDoNotWantOne = 4;

// SnapshotEmail Enum

enums.SnapshotEmail = [];
enums.SnapshotEmail[1] = 'Proposition';
enums.SnapshotEmailProposition = 1;
enums.SnapshotEmail[2] = 'Retargeting';
enums.SnapshotEmailRetargeting = 2;
enums.SnapshotEmail[3] = 'DoubleEANFixed';
enums.SnapshotEmailDoubleEANFixed = 3;
enums.SnapshotEmail[4] = 'PropsalContract';
enums.SnapshotEmailPropsalContract = 4;
enums.SnapshotEmail[5] = 'Reminder';
enums.SnapshotEmailReminder = 5;
enums.SnapshotEmail[6] = 'Definitive';
enums.SnapshotEmailDefinitive = 6;
enums.SnapshotEmail[7] = 'StartedDelivery';
enums.SnapshotEmailStartedDelivery = 7;
enums.SnapshotEmail[8] = 'AutoTransition';
enums.SnapshotEmailAutoTransition = 8;
enums.SnapshotEmail[9] = 'ManualTransition';
enums.SnapshotEmailManualTransition = 9;
enums.SnapshotEmail[10] = 'UpfrontRetentionMail';
enums.SnapshotEmailUpfrontRetentionMail = 10;
enums.SnapshotEmail[11] = 'NewContractNotFinished';
enums.SnapshotEmailNewContractNotFinished = 11;
enums.SnapshotEmail[12] = 'SaveWithSolarPanel';
enums.SnapshotEmailSaveWithSolarPanel = 12;
enums.SnapshotEmail[13] = 'BusinessDeclaration';
enums.SnapshotEmailBusinessDeclaration = 13;

// SnapshotErrorType Enum

enums.SnapshotErrorType = [];
enums.SnapshotErrorType[1] = 'NoError';
enums.SnapshotErrorTypeNoError = 1;
enums.SnapshotErrorType[11] = 'UserShouldAgree';
enums.SnapshotErrorTypeUserShouldAgree = 11;
enums.SnapshotErrorType[21] = 'MultipleMeteringPointsFound';
enums.SnapshotErrorTypeMultipleMeteringPointsFound = 21;
enums.SnapshotErrorType[22] = 'ProductInProductGroupCheckFailed';
enums.SnapshotErrorTypeProductInProductGroupCheckFailed = 22;
enums.SnapshotErrorType[23] = 'MeteringPointBlackListed';
enums.SnapshotErrorTypeMeteringPointBlackListed = 23;
enums.SnapshotErrorType[24] = 'RejectedMeteringPoint(s)MoveSwitch';
enums.SnapshotErrorTypeRejectedMeteringPointsMoveSwitch = 24;
enums.SnapshotErrorType[25] = 'RejectedMeteringPoint(s)MoveOutLoss';
enums.SnapshotErrorTypeRejectedMeteringPointsMoveOutLoss = 25;
enums.SnapshotErrorType[31] = 'VerificationRetrievalFailed';
enums.SnapshotErrorTypeVerificationRetrievalFailed = 31;
enums.SnapshotErrorType[41] = 'StartDateHalfYearLater';
enums.SnapshotErrorTypeStartDateHalfYearLater = 41;
enums.SnapshotErrorType[42] = 'UserStartDateBeforeVerifiedStartDate';
enums.SnapshotErrorTypeUserStartDateBeforeVerifiedStartDate = 42;
enums.SnapshotErrorType[43] = 'StartDateBeforeTwoWeeksCreation';
enums.SnapshotErrorTypeStartDateBeforeTwoWeeksCreation = 43;
enums.SnapshotErrorType[51] = 'UserSpecifiedSmartmeterIncorrect';
enums.SnapshotErrorTypeUserSpecifiedSmartmeterIncorrect = 51;
enums.SnapshotErrorType[52] = 'UserSpecifiedNoSmartmeterIncorrect';
enums.SnapshotErrorTypeUserSpecifiedNoSmartmeterIncorrect = 52;
enums.SnapshotErrorType[53] = 'SmartmeterOffline';
enums.SnapshotErrorTypeSmartmeterOffline = 53;
enums.SnapshotErrorType[54] = 'SmartmeterRequired';
enums.SnapshotErrorTypeSmartmeterRequired = 54;
enums.SnapshotErrorType[61] = 'UsageOrCaptarIncorrect';
enums.SnapshotErrorTypeUsageOrCaptarIncorrect = 61;
enums.SnapshotErrorType[71] = 'DuplicateActiveFlexSnapshot';
enums.SnapshotErrorTypeDuplicateActiveFlexSnapshot = 71;
enums.SnapshotErrorType[131] = 'DeliveryPlannedOrStartedFailed';
enums.SnapshotErrorTypeDeliveryPlannedOrStartedFailed = 131;
enums.SnapshotErrorType[132] = 'DeliveryP4SettingsNotUpdated';
enums.SnapshotErrorTypeDeliveryP4SettingsNotUpdated = 132;
enums.SnapshotErrorType[133] = 'DeliveryStartDateWithWorkdayCheckFailed';
enums.SnapshotErrorTypeDeliveryStartDateWithWorkdayCheckFailed = 133;

// SnapshotNotDeliveredNotificationType Enum

enums.SnapshotNotDeliveredNotificationType = [];
enums.SnapshotNotDeliveredNotificationType[1] = 'Auto';
enums.SnapshotNotDeliveredNotificationTypeAuto = 1;
enums.SnapshotNotDeliveredNotificationType[2] = 'Manual';
enums.SnapshotNotDeliveredNotificationTypeManual = 2;

// SnapshotPhase Enum

enums.SnapshotPhase = [];
enums.SnapshotPhase[1] = 'UserInput';
enums.SnapshotPhaseUserInput = 1;
enums.SnapshotPhase[2] = 'UserAgreement';
enums.SnapshotPhaseUserAgreement = 2;
enums.SnapshotPhase[3] = 'MeteringPointsChecked';
enums.SnapshotPhaseMeteringPointsChecked = 3;
enums.SnapshotPhase[4] = 'RetrievedVerification';
enums.SnapshotPhaseRetrievedVerification = 4;
enums.SnapshotPhase[5] = 'DateChecked';
enums.SnapshotPhaseDateChecked = 5;
enums.SnapshotPhase[6] = 'SmartMeterChecked';
enums.SnapshotPhaseSmartMeterChecked = 6;
enums.SnapshotPhase[7] = 'UsageChecked';
enums.SnapshotPhaseUsageChecked = 7;
enums.SnapshotPhase[8] = 'SmartMeterFound';
enums.SnapshotPhaseSmartMeterFound = 8;
enums.SnapshotPhase[9] = 'FirstInvoiceSend';
enums.SnapshotPhaseFirstInvoiceSend = 9;
enums.SnapshotPhase[10] = 'FirstPaymentDone';
enums.SnapshotPhaseFirstPaymentDone = 10;
enums.SnapshotPhase[11] = 'DeliveryPlanned';
enums.SnapshotPhaseDeliveryPlanned = 11;
enums.SnapshotPhase[12] = 'DeliveryStarted';
enums.SnapshotPhaseDeliveryStarted = 12;
enums.SnapshotPhase[13] = 'DeliveryFailed';
enums.SnapshotPhaseDeliveryFailed = 13;
enums.SnapshotPhase[14] = 'DeliveryStopped';
enums.SnapshotPhaseDeliveryStopped = 14;
enums.SnapshotPhase[15] = 'NeverDelivered';
enums.SnapshotPhaseNeverDelivered = 15;
enums.SnapshotPhase[16] = 'MeterUnderConstruction';
enums.SnapshotPhaseMeterUnderConstruction = 16;
enums.SnapshotPhase[900] = 'DemoContractCreated';
enums.SnapshotPhaseDemoContractCreated = 900;
enums.SnapshotPhase[990] = 'InsightContractActive';
enums.SnapshotPhaseInsightContractActive = 990;
enums.SnapshotPhase[998] = 'InsightNeverActive';
enums.SnapshotPhaseInsightNeverActive = 998;
enums.SnapshotPhase[999] = 'InsightContractStopped';
enums.SnapshotPhaseInsightContractStopped = 999;
enums.SnapshotPhase[2000] = 'SmartChargingContractCreated';
enums.SnapshotPhaseSmartChargingContractCreated = 2000;
enums.SnapshotPhase[2001] = 'SmartChargingCarAttached';
enums.SnapshotPhaseSmartChargingCarAttached = 2001;
enums.SnapshotPhase[2010] = 'SmartChargingActive';
enums.SnapshotPhaseSmartChargingActive = 2010;
enums.SnapshotPhase[2020] = 'SmartChargingStopped';
enums.SnapshotPhaseSmartChargingStopped = 2020;

// SnapshotType Enum

enums.SnapshotType = [];
enums.SnapshotType[1] = 'Default';
enums.SnapshotTypeDefault = 1;
enums.SnapshotType[2] = 'Insight';
enums.SnapshotTypeInsight = 2;
enums.SnapshotType[3] = 'LPEHouseHold';
enums.SnapshotTypeLPEHouseHold = 3;
enums.SnapshotType[4] = 'LPEChargingPole';
enums.SnapshotTypeLPEChargingPole = 4;

// Source Enum

enums.Source = [];
enums.Source[1] = 'CSV';
enums.SourceCSV = 1;

// SplitType Enum

enums.SplitType = [];
enums.SplitType[1] = 'Split';
enums.SplitTypeSplit = 1;
enums.SplitType[2] = 'Private';
enums.SplitTypePrivate = 2;
enums.SplitType[3] = 'Business';
enums.SplitTypeBusiness = 3;

// TMRType Enum

enums.TMRType = [];
enums.TMRType[1] = 'Start';
enums.TMRTypeStart = 1;
enums.TMRType[2] = 'End';
enums.TMRTypeEnd = 2;

// TaskStatusType Enum

enums.TaskStatusType = [];
enums.TaskStatusType[1] = 'Pending';
enums.TaskStatusTypePending = 1;
enums.TaskStatusType[2] = 'InProgress';
enums.TaskStatusTypeInProgress = 2;
enums.TaskStatusType[3] = 'Deferred';
enums.TaskStatusTypeDeferred = 3;
enums.TaskStatusType[4] = 'Completed';
enums.TaskStatusTypeCompleted = 4;

// TaxPurposeType Enum

enums.TaxPurposeType = [];
enums.TaxPurposeType[1] = 'Business';
enums.TaxPurposeTypeBusiness = 1;
enums.TaxPurposeType[2] = 'Consumption';
enums.TaxPurposeTypeConsumption = 2;

// TaxType Enum

enums.TaxType = [];
enums.TaxType[1] = 'Elec Usage Tax';
enums.TaxTypeElecUsageTax = 1;
enums.TaxType[2] = 'Elec Storage Tax';
enums.TaxTypeElecStorageTax = 2;
enums.TaxType[3] = 'Gas Usage Tax';
enums.TaxTypeGasUsageTax = 3;
enums.TaxType[4] = 'Gas Storage Tax';
enums.TaxTypeGasStorageTax = 4;
enums.TaxType[5] = 'Tax Credit';
enums.TaxTypeTaxCredit = 5;

// TransactionStatusType Enum

enums.TransactionStatusType = [];
enums.TransactionStatusType[11] = 'Created';
enums.TransactionStatusTypeCreated = 11;
enums.TransactionStatusType[12] = 'Pending';
enums.TransactionStatusTypePending = 12;
enums.TransactionStatusType[19] = 'Manual';
enums.TransactionStatusTypeManual = 19;
enums.TransactionStatusType[21] = 'Success';
enums.TransactionStatusTypeSuccess = 21;
enums.TransactionStatusType[81] = 'CancelledByMerchant';
enums.TransactionStatusTypeCancelledByMerchant = 81;
enums.TransactionStatusType[82] = 'CancelledByConsumer';
enums.TransactionStatusTypeCancelledByConsumer = 82;
enums.TransactionStatusType[91] = 'Failure';
enums.TransactionStatusTypeFailure = 91;
enums.TransactionStatusType[92] = 'Expired';
enums.TransactionStatusTypeExpired = 92;
enums.TransactionStatusType[93] = 'Invalid';
enums.TransactionStatusTypeInvalid = 93;
enums.TransactionStatusType[94] = 'TechnicalError';
enums.TransactionStatusTypeTechnicalError = 94;
enums.TransactionStatusType[95] = 'Rejected';
enums.TransactionStatusTypeRejected = 95;
enums.TransactionStatusType[96] = 'Storno';
enums.TransactionStatusTypeStorno = 96;

// TransmissionValueType Enum

enums.TransmissionValueType = [];
enums.TransmissionValueType[1] = 'Unknown';
enums.TransmissionValueTypeUnknown = 1;
enums.TransmissionValueType[10000] = 'Nultarief elektriciteit';
enums.TransmissionValueTypeNultariefElektriciteit = 10000;
enums.TransmissionValueType[10101] = '1x6A geschakeld net, onbemeten';
enums.TransmissionValueType1x6AGeschakeldNetOnbemeten = 10101;
enums.TransmissionValueType[10111] = '1x6A geschakeld net';
enums.TransmissionValueType1x6AGeschakeldNet = 10111;
enums.TransmissionValueType[10201] = '3x25A t/m 1x80A, onbemeten';
enums.TransmissionValueType3x25ATm1x80AOnbemeten = 10201;
enums.TransmissionValueType[10211] = '3x25A t/m 1x80A';
enums.TransmissionValueType3x25ATm1x80A = 10211;
enums.TransmissionValueType[10311] = '3x25A t/m 3x35A';
enums.TransmissionValueType3x25ATm3x35A = 10311;
enums.TransmissionValueType[10411] = '3x35A t/m 3x50A';
enums.TransmissionValueType3x35ATm3x50A = 10411;
enums.TransmissionValueType[10511] = '3x50A t/m 3x63A';
enums.TransmissionValueType3x50ATm3x63A = 10511;
enums.TransmissionValueType[10611] = '3x63A t/m 3x80A';
enums.TransmissionValueType3x63ATm3x80A = 10611;
enums.TransmissionValueType[20000] = 'Nultarief gas';
enums.TransmissionValueTypeNultariefGas = 20000;
enums.TransmissionValueType[20101] = 'Onbemeten gas';
enums.TransmissionValueTypeOnbemetenGas = 20101;
enums.TransmissionValueType[20111] = '10 m3/uur tot 500 m3/jaar';
enums.TransmissionValueType10M3uurTot500M3jaar = 20111;
enums.TransmissionValueType[20211] = '10 m3/uur tussen 500 m3 en 4.000 m3/jaar';
enums.TransmissionValueType10M3uurTussen500M3En4000M3jaar = 20211;
enums.TransmissionValueType[20311] = '10 m3/uur meer dan 4.000 m3/jaar';
enums.TransmissionValueType10M3uurMeerDan4000M3jaar = 20311;
enums.TransmissionValueType[20411] = '10 m3/uur <= 16 m3/uur';
enums.TransmissionValueType10M3uur16M3uur = 20411;
enums.TransmissionValueType[20511] = '16 m3/uur <= 25 m3/uur';
enums.TransmissionValueType16M3uur25M3uur = 20511;
enums.TransmissionValueType[20611] = '25 m3/uur <= 40 m3/uur';
enums.TransmissionValueType25M3uur40M3uur = 20611;
enums.TransmissionValueType[20621] = '25 m3/uur <= 40 m3/uur +EVHI';
enums.TransmissionValueType25M3uur40M3uurEVHI = 20621;
enums.TransmissionValueType[20711] = '40 m3/uur <= 65 m3/uur';
enums.TransmissionValueType40M3uur65M3uur = 20711;
enums.TransmissionValueType[20721] = '40 m3/uur <= 65 m3/uur +EVHI';
enums.TransmissionValueType40M3uur65M3uurEVHI = 20721;
enums.TransmissionValueType[20811] = '65 m3/uur <= 100 m3/uur';
enums.TransmissionValueType65M3uur100M3uur = 20811;
enums.TransmissionValueType[20821] = '65 m3/uur <= 100 m3/uur +EVHI';
enums.TransmissionValueType65M3uur100M3uurEVHI = 20821;
enums.TransmissionValueType[20911] = '100 m3/uur <= 160 m3/uur';
enums.TransmissionValueType100M3uur160M3uur = 20911;
enums.TransmissionValueType[20921] = '100 m3/uur <= 160 m3/uur +EVHI';
enums.TransmissionValueType100M3uur160M3uurEVHI = 20921;
enums.TransmissionValueType[21011] = '160 m3/uur <= 250 m3/uur';
enums.TransmissionValueType160M3uur250M3uur = 21011;
enums.TransmissionValueType[21021] = '160 m3/uur <= 250 m3/uur +EVHI';
enums.TransmissionValueType160M3uur250M3uurEVHI = 21021;
enums.TransmissionValueType[21111] = '250 m3/uur <= 400 m3/uur';
enums.TransmissionValueType250M3uur400M3uur = 21111;
enums.TransmissionValueType[21121] = '250 m3/uur <= 400 m3/uur +EVHI';
enums.TransmissionValueType250M3uur400M3uurEVHI = 21121;
enums.TransmissionValueType[21211] = '400 m3/uur <= 650 m3/uur';
enums.TransmissionValueType400M3uur650M3uur = 21211;
enums.TransmissionValueType[21221] = '400 m3/uur <= 650 m3/uur +EVHI';
enums.TransmissionValueType400M3uur650M3uurEVHI = 21221;
enums.TransmissionValueType[21311] = '650 m3/uur <= 1000 m3/uur';
enums.TransmissionValueType650M3uur1000M3uur = 21311;
enums.TransmissionValueType[21321] = '650 m3/uur <= 1000 m3/uur +EVHI';
enums.TransmissionValueType650M3uur1000M3uurEVHI = 21321;
enums.TransmissionValueType[21411] = '1000 m3/uur <= 1600 m3/uur';
enums.TransmissionValueType1000M3uur1600M3uur = 21411;
enums.TransmissionValueType[21421] = '1000 m3/uur <= 1600 m3/uur +EVHI';
enums.TransmissionValueType1000M3uur1600M3uurEVHI = 21421;
enums.TransmissionValueType[21511] = '1600 m3/uur <= 2500 m3/uur';
enums.TransmissionValueType1600M3uur2500M3uur = 21511;
enums.TransmissionValueType[21521] = '1600 m3/uur <= 2500 m3/uur +EVHI';
enums.TransmissionValueType1600M3uur2500M3uurEVHI = 21521;
enums.TransmissionValueType[21611] = '2500 m3/uur';
enums.TransmissionValueType2500M3uur = 21611;
enums.TransmissionValueType[21621] = '2500 m3/uur +EVHI';
enums.TransmissionValueType2500M3uurEVHI = 21621;

// UsageType Enum

enums.UsageType = [];
enums.UsageType[1] = 'Elek usage';
enums.UsageTypeElekUsage = 1;
enums.UsageType[2] = 'Elek prod';
enums.UsageTypeElekProd = 2;
enums.UsageType[3] = 'Gas usage';
enums.UsageTypeGasUsage = 3;
enums.UsageType[4] = 'Additional';
enums.UsageTypeAdditional = 4;
enums.UsageType[21] = 'Elek usage Estimation';
enums.UsageTypeElekUsageEstimation = 21;
enums.UsageType[22] = 'Elek prod estimation';
enums.UsageTypeElekProdEstimation = 22;
enums.UsageType[23] = 'Gas usage Estimation';
enums.UsageTypeGasUsageEstimation = 23;
enums.UsageType[31] = 'CostSavings';
enums.UsageTypeCostSavings = 31;

// UserType Enum

enums.UserType = [];
enums.UserType[1] = 'Consumer';
enums.UserTypeConsumer = 1;
enums.UserType[2] = 'FreeInsight';
enums.UserTypeFreeInsight = 2;

// VirtualMeteringPointSource Enum

enums.VirtualMeteringPointSource = [];
enums.VirtualMeteringPointSource[1] = 'LPE';
enums.VirtualMeteringPointSourceLPE = 1;
enums.VirtualMeteringPointSource[99] = 'Conditional';
enums.VirtualMeteringPointSourceConditional = 99;

// VirtualMeteringPointStatusType Enum

enums.VirtualMeteringPointStatusType = [];
enums.VirtualMeteringPointStatusType[1] = 'On';
enums.VirtualMeteringPointStatusTypeOn = 1;
enums.VirtualMeteringPointStatusType[2] = 'Off';
enums.VirtualMeteringPointStatusTypeOff = 2;

// VirtualMeteringPointType Enum

enums.VirtualMeteringPointType = [];
enums.VirtualMeteringPointType[1] = 'ChargingPole';
enums.VirtualMeteringPointTypeChargingPole = 1;
enums.VirtualMeteringPointType[2] = 'HouseHold';
enums.VirtualMeteringPointTypeHouseHold = 2;
enums.VirtualMeteringPointType[3] = 'HouseHoldChargingPole';
enums.VirtualMeteringPointTypeHouseHoldChargingPole = 3;

// WorkDayType Enum

enums.WorkDayType = [];
enums.WorkDayType[1] = 'Work';
enums.WorkDayTypeWork = 1;
enums.WorkDayType[2] = 'Vacation';
enums.WorkDayTypeVacation = 2;

export default enums;
