import { gqlResponse } from '@async-reducers/helpers/gql';
import { ReduxAsync } from '@weavedev/redux-async';
import axios, { AxiosResponse } from 'axios';
import headers from '../sagas/helpers/headers';

const HAS_CONVENTIONAL_METERING_POINT = 'HAS_CONVENTIONAL_METERING_POINT';
const HAS_CONVENTIONAL_METERING_POINT_SUCCESS =
    'HAS_CONVENTIONAL_METERING_POINT_SUCCESS';
const HAS_CONVENTIONAL_METERING_POINT_FAILED =
    'CHAS_CONVENTIONAL_METERING_POINT_FAILED';
export const hasConventionalMeter = new ReduxAsync(
    HAS_CONVENTIONAL_METERING_POINT,
    HAS_CONVENTIONAL_METERING_POINT_SUCCESS,
    HAS_CONVENTIONAL_METERING_POINT_FAILED,
    async (snapshotId: string): Promise<any> => {
        const response: AxiosResponse = await axios.post(
            `/v1/gql`,
            {
                query: `
query HasConventionalMeter($snapshotId:UUID!) {
  HasConventionalMeter(
    snapshotId:$snapshotId)
} 
                `,
                variables: { snapshotId },
            },
            {
                baseURL: window.API_LINK,
                headers: headers(window.STDHeaders),
            },
        );
        // if a pure gql error occurs, gql will always send a 200 regardless
        // in case there is not a 200, a different error, like a network error could have occurred
        if (
            response &&
            response.statusText !== 'OK' &&
            response.status !== 200
        ) {
            throw response.status;
        }
        const gqlData = response.data as gqlResponse;
        if (!gqlData.errors?.length) {
            return response.data;
        }
        window.displayMessage(
            'Er ging iets mis tijdens ophalen of het een conventionele meter is.',
            'warning',
        );
        throw gqlData.errors[0]?.message;
    },
);

const SET_METERING_POINTS = 'SET_METERING_POINTS';
const SET_METERING_POINTS_SUCCESS = 'SET_METERING_POINTS_SUCCESS';
const SET_METERING_POINTS_FAILED = 'SET_METERING_POINTS_FAILED';
export const setMeteringPoints = new ReduxAsync(
    SET_METERING_POINTS,
    SET_METERING_POINTS_SUCCESS,
    SET_METERING_POINTS_FAILED,
    async (snapshotId: string, meteringPointIds: string[]): Promise<any> => {
        const response: AxiosResponse = await axios
            .post(
                `/v1/gql`,
                {
                    query: `
mutation SetMeteringPoints($input: SetMeteringPoints) {
  setMeteringPoints(input: $input) {
    meteringPoints {
      id
    }
  }
}
                `,
                    variables: {
                        input: {
                            meteringPointIds,
                            snapshotId,
                        },
                    },
                },
                {
                    baseURL: window.API_LINK,
                    headers: {
                        vendors: '*',
                        ...headers(window.STDHeaders),
                    },
                },
            )
            .catch((e) => {
                window.displayMessage(
                    'Er ging iets mis tijdens het doorgeven van de EAN-code. Probeer het later nog eens of neem contact met ons op.',
                    'warning',
                );
                throw e;
            });
        // if a pure gql error occurs, gql will always send a 200 regardless
        // in case there is not a 200, a different error, like a network error could have occurred
        if (
            response &&
            response.statusText !== 'OK' &&
            response.status !== 200
        ) {
            throw response.status;
        }
        const gqlData = response.data as gqlResponse;
        if (!gqlData.errors?.length) {
            return response.data;
        }
        window.displayMessage(
            'Er ging iets mis tijdens het zetten van de meetpunten',
            'warning',
        );
        throw gqlData.errors[0]?.message;
    },
);

const GET_METERING_POINTS = 'GET_METERING_POINTS';
const GET_METERING_POINTS_SUCCESS = 'GET_METERING_POINTS_SUCCESS';
const GET_METERING_POINTS_FAILED = 'GET_METERING_POINTS_FAILED';
export const getMeteringPoints = new ReduxAsync(
    GET_METERING_POINTS,
    GET_METERING_POINTS_SUCCESS,
    GET_METERING_POINTS_FAILED,
    async (snapshotId: string, vendorId: string): Promise<any> => {
        const response: AxiosResponse = await axios
            .post(
                `/v1/gql`,
                {
                    query: `
query Snapshot($snapshotId: String!) {
  snapshot(snapshotId: $snapshotId) {
    meteringPoints {
      id
      identifier
      description
      metadata
    }
  }
}
                `,
                    variables: {
                        snapshotId,
                    },
                },
                {
                    baseURL: window.API_LINK,
                    headers: {
                        vendors: vendorId,
                        ...headers(window.STDHeaders),
                    },
                },
            )
            .catch((e) => {
                window.displayMessage(
                    'Er is iets misgegaan bij het ophalen van de metering punten. Probeer het later nog eens of neem contact met ons op.',
                    'warning',
                );
                throw e;
            });
        // if a pure gql error occurs, gql will always send a 200 regardless
        // in case there is not a 200, a different error, like a network error could have occurred
        if (
            response &&
            response.statusText !== 'OK' &&
            response.status !== 200
        ) {
            throw response.status;
        }
        const gqlData = response.data as gqlResponse;
        if (!gqlData.errors?.length) {
            return response.data;
        }
        window.displayMessage(
            'Er is helaas iets misgegaan bij het ophalen van de meetpunten.',
            'warning',
        );
        throw gqlData.errors[0]?.message;
    },
);

const SEND_METERING_POINTS_REMINDER_MAIL = 'SEND_METERING_POINTS_REMINDER_MAIL';
const SEND_METERING_POINTS_REMINDER_MAIL_SUCCESS =
    'SEND_METERING_POINTS_REMINDER_MAIL_SUCCESS';
const SEND_METERING_POINTS_REMINDER_MAIL_FAILED =
    'SEND_METERING_POINTS_REMINDER_MAIL_FAILED';
export const sendMeteringPointsReminderMail = new ReduxAsync(
    SEND_METERING_POINTS_REMINDER_MAIL,
    SEND_METERING_POINTS_REMINDER_MAIL_SUCCESS,
    SEND_METERING_POINTS_REMINDER_MAIL_FAILED,
    async (snapshotId: string): Promise<any> => {
        const response: AxiosResponse = await axios
            .post(
                `/v1/gql`,
                {
                    query: `
mutation SendMeteringPointOnboardingReminder($input: SendMeteringPointOnboardingReminder!) {
  sendMeteringPointOnboardingReminder(input: $input)
}
                `,
                    variables: {
                        input: {
                            snapshotId,
                        },
                    },
                },
                {
                    baseURL: window.API_LINK,
                    headers: headers(window.STDHeaders),
                },
            )
            .catch((e) => {
                window.displayMessage(
                    'Er is iets misgegaan bij het verzenden van de reminder.',
                    'warning',
                );
                throw e;
            });
        // if a pure gql error occurs, gql will always send a 200 regardless
        // in case there is not a 200, a different error, like a network error could have occurred
        if (
            response &&
            response.statusText !== 'OK' &&
            response.status !== 200
        ) {
            throw response.status;
        }
        const gqlData = response.data as gqlResponse;
        if (!gqlData.errors?.length) {
            window.displayMessage(
                'De herinneringsmail wordt naar u verstuurd. Het kan een paar minuten duren voordat u deze ontvangt.',
                'info',
            );
            return response.data;
        }
        window.displayMessage(
            'Er is iets misgegaan bij het verzenden van de reminder.',
            'warning',
        );
        throw gqlData.errors[0]?.message;
    },
);
