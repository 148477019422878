const updateReady = () =>
    document.dispatchEvent(new CustomEvent('updateReady'));

/**
 * Track installment of serviceworker
 * @param {ServiceWorker|null} worker
 */
const trackInstalling = (worker) => {
    if (worker != null) {
        worker.addEventListener('statechange', () => {
            if (worker.state === 'installed') {
                updateReady();
            }
        });
    }
};

export default () => {
    if (!('serviceWorker' in navigator)) {
        console.warn('SW is not supported');
        return;
    }

    navigator.serviceWorker
        .register('/sw.js')
        .then((registration) => {
            if (!navigator.serviceWorker.controller) {
                return;
            }
            if (registration.waiting) {
                updateReady();
                return;
            }

            if (registration.installing) {
                trackInstalling(registration.installing);
                return;
            }

            registration.addEventListener('updatefound', () =>
                trackInstalling(registration.installing),
            );
        })
        .catch((err) => {
            console.warn('Error during service worker registration:', err);
        });
};
