import { PolymerElement, html } from '@polymer/polymer/polymer-element';
import { provider } from '@weavelab/frontend-connect';

import { Router } from '@vaadin/router';
import css from '../styles.pcss';
import settingsEditCss from '../../settings-edit.pcss';
import template from './template.html';
import enums from '../../../../../../enums';
import { setError } from '../../../../../../actions/error';
import { store } from '../../../../../../store';
import '@polymer/paper-dialog/paper-dialog';
import '@polymer/paper-dialog-scrollable/paper-dialog-scrollable';
import { routePaths } from '../../../../../../helpers/routing';
import '../../../../../atoms/RadioButtonGroup';
/**
 * Login class
 * @namespace SettingsEditPaymentMethod
 * @class SettingsEditPaymentMethod
 * @extends PolymerElement
 */
export default class SettingsEditPaymentMethod extends PolymerElement {
    /**
     * Constructor
     */
    constructor() {
        super();
        /** @type {Object} */
        this.snapshot = {};
        /** @type {Boolean} */
        this.paymentMethodChoice = false;
    }

    /**
     * Gets properties of class
     */
    static get properties() {
        return {
            snapshot: {
                type: Object,
                observer: '_snapshotChanged',
            },
            paymentMethodChoice: {
                type: Boolean,
            },
            showConfirmationPopup: {
                type: Boolean,
                value: false,
            },
        };
    }

    /**
     * Gets template of class
     */
    static get template() {
        const cssTemplate = document.createElement('template');
        cssTemplate.innerHTML =
            provider.styles(css) + provider.styles(settingsEditCss);
        const htmlTemplate = document.createElement('template');
        htmlTemplate.innerHTML = template;
        return html` <style include="iron-flex">
                ${cssTemplate}
            </style>
            ${htmlTemplate}`;
    }

    /**
     * _updatePaymentMethodClick
     */
    _updatePaymentMethodClick() {
        if (this.paymentMethodChoice) {
            // @ts-ignore
            this.$.confirmDialog.open();
        } else {
            fetch(
                `${API_LINK}/v1/user/${this.snapshot.user_id}/snapshot/${this.snapshot.id}/updateToIdealPaymentMethod`,
                {
                    method: 'PUT',
                    headers: STDHeaders,
                },
            )
                .then((res) => {
                    if (res.status === 204) {
                        window.displayMessage(
                            'Je betaalmethode is succesvol gewijzigd naar iDEAL.',
                        );
                        Router.go(routePaths.settings);
                    } else {
                        store.dispatch(
                            setError(
                                `Het wijzigen van de betaalmethode naar iDEAL is niet gelukt. ` +
                                    `Probeer het later nog eens of neem contact op met de klantenservice.`,
                            ),
                        );
                    }
                })
                .catch((error) => {
                    console.warn(error);
                    store.dispatch(
                        setError(
                            'Er is iets fout gegaan. Probeer het later nog eens.',
                        ),
                    );
                });
        }
    }

    /**
     * _snapshotChanged
     * @param {Object} snapshot
     */
    _snapshotChanged(snapshot) {
        if (snapshot && snapshot.paymentaccount && snapshot.payment_method) {
            switch (snapshot.payment_method) {
                case enums.PaymentMethodIdealLink:
                    this.set('paymentMethodChoice', false);
                    break;
                case enums.PaymentMethodWithPaymentAccount:
                    this.set('paymentMethodChoice', true);
                    break;
                default:
                    break;
            }
        }
    }

    /**
     * Goes back to settings overview
     */
    _backButtonPressed = () => {
        Router.go(routePaths.editSettings);
    };

    /**
     * Give mandate
     */
    _giveMandate() {
        window.open(
            `${window.API_LINK}/v1/payments/emandate/${this.snapshot.id}?returnURL=true`,
            '_blank',
        );
    }

    /**
     * Give mandate
     */
    _updatePaymentMethod = () => {
        alert('Update paymentMethod here');
    };

    /**
     * Subscribes on user state when element is ready
     */
    ready() {
        super.ready();
        this.snapshot = store.getState().jwtSnapshots.selectedSnapshot;

        const dialog = this.$.confirmDialog;
        dialog.addEventListener('opened-changed', (event) => {
            // @ts-ignore
            if (event.target.closingReason.confirmed) {
                switch (this.paymentMethodChoice) {
                    case true:
                        this._giveMandate();
                        break;
                    default:
                        window.displayMessage(
                            'Betaalmethode kan niet gewijzigd worden. Probeer het later nog eens.',
                        );
                        break;
                }
            }
        });
    }
}

window.customElements.define(
    'settings-edit-payment-method',
    SettingsEditPaymentMethod,
);
