import { store } from '../../store';
import { setRedirect, clearRedirect } from '../../actions/redirect';

/**
 * Redirect class help to manage and check the redirect state
 */
class RedirectManager {
    /**
     * redirectUrl will get the redirect from the state
     * @return {string}
     */
    get redirectURL() {
        this._validate();
        return this._getRedirectState().to;
    }

    /**
     * set redirectURL will set the url to the store
     * @param {string} url
     */
    set redirectURL(url) {
        store.dispatch(setRedirect(url));
    }

    /**
     * clear redirect url will empty the state
     */
    clear = () => {
        store.dispatch(clearRedirect());
    };

    /**
     * validate will check if we don't pass the time till when the redirect takes place
     */
    _validate() {
        const r = this._getRedirectState();
        let diffInMinutes = 0;
        if (r.till !== '') {
            let rt;
            try {
                rt = new Date(r.till);
            } catch (e) {
                return;
            }
            const now = new Date(Date.now());
            // @ts-ignore
            const millisecondsDiff = Math.abs(now - rt);
            diffInMinutes = Math.ceil(millisecondsDiff / (1000 * 60));
        }
        if (diffInMinutes > 10) {
            this.clear();
        }
    }

    /**
     * _getRedirectState will make sure we work with the correct state
     * @return {Object}
     * @private
     */
    _getRedirectState = () => {
        const r = store.getState().redirect;
        if (r?.to && r?.till) {
            return r;
        }
        return { to: '', till: '' };
    };
}

export const redirectManager = new RedirectManager();
