import { css } from 'lit';

export default css`
    :host {
        --input-width: 22px;
        --input-height: 38px;
        --input-padding-horizontal: 10px;
        --input-border: 2px solid rgb(224, 224, 224);
        --input-border-radius: 0px;
        --input-margin-vertical: 10px;
        --input-space-between-inputs: -1px;
        --input-label-font-size: 12px;
    }

    @media only screen and (max-width: 425px) {
        :host {
            --input-width: 18px;
        }
    }

    @media only screen and (max-width: 375px) {
        :host {
            --input-width: 8px;
        }
    }

    /* Render befores and afters on one line */
    .input-group {
        display: flex;
    }
    :host .input-group {
        display: flex;
    }
    ::slotted(label) {
        font-size: var(--input-label-font-size);
    }
    .input-group__container
        > .input-group__input
        ::slotted(input:not(:last-child)) {
        margin-right: 2px;
    }
    :host([controller]) ::slotted(input) {
        display: none;
    }
    :host([child]) ::slotted(label) {
        display: none !important;
    }
    :host ::slotted(input) {
        width: var(--input-width);
        height: var(--input-height);
        padding-left: var(--input-padding-horizontal);
        padding-right: var(--input-padding-horizontal);
        text-align: center;
        border: var(--input-border);
        border-left: none;
        border-radius: var(--input-border-radius);
    }
    :host([first]) {
        margin-left: 0;
        margin-top: var(--input-margin-vertical);
        margin-bottom: var(--input-margin-vertical);
        border-left: var(--input-border);
    }
    :host([child]) {
        margin-left: var(--input-space-between-inputs);
    }
`;
