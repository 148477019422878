import {
    LitElement,
    customElement,
    html,
    CSSResult,
    property,
} from 'lit-element';
import { whitelabel } from '@weavelab/whitelabel';
import style from './PlaceholderStyle';

import '@atoms/icon/HvIcon';

@customElement('hv-page-placeholder')
export class HvPagePlaceholder extends LitElement {
    @property({ type: String })
    title: string = '';

    @property({ type: String })
    subtitle: string = '';

    @property({ type: String })
    icon: string = '';

    @whitelabel()
    public static get styles(): CSSResult[] {
        return [style];
    }

    render() {
        return html`<div>
            <hv-icon name=${this.icon} class="icon"></hv-icon>
            <p class="title">${this.title}</p>
            <p class="subtitle">${this.subtitle}</p>
        </div>`;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        'hv-page-placeholder': HvPagePlaceholder;
    }
}
