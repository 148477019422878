import {
    REQUEST_USER,
    RECEIVE_USER,
    HAS_DATA_USER,
    CLEAR_CREATE_USER_QUERY,
    REQUEST_CREATE_USER,
    REQUEST_CREATE_USER_SUCCESS,
    REQUEST_CREATE_USER_FAILED,
    RESET_CREATE_USER,
} from '../actions/user';

/**
 * State reducer for User state
 * @param {Object} state
 * @param {Object} action
 * @return {Object}
 */
export const user = (state = { query: null }, action) => {
    switch (action.type) {
        case REQUEST_USER:
            return {
                ...action.user,
                query: action.query,
                failure: false,
                isFetching: true,
            };
        case RECEIVE_USER:
            return {
                ...state,
                ...action.user,
                failure: false,
                isFetching: false,
            };
        case HAS_DATA_USER:
            return {
                ...state,
                hasData: true,
            };
        default:
            return state;
    }
};

const defaultCreateUserState = { query: null, data: null, error: null };

/**
 * State reducer for create user request
 * @param {Object} state
 * @param {Object} action
 * @return {Object}
 */
export const createUser = (state = { ...defaultCreateUserState }, action) => {
    switch (action.type) {
        case CLEAR_CREATE_USER_QUERY:
            return { ...state, query: null, error: null };
        case REQUEST_CREATE_USER:
            return { ...state, query: action.payload, data: null };
        case REQUEST_CREATE_USER_SUCCESS:
            return { ...state, data: action.data, error: null };
        case REQUEST_CREATE_USER_FAILED:
            return { ...state, error: action.error };
        case RESET_CREATE_USER:
            return { ...defaultCreateUserState };
        default:
            return state;
    }
};
