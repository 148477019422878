/**
 * Takes in a headers object and maps them to a object
 * @param {Headers} heads
 * @return {Object}
 */
const headers = (heads) => {
    const returnable = {};
    for (const h of heads.entries()) {
        if (h[1]) {
            // @ts-ignore
            returnable[h[0]] = h[1];
        }
    }
    return returnable;
};
export default headers;
