import { setNotification } from '@actions/notification';
import { store } from 'data-access/store';
import { SuccessAction } from 'goagen-js-resource';
import { takeLatest } from 'redux-saga/effects';
import { Snapshot } from 'types/snapshot';
import { VENDOR_CONFIG_SUCCESS } from '../async-reducers/vendor';

/**
 * EXAMPLE Cases for showing minimal start date notification to user
 * Background
 *      Given selected snapshot = vast And current date = 8 And min wait days = 5
 *
 * When min start date 14 / 3   Then show notification
 * When min start date 12 / 3   Then don't show notification
 * When min start date 7 / 3    Then don't show notification
 */
function* vendorConfigSuccess(result: SuccessAction) {
    const { selectedSnapshot } = store.getState().jwtSnapshots;
    const snapShotIsVast = new Snapshot(selectedSnapshot).isVast();
    if (!snapShotIsVast) return;

    const { min_start_date, min_waiting_days } = result.data;
    const date_min_start_date = new Date(min_start_date);

    // add days to date
    // TODO same functions being used in customer-form.js make date helper function!
    const dateCurrentPlusWaitingDays = new Date();
    dateCurrentPlusWaitingDays.setDate(
        dateCurrentPlusWaitingDays.getDate() + min_waiting_days,
    );

    const minStartDateString = `${date_min_start_date.getDate()}-${
        date_min_start_date.getMonth() + 1
    }-${date_min_start_date.getFullYear()}`;

    // if minimal start date is later than today then let the user know with a popup
    if (dateCurrentPlusWaitingDays < date_min_start_date) {
        store.dispatch(
            setNotification(
                `Je hebt voor een vast contract gekozen deze wordt actief vanaf ${minStartDateString}.`,
                undefined,
                undefined,
                { ms: 0, fixed: true },
                'warning',
            ),
        );
    }
}

/**
 * Watcher for VEHICLE_LIST_SUCCESS action
 */
export function* watchRequestVendorConfig() {
    yield takeLatest(VENDOR_CONFIG_SUCCESS, vendorConfigSuccess);
}
