import { all, call, put, takeLatest, take, race } from 'redux-saga/effects';
import {
    START_ENROLL,
    finishEnroll,
    promptEnroll,
    RESET_ENROLL,
    FINISH_ENROLL,
} from '../actions/enroll';

import { store } from '../store';
import {
    requestCreateUser,
    REQUEST_CREATE_USER_FAILED,
    REQUEST_CREATE_USER_SUCCESS,
    resetCreateUser,
    clearCreateUserQuery,
} from '../actions/user';
import {
    requestCreateLocation,
    REQUEST_CREATE_LOCATION_FAILED,
    REQUEST_CREATE_USER_LOCATION_SUCCESS,
    REQUEST_CREATE_INVOICE_LOCATION_FAILED,
    resetCreateUserLocation,
    REQUEST_CREATE_INVOICE_LOCATION_SUCCESS,
    resetCreateInvoiceLocation,
    requestCreateInvoiceLocation,
    INVOICE_LOCATION,
    USER_LOCATION,
} from '../actions/userLocation';
import {
    requestCreateCompany,
    REQUEST_CREATE_COMPANY_FAILED,
    REQUEST_CREATE_COMPANY_SUCCESS,
    resetCreateCompany,
} from '../actions/company';
import {
    requestCreateUserPaymentAccount,
    REQUEST_CREATE_USER_PAYMENT_ACCOUNT_FAILED,
    REQUEST_CREATE_USER_PAYMENT_ACCOUNT_SUCCESS,
    resetCreateUserPaymentAccount,
} from '../actions/userPaymentAccount';
import {
    REQUEST_CREATE_USER_SNAPSHOT_FAILED,
    REQUEST_CREATE_USER_SNAPSHOT_SUCCESS,
    requestCreateUserSnapshot,
    requestCreateUserSnapshotFailed,
    resetCreateUserSnapshot,
    requestRerouteUser,
} from '../actions/userSnapshot';
import { routePaths } from '../helpers/routing';

/** @typedef {import('../actions/user').CreateUserPayload} CreateUserPayload */
/** @typedef {import('../actions/userLocation').CreateLocationPayload} CreateLocationPayload */
/** @typedef {import('../actions/company').CreateCompanyPayload} CreateCompanyPayload */
/** @typedef {import('../actions/userPaymentAccount').CreateUserPaymentAccountPayload} CreateUserPaymentAccountPayload */
/** @typedef {import('../actions/userSnapshot').CreateUserSnapshotPayload} CreateUserSnapshotPayload */

/**
 * Watcher for RESET_ENROLL action
 */
export function* watchResetEnroll() {
    yield takeLatest(RESET_ENROLL, function* () {
        yield all([
            put(resetCreateCompany()),
            put(resetCreateUser()),
            put(resetCreateUserPaymentAccount()),
            put(resetCreateUserSnapshot()),
            put(resetCreateUserLocation()),
            put(resetCreateInvoiceLocation()),
        ]);
    });
}

/**
 * Watcher for FINISH_ENROLL action
 */
export function* watchFinishEnroll() {
    yield takeLatest(FINISH_ENROLL, function* () {
        const state = store.getState();
        if (!state.createUserSnapshot?.data?.id) {
            window.displayMessage(
                'Probeer het later nog eens of neem contact op met de helpdesk.',
                'warning',
            );
            return;
        }

        yield put(
            requestRerouteUser(
                `${routePaths.customerOverview}/${state.createUserSnapshot.data.id}`,
            ),
        );
    });
}

/**
 * Create user snapshot task
 * @param {CreateUserSnapshotPayload} snapshot
 */
function* createUserSnapshot(snapshot) {
    const state = store.getState();
    const userLocationID =
        state.createUser &&
        state.createUser.data &&
        state.createUser.data.location &&
        state.createUser.data.location.id
            ? state.createUser.data.location.id
            : null;

    let deliveryLocationID =
        state.createUserLocation &&
        state.createUserLocation.data &&
        state.createUserLocation.data.id
            ? state.createUserLocation.data.id
            : null;

    let invoiceLocationID =
        state.createInvoiceLocation &&
        state.createInvoiceLocation.data &&
        state.createInvoiceLocation.data.id
            ? state.createInvoiceLocation.data.id
            : null;

    if (!invoiceLocationID) {
        const { selectedSnapshot } = state.jwtSnapshots;
        invoiceLocationID = userLocationID;
        // if userLocatinID is not set, get invoice location id from selected snapshot.
        if (invoiceLocationID == null && selectedSnapshot != null) {
            invoiceLocationID = selectedSnapshot.invoice_location.id;
        } else if (invoiceLocationID == null && selectedSnapshot == null) {
            console.warn(
                `Invoice location and selected snapshot doesn't exist`,
            );
        }
    }
    if (!deliveryLocationID) {
        deliveryLocationID = userLocationID;
    }
    if (!deliveryLocationID) {
        put(
            requestCreateUserSnapshotFailed({
                noLocationIdInCreateUserState: state.createUser.data,
            }),
        );
        return;
    }
    const payload = {
        ...snapshot,
        delivery_location_id: deliveryLocationID,
        invoice_location_id: invoiceLocationID,
    };

    // Set company_id when company is not null
    if (
        state.createCompany &&
        state.createCompany.data &&
        state.createCompany.data.id
    ) {
        payload.company_id = state.createCompany.data.id;
    }

    // Set delivery_location_id when location is not null
    if (
        state.createUserLocation &&
        state.createUserLocation.data &&
        state.createUserLocation.data.id
    ) {
        payload.delivery_location_id = state.createUserLocation.data.id;
    }

    // Set invoice_location_id when location is not null
    if (
        state.createInvoiceLocation &&
        state.createInvoiceLocation.data &&
        state.createInvoiceLocation.data.id
    ) {
        payload.invoice_location_id = state.createInvoiceLocation.data.id;
    }

    // Set payment_account_id when paymentAccount is not null
    if (
        state.createUserPaymentAccount &&
        state.createUserPaymentAccount.data &&
        state.createUserPaymentAccount.data.id
    ) {
        payload.payment_account_id = state.createUserPaymentAccount.data.id;
    }

    if (
        payload.invoice_location_id === null ||
        payload.invoice_location_id === undefined
    ) {
        payload.invoice_location_id = payload.delivery_location_id;
    }

    yield put(requestCreateUserSnapshot(payload));
    yield race([
        take(REQUEST_CREATE_USER_SNAPSHOT_FAILED),
        take(REQUEST_CREATE_USER_SNAPSHOT_SUCCESS),
    ]);
}

/**
 * Runs error checks for the middle of the enrollment flow and dispatches a snapshot if everything was ok
 * @param {Object} snapshot
 */
function* checkAndSnapshot(snapshot) {
    const state = store.getState();
    // Halt when any of the following failed
    //  - Create user location            - Create company             - Create user payment account
    if (
        state.createUserLocation.error ||
        state.createCompany.error ||
        state.createUserPaymentAccount.error ||
        state.createInvoiceLocation?.error
    ) {
        yield put(promptEnroll());
        return;
    }

    // Create user snapshot
    // @ts-ignore
    yield call(createUserSnapshot, snapshot);

    // Finish enroll
    yield put(finishEnroll());
}

/**
 * Create user task
 * @param {CreateUserPayload} user
 */
function* createUser(user) {
    yield put(requestCreateUser(user, VENDOR));
    yield race([
        take(REQUEST_CREATE_USER_FAILED),
        take(REQUEST_CREATE_USER_SUCCESS),
    ]);
}

/**
 * Create user location task
 * @param {CreateLocationPayload} location
 */
function* createUserLocation(location) {
    if (location === null) {
        return;
    }
    yield put(requestCreateLocation(location, USER_LOCATION));
    yield race([
        take(REQUEST_CREATE_LOCATION_FAILED),
        take(REQUEST_CREATE_USER_LOCATION_SUCCESS),
    ]);
}

/**
 * Create invoice location task
 * @param {CreateLocationPayload} location
 */
function* createInvoiceLocation(location) {
    if (location === null) {
        return;
    }
    yield put(requestCreateInvoiceLocation(location, INVOICE_LOCATION));
    yield race([
        take(REQUEST_CREATE_INVOICE_LOCATION_FAILED),
        take(REQUEST_CREATE_INVOICE_LOCATION_SUCCESS),
    ]);
}

/**
 * Create company task
 * @param {CreateCompanyPayload} company
 */
function* createCompany(company) {
    if (company === null) {
        return;
    }
    yield put(requestCreateCompany(company));
    yield race([
        take(REQUEST_CREATE_COMPANY_FAILED),
        take(REQUEST_CREATE_COMPANY_SUCCESS),
    ]);
}

/**
 * Create user payment account task
 * @param {CreateUserPaymentAccountPayload} paymentAccount
 */
function* createUserPaymentAccount(paymentAccount) {
    if (paymentAccount === null) {
        return;
    }
    yield put(requestCreateUserPaymentAccount(paymentAccount));
    yield race([
        take(REQUEST_CREATE_USER_PAYMENT_ACCOUNT_FAILED),
        take(REQUEST_CREATE_USER_PAYMENT_ACCOUNT_SUCCESS),
    ]);
}

/**
 * Resumes enrollment flow
 * @param {Object} action
 */
function* doResumeEnroll(action) {
    const state = store.getState();
    if (state.createUserLocation.error) {
        yield call(createUserLocation, action.deliveryLocation);
    }
    if (state.createInvoiceLocation.error) {
        yield call(createInvoiceLocation, action.invoiceLocation);
    }
    if (state.createCompany.error) {
        yield call(createCompany, action.company);
    }
    if (state.createUserPaymentAccount.error) {
        yield call(createUserPaymentAccount, action.paymentAccount);
    }

    yield call(checkAndSnapshot, action.snapshot);
}

/**
 * Runs enrollment flow
 * @param {Object} action
 */
function* doEnroll(action) {
    let state = store.getState();

    // Check if user already created
    if (
        (action.user === null ||
            (state.createUser &&
                !state.createUser.error &&
                state.createUser.data)) &&
        (state.createUserLocation.error ||
            state.createCompany.error ||
            state.createUserPaymentAccount.error ||
            state.createInvoiceLocation.error)
    ) {
        yield call(doResumeEnroll, action);
        return;
    }

    if (action.user) {
        // Create user
        yield call(createUser, action.user);

        state = store.getState();
        // Failed creating user
        if (
            state.createUser.error ||
            !state.createUser.data ||
            !state.createUser.data.id
        ) {
            yield put(promptEnroll());
            return;
        }

        // Hide submitted query (removes passwords from local storage)
        yield put(clearCreateUserQuery());
    }

    const userActions = [
        call(createCompany, action.company),
        call(createUserPaymentAccount, action.paymentAccount),
        call(createUserLocation, action.deliveryLocation),
    ];
    yield all(userActions);

    if (action.invoiceLocation && action.invoiceLocation.area_code) {
        yield call(createInvoiceLocation, action.invoiceLocation);
    }

    yield call(checkAndSnapshot, action.snapshot);
}

/**
 * Watcher for START_ENROLL action
 */
export function* watchStartEnroll() {
    yield takeLatest(START_ENROLL, doEnroll);
}
