import { customElement } from 'lit-element';

import { LionRadioGroup } from '@lion/radio-group';

@customElement('hv-radio-group')
export class HvRadioGroup extends LionRadioGroup {
    static get styles() {
        return [...super.styles];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        'hv-radio-group': HvRadioGroup;
    }
}
