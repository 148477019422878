import {
    LitElement,
    property,
    TemplateResult,
    customElement,
    html,
    css,
} from 'lit-element';
import { connect } from 'lit-redux-watch';
import { whitelabel } from '@weavelab/whitelabel';

@customElement('hv-info-box')
@whitelabel({ name: 'hv-info-box' })
export class HvInfoBox extends connect(window.store)(LitElement) {
    @property({ type: String })
    title: string = '';

    @property({ type: String })
    body: string = '';

    @whitelabel() // Allow styles to be injected from theme
    static get styles() {
        return [
            css`
                :host {
                    --container--background-color: #f5f5f5;
                    --container--color: var(--warm-grey);
                    --container--font-size: 13px;
                    --container--margin-top: 20px;
                    --container--padding: 2px 15px;
                    --container--border-radius: 10px;
                }
                #container {
                    margin-top: var(--container--margin-top);
                    color: var(--container--color);
                    display: flex;
                    flex-direction: column;
                    background-color: var(--container--background-color);
                    padding: var(--container--padding);
                    border-radius: var(--container--border-radius);
                    font-size: var(--container--font-size);
                }
                #container__title {
                    font-style: italic;
                    display: flex;
                    align-items: center;
                    margin-bottom: 0;
                }
                #container__body__attention {
                    font-weight: bold;
                }

                #container__body ::slotted(ul),
                #container__body ::slotted(ol) {
                    padding-inline-start: 20px;
                }
            `,
        ];
    }

    public render = (): TemplateResult => html`
        <div id="container">
            <h3 id="container__title">${this.title}</h3>
            <p id="container__body">${this.body}<slot></slot></p>
        </div>
    `;
}

declare global {
    interface HTMLElementTagNameMap {
        'hv-info-box': HvInfoBox;
    }
}
