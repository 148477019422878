// accepts any class which has a busy property set.
// use after dispatch to make function asyncable
export const asyncReducerResolver = <SomeClass extends { busy: boolean }>(
    someClass: SomeClass,
): Promise<boolean> =>
    new Promise<boolean>(async (res) => {
        while (someClass.busy) {
            await new Promise((res) => setTimeout(() => res(true), 200));
        }
        res(true);
    });
