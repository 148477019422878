import { css } from 'lit-element';

export default css`
    :host() {
        visibility: visible;
        height: auto;
    }
    :host {
        --button-component--padding__left: 10px;
        --button-component--padding: 12px;
        --button-component--width: 100%;
        --button-component_small--width: 400px;
        --button-component--background: var(--primary-color);
        --button-component--border: 1px solid var(--primary-color);
        --button-component--border-radius: 4px;
        --button-component__secondary--color: var(--tertiary-color);
        --button-component__secondary--border: 1px solid
            var(--button-border-color);
        --button-component--font-size: 18px;
        --button-component--font-family: inherit;
        --button-component--font-color: var(--app-white);
        --button-component--flex-grow: 1;
        --button-component--inner-margin__left: 15px;
        --button-component--inner-padding__left: 12px;
        --button-component--inner-padding__right: inherit;
        --button-component--line-height: 1.78;
        --button-component--margin: 0 auto;
        --button-component--height: auto;
        --button-component--font-weight: 900;
        --button-component_small--padding: 8px;
        --button-component--align-item: center;

        justify-content: center;
        align-items: center;
        margin: var(--button-component--margin);
        width: var(--button-component--width);
        visibility: visible;
        height: var(--button-component--height);
        opacity: 1;
        transition: opacity 500ms linear;
        font-family: var(--button-component--font-family);
    }

    :host([hidden]) {
        visibility: hidden;
        height: 0 !important;
        margin: 0 !important;
        opacity: 0;
        transition: visibility 0s 200ms, opacity 200ms linear;
    }
    :host([disabled]) {
        cursor: not-allowed;
        pointer-events: none;
        opacity: 0.5;
    }

    button:visited,
    button:default {
        transition-property: all;
        transition-duration: 300ms;
        transition-timing-function: ease-out;
    }

    button:active,
    button:hover {
        transition-property: all;
        transition-duration: 300ms;
        transition-timing-function: ease-in;
    }

    button[theme='primary'] span {
        color: var(--button-component--font-color);
    }

    button[theme='primary'] {
        background: var(--button-component--background);
        color: var(--button-component--font-color);
        border: none;
    }

    button[theme='secondary'] {
        background: none;
        color: var(--button-component__secondary--color);
        border: var(--button-component__secondary--border);
    }

    button[theme='text-and-arrow'] {
        background: none;
        color: var(--button-component__secondary--color);
        border: var(--button-component__secondary--border);
    }

    button[theme='text'] {
        background: none;
        text-decoration: underline;
        color: var(--button-component__secondary--color);
        border: none;
    }

    button {
        display: flex;
        flex-grow: var(--button-component--flex-grow);
        padding: var(--button-component--padding);
        border: var(--button-component--border);
        margin: 24px 0 0;
        text-transform: unset;
        border-radius: var(--button-component--border-radius);
        box-sizing: border-box;
        cursor: pointer;
        outline: none;
        background: none;
        align-items: var(--button-component--align-item);
        transition: var(--transition-ease);
        user-select: none;
        margin-left: 0;
        margin-top: 0;
        height: var(--button-component--height);
    }

    button span {
        display: flex;
        flex-grow: var(--button-component--flex-grow);
        align-items: center;
        justify-content: center;
        font-family: var(--primary-font-family);
        font-size: var(--button-component--font-size);
        font-weight: var(--button-component--font-weight);
        font-stretch: normal;
        font-style: normal;
        line-height: var(--button-component--line-height);
        letter-spacing: -0.25px;
    }

    button[theme='text-and-arrow'] span {
        color: var(--tertiary-color);
        justify-content: left;
        margin-left: var(--button-component--padding__left);
    }

    button[theme='text-and-arrow'] .arrow-icon {
        color: var(--tertiary-color);
        font-weight: 700;
        transform: scaleY(1.5);
        font-size: 18px;
        font-stretch: condensed;
        margin-right: var(--button-component--padding__left);
    }

    button .inner {
        padding-left: var(--button-component--inner-padding__left);
        padding-right: var(--button-component--inner-padding__right);
    }

    button .inner:empty {
        padding-left: 0;
    }

    button ::slotted() {
        margin-left: var(--button-component--inner-margin__left);
    }

    /* ripple logic */
    .ripple {
        position: relative;
        overflow: hidden;
        transform: translate3d(0, 0, 0);
    }

    button[size='small'] {
        padding: var(--button-component_small--padding);
        width: var(--button-component_small--width);
        max-width: 100%;
        min-height: 40px;
    }

    button[size='small'] span {
        font-size: var(--font-size);
        font-weight: 700;
    }

    button[verticalSpacing='default'] {
        margin: 30px 0;
    }

    button[verticalSpacing='big-top'] {
        margin: 40px 0 30px 0;
    }

    button[verticalSpacing='minimal'] {
        margin: 10px 0;
    }

    /* Mobile */
    @media only screen and (max-width: 822px) {
        button[size='small'] {
            padding: 8px;
            width: 100%;
        }
    }
`;
