type personalDataComponents = 'je-bevestiging' | 'aanvraag-afgerond';

export const userInteractionEvent = 'user_interaction';

type eventTypes = 'button' | 'select' | 'link' | 'form' | 'checkbox' | 'popup';
type eventActionTypes = 'change' | 'click' | 'select';
type eventActionValueTypes = 'valid' | 'invalid' | String;

export type OrderOverviewFields =
    | 'mijn-contract-bevestigen'
    | 'aanvraag-afgerond'
    | 'ja-ik-ga-akkoord'
    | 'nee-ik-ga-niet-akkoord'
    | 'bekijk-klantomgeving'
    | 'lees-algemene-voorwaarden'
    | 'bestelling-wijzigen'
    | 'wijzigen'
    | 'algemene-voorwaarden'
    | 'slimme-meter-voorwaarden'
    | 'vendor-voorwaarden'
    | 'leverings-voorwaarden'
    | 'prijsvoorstel-op-door-mij-gegeven-gebruik';

export interface OrderOverviewEventType {
    event: typeof userInteractionEvent;
    type: eventTypes;
    field: OrderOverviewFields;
    component: personalDataComponents;
    action: eventActionTypes;
    actionValue: eventActionValueTypes;
}

export const newOrderOverviewEvent = (
    component: personalDataComponents,
    type: eventTypes,
    field: OrderOverviewFields,
    action: eventActionTypes,
    actionValue: eventActionValueTypes = '',
): OrderOverviewEventType => ({
    event: userInteractionEvent,
    type,
    component,
    field,
    action,
    actionValue,
});

export const personalDataInputChanged = (
    OrderOverviewField: OrderOverviewFields,
    action: eventActionTypes,
): OrderOverviewEventType =>
    newOrderOverviewEvent(
        'je-bevestiging',
        'button',
        OrderOverviewField,
        action,
        '',
    );

export const readTermsAndConditions = (): OrderOverviewEventType =>
    newOrderOverviewEvent(
        'je-bevestiging',
        'link',
        'lees-algemene-voorwaarden',
        'click',
        'valid',
    );

export const conditionsChanged = (
    actionValue: eventActionValueTypes,
    field: OrderOverviewFields,
): OrderOverviewEventType =>
    newOrderOverviewEvent(
        'je-bevestiging',
        'checkbox',
        field,
        'click',
        actionValue,
    );

export const changeOrder = (
    field: 'bestelling-wijzigen' | 'wijzigen',
): OrderOverviewEventType =>
    newOrderOverviewEvent('je-bevestiging', 'link', field, 'click', 'valid');

// Purchase Items
export interface PurchaseItems {
    affiliation: string;
    currency: string;
    index: number;
    item_brand: string;
    item_category: string;
    item_id: string;
    item_name: string;
    item_variant: string;
    price: number;
    quantity: number;
}
export interface Purchase {
    affiliation: string;
    currency: string;
    items: PurchaseItems[];
    tax: number;
    transaction_id: string;
    value: number;
}

interface PurchaseEventType {
    event: string;
    ecommerce: Purchase;
}

export const purchaseEvent = (event: Purchase): PurchaseEventType => {
    event.affiliation = 'energie';
    event.currency = 'EUR';
    if (event.items == null) {
        console.error('purchase event items are missing');
    } else {
        event.items.forEach((item, index) => {
            item.affiliation = 'energie';
            item.currency = 'EUR';
            item.index = index;
            item.item_category = 'energie';
            item.quantity = 1;
        });
    }
    return {
        event: 'purchase',
        ecommerce: {
            ...event,
        },
    };
};

export const purchaseEventConfirmation = (
    actionValue: string,
): OrderOverviewEventType =>
    newOrderOverviewEvent(
        'je-bevestiging',
        'button',
        'mijn-contract-bevestigen',
        'click',
        actionValue,
    );

export const uiProceedToMoClickedDataLayer = (): OrderOverviewEventType =>
    newOrderOverviewEvent(
        'aanvraag-afgerond',
        'button',
        'bekijk-klantomgeving',
        'click',
        'valid',
    );

export const uiUsageCheckAgreeClickedDataLayer = (): OrderOverviewEventType =>
    newOrderOverviewEvent(
        'je-bevestiging',
        'button',
        'ja-ik-ga-akkoord',
        'click',
        'valid',
    );

export const uiUsageSupportClickedDataLayer = (
    actionValue: string,
): OrderOverviewEventType =>
    newOrderOverviewEvent(
        'je-bevestiging',
        'popup',
        'prijsvoorstel-op-door-mij-gegeven-gebruik',
        'click',
        actionValue,
    );

export const uiUsageCheckDisagreeClickedDataLayer =
    (): OrderOverviewEventType =>
        newOrderOverviewEvent(
            'je-bevestiging',
            'button',
            'nee-ik-ga-niet-akkoord',
            'click',
            'valid',
        );
