import { css } from 'lit-element';

export default css`
    :host {
        --banner-height: 244px;
        --mobile-banner-height: 150px;

        width: 100vw;
        position: relative;
        transition: height 300ms ease-in-out;
    }

    /**
    * video banner logic
    */
    .bannerVideoWrapper {
        overflow: hidden;
        background-repeat: no-repeat;
        background-position: center top;
        background-size: cover;
        display: block;
        width: inherit;
        height: var(--banner-height);
        max-height: var(--banner-height);
        z-index: 50;
        transition: all 300ms ease-in-out;
        margin-bottom: 20px;
    }

    .banner__title__wrapper {
        top: 0;
        left: 0;
        width: 100vw;
        background-color: rgba(0, 0, 0, 0.75);
        display: flex;
        align-items: center;
        height: var(--banner-height);
        z-index: 100;
        transition: font-size 300ms ease-in-out;
    }

    .bannerAddress {
        margin: 0 auto;
        font-family: var(--primary-font-family);
        font-size: 48px;
        font-weight: 900;
        font-style: normal;
        line-height: 1.08;
        font-stretch: normal;
        color: var(--ice-blue);
        z-index: 6;
        width: 795px;
        transition: font-size 300ms ease-in-out;
    }

    .bannerAddress small {
        color: var(--white);
        display: inline;
        font-size: 20px;
        font-weight: 400;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.35;
        letter-spacing: -0.5px;
        z-index: 6;
        transition: font-size 300ms ease-in-out;
    }

    .bannerAddress span {
        font-weight: 800;
    }

    video {
        max-width: 100vw;
    }

    /* Mobile */
    @media only screen and (max-width: 822px) {
        .bannerVideoWrapper {
            max-height: var(--banner-height);
            height: var(--mobile-banner-height);
        }

        .bannerVideoWrapper,
        .banner__title__wrapper {
            height: var(--mobile-banner-height);
        }

        .bannerAddress {
            padding: 0 20px;
            font-size: 24px;
            letter-spacing: -1.5px;
            line-height: 1.07;
            width: auto;
        }

        .bannerAddress small {
            display: inline;
            font-size: 14px;
            line-height: 1;
        }

        .bannerAddress span {
            display: inline-block;
        }
    }
`;
