import { customElement, html, query } from 'lit-element';
import { MaxLength, MinLength, Required, Validator } from '@lion/form-core';
import { whitelabel } from '@weavelab/whitelabel';
import { changePassword } from '@async-reducers/user';
import { Form } from './Form';
import { ContainsUpper } from './validators/ContainsUpper';
import { ContainsLower } from './validators/ContainsLower';
import { ContainsDigit } from './validators/ContainsDigit';
import { ContainsSpecialCharacter } from './validators/ContainsSpecialCharacter';
import { Equals } from './validators/Equals';
import '../components/elements/password-validations/password-validation/PasswordValidation';
import './change-password-helpers/helperText';

@customElement('ice-change-password')
@whitelabel({ name: 'ice-change-password' })
export class ChangePassword extends Form {
    @whitelabel()
    public static showLabel?: boolean;

    @whitelabel()
    public static styleObject?: Partial<CSSStyleDeclaration>;

    @whitelabel()
    public static placeholder?: Partial<{
        old: string | boolean;
        password: string | boolean;
        repeat: string | boolean;
    }>;

    @whitelabel()
    public static label?: Partial<{
        old: string;
        password: string;
        repeat: string;
        submit: string;
    }>;

    @query('hv-input[name="password"]')
    private passwordInput?: HTMLElementTagNameMap['hv-input'];

    public render() {
        return html`
            <div>
                <password-validation
                    validation-text="minimaal 12 tekens"
                    ?is-valid=${this.checkValidator(MinLength)}
                ></password-validation>
                <password-validation
                    validation-text="minstens 1 hoofdletter"
                    ?is-valid=${this.checkValidator(ContainsUpper)}
                ></password-validation>
                <password-validation
                    validation-text="minstens 1 kleine letter"
                    ?is-valid=${this.checkValidator(ContainsLower)}
                ></password-validation>
                <password-validation
                    validation-text="minstens 1 cijfer"
                    ?is-valid=${this.checkValidator(ContainsDigit)}
                ></password-validation>
                <password-validation
                    validation-text="minstens 1 special teken (!@#$%^?)"
                    ?is-valid=${this.checkValidator(ContainsDigit)}
                ></password-validation>
            </div>
            <ice-change-password-helper-text></ice-change-password-helper-text>
            ${super.render()}
        `;
    }

    public connectedCallback(): void {
        super.connectedCallback();

        this.style.width = '100%';
        this.style.paddingBottom = '28px';

        Object.keys(ChangePassword.styleObject ?? {}).forEach((k: string) => {
            // @ts-ignore
            this.style[k] = ChangePassword.styleObject[k];
        });
    }

    private listener = (): void => {
        this.requestUpdate(); // Trigger update
    };

    protected checkValidator(validator: typeof Validator): boolean {
        // Ensure input field exists
        if (!this.passwordInput) {
            return false;
        }

        // Check if any input exists
        if (this.passwordInput.validationStates.error.Required) {
            return false;
        }

        // Read validator
        return !this.passwordInput.validationStates.error[
            validator.validatorName
        ];
    }

    protected form = [
        Form.field(
            'current-password',
            'old',
            ChangePassword.label?.old ?? 'Huidig wachtwoord',
            {
                label: ChangePassword.showLabel ?? false,
                placeholder: ChangePassword.placeholder?.old ?? true,
                validators: [
                    new MinLength(1, {
                        getMessage: async () => `Vul het oude wachtwoord in`,
                    }),
                    new Required(null, {
                        getMessage: async () => `Verplicht veld`,
                    }),
                ],
            },
        ),
        Form.field(
            'new-password',
            'password',
            ChangePassword.label?.password ?? 'Nieuwe wachtwoord',
            {
                label: ChangePassword.showLabel ?? false,
                listener: this.listener,
                placeholder: ChangePassword.placeholder?.password ?? true,
                validators: [
                    new MaxLength(512, {
                        getMessage: async () =>
                            `Het wachtwoord mag niet langer zijn dan 512 tekens`,
                    }),
                    new MinLength(12, {
                        getMessage: async () =>
                            `Het wachtwoord mag niet korter zijn dan 12 tekens`,
                    }),
                    new ContainsUpper(null, {
                        getMessage: async () =>
                            `Het wachtwoord moet minstens 1 hoofdletter bevatten`,
                    }),
                    new ContainsLower(null, {
                        getMessage: async () =>
                            `Het wachtwoord moet minstens 1 kleine letter bevatten`,
                    }),
                    new ContainsDigit(null, {
                        getMessage: async () =>
                            `Het wachtwoord moet minstens 1 cijfer bevatten`,
                    }),
                    new ContainsSpecialCharacter(null, {
                        getMessage: async () =>
                            `Het wachtwoord moet minstens 1 speciaal teken bevatten`,
                    }),
                    new Required(null, {
                        getMessage: async () => `Verplicht veld`,
                    }),
                ],
            },
        ),
        Form.field(
            'new-password',
            'repeat',
            ChangePassword.label?.repeat ?? 'Herhaal wachtwoord',
            {
                label: ChangePassword.showLabel ?? false,
                placeholder: ChangePassword.placeholder?.repeat ?? true,
                validators: [
                    new Equals(() => this.values.password, {
                        getMessage: async () =>
                            `De wachtwoorden moeten gelijk zijn`,
                    }),
                    new Required(null, {
                        getMessage: async () => `Verplicht veld`,
                    }),
                ],
            },
        ),
        Form.button(
            'submit',
            ChangePassword.label?.submit ?? 'Wachtwoord opslaan',
        ),
    ];

    // eslint-disable-next-line class-methods-use-this
    protected async submit(values: {
        old: string;
        password: string;
    }): Promise<void> {
        window.store.dispatch(changePassword.run(values.old, values.password));
    }
}

declare global {
    interface HTMLElementTagNameMap {
        'ice-change-password': ChangePassword;
    }
}
