import { customElement, property } from 'lit-element';

import '@templates/oda-onboarding-step-wrapper/OdaOnboardingStepWrapper';
import '@organism/radio/hvRadioComponent';

import '@components/molecules/InfoButton';
import { connect, watch } from 'lit-redux-watch';
import { WeaveStep } from '@components/organism/stepper/WeaveStep';
import { css, html, CSSResult } from '@lion/core';
import '@components/atoms/HvInputCode';
import { radioValuechangedEvent } from '@organism/radio/hvRadioComponent';
import { OdaOnboardingState } from '@async-reducers/oda';
import { setOdaOnboarding } from '@async-reducers/oda';

@customElement('has-gas-step')
export class HasGasStep extends connect(window.store)(WeaveStep) {
    @property({ type: Boolean })
    private hasChosen: boolean = false;

    @property({ type: Boolean })
    private hasGas?: boolean;

    @watch('setOdaOnboarding.data')
    private odaOnboardingData?: OdaOnboardingState;

    validate = async (): Promise<boolean> => {
        if (this.hasGas !== undefined) {
            window.displayMessage(
                `${
                    this.hasGas
                        ? 'Je hebt het gasinzicht toegevoegd.'
                        : 'Je hebt alleen stroominzicht toegevoegd.'
                }`,
                'succes',
            );
        }
        return this.hasChosen;
    };

    dispatch = (hasChosen: boolean, hasGas: boolean) => {
        if (!this.odaOnboardingData) {
            console.warn('no oda onboarding data');
            return;
        }
        this.hasChosen = hasChosen;
        window.store.dispatch(
            setOdaOnboarding.run({
                ...this.odaOnboardingData,
                hasGas,
            }),
        );
    };

    setSelected = (e: radioValuechangedEvent) => {
        switch (e.detail.value) {
            case 'true':
                this.dispatch(true, true);
                this.hasGas = true;
                break;
            case 'false':
                this.dispatch(true, false);
                this.hasGas = false;
                break;
            case 'none':
                this.dispatch(true, false);
                this.hasGas = undefined;
                break;
            default:
                this.hasChosen = false;
        }
    };

    render = () => {
        return html`
            <oda-onboarding-step-wrapper
                nextText="Keuze toevoegen"
                ?disable-navigation=${!this.hasChosen}
            >
                <main slot="title">Kies ook voor inzicht van gas</main>
                <span slot="introduction"
                    >We gaan per uur je stroomverbruik tonen. Het is ook goed om
                    te zien wat je verbruikt aan gas, omdat dit vaak de grootste
                    invloed heeft op je energierekening. Inzicht hierin is de
                    allereerste stap naar besparing.</span
                >
                <div class="content">
                    <div class="title">
                        <h4>Wil je ook gasinzicht in de app?</h4>
                    </div>
                    <hv-radio-component
                        name="has gas"
                        @value=${this.setSelected}
                        .options="${[
                            {
                                label: 'Ja, ik wil ook gaan besparen op gas.',
                                value: 'true',
                                recommend: true,
                            },
                            {
                                label: 'Nee, ik wil alleen stroominzicht.',
                                value: 'false',
                            },
                            {
                                label: 'Ik heb geen gasaansluiting',
                                value: 'none',
                            },
                        ]}"
                    >
                    </hv-radio-component>
                </div>
            </oda-onboarding-step-wrapper>
        `;
    };

    static get styles(): CSSResult[] {
        return [
            ...super.styles,
            css`
                :host {
                    --oda-onboarding-content-padding: 20px;
                    --oda-onboarding-terms-border: 1px solid #e5e5e5;
                    --oda-onboarding-terms-padding: 20px 20px;
                    --oda-onboarding-checkbox-font-size: 13px;
                    --oda-onboarding-checkbox-font-weight: 600;
                    --oda-onboarding-checkbox-padding: 10px 0 15px;
                    --oda-onboarding-checkbox-margin: 0;
                    --oda-onboarding-checkbox-color: #77777;
                }
                .content {
                    border: var(--oda-onboarding-terms-border);
                    padding: 0 15px 15px;
                }
                hv-info-button {
                    padding-top: 5px;
                }
                hv-input-code::slotted(label) {
                    font-size: 10px;
                }
                .title {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                }
                .terms {
                    display: flex;
                    flex-direction: column;
                    border: var(--oda-onboarding-terms-border);
                    padding: var(--oda-onboarding-terms-padding);
                }
                .buttons {
                    display: flex;
                    margin-top: 7px;
                }
                .buttons a {
                    background-color: rgba(0, 0, 0, 0.06);
                    font-weight: 600;
                    padding: 5px 10px;
                    text-decoration: none;
                    color: #000;
                }
                .buttons a:first-child {
                    margin-right: 5px;
                }
                .checkbox-helper {
                    font-size: var(--oda-onboarding-checkbox-font-size);
                    color: var(--oda-onboarding-checkbox-color);
                    font-weight: var(--oda-onboarding-checkbox-font-weight);
                    padding: var(--oda-onboarding-checkbox-padding);
                    margin: var(--oda-onboarding-checkbox-margin);
                }
                .checkbox-text {
                    font-size: var(--oda-onboarding-checkbox-font-size);
                    margin: var(--oda-onboarding-checkbox-margin);
                }
                .term {
                    text-decoration: underline;
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        'has-gas-step': HasGasStep;
    }
}
