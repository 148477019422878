import { Router } from '@vaadin/router';
import { routePaths } from 'data-access/router';
import { SuccessAction } from 'goagen-js-resource';
import { OdaOnboardingState, setOdaOnboarding } from './../async-reducers/oda';
import { ForkEffect, takeLatest } from 'redux-saga/effects';

interface odaOnboarding extends SuccessAction {
    data: OdaOnboardingState;
}

const setOdaNotification: (result: odaOnboarding) => void = (
    result: odaOnboarding,
): void => {
    const { skipped } = result.data;
    let loc = location.pathname.split('/').pop();

    if (!skipped) {
        if (loc !== routePaths.oda.split('/').pop()) {
            Router.go(routePaths.dashboard);
        }
        return;
    }

    // check if current location equals oda then redirect to the dashboard page
    if (loc === routePaths.oda.split('/').pop()) {
        Router.go(routePaths.dashboard);
    }
};

/**
 * Watcher for all oda actions
 */
export function* watchOda(): IterableIterator<ForkEffect> {
    yield takeLatest([setOdaOnboarding.actionTypeMap.save], setOdaNotification);
}
