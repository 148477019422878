import {
    customElement,
    html,
    property,
    LitElement,
    TemplateResult,
    PropertyValues,
    query,
    CSSResult,
} from 'lit-element';
import { AxiosError, AxiosResponse } from 'axios';
import { ifDefined } from 'lit-html/directives/if-defined';
import { when } from 'lit/directives/when';
import { whitelabel } from '@weavelab/whitelabel';
import { connect, watch } from 'lit-redux-watch';
import { Snapshot } from 'types/snapshot';
import { currency } from 'helpers/currency';
import '@atoms/HvFaq';
import { OfferRecalculateSnapshotRequestObject } from '../../resources/OfferRecalculateSnapshot';
import style from './style';
import '../../components/elements/base/input-component/InputComponent';
import '../../components/elements/base/button/ButtonComponent';
import '../../components/elements/tooltip/Tooltip';
import './components/recalculateModal/index';
import './components/invalidRecalculateModal/index';
import { offerRecalculation } from '../../store';
import { messageTypes } from '../../index';
import {
    recalculationModalOpener,
    recalculationModalErrorOpener,
} from '../../core/store/snapshot/sagas/offerRecalculation';
import { _defaults } from '../../components/helpers/_defaults';

const defaultTooltipText =
    'Hieronder kan je het maandbedrag aanpassen door een hoger of lager energieverbruik per jaar in te vullen. Verandert je situatie wel vaker? Je kan hier steeds weer opnieuw terecht om je maandbedrag bij te stellen.';

@customElement('settings-edit-monthly-payment')
@whitelabel({ name: 'settings-edit-monthly-payment' })
export class MonthlyPayment extends connect(window.store)(LitElement) {
    @watch('jwtSnapshots.selectedSnapshot')
    protected snapshot?: Snapshot;

    @watch('offerRecalculation.data.price')
    public newOfferPrice?: number;

    @watch('offerRecalculation.error')
    public newOfferError?: AxiosResponse;

    @watch('offerRecalculation.busy')
    public newOfferPriceLoading: boolean = false;

    @watch('acceptOfferRecalculation.busy')
    public acceptOfferRecalculationBusy: boolean = true;

    @watch('acceptOfferRecalculation.error')
    public acceptOfferRecalculationError?: AxiosError;

    @watch('requestInvalidOfferRecalculation.busy')
    public requestInvalidOfferBusy: boolean = true;

    @watch('requestInvalidOfferRecalculation.error')
    public requestInvalidOfferError?: AxiosError;

    @watch('recalculationModalOpener.data')
    public openSuccesModal: boolean = false;

    @watch('recalculationModalErrorOpener.data.opened')
    public openErrorModal: boolean = false;

    @watch('recalculationModalErrorOpener.data.price')
    public errorModalPrice?: number;

    @property({ type: String })
    public snapshotID: string = '';

    @whitelabel()
    public static showIntroduction: boolean = false;

    @property({ type: Boolean })
    public recalculateIntroduction: boolean = false;

    @property({ type: String })
    private monthlyPricing?: string;

    @property({ type: String })
    public tooltipText: string = defaultTooltipText;

    @property({ type: Number })
    private currentElecUsage: number = 0;

    @property({ type: Number })
    private currentGasUsage: number = 0;

    @property({ type: Number })
    private currentProduction: number = 0;

    @property({ type: Number })
    private newElecHighUsage: number = 0;

    @property({ type: Number })
    private newElecLowUsage: number = 0;

    @property({ type: Number })
    private newGasUsage: number = 0;

    @property({ type: Number })
    private newProduction: number = 0;

    @query('recalculate-modal')
    public approveModal?: HTMLElementTagNameMap['recalculate-modal'];

    @query('invalid-recalculate-modal')
    public invalidApproveModal?: HTMLElementTagNameMap['invalid-recalculate-modal'];

    @whitelabel()
    public static faq: [] = [];

    @whitelabel()
    public static monthlyIntroduction: string =
        'Vul hieronder je nieuwe energieverbruik per jaar in.';

    @property({ type: String })
    public buttonLabel: string = 'Herbereken maandbedrag';

    @whitelabel() // Allow styles to be injected from theme
    static get styles() {
        return [_defaults as CSSResult, style];
    }

    constructor() {
        super();
        // overwrite a color in the colors for a vendor theme
        const { showIntroduction } = MonthlyPayment;
        if (showIntroduction != null) {
            this.recalculateIntroduction = showIntroduction;
        }
    }

    private setMonthlyPricing() {
        if (this?.snapshot?.calculated_product_group_verified) {
            this.monthlyPricing = currency(
                this.snapshot.calculated_product_group_verified.monthly_pricing,
            );
        }
    }

    private setContractDetails() {
        const snapshotPayload = this?.snapshot?.verified_snapshot_payload;
        this.snapshotID = this.snapshot!.id;
        if (snapshotPayload?.has_elec) {
            this.newElecHighUsage = Math.round(
                snapshotPayload.elec_usage_amount *
                    snapshotPayload.high_low_distribution,
            );
            this.currentElecUsage = snapshotPayload.elec_usage_amount;
            this.newElecLowUsage = Math.round(
                this.currentElecUsage - this.newElecHighUsage,
            );
        }
        if (snapshotPayload?.has_gas) {
            this.newGasUsage = snapshotPayload.gas_usage_amount;
            this.currentGasUsage = snapshotPayload.gas_usage_amount;
        }
        if (snapshotPayload?.elec_prod_amount) {
            this.newProduction = snapshotPayload.elec_prod_amount;
            this.currentProduction = snapshotPayload.elec_prod_amount;
        }
    }

    private elecPresentWhenProdIs(): boolean {
        if (
            this.newProduction &&
            this.newProduction > 0 &&
            this.newElecHighUsage === 0
        ) {
            return false;
        }
        return true;
    }

    protected updated(changedProperties: PropertyValues) {
        if (
            changedProperties.has('snapshot') &&
            this.monthlyPricing === undefined
        ) {
            this.setMonthlyPricing();
            this.setContractDetails();
        }
    }

    setHighElecUsage = (e: CustomEvent): void => {
        this.newElecHighUsage = parseInt(e.detail!.value, 10);
    };

    setLowElecUsage = (e: CustomEvent): void => {
        this.newElecLowUsage = parseInt(e.detail!.value, 10);
    };

    setGasUsage = (e: CustomEvent): void => {
        this.newGasUsage = parseInt(e.detail!.value, 10);
    };

    validateUsages = (): boolean => {
        if (
            Number.isNaN(this.newElecHighUsage) ||
            Number.isNaN(this.newElecLowUsage) ||
            Number.isNaN(this.newGasUsage)
        ) {
            return false;
        }
        return true;
    };

    setReturn = (e: CustomEvent): void => {
        this.newProduction = parseInt(e.detail!.value, 10);
        if (
            this.newProduction === 0 &&
            !this.snapshot?.verified_snapshot_payload.has_elec
        ) {
            this.newElecHighUsage = 0;
            this.newElecLowUsage = 0;
        }
    };

    hasElec = (): boolean =>
        !!(
            this.newProduction > 0 ||
            this.snapshot?.verified_snapshot_payload.has_elec
        );

    hasGas = (): boolean => !!this.snapshot?.verified_snapshot_payload.has_gas;

    recalculateMonthly = async () => {
        if (!this.elecPresentWhenProdIs()) {
            window.displayMessage(
                'Vul het geschatte stroomverbruik per jaar in.',
                messageTypes.warning,
            );
            return;
        }

        if (!this.validateUsages()) {
            window.displayMessage(
                'Vul je nieuwe waarden in.',
                messageTypes.warning,
            );
            return;
        }

        const data: OfferRecalculateSnapshotRequestObject = {
            snapshotId: this.snapshotID,
        };
        const body = {
            data: {
                normal_usage: this.newElecHighUsage,
                low_usage: this.newElecLowUsage,
                gas_usage: this.newGasUsage,
                production: this.newProduction,
            } as {
                normal_usage: number;
                low_usage: number;
                gas_usage: number;
                production: number;
                calculation_date: string | null;
            },
        };
        // FIXME https://energyzero.atlassian.net/browse/EN-2320 should be removed next year
        const today = new Date();
        const firstDayOfNextYear = new Date('2023-01-01');
        if (today < firstDayOfNextYear) {
            body.data.calculation_date = firstDayOfNextYear.toISOString();
        }
        window.store.dispatch(offerRecalculation.request(data, body));
    };

    public render = (): TemplateResult => html`
        <header>
            <div id="monthly-pricing__header">
                <h1>
                    ${when(
                        this.recalculateIntroduction,
                        () => html`Mijn maandbedrag`,
                        () => html`Wijzig mijn maandbedrag`,
                    )}
                </h1>
                <tool-tip value="${this.tooltipText}"></tool-tip>
            </div>
            <p>
                Je maandbedrag is momenteel
                <span class="monthly-pricing total">${this.monthlyPricing}</span
                >.<br />
                Dit bedrag is gebaseerd op een jaarverbruik van
                <span class="total"
                    >${this.currentElecUsage} kWh stroom,
                    ${this.currentGasUsage} m<span class="power">3</span> gas en
                    ${this.currentProduction} kWh aan eigen opwek</span
                >.
            </p>
        </header>
        ${when(
            this.recalculateIntroduction,
            () => this.renderIntroduction(),
            () => this.renderRecalculate(),
        )}
        ${MonthlyPayment.faq.length
            ? html`<hv-faq id="faq" .questions=${MonthlyPayment.faq}></hv-faq>`
            : null}
    `;

    private renderIntroduction = (): TemplateResult => html`<p>
            Check hier regelmatig je maandbedrag, zo voorkom je dat je te weinig
            of juist te veel betaalt.
        </p>
        <p>
            Door op de knop Herbereken maandbedrag te klikken, krijg je eerst de
            optie om je verbruik aan te passen, waarna je een voorstel van je
            nieuwe maandbedrag te zien krijgt. Pas je je verbruik aan, dan nemen
            we dit mee in de berekening. Als je je verbruik niet aanpast, nemen
            we alleen de laatste prijsontwikkelingen in de markt mee. Vervolgens
            kun je het voorgestelde maandbedrag bevestigen.
        </p>
        <paper-button
            @click="${() => {
                this.recalculateIntroduction = false;
            }}"
            class="btnPrimary"
            >${this.buttonLabel}
        </paper-button>`;

    private renderRecalculate = (): TemplateResult => html`
        <p class="monthly-payment-header">
            ${MonthlyPayment.monthlyIntroduction}
        </p>
        <main>
            <input-component
                label="Stroom normaal jaarverbruik"
                widthKind="small"
                inputSuffix="kWh"
                type="number"
                min="10"
                max="99999"
                rounded
                value="${ifDefined(this.newElecHighUsage)}"
                validate-on-focus-out
                @form-element-changed=${this.setHighElecUsage}
                ?disabled=${!this.hasElec()}
            ></input-component>
            <input-component
                label="Stroom dal jaarverbruik"
                inputSuffix="kWh"
                widthKind="small"
                type="number"
                min="10"
                max="99999"
                rounded
                value="${ifDefined(this.newElecLowUsage)}"
                validate-on-focus-out
                @form-element-changed=${this.setLowElecUsage}
                ?disabled=${!this.hasElec()}
            ></input-component>
            <input-component
                label="Gasverbruik per jaar"
                inputSuffix="m3"
                widthKind="small"
                type="number"
                min="5"
                max="99999"
                value="${ifDefined(this.newGasUsage)}"
                validate-on-focus-out
                @form-element-changed=${this.setGasUsage}
                ?disabled=${!this.hasGas()}
            ></input-component>
            <input-component
                label="Eigen opwek per jaar"
                inputSuffix="kWh"
                widthKind="small"
                type="number"
                min="0"
                max="99999"
                rounded
                value="${ifDefined(this.newProduction)}"
                validate-on-focus-out
                @form-element-changed=${this.setReturn}
                ?disabled=${!this.hasElec()}
            ></input-component>
            <button-component
                label="Berekenen"
                theme="primary"
                size="small"
                verticalSpacing="big-top"
                @click=${this.recalculateMonthly}
            ></button-component>
            ${MonthlyPayment.showIntroduction
                ? html`<button-component
                      label="Annuleren"
                      theme="secondary"
                      size="small"
                      verticalSpacing="default"
                      @click="${() => {
                          this.recalculateIntroduction =
                              !this.recalculateIntroduction;
                      }}"
                  ></button-component>`
                : null}
            <div>
                <hv-loader-modal
                    .busy=${this.newOfferPriceLoading && !this.newOfferPrice}
                    .overlay=${true}
                ></hv-loader-modal>
            </div>

            <recalculate-modal
                ?opened=${this.openSuccesModal}
                .oldPrice="${ifDefined(this.monthlyPricing)}"
                .newPrice="${ifDefined(this.newOfferPrice)}"
                .snapshotID=${this.snapshotID}
                @modal-closed=${() => {
                    window.store.dispatch(recalculationModalOpener.run(false));
                }}
            ></recalculate-modal>
            <invalid-recalculate-modal
                ?opened=${this.openErrorModal}
                .oldPrice="${ifDefined(this.monthlyPricing)}"
                .newPrice="${ifDefined(this.errorModalPrice)}"
                .snapshotID=${this.snapshotID}
                @modal-closed=${() => {
                    window.store.dispatch(
                        recalculationModalErrorOpener.run({
                            opened: false,
                            price: 0,
                        }),
                    );
                }}
            ></invalid-recalculate-modal>
        </main>
    `;
}
