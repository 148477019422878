import {
    CLEAR_CREATE_USER_PAYMENT_ACCOUNT_QUERY,
    REQUEST_CREATE_USER_PAYMENT_ACCOUNT,
    REQUEST_CREATE_USER_PAYMENT_ACCOUNT_SUCCESS,
    REQUEST_CREATE_USER_PAYMENT_ACCOUNT_FAILED,
    RESET_CREATE_USER_PAYMENT_ACCOUNT,
} from '../actions/userPaymentAccount';

const defaultCreateUserPaymentAccountState = {
    query: null,
    data: null,
    error: null,
};

/**
 * State reducer for create user payment account request
 * @param {Object} state
 * @param {Object} action
 * @return {Object}
 */
export const createUserPaymentAccount = (
    state = { ...defaultCreateUserPaymentAccountState },
    action,
) => {
    switch (action.type) {
        case CLEAR_CREATE_USER_PAYMENT_ACCOUNT_QUERY:
            return { ...state, query: null };
        case REQUEST_CREATE_USER_PAYMENT_ACCOUNT:
            return { ...state, query: action.payload, data: null };
        case REQUEST_CREATE_USER_PAYMENT_ACCOUNT_SUCCESS:
            return { ...state, data: action.data, error: null };
        case REQUEST_CREATE_USER_PAYMENT_ACCOUNT_FAILED:
            return { ...state, error: action.error };
        case RESET_CREATE_USER_PAYMENT_ACCOUNT:
            return { ...defaultCreateUserPaymentAccountState };
        default:
            return state;
    }
};
