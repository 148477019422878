import { css } from 'lit-element';

// deprecate these styles
export default css`
    :host {
        /* Default colours */
        --tertiary-color: #646f79;
        --quinary-color: #7c36c7;
        --app-white: var(--white);
        --app-black: #1e2129;
        --app-light-grey: #eee;
        --app-dark-grey-aaa: #aaa;
        --app-blue: #00dbdb;
        --app-blue-dark: #6b2fad;
        --app-text-color: #444;
        --broken-black: #1e2029;

        /* Custom colors */
        --button-border-color: rgba(0, 0, 0, 0.12);

        /* Time picker */
        --picker-theme-color: var(--tertiary-color);
        --picker-item-font-size: 18px;
        --picker-item-height: 50px;
        --picker-current-border-width: 1px;

        /* borders */
        --border-color: var(--black-12);
    }
`;
