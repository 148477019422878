import {
    ADD_LABEL,
    CLEAR,
    CLEAR_COLLECTIVE,
    RESET_ORDER,
    SET_CHARGE_POLE_USAGE,
    SET_COLLECTIVE,
    SET_ELECTRICITY,
    SET_ELECTRICITY_PRODUCTION_ESTIMATE,
    SET_ELECTRICITY_USAGE_ESTIMATE,
    SET_ELECTRICITY_USAGE_FACTOR_ESTIMATE,
    SET_ENERGYSPLITTER,
    SET_GAS,
    SET_GAS_USAGE_ESTIMATE,
    SET_GEO_INFORMATION,
    SET_HOUSE_NUMBER,
    SET_HOUSE_NUMBER_SUFFIX,
    SET_OFFER,
    SET_POSTAL_CODE,
    SET_PROPOSITION,
    SET_SOLAR_MAIL,
} from '../actions/order';

import { SET_TRANSMISSION_TYPES } from '../actions/offer';

/**
 * @typedef SelectedOffer
 * @property {string} id the selected offer
 * @property {number} index the selected configuration
 */

/**
 * @typedef GeoCode
 * @property {string} city name of the city
 * @property {number} house_number
 * @property {string} street_name
 */

/**
 * @typedef Order
 * @property {string?} collective the selected collective or null when none should be used
 * @property {boolean} electricity if elec should be included in order
 * @property {number} electricityProductionEstimate amount of estimated production of elec type
 * @property {number} electricityUsageEstimate amount of estimated usage of elec type
 * @property {number} electricityUsageFactorEstimate amount of estimated usage of elec type
 * @property {boolean} gas if gas should be included in order
 * @property {number} gasUsageEstimate amount of estimated usage of gas type
 * @property {number?} houseNumber where metering points should be
 * @property {number} transmissionTypeGas transmissionTypeGas
 * @property {number} transmissionTypeElec transmissionTypeElec
 * @property {string?} [houseNumberSuffix] only if address needs suffix
 * @property {SelectedOffer?} offer selected offer and index
 * @property {string?} postalCode where metering points should be
 * @property {UUID?} proposition selected prop
 * @property {number} chargePoleUsage is the amount of usage of a charging pole
 * @property {string?} redirectType is the type of redirect we need to perform
 * @property {GeoCode?} geoInformation
 */

/** @type {Order} */
const defaultState = {
    collective: null,
    electricity: true,
    electricityProductionEstimate: 0,
    electricityUsageEstimate: 0,
    electricityUsageFactorEstimate: 0.6,
    energysplitter: false,
    gas: true,
    gasUsageEstimate: 0,
    houseNumber: null,
    houseNumberSuffix: null,
    offer: null,
    postalCode: null,
    proposition: null,
    transmissionTypeGas: 20111,
    transmissionTypeElec: 10211,
    chargePoleUsage: 0,
    redirectType: null,
    geoInformation: null,
    labels: [],
};

/**
 * State reducer for order object
 * @param {Object} state
 * @param {Object} action
 * @return {Object}
 */
export const order = (state = { ...defaultState }, action) => {
    switch (action.type) {
        case CLEAR:
            return { ...defaultState };
        case ADD_LABEL:
            const res = [];
            let found = false;
            for (const label of state.labels) {
                if (label[0] === action.label[0]) {
                    found = true;
                }
                res.push(label);
            }
            if (!found) {
                res.push(action.label);
            }
            return {
                ...state,
                labels: res,
            };
        case CLEAR_COLLECTIVE:
            return {
                ...state,
                collective: null,
            };
        case SET_COLLECTIVE:
            return {
                ...state,
                collective: action.collective,
            };
        case SET_ELECTRICITY:
            return {
                ...state,
                electricity: action.electricity,
                ...(!action.electricity
                    ? { electricityUsageEstimate: 0 }
                    : undefined),
            };
        case SET_ELECTRICITY_PRODUCTION_ESTIMATE:
            return {
                ...state,
                electricityProductionEstimate:
                    action.electricityProductionEstimate,
            };
        case SET_ELECTRICITY_USAGE_ESTIMATE:
            return {
                ...state,
                electricityUsageEstimate: action.electricityUsageEstimate,
            };
        case SET_ELECTRICITY_USAGE_FACTOR_ESTIMATE: {
            return {
                ...state,
                electricityUsageFactorEstimate:
                    action.electricityUsageFactorEstimate,
            };
        }
        case SET_GAS:
            return {
                ...state,
                gas: action.gas,
                ...(!action.gas ? { gasUsageEstimate: 0 } : undefined),
            };
        case SET_GAS_USAGE_ESTIMATE:
            return {
                ...state,
                gasUsageEstimate: action.gasUsageEstimate,
            };
        case SET_HOUSE_NUMBER:
            return {
                ...state,
                houseNumber: action.houseNumber,
            };
        case SET_HOUSE_NUMBER_SUFFIX:
            return {
                ...state,
                houseNumberSuffix: action.houseNumberSuffix,
            };
        case SET_OFFER:
            return {
                ...state,
                offer: {
                    id: action.id,
                    index: action.index,
                },
            };
        case SET_POSTAL_CODE:
            return {
                ...state,
                postalCode: action.postalCode,
            };
        case SET_PROPOSITION:
            return {
                ...state,
                proposition: action.proposition,
            };
        case SET_TRANSMISSION_TYPES:
            return {
                ...state,
                transmissionTypeGas: action.gas,
                transmissionTypeElec: action.elec,
            };
        case SET_CHARGE_POLE_USAGE:
            return {
                ...state,
                chargePoleUsage: action.chargePoleUsage,
            };
        case SET_SOLAR_MAIL:
            return {
                ...state,
                solarMail: action.solarMail,
            };
        case SET_ENERGYSPLITTER:
            return {
                ...state,
                energysplitter: action.energysplitter,
            };
        case SET_GEO_INFORMATION:
            return {
                ...state,
                geoInformation: action.geoInformation,
            };
        case RESET_ORDER:
            return defaultState;
        default:
            return state;
    }
};
