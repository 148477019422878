export const UPDATE_ROUTING = 'UPDATE_ROUTING';
export const UPDATE_OFFLINE = 'UPDATE_OFFLINE';
export const UPDATE_DRAWER_STATE = 'UPDATE_DRAWER_STATE';
export const UPDATE_TERMS_AND_CONDITIONS = 'UPDATE_TERMS_AND_CONDITIONS';
export const UPDATE_USER_AUTHENTICATED = 'UPDATE_USER_AUTHENTICATED';
export const UPDATE_INSIGHTS_APP_USER = 'UPDATE_INSIGHTS_APP_USER';
export const OPEN_SNACKBAR = 'OPEN_SNACKBAR';
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';
export const UPDATE_REROUTE = 'UPDATE_REROUTE';
export const UPDATE_ENABLED_ITEMS = 'UPDATE_ENABLED_ITEMS';

/**
 * Update if terms accepted
 * @param {boolean} acceptedTermsAndConditions
 * @return {Object}
 */
export const updateTermsAndConditions = (acceptedTermsAndConditions) => ({
    type: UPDATE_TERMS_AND_CONDITIONS,
    acceptedTermsAndConditions,
});

/**
 * Update if user is authenticated
 * @param {boolean} userAuthenticated
 * @return {Object}
 */
export const updateUserAuthenticated = (userAuthenticated) => ({
    type: UPDATE_USER_AUTHENTICATED,
    userAuthenticated,
});

/**
 * Update is the user is a insights app user
 * @param {boolean} insightsAppUser
 * @return {Object}
 */
export const updateInsightsAppUser = (insightsAppUser) => ({
    type: UPDATE_INSIGHTS_APP_USER,
    insightsAppUser,
});

/** @typedef {import('./app').enabledItemsObject} enabledItemsObject */

/**
 * Update enabled items
 * @param {enabledItemsObject} enabledItems
 * @return {Object}
 */
export const updateEnabledItems = (enabledItems) => ({
    type: UPDATE_ENABLED_ITEMS,
    enabledItems,
});

/** @type {NodeJS.Timer} */
let snackbarTimer;

// @ts-ignore
export const showSnackbar = () => (dispatch) => {
    dispatch({
        type: OPEN_SNACKBAR,
    });
    clearTimeout(snackbarTimer);
    snackbarTimer = setTimeout(() => dispatch({ type: CLOSE_SNACKBAR }), 3000);
};

/**
 * Update if app is offline mode or not
 * @param {Boolean} offline
 * @return {function}
 */
export const updateOffline =
    (offline) =>
    // @ts-ignore
    (dispatch, getState) => {
        // Show the snackbar, unless this is the first load of the page.
        if (getState().app.offline !== undefined) {
            dispatch(showSnackbar());
        }
        dispatch({
            type: UPDATE_OFFLINE,
            offline,
        });
    };

/**
 * Updates drawer state
 * @param {boolean} opened
 * @return {function}
 */
export const updateDrawerState =
    (opened) =>
    // @ts-ignore
    (dispatch, getState) => {
        if (getState().app.drawerOpened !== opened) {
            dispatch({
                type: UPDATE_DRAWER_STATE,
                opened,
            });
        }
    };

export const updateLayout =
    () =>
    // @ts-ignore
    (dispatch, getState) => {
        if (getState().app.drawerOpened) {
            dispatch(updateDrawerState(false));
        }
    };
