import { css } from 'lit-element';

export default css`
    :host {
        --font-size: 14px;
        --margin-bottom: 20px;
        --margin-top: 20px;
    }
    p {
        font-size: var(--font-size);
        color: var(--font-secondary-color);
        text-align: center;
        margin-bottom: var(--margin-bottom);
        margin-top: var(--margin-top);
    }
`;
