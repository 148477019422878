import { Snapshot } from 'types/snapshot';
import enums from 'enums';
import { contractPrefix } from './snapshot';

export const allowedPhases = [
    enums.SnapshotPhaseFirstPaymentDone,
    enums.SnapshotPhaseDeliveryPlanned,
    enums.SnapshotPhaseDeliveryStarted,
    enums.SnapshotPhaseInsightContractActive,
];

export interface SnapshotObject {
    address: string;
    snapshot: Snapshot;
}

export interface JWTSortedByAdress {
    snapshots: Snapshot[];
    firstSnapshot: Snapshot;
    snapshotWithAdress: Map<string, Snapshot>;
}

export const sortJwtByAdressAndPrefix = (
    snapshots: Snapshot[],
): JWTSortedByAdress => {
    if (snapshots && snapshots.length > 0) {
        const snaps = new Map();
        snapshots.forEach((snap) => {
            const item = {} as SnapshotObject;
            item.snapshot = snap;
            if (snap.snapshot_phase === enums.SnapshotPhaseNeverDelivered) {
                return;
            }
            item.snapshot.prefix = contractPrefix(snap);
            item.address = `${snap.delivery_location.street_name} ${snap.delivery_location.house_number}`;
            // developer fallback to display snapshot without delivery location.
            if (item.address === '') {
                console.warn(
                    `Snapshot ${snap.id} is missing delivery location`,
                );
            }

            if (snap.delivery_location?.house_addition !== '') {
                item.address += ` ${snap.delivery_location.house_addition}`;
            }
            item.address += `, ${snap.delivery_location.area_code} ${snap.delivery_location.city}`;

            // if key doesnt exist add to set but if exist override item if that item has not ended.
            if (allowedPhases.includes(item.snapshot.snapshot_phase)) {
                if (
                    snaps.has(item.address) &&
                    snaps.get(item.address).id !== item.snapshot.id
                ) {
                    item.address += ' ';
                }
                snaps.set(item.address, item.snapshot);
            }
        });

        const sortedByKey = new Map([...snaps.entries()].sort());
        const sortedBySnapshotPhase = new Map(
            [...sortedByKey.entries()].sort(compareSnapshotPhase),
        );
        return {
            snapshots: Array.from(sortedBySnapshotPhase.values()),
            firstSnapshot: sortedBySnapshotPhase.values().next().value,
            snapshotWithAdress: sortedBySnapshotPhase,
        } as JWTSortedByAdress;
    }

    return {
        snapshots: [],
        firstSnapshot: {} as Snapshot,
        snapshotWithAdress: new Map(),
    } as JWTSortedByAdress;
};

export const compareSnapshotPhase = (
    a: [string, Snapshot],
    b: [string, Snapshot],
): number => {
    const aPhase = a[1].snapshot_phase;
    const aValid = allowedPhases.includes(aPhase);
    const bPhase = b[1].snapshot_phase;
    const bValid = allowedPhases.includes(aPhase);
    return aValid && !bValid ? 1 : aPhase === bPhase ? 0 : -1;
};
