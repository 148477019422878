import axios from 'axios';
import { call, put, takeLatest } from 'redux-saga/effects';
import resourceSettings from '../settings/resourceSettings';

/**
 * Store member management object abstract
 * @abstract
 */
export class Resource {
    /**
     * @typedef {import('axios').AxiosRequestConfig} AxiosRequestConfig
     */

    /**
     * @typedef {import('./types/ResourceDataType').ResourceDataTypeClass} ResourceDataTypeClass
     */

    /**
     * @typedef {Object} ResourceOptions
     * @property {Object} defaultParameters
     */

    /**
     * Constructor
     * @param {string} storeName
     * @param {ResourceOptions} [options]
     */
    constructor(storeName, options) {
        /** @type {string} */
        this._storeName = '';
        /** @type {string} */
        this.prefix = '';
        /** @type {Object} */
        this._defaultParameters = {};

        this.storeName = storeName;

        this.basePath = '/v1';

        if (options) {
            if (options.basePath) {
                this.basePath = options.basePath;
            }

            if (options.defaultParameters) {
                Object.keys(options.defaultParameters).forEach(
                    (requestProperty) => {
                        // @ts-ignore
                        if (this.constructor.parameters[requestProperty]) {
                            this._defaultParameters[requestProperty] =
                                options.defaultParameters[requestProperty];
                        } else {
                            // @ts-ignore
                            console.warn(
                                'Property',
                                requestProperty,
                                'not found on',
                                this.constructor.parameters,
                            );
                        }
                    },
                );
            }
        }
    }

    /**
     * Default empty route object
     * @return {string}
     */
    static get route() {
        return '';
    }

    /**
     * Default empty variables object
     * @return {Object}
     */
    static get variables() {
        return {};
    }

    /**
     * Setter for storeName
     * @param {string} storeName
     */
    set storeName(storeName) {
        this._storeName = storeName;
        this.prefix = `${storeName} <${this.constructor.name.replace(
            /^\w/,
            (c) => c.toLowerCase(),
        )}>: `;
    }

    /**
     * Getter for storeName
     * @return {string}
     */
    get storeName() {
        return this._storeName;
    }

    /* Action types */
    /**
     * REQUEST action type
     */
    get REQUEST() {
        return `${this.prefix}REQUEST`;
    }

    /**
     * REQUEST_FAILED action type
     */
    get REQUEST_FAILED() {
        return `${this.prefix}REQUEST_FAILED`;
    }

    /**
     * REQUEST_SUCCESS action type
     */
    get REQUEST_SUCCESS() {
        return `${this.prefix}REQUEST_SUCCESS`;
    }

    /**
     * RESET action type
     */
    get RESET() {
        return `${this.prefix}RESET`;
    }

    /* Action generators */
    /**
     * Default failure callback action generator for fetch
     * @param {any} error
     * @return {Object}
     */
    failureCallback(error) {
        return { error, type: this.REQUEST_FAILED };
    }

    /**
     * Default action generator for fetch
     * @param {Object} requestObject
     * @return {Object}
     */
    request(requestObject = {}) {
        return {
            requestObject,
            type: this.REQUEST,
        };
    }

    /**
     * Default action generator for reset
     * @return {Object}
     */
    reset() {
        return {
            type: this.RESET,
        };
    }

    /**
     * Default success callback action generator for fetch
     * @param {any} data
     * @return {Object}
     */
    successCallback(data) {
        return { data, type: this.REQUEST_SUCCESS };
    }

    /* Reducer */

    /**
     * Returns state reducer
     * @return {function}
     */
    reducer() {
        const resource = this;
        const defaultState = {
            busy: false,
            data: null,
            error: null,
            query: null,
        };
        /**
         * State reducer
         * @param {Object} state
         * @param {Object} action
         * @return {Object}
         */
        const reducer = (state = defaultState, action) => {
            switch (action.type) {
                case resource.REQUEST:
                    return {
                        ...state,
                        busy: true,
                        query: action.requestObject,
                    };
                case resource.REQUEST_FAILED:
                    return { ...state, busy: false, error: action.error };
                case resource.REQUEST_SUCCESS:
                    return {
                        ...state,
                        busy: false,
                        data: action.data,
                        error: null,
                    };
                default:
                    return state;
            }
        };
        return reducer;
    }

    /* Saga */
    /**
     * Returns watcher
     * @return {function}
     */
    watch() {
        const resource = this;
        /**
         * Watcher
         */
        const watcher = function* () {
            // @ts-ignore
            yield takeLatest(resource.REQUEST, resource.fetch());
        };
        return watcher;
    }

    /**
     * Returns fetcher
     * @return {function}
     */
    fetch() {
        const resource = this;
        /**
         * Fetcher
         * @param {Object} action
         */
        const fetcher = function* (action) {
            try {
                let result;
                const reqObject = action.requestObject;
                const baseURL = `${resourceSettings.apiBaseURL}${resource.basePath}`;
                // If post add parameters to the axios call.
                if (
                    action &&
                    reqObject &&
                    reqObject.payload &&
                    Object.keys(reqObject.payload).length > 0
                ) {
                    result = yield call(async () => {
                        const response = await axios.post(
                            resource.generateRequestRoute(reqObject),
                            resource.generateRequestPayload(reqObject),
                            {
                                baseURL,
                                headers:
                                    resource.generateRequestHeaders(reqObject),
                            },
                        );
                        if (
                            response.statusText === 'OK' ||
                            (response.status >= 200 && response.status < 300)
                        ) {
                            return { data: response.data };
                        }
                        throw response.status;
                    });
                } else {
                    // if call isnt a post
                    result = yield call(async () => {
                        const response = await axios.request({
                            ...resource.generateRequest(reqObject),
                        });
                        if (
                            response.statusText === 'OK' ||
                            (response.status >= 200 && response.status < 300)
                        ) {
                            return { data: response.data };
                        }
                        throw response.status;
                    });
                }
                yield put(resource.successCallback(result.data));
            } catch (e) {
                if (e instanceof Error) {
                    /* eslint-disable no-console */
                    console.warn('Caught while running request:', e, action);
                    /* eslint-enable no-console */
                    yield put(resource.failureCallback(e.toString()));
                } else {
                    yield put(resource.failureCallback(e));
                }
            }
        };
        return fetcher;
    }

    /**
     * Generate request
     * @param {Object?} requestObject
     * @return {AxiosRequestConfig}
     */
    generateRequest(requestObject = {}) {
        /** @type {AxiosRequestConfig} */
        const request = {};
        const baseURL = `${resourceSettings.apiBaseURL}${this.basePath}`;

        request.baseURL = baseURL;
        request.headers = this.generateRequestHeaders(requestObject);
        // @ts-ignore
        request.method = this.constructor.method;
        request.params = this.generateRequestParams(requestObject);
        request.url = this.generateRequestRoute(requestObject);

        return request;
    }

    /**
     * Generate request route
     * @param {Object?} requestObject
     * @return {string}
     */
    generateRequestRoute(requestObject = {}) {
        const pathProps = this.getRequestPropsOfInType(
            'path',
            requestObject,
            true,
        );
        // @ts-ignore
        let { route } = this.constructor;
        const routeProps = [];
        // Justification for filter https://tools.ietf.org/html/rfc3986#section-2.3
        const pattern = /{([a-zA-Z0-9-_~.]*?)}/g;
        {
            let found;
            while ((found = pattern.exec(route))) {
                if (
                    Array.isArray(found) &&
                    found.length > 1 &&
                    typeof found[1] === 'string' &&
                    routeProps.indexOf(found[1]) < 0
                ) {
                    routeProps.push(found[1]);
                }
            }
        }
        routeProps.forEach((prop) => {
            if (pathProps[prop] === undefined) {
                throw new Error(
                    `Property in resource route missing in resource parameters: ${prop}`,
                );
            } else {
                route = route.split(`{${prop}}`).join(pathProps[prop]);
            }
        });
        return route;
    }

    /**
     * Generate request params
     * @param {Object?} requestObject
     * @return {Object}
     */
    generateRequestParams(requestObject = {}) {
        return this.getRequestPropsOfInType('query', requestObject);
    }

    /**
     * Generate request headers
     * @param {Object?} requestObject
     * @return {Object}
     */
    generateRequestHeaders(requestObject = {}) {
        return this.getRequestPropsOfInType('header', requestObject);
    }

    /**
     * Generate request headers
     * @param {Object?} requestObject
     * @return {Object}
     */
    generateRequestPayload = (requestObject = {}) =>
        Object.keys(requestObject.payload).length > 0
            ? requestObject.payload
            : {};

    /**
     * Generate request properties for an in-type
     * @param {string} inType
     * @param {Object?} requestObject
     * @param {boolean} [requiredOverwrite]
     * @return {Object}
     */
    getRequestPropsOfInType(inType, requestObject = {}, requiredOverwrite) {
        /** @type {Object} */
        const requestParams = {};

        this.parameterArray()
            .filter((p) => p.in === inType)
            .forEach((p) => {
                if (requestObject[p.name] !== undefined) {
                    const value =
                        typeof requestObject[p.name] === 'function'
                            ? requestObject[p.name]()
                            : requestObject[p.name];
                    this.validateType(p.type, value);
                    requestParams[p.name] = value;
                } else if (this._defaultParameters[p.name] !== undefined) {
                    const value =
                        typeof this._defaultParameters[p.name] === 'function'
                            ? this._defaultParameters[p.name]()
                            : this._defaultParameters[p.name];
                    this.validateType(p.type, value);
                    requestParams[p.name] = value;
                } else if (
                    (p.required && requiredOverwrite === undefined) ||
                    requiredOverwrite
                ) {
                    throw new Error(
                        `Missing required ${inType} param: "${p.name}"`,
                    );
                }
            });

        return requestParams;
    }

    /**
     *
     * @param {ResourceDataTypeClass?} T
     * @param {any} value
     */
    validateType = (T, value) => {
        if (T && typeof T.validate === 'function') {
            if (!T.validate(value)) {
                // @ts-ignore
                throw new Error(
                    `Validation failed for ${value} as type ${
                        T.name || T
                    }. Correct format example: ${new T().toFormatString()}`,
                );
            }
        }
    };

    /**
     * @return {Array<Object>}
     */
    parameterArray() {
        /** @type {Object} */
        const { parameters } = this.constructor;

        /** @type {Array<Object>} */
        const parameterArray = [];
        Object.keys(parameters).forEach((p) => {
            if (p && parameters[p]) {
                parameterArray.push({ ...parameters[p], name: p });
            }
        });

        return parameterArray;
    }
}
