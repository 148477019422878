import { LitElement, TemplateResult, customElement, html } from 'lit-element';

import './settings-display';

@customElement('settings-display-container')
export class settingsDisplayContainer extends LitElement {
    public render = (): TemplateResult => html`
        <style>
            :host {
                display: flex;
                flex-direction: column;
            }
        </style>
        <information-header
            ?allowFutureSnapshots="${true}"
        ></information-header>
        <settings-display></settings-display>
    `;
}

declare global {
    interface HTMLElementTagNameMap {
        'settings-display-container': settingsDisplayContainer;
    }
}
