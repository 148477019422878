import { css } from 'lit-element';

export default css`
    :host {
        --loader--background-color: rgba(0, 0, 0, 0.5);
        --loader--loading--border: 4px solid var(--primary-color);
        --loader--width: 60px;
        --loader--height: 60px;

        --hv-loader-modal-loader-background: transparent;
        --hv-loader-modal-loader-padding: 0px;

        display: none;
        height: 0;
        width: 0;
    }

    hv-loader {
        --hv-loader-width: var(--loader--width);
        --hv-loader-height: var(--loader--height);
        --hv-loader-loader-color: var(--primary-color);
        --hv-loader-color: var(--white);
        --hv-loader-background: var(--hv-loader-modal-loader-background);
        --hv-loader-padding: var(--hv-loader-modal-loader-padding);
    }

    :host([busy]) {
        display: block;
        height: auto;
        width: auto;
    }

    .overlay {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--loader--background-color);
        position: absolute;
        height: 100vh;
        width: 100vw;
        z-index: 1000;
        top: 0;
        left: 0;
    }
`;
