import {
    customElement,
    TemplateResult,
    html,
    LitElement,
    CSSResult,
    PropertyValues,
    property,
} from 'lit-element';
import { whitelabel } from '@weavelab/whitelabel';
import { Snapshot } from 'types/snapshot';
import {
    PhaseNotifications,
    PhaseNotificationStyle,
} from 'types/phaseNotifications';
import PhaseBannerStyle from './style';
import { connect, watch } from 'lit-redux-watch';
import enums from 'enums';

export const smartChargingContractCreated = 'Welkom bij slim laden';
export const smartChargingCarAttached = 'Je auto is gekoppeld';
export const smartChargingChargingActive = 'Je auto is aan het laden';
export const smartChargingChargingInactive = 'Je auto is niet aan het laden';

const defaultPhaseNotifications: PhaseNotifications = {
    [enums.SnapshotPhaseSmartChargingContractCreated]: {
        title: smartChargingContractCreated,
    },
    [enums.SnapshotPhaseSmartChargingCarAttached]: {
        title: smartChargingCarAttached,
    },
    [enums.SnapshotPhaseSmartChargingActive]: {
        title: smartChargingChargingActive,
    },
    [enums.SnapshotPhaseSmartChargingStopped]: {
        title: smartChargingChargingInactive,
    },
};

@customElement('phase-banner')
@whitelabel({ name: 'phase-banner' })
export class PhaseBanner extends connect(window.store)(LitElement) {
    @watch('jwtSnapshots.smartChargingSnapshots')
    protected smartChargingSnapshots?: Snapshot[];

    @property({ type: Number })
    snapshotPhase: number = 0;

    @whitelabel()
    static phaseNotifications?: PhaseNotifications;

    @property()
    title: string = '';

    @property()
    phaseNotificationStyle?: string = PhaseNotificationStyle.NEUTRAL;

    @property()
    status?: string = '';

    @property()
    body?: string = '';

    @whitelabel()
    public static get styles(): CSSResult[] {
        return [PhaseBannerStyle];
    }

    getWhitelabelTextForSnapshotPhase = (): void => {
        let phaseNotifications = defaultPhaseNotifications;
        if (
            PhaseBanner.phaseNotifications?.hasOwnProperty(this.snapshotPhase)
        ) {
            phaseNotifications = PhaseBanner.phaseNotifications;
        }

        this.title = phaseNotifications[this.snapshotPhase].title;
        if (phaseNotifications[this.snapshotPhase].status) {
            this.status = phaseNotifications[this.snapshotPhase].status;
        }
        if (phaseNotifications[this.snapshotPhase].body) {
            this.body = phaseNotifications[this.snapshotPhase].body;
        }
        if (phaseNotifications[this.snapshotPhase].status) {
            this.status = phaseNotifications[this.snapshotPhase].status;
        }
    };

    getSmartChargingStatus = (): void => {
        // implement here by doing some logic to get the actual charging status
        // set the style and status accordingly
        // this.phaseNotification.status = ...
    };

    protected updated(changedProperties: PropertyValues): void {
        // if smart charging is set on component, loop through all snapshots and check if any of them is smart charging
        // we do this because a selectedsnapshot will never be a smart charging snapshot, so we have to loop over all snapshots
        if (changedProperties.has('smartChargingSnapshots')) {
            this.smartChargingSnapshots?.forEach((snapshot: Snapshot) => {
                if (snapshot.snapshot_phase !== this.snapshotPhase) {
                    this.snapshotPhase = snapshot.snapshot_phase;
                    this.getWhitelabelTextForSnapshotPhase();
                    this.getSmartChargingStatus();
                }
            });
        }
    }

    public render = (): TemplateResult => {
        return html` <div
            class="phase-banner"
            phaseBannerStyle="${this.phaseNotificationStyle}"
        >
            <p class="title">${this.title}</p>
            <div class="status-row">
                <div class="dot"></div>
                <p class="status">${this.status}</p>
            </div>
            <p class="body">${this.body}</p>
        </div>`;
    };
}

declare global {
    interface HTMLElementTagNameMap {
        'phase-banner': PhaseBanner;
    }
}
