import { css } from 'lit-element';

export default css`
    :host([hideText='false']) {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
    }
    :host([hideText='false']) checkbox-wrapper {
        min-height: 23px;
    }
    :host {
        --checkbox-component--border-radius: 5px;
        --checkbox-component-margin: 0;
        --checkbox-component--color: var(--secondary-color);
        --checkbox-component--hover-color: var(--checkbox-component--color);
        --checkbox-component--font-size: 15px;
        --checkbox-component--font-family: inherit;
        --checkbox-component-border: 1px solid var(--primary-color);
        --checkbox-component__unchecked--border: 1px solid
            var(--checkbox-component__unchecked-color);
        --checkbox-component__checked-color: var(--primary-color);
        --checkbox-component__unchecked-color: var(
            --checkbox-component__checked-color
        );
        --checkbox-component__dark--color: --trinary-color;

        position: relative;
        cursor: pointer;
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none; /* Non-prefixed version, currently
                        supported by Chrome, Opera and Firefox */
        margin: var(--checkbox-component-margin);
        font-family: var(--checkbox-component--font-family);
    }
    :host([hidden]) {
        display: none;
    }
    :host(:last-of-type) {
        border-bottom: none;
    }

    :host([theme='light']) p {
        color: var(--white);
    }
    :host([theme='dark']) #checkbox,
    :host([theme='dark']) p {
        color: var(--checkbox-component__dark--color);
    }
    :host([theme='dark']) #checkbox {
        border: var(--checkbox-component-border);
    }

    p {
        color: var(--checkbox-component--color);
        font-size: var(--checkbox-component--font-size);
        margin: 0 0 0 12px;
        line-height: 20px;
        flex-grow: 1;
    }
    #checkbox {
        -moz-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        -webkit-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        width: 20px;
        height: 20px;
        border-radius: 5px;
        border: var(--checkbox-component__unchecked--border);
        display: flex;
        align-items: center;
        justify-content: center;
    }
    :host(.selected) #checkbox {
        border: var(--checkbox-component-border);
        background-color: var(--primary-color);
    }
    :host(:hover) #checkbox {
        border: var(--checkbox-component-border);
    }
    :host(:hover) p {
        color: var(--checkbox-component--hover-color);
    }
    img {
        transform: translate(-1%, 5%);
    }
    .checkbox-wrapper {
        display: flex;
        flex-grow: 1;
    }
`;
