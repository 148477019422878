import {
    LitElement,
    TemplateResult,
    customElement,
    html,
    property,
} from 'lit-element';
import { whitelabel } from '@weavelab/whitelabel';
import { provider } from '@weavelab/frontend-connect';

import SmartChargingSharedStyles from '../SmartChargingSharedStyles';
import { _defaults } from '../../../components/helpers/_defaults';

import '@templates/smart-charging-step-wrapper/SmartChargingStepWrapper';

/**
 * Used as ui view for the step with SmartChargingOnboarding.ts
 */
@customElement('view-car-connect-explanation')
@whitelabel({ name: 'view-car-connect-explanation' })
export class CarConnectExplanation extends LitElement {
    @property()
    public assets?: any = provider.asset;

    static get styles() {
        return [_defaults, SmartChargingSharedStyles];
    }

    public render = (): TemplateResult => {
        return html`
            <div>
                <h1 class="title">Koppelen van de auto met de app</h1>
                <div class="description">
                    <img
                        class="icon"
                        src="${this.assets('icons/car.svg')}"
                        alt="Car icon"
                    />
                    <div class="text">
                        Eenvoudig verbinding maken met de auto via Enode
                    </div>
                </div>
                <div class="explanation">
                    <p>
                        We werken samen met onze partner Enode om de auto te
                        koppelen aan de Slim Laden app.
                    </p>
                    <p>
                        Zij zullen je vragen in te loggen in het
                        <b>persoonlijke account van je autofabrikant</b> en daar
                        het juiste voertuig te selecteren.
                    </p>
                    <p>
                        Eenmaal gekoppeld zul je de auto terug vinden op het
                        dashboard van de Slim Laden app.
                    </p>
                </div>
            </div>
            <div class="button-wrapper">
                <button class="btnPrimary button" @click="${this.nextHandler}">
                    Koppel je auto
                </button>
            </div>
        `;
    };

    // let parent components know that a previous step has been trigger in this ui
    public nextHandler = (): void => {
        this.dispatchEvent(
            new CustomEvent('weave-step-next', {
                bubbles: true,
                composed: true,
            }),
        );
    };
}

declare global {
    interface HTMLElementTagNameMap {
        'view-car-connect-explanation': CarConnectExplanation;
    }
}
