import { css } from 'lit-element';

export default css`
    :host([theme='no-border']) .input-container {
        border-radius: 0;
        border: none;
    }
    :host([theme='no-border']) input {
        text-align: center;
    }
    :host {
        width: 100%;

        --input-component--font-familiy: inherit;
        --input-component--margin: 0 auto 20px;
        --input-component--max-width: auto;
        --input-component--border-radius: 5px;
        --input-component__input--max-width: auto;
        --text-component__input--padding: 0 20px;
        --input-component__border-color: #ddd;
        --input-component--border: 1px solid
            var(--input-component__border-color);
        --input-component__error--font-size: 15px;
        --input-component--label-margin-top: 18px;
        --input-component--label-font-weight: 700;
        --input-component--label-font-size: 14px;
        --input-component--label-line-height: 24px;
        --input-component--label-letter-spacing: 0.25px;
        --input-default-margin-top: 10px;

        margin: var(--input-component--margin);
        font-family: var(--input-component--font-familiy);
    }

    error-handling {
        --error--font-size: var(--input-component__error--font-size);
    }

    .input-label {
        margin-top: var(--input-component--label-margin-top);
        font-weight: var(--input-component--label-font-weight);
        font-size: var(--input-component--label-font-size);
        line-height: var(--input-component--label-line-height);
        letter-spacing: var(--input-component--label-letter-spacing);
        color: var(--greyish-brown);
    }

    .input-container {
        max-width: var(--input-component--max-width);
        border-radius: var(--input-component--border-radius);
    }
    .input-wrapper {
        margin-top: var(--input-default-margin-top);
        border: var(--input-component--border);
        border-radius: var(--input-component--border-radius);
    }

    input {
        color: var(--text-component--color);
        max-width: var(--input-component__input--max-width);
        padding: var(--text-component__input--padding);
        font-size: var(--text-component--font-size);
        width: 100%;
        border: none;
        height: inherit;
    }

    .input-wrapper.disabled input {
        background-color: var(--app-light-grey);
        color: var(--app-dark-grey-aaa);
    }

    :host(.dragging) input {
        cursor: grabbing;
    }
    input:link {
        cursor: var(--dragging);
    }
    input[dragging='true'] {
        cursor: grabbing;
    }
    input[dragging='false'] {
        cursor: auto;
    }

    .input-wrapper[widthKind='small'] {
        max-width: 300px;
    }
`;
