import {
    customElement,
    LitElement,
    TemplateResult,
    html,
    query,
    property,
    PropertyValues,
} from 'lit-element';
import { whitelabel } from '@weavelab/whitelabel';
import { ifDefined } from 'lit-html/directives/if-defined';
import '@components/elements/modal/ModalComponent';
import '@components/elements/base/button/ButtonComponent';
import '@components/elements/base/input-component/InputComponent';
import '@components/molecules/HvLoaderModal/HvLoaderModal';
import '@components/price-compare/index';
import { requestInvalidOfferRecalculation } from '../../../../store';
import { convertToLocalCurrencyNotRounded } from '../../../../helpers/price';
import { messageTypes } from '../../../../index';

import style from './style';

const maxWidth = '778px';

const invalidModalContentText =
    'Grote wijzigingen in het maandbedrag dienen via de klantenservice te verlopen. Geef de reden van de wijziging aan ons door via onderstaande knop. Als het maandbedrag door de klantenservice gewijzigd wordt conform jouw verzoek, dan zul je jouw nieuwe maandbedrag terugzien in jouw online omgeving, en op jouw eerst volgende maandnota. Houd rekening met maximaal 5 werkdagen voor verwerking.';
const leaveAReasonText = 'Vul alstublieft een reden in.';

@customElement('invalid-recalculate-modal')
@whitelabel({ name: 'monthly-payments-recalculate-modal' })
export class InvalidRecalculateModal extends LitElement {
    @query('#recal__modal--invalid')
    public recalModal?: HTMLElementTagNameMap['modal-component'];

    @property({ type: String })
    public newPrice?: string;

    @property({ type: Boolean })
    public opened: boolean = false;

    @property({ type: String })
    public oldPrice?: string;

    @property({ type: Number })
    public normal_usage: number = 0;

    @property({ type: Number })
    public low_usage: number = 0;

    @property({ type: Number })
    public gas_usage: number = 0;

    @property({ type: Number })
    public production: number = 0;

    @property({ type: String })
    public snapshotID: string = '';

    @property({ type: String })
    public reason: string = '';

    @whitelabel({ type: 'string' })
    public static headerTitle: string = 'Nieuw maandbedrag';

    @property({ type: String })
    public leaveAReasonText: string = leaveAReasonText;

    @whitelabel({ type: 'string' })
    public static content: string = invalidModalContentText;

    @whitelabel() // Allow styles to be injected from theme
    static get styles() {
        return [style];
    }

    protected updated(changedProperties: PropertyValues) {
        if (changedProperties.has('opened') && this.opened) {
            this.open();
        }
    }

    public request = (): void => {
        if (!this.reason.length) {
            return window.displayMessage(
                this.leaveAReasonText,
                messageTypes.warning,
            );
        }
        window.store.dispatch(
            requestInvalidOfferRecalculation.request(
                {
                    snapshotId: this.snapshotID,
                },
                {
                    data: {
                        reason: this.reason,
                    },
                },
            ),
        );
        this.close();
    };

    public changeReason = (e: CustomEvent): void => {
        this.reason = e.detail!.value;
    };

    public render = (): TemplateResult => html` <modal-component
        header-title=${InvalidRecalculateModal.headerTitle}
        id="recal__modal--invalid"
        max-width=${maxWidth}
        prevent-closing-outside
    >
        <price-compare
            .oldPrice="${ifDefined(this.oldPrice)}"
            .newPrice="${convertToLocalCurrencyNotRounded(this.newPrice!)}"
        ></price-compare>
        <p>${InvalidRecalculateModal.content}</p>
        <input-component
            label="Wat is de reden voor uw wijziging?"
            placeholder="Type hier uw bericht..."
            @form-element-changed=${this.changeReason}
        ></input-component>
        <div id="select__buttons">
            <button-component
                label="Aanvraag indienen"
                theme="primary"
                size="small"
                verticalSpacing="minimal"
                @click=${this.request}
            ></button-component>
            <button-component
                label="Annuleren"
                theme="secondary"
                size="small"
                verticalSpacing="minimal"
                @click=${this.close}
            ></button-component>
        </div>
    </modal-component>`;

    public open = (): void => {
        this.recalModal?.open();
    };

    public close = (): void => {
        this.recalModal?.close();
    };
}

declare global {
    interface HTMLElementTagNameMap {
        'invalid-recalculate-modal': InvalidRecalculateModal;
    }
}
