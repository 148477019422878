export const PREFIX = 'Geocode: ';

export const INVALIDATE_GEOCODE = `${PREFIX}INVALIDATE_GEOCODE`;
/**
 * Action to notify user there is a problem with the geocode query
 * @return {Object}
 */
export const invalidateGeocode = () => ({ type: INVALIDATE_GEOCODE });

export const REQUEST_GEOCODE_SUCCESS = `${PREFIX}REQUEST_GEOCODE_SUCCESS`;
/**
 * Success callback action for fetch geocode
 * @param {Object} data
 * @return {Object}
 */
export const requestGeocodeSuccess = (data) => ({
    type: REQUEST_GEOCODE_SUCCESS,
    data,
});

export const REQUEST_GEOCODE_FAILED = `${PREFIX}REQUEST_GEOCODE_FAILED`;
/**
 * Error callback action for fetch geocode
 * @param {Object} error
 * @return {Object}
 */
export const requestGeocodeFailed = (error) => ({
    type: REQUEST_GEOCODE_FAILED,
    error,
});
