import { css } from 'lit-element';

export default css`
    :host {
        --overlay-component__border-radius: 0 0 10px 10px;
        --overlay-component__box-shadow: 0 10px 70px rgba(8, 36, 70, 0.12);
        --overlay-component__background: var(--ew-white);
    }
    .dropdown {
        position: relative;
        display: flex;
    }

    #overlay {
        position: fixed;
        left: 0;
        top: 0;
        width: 100vw;
        height: 100vh;
        z-index: 100;
    }

    slot[name='sub-menu'] {
        display: none;
        opacity: 0;
        position: fixed;
        background: var(--overlay-component__background);
        box-shadow: var(--overlay-component__box-shadow);
        border-radius: var(--overlay-component__border-radius);
        font-size: 14px;
        z-index: 200;
        border-top: 2px solid #e1e1e1;
    }

    slot[name='sub-menu'].right {
        min-height: 111px;
    }

    slot[name='sub-menu'].left {
        min-height: 111px;
    }

    slot[name='sub-menu']::slotted(*) {
        border-bottom: 1px solid var(--ew-gray-light);
    }

    slot[name='sub-menu']::slotted(*:last-of-type) {
        border-bottom: none;
    }

    slot[name='sub-menu'].open {
        opacity: 1;
        -webkit-animation: fadeIn 300ms;
        animation: fadeIn 300ms;
        display: block;
    }

    slot[name='sub-menu'].close {
        -webkit-animation: fadeOut 300ms;
        animation: fadeOut 300ms;
    }

    .dropdown[error] {
        border: 1px solid var(--ew-red);
        border-radius: 5px;
    }
`;
