import { PolymerElement, html } from '@polymer/polymer/polymer-element';
import { provider } from '@weavelab/frontend-connect';

import { formSlotInjector } from 'helpers/formInjector';
import pcss from './style.pcss';
import css from '../styles.pcss';
import settingsEditCss from '../../settings-edit.pcss';
import template from './template.html';

/**
 * Login class
 * @namespace SettingsEditPassword
 * @class SettingsEditPassword
 * @extends PolymerElement
 */
export default class SettingsEditPassword extends formSlotInjector('form')(
    PolymerElement,
) {
    /**
     * Gets properties of class
     */
    static get properties() {
        return {};
    }

    /**
     * Gets template of class
     */
    static get template() {
        const cssTemplate = document.createElement('template');
        cssTemplate.innerHTML =
            provider.styles(css) + provider.styles(settingsEditCss);
        const pcssTemplate = document.createElement('template');
        pcssTemplate.innerHTML = provider.styles(pcss);
        const htmlTemplate = document.createElement('template');
        htmlTemplate.innerHTML = template;
        return html` <style include="iron-flex">
                ${cssTemplate}
                ${pcssTemplate}
            </style>
            ${htmlTemplate}`;
    }
}

window.customElements.define('settings-edit-password', SettingsEditPassword);
