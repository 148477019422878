import { takeLatest, put, ForkEffect } from 'redux-saga/effects';
import { ErrorAction } from 'goagen-js-resource';
import {
    createVacation,
    createWorkSchedule,
    deleteVacation,
    getScheduleVacationsList,
    getCurrentSchedule,
    getListWorkdays,
} from '../async-reducers/schedule';

import { settleMonthlyWorkdaysSchedule } from '../store';

const getSnapshotID = (): string | undefined => {
    const state = window.store.getState();
    if (state && state.jwtSnapshots) {
        return state.jwtSnapshots.selectedSnapshot.id;
    }
    return undefined;
};

/**
 * Watcher for REQUEST_WORK_SCHEDULES action
 */
export function* watchVactionRefetchWorkSchedule() {
    yield takeLatest(
        createVacation.actionTypeMap.callback,
        function* (): Iterator<any> {
            const snapshotID = getSnapshotID();
            if (snapshotID) {
                yield put(getScheduleVacationsList.run(snapshotID));
                yield put(getCurrentSchedule.run(snapshotID));
            }
        },
    );
}

/**
 * Watcher for CREATE_WORK_SCHEDULE action
 */
export function* watchRefetchWorkSchedule() {
    yield takeLatest(
        createWorkSchedule.actionTypeMap.callback,
        function* (): Iterator<any> {
            const snapshotID = getSnapshotID();
            if (snapshotID) {
                window.displayMessage('Je rooster is succesvol ingesteld.');
                yield put(getCurrentSchedule.run(snapshotID));
            }
        },
    );
}

/**
 * Watcher for DELETE_VACATION
 */
export function* watchDeleteVacation() {
    yield takeLatest(
        deleteVacation.actionTypeMap.callback,
        function* (): Iterator<any> {
            const snapshotID = getSnapshotID();
            if (snapshotID) {
                yield put(getScheduleVacationsList.run(snapshotID));
                yield put(getCurrentSchedule.run(snapshotID));
            }
        },
    );
}

/**
 * Watcher for settleMonthlyWorkdaysSchedule
 */
export function* watchEnergysplitter(): IterableIterator<ForkEffect> {
    yield takeLatest(
        settleMonthlyWorkdaysSchedule.REQUEST_SUCCESS,
        getWorkdays,
    );
    yield takeLatest(
        settleMonthlyWorkdaysSchedule.REQUEST_FAILED,
        fetchingWorkdaysFailed,
    );
}
function* getWorkdays(_: any) {
    const snapshot = window.store.getState().jwtSnapshots.selectedSnapshot;
    if (snapshot) {
        window.store.dispatch(getListWorkdays.run(snapshot, false));
        window.displayMessage('De maandelijkse uren zijn ingedient is gelukt');
    }
}
function* fetchingWorkdaysFailed(err: ErrorAction) {
    console.warn('Indienen maandelijkse uren ging fout:', err.error);
    window.displayMessage(
        'Bij het indienden van de maandelijkse uren ging iets fout, probeer het later nog eens',
    );
}
