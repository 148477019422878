import { customElement, property } from 'lit-element';

import { css } from '@lion/core';
import { LionRadio } from '@lion/radio-group';
import '@atoms/HvRadioLabel';
import '@atoms/HvRadioButton';

@customElement('hv-radio')
export class HvRadio extends LionRadio {
    @property({ type: Boolean })
    recommend: boolean = false;

    get slots() {
        return {
            ...super.slots,
            input: () => {
                const weaveRadioButton =
                    document.createElement('hv-radio-button');
                if (weaveRadioButton) {
                    (weaveRadioButton as HTMLElement).setAttribute(
                        'checked',
                        String(this.checked),
                    );
                }
                return weaveRadioButton;
            },
            label: () => {
                const weaveRadioLabel =
                    document.createElement('hv-radio-label');
                if (weaveRadioLabel) {
                    (weaveRadioLabel as HTMLElement).setAttribute(
                        'recommend',
                        String(this.recommend),
                    );
                    (weaveRadioLabel as HTMLElement).setAttribute(
                        'active',
                        String(this.checked),
                    );
                }
                return weaveRadioLabel;
            },
        };
    }

    static get styles() {
        return [
            ...super.styles,
            css`
                :host {
                    --hv-radio-border: 1px solid #e5e5e5;
                    --hv-radio-border--checked: 1px solid #3aad3a;
                    --hv-radio-background: #f2faf2;
                    --hv-radio-margin-vertical: 0;
                    --hv-radio-margin-horizontal: 0;
                    --hv-radio-padding-vertical: 10px;
                    --hv-radio-padding-horizontal: 5px;
                    border: var(--hv-radio-border);
                    margin: var(--hv-radio-margin-vertical)
                        var(--hv-radio-margin-horizontal);
                    padding: var(--hv-radio-padding-vertical)
                        var(--hv-radio-padding-horizontal);
                    display: flex;
                    align-items: center;
                }
                :host([checked]) {
                    border: var(--hv-radio-border--checked);
                    background: var(--hv-radio-background);
                }
            `,
        ];
    }

    constructor() {
        super();

        this.addEventListener('click', (ev) => {
            ev.stopPropagation();
            this.dispatchEvent(
                new Event('user-input-changed', {
                    bubbles: true,
                    composed: true,
                }),
            );
        });
    }
}

declare global {
    interface HTMLElementTagNameMap {
        'hv-radio': HvRadio;
    }
}
