import { Validator } from '@lion/form-core';

export class Equals extends Validator {
    static get validatorName() {
        return 'Equals';
    }

    execute(value: any, thunk = this.param) {
        return typeof thunk !== 'function' || thunk() !== value;
    }
}
