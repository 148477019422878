/* eslint-disable no-unused-vars */

interface ResetHook {
    passwordInputChanged: (password: string) => void;
    onReset: (password: string, repeat: string) => void;
}

export const resetHook: ResetHook = {
    passwordInputChanged: () => {
        throw new Error('passwordInputChanged not implemented');
    },
    onReset: (): void => {
        throw new Error('onReset not implemented');
    },
};
