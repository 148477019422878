import { provider } from '@weavelab/frontend-connect';
import { PolymerElement, html } from '@polymer/polymer/polymer-element';

import css from './dropdown-menu.pcss';
import template from './dropdown-menu.html';

import '@polymer/iron-flex-layout/iron-flex-layout-classes';
import { faCarrot } from '@fortawesome/free-solid-svg-icons/faCarrot';

/**
 * EzDropdownMenu class
 */
export default class EzDropdownMenu extends PolymerElement {
    /**
     * Gets properties of class
     */
    static get properties() {
        return {
            label: {
                reflectToAttribute: true,
                type: String,
                value: null,
            },
            opened: {
                type: Boolean,
                value: false,
                notify: true,
            },
            selected: {
                reflectToAttribute: true,
                type: Object,
                notify: true,
            },
            items: {
                type: Array,
                value: [],
                observer: '_itemsChanged',
            },
            disabled: {
                type: Boolean,
                value: false,
            },
        };
    }

    /**
     * Constructor of epic flow
     */
    constructor() {
        super();
        this.asset = provider.asset;
        /** @type {Array} */
        this.items = [];
    }

    /**
     *
     */
    ready() {
        super.ready();
        document.body.addEventListener('click', this.closeChecker.bind(this));
    }

    /**
     * Closes the checker
     * @param {any} e event
     */
    closeChecker(e) {
        // prevent undefined
        if (e == null || (e && e.path == null)) {
            return;
        }
        if (e.model == null && e.path[0].closest('#dropdown-wrapper') == null) {
            this.close();
        }
    }

    /**
     * Opens dropdown
     */
    open() {
        this.opened = true;
    }

    isDisabled = (disabled) => (disabled ? 'disabled' : '');

    /**
     * Closes dropdown
     */
    close() {
        this.opened = false;
    }

    /**
     * Set selected item
     * @param {any} e selected item
     */
    itemClicked(e) {
        if (e.model == null) return;
        this.set('selected', e.model.item);
        this.close();
    }

    /**
     * Show place holder
     * @param {any} selected
     * @param {Boolean} opened
     * @return {Boolean} indicating if place holder should be shown
     */
    showPlaceholder = (selected, opened) => selected == null || opened;

    /**
     * Show label
     * @param {any} selected
     * @param {Boolean} opened
     * @return {Boolean} indicating iflabel should be shown
     */
    showLabel = (selected, opened) => selected == null || opened;

    /**
     * Show item
     * @param {any} item
     * @param {any} selected
     * @param {Boolean} opened
     * @return {Boolean} indicating if item should be shown
     */
    showItem = (item, selected, opened) =>
        item === selected || (opened && item.name);

    hideCarrot = (items, opened) => !items.length || opened;

    /**
     * Called when item changed
     */
    _itemsChanged() {
        this.opened = false;
        if (this.items.indexOf(this.selected) === -1) {
            this.selected =
                this.items && this.items.length ? this.items[0] : null;
        }
    }

    /**
     * Class setter for when opened
     * @param {Boolean} opened
     * @return {String}
     */
    _classOpened = (opened) => (opened ? ' opened' : '');

    /**
     * Gets template of class
     */
    static get template() {
        const cssTemplate = document.createElement('template');
        cssTemplate.innerHTML = provider.styles(css);
        const htmlTemplate = document.createElement('template');
        htmlTemplate.innerHTML = template;
        return html`<style include="iron-flex">
                ${cssTemplate}
            </style>
            ${htmlTemplate}`;
    }
}

window.customElements.define('dropdown-menu', EzDropdownMenu);
