export const productListComponent = 'product-list';
export const priceProposalModal = 'stuur-mij-het-prijsvoorstel';

type productListComponents =
    | typeof productListComponent
    | typeof priceProposalModal;

export const userInteractionEvent = 'user_interaction';

type eventTypes = 'button' | 'select' | 'link' | 'form';
type eventActionTypes = 'change' | 'click' | 'select';
type eventActionValueTypes = 'valid' | 'invalid' | string;

type productListFields =
    | 'kies'
    | 'doorlaatwaarde-stroom'
    | 'doorlaatwaarde-gas'
    | 'opnieuw-berekenen'
    | 'stuur-prijsvoorstel'
    | 'bekijk-prijsopbouw'
    | 'naam'
    | 'e-mailadres'
    | 'telefoonnummer'
    | 'verstuur';

export interface ProductListEventType {
    event: typeof userInteractionEvent;
    type: eventTypes;
    field: productListFields;
    component: productListComponents;
    action: eventActionTypes;
    actionValue: eventActionValueTypes;
}

export const newProductListEvent = (
    component: productListComponents,
    type: eventTypes,
    field: productListFields,
    action: eventActionTypes,
    actionValue: eventActionValueTypes = '',
): ProductListEventType => ({
    event: userInteractionEvent,
    type,
    component,
    field,
    action,
    actionValue,
});
export const ProductListEventInputChanged = (
    actionValue: string,
    productListField: productListFields,
    component: productListComponents = 'product-list',
): ProductListEventType =>
    newProductListEvent(
        component,
        'form',
        productListField,
        'change',
        actionValue,
    );

export const chooseButtonClicked = (): ProductListEventType =>
    newProductListEvent('product-list', 'button', 'kies', 'click');
export const chooseCapacityChanged = (
    actionValue: string,
    field: 'doorlaatwaarde-stroom' | 'doorlaatwaarde-gas',
): ProductListEventType =>
    newProductListEvent('product-list', 'select', field, 'select', actionValue);
export const chooseRecalculateClicked = (): ProductListEventType =>
    newProductListEvent('product-list', 'link', 'opnieuw-berekenen', 'click');
export const chooseSendOfferClicked = (): ProductListEventType =>
    newProductListEvent(
        'product-list',
        'button',
        'stuur-prijsvoorstel',
        'click',
        'valid',
    );
export const chooseOpenDetailsClicked = (): ProductListEventType =>
    newProductListEvent(
        'product-list',
        'button',
        'bekijk-prijsopbouw',
        'click',
        'valid',
    );

export const PriceOfferSend = (
    actionValue: string,
    component: productListComponents = 'stuur-mij-het-prijsvoorstel',
): ProductListEventType =>
    newProductListEvent(component, 'form', 'verstuur', 'click', actionValue);
