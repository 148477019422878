import '../behavior/error-handling/ErrorHandling';
import {
    LitElement,
    PropertyValues,
    TemplateResult,
    customElement,
    html,
    property,
    query,
} from 'lit-element';
import { ifDefined } from 'lit-html/directives/if-defined';
import { whitelabel } from '@weavelab/whitelabel';

import { provider } from '@weavelab/frontend-connect';
import sharedStyles from '../../../../sharedStyles';
import style from './style';

type themeOptions = '' | 'light' | 'dark';

@customElement('checkbox-component')
@whitelabel({ name: 'checkbox-component' })
export class CheckboxComponent extends LitElement {
    @property({ type: Boolean })
    public checked: boolean = false;

    @property({ type: Object })
    public item?: object;

    @query('.checkbox')
    public checkbox?: HTMLInputElement;

    @property({
        type: Boolean,
        reflect: true,
    })
    public value?: boolean;

    @property()
    public theme: themeOptions = '';

    @property({ type: Boolean })
    public required: boolean = false;

    @property({
        attribute: 'error-message',
        reflect: true,
    })
    public errorMessage: string = `Verplicht`;

    @property({ type: Boolean })
    public error: boolean = false;

    @property({ attribute: 'hide-text', type: Boolean })
    public hideText: boolean = false;

    @property({ type: String })
    public checkIconLocation?: string;

    private asset?: any;

    @whitelabel() // Allow styles to be injected from theme
    static get styles() {
        return [sharedStyles, style];
    }

    constructor() {
        super();
        this.addEventListener('click', (): void => {
            this.value = !this.value;
            this.dispatchEvent(
                new CustomEvent('clicked', {
                    bubbles: true,
                    composed: true,
                    detail: { element: this.value },
                }),
            );
        });
    }

    protected render = (): TemplateResult => html`
        <div class="checkbox-wrapper">
            <div id="checkbox">
                ${this.value
                    ? html`
                          <img
                              width="10"
                              height="8"
                              .src="${this.checkIconLocation
                                  ? this.checkIconLocation
                                  : 'assets/icons/check.svg'}"
                          />
                      `
                    : null}
            </div>
            ${!this.hideText
                ? html`
                      <p>
                          <slot theme=${ifDefined(this.theme)}></slot>
                      </p>
                  `
                : null}
        </div>
        <error-handling
            .error=${this.error}
            error-message=${this.errorMessage}
        ></error-handling>
    `;

    protected firstUpdated() {
        this.asset = provider.asset;
        this.checkIconLocation = this.asset('icons/check.svg');
    }

    public updated(changedProperties: PropertyValues): void {
        if (changedProperties.has('value') && this.value !== undefined) {
            if (this.value) {
                this.classList.add('selected');
            } else {
                this.classList.remove('selected');
            }
            /**
             * This function binds details to Event
             * The properties needed to read the values of the input where bind in properties
             * The return is a Event and the interface CustomInputEvent is needed for typechecking.
             */
            const changeEvent: Event = new CustomEvent('form-element-changed', {
                bubbles: true,
                composed: true,
                detail: {
                    element: this,
                    id: this.id,
                    required: this.required,
                    validity: this.validate(),
                    value: this.value,
                },
            });
            this.dispatchEvent(changeEvent);
        }
    }

    public validate = (): boolean => {
        this.error = false;
        // When the value is required or value changed to null or undefined it validates the value.
        if (this.required || this.value != null) {
            this.error = this.value == null;
        }
        return !this.error;
    };
}

declare global {
    interface HTMLElementTagNameMap {
        'checkbox-component': CheckboxComponent;
    }
}
