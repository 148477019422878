import {
    LitElement,
    PropertyValues,
    customElement,
    html,
    property,
    query,
} from 'lit-element';
import { TemplateResult } from 'lit-html';

import { checkIfStringValueExistWithinAStringArray } from '../../helpers/data';
import { DropdownCell } from '../../dropdown-selector-component/DropDownCell';
import style from './style';
import { rendererConverter } from '../../dropdown-selector-component/DropdownSelector';

/**
 * This component creates a dropdown menu with slots menu and content.
 * This component also watch if the dropdown is within the screen,
 * if not it adjust the position of the content.
 */
@customElement('overlay-component')
export class OverlayComponent extends LitElement {
    @property({ type: Array })
    public items?: any[];

    @property({ type: Array })
    public itemsSelected?: any[];

    @property({
        attribute: 'item-selected',
        reflect: true,
    })
    public itemSelected?: string | string[];

    @property({ attribute: 'item-label' })
    public itemLabel?: string;

    @property({ attribute: 'item-value' })
    public itemValue?: string;

    @property({ attribute: 'items-prop' })
    public itemsProp?: string;

    @property({ attribute: 'position-submenu' })
    public positionSubmenu: 'right' | 'left' | 'bottom' = 'right';

    @property({ type: Number })
    public width: number = 245;

    @property({ attribute: 'component-width', type: Number })
    public componentWidth: number = 22;

    @property({ type: Boolean, reflect: true })
    public state: boolean = false;

    @property({ attribute: 'close-on-click', type: Boolean, reflect: true })
    public closeOnClick?: boolean;

    @property({
        attribute: 'padding-top-and-bottom',
        type: Boolean,
    })
    public paddingTopAndBottom?: boolean;

    @query('slot[name="sub-menu"]')
    public subMenuSlot?: HTMLSlotElement;

    @query('#overlay')
    public overlay?: HTMLElement;

    @query('.dropdown')
    public dropdownElement?: HTMLSlotElement;

    @query('slot[name="menu"]')
    public menuElement?: HTMLSlotElement;

    @query('slot.dropdown-content')
    public slotElement?: HTMLSlotElement;

    @property({ converter: rendererConverter })
    public onRender?: (value: any, data: any) => TemplateResult;

    protected prevValue: any;

    public disconnectedCallback() {
        this.state = false;
        if (this.slotElement?.hasAttribute('open')) {
            this.slotElement!.classList.remove('open');
            this.slotElement!.classList.add('close');
        }
        super.disconnectedCallback();
    }

    public connectedCallback() {
        super.connectedCallback();
        this.state = false;
        if (this.slotElement?.hasAttribute('open')) {
            this.slotElement!.classList.remove('open');
            this.slotElement!.classList.add('close');
        }
    }

    static get styles() {
        return [style];
    }

    protected render = (): TemplateResult => {
        return html`
            <style>
                :host {
                    display: block;
                }

                slot[name='sub-menu'] {
                    width: ${this.width ? this.width : 245}px;
                }
            </style>
            <div class="dropdown">
                <slot
                    @mouseover="${this.checkContentPosition}"
                    name="menu"
                ></slot>
                <slot
                    style=${this.positionSubmenu}
                    @slotchange=${this.handleSubMenuChange}
                    @click=${this.handleInsideClick}
                    name="sub-menu"
                ></slot>
                <div id="overlay"></div>
            </div>
        `;
    };

    public handleInsideClick(event: Event): void {
        if (
            event &&
            event.target &&
            (event.target as Element).nodeName.toLowerCase().includes('button')
        ) {
            if (this.slotElement) {
                this.slotElement!.classList.remove('open');
                this.slotElement!.classList.add('close');
            }
            if (this.subMenuSlot) {
                this.subMenuSlot!.classList.add('close');
                this.subMenuSlot!.classList.remove('open');
            }
            this.state = false;
        }
    }

    public handleSubMenuChange(event: Event) {
        if (this.closeOnClick) {
            const slot: HTMLSlotElement | null =
                event.target as HTMLSlotElement;
            if (slot) {
                const childs = slot.assignedElements();
                if (childs && childs.length > 0) {
                    childs.forEach((child) => {
                        child.addEventListener('click', (e) => {
                            this.changeDropDownState(e);
                        });
                    });
                }
            }
        }
    }

    public changeDropdownState(e: Event) {
        const { subMenuSlot, state } = this;
        if (state) {
            this.itemSelected = (e.target! as HTMLElement).innerText;

            // when an image has been selected the parent text will be set to itemSelected
            if (this.itemSelected === '') {
                this.itemSelected = (
                    e.target! as HTMLElement
                ).parentElement!.innerText;
            }
            this.itemLabel !== undefined
                ? this.customValueEvent((e.target! as DropdownCell).item)
                : this.customValueEvent();
            this.slotElement!.classList.remove('open');
            this.slotElement!.classList.add('close');
            subMenuSlot!.classList.add('close');
            subMenuSlot!.classList.remove('open');
            this.state = false;
        } else {
            subMenuSlot!.classList.add('open');
            subMenuSlot!.classList.remove('close');
            this.slotElement!.classList.add('open');
            this.slotElement!.classList.remove('close');
            this.state = true;
        }
    }

    // Default way of closing the overlay component.
    public changeDropDownState = (e: Event): void => {
        e.preventDefault();
        const { subMenuSlot, state } = this;
        if (subMenuSlot) {
            if (state) {
                subMenuSlot.classList.add('close');
                subMenuSlot.classList.remove('open');
                this.state = false;
            } else {
                subMenuSlot.classList.add('open');
                subMenuSlot.classList.remove('close');
                this.state = true;
            }
        }

        this.checkContentPosition();
        this.dispatchEvent(new CustomEvent('overlay-closed'));
    };

    /**
     * Function to check if the dropdown styling is within the window.
     * Else adjust the dropdown to fit within the window.
     */
    public checkContentPosition = (): void => {
        const { subMenuSlot, dropdownElement } = this;

        if (dropdownElement && subMenuSlot) {
            subMenuSlot!.classList.add(this.positionSubmenu);
            // Get the positions where the components are on the screen
            const menu: ClientRect = this.getBoundingClientRect();
            const elementStart: number =
                dropdownElement.getBoundingClientRect().top + window.scrollY;
            const elementEnd: number =
                dropdownElement.getBoundingClientRect().bottom + window.scrollY;
            const elementRight: number =
                dropdownElement.getBoundingClientRect().right + window.scrollX;
            const elementLeft: number =
                dropdownElement.getBoundingClientRect().left + window.scrollX;

            // TODO: fix issue when the menu has padding.
            // assignedElements()[0]

            // Get the height of the window and the dropdown.
            const windowHeight: number = window.innerHeight;
            const contentHeight: number = subMenuSlot.offsetHeight;
            const contentWidth: number = subMenuSlot.offsetWidth;
            const totalElementHeight: number = elementStart + contentHeight;
            const paddingMenu: number = 0;
            const leftWallSpacing: number =
                elementRight - contentWidth - this.componentWidth - paddingMenu;

            if (this.positionSubmenu === 'right') {
                this.subMenuSlot!.style.top = `${elementStart}px`;
                this.subMenuSlot!.style.left = `${
                    elementRight + paddingMenu
                }px`;
            } else if (this.positionSubmenu === 'bottom') {
                this.subMenuSlot!.style.top = `${elementEnd + paddingMenu}px`;
                this.subMenuSlot!.style.left = `${
                    elementRight - contentWidth
                }px`;
            } else {
                this.subMenuSlot!.style.top = `${elementStart}px`;
                this.subMenuSlot!.style.left = `${
                    elementLeft - contentWidth - paddingMenu
                }px`;
            }

            /**
             * When dropdown go outside of the window on the left side
             * Place the drop down on the right side.
             */
            if (menu.left < 0) {
                subMenuSlot.style.left = `0`;
            }

            /**
             * When dropdown go outside of the window on the right side
             * Place the drop down on the left side.
             */
            const submenuRight: number =
                this.subMenuSlot?.getBoundingClientRect().right!;
            if (
                submenuRight > window.innerWidth &&
                this.positionSubmenu !== 'bottom'
            ) {
                subMenuSlot!.classList.remove(this.positionSubmenu);
                this.subMenuSlot!.style.top = `${elementStart}px`;
                subMenuSlot.style.left = `${leftWallSpacing}px`;
            }

            /**
             * When the dropdown go outside of the window on the bottom side.
             * Returns the difference that the dropdown need to be higher then the side of the menu.
             */
            if (totalElementHeight + menu.height > windowHeight) {
                subMenuSlot.style.top = `${
                    windowHeight - contentHeight - (windowHeight - elementEnd)
                }px`;
            }

            if (this.paddingTopAndBottom) {
                subMenuSlot.style.paddingTop = '10px';
                subMenuSlot.style.paddingBottom = '10px';
            }
        }
    };

    protected updated(changedProperties: PropertyValues) {
        if (changedProperties.has('state') && this.overlay) {
            this.state
                ? (this.overlay.style.display = 'block')
                : (this.overlay.style.display = 'none');
        }
    }

    // needed to see if itemSelected has been set to fill the menu value.
    protected firstUpdated(changedProperties: PropertyValues) {
        changedProperties.forEach((_, propName) => {
            if (propName === 'itemSelected') {
                this.customValueEvent();
            }
        });

        if (this.menuElement) {
            this.menuElement!.addEventListener('click', (event: Event) =>
                this.changeDropDownState(event),
            );
        }
        if (this.overlay) {
            this.overlay!.addEventListener('click', (event: Event) =>
                this.changeDropDownState(event),
            );
        }

        this.addEventListener(
            'dropdown-selector-cell-active',
            this.changeDropDownSelectorState as EventListener,
        );

        window.addEventListener('resize', this.checkContentPosition);
        // needed to close the overlay when a modal component has been opened.
        addEventListener('close-overlay', (e: Event) => {
            if (this.state) {
                this.changeDropDownState(e);
            }
        });
    }

    public changeDropDownSelectorState = (e: CustomEvent) => {
        const detail = e.detail;
        // check if itemSelected has been set if not stay default.
        if (
            (detail.state && this.itemValue == null) ||
            (detail.state && detail.value[this.itemValue!] !== this.prevValue)
        ) {
            this.updateSubmenuSlot();
            this.prevValue = detail.value[this.itemValue!];
        }
        const newItemSelected = detail.active ? detail.value : undefined;
        if (Array.isArray(this.itemSelected)) {
            if (newItemSelected != null) {
                if (
                    !checkIfStringValueExistWithinAStringArray(
                        newItemSelected,
                        this.itemSelected,
                    )
                ) {
                    this.itemSelected = [...this.itemSelected, newItemSelected];
                }
            } else {
                // remove the item from the array.
                const newItemArray = this.itemSelected.filter(
                    (item: any) => item !== detail.value,
                );
                this.itemSelected = [...newItemArray];
            }
        } else {
            // when item is active it sets the value else set undefined for the placeholder.
            this.itemSelected =
                newItemSelected != null
                    ? this.onRender != null
                        ? this.onRender(newItemSelected, newItemSelected)
                        : newItemSelected
                    : undefined;
        }
        this.dispatchEvent(
            new CustomEvent('selected-value', {
                detail: {
                    items: detail.element.item,
                    element: detail.element,
                },
            }),
        );
    };

    public updateSubmenuSlot = (): void => {
        const { subMenuSlot, state } = this;
        if (state || this.itemSelected === undefined) {
            this.slotElement!.classList.remove('open');
            this.slotElement!.classList.add('close');
            subMenuSlot!.classList.add('close');
            subMenuSlot!.classList.remove('open');
            this.state = false;
        } else {
            subMenuSlot!.classList.add('open');
            subMenuSlot!.classList.remove('close');
            this.slotElement!.classList.add('open');
            this.slotElement!.classList.remove('close');
            this.state = true;
        }
    };

    private customValueEvent = (item?: any): void => {
        if (item === undefined) {
            if (this.items && this.itemLabel) {
                this.items.forEach((it) => {
                    // tslint:disable-next-line:no-unused-expression
                    if (it[this.itemLabel!] === this.itemSelected) {
                        item = it;
                    }
                });
            }
        }
        this.dispatchEvent(
            new CustomEvent('selected-value', {
                detail: {
                    value: this.itemValue
                        ? item[this.itemValue]
                        : item != null
                        ? item
                        : this.itemSelected,
                    item: item != null ? item : this.itemSelected,
                },
            }),
        );
    };
}
