import '../behavior/error-handling/ErrorHandling';

import { LitElement, customElement, html, property } from 'lit-element';
import { TemplateResult } from 'lit-html';
import style from './Style';
import { whitelabel } from '@weavelab/whitelabel';

/**
 * This documentation comment will be shown when you hover your component imported in other files
 */
@customElement('placeholder-component')
@whitelabel({ name: 'placeholder-component' })
export class PlaceholderComponent extends LitElement {
    @property()
    public placeholder?: string;

    @property({ reflect: true })
    public value?: string | string[];

    @property({ type: Boolean })
    public error: boolean = false;

    @property({ type: Boolean })
    public required: boolean = false;

    @property({ type: Array })
    public items?: any;

    @property({ type: String })
    public itemLabel?: string;

    @property({ attribute: false })
    public onRender?: (value: any, data: any) => TemplateResult;

    @property({
        attribute: 'error-message',
        reflect: true,
    })
    public errorMessage: string = `Verplicht selecteer een optie.`;

    @whitelabel() // Allow styles to be injected from theme
    static get styles() {
        return [style];
    }

    protected render = (): TemplateResult => {
        return html`
            <div>${this.renderer(this.value, this.items)}</div>
            <error-handling
                .error=${this.error}
                error-message=${this.errorMessage}
            ></error-handling>
        `;
    };

    public select = (): any => {
        return this.value;
    };

    protected renderer = (
        value: string | object | undefined,
        items: any,
    ): TemplateResult => {
        if (this.onRender !== undefined) {
            try {
                if (this.itemLabel) {
                    return this.onRender(this.placeholder, items);
                } else {
                    throw new Error('Item label must be set!');
                }
            } catch (error) {
                console.warn('Err rendering custom function', error);
            }
        }
        return value != null
            ? html` ${this.renderValue(value)}`
            : this.returnPlaceholder();
    };

    // this function checks if value is an array, string or any and returns that value.
    private renderValue = (value: any): TemplateResult => {
        if (Array.isArray(value)) {
            if (value.length === 0) {
                return this.returnPlaceholder();
            }
            return html`<p>
                ${value.map((val, i, arr) => {
                    if (arr.length - 1 === i) {
                        // last one
                        return `${val}`;
                    } else {
                        // not last one
                        return `${val}, `;
                    }
                })}
            </p>`;
        }
        if (typeof value === 'string' && value === '') {
            return this.returnPlaceholder();
        }
        return html`<p>${value}</p>`;
    };

    // returns the html for the placeholder value.
    private returnPlaceholder = (): TemplateResult => {
        return html`<p>${this.placeholder}</p>`;
    };
}

declare global {
    interface HTMLElementTagNameMap {
        'placeholder-component': PlaceholderComponent;
    }
}
