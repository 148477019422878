import { PolymerElement, html } from '@polymer/polymer/polymer-element';
import { provider } from '@weavelab/frontend-connect';

// @ts-ignore
import watch from 'redux-watch';

// routing
import { Router } from '@vaadin/router';
import { routePaths } from 'helpers/routing';
import { uiPriceDetailsClicked, uiProductListInteraction } from '@actions/ui';
import { changeOrder } from 'gtm-middleware/orderOverview';
import { NumberToPrice } from '../../helpers/number';

import css from './productgroup-overview-display.pcss';
import template from './productgroup-overview-display.html';
import { store } from '../../../../../store';
import '../../product-details/product-details';
import 'atoms/Popup';

const themeSettings = provider.settings('productgroup-overview-display');

/**
 * Order menu class
 */
export default class EzProductgroupOverview extends PolymerElement {
    /**
     * Gets properties of class
     */
    static get properties() {
        return {
            collective: {
                type: String,
            },
            productGroup: {
                type: Object,
            },
            hideChoice: {
                type: Boolean,
                value: false,
            },
        };
    }

    /**
     * Constructor of reset password
     */
    constructor() {
        super();

        /** @type {Boolean} */
        this.details = false;

        /** @type {String} */
        this.themeTitle =
            themeSettings && themeSettings.title ? themeSettings.title : '';
    }

    /**
     * On element ready
     */
    ready() {
        super.ready();
        /** @type {Object} */
        const state = store.getState();

        this.collectives =
            state.collectives && Array.isArray(state.collectives.data)
                ? state.collectives.data
                : [];
        const watchCollectives = watch(store.getState, 'collectives.data');
        store.subscribe(
            watchCollectives(
                /**
                 * @param {Object} collectives
                 * @return {void}
                 */
                (collectives) => {
                    this.collectives = Array.isArray(collectives)
                        ? collectives
                        : [];
                },
            ),
        );
    }

    /**
     * Open the details view
     */
    _closeDetails() {
        this.details = false;
    }

    /**
     * Close the details view
     */
    _openDetails() {
        store.dispatch(uiPriceDetailsClicked.run());
        this.details = true;
    }

    /**
     * Convert number to display price
     * @param {Number} price
     * @return {String}
     */
    _numberToPrice(price) {
        return !Number.isNaN(Number(price))
            ? NumberToPrice(price, null, 2, false)
            : '';
    }

    /**
     * return first part of price
     * @param {Number} price
     * @return {String} first part of price
     */
    _getFirstPricingPart(price) {
        if (price == null) {
            console.error('Total price is null');
            return '';
        }
        return NumberToPrice(price, null, 2, false).split(',')[0].trim();
    }

    /**
     * return second part of price
     * @param {Number} price
     * @return {String} second part of price
     */
    _getDecimalPricingPart(price) {
        if (!price) {
            return '';
        }
        return NumberToPrice(price, null, 2, false).split(',')[1].trim();
    }

    /**
     * Reroute user back to product picker
     */
    _toProductList = () => {
        store.dispatch(
            uiProductListInteraction.run(changeOrder('bestelling-wijzigen')),
        );
        Router.go(routePaths.customerProductList);
    };

    /**
     * Get collective from collectives based on collective id
     * @param {String} collective
     * @param {Array} collectives
     * @return {Object}
     */
    _getCollective = (collective, collectives) =>
        collectives.find((c) => c.id === collective);

    /**
     * Get collective image from collectives based on collective id
     * @param {String} collective
     * @return {String}
     */
    _getCollectiveSrc = (collective) => {
        const found = collective;
        // @ts-ignore
        return found && found.logo_url ? found.logo_url : '';
    };

    /**
     * Gets template of class
     */
    static get template() {
        const cssTemplate = document.createElement('template');
        cssTemplate.innerHTML = provider.styles(css);
        const htmlTemplate = document.createElement('template');
        htmlTemplate.innerHTML = template;
        return html`<style include="iron-flex">
                ${cssTemplate}
            </style>
            ${htmlTemplate}`;
    }
}

window.customElements.define(
    'productgroup-overview-display',
    EzProductgroupOverview,
);
