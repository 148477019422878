import { css } from 'lit-element';

export default css`
    :host {
        --placeholder-max-width: 240px;
        --placeholder-margin-top: 0;
        --placeholder-spacing-vertical: 8px;
        --placeholder-spacing-horizontal: 0;
        --placeholder-title-font-weight: 600;
        --placeholder-title-color: #777;
        --placeholder-icon-color: #aaa;
        --placeholder-icon-size: 50px;
        --placeholder-subtitle-font-size: 14px;
    }
    div {
        margin-top: var(--placeholder-margin-top);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        max-width: var(--placeholder-max-width);
    }
    p {
        padding: 0;
        margin: var(--placeholder-spacing-vertical)
            var(--placeholder-spacing-horizontal);
    }
    .title {
        font-weight: var(--placeholder-title-font-weight);
        color: var(--placeholder-title-color);
    }
    .icon {
        color: var(--placeholder-title-color);
        height: var(--placeholder-icon-height);
        width: var(--placeholder-icon-width);
    }
    .subtitle {
        text-align: center;
        font-size: var(--placeholder-subtitle-font-size);
    }
    hv-icon {
        --hv-icon-font-size: var(--placeholder-icon-size);
        --hv-icon-color: var(--placeholder-icon-color);
    }
`;
