import {
    customElement,
    LitElement,
    TemplateResult,
    html,
    css,
    CSSResult,
} from 'lit-element';

@customElement('ez-popup')
export class Popup extends LitElement {
    public static get styles(): CSSResult {
        return css`
            .PopupContainer {
                position: fixed;
                top: 0;
                left: 0;
                display: flex;
                height: 100%;
                width: 100%;
                justify-content: center;
                align-items: center;
                z-index: 1;
            }

            .PopupBackdrop {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                background-color: rgba(0, 0, 0, 0.5);
                z-index: 1;
            }

            .Popup {
                max-height: calc(100% - 40px);
                max-width: calc(100% - 40px);
                z-index: 2;
            }
        `;
    }

    constructor() {
        super();

        document.body.addEventListener('keydown', this.closeHandler);
    }

    public disconnectedCallback = () => {
        document.body.removeEventListener('keydown', this.closeHandler);
        super.disconnectedCallback();
    };

    public render = (): TemplateResult => html`
        <div class="PopupContainer">
            <div class="PopupBackdrop" @click="${this.close}"></div>
            <div class="Popup"><slot></slot></div>
        </div>
    `;

    private readonly close = (): void => {
        this.dispatchEvent(new CustomEvent('close', {}));
    };

    private readonly closeHandler = (e: KeyboardEvent) => {
        if (e.key === 'Escape' || e.key === 'Esc' || e.keyCode === 27) {
            this.close();
        }
    };
}

declare global {
    interface HTMLElementTagNameMap {
        'ez-popup': Popup;
    }
}
