import { routePaths } from 'data-access/router';
import { RouterLocation } from '@vaadin/router';
import { put, takeLatest } from 'redux-saga/effects';
import storage from 'redux-persist/es/storage';
import { UPDATE_ROUTING } from '@actions/app';
import { signIn } from '@async-reducers/authentication';
import { Action } from 'redux';
import {
    setElectricity,
    setElectricityProductionEstimate,
    setElectricityUsageEstimate,
    setGas,
    setGasUsageEstimate,
    setHouseNumber,
    setHouseNumberSuffix,
    setPostalCode,
    setProposition,
} from '../actions/order';

interface routing extends RouterLocation {
    page: string;
}

interface routeAction<D = unknown> extends Action {
    data: D;
    routing: routing;
}

/**
 * Fetches data from geocode with postal codes debounced
 * @param {Object} action
 */
function* authenticateIncomingUser(action: routeAction) {
    console.log('authenticateIncomingUser');

    if (action.routing && action.routing.params && action.routing.params.auth) {
        // Clear persistent state
        storage.removeItem('persist:root');
        // Clear auth
        storage.removeItem('auth');
        const decoded = atob(action.routing.params.auth as string);
        const authDetails = JSON.parse(decoded);
        if (authDetails && authDetails.email && authDetails.password) {
            yield put(signIn.run(authDetails.email, authDetails.password));
        }
    }
}

/**
 * Fills calc tool
 * @param {Object} action
 */
function* fillCalculationTool(action: any) {
    if (action.routing.page.includes(routePaths.customer)) {
        const params = action.routing.params;
        if (params.area_code && params.area_code.length > 0) {
            yield put(<any>setPostalCode(params.area_code));
        }
        if (params.house_number && params.house_number.length > 0) {
            yield put(<any>setHouseNumber(params.house_number));
        }
        if (params.house_addition && params.house_addition.length > 0) {
            yield put(<any>setHouseNumberSuffix(params.house_addition));
        }
        if (params.proposition && params.proposition.length > 0) {
            yield put(<any>setProposition(params.proposition));
        }
        if (params.elec_usage_amount && params.elec_usage_amount.length > 0) {
            yield put(
                <any>(
                    setElectricityUsageEstimate(
                        Math.abs(params.elec_usage_amount),
                    )
                ),
            );
        }
        if (params.gas_usage_amount && params.gas_usage_amount.length > 0) {
            yield put(
                <any>setGasUsageEstimate(Math.abs(params.gas_usage_amount)),
            );
        }
        if (params.elec_prod_amount && params.elec_prod_amount.length > 0) {
            yield put(
                <any>(
                    setElectricityProductionEstimate(
                        Math.abs(params.elec_prod_amount),
                    )
                ),
            );
        }
        if (
            params.has_elec &&
            params.has_elec.length > 0 &&
            params.has_elec === 'true'
        ) {
            yield put(<any>setElectricity(true));
        } else if (
            params.has_elec &&
            params.has_elec.length > 0 &&
            params.has_elec === 'false'
        ) {
            yield put(<any>setElectricity(false));
        }
        if (
            params.has_gas &&
            params.has_gas.length > 0 &&
            params.has_gas === 'true'
        ) {
            yield put(<any>setGas(true));
        } else if (
            params.has_gas &&
            params.has_gas.length > 0 &&
            params.has_gas === 'false'
        ) {
            yield put(<any>setGas(false));
        }
    }
}

/**
 * Watcher for fetching from geocode with postal codes
 */
export function* watchAuthRedirect() {
    yield takeLatest(UPDATE_ROUTING, authenticateIncomingUser);
    yield takeLatest(UPDATE_ROUTING, fillCalculationTool);
}
