import '@polymer/iron-flex-layout/iron-flex-layout';
import '@polymer/iron-flex-layout/iron-flex-layout-classes';
import '@polymer/paper-input/paper-input';
import 'web-animations-js/web-animations.min';
import '../../../general/ez-toggle/toggle';
import '../../../elements/energysplitter-activation-modal/EnergysplitterActivationModal';

/* eslint-disable camelcase */
import { PolymerElement, html } from '@polymer/polymer/polymer-element';
import { Router } from '@vaadin/router';
import { provider } from '@weavelab/frontend-connect';
import watch from 'redux-watch';
import { convertDateTo, sortDateByCreatedAt } from 'helpers/dates';
import { currency } from 'helpers/currency';
import { routePaths } from 'helpers/routing';
import {
    downloadDocument,
    persistor,
    store,
    listSplitBusiness,
    updateSnapshot,
} from '../../../../store';

import css from './settings-display.pcss';
import enums from '../../../../enums';
import { formatDate } from '../../../../globals';
import { logOut } from '../../../../actions/user';

import template from './settings-display.html';
import '../../../elements/dropdown-selector-component/DropdownSelector';
import '../../../../containers/invoice-grid/components/information-header/InformationHeader';
import settings from '../../../../internationalization/settings';

const themeSettings = provider.settings('user-settings-display');
const appSettings = provider.settings('app');

const defaultSplitBusinessText =
    // eslint-disable-next-line max-len
    'Je staat op het punt om de Energiesplitter aan te zetten. Zet de switch aan en stel je werkuren in.';
const defaultSplitBusinessEmpty =
    // eslint-disable-next-line max-len
    'Je hebt nog geen gebruik gemaakt van de Energiesplitter.';
/**
 * Login class
 * @polymer
 * @extends PolymerElement
 */
export default class SettingsDisplay extends PolymerElement {
    /**
     * Constructor of SettingsDisplay
     */
    constructor() {
        super();
        /** @type {!Object<string, any>} */
        this.$;
        /** @type {String} */
        this.termsOfServiceURL = themeSettings.termsOfServiceURL;
        /** @type {String} */
        this.changeSettingsButtonString =
            themeSettings?.changeSettingsButtonString
                ? themeSettings.changeSettingsButtonString
                : 'Gegevens wijzigen';
        /** @type {Boolean} */
        this.splitBusiness = settings.energySplitter;
        /** @type {String} */
        this.splitBusinessInformation =
            themeSettings && themeSettings.splitBusinessInformation
                ? themeSettings.splitBusinessInformation
                : defaultSplitBusinessText;
        /** @type {String} */
        this.splitBusinessName =
            themeSettings && themeSettings.splitBusinessName
                ? themeSettings.splitBusinessName
                : 'Energiesplitter';
        this.splitBusinessEmpty =
            themeSettings && themeSettings.splitBusinessEmpty
                ? themeSettings.splitBusinessEmpty
                : defaultSplitBusinessEmpty;
        /** @type {Boolean} */
        this.preventSplitBusinessUpdate = false;
    }

    /**
     * Gets properties of class
     */
    static get properties() {
        return {
            user: {
                type: Object,
                observer: '_userChanged',
            },
            meteringPoints: {
                type: Array,
            },
            snapshots: {
                type: Array,
                notify: true,
            },
            snapshot: {
                type: Object,
                observer: 'snapshotHasChanged',
            },
            bundleInvoices: {
                type: Boolean,
            },
            bundleInvoicesText: {
                type: String,
                value: 'Uit',
            },
            splitBusinessText: {
                type: String,
                value: 'Uit',
            },
            settingsPage: {
                type: String,
                value: 'display',
            },
            splitBusinessSelected: {
                type: Boolean,
                value: false,
                observer: 'splitBusinessHasChanged',
            },
            splitBusinessLogs: {
                type: Array,
                value: [],
            },
            termsOfServiceURL: String,
        };
    }

    /**
     * Gets template of class
     */
    static get template() {
        const cssTemplate = document.createElement('template');
        cssTemplate.innerHTML = provider.styles(css);
        const htmlTemplate = document.createElement('template');
        htmlTemplate.innerHTML = template;
        return html`<style include="iron-flex">
                ${cssTemplate}
            </style>
            ${htmlTemplate}`;
    }

    /**
     * Subscribes on user state when element is ready
     */
    ready() {
        super.ready();
        this.user = store.getState().user;
        this.meteringPoints = store.getState().meteringPoints;
        this.snapshots = store.getState().jwtSnapshots.snapshots;
        this.snapshot = store.getState().jwtSnapshots.selectedSnapshot;
        const watchUser = watch(store.getState, 'user');
        store.subscribe(
            watchUser(
                /**
                 * @param {Object} user
                 */
                (user) => {
                    this.user = user;
                },
            ),
        );

        const watchMeteringPoints = watch(store.getState, 'meteringPoints');
        store.subscribe(
            watchMeteringPoints(
                /**
                 * @param {Array<Object>} meteringPoints
                 */
                (meteringPoints) => {
                    this.meteringPoints = meteringPoints;
                },
            ),
        );

        // watcher for the selected snapshot.
        const watchSnapshot = watch(
            store.getState,
            'jwtSnapshots.selectedSnapshot',
        );
        store.subscribe(
            watchSnapshot((snapshot) => {
                if (snapshot) {
                    this.preventSplitBusinessUpdate = true;
                    this.snapshot = snapshot;
                }
            }),
        );

        const watchSnapshots = watch(store.getState, 'jwtSnapshots');
        store.subscribe(
            watchSnapshots(
                /**
                 * @param {Snapshot} snapshots
                 */
                (snapshots) => {
                    if (snapshots && snapshots.selectedSnapshot) {
                        const snapshot = snapshots.selectedSnapshot;
                        if (snapshot && snapshot.invoice_location && snapshot) {
                            this.notifyPath('snapshot.invoice_location');
                        }
                        if (snapshot && snapshot.split_business != null) {
                            this.splitBusinessSelected =
                                snapshot.split_business;
                        }
                        // if snapshot is updated, update the split business logs.
                        this.splitBusinessLogs = sortDateByCreatedAt(
                            snapshots.splitBusinessLogs,
                        );
                    }
                },
            ),
        );
        const watchEnergySplitter = watch(store.getState, 'energysplitter');
        const energySplitter = store.getState().energysplitter;
        if (energySplitter && energySplitter.activationLogs) {
            this.splitBusinessLogs = energySplitter.activationLogs;
        }

        store.subscribe(
            watchEnergySplitter(
                /**
                 * @param {EnergysplitterLogs} energysplitter
                 */
                (energysplitter) => {
                    // update the energysplitter logs.
                    this.splitBusinessLogs = sortDateByCreatedAt(
                        energysplitter.activationLogs,
                    );
                },
            ),
        );

        this.addEventListener('modal-window-closed', this.closeModal);
        this.addEventListener('modal-overlay-closed', this.closeModal);

        this.addEventListener(
            'energysplitter-accept',
            this.changeSplitBusiness,
        );
        this.addEventListener('energysplitter-declined', this.closeModal);
        this.addEventListener(
            'energysplitter-turn-off',
            this.changeSplitBusiness,
        );
    }

    /**
     * Set push notificatoins on user changed
     */
    _userChanged() {
        if (this.bundleInvoices === undefined && this.user?.bundle_invoices) {
            this.bundleInvoices = this.user?.bundle_invoices;
        }

        if (this.user?.bundle_invoices !== this.bundleInvoices) {
            this.bundleInvoices = this.user.bundle_invoices;
        }
    }

    /**
     * update the split business updated
     * @param {Object} snapshot
     */
    snapshotHasChanged(snapshot) {
        if (snapshot?.split_business != null && this.user?.id) {
            this.splitBusinessSelected = this.snapshot.split_business;
            const payload = {
                // (UUID)
                snapshotId: this.snapshot.id,
                // User ID (UUID)
                userId: this.user.id,
            };
            store.dispatch(listSplitBusiness.request(payload));
        }
    }

    /**
     * update the split business updated
     * @param {Boolean} bool
     */
    splitBusinessHasChanged(bool) {
        this.splitBusinessText = bool ? 'Aan' : 'Uit';
        const energysplitterModal = this.shadowRoot?.querySelector(
            'energysplitter-activation-modal',
        );
        if (energysplitterModal) {
            energysplitterModal.energysplitterActive = bool;
        }
    }

    /**
     * Toggles the bundle invoices for a user
     */
    _toggleBundleInvoices() {
        this.bundleInvoices = !this.bundleInvoices;
        this.user.bundle_invoices = this.bundleInvoices;
        this._setToggleTextBundleInvoices();
    }

    /**
     * _downloadDocument
     */
    _downloadDocument() {
        try {
            const { user } = store.getState();
            store.dispatch(
                downloadDocument.request({
                    // @ts-ignore
                    document_id: this.snapshot.id,
                    type: 3,
                    file: 1,
                    user_id: user.id,
                }),
            );
        } catch (error) {
            window.displayMessage(
                'Kan PDF niet downloaden, probeer het later nog eens.',
            );
        }
    }

    /**
     * _openTerms opens terms url
     */
    _openTerms() {
        if (this.termsOfServiceURL) {
            window.open(
                this.termsOfServiceURL,
                '_blank',
                'location=yes, usewkwebview=yes',
            );
        }
    }

    /**
     * Sets the text next to the toggle button
     */
    _setToggleTextBundleInvoices() {
        if (this.bundleInvoices) {
            this.bundleInvoicesText = 'Aan';
        } else {
            this.bundleInvoicesText = 'Uit';
        }
    }

    /**
     * Retrieves the payment method string
     * @param {Number} method the enum
     * @return {String} the string type of payment method
     */
    _getPaymentMethod = (method) => {
        switch (method) {
            case enums.PaymentMethodIdealLink:
                return 'iDEAL';
            case enums.PaymentMethodWithPaymentAccount:
                return 'Automatisch incasso';
            case 3:
                return 'n.v.t.';
            default:
                break;
        }
        return '';
    };

    /**
     * Retrieves the snapshot end date string
     * @param {Object} snapshot the enum
     * @return {String}
     */
    _getEndDate(snapshot) {
        if (snapshot && snapshot.id) {
            if (snapshot.contract_duration === 5) {
                return 'Doorlopend contract (30 dagen opzegtermijn)';
            }
            return `Verwacht ${this._formatDate(
                snapshot.verified_snapshot_payload.end_date,
            )}`;
        }
        return '';
    }

    /**
     * Retrieves the snapshot end date string
     * @param {Object} snapshot the enum
     * @return {String}
     */
    _getDuration = (snapshot) => {
        if (snapshot) {
            switch (snapshot.contract_duration) {
                case 5:
                    return 'Per maand opzegbaar';
                case 6:
                    return '1 jaar';
                case 7:
                    return '2 jaar';
                case 8:
                    return '3 jaar';
                default:
                    break;
            }
        }
        return '';
    };

    /**
     * Retrieves the metering point type string
     * @param {String} meteringpoint the type as enum
     * @return {String} the string type of metering point
     */
    _getMeteringPointType(meteringpoint) {
        // @ts-ignore
        if (this.meteringPoints) {
            // @ts-ignore
            const meteringpointMatch = this.meteringPoints.filter(
                (point) => meteringpoint === point.id,
            );
            // @ts-ignore
            return enums.MeteringPointType[
                meteringpointMatch[0].metering_point_type
            ];
        }
        return '';
    }

    /**
     * Check the contract status and returns readable string
     * @param {Number} status the type as enum
     * @param {Object} snapshot the snapshot object
     * @return {String} translated status
     */
    _getContractStatus(status, snapshot) {
        switch (status) {
            case enums.SnapshotPhaseSmartMeterChecked:
                return `In afwachting van eerste betaling voor start levering per ${this._formatDate(
                    snapshot.verified_snapshot_payload.start_date,
                )}`;
            case enums.SnapshotPhaseDeliveryPlanned:
                return `In afwachting van start levering per ${this._formatDate(
                    snapshot.verified_snapshot_payload.start_date,
                )}`;
            case enums.SnapshotPhaseDeliveryStarted:
                return `Begonnen met energielevering sinds ${this._formatDate(
                    snapshot.verified_snapshot_payload.start_date,
                )}`;
            case enums.SnapshotPhaseDeliveryStopped:
                return `Geëindigd met energieleveren sinds ${this._formatDate(
                    snapshot.verified_snapshot_payload.delivery_stopped_date,
                )}`;
            default:
                return 'Status onbekend';
        }
    }

    /**
     * Returns if the block should be revealed
     * @param {Object} snapshot
     * @return {Boolean}
     */
    _isEnabled = (snapshot) =>
        snapshot &&
        snapshot.snapshot_phase &&
        snapshot.snapshot_phase >= enums.SnapshotPhaseDemoContractCreated;

    /**
     * Returns if the block should be revealed
     * @param {Object} user
     * @param {String} prop
     * @return {Boolean}
     */
    _hasUserProp = (user, prop) => user[prop] != null && user[prop] !== '';

    /**
     * Formats money to nice readable string
     * @param {Number} amount total amount
     * @return {String} translated status
     */
    _formatMoney = (amount) => `${currency(Number(amount))} incl. btw`;

    /**
     * Retrieves the metering point type string
     * @param {String} meteringpoint the type as enum
     * @return {String} the string type of metering point
     */
    _getMeteringPointIdentifier = (meteringpoint) => {
        if (this.meteringPoints) {
            const meteringpointMatch = this.meteringPoints.filter(
                (point) => meteringpoint === point.id,
            );
            // @ts-ignore
            return meteringpointMatch[0].identifier;
        }
        return '';
    };

    /**
     * Retrieve toggle color based on bundleInvoices
     * @return {String} color class of toggle
     */
    _toggleBackgroundColor() {
        if (this.bundleInvoices) {
            return 'activeToggleColor';
        }
        return 'inActiveToggleColor';
    }

    /**
     * Logout a user
     */
    _logOut = () => {
        persistor
            .purge()
            .then(() => {
                store.dispatch(logOut());
                if (appSettings.logOutLink && appSettings.logOutLink !== '') {
                    // TODO: check
                    window.location.href = appSettings.logOutLink;
                }
            })
            .catch((err) => {
                console.warn('failed at purging state: ', err);
            });
    };

    /**
     * getContractCancel date will retrieve the cancellation date
     * @param {object} snapshot
     * @return {string}
     * @private
     */
    _getContractCancelDate(snapshot) {
        if (
            !snapshot ||
            !snapshot.contract_cancellation ||
            snapshot.contract_cancellation.length === 0
        ) {
            return '';
        }
        const contractCancellations = snapshot.contract_cancellation.filter(
            (el) => !el.failed && el.type === enums.InteractionTypeReceived,
        );
        if (contractCancellations.length === 0) {
            return '';
        }
        return this._formatDate(new Date(contractCancellations[0].date));
    }

    /**
     * Has cancelled checks if we have a cancellation from CER
     * @param {object} snapshot
     * @return {boolean}
     * @private
     */
    _hasCancelled(snapshot) {
        return this._getContractCancelDate(snapshot) !== '';
    }

    /**
     * Formats date to string format
     * @param {Date} date from user state object
     * @return {String} dd-mm-yyyy
     */
    _formatDate = (date) => formatDate(date);

    /**
     * Saves adjusted settings
     */
    _changeSettings = () => {
        Router.go(routePaths.editSettings);
    };

    /**
     * getSplitBusinessFromSnapshot will retrieve the splitBusiness form the snapshot.
     * @param {object} snapshot
     * @return {Boolean}
     */
    getSplitBusinessFromSnapshot = (snapshot) =>
        snapshot && snapshot.split_business ? snapshot.split_business : false;

    /**
     * @param {MouseEvent} e
     */
    splitBusinessChanged(e) {
        e.stopImmediatePropagation();
        if (this.preventSplitBusinessUpdate) {
            this.preventSplitBusinessUpdate = false;
            return;
        }

        const energysplitterModal = this.shadowRoot.querySelector(
            'energysplitter-activation-modal',
        );
        energysplitterModal.open();
    }

    /**
     * changeSplitBusiness updates the split business toggle and snapshot.
     * @param {CustomEvent} e
     */
    changeSplitBusiness(e) {
        e.stopImmediatePropagation();
        if (e.detail?.state == null) {
            console.warn('Error getting state from event detail');
            return;
        }

        const splitBusinessState = !e.detail.state;
        if (this.snapshot.split_business !== splitBusinessState) {
            const payload = {
                // (UUID)
                snapshotId: this.snapshot.id,
                // (Boolean)
                splitBusiness: splitBusinessState,
                // User ID (UUID)
                userId: this.user.id,
            };
            try {
                store.dispatch(updateSnapshot.request(payload));
                const splitBusinessMessage = splitBusinessState
                    ? `De ${this.splitBusinessName} staat aan.`
                    : `De ${this.splitBusinessName} wordt uitgezet.`;
                window.displayMessage(splitBusinessMessage);
                this.snapshot.split_business = splitBusinessState;
            } catch (error) {
                this.splitBusinessSelected = splitBusinessState;
                console.warn(
                    `Het bijwerken van de ${this.splitBusinessName} is niet gelukt.`,
                    error,
                );
                window.displayMessage(
                    `Er ging iets fout bij het aanpassen van status van de ${this.splitBusinessName}, probeer het later opnieuw.`,
                );
            }
        }

        this.callCloseEnergysplitterModal();
    }

    /**
     * open the modal that showes the split business history of the user.
     */
    openSplitBusinessHistory() {
        if (this.shadowRoot) {
            const splitBusinessHistoryModal = this.shadowRoot.querySelector(
                '#split__business__history--modal',
            );
            splitBusinessHistoryModal.open();
        }
    }

    /**
     * modal is closed.
     * @param {MouseEvent} e
     */
    closeModal(e) {
        if (e.detail.target.id !== 'split__business--modal') {
            // required
            if (e.detail?.state == null) {
                return;
            }
        }
        this.preventSplitBusinessUpdate = true;
        const toggle = this.shadowRoot?.querySelector(
            '#split__business--toggle',
        );
        if (toggle) {
            toggle.isChecked = this.splitBusinessSelected;
        }
    }

    /**
     * IS the given string Date a null date
     * @param {String} date
     * @return {Boolean}
     */
    dateIsNull = (date) => date === '0001-01-01T00:00:00Z' || date == null;

    /**
     * @param {Array} logs
     * @Return {Boolean}
     */
    splitBusinessLogsIsEmpty = (logs) => !(logs && logs.length);

    /**
     * @param {String} date
     * @return {String}
     */
    dateDayMonth = (date) => {
        const dayOption = { day: '2-digit', month: '2-digit', year: 'numeric' };
        return convertDateTo(date, dayOption);
    };

    /**
     * @param {Date} date
     * @return {String}
     */
    dateHourMinutes(date) {
        if (date == null) {
            return '';
        }
        const _date = new Date(date);
        const hours = this.returnTwoDigitString(_date.getHours());
        const minutes = this.returnTwoDigitString(_date.getMinutes());
        return `${hours}:${minutes}`;
    }

    /**
     * @param {Number} num
     * @return {String}
     */
    returnTwoDigitString = (num) => (num < 10 ? '0' : '') + num;
}

window.customElements.define('settings-display', SettingsDisplay);
