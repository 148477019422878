import {
    LitElement,
    PropertyValues,
    TemplateResult,
    customElement,
    html,
    property,
    query,
} from 'lit-element';
import { ifDefined } from 'lit-html/directives/if-defined';

type themeOptions = '' | 'light' | 'dark';

import style from './style-ez-check-box';

@customElement('ez-check-box')
export class EzCheckBox extends LitElement {
    @property({ type: Object })
    public item?: object;

    @query('.checkbox')
    public checkbox?: HTMLInputElement;

    @property({
        type: Boolean,
        reflect: true,
    })
    public value?: boolean;

    @property()
    public theme: themeOptions = '';

    @property({ type: Boolean })
    public required: boolean = false;

    @property({
        attribute: 'error-message',
        reflect: true,
    })
    public errorMessage: string = `Verplicht`;

    @property({ type: Boolean })
    public error: boolean = false;

    @property({ attribute: 'hide-text', type: Boolean })
    public hideText: boolean = false;

    @property({ attribute: 'component-margin' })
    public componentMargin: string = '';

    static get styles() {
        return [style];
    }

    constructor() {
        super();
        this.addEventListener('click', (): void => {
            this.value = this.value ? false : true;
            this.dispatchEvent(
                new CustomEvent('clicked', {
                    bubbles: true,
                    composed: true,
                    detail: { element: this.value },
                }),
            );
        });
    }

    protected render = (): TemplateResult => {
        return html`
            <style>
                :host {
                    margin: ${this.componentMargin};
                }
            </style>
            <div class="checkbox-wrapper">
                <div id="checkbox">
                    ${this.value
                        ? html`
                              <svg
                                  width="10"
                                  height="8"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                              >
                                  <path
                                      d="M9 1.5l-4.914 5L1 3.407"
                                      stroke="#fff"
                                      stroke-width="1.5"
                                      stroke-miterlimit="10"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                  />
                              </svg>
                          `
                        : null}
                </div>
                ${!this.hideText
                    ? html`
                          <p>
                              <slot theme=${ifDefined(this.theme)}></slot>
                          </p>
                      `
                    : null}
            </div>
            <error-handling
                .error=${this.error}
                error-message=${this.errorMessage}
            ></error-handling>
        `;
    };

    public updated(changedProperties: PropertyValues): void {
        if (changedProperties.has('value') && this.value !== undefined) {
            if (this.value) {
                this.classList.add('selected');
            } else {
                this.classList.remove('selected');
            }
            /**
             * This function binds details to Event
             * The properties needed to read the values of the input where bind in properties
             * The return is a Event and the interface CustomInputEvent is needed for typechecking.
             */
            const changeEvent: Event = new CustomEvent('form-element-changed', {
                bubbles: true,
                composed: true,
                detail: {
                    element: this,
                    id: this.id,
                    required: this.required,
                    validity: this.validate(),
                    value: this.value,
                },
            });
            this.dispatchEvent(changeEvent);
        }
    }

    public validate = (): boolean => {
        this.error = false;
        // When the value is required or value changed to null or undefined it validates the value.
        if (this.required || this.value != null) {
            this.error = this.value != null ? false : true;
        }
        return !this.error;
    };
}

declare global {
    interface HTMLElementTagNameMap {
        'ez-check-box': EzCheckBox;
    }
}
