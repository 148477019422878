import {
    Bearer,
    BearerSupportedTypes,
    ParameterDefinitionsObject,
    ParameterIn,
    RequestAction,
    RequestOptions,
    RequestSagaIterableIterator,
    ResolvableOrType,
    Resource,
    ResourceOptions,
    UUID,
    UUIDSupportedTypes,
} from 'goagen-js-resource';
import { Method } from 'axios';

export interface ListSplitBusinessRequestObject {
    // (UUID)
    snapshotId?: UUIDSupportedTypes;
    // User ID (UUID)
    userId?: UUIDSupportedTypes;
    // Injected Bearer header (Bearer)
    xAuth?: BearerSupportedTypes;
}

export interface ResolvableListSplitBusinessRequestObject {
    // (UUID)
    snapshotId?: ResolvableOrType<ListSplitBusinessRequestObject['snapshotId']>;
    // User ID (UUID)
    userId?: ResolvableOrType<ListSplitBusinessRequestObject['userId']>;
    // Injected Bearer header (Bearer)
    xAuth?: ResolvableOrType<ListSplitBusinessRequestObject['xAuth']>;
}

/**
 * Store member management object for list splitBusiness
 */
export class ListSplitBusiness extends Resource<
    ListSplitBusinessRequestObject,
    ResolvableListSplitBusinessRequestObject
> {
    /**
     * Create new ListSplitBusiness
     *
     * | Parameter |  | Target |  |  |
     * | --- | --- | --- | --- | --- |
     * | `snapshotId` * | `UUID` | path | `snapshotID` |  |
     * | `userId` * | `UUID` | path | `user_id` | User ID |
     * | `xAuth` | `Bearer` | header | `X-Auth` | Injected Bearer header |
     */
    constructor(
        storeName: string,
        options: ResourceOptions<ResolvableListSplitBusinessRequestObject> = {},
    ) {
        super(storeName, options);
    }

    /**
     * Generate request action
     *
     * | Parameter |  | Target |  |  |
     * | --- | --- | --- | --- | --- |
     * | `snapshotId` * | `UUID` | path | `snapshotID` |  |
     * | `userId` * | `UUID` | path | `user_id` | User ID |
     * | `xAuth` | `Bearer` | header | `X-Auth` | Injected Bearer header |
     */
    public request(
        requestObject: ListSplitBusinessRequestObject = {},
        requestOptions: RequestOptions = {},
    ): RequestAction<ListSplitBusinessRequestObject> {
        return super.request(requestObject, requestOptions);
    }

    /**
     * Run request as saga
     *
     * | Parameter |  | Target |  |  |
     * | --- | --- | --- | --- | --- |
     * | `snapshotId` * | `UUID` | path | `snapshotID` |  |
     * | `userId` * | `UUID` | path | `user_id` | User ID |
     * | `xAuth` | `Bearer` | header | `X-Auth` | Injected Bearer header |
     */
    // @ts-ignore
    public get requestSaga(): (
        requestObject?: ListSplitBusinessRequestObject,
        requestOptions?: RequestOptions,
    ) => RequestSagaIterableIterator<
        RequestAction<ListSplitBusinessRequestObject>
    > {
        return super.requestSaga;
    }

    /**
     * Action string
     */
    static get action(): string {
        return 'list';
    }

    /**
     * Description string
     */
    static get description(): string {
        return 'List a all split business activities';
    }

    /**
     * Request method
     */
    static get method(): Method {
        return 'get';
    }

    /**
     * Resource string
     */
    static get resource(): string {
        return 'splitBusiness';
    }

    /**
     * Route string
     */
    static get route(): string {
        return '/user/{user_id}/snapshot/{snapshotID}/split-business';
    }

    /**
     * Unique name string
     */
    static get uniqueName(): string {
        return 'ListSplitBusiness';
    }

    /**
     * Parameters object
     */
    static get parameters(): ParameterDefinitionsObject {
        return {
            snapshotId: {
                in: ParameterIn.path,
                name: 'snapshotID',
                required: true,
                type: UUID,
            },
            userId: {
                description: 'User ID',
                in: ParameterIn.path,
                name: 'user_id',
                required: true,
                type: UUID,
            },
            xAuth: {
                description: 'Injected Bearer header',
                in: ParameterIn.header,
                name: 'X-Auth',
                type: Bearer,
            },
        };
    }
}
