import { Reducer } from 'redux';
import { Snapshot } from 'types/snapshot';
import {
    SnapshotActions,
    SET_SELECTED_SNAPSHOT_ID,
    CLEAR_SELECTED_SNAPSHOT_ID,
    RESET_JWT_SNAPSHOTS,
    SET_JWT_SNAPSHOTS,
    SET_SELECTED_SNAPSHOT,
    CLEAR_SELECTED_SNAPSHOT,
    AGREED_SNAPSHOT,
} from '../actions/snapshot';
import { snapshotsPhaseAllowedFrom } from '../helpers/snapshot';
import { isSmartCharging } from '../helpers/smartCharging';
import { sortJwtByAdressAndPrefix } from '../helpers/jwt';

// TODO: implement sortedSnapshot that when the snapshot changes
// the sortedSnapshot gets updated
export interface JWTSnapshotState {
    agreedSnapshot: Snapshot | null;
    dashboardSnapshotID: string | null;
    selectedSnapshot: Snapshot | null;
    hasFlex: boolean;
    sortedByLatestSnapshot: Snapshot[];
    snapshots: Snapshot[];
    sortedAndFilteredAddresses: Snapshot[];
    smartChargingSnapshots: Snapshot[];
}
export interface SnapshotState {
    id?: string;
}

const defaultState: JWTSnapshotState = {
    agreedSnapshot: null,
    dashboardSnapshotID: null,
    selectedSnapshot: null,
    hasFlex: false,
    sortedByLatestSnapshot: [],
    snapshots: [],
    sortedAndFilteredAddresses: [],
    smartChargingSnapshots: [],
};

// State reducer for jwt snapshots
export const jwtSnapshots: Reducer<JWTSnapshotState, any> = (
    state = { ...defaultState },
    action: SnapshotActions,
) => {
    const energySnapshot = action.snapshots?.filter(
        (snapshot) => !isSmartCharging(snapshot),
    );
    const smartChargingSnapshots = action.snapshots?.filter((snapshot) =>
        isSmartCharging(snapshot),
    );
    switch (action.type) {
        case RESET_JWT_SNAPSHOTS:
            return { ...state, snapshots: [] };
        case SET_JWT_SNAPSHOTS:
            const snapshotsAddresses = sortJwtByAdressAndPrefix(energySnapshot);
            const sortedByLastestSnapshot: Snapshot[] = sortByLatestSnapshots(
                energySnapshot,
                state.selectedSnapshot,
            );
            const hasFlex = hasFlexSnapshot(energySnapshot);
            return {
                ...state,
                snapshots: energySnapshot,
                sortedByLastestSnapshot,
                hasFlex,
                sortedAndFilteredAddresses: snapshotsAddresses.snapshots,
                smartChargingSnapshots,
            };
        case SET_SELECTED_SNAPSHOT:
            const { snapshot } = action;
            return {
                ...state,
                sortedByLatestSnapshot: sortByLatestSnapshots(
                    state.snapshots,
                    action.snapshot,
                ),
                selectedSnapshot: snapshot,
            };
        case CLEAR_SELECTED_SNAPSHOT:
            return {
                ...state,
                selectedSnapshot: null,
            };
        case SET_SELECTED_SNAPSHOT_ID:
            return {
                ...state,
                dashboardSnapshotID: null,
            };
        case AGREED_SNAPSHOT:
            return {
                ...state,
                agreedSnapshot: action.snapshot,
            };
        case CLEAR_SELECTED_SNAPSHOT_ID:
            return {
                ...state,
                dashboardSnapshotID: null,
            };
        default:
            return state;
    }
};

// sort the latest snapshots
const sortByLatestSnapshots = (
    snapshots?: Snapshot[],
    selectedSnapshot?: Snapshot | null,
): Snapshot[] => {
    if (snapshots == null) {
        return [];
    }
    if (selectedSnapshot == null) {
        return snapshots;
    }

    const id: string | undefined = selectedSnapshot.delivery_location?.id;
    let res: Snapshot[] = [];
    // create a snapshot map with key: stopDate and value: snapshot
    snapshots.forEach((snapshot) => {
        if (Number(snapshot.snapshot_phase) > snapshotsPhaseAllowedFrom) {
            if (snapshot.delivery_location.id === id || id == null) {
                res.push(snapshot);
            }
        }
    });

    res = res.sort((a: Snapshot, b: Snapshot) => {
        const aPayload = a.verified_snapshot_payload;
        const aStart: Date = new Date(aPayload.start_date);

        const bPayload = b.verified_snapshot_payload;
        const bStart: Date = new Date(bPayload.start_date);

        const aRes: number = aStart.getTime();
        const bRes: number = bStart.getTime();
        if (aRes > bRes) {
            return -1;
        }
        return 1;
    });
    return res;
};

// hasFlexSnapshot loops over snapshots collection and checks if a flex snapshot is available
const hasFlexSnapshot = (snapshots: Snapshot[]): boolean =>
    !!snapshots.find((snap: Snapshot) => {
        const snapShot = new Snapshot(snap);
        return snapShot.isFlex();
    });
