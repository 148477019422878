export const REQUEST_INSIGHTS = 'REQUEST_INSIGHTS';
/**
 * Action generator for request
 * @return {Object}
 */
export const requestInsights = () => ({
    type: REQUEST_INSIGHTS,
});

export const REQUEST_INSIGHTS_SUCCESS = 'REQUEST_INSIGHTS_SUCCESS';
/**
 * Success callback action
 * @param {Number | Null} e
 * @param {Number | Null} g
 * @param {Number | Null} p
 * @param {Object} requestObject
 * @return {Object}
 */
export const requestInsightsSuccess = (e, g, p, requestObject) => ({
    type: REQUEST_INSIGHTS_SUCCESS,
    e,
    g,
    p,
    requestObject,
});

export const REQUEST_INSIGHTS_FAILED = 'REQUEST_INSIGHTS_FAILED';
/**
 * Error callback action
 * @param {Object} error
 * @return {Object}
 */
export const requestInsightsFailed = (error) => ({
    type: REQUEST_INSIGHTS_FAILED,
    error,
});
