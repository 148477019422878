import {
    LitElement,
    TemplateResult,
    customElement,
    html,
    property,
    PropertyValues,
} from 'lit-element';

import style from './style/style';
import '../base/button/ButtonComponent';
import { whitelabel } from '@weavelab/whitelabel';

@customElement('pagination-component')
@whitelabel({ name: 'pagination-component' })
export class Pagination extends LitElement {
    /** Per-page limit of the elements. */
    @property({ type: Number, reflect: true })
    public limit: number = 2;

    /** Total count of the elements. */
    @property({ type: Number, reflect: true })
    public total: number = 20;

    /** Current page. */
    @property({ type: Number, reflect: true })
    public page: number = 2;

    /** Count of the pages displayed before or after the current page. */
    @property({ type: Number, reflect: true })
    public size: number = 2;

    /** Number of paginated pages. */
    @property({ type: Number, reflect: true })
    public pages: number = 1;

    /** Has pages before the current page. */
    @property({ type: Boolean })
    public hasBefore: boolean = false;

    /** Has pages after the current page. */
    @property({ type: Boolean })
    public hasNext: boolean = false;

    /** Has pages. */
    @property({ type: Boolean })
    public hasPages: boolean = false;

    /** Displayed page elements */
    @property({ type: Array })
    public items: any[] = [];

    /** Hide first and last. */
    @property({ type: Boolean })
    public hideFirstAndLast: boolean = true;

    @property({ type: Boolean })
    public hideTotal: boolean = false;

    @property({ type: Boolean })
    public hidden: boolean = false;

    @property({ type: Boolean, reflect: true })
    public isMobile: boolean = false;

    @whitelabel() // Allow styles to be injected from theme
    static get styles() {
        return [style];
    }

    public render = (): TemplateResult => {
        const hasBefore = this.computeBefore(this.page, this.pages);
        const hasNext = this.computeNext(this.page, this.pages);
        const hasPages = this.computeHasPage(this.items.length, this.total);

        return html` <div
            class="paginator-page-container"
            ?hidden="${!hasPages}"
        >
            <ez-button-component
                icon="pagination-icons:fast-rewind"
                @click="${this.onBegin}"
                ?hidden="${!hasBefore || this.hideFirstAndLast}"
                ><<</ez-button-component
            >
            <ez-button-component
                class="navigate-before"
                icon="pagination-icons:navigate-before"
                @click="${this.onBefore}"
                ?hidden="${!hasBefore}"
            >
                <</ez-button-component
            >
            ${this.pages > 1
                ? this.items.map(
                      (item) => html`
                          <ez-button-component
                              class="pages"
                              raised="${!this.isCurrent(item, this.page)}"
                              ?disabled="${this.isCurrent(item, this.page)}"
                              @click="${(e: MouseEvent) =>
                                  this.onChange(item, e)}"
                          >
                              ${item}
                          </ez-button-component>
                      `,
                  )
                : html``}
            ${!this.hideTotal && hasNext
                ? html`
                      <span class="pages">...</span>
                      <span class="info">${this.pages}</span>
                  `
                : null}
            <ez-button-component
                class="navigate-next"
                icon="pagination-icons:navigate-next"
                @click=${this.onNext}
                ?hidden="${!hasNext}"
                >></ez-button-component
            >
            <ez-button-component
                icon="pagination-icons:fast-forward"
                @click=${this.onEnd}
                ?hidden="${!hasNext || this.hideFirstAndLast}"
                >>>
            </ez-button-component>
        </div>`;
    };

    protected updated(changedProperties: PropertyValues) {
        if (
            (changedProperties.has('page') && this.page) ||
            (changedProperties.has('limit') && this.limit) ||
            (changedProperties.has('total') && this.total)
        ) {
            this.observePageCount(this.page, this.limit, this.total);
        }

        if (
            changedProperties.has('page') &&
            changedProperties.get('page') &&
            this.page
        ) {
            const previousPage = changedProperties.get('page') as number;
            this.onPageChange(previousPage, this.page);
        }
    }

    private observePageCount = (
        page: number,
        limit: number,
        total: number,
    ): void => {
        if (limit && total) {
            this.pages = Math.ceil(total / limit);
        }

        if (page && limit && total) {
            const items = [];
            const firstIndex = this.firstIndex(page, this.size);
            const lastIndex = this.lastIndex(page, this.size);
            for (let num = firstIndex; num <= lastIndex; num++) {
                items.push(num);
            }
            this.items = items;
            if (lastIndex === this.pages) {
                this.hideTotal = true;
            } else if (this.hideTotal) {
                this.hideTotal = false;
            }
        }
    };

    // Compute the current values
    private computeBefore = (page: number, _: number): boolean => page > 1;

    private computeNext = (page: number, pages: number): boolean =>
        page < pages;

    private computeHasPage = (itemsLength: number, total: number): boolean =>
        itemsLength < total;

    // If page change dispatch event page change.
    private onPageChange(previousPage: number, page: number) {
        this.dispatchEvent(
            new CustomEvent('page-change', {
                bubbles: true,
                composed: true,
                detail: { previousPage, page },
            }),
        );
    }

    // Returns first page index
    private firstIndex = (page: number, _: number): number => {
        const index = page - 0;
        if (index < 1) {
            return 1;
        }
        return index;
    };

    // Returns last page index
    private lastIndex = (page: number, size: number): number => {
        const index = page + size;
        if (index > this.pages) {
            return this.pages;
        }
        return index;
    };

    // Returns if the current index equals the page.
    private isCurrent = (index: number, page: number): boolean =>
        index === page;

    // when changed update page.
    private onChange = (item: any, event: MouseEvent): void => {
        event.stopImmediatePropagation();
        this.page = item;
    };

    private onBefore = (event: MouseEvent) => {
        event.stopImmediatePropagation();
        this.page = this.page > 0 ? this.page - 1 : 1;
    };

    private onNext = (event: MouseEvent): void => {
        event.stopImmediatePropagation();
        this.page = this.page < this.pages ? this.page + 1 : this.pages;
    };

    private onBegin = (event: MouseEvent) => {
        event.stopImmediatePropagation();
        this.page = 1;
    };

    private onEnd = (event: MouseEvent) => {
        event.stopImmediatePropagation();
        this.page = this.pages;
    };
}

declare global {
    interface HTMLElementTagNameMap {
        'pagination-component': Pagination;
    }
}
