import {
    LitElement,
    PropertyValues,
    TemplateResult,
    customElement,
    html,
    property,
} from 'lit-element';

import '../base/checkbox-component/CheckboxComponent';
import { itemType } from './GridDataProvider';

import style from './style/styleGridCell';

@customElement('grid-cell')
export class GridCell extends LitElement {
    @property({ type: String })
    public value?: any;

    @property({ type: Object })
    public item?: any;

    @property({ type: Number })
    public index?: number;

    @property({ type: Array })
    public childElements?: any[];

    @property({ type: Boolean })
    public changeRenderer: boolean = false;

    @property({ type: Boolean, reflect: true })
    public hidden: boolean = false;

    @property({ attribute: false })
    public onRender?: (
        value: any,
        item: any,
        changeDropdownState?: boolean,
    ) => TemplateResult;

    @property({ type: Boolean })
    public changeDropdownState?: boolean;

    @property({ type: Boolean })
    public checkbox: boolean = false;

    static get styles() {
        return [style];
    }

    protected render = (): TemplateResult => {
        return html`
            ${this.renderer(
                this.value,
                this.item,
                this.childElements,
                this.changeDropdownState,
                this.checkbox,
            )}
        `;
    };

    protected firstUpdated(_: PropertyValues) {
        if (this.childElements && this.childElements.length > 0) {
            // when childElements add the click event to allow the collapse event for the child elements.
            this.addEventListener('click', () => {
                this.childElements!.forEach(
                    (child: GridCell) => (child.hidden = !child.hidden),
                );
                this.changeDropdownState = !this.changeDropdownState;
            });
        }
    }

    public updated(changedProperties: PropertyValues): void {
        if (changedProperties.has('hidden')) {
            this.hidden
                ? this.setAttribute('hidden', 'true')
                : this.removeAttribute('hidden');
        }
    }

    protected renderer = (
        value: any,
        item: itemType,
        childElements?: any[],
        dropdownState?: boolean,
        checkbox?: boolean,
    ): TemplateResult => {
        if (this.onRender !== undefined) {
            try {
                let result: TemplateResult;

                // when the parent has childElements add indent styling.
                if (
                    childElements !== undefined &&
                    Array.isArray(childElements) &&
                    childElements.length > 0 &&
                    this.index != null &&
                    this.index === 0
                ) {
                    result = this.onRender(value, item, dropdownState);
                } else {
                    result = this.onRender(value, item);
                }

                // when childElement is the first element.
                if (
                    this.changeRenderer &&
                    this.index != null &&
                    this.index === 0
                ) {
                    return this.addIndicatorBeforeHTML(result);
                }

                return result;
            } catch (error) {
                console.warn('Err rendering custom function', error);
            }
        }
        if (checkbox) {
            const parentGridCheckbox = this
                .parentElement as HTMLElementTagNameMap['grid-checkbox'];
            const parentGrid =
                parentGridCheckbox.parentElement as HTMLElementTagNameMap['grid-component'];
            let checkChild = parentGridCheckbox
                ? parentGridCheckbox.checked
                : false;
            const itemId: string = item.id;
            if (
                parentGridCheckbox.checked &&
                parentGrid.excludedItems.size > 0 &&
                parentGrid.excludedItems.has(itemId)
            ) {
                checkChild = false;
            } else if (
                !parentGridCheckbox.checked &&
                parentGrid.excludedItems.size === 0 &&
                parentGrid.checkedItems.has(itemId)
            ) {
                checkChild = true;
            }
            return html`<checkbox-component
                ?checked=${checkChild}
                .item=${item}
            ></checkbox-component>`;
        }
        return html`
            ${typeof value !== 'string' ? JSON.stringify(value) : value}
        `;
    };

    private addIndicatorBeforeHTML = (
        result: TemplateResult,
    ): TemplateResult => {
        return html` <style>
                img {
                    margin-left: 57px;
                }
            </style>
            <img
                class="noselect"
                draggable="false"
                src="assets/icons/indent.svg"
                alt="Indent indicator"
            />
            ${result}`;
    };
}

declare global {
    interface HTMLElementTagNameMap {
        'grid-cell': GridCell;
    }
}
