import { provider } from '@weavelab/frontend-connect';
import EzUsageTemplate from '../../templates/ez-usage-template/usage-template';
import enums from '../../../enums';

const appSettings = provider.settings('app');

/**
 * Class for showing Energy usage using Usage Template
 */
export default class chargingSessions extends EzUsageTemplate {
    /**
     * Constructor for chargingSessions
     */
    constructor() {
        super();
        /** @type {Number | null} */
        this.usageType = enums.UsageTypeElekUsage;
        /** @type {String} */
        this.label = 'Euro';
        /** @type {Boolean} */
        this.active = true;
        /** @type {Boolean} */
        this.chargingSessions = true;
        /** @type {Boolean} */
        this.displayHeaderTooltip = true;
        /** @type {String} */
        this.headerTooltipInformation =
            'Deze kWh’s hebben wij al van je energierekening afgehaald, wij facturen deze rechtstreeks aan je werkgever.';

        this.insightsAppUser = true;

        this.dataColor = '#000';
    }

    /**
     * Callback for global state changed
     * @param  {...any} args
     */
    _stateChanged(...args) {
        // @ts-ignore
        super._stateChanged(...args);
    }
}

window.customElements.define('charging-sessions', chargingSessions);
