import { ResourceDataType } from './ResourceDataType';

/**
 * Bearer object
 */
export class Bearer extends ResourceDataType {
    /**
     * Constructor to create a Bearer object
     * @param {null|string|Bearer} [value]
     * @param {boolean} [nullable]
     */
    constructor(value, nullable) {
        super(nullable);
        /** @type {string?} */
        this.value = '';

        if (value === undefined) {
            this.setValue(Bearer._new());
        } else {
            this.setValue(value instanceof Bearer ? value.getValue() : value);
        }
    }

    /**
     * Get Bearer value
     * @return {string?}
     */
    getValue() {
        return this.value;
    }

    /**
     * Set Bearer value
     * @param {string?} value
     */
    setValue(value) {
        if (value === null || value === undefined) {
            if (!this._nullable) {
                console.warn(
                    'To allow for nullable Bearers use',
                    'new Bearer(null, true)',
                );
                throw new Error('Parameter is not a valid Bearer');
            }
            this.value = null;
        } else if (typeof value === 'string' && Bearer.validate(value)) {
            this.value = value;
        } else {
            throw new Error('Parameter is not a valid Bearer');
        }
    }

    /**
     * Check if the value is set
     * @return {boolean}
     */
    isSet() {
        return !!this.value;
    }

    /**
     * Get Bearer as a format string
     * @return {string}
     */
    toFormatString() {
        return `"${this.toString()}"`;
    }

    /**
     * Get Bearer as string
     * @return {string}
     */
    toString() {
        return this.value || new Bearer().toString();
    }

    /**
     * Generate a new Bearer string
     * @return {string}
     */
    static _new() {
        return 'Bearer jwtHeader.jwtPayload.jwtSignature';
    }

    /**
     * Validate a Bearer string
     * @param {string} value
     * @return {boolean}
     */
    static validate(value) {
        return /^Bearer ([a-zA-Z0-9-_]*\.){2}[a-zA-Z0-9-_]*$/.test(value);
    }
}
