import { put, takeLatest, delay, call } from 'redux-saga/effects';
import { geoCodeEDSNCheck } from '@async-reducers/geocode';
import { SET_POSTAL_CODE, SET_HOUSE_NUMBER } from '../actions/order';
import { invalidateGeocode } from '../actions/geocode';

import { store } from '../store';
import { dutchPostalCodesRegex } from '../helpers/regex';

const DEBOUNCE_REQUEST_MS = 700;

/**
 * Fetches data from geocode with postal codes debounced
 * @param {Object} param
 */
function* requestAddress() {
    const state = store.getState();
    const { order } = state;

    // The order object has all address information
    if (!order) {
        return;
    }
    const { postalCode, houseNumber } = order;
    // Mark geocode as no longer valid when a change is made
    if (
        (postalCode == null ||
            postalCode === '' ||
            houseNumber == null ||
            houseNumber === 0) &&
        !state.geocode.invalidated
    ) {
        yield put(invalidateGeocode());
    }
    if (postalCode && houseNumber && postalCode.length) {
        let groups = postalCode;
        // if group is not of type regular expression create array group from regex
        if (!Array.isArray(postalCode)) {
            groups = new RegExp(dutchPostalCodesRegex).exec(postalCode);
        }
        if (
            groups &&
            groups.length > 2 &&
            groups[1].length === 4 &&
            groups[2].length === 2
        ) {
            store.dispatch(
                geoCodeEDSNCheck.run(`${groups[1]}${groups[2]}`, houseNumber),
            );
        }
    }
}

/**
 * onAddressUpdate triggers request address with debounce
 */
function* onAddressUpdate() {
    yield delay(DEBOUNCE_REQUEST_MS);
    yield call(requestAddress);
}

/**
 * Watcher for fetching from geocode with postal codes
 */
export function* watchSetPostalCode() {
    // @ts-ignore
    yield takeLatest([SET_POSTAL_CODE, SET_HOUSE_NUMBER], onAddressUpdate);
}
