import { ReduxAsync } from '@weavedev/redux-async';
import axios, { AxiosResponse } from 'axios';
import { gqlResponse } from '@async-reducers/helpers/gql';
import headerParser from '../sagas/helpers/headers';

const LIST_BROADCASTS = 'LIST_BROADCASTS';
const LIST_BROADCASTS_SUCCESS = 'LIST_BROADCASTS_SUCCESS';
const LIST_BROADCASTS_FAILED = 'LIST_BROADCASTS_FAILED';

export const ListBroadcasts = new ReduxAsync(
    LIST_BROADCASTS,
    LIST_BROADCASTS_SUCCESS,
    LIST_BROADCASTS_FAILED,
    async (): Promise<any> => {
        const headers = window.STDHeaders;
        const response: AxiosResponse = await axios.post(
            '/v1/gql',
            {
                query: `
                    {
                      getLatestBroadcast {
                        createdAt
                        deletedAt
                        expiryDate
                        id
                        message
                        updatedAt
                      }
                    }
                `,
                variables: {},
            },
            {
                baseURL: window.API_LINK,
                headers: headerParser(headers),
            },
        );

        // if a pure gql error occurs, gql will always send a 200 regardless
        // in case there is not a 200, a different error, like a network error could have occurred
        if (
            response &&
            response.statusText !== 'OK' &&
            response.status !== 200
        ) {
            throw response.status;
        }
        const gqlData = response.data as gqlResponse;
        const noMessageFound = gqlData.errors?.find(
            (error) => error.message === 'entity_not_found',
        );
        if (!gqlData.errors?.length || noMessageFound) {
            return response.data;
        }
        window.displayMessage(
            'Er is iets misgegaan met het ophalen van de laatste berichten.',
            'warning',
        );
        throw gqlData.errors[0]?.message;
    },
);
