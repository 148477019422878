import { Validator } from '@lion/form-core';

export class ContainsUpper extends Validator {
    static get validatorName() {
        return 'ContainsUpper';
    }

    // eslint-disable-next-line class-methods-use-this
    execute(value: any) {
        return typeof value !== 'string' || !/[A-Z]/.test(value);
    }
}
