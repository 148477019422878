import {
    START_ENROLL,
    PROMPT_ENROLL,
    RESET_ENROLL,
    FINISH_ENROLL,
} from '../actions/enroll';

/**
 * State reducer for enroll status
 * @param {Object} state
 * @param {Object} action
 * @return {Object}
 */
export const enrollStatus = (state = { busy: false }, action) => {
    switch (action.type) {
        case START_ENROLL:
            return { ...state, busy: true };
        case RESET_ENROLL:
        case FINISH_ENROLL:
        case PROMPT_ENROLL:
            return { ...state, busy: false };
        default:
            return state;
    }
};
