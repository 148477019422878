import { takeLatest, call, delay } from 'redux-saga/effects';
import { store } from '../store';
import {
    AuthAction,
    AuthActionLogin,
    AuthActionLogout,
    unAuthAction,
    logOut,
} from '../actions/user';

export const DefaultMSDelay = 2000;

/**
 * Checks for 401 status
 * @param {*} action latest action that is dispatched
 */
function checkRequestResponse(action) {
    if (action?.type?.includes('REQUEST_FAILED')) {
        if (
            (action.error === 'number' && action.error === 401) ||
            (action.error === 'string' && action.error.includes('401'))
        ) {
            store.dispatch(unAuthAction);
        }
    }
}

/**
 * Logging incoming auth and unatuh actions
 * @param {*} action latest auth action that is dispatched
 */
function handleAuthAction(action) {
    if (action.action === AuthActionLogin) {
        STDHeaders.set('X-Auth', action.bearer);
        localStorage.setItem('auth', action.bearer);
    }
}

/**
 * Logging incoming auth and unatuh actions
 * @param {*} action latest auth action that is dispatched
 */
function handleUnAuthAction(action) {
    if (
        action.action === AuthActionLogout &&
        localStorage.getItem('auth') !== '' &&
        localStorage.getItem('auth') !== null
    ) {
        window.displayMessage(`Jouw sessie is verlopen`, 'error');
        const { user } = store.getState();
        if (user.busy) {
            return;
        }
        store.dispatch(logOut());
        localStorage.clear();
    }
}

/**
 * Runs enrollment flow
 * @param {Object} action
 */
function* unauthChecker(action) {
    yield call(checkRequestResponse, action);
}

/**
 * Runs enrollment flow
 * @param {Object} action
 */
function* authChecker(action) {
    yield call(handleAuthAction, action);
    yield delay(DefaultMSDelay);
    yield call(handleUnAuthAction, action);
}

/**
 * Watcher for any state change
 */
export function* watchStateChange() {
    yield takeLatest('*', unauthChecker);
}

/**
 * Watcher for any state change
 */
export function* watchAuthStateChange() {
    yield takeLatest(AuthAction, authChecker);
}
