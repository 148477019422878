import {
    DELETE_ALL_NOTIFICATIONS,
    SetNotificationAction,
    SET_NOTIFICATION,
} from '@actions/notification';
import { notificationBarComponentType } from 'types/notification';

export interface NotificationState {
    routePaths?: string[];
    information: string;
    cta?: {
        text: string;
        callback: () => any;
    };
    duration?: {
        fixed: boolean;
        ms: number;
    };
    notificationBarType?: notificationBarComponentType;
}

const defaultState: NotificationState[] = [];

/**
 * State reducer for notification
 */
export const notification = (
    state: NotificationState[] = defaultState,
    action: SetNotificationAction,
): NotificationState[] => {
    switch (action.type) {
        case SET_NOTIFICATION:
            return [
                ...state,
                {
                    routePaths: action.routePaths,
                    information: action.information,
                    cta: action.cta,
                    duration: action.duration,
                    notificationBarType: action.notificationBarType,
                },
            ];
        case DELETE_ALL_NOTIFICATIONS:
            return [];
        default:
            return state;
    }
};
