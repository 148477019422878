import { css } from 'lit-element';

export default css`
    :host {
        /* skeleton */
        --skeleton-row-height: 25px;
        --skeleton-row-width: 100%;
        --skeleton-row-padding: 34px 30px;
        --skeleton-column-height: 53px;
        --skeleton-column-width: 100%;
        --skeleton-column-padding-top: 34px;
        --skeleton-column-padding-bottom: 34px;
        --skeleton-background-image: linear-gradient(
                100deg,
                rgba(255, 255, 255, 0) 0%,
                rgba(255, 255, 255, 0.5) 50%,
                rgba(255, 255, 255, 0) 100%
            ),
            radial-gradient(rgba(211, 211, 211, 0.5) 99%, transparent 0);
        --skeleton-background-size: 50% 100%, 100% 100%;
        --skeleton-animation: shine 1s infinite;
        --grid__header--background--color: #f5f5f5;
        --grid__header--font-weight: 900;

        display: flex;
        flex-direction: column;
        flex-grow: 1;
        box-sizing: border-box;
    }

    grid-column,
    grid-sorter {
        --grid__header--background--color: var(
            --grid__header--background--color
        );
    }

    :host([compact]) {
        padding: 0;
        height: auto;
        max-height: 500px;
        overflow-y: scroll;
    }

    .empty {
        background: var(--white);
        font-family: var(--font-family-primary);
        font-weight: 500;
        font-size: 17px;
        line-height: 23px;
        text-align: center;
        color: #082446;
        padding: 34px 0;
        border-radius: 15px;
    }

    .grid-component-body {
        display: flex;
        position: relative;
    }

    .icon-indicator {
        margin-right: 25px;
    }
`;
