import { User } from 'types/user';
import { authCheck, receiveSelf, signIn } from '@async-reducers/authentication';
import { Router } from '@vaadin/router';
import { routePaths } from 'data-access/router';
import { store } from 'data-access/store';
import { SuccessAction } from 'goagen-js-resource';
import { ForkEffect, put, takeLatest } from 'redux-saga/effects';
import { updateUserAuthenticated } from '../actions/app';
import { setError } from '../actions/error';
import { awaitLogin } from '../actions/routing';
import { resetSnapshots, setSnapshots } from '../actions/snapshot';
import {
    forcedUserToSnapshotIfOpen,
    receiveUser,
    userOnboardingFinished,
} from '../actions/user';

// helper functions
const clearCache = () => {
    localStorage.clear();
    window.STDHeaders.delete('X-Auth');
    // @ts-ignore
    const { searchParams } = new URL(window.location);
    // checks if auth param exists
    if (!searchParams.has('auth')) {
        searchParams.delete('auth');
    }
    Router.go(routePaths.login);
};

const authenticationCheck = () => {
    if (store.getState().authCheck!) {
        store.dispatch(authCheck.run(true));
    }
};

const setUser = (user: User) => {
    const usr = store.getState().user as User;
    if (user == null || usr.id !== user.id) {
        store.dispatch(receiveUser(user));
    }
};

// succes iterator functions
function* signInSucces(success: SuccessAction) {
    const { user, contracts, snapshots } = success.data;
    authenticationCheck();

    // checks if user accepted his terms
    if (forcedUserToSnapshotIfOpen(user.snapshots)) {
        yield put(awaitLogin(false));
        return;
    }

    if (contracts && contracts.length > 0) {
        store.dispatch(userOnboardingFinished(user));
    }
    if (!user.contracts && !user.snapshots) {
        yield put(updateUserAuthenticated(false));
    }
    // set snapshot if any
    if (Array.isArray(snapshots)) {
        yield put(setSnapshots(snapshots));
    } else {
        yield put(resetSnapshots());
    }
    setUser(user);
    // TODO: future check for routePath params to redirect to given param?
    Router.go(routePaths.overview);

    yield put(awaitLogin(false));
}

function* receiveSelfSuccess(success: SuccessAction) {
    const appState = success.data;
    if (appState?.user?.email === undefined) {
        return;
    }
    const { user, snapshots, contracts } = appState;
    authenticationCheck();

    setUser(user);
    if (contracts && contracts.length > 0) {
        store.dispatch(userOnboardingFinished(user));
    }
    if (appState && Array.isArray(snapshots)) {
        yield put(setSnapshots(snapshots));
    } else {
        yield put(resetSnapshots());
    }
    yield put(awaitLogin(false));
}

function* signInFailed() {
    yield put(awaitLogin(false));
    clearCache();
    yield put(setError('Inloggen mislukt, probeer opnieuw!'));
}

function* receiveSelfError() {
    yield put(setError('Ophalen van gebruikers informatie mislukt'));
    clearCache();
}

/**
 * Watcher for sign in
 */
export function* watchAuthentication(): IterableIterator<ForkEffect> {
    // success
    yield takeLatest(signIn.actionTypeMap.callback, signInSucces);
    yield takeLatest(receiveSelf.actionTypeMap.callback, receiveSelfSuccess);
    // failed
    yield takeLatest(signIn.actionTypeMap.error, signInFailed);
    yield takeLatest(receiveSelf.actionTypeMap.error, receiveSelfError);
}
