import { css } from 'lit-element';

export default css`
    :host {
        --warning-background-color: #fefbf5;
        --notify-background-color: #f5fbfe;
        --warning-font-color: #dba402;
        --notify-font-color: #008cdb;
        --font-size: 14px;
    }

    #reminder-btn {
        margin: 25px 0;
    }

    .messageBox {
        margin-bottom: 20px;
        padding: 15px 25px;
        border-radius: 3px;
        font-weight: bold;
        border-width: 1px;
        border-style: solid;
    }

    .warning {
        background-color: var(--warning-background-color);
        border-color: var(--warning-font-color);
        color: var(--warning-font-color);
        font-size: var(--font-size);
    }

    .notify {
        background-color: var(--notify-background-color);
        border-color: var(--notify-font-color);
        color: var(--notify-font-color);
        font-size: var(--font-size);
    }

    hv-loader {
        margin-top: 35px;
    }
`;
