import { customElement } from 'lit-element';

import { LionCheckboxGroup } from '@lion/checkbox-group';
import { css } from '@lion/core';

@customElement('hv-checkbox-group')
export class HvCheckboxGroup extends LionCheckboxGroup {
    static get styles() {
        return [
            ...super.styles,
            css`
                ::slotted(label) {
                    font-weight: 600;
                }
            `,
        ];
    }

    constructor() {
        super();
    }
}

declare global {
    interface HTMLElementTagNameMap {
        'hv-checkbox-group': HvCheckboxGroup;
    }
}
