import { Validator } from '@lion/form-core';

export const specialCharacters = /[!@#$%^*()+=?.,{};:[\]-]+/;

export class ContainsSpecialCharacter extends Validator {
    static get validatorName() {
        return 'ContainsSpecialCharacter';
    }

    // eslint-disable-next-line class-methods-use-this
    execute(value: any) {
        return typeof value !== 'string' || !specialCharacters.test(value);
    }
}
