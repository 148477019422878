import { combineReducers } from 'redux';

import app from './app';
import * as busy from './busy';
import * as calculator from './calculator';
import * as collective from './collective';
import * as invoice from './invoice';
import * as company from './company';
import * as enroll from './enroll';
import error from './error';
import * as geocode from './geocode';
import * as loginPrompt from './loginPrompt';
import offers from './offers';
import * as order from './order';
import usage from './usage';
import * as user from './user';
import * as userLocation from './userLocation';
import * as userPaymentAccount from './userPaymentAccount';
import * as userSnapshot from './userSnapshot';
import * as graph from './graph';
import * as savings from './savings';
import * as insights from './insights';
import * as redirect from './redirect';
import * as energysplitter from './energysplitter';
import * as menu from './menu';
import * as loader from './loader';
import * as snapshot from './snapshot';
import * as costValues from './costValues';
import * as history from './history';
import * as routing from './routing';
import * as notification from './notification';
import { asyncReducers } from '../async-reducers';

/** @typedef {import('../resources/helpers/Resource').Resource} Resource */
/** @typedef {import('redux').Reducer} Reducer */

/**
 *
 * @param  {...Resource} resources
 * @return {Reducer}
 */
const reducers = (...resources) => {
    /** @type {Object} */
    const resourceReducers = {};
    resources.forEach((resource) => {
        resourceReducers[resource.storeName] = resource.reducer();
    });
    return combineReducers({
        ...resourceReducers,
        app,
        ...busy,
        ...calculator,
        ...collective,
        ...invoice,
        ...company,
        ...enroll,
        error,
        ...geocode,
        ...loginPrompt,
        offers,
        ...order,
        usage,
        ...user,
        ...userLocation,
        ...userPaymentAccount,
        ...userSnapshot,
        ...graph,
        ...savings,
        ...insights,
        ...redirect,
        ...asyncReducers,
        ...energysplitter,
        ...menu,
        ...loader,
        ...snapshot,
        ...costValues,
        ...history,
        ...routing,
        ...notification,
    });
};

export default reducers;
