import {
    LitElement,
    TemplateResult,
    customElement,
    html,
    css,
} from 'lit-element';
import { connect } from 'lit-redux-watch';

import '../../../../../elements/base/button/ButtonComponent';
import { whitelabel } from '@weavelab/whitelabel';
import '../../../../../atoms/icon/HvIcon';

@customElement('no-smart-meter')
@whitelabel({ name: 'no-smart-meter' })
export class NoSmartMeter extends connect(window.store)(LitElement) {
    @whitelabel() // Allow styles to be injected from theme
    static get styles() {
        return [
            css`
                :host {
                    --container--background-color: #fef0ec;
                    --container--color: #ee4411;
                    --container__title__icon--color: #ee4411;
                    --container--font-size: 13px;
                }
                #container {
                    color: var(--container--color);
                    display: flex;
                    flex-direction: column;
                    background-color: var(--container--background-color);
                    padding: 10px 20px;
                    border-radius: 10px;
                    margin-bottom: 20px;
                    font-size: var(--container--font-size);
                }
                #container__title {
                    font-style: italic;
                    display: flex;
                    align-items: center;
                }
                #container__title__icon {
                    font-size: 30px;
                    padding-bottom: 8px;
                    margin-right: 7px;
                    --hv-icon-color: var(--container__title__icon--color);
                }
                #container__body__attention {
                    font-weight: bold;
                }
            `,
        ];
    }

    public render = (): TemplateResult => html`
        <div id="container">
            <h3 id="container__title">
                <hv-icon
                    id="container__title__icon"
                    name="warning"
                    tone="outlined"
                ></hv-icon>
                Let op: er is op dit adres nog geen slimme meter
            </h3>
            <p id="container__body">
                Om te kunnen profiteren van dynamische tarieven voor stroom en
                gas is een slimme meter noodzakelijk.
                <span id="container__body__attention"
                    >We hebben je hier een e-mail over gestuurd.</span
                >
            </p>
        </div>
    `;
}

declare global {
    interface HTMLElementTagNameMap {
        'no-smart-meter': NoSmartMeter;
    }
}
