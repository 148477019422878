import { EnergysplitterLogs } from './../types/energysplitter';
import { SET_ENERGYSPLITTER_LOGS } from '../actions/energysplitter';

export interface Energysplitter {
    activationLogs: EnergysplitterLogs[];
}

const defaultEnergysplitter = {
    activationLogs: [],
};

/**
 * State reducer for energysplitter
 */
export const energysplitter = (
    state = { ...defaultEnergysplitter },
    action: any,
): Energysplitter => {
    switch (action.type) {
        case SET_ENERGYSPLITTER_LOGS:
            return { ...state, activationLogs: [...action.logs] };
        default:
            return state;
    }
};
