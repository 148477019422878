import { ReduxAsync } from '@weavedev/redux-async';
import axios, { AxiosResponse } from 'axios';
import headers from '../sagas/helpers/headers';

const VENDOR_CONFIG = 'VENDOR_CONFIG';
export const VENDOR_CONFIG_SUCCESS = 'VENDOR_CONFIG_SUCCESS';
export const VENDOR_CONFIG_FAILED = 'VENDOR_CONFIG_FAILED';
export const vendorConfig = new ReduxAsync(
    VENDOR_CONFIG,
    VENDOR_CONFIG_SUCCESS,
    VENDOR_CONFIG_FAILED,
    async (vendorID: string): Promise<any> => {
        const response: AxiosResponse = await axios.get(
            `/v2/vendors/${vendorID}/config`,
            {
                baseURL: window.API_LINK,
                headers: headers(window.STDHeaders),
            },
        );
        if (response.statusText === 'OK' || response.status === 200) {
            return response.data;
        } else {
            throw response.status;
        }
    },
);
