import { css } from 'lit-element';

export default css`
    :host {
        --product--color: #95a4ac;
        --product--border-color: var(--product--color);
        --product--border: 1px solid var(--product--border-color);
        --product--border-radius: 4px;
        --product--h1-size: 26px;
        --product--h1-margin: 0 0 20px 0;
        --product--description-size: 14px;
        --product--description-line-height: 20px;

        padding: 40px;
        border-radius: var(--product--border-radius);
        border: var(--product--border);
        display: flex;
        width: max-content;
        flex-direction: column;
    }

    h1 {
        color: var(--product--color);
        font-family: inherit;
        font-size: var(--product--h1-size);
        margin: var(--product--h1-margin);
    }

    p {
        color: var(--product--color);
        font-family: inherit;
        margin: 0;
        padding: 0;
        font-size: var(--product--description-size);
        line-height: var(--product--description-line-height);
    }
`;
