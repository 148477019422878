import {
    Bearer,
    BearerSupportedTypes,
    ParameterDefinitionsObject,
    ParameterIn,
    RequestAction,
    RequestOptions,
    RequestSagaIterableIterator,
    ResolvableOrType,
    Resource,
    ResourceOptions,
    UUID,
    UUIDSupportedTypes,
} from 'goagen-js-resource';
import { Method } from 'axios';

export interface ListVkoDocumentsUserRequestObject {
    // (String)
    filter?: string;
    // (String)
    order?: string;
    // (Number)
    pageNumber?: number;
    // (Number)
    pageSize?: number;
    // (UUID)
    snapshotId?: UUIDSupportedTypes;
    // (UUID)
    userId?: UUIDSupportedTypes;
    // Injected Bearer header (Bearer)
    xAuth?: BearerSupportedTypes;
}

export interface ResolvableListVkoDocumentsUserRequestObject {
    // (String)
    filter?: ResolvableOrType<ListVkoDocumentsUserRequestObject['filter']>;
    // (String)
    order?: ResolvableOrType<ListVkoDocumentsUserRequestObject['order']>;
    // (Number)
    pageNumber?: ResolvableOrType<
        ListVkoDocumentsUserRequestObject['pageNumber']
    >;
    // (Number)
    pageSize?: ResolvableOrType<ListVkoDocumentsUserRequestObject['pageSize']>;
    // (UUID)
    snapshotId?: ResolvableOrType<
        ListVkoDocumentsUserRequestObject['snapshotId']
    >;
    // (UUID)
    userId?: ResolvableOrType<ListVkoDocumentsUserRequestObject['userId']>;
    // Injected Bearer header (Bearer)
    xAuth?: ResolvableOrType<ListVkoDocumentsUserRequestObject['xAuth']>;
}

/**
 * Store member management object for list vko documents user
 */
export class ListVkoDocumentsUser extends Resource<
    ListVkoDocumentsUserRequestObject,
    ResolvableListVkoDocumentsUserRequestObject
> {
    /**
     * Create new ListVkoDocumentsUser
     *
     * | Parameter |  | Target |  |  |
     * | --- | --- | --- | --- | --- |
     * | `filter` | `String` | query | `filter` |  |
     * | `order` | `String` | query | `order` |  |
     * | `pageNumber` * | `Number` | query | `page_number` |  |
     * | `pageSize` * | `Number` | query | `page_size` |  |
     * | `snapshotId` * | `UUID` | query | `snapshot_id` |  |
     * | `userId` * | `UUID` | query | `user_id` |  |
     * | `xAuth` | `Bearer` | header | `X-Auth` | Injected Bearer header |
     */
    constructor(
        storeName: string,
        options: ResourceOptions<ResolvableListVkoDocumentsUserRequestObject> = {},
    ) {
        super(storeName, options);
    }

    /**
     * Generate request action
     *
     * | Parameter |  | Target |  |  |
     * | --- | --- | --- | --- | --- |
     * | `filter` | `String` | query | `filter` |  |
     * | `order` | `String` | query | `order` |  |
     * | `pageNumber` * | `Number` | query | `page_number` |  |
     * | `pageSize` * | `Number` | query | `page_size` |  |
     * | `snapshotId` * | `UUID` | query | `snapshot_id` |  |
     * | `userId` * | `UUID` | query | `user_id` |  |
     * | `xAuth` | `Bearer` | header | `X-Auth` | Injected Bearer header |
     */
    public request(
        requestObject: ListVkoDocumentsUserRequestObject = {},
        requestOptions: RequestOptions = {},
    ): RequestAction<ListVkoDocumentsUserRequestObject> {
        return super.request(requestObject, requestOptions);
    }

    /**
     * Run request as saga
     *
     * | Parameter |  | Target |  |  |
     * | --- | --- | --- | --- | --- |
     * | `filter` | `String` | query | `filter` |  |
     * | `order` | `String` | query | `order` |  |
     * | `pageNumber` * | `Number` | query | `page_number` |  |
     * | `pageSize` * | `Number` | query | `page_size` |  |
     * | `snapshotId` * | `UUID` | query | `snapshot_id` |  |
     * | `userId` * | `UUID` | query | `user_id` |  |
     * | `xAuth` | `Bearer` | header | `X-Auth` | Injected Bearer header |
     */
    // @ts-ignore
    public get requestSaga(): (
        requestObject?: ListVkoDocumentsUserRequestObject,
        requestOptions?: RequestOptions,
    ) => RequestSagaIterableIterator<
        RequestAction<ListVkoDocumentsUserRequestObject>
    > {
        return super.requestSaga;
    }

    /**
     * Action string
     */
    static get action(): string {
        return 'list vko documents';
    }

    /**
     * Description string
     */
    static get description(): string {
        return 'List all vko documents for a snapshot';
    }

    /**
     * Request method
     */
    static get method(): Method {
        return 'get';
    }

    /**
     * Resource string
     */
    static get resource(): string {
        return 'user';
    }

    /**
     * Route string
     */
    static get route(): string {
        return '/user/document/vko';
    }

    /**
     * Unique name string
     */
    static get uniqueName(): string {
        return 'ListVkoDocumentsUser';
    }

    /**
     * Parameters object
     */
    static get parameters(): ParameterDefinitionsObject {
        return {
            filter: {
                in: ParameterIn.query,
                name: 'filter',
                type: String,
            },
            order: {
                in: ParameterIn.query,
                name: 'order',
                type: String,
            },
            pageNumber: {
                in: ParameterIn.query,
                name: 'page_number',
                required: true,
                type: Number,
            },
            pageSize: {
                in: ParameterIn.query,
                name: 'page_size',
                required: true,
                type: Number,
            },
            snapshotId: {
                in: ParameterIn.query,
                name: 'snapshot_id',
                required: true,
                type: UUID,
            },
            userId: {
                in: ParameterIn.query,
                name: 'user_id',
                required: true,
                type: UUID,
            },
            xAuth: {
                description: 'Injected Bearer header',
                in: ParameterIn.header,
                name: 'X-Auth',
                type: Bearer,
            },
        };
    }
}
