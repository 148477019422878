import { ReduxLazy } from '@weavedev/redux-lazy';
import { ReduxAsync } from '@weavedev/redux-async';
import axios, { AxiosResponse } from 'axios';
import headers from '../sagas/helpers/headers';

export interface OdaOnboardingState {
    active: boolean;
    skipped: boolean;
    hasGas: boolean;
}

export const odaOnboardingState: OdaOnboardingState = {
    active: false,
    skipped: false,
    hasGas: false,
};

const ODA_ONBOARDING = 'ODA_ONBOARDING';
export const setOdaOnboarding = new ReduxLazy(
    ODA_ONBOARDING,
    odaOnboardingState,
);

// accept oda
const ACCEPT_ODA = 'ACCEPT_ODA';
const ACCEPT_ODA_SUCCESS = 'ACCEPT_ODA_SUCCESS';
const ACCEPT_ODA_FAILED = 'ACCEPT_ODA_FAILED';
export const acceptOda = new ReduxAsync(
    ACCEPT_ODA,
    ACCEPT_ODA_SUCCESS,
    ACCEPT_ODA_FAILED,
    async (
        smpID: string,
        snapshotID: string,
        gasRequested: boolean,
    ): Promise<void> => {
        await axios.post(
            `/v2/metering-point/oda/accept`,
            {
                smp_id: smpID,
                snapshot_id: snapshotID,
                gas_requested: gasRequested,
            },
            {
                baseURL: window.API_LINK,
                headers: headers(window.STDHeaders),
            },
        );
    },
);

// verify oda
const VERIFY_ODA = 'VERIFY_ODA';
const VERIFY_ODA_SUCCESS = 'VERIFY_ODA_SUCCESS';
const VERIFY_ODA_FAILED = 'VERIFY_ODA_FAILED';
export const verifyOda = new ReduxAsync(
    VERIFY_ODA,
    VERIFY_ODA_SUCCESS,
    VERIFY_ODA_FAILED,
    async (
        smpID: string,
        snapshotID: string,
        barcode: string,
    ): Promise<void> => {
        await axios.post(
            `/v2/metering-point/oda/verify`,
            {
                smp_id: smpID,
                snapshot_id: snapshotID,
                barcode,
            },
            {
                baseURL: window.API_LINK,
                headers: headers(window.STDHeaders),
            },
        );
    },
);

// init oda
const INIT_ODA = 'INIT_ODA';
const INIT_ODA_SUCCESS = 'INIT_ODA_SUCCESS';
const INIT_ODA_FAILED = 'INIT_ODA_FAILED';
export const initOda = new ReduxAsync(
    INIT_ODA,
    INIT_ODA_SUCCESS,
    INIT_ODA_FAILED,
    async (snapshotID: string): Promise<void> => {
        const response: AxiosResponse = await axios.post(
            `/v2/metering-point/oda/init`,
            {
                snapshot_id: snapshotID,
            },
            {
                baseURL: window.API_LINK,
                headers: headers(window.STDHeaders),
            },
        );
        return response.data;
    },
);
