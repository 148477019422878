import {
    createWorkday,
    deleteWorkday,
    getListWorkdays,
    DELETE_WORKDAY_SUCCESS,
    DELETE_WORKDAY_FAILED,
    CREATE_WORKDAY_SUCCESS,
} from './../async-reducers/schedule';

import { call, takeLatest, put, race, take } from 'redux-saga/effects';
import { ScheduleEntry } from 'types/schedule';

export const EDIT_WORKDAY = 'START_EDIT_WORKDAY';

export const editWorkday = (
    snapshotID: string,
    date: Date,
    entries: ScheduleEntry[],
    workDayID?: string,
) => ({
    type: 'START_EDIT_WORKDAY',
    snapshotID,
    date,
    entries,
    workDayID,
});

/**
 * Watcher for EDIT_WORKDAY action
 */
export function* watchEditWorkDay() {
    yield takeLatest(EDIT_WORKDAY, doEditWorkday);
}

function* doEditWorkday(action: any) {
    if (action.workDayID) {
        yield call(deleteIt, action.workDayID);
    }
    yield call(addIt, action.snapshotID, action.date, action.entries);
    yield fetchWorkdays();
}

function* deleteIt(workdayID: string) {
    yield put(deleteWorkday.run(workdayID));
    yield race([take(DELETE_WORKDAY_SUCCESS), take(DELETE_WORKDAY_FAILED)]);
}

function* addIt(snapshotID: string, date: Date, entries: any) {
    yield put(createWorkday.run(snapshotID, date, entries));
    yield race([take(CREATE_WORKDAY_SUCCESS)]);
}

function* fetchWorkdays() {
    const snapshot = window.store.getState().jwtSnapshots.selectedSnapshot;
    if (snapshot) {
        yield put(getListWorkdays.run(snapshot));
    }
}
