import { takeLatest, ForkEffect } from 'redux-saga/effects';
import { ErrorAction } from 'goagen-js-resource';
import { resetPassword } from '../async-reducers/user';
import { Router } from '@vaadin/router';
import { routePaths } from '../helpers/routing';

/**
 * Watcher for REQUEST_GRAPH_SUCCESS action
 */
export function* watchResetPassword(): IterableIterator<ForkEffect> {
    yield takeLatest([resetPassword.actionTypeMap.error], resetPasswordFailed);
}

/**
 * If snapshot gets updated update the selected snapshot with the returend snapshot.
 */
const resetPasswordFailed: (result: ErrorAction) => void = (
    result: ErrorAction,
): void => {
    const response = result.error.response;
    if (
        response.status === 500 &&
        response.data?.detail === 'record not found'
    ) {
        Router.go(`..${routePaths.forgot}`);
        window.displayMessage(
            'De huidige link is verlopen, vraag een nieuwe wachtwoord vergeten link aan.',
        );
    } else {
        window.displayMessage(
            'Wachtwoord wijzigen niet gelukt. Probeer het later nog eens.',
        );
    }
};
