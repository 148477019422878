import { customElement, property } from 'lit-element';

import { css } from '@lion/core';
import { LionCheckbox } from '@lion/checkbox-group';
import '@atoms/HvCheckboxButton';

@customElement('hv-checkbox')
export class HvCheckbox extends LionCheckbox {
    @property({ type: Boolean })
    recommend: boolean = false;

    get slots() {
        return {
            ...super.slots,
            input: () => {
                const hvCheckboxButton =
                    document.createElement('hv-checkbox-button');
                if (hvCheckboxButton) {
                    (hvCheckboxButton as HTMLElement).setAttribute(
                        'checked',
                        String(this.checked),
                    );
                }
                return hvCheckboxButton;
            },
        };
    }

    static get styles() {
        return [
            css`
                :host {
                    --hv-checkbox-border: 1px solid #e5e5e5;
                    --hv-checkbox-border--checked: 1px solid #3aad3a;
                    --hv-checkbox-background: #f2faf2;
                    --hv-checkbox-margin-vertical: 0;
                    --hv-checkbox-margin-horizontal: 0;
                    --hv-checkbox-padding-vertical: 15px;
                    --hv-checkbox-padding-horizontal: 15px;
                    --hv-checkbox-label-font-size: 13px;
                    --hv-checkbox-label-icon-width: 15px;
                    --hv-checkbox-label-icon-height: 15px;
                    --hv-checkbox-label-icon-margin-right: 5px;
                    --hv-checkbox-button-margin-left: 15px;
                    --hv-checkbox-label-width: 100%;
                    --hv-checkbox-label-spacing: 10px;
                    border: var(--hv-checkbox-border);
                    margin: var(--hv-checkbox-margin-vertical)
                        var(--hv-checkbox-margin-horizontal);
                    padding: var(--hv-checkbox-padding-vertical)
                        var(--hv-checkbox-padding-horizontal);
                    display: flex;
                    flex-wrap: no-wrap !important;
                }
                :host(:first-child) {
                    margin-top: var(--hv-checkbox-label-spacing);
                }
                :host([checked]) {
                    border: var(--hv-checkbox-border--checked);
                    background: var(--hv-checkbox-background);
                    display: flex;
                    z-index: 2;
                }
                ::slotted(label) {
                    font-size: var(--hv-checkbox-label-font-size);
                    width: var(--hv-checkbox-label-width);
                    display: flex;
                    flex-direction: column;
                    font-size: var(--hv-checkbox-label-font-size);
                }
            `,
        ];
    }

    constructor() {
        super();

        this.addEventListener('click', (ev) => {
            ev.stopPropagation();
            this.dispatchEvent(
                new Event('user-input-changed', {
                    bubbles: true,
                    composed: true,
                }),
            );
        });
    }
}

declare global {
    interface HTMLElementTagNameMap {
        'hv-checkbox': HvCheckbox;
    }
}
