import { LitElement, TemplateResult, customElement, html } from 'lit-element';

import SmartChargingStepWrapperStyle from './SmartChargingStepWrapperStyle';

/**
 * Wrapper for a step that contains the next step button that triggers the
 * next and previous events
 *
 * These events will be caught by the WeaveStep.ts because this component must
 * be a inner child of <weave-step>.
 *
 * WeaveStep: catches event > validates > goesto next/previous step
 */
@customElement('template-step-wrapper')
export class SmartChargingStepWrapper extends LitElement {
    static get styles() {
        return [SmartChargingStepWrapperStyle];
    }

    public render = (): TemplateResult => html`
        <slot></slot>
        <button @click="${this.prevHandler}">Trigger prev</button>
        <button @click="${this.nextHandler}">Trigger next</button>
    `;

    // let parent components know that a previous step has been trigger in this ui
    public prevHandler = (): void => {
        this.dispatchEvent(
            new CustomEvent('weave-step-previous', {
                bubbles: true,
                composed: true,
            }),
        );
    };

    // let parent components know that a previous step has been trigger in this ui
    public nextHandler = (): void => {
        this.dispatchEvent(
            new CustomEvent('weave-step-next', {
                bubbles: true,
                composed: true,
            }),
        );
    };
}

declare global {
    interface HTMLElementTagNameMap {
        'template-step-wrapper': SmartChargingStepWrapper;
    }
}
