import {
    CSSResultArray,
    LitElement,
    PropertyValues,
    customElement,
    html,
    property,
    query,
} from 'lit-element';
import { TemplateResult } from 'lit-html';
import { whitelabel } from '@weavelab/whitelabel';

import style from './style';
import sharedStyles from '../../../sharedStyles';
import '../base/button/ButtonComponent';
import { litFontawesome } from '@weavedev/lit-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons/faTrashAlt';

export type theme = 'center' | 'left';

/**
 * this component creates a modal in te middle of an existing page.
 */
@customElement('modal-component')
@whitelabel({ name: 'modal-component' })
export class ModalComponent extends LitElement {
    @property({
        type: Boolean,
    })
    public opened: boolean = false;

    @property({ attribute: 'header-title' })
    public headerTitle?: string;

    @property({ attribute: 'sub-title' })
    public subTitle?: string;

    @property({ attribute: 'call-to-action-button-label' })
    public ctaButtonLabel?: string;

    @property({ attribute: 'call-to-action-button-theme' })
    public ctaButtonTheme: string = 'secondary';

    @property({ attribute: 'call-to-action-callback', type: Function })
    public ctaCallback?: () => void;

    @property({ type: Object })
    public modal: HTMLElement | null = null;

    @property({ type: String })
    public width?: string;

    @property({ attribute: 'max-width', type: String })
    public maxWidth?: string;

    @property({ type: String, reflect: true })
    public theme: theme = 'center';

    @property({ attribute: 'hide-close-button', reflect: true, type: Boolean })
    public hideCloseButton: boolean = false;

    @property({ attribute: 'show-trash-icon', reflect: true, type: Boolean })
    public showTrashIcon: boolean = false;

    @query('#overlay')
    public overlay?: HTMLElement;

    @property({
        type: Boolean,
        reflect: true,
        attribute: true,
    })
    public closable: boolean = true;

    @property({
        type: Boolean,
        reflect: true,
        attribute: 'prevent-closing-outside',
    })
    public preventClosingOutside?: boolean;

    @whitelabel() // Allow styles to be injected from theme
    static get styles(): CSSResultArray {
        return [sharedStyles, style];
    }

    private hasTitle = () => this.headerTitle || this.subTitle;

    private callToActionClicked = (): void => {
        if (this.ctaCallback != null) {
            this.ctaCallback();
            return;
        }
        this.close();
    };

    private renderClosingButton = (): TemplateResult => {
        return html` <div
            class="close ${this.hideCloseButton ? 'hidden' : ''}"
            @click="${this.closeWindow}"
        >
            ${this.showTrashIcon ? litFontawesome(faTrashAlt) : html`×`}
        </div>`;
    };

    private renderCallToActionButton = (): TemplateResult | null => {
        if (!this.ctaButtonLabel) {
            return null;
        }
        return html`
            <button-component
                label="${this.ctaButtonLabel}"
                theme="${this.ctaButtonTheme}"
                ?hidden=${!this.closable}
                @click="${this.callToActionClicked}"
            ></button-component>
        `;
    };

    private renderHeader = () => {
        return html`<header>
            ${this.headerTitle ? html`<h1>${this.headerTitle}</h1>` : null}
            ${this.subTitle ? html`<p>${this.subTitle}</p>` : null}
            ${this.renderClosingButton()}
        </header>`;
    };

    protected render = (): TemplateResult => html`
        <div class="modal-content">
            ${this.hasTitle()
                ? this.renderHeader()
                : this.renderClosingButton()}
            <main>
                <slot></slot>
                ${this.renderCallToActionButton()}
            </main>
        </div>
        <div id="overlay"></div>
    `;

    public updated(changedProperties: PropertyValues) {
        if (changedProperties.has('opened') && this.overlay) {
            this.opened
                ? (this.overlay.style.display = 'block')
                : (this.overlay.style.display = 'none');
        }
        if (changedProperties.has('preventClosingOutside')) {
            if (this.overlay) {
                if (this.preventClosingOutside) {
                    this.overlay.removeEventListener(
                        'click',
                        this.handleOverlayClose,
                        false,
                    );
                } else {
                    this.overlay.addEventListener(
                        'click',
                        this.handleOverlayClose,
                    );
                }
            }
        }
    }

    private handleOverlayClose = (e: MouseEvent) => {
        e.stopImmediatePropagation();
        if (this.closable) {
            this.fireEvent('modal-overlay-closed');
            this.close();
        }
    };

    private closeWindow = (e: MouseEvent): void => {
        e.stopImmediatePropagation();
        this.fireEvent('modal-window-closed');
        this.close();
    };

    public open = (): void => {
        this.opened = true;
        this.style.display = 'flex';
        if (this.overlay) {
            this.overlay.style.display = 'block';
        }
        this.fireEvent('modal-opened');
        dispatchEvent(new CustomEvent('close-overlay'));
    };

    public close = (): void => {
        this.style.display = 'none';
        if (this.overlay) {
            this.overlay.style.display = 'none';
        }
        this.opened = false;
        this.fireEvent('modal-closed');
    };

    public status = (): boolean => this.opened;

    public firstUpdated() {
        this.modal = this.shadowRoot!.querySelector('modal') as HTMLElement;
        if (this.overlay == null) {
            return;
        }

        this.overlay.addEventListener('click', this.handleOverlayClose);
    }

    public fireEvent = (eventType: string): void => {
        this.dispatchEvent(
            new CustomEvent(eventType, {
                bubbles: true,
                composed: true,
                detail: { target: this },
            }),
        );
    };
}

declare global {
    interface HTMLElementTagNameMap {
        'modal-component': ModalComponent;
    }
}
