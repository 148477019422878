import { LitElement, customElement, html, CSSResult } from 'lit-element';
import style from './HvIntroductionStyle';

@customElement('hv-introduction')
export class HvIntroduction extends LitElement {
    public static get styles(): CSSResult[] {
        return [style];
    }

    render() {
        return html`<slot></slot>`;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        'hv-introduction': HvIntroduction;
    }
}
