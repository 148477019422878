// eslint-disable-next-line max-classes-per-file
import {
    customElement,
    LitElement,
    TemplateResult,
    html,
    css,
    CSSResult,
    property,
    PropertyValues,
} from 'lit-element';
import { whitelabel } from '@weavelab/whitelabel';
import { _defaults } from '../helpers/_defaults';
import { ironFlex } from '../helpers/iron-flex-layout/iron-flex';

@customElement('radio-button-group')
@whitelabel({ name: 'radio-button-group' })
export class RadioButtonGroup extends LitElement {
    @property({ type: String, attribute: 'text-true' })
    public textTrue: string = '';

    @property({ type: String, attribute: 'text-false' })
    public textFalse: string = 'Nee';

    @property({ type: Boolean, attribute: 'group-value' })
    public groupValue: boolean = false;

    @whitelabel()
    public static get styles(): CSSResult {
        return css`
            ${ironFlex}
            ${_defaults}

            :host([vertical]) .emptySpace {
                display: none;
            }

            :host([vertical]) .radioButtonWrapper {
                flex-direction: column;
            }
        `;
    }

    public render = (): TemplateResult => html`
        <div class="radioButtonWrapper">
            <div
                class="layout horizontal radioWrapper"
                @click="${this.setValue(true)}"
            >
                <div
                    class="radioButton ${this.groupValue
                        ? 'radioChecked'
                        : 'radioNotChecked'}"
                ></div>
                <div class="radioText layout vertical center-justified">
                    ${this.textTrue}
                </div>
            </div>
            <div class="emptySpace"></div>
            <div
                class="layout horizontal radioWrapper"
                @click="${this.setValue(false)}"
            >
                <div
                    class="radioButton ${this.groupValue
                        ? 'radioNotChecked'
                        : 'radioChecked'}"
                ></div>
                <div class="radioText layout vertical center-justified">
                    ${this.textFalse}
                </div>
            </div>
        </div>
    `;

    public updated = (changedProperties: PropertyValues): void => {
        super.updated(changedProperties);

        this.dispatchEvent(new CustomEvent('group-value-changed'));
    };

    /**
     * Sets value of the radio buttons
     */
    public setValue = (v: boolean) => (): void => {
        this.groupValue = v;
    };
}

declare global {
    interface HTMLElementTagNameMap {
        'radio-button-group': RadioButtonGroup;
    }
}
