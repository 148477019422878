import { litFontawesome } from '@weavedev/lit-fontawesome';
import { html, svg, SVGTemplateResult, TemplateResult } from 'lit-html';

export type hvIconFamily = 'material' | 'font-awesome';

export type IconNameMapping = {
    [icon in hvAvailableIcon]: { material?: string; 'font-awesome'?: string };
};

export type IconToneMapping = {
    [tone in hvIconTone]: { material?: string; 'font-awesome'?: string };
};

// this holds all available icons.
// This is where you would map a common icon name for different icon families
export type hvAvailableIcon =
    | 'question-circle'
    | 'thumb-up'
    | 'warning'
    | 'check'
    | 'house'
    | 'info'
    | 'arrow-down';

export const iconNameMapping: IconNameMapping = {
    'question-circle': {
        material: 'help_outline',
        'font-awesome': 'faQuestionCircle',
    },
    'thumb-up': {
        material: 'thumb_up',
        'font-awesome': 'faThumbsUp',
    },
    warning: {
        material: 'warning',
        'font-awesome': 'faExclamationTriangle',
    },
    info: {
        material: 'info',
        'font-awesome': 'faInfoCircle',
    },
    check: {
        material: 'check',
        'font-awesome': 'faCheck',
    },
    house: {
        material: 'home',
        'font-awesome': 'faHome',
    },
    'arrow-down': {
        material: 'keyboard_arrow_down_icon',
        'font-awesome': 'faAngleDown',
    },
};

// this holds all available tones.
// This is where you would map a common icon tone for different icon families
export type hvIconTone = 'filled' | 'round' | 'sharp' | 'outlined' | 'two-tone';
export const iconToneMapping: IconToneMapping = {
    filled: {
        material: 'filled',
        'font-awesome': 'solid',
    },
    outlined: {
        material: 'outlined',
        'font-awesome': 'regular',
    },
    round: {
        material: 'round',
    },
    sharp: {
        material: 'sharp',
    },
    'two-tone': {
        material: 'two-tone',
    },
};

export class Icon {
    family: hvIconFamily = 'material';

    tone: hvIconTone = 'filled';

    name?: hvAvailableIcon;

    className?: string = '';

    iconDefinition: SVGTemplateResult = svg``;

    private loadedTones: string[] = [];

    private baseFontUrl: string =
        'https://fonts.googleapis.com/css?family=Material+Icons';

    constructor(
        name: hvAvailableIcon,
        family: hvIconFamily,
        tone: hvIconTone,
        className?: string,
    ) {
        this.family = family;
        this.tone = tone;
        this.name = name;
        this.className = className;
    }

    getName = (): string => {
        if (!this.getName) {
            console.warn('no icon name was given.');
            return '';
        }
        const iconName = iconNameMapping[this.name as hvAvailableIcon];
        if (!iconName) {
            // Default
            console.warn(
                `icon not available, available icons are ${JSON.stringify(
                    Object.keys(iconNameMapping),
                )}`,
            );
            return '';
        }
        const icon = iconName[this.family as hvIconFamily];
        if (!icon) {
            console.warn(
                `icon ${this.getName} not available for family ${this.family}`,
            );
            return '';
        }
        return icon;
    };

    getIconTone = (): string => {
        const iconTone = iconToneMapping[this.tone];
        if (!iconTone) {
            console.warn(
                `icon tone not available, available icon tones are ${JSON.stringify(
                    Object.keys(iconToneMapping),
                )}`,
            );
            return '';
        }
        const tone = iconTone[this.family as hvIconFamily];
        if (!tone) {
            console.warn(
                `icon tone ${this.tone} not available for family ${this.family}`,
            );
            return '';
        }
        return tone;
    };

    // initFont will init and return the icon depending on the family
    // we dont init in the constructor because it is async
    init = async (cb: () => void): Promise<void> => {
        switch (this.family) {
            case 'font-awesome':
                await this.initFontAwesomeFont();
                break;
            default:
                this.initMaterialFont();
        }
        cb();
    };

    initFontAwesomeFont = async (): Promise<void> => {
        const icon = await import(
            /* webpackChunkName: "font-awesome" */
            /* webpackPreload: true */
            `@fortawesome/free-${this.getIconTone()}-svg-icons/${this.getName()}`
        );
        this.iconDefinition = litFontawesome(icon.definition, {
            className: this.className,
        });
    };

    initMaterialFont = () => {
        const fontUrl = this.getFontURL();
        // if font is already loaded we dont need to add it to the head again
        if (this.loadedTones.includes(fontUrl)) {
            return;
        }
        // fonts are not loaded in the shadow dom so we want to prepend them to the head
        const head = document.querySelector('head');
        if (head) {
            head.insertAdjacentHTML(
                'beforeend',
                `
                <link rel="stylesheet" type="text/css" href="${fontUrl}" />
            `,
            );
        }
    };

    // different families require different ways of rendering
    create = (): TemplateResult | SVGTemplateResult => {
        const tone = this.getIconTone();
        switch (this.family) {
            case 'material':
                return html`<i
                    class="m-i${tone !== 'filled' ? `-${tone}` : ''} ${this
                        .className}"
                    >${this.getName()}</i
                >`;
            case 'font-awesome':
                return this.iconDefinition;
            default:
                return svg``;
        }
    };

    getFontURL = () => {
        let url = this.baseFontUrl;
        const tone = this.getIconTone();
        if (tone !== 'filled') {
            url = `${url}+${tone
                .split(/[-]/)
                .map((i) => i.charAt(0).toUpperCase() + i.substring(1))
                .join('+')}`;
        }
        return url;
    };
}
