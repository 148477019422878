import { css } from 'lit-element';

export default css`
    :host {
        --hv-checkbox-button-margin-left: 0;
        --hv-checkbox-button-margin-right: 12px;
        --hv-checkbox-button-margin-top: 4px;
        --hv-checkbox-button-padding: 1px;
        --hv-checkbox-button-background-color: #fff;
        --hv-checkbox-button-width: 11px;
        --hv-checkbox-button-height: 11px;
        --hv-checkbox-button-border-radius: 5%;
        --hv-checkbox-button-border: 1px solid #000;
        --hv-checkbox-button-transition: 0.1s;
        --hv-checkbox-button-background--checked: #3aad3a;
        --hv-checkbox-button-box-shadow--checked: 0 0 1px 1px #3aad3a;
        --hv-checkbox-button-border--checked: 1px solid #3aad3a;
        --hv-icon-padding-bottom: 2px;
    }
    div {
        margin-left: var(--hv-checkbox-button-margin-left);
        margin-right: var(--hv-checkbox-button-margin-right);
        margin-top: var(--hv-checkbox-button-margin-top);
        width: var(--hv-checkbox-button-width);
        padding: var(--hv-checkbox-button-padding);
        border: var(--hv-checkbox-button-border);
        border-radius: var(--hv-checkbox-button-border-radius);
        height: var(--hv-checkbox-button-height);
        transition: var(--hv-checkbox-button-transition);
        background: var(--hv-checkbox-button-background-color);
        display: flex;
        justify-content: center;
        align-items: center;
    }
    div[checked] {
        transition: var(--hv-checkbox-button-transition);
        background: var(--hv-checkbox-button-background--checked);
        box-shadow: var(--hv-checkbox-button-box-shadow--checked);
        border: var(--hv-checkbox-button-border--checked);
    }
    hv-icon {
        --hv-icon-color: var(--hv-checkbox-button-background-color);
        padding-bottom: var(--hv-icon-padding-bottom);
    }
    check[checked] {
        color: #fff;
    }
`;
