import { css } from 'lit-element';

export default css`
    modal-component {
        --modal__header--text-align: left;
        --modal__header--margin: 0;
        --modal__header--padding: 32px;
        --modal__main--margin: 8px 32px 48px;
        --modal__header--border-bottom: 0;
        --modal__header__sub-title--color: var(--primary-color);
        --modal__header--padding: 32px 32px 10px;
        --modal__close--font-size: 40px;
    }

    button-component {
        --button-component--padding: 16px 24px;
        --button-component--font-size: 14px;
        --button-component--line-height: 1;
        --button-component--width: auto;
        --button-component--margin: 0;
        --button-component_small--width: 400px;
    }

    #content {
        color: #1e2029;
        font-size: 16px;
    }

    #select__buttons {
        margin-top: 48px;
        margin-bottom: -16px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .recal__loader {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
    }
`;
