import { Snapshot } from 'types/snapshot';
import { ProductGroup } from '../reducers/offers';

export const PREFIX = 'Offer: ';

export const SELECT_OFFER = 'SELECT_OFFER';
export const ADD_OFFER = 'ADD_OFFER';
export const REPLACE_OFFERS = 'REPLACE_OFFERS';
export const AGREE_OFFER = 'AGREE_OFFER';
export interface Offer {
    id: string;
    index: number;
    name?: string;
    product_group: ProductGroup;
}

export interface OfferAction {
    type: string;
    offer_id?: string;
    offers?: Offer[];
    offer?: Offer;
    params?: any;
    data?: any;
    error?: any;
    vendorId?: string;
    snapshot?: Snapshot;
}

export interface RecalculateOfferResetAction {
    type: string;
}

export interface GeoInformation {
    city: string;
    house_number: number | string;
    street_name: string;
}

export interface Order {
    collective?: string;
    electricity: boolean;
    electricityProductionEstimate: number;
    electricityUsageEstimate: number;
    electricityUsageFactorEstimate: number;
    energysplitter: boolean;
    gas: boolean;
    gasUsageEstimate: number;
    houseNumber: number;
    houseNumberSuffix: string;
    offer: Offer;
    postalCode: string;
    proposition?: any;
    transmissionTypeGas: number;
    transmissionTypeElec: number;
    chargePoleUsage: number;
    redirectType?: any;
    geoInformation: GeoInformation;
    labels: any[];
}

export const setOffer = (offer: Offer): OfferAction => ({
    type: ADD_OFFER,
    offer,
});

export const replaceOffers = (offers: Offer[]): OfferAction => ({
    type: REPLACE_OFFERS,
    offers,
});

export const selectOffer = (offerID: string): OfferAction => ({
    type: SELECT_OFFER,
    offer_id: offerID,
});

export const agreeOffer = (snapshot: Snapshot): OfferAction => ({
    type: AGREE_OFFER,
    snapshot,
});

export const REQUEST_OFFER = `${PREFIX}REQUEST_OFFER`;

export const requestOffer = (params: any, vendorId: string): OfferAction => ({
    type: REQUEST_OFFER,
    params,
    vendorId,
});

export const offerRecalculationReset = `${PREFIX}RESET_RECALCULATION_OFFER`;

export const resetRecalculationOffer = (): RecalculateOfferResetAction => ({
    type: offerRecalculationReset,
});

export interface TransmissionAction {
    type: string;
    elec: number;
    gas: number;
}

export const SET_TRANSMISSION_TYPES = `${PREFIX}SET_TRANSMISSION_TYPES`;

export const setTransmissionTypes = (
    elec: number,
    gas: number,
): TransmissionAction => ({
    type: SET_TRANSMISSION_TYPES,
    elec,
    gas,
});

export interface OfferSuccessAction {
    type: string;
    data?: any;
    error?: any;
}

export const REQUEST_OFFER_SUCCESS = `${PREFIX}REQUEST_OFFER_SUCCESS`;

export const requestOfferSuccess = (data: any): OfferSuccessAction => ({
    type: REQUEST_OFFER_SUCCESS,
    data,
});

export const REQUEST_OFFER_FAILED = `${PREFIX}REQUEST_OFFER_FAILED`;

export const requestOfferFailed = (error: any): OfferSuccessAction => ({
    type: REQUEST_OFFER_FAILED,
    error,
});

export const RESET_OFFER = `${PREFIX}RESET_OFFER`;

export const resetOffer = (): any => ({ type: RESET_OFFER });

export const retrieveOffers = (
    order: Order,
    gasTransmission: number,
    elecTransmission: number,
) => {
    const params: any = {
        has_elec: order.electricity,
        elec_amount: order.electricity ? order.electricityUsageEstimate : 0,
        high_low_distribution: order.electricityUsageFactorEstimate,
        has_gas: order.gas,
        gas_amount: order.gas ? order.gasUsageEstimate : 0,
        house_number: order.houseNumber,
        area_code: order.postalCode,
        prod_amount: order.electricityProductionEstimate,
        transmission_value_type_elec: elecTransmission,
        transmission_value_type_gas: gasTransmission,
    };

    if (order.houseNumberSuffix && order.houseNumberSuffix !== '') {
        params.house_addition = order.houseNumberSuffix;
    }

    if (order.collective) {
        params.collective_id = order.collective;
    }
    if (order.proposition) {
        params.proposition = order.proposition;
    }

    if (order.chargePoleUsage && order.chargePoleUsage > 0) {
        params.charging_pole_usage_amount = order.chargePoleUsage;
    }
    // @ts-ignore
    return (dispatch) => {
        dispatch(setTransmissionTypes(elecTransmission, gasTransmission));
        dispatch(requestOffer(params, window.VENDOR));
    };
};
