import {
    CLEAR_CREATE_USER_SNAPSHOT_QUERY,
    REQUEST_CREATE_USER_SNAPSHOT,
    REQUEST_CREATE_USER_SNAPSHOT_SUCCESS,
    REQUEST_CREATE_USER_SNAPSHOT_FAILED,
    RESET_CREATE_USER_SNAPSHOT,
} from '../actions/userSnapshot';

const defaultCreateUserSnapshotState = { query: null, data: null, error: null };

/**
 * State reducer for create user snapshot request
 * @param {Object} state
 * @param {Object} action
 * @return {Object}
 */
export const createUserSnapshot = (
    state = { ...defaultCreateUserSnapshotState },
    action,
) => {
    switch (action.type) {
        case CLEAR_CREATE_USER_SNAPSHOT_QUERY:
            return { ...state, query: null };
        case REQUEST_CREATE_USER_SNAPSHOT:
            return { ...state, query: action.payload, data: null };
        case REQUEST_CREATE_USER_SNAPSHOT_SUCCESS:
            return { ...state, data: action.data, error: null };
        case REQUEST_CREATE_USER_SNAPSHOT_FAILED:
            return { ...state, error: action.error };
        case RESET_CREATE_USER_SNAPSHOT:
            return { ...defaultCreateUserSnapshotState };
        default:
            return state;
    }
};
