import {
    LitElement,
    TemplateResult,
    customElement,
    html,
    property,
    CSSResult,
    PropertyValues,
} from 'lit-element';
import '@shoelace-style/shoelace/dist/shoelace';
// @ts-ignore
import light from '@shoelace-style/shoelace/dist/themes/light.styles';

import { whitelabel } from '@weavelab/whitelabel';
import enums from 'enums';
import style from './styles';

export interface CapacityType {
    id: string;
    name: string;
}
export const ElecCapacities: CapacityType[] = [
    {
        id: enums.TransmissionValueType1x6AGeschakeldNet,
        name: '1x6 A doorlaatwaarde',
    },
    {
        id: enums.TransmissionValueType3x25ATm1x80A,
        name: '3x25 A doorlaatwaarde',
    },
    {
        id: enums.TransmissionValueType3x25ATm3x35A,
        name: '3x35 A doorlaatwaarde',
    },
    {
        id: enums.TransmissionValueType3x35ATm3x50A,
        name: '3x50 A doorlaatwaarde',
    },
    {
        id: enums.TransmissionValueType3x50ATm3x63A,
        name: '3x63 A doorlaatwaarde',
    },
    {
        id: enums.TransmissionValueType3x63ATm3x80A,
        name: '3x80 A doorlaatwaarde',
    },
];

export const GasCapacities: CapacityType[] = [
    {
        id: enums.TransmissionValueType10M3uurTot500M3jaar,
        name: '≤ G6 doorlaatwaarde, tot 500 m³/jaar',
    },
    {
        id: enums.TransmissionValueType10M3uurTussen500M3En4000M3jaar,
        name: '≤ G6 doorlaatwaarde, 500 tot 4000 m³/jaar',
    },
    {
        id: enums.TransmissionValueType10M3uurMeerDan4000M3jaar,
        name: '≤ G6 doorlaatwaarde, meer dan 4000 m³/jaar',
    },
    {
        id: enums.TransmissionValueType10M3uur16M3uur,
        name: 'G10 doorlaatwaarde',
    },
    {
        id: enums.TransmissionValueType16M3uur25M3uur,
        name: 'G16 doorlaatwaarde',
    },
    {
        id: enums.TransmissionValueType25M3uur40M3uur,
        name: '≥ G25 doorlaatwaarde',
    },
];

@customElement('capacity-component')
@whitelabel({ name: 'capacity-component' })
export class CapacityComponent extends LitElement {
    @property({ type: String, reflect: true })
    public type: 'elec' | 'gas' = 'elec';

    @property({ type: Array })
    public capacities: CapacityType[] = ElecCapacities;

    @property({ type: String })
    public placeholder: string = `Selecteer je doorlaatwaarde voor`;

    @property({ type: String })
    public value?: string;

    @property({ type: String })
    public selected: string = '';

    @property({ type: Boolean })
    public disabled: boolean = false;

    @property({ type: String })
    public size?: 'small' | 'medium' | 'large' = 'medium';

    @whitelabel() // Allow styles to be injected from theme
    static get styles() {
        return [light as unknown as CSSResult, style];
    }

    public render = (): TemplateResult => html`
        <sl-select
            size="${this.size}"
            value="${this.selected}"
            placeholder="${this.placeholder} ${this.type === 'elec'
                ? `elektriciteit`
                : this.type}"
            required
            ?disabled="${this.disabled}"
        >
            ${this.capacities.map(
                (capacity) =>
                    html`
                        <sl-option
                            data-id="${capacity.id}"
                            value="${String(capacity.name).split(' ').join('_')}"
                            >${capacity.name}
                        </sl-option>
                    `,
            )}
        </sl-select>
    `;

    protected updated(changedProperties: PropertyValues) {
        // set the capacities array on the component for the specific type
        if (
            changedProperties.has('type') &&
            this.type !== changedProperties.get('type')
        ) {
            this.capacities =
                this.type === 'elec' ? ElecCapacities : GasCapacities;
        }
        // check if value is set to set the selected value.
        if (
            changedProperties.has('value') &&
            this.value &&
            this.value !== changedProperties.get('value')
        ) {
            this.setSelectedValue();
        }
    }

    private getCapacityArray = () =>
        this.type === 'elec' ? ElecCapacities : GasCapacities;

    private setSelectedValue = (): void => {
        if (this.value === '') {
            this.selected = '';
        }
        const capacityArray = this.getCapacityArray();
        let found = false;
        for (let i = 0; i < capacityArray.length; i += 1) {
            if (this.value === capacityArray[i].id) {
                this.selected = capacityArray[i].name;
                found = true;
                break;
            }
        }

        if (!found) {
            this.selected = '';
        }
    };
}
