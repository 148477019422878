import {
    customElement,
    LitElement,
    TemplateResult,
    html,
    query,
    property,
    PropertyValues,
} from 'lit-element';
import { whitelabel } from '@weavelab/whitelabel';
import { ifDefined } from 'lit-html/directives/if-defined';
import { Router } from '@vaadin/router';
import { routePaths } from 'helpers/routing';
import '../../../../components/elements/modal/ModalComponent';
import '../../../../components/elements/base/button/ButtonComponent';
import '../../../../components/molecules/HvLoaderModal/HvLoaderModal';
import '../../../../components/price-compare/index';
import { currency } from 'helpers/currency';

import style from './style';
import { acceptOfferRecalculation } from '../../../../store';

const maxWidth = '778px';

@customElement('recalculate-modal')
@whitelabel({ name: 'monthly-payments-invalid-recalculate-modal' })
export class RecalculateModal extends LitElement {
    @query('#recal__modal')
    public recalModal?: HTMLElementTagNameMap['modal-component'];

    @property({ type: String })
    public newPrice?: string;

    @property({ type: Boolean })
    public opened: boolean = false;

    @property({ type: String })
    public oldPrice?: string;

    @property({ type: Number })
    public normal_usage: number = 0;

    @property({ type: Number })
    public low_usage: number = 0;

    @property({ type: Number })
    public gas_usage: number = 0;

    @property({ type: Number })
    public production: number = 0;

    @property({ type: String })
    public snapshotID: string = '';

    @whitelabel({ type: 'string' })
    public static headerTitle: string = 'Nieuw maandbedrag';

    @whitelabel({ type: 'string' })
    public static content: string =
        'Na de bevestiging zie je dit bedrag op de eerstvolgende maandfactuur terug. Bevestig dit nieuwe bedrag met de onderstaande knop.';

    @whitelabel() // Allow styles to be injected from theme
    static get styles() {
        return [style];
    }

    public acceptNewOffer = () => {
        window.store.dispatch(
            acceptOfferRecalculation.request({ snapshotId: this.snapshotID }),
        );
        this.close();
        Router.go(routePaths.settings);
    };

    protected updated(changedProperties: PropertyValues) {
        if (changedProperties.has('opened') && this.opened) {
            this.open();
        }
    }

    public render = (): TemplateResult => html` <modal-component
        ?opened=${this.opened}
        header-title=${RecalculateModal.headerTitle}
        id="recal__modal"
        max-width=${maxWidth}
        prevent-closing-outside
    >
        <price-compare
            .oldPrice="${ifDefined(this.oldPrice)}"
            .newPrice="${currency(Number(this.newPrice))}"
        ></price-compare>
        <p>${RecalculateModal.content}</p>
        <div id="select__buttons">
            <button-component
                label="Definitief bevestigen"
                theme="primary"
                size="small"
                verticalSpacing="minimal"
                @click=${this.acceptNewOffer}
            ></button-component>
            <button-component
                label="Annuleren"
                theme="secondary"
                size="small"
                verticalSpacing="minimal"
                @click=${this.close}
            ></button-component>
        </div>
    </modal-component>`;

    public open = (): void => {
        this.recalModal?.open();
    };

    public close = (): void => {
        this.recalModal?.close();
    };
}

declare global {
    interface HTMLElementTagNameMap {
        'recalculate-modal': RecalculateModal;
    }
}
