import { LitElement, TemplateResult, customElement, html } from 'lit-element';
import '../../../components/molecules/Spinner';
import SmartChargingSharedStyles from '../SmartChargingSharedStyles';
import { _defaults } from '../../../components/helpers/_defaults';
import { receiveSelf } from '@async-reducers/authentication';
import { store } from 'data-access/store';
/**
 * Used as ui view for the step with SmartChargingOnboarding.ts
 */
@customElement('view-car-connect-successfully')
export class CarConnectSuccessfully extends LitElement {
    static get styles() {
        return [_defaults, SmartChargingSharedStyles];
    }

    public render = (): TemplateResult => {
        return html`
                <h1 class="title">Koppelen van de auto gelukt</h1>
                <div class="explanation">
                    <p>
                        Het kan even duren voordat je smart charging contract de juiste gegevens heeft ontvangen van Enode. Klikt hieronder op de ververs knop om de meest recente gegevens op te halen.
                    </p>
                    <p>
                        Als deze gegevens beschikbaar zijn linken we je door naar het smart charging dashboard.
                    </p>
                </div>
            </div>
            <div class="button-wrapper">
                <button class="btnPrimary button" @click="${() => {
                    store.dispatch(receiveSelf.run());
                }}">
                    Ververs gegevens
                </button>
            </div>
        `;
    };
}

declare global {
    interface HTMLElementTagNameMap {
        'view-car-connect-successfully': CarConnectSuccessfully;
    }
}
