import enums from 'enums';

export enum pageUsageTypes {
    'stroom' = enums.UsageTypeElekUsage,
    'laadsessies' = enums.UsageTypeElekUsage,
    'teruglevering' = enums.UsageTypeElekProd,
    'gas' = enums.UsageTypeGasUsage,
}

export const usageSuffix = new Map();
usageSuffix.set(0, 'Euro');
usageSuffix.set(enums.UsageTypeElekUsage, 'kWh');
usageSuffix.set(enums.UsageTypeElekProd, 'kWh');
usageSuffix.set(enums.UsageTypeGasUsage, 'm³');
