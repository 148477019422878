export const PREFIX = 'Company: ';

/**
 * @typedef {Object} CreateCompanyPayload
 */

export const CLEAR_CREATE_COMPANY_QUERY = `${PREFIX}CLEAR_CREATE_COMPANY_QUERY`;
/**
 * Action generator to clear the sent create company query
 * @return {Object}
 */
export const clearCreateCompanyQuery = () => ({
    type: CLEAR_CREATE_COMPANY_QUERY,
});

export const REQUEST_CREATE_COMPANY = `${PREFIX}REQUEST_CREATE_COMPANY`;
/**
 * Action generator for request create company
 * @param {CreateCompanyPayload} payload
 * @return {Object}
 */
export const requestCreateCompany = (payload) => ({
    type: REQUEST_CREATE_COMPANY,
    payload,
});

export const REQUEST_CREATE_COMPANY_SUCCESS = `${PREFIX}REQUEST_CREATE_COMPANY_SUCCESS`;
/**
 * Success callback action for create company
 * @param {Object} data
 * @return {Object}
 */
export const requestCreateCompanySuccess = (data) => ({
    type: REQUEST_CREATE_COMPANY_SUCCESS,
    data,
});

export const REQUEST_CREATE_COMPANY_FAILED = `${PREFIX}REQUEST_CREATE_COMPANY_FAILED`;
/**
 * Error callback action for create company
 * @param {Object} error
 * @return {Object}
 */
export const requestCreateCompanyFailed = (error) => ({
    type: REQUEST_CREATE_COMPANY_FAILED,
    error,
});

export const RESET_CREATE_COMPANY = `${PREFIX}RESET_CREATE_COMPANY`;
/**
 * Action generator to reset create company
 * @return {Object}
 */
export const resetCreateCompany = () => ({ type: RESET_CREATE_COMPANY });
