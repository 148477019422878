import { Action, AnyAction } from 'redux';
import { Snapshot } from 'types/snapshot';

export const PREFIX = 'Snapshot: ';
export const SET_SELECTED_SNAPSHOT = `${PREFIX}SET_SELECTED_SNAPSHOT`;
export const SET_SELECTED_SNAPSHOT_ID = `${PREFIX}SET_SELECTED_SNAPSHOT_ID`;
export const CLEAR_SELECTED_SNAPSHOT = `${PREFIX}CLEAR_SELECTED_SNAPSHOT`;
export const CLEAR_SELECTED_SNAPSHOT_ID = `${PREFIX}CLEAR_SELECTED_SNAPSHOT_ID`;
export const SET_JWT_SNAPSHOTS = `${PREFIX}SET_JWT_SNAPSHOTS`;
export const RESET_JWT_SNAPSHOTS = `${PREFIX}RESET_JWT_SNAPSHOTS`;
export const SET_SPLIT_BUSINESS_LOGS = `SET_SPLIT_BUSINESS_LOGS`;
export const AGREED_SNAPSHOT = `${PREFIX}AGREED_SNAPSHOT`;

export interface SetSnaphostID extends Action<string> {
    id: string;
}
export interface SetSnapshots extends Action<string> {
    snapshots: Snapshot[];
    sortedByLastestSnapshot: Snapshot[];
}
export interface SetSelectedSnapshot extends Action<string> {
    snapshot: Snapshot;
}

export interface SetOdaOnboardingSkipped extends Action<string> {
    skipped: boolean;
}

// actions
export const setSnapshots = (snapshots: Snapshot[]) => ({
    type: SET_JWT_SNAPSHOTS,
    snapshots,
});
// set selected snapshots
export const setSelectedSnapshot = (snapshot: Snapshot) => ({
    type: SET_SELECTED_SNAPSHOT,
    snapshot,
});

export const agreedSnapshot = (snapshot: Snapshot): AnyAction => ({
    type: AGREED_SNAPSHOT,
    snapshot,
});

// set selected snapshots id for the menu
export const setSelectedSnapshotID = (id: string): SetSnaphostID => ({
    type: SET_SELECTED_SNAPSHOT_ID,
    id,
});
// clear selected snapshots id for the menu
export const clearSelectedSnapshotID = (): Action<string> => ({
    type: CLEAR_SELECTED_SNAPSHOT_ID,
});

/**
 * Action to clear the selected snapshot
 */
export const clearSelectedSnapshot = () => ({ type: CLEAR_SELECTED_SNAPSHOT });

/**
 * Action to reset snapshots received through jwt
 */
export const resetSnapshots = () => ({ type: RESET_JWT_SNAPSHOTS });

// Combined action interface for reducer
export interface SnapshotActions
    extends SetSnaphostID,
        SetSnapshots,
        SetOdaOnboardingSkipped,
        SetSelectedSnapshot {}
