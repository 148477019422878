import { capitalizeFirstCharacter } from './helpers/string';

export const sleep = (milliseconds = 500) =>
    new Promise((resolve) => setTimeout(resolve, milliseconds));

export const monthNames = [
    'Jan',
    'Feb',
    'Mrt',
    'Apr',
    'Mei',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Okt',
    'Nov',
    'Dec',
];

export const monthNamesFull = [
    'januari',
    'februari',
    'maart',
    'april',
    'mei',
    'juni',
    'juli',
    'augustus',
    'september',
    'oktober',
    'november',
    'december',
];

export const dayNames = [
    'zondag',
    'maandag',
    'dinsdag',
    'woensdag',
    'donderdag',
    'vrijdag',
    'zaterdag',
];

/**
 * Formats given date as weekday string
 * @param {Date} currentDate is current begin date for interval request
 * @return {String} day formatted string
 */
export const getWeekDayAsString = (currentDate) =>
    dayNames[currentDate.getDay()];

/**
 * Returns amount of day for given month
 * @param {Number} month
 * @param {Number} year
 * @return {Number}
 */
export const daysInMonth = (month, year) => new Date(year, month, 0).getDate();

/**
 *
 * @param {Date} currentDate is date to question
 * @param {Boolean} showYear adds the numeric year when required
 * @return {String} date number plus full month
 */
export const getCurrentDateWithFullMonth = (currentDate, showYear = false) => {
    let dt = `${currentDate.getDate()} ${capitalizeFirstCharacter(
        monthNamesFull[currentDate.getMonth()],
    )}`;
    if (showYear) {
        dt += ` ${currentDate.getFullYear()}`;
    }
    return dt;
};

/**
 * Gets monday for input date (same week)
 * @param {Date} d date which will be used to get monday of the same week as d
 * @return {Date} Same week as input but now the monday
 */
export const getMonday = (d) => {
    const res = new Date(d);
    const day = res.getDay();
    const diff = res.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
    return new Date(res.setDate(diff));
};

/**
 * Formats begin date to week range string
 * @param {Date} currentDate is current begin date for interval request
 * @return {String} week range formatted string
 */
export const getCurrentWeekAsString = (currentDate) => {
    const monday = getMonday(currentDate);
    const mondayCopy = new Date(monday);
    const sunday = new Date(mondayCopy.setDate(mondayCopy.getDate() + 6));
    if (monday.getMonth() === sunday.getMonth()) {
        return `${monday.getDate()}-${sunday.getDate()} ${
            monthNamesFull[monday.getMonth()]
        }`;
    }
    return `${monday.getDate()} ${
        monthNamesFull[monday.getMonth()]
    } - ${sunday.getDate()} ${monthNamesFull[sunday.getMonth()]}`;
};

/**
 * Checks if current month and year is same as active contract start date if so corrects is
 * @param {Object} snapshot
 * @return {Object}
 */
export const getDatesForSnapshot = (snapshot) => {
    const today = new Date();
    let from = new Date(today.getFullYear(), today.getMonth(), 1);
    const till = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    // @ts-ignore
    const contract = snapshot;
    if (contract && contract.verified_snapshot_payload) {
        const contractStartDate = new Date(
            contract.verified_snapshot_payload.start_date,
        );
        if (
            contractStartDate.getFullYear() === today.getFullYear() &&
            contractStartDate.getMonth() === today.getMonth()
        ) {
            from = contractStartDate;
        }
    }

    return { from, till };
};

/**
 * Formats date to string format
 * @param {Date} date from user state object
 * @return {String} dd-mm-yyyy
 */
export const formatDate = (date) => {
    if (date) {
        const event = new Date(date);
        const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
        return event.toLocaleDateString('nl-NL', options);
    }

    return '';
};

/**
 * Check if date is valid
 * @param {Date} d date to be checked
 * @return {Boolean} indiciating if valid date
 */
export const validDate = (d) => {
    if (Object.prototype.toString.call(d) === '[object Date]') {
        // it is a date
        if (Number.isNaN(d.getTime())) {
            // d.valueOf() could also work
            // date is not valid
            return false;
        }
        // date is valid
        return true;
    }
    // not a date
    return false;
};

/**
 * Formats date to short name of month
 * @param {Date} date from user state object
 * @return {String} short name of month
 */
export const getMonthShortNameFromDate = (date) => {
    let _date = date;
    const shortMonthList = [
        'jan',
        'feb',
        'mrt',
        'apr',
        'mei',
        'jun',
        'jul',
        'aug',
        'sep',
        'okt',
        'nov',
        'dec',
    ];
    if (date) {
        _date = new Date(date);
    }
    return shortMonthList[_date.getMonth()];
};

/**
 * Formats date to long name of month
 * @param {Date} date from user state object
 * @return {String} long name of month
 */
export const getMonthLongNameFromDate = (date) => {
    let _date = date;
    const shortMonthList = [
        'januari',
        'februari',
        'maart',
        'april',
        'mei',
        'juni',
        'juli',
        'augustus',
        'september',
        'oktober',
        'november',
        'december',
    ];
    if (date) {
        _date = new Date(date);
    }
    return shortMonthList[_date.getMonth()];
};

// legalNoticePeriod determines the period in days a customer is legally allowed to cancel their contract.
// The reason this value is a const and not a variable in the vendor config like the urgent_min_start_days, is because this value
// will not be able to change over time. Not in near future for now anyway.
export const legalNoticePeriod = 15;
