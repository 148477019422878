import { Snapshot } from 'types/snapshot';
import {
    ChargingState,
    SmartChargingState,
    SmartChargingStateEnum,
    Vehicle,
} from 'types/vehicle';
import {
    css,
    customElement,
    html,
    LitElement,
    TemplateResult,
} from 'lit-element';
import { connect, watch } from 'lit-redux-watch';
import { _defaults } from '@components/helpers/_defaults';
import { vehicleGet, vehicleStartStopCharging } from '@async-reducers/vehicle';
import SmartChargingSharedStyles from './SmartChargingSharedStyles';
import '../../components/atoms/ChargingBattery';
import '../../components/molecules/Spinner';

/**
 *  View responsible for showing the smart charging onboarding stepper
 *  This component should probably connect to redux state
 *
 *  The stepper works not with page routing out of the box. You cloud
 *  hook up routing with stepper by catching route and translating the route name
 *  to this.startingStep. Maybe with a look up tabel "/routname" : 0, "/routname2" : 1
 */

@customElement('view-smart-charging-dashboard')
export class SmartChargingOnboardDashboard extends connect(window.store)(
    LitElement,
) {
    @watch('jwtSnapshots.smartChargingSnapshots')
    public smartChargingSnapshot?: Snapshot[];

    @watch('vehicleList.busy')
    public vehicleListLoading: boolean = false;

    @watch('vehicleGet.busy')
    public vehicleLoading: boolean = false;

    @watch('vehicleGet.data.vehicle')
    public vehicle?: Vehicle;

    @watch('vehicleGet.updated.data')
    public vehicleListDataDate?: string;

    @watch('vehicleGet.busy')
    public vehicleGetLoading: boolean = false;

    static get styles() {
        return [
            _defaults,
            SmartChargingSharedStyles,
            css`
                :host {
                    display: flex;
                    flex-grow: 1;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: center;
                    text-align: center;
                }
            `,
        ];
    }

    protected get isChargingSmart(): boolean {
        return (
            !!this.vehicle &&
            this.vehicle.charging_state.is_charging &&
            this.vehicle.smart_charging_state.state.startsWith(
                'PLAN:EXECUTING:START',
            )
        );
    }

    protected get isSmartChargingFinished(): boolean {
        return (
            !!this.vehicle &&
            this.vehicle.charging_state.is_fully_charged &&
            this.vehicle.smart_charging_state.state.startsWith(
                'PLAN:ENDED:FINISHED',
            )
        );
    }

    protected get isChargingNormally(): boolean {
        return (
            !!this.vehicle &&
            this.vehicle.charging_state.is_charging &&
            !this.vehicle.smart_charging_state.state.startsWith(
                'PLAN:EXECUTING:START',
            )
        );
    }

    public handleRefreshClicked = () => {
        const snapshot = this.smartChargingSnapshot?.[0]?.id;
        if (snapshot && this.vehicle) {
            window.store.dispatch(vehicleGet.run(snapshot, this.vehicle.id));
        }
    };

    public handleChargingClicked = () => {
        const snapshot = this.smartChargingSnapshot?.[0]?.id;
        if (this.vehicle && snapshot) {
            const action = this.vehicle.charging_state.is_charging
                ? 'ACTION_STOP'
                : 'ACTION_START';
            window.store.dispatch(
                vehicleStartStopCharging.run(action, this.vehicle.id, snapshot),
            );
        }
    };

    public canSmartCharge(state: SmartChargingStateEnum): boolean {
        switch (state) {
            case SmartChargingStateEnum.Disabled:
            case SmartChargingStateEnum.Considering:
                return false;
        }

        return true;
    }

    public render = (): TemplateResult => {
        if (this.vehicleListLoading || this.vehicleLoading) {
            return html`<ez-spinner
                >Voertuig gegevens worden opgehaald...</ez-spinner
            >`;
        }

        return html`
            <h1 class="title">${this.getTitle(this.vehicle)}</h1>
            <div>
                <charging-battery .state=${this.vehicle?.charging_state}>
                    <p class="explanation">
                        ${this.getStateContext(this.vehicle)}
                    </p>
                </charging-battery>
                ${!this.vehicle
                    ? null
                    : html`
                          <p class="loaded-at">
                              ${!this.vehicleListDataDate
                                  ? null
                                  : html`
                                        Bijgewerkt om
                                        ${new Intl.DateTimeFormat('nl-NL', {
                                            // @ts-ignore
                                            dateStyle: 'short',
                                            timeStyle: 'short',
                                        }).format(
                                            new Date(this.vehicleListDataDate),
                                        )}<br />
                                        <a
                                            href=""
                                            @click=${this.handleRefreshClicked}
                                            >verversen</a
                                        >
                                    `}
                          </p>
                      `}
            </div>
            <div class="button-wrapper">
                ${!this.vehicle
                    ? null
                    : html`
                          ${this.getSmartChargingButton(this.vehicle)}
                          ${this.getChargingButton(this.vehicle)}
                      `}
            </div>
        `;
    };

    protected getState(
        state?: ChargingState,
        smartState?: SmartChargingState,
    ): string {
        if (!state || !smartState || !state.is_plugged_in)
            return 'Slim laadplan niet berekend';

        if (state.is_fully_charged) {
            const chargingMethod = this.isSmartChargingFinished
                ? 'Slim'
                : 'Normaal';
            return `${chargingMethod} laadplan gehanteerd`;
        }
        if (!state.is_charging) {
            const smartMessage = 'Slim laadplan ';
            if (smartState.consideration) {
                return `${smartMessage} berekenen\u2026`;
            }
            if (smartState.plan) {
                return `${smartMessage} gereed`;
            }
        }

        return `Je ${
            state.is_charging ? 'auto is' : 'bent niet'
        } aan het laden`;
    }

    protected getTitle(vehicle?: Vehicle): TemplateResult | string {
        let title: string;
        if (
            !vehicle ||
            !vehicle.charging_state.is_plugged_in ||
            !vehicle.charging_state.is_charging
        ) {
            title = 'Géén laadsessie actief';
        } else if (vehicle.charging_state.is_fully_charged) {
            title = `${
                this.isSmartChargingFinished ? 'Slimme' : 'Normale'
            } laadsessie voltooid`;
        } else {
            const sessionType = this.isChargingSmart ? 'Slimme' : 'Normale';

            title = `${sessionType} laadsessie actief`;
        }

        return html`
            ${title}<br /><small
                >${this.getState(
                    vehicle?.charging_state,
                    vehicle?.smart_charging_state,
                )}</small
            >
        `;
    }

    protected getStateContext(vehicle?: Vehicle): string {
        if (!vehicle)
            return 'Verbinden met auto mislukt, we proberen het elke twee minuten opnieuw';

        const location =
            !vehicle.smart_charging_state.consideration
                ?.recentlyAtChargingLocation &&
            !vehicle.smart_charging_state.plan
                ? 'Elders'
                : 'Thuis';
        const chargingMethod =
            this.isChargingSmart ||
            this.isSmartChargingFinished ||
            vehicle.smart_charging_state.plan
                ? 'slim'
                : 'normaal';
        if (vehicle.charging_state.is_charging)
            return `${location} ${chargingMethod} aan het opladen naar het gewenste maximum percentage ${vehicle.charging_state.charge_limit}%`;
        if (vehicle.charging_state.is_fully_charged)
            return `${location} ${chargingMethod} opgeladen naar het gewenste maximum percentage ${vehicle.charging_state.charge_limit}%`;

        if (!vehicle.charging_state.is_plugged_in)
            return 'Uitgeplugd en niet aan het opladen';

        return `${location} ingeplugd en niet aan het opladen`;
    }

    protected getSmartChargingButton(vehicle: Vehicle): TemplateResult {
        if (
            vehicle.charging_state.is_fully_charged ||
            !this.canSmartCharge(vehicle.smart_charging_state.state)
        ) {
            return html``;
        }

        return html`
            <button class="btnPrimary" @click=${this.handleChargingClicked}>
                ${this.isChargingSmart ? 'Stop' : 'Start'} slim laden
            </button>
        `;
    }

    protected getChargingButton(vehicle: Vehicle): TemplateResult {
        if (vehicle.charging_state.is_fully_charged || this.isChargingSmart) {
            return html``;
        }

        return html`
            <button class="btnSecondary" @click=${this.handleChargingClicked}>
                ${this.isChargingNormally ? 'Stop' : 'Start'} normaal laden
            </button>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        'view-smart-charging-dashboard': SmartChargingOnboardDashboard;
    }
}
