import { css } from 'lit-element';

export default css`
    :host {
        --placeholder-component--color: var(primary-color);
        --placeholder-component--font-size: 14px;
        --placeholder-component--min-height: auto;
        --placeholder-component--text-color: #000;
        --placeholder-component--font-family: inherit;
        --placeholder-component--text-margin: 0;

        color: var(---placeholder-component--color);
        font-size: var(--placeholder-component--font-size);
        vertical-align: text-top;
        user-select: none;
        display: flex;
    }
    p {
        display: flex;
        align-items: center;
        padding: 0;
        margin: var(--placeholder-component--text-margin);
        min-height: var(--placeholder-component--min-height);
        color: var(--placeholder-component--text-color);
        font-family: var(--placeholder-component--font-family);
    }
    error-handling {
        float: left;
        padding-top: 15px;
        margin-top: -10px;
    }
`;
