import { Router } from '@vaadin/router';
import { ReduxAsync } from '@weavedev/redux-async';
import axios, { AxiosResponse } from 'axios';
import { routePaths } from 'data-access/router';
import { provider } from '@weavelab/frontend-connect';
import { gqlResponse } from '@async-reducers/helpers/gql';
import { resetLanderPage } from '../actions/history';
import headers from '../sagas/helpers/headers';

// reset password
const RESET_PASSWORD = 'RESET_PASSWORD';
const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
const RESET_PASSWORD_FAILED = 'RESET_PASSWORD_FAILED';
export const resetPassword = new ReduxAsync(
    RESET_PASSWORD,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAILED,
    async (resetToken: string, password: string): Promise<any> => {
        const response: AxiosResponse = await axios.post(
            `/v1/user/reset/${resetToken}`,
            {
                password,
            },
            {
                baseURL: window.API_LINK,
                headers: headers(window.STDHeaders),
            },
        );
        if (response.statusText === 'OK' || response.status === 200) {
            localStorage.clear();
            window.STDHeaders.delete('X-Auth');
            Router.go('../');
            window.displayMessage('Wachtwoord gewijzigd, je kunt nu inloggen.');
            return response.data;
        }
        throw response.status;
    },
);

// forgot password
const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
const FORGOT_PASSWORD_FAILED = 'FORGOT_PASSWORD_FAILED';
export const forgotPassword = new ReduxAsync(
    FORGOT_PASSWORD,
    FORGOT_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_FAILED,
    async (email: string): Promise<any> => {
        const appSettings = provider.settings('app');
        const vendorID = appSettings?.vendorID ? appSettings.vendorID : '';
        const vendor = vendorID !== '' ? `?vendorID=${vendorID}` : '';
        const response: AxiosResponse = await axios.get(
            `/v1/user/forgot/${email}${vendor}`,
            {
                baseURL: window.API_LINK,
                headers: headers(window.STDHeaders),
            },
        );
        if (
            response.statusText === 'OK' ||
            response.status === 200 ||
            response.status === 204
        ) {
            localStorage.clear();
            window.STDHeaders.delete('X-Auth');
            Router.go('../');
            window.displayMessage(
                `Een e-mail is verzonden naar ${email} als dit mailadres bij ons bekend is.`,
            );
            const isLander = window.store.getState().lander;
            if (isLander) {
                window.store.dispatch(resetLanderPage());
                Router.go(routePaths.lander);
                return response.data;
            }
            Router.go(routePaths.login);
            return response.data;
        }
        window.displayMessage(
            'Wachtwoord vergeten e-mail kon niet worden verzonden, probeer opnieuw.',
            'error',
        );
        throw response.status;
    },
);

// change password
const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
const CHANGE_PASSWORD_FAILED = 'CHANGE_PASSWORD_FAILED';
export const changePassword = new ReduxAsync(
    CHANGE_PASSWORD,
    CHANGE_PASSWORD_SUCCESS,
    CHANGE_PASSWORD_FAILED,
    async (oldPassword: string, newPassword: string): Promise<any> => {
        const response: AxiosResponse = await axios.post(
            `/v1/gql`,
            {
                query: `
mutation ChangePassword($oldPassword:String!, $newPassword:String!) {
  changePassword(
    input:{
      oldPassword: $oldPassword,
      newPassword: $newPassword,
    })
} 
                `,
                variables: { oldPassword, newPassword },
            },
            {
                baseURL: window.API_LINK,
                headers: headers(window.STDHeaders),
            },
        );
        // if a pure gql error occurs, gql will always send a 200 regardless
        // in case there is not a 200, a different error, like a network error could have occurred
        if (
            response &&
            response.statusText !== 'OK' &&
            response.status !== 200
        ) {
            throw response.status;
        }
        const gqlData = response.data as gqlResponse;
        // gql errors come in a form of an array in the 200 response
        // if that errors array is empty, every has gone right,
        // we display a success message
        if (!gqlData.errors?.length) {
            window.displayMessage('Je wachtwoord is gewijzigd.');
            return response.data;
        }
        // if an error has occurred, it's either a match or requirement error.
        // if it's none, we display a generic error message.
        let errorMessage = '';
        if (gqlData.errors[0]?.extensions.error?.includes('match')) {
            errorMessage =
                'Je huidig opgegeven wachtwoord komt niet overeen met je huidige wachtwoord.';
        }
        if (gqlData.errors[0]?.extensions.error?.includes('requirements')) {
            errorMessage = 'Je nieuwe wachtwoord voldoet niet minimale eisen.';
        }
        if (!errorMessage.length) {
            errorMessage = 'Er is helaas iets misgegaan.';
        }
        window.displayMessage(errorMessage, 'warning');
        throw gqlData.errors[0]?.message;
    },
);
