import { Action } from 'redux';
import { notificationBarComponentType } from 'types/notification';
import { PREFIX } from './company';

export interface notificationCta {
    text: string;
    callback: () => void;
}

export interface notificationDuration {
    fixed: boolean;
    ms: number;
}

export interface SetNotificationAction extends Action<string> {
    routePaths?: string[];
    information: string;
    duration?: notificationDuration;
    cta?: notificationCta;
    notificationBarType?: notificationBarComponentType;
}
export const SET_NOTIFICATION: string = `${PREFIX}SET_NOTIFICATION`;
export const setNotification: (
    information: string,
    routePaths?: string[],
    cta?: notificationCta,
    duration?: notificationDuration,
    notificationBarType?: notificationBarComponentType,
) => SetNotificationAction = (
    information: string,
    routePaths?: string[],
    cta?: notificationCta,
    duration?: notificationDuration,
    notificationBarType?: notificationBarComponentType,
): SetNotificationAction => ({
    type: SET_NOTIFICATION,
    routePaths,
    information,
    cta,
    duration,
    notificationBarType,
});
export const DELETE_ALL_NOTIFICATIONS: string = `${PREFIX}DELETE_ALL_NOTIFICATIONS`;
export const deleteAllNotifications: () => Action = (): Action => ({
    type: DELETE_ALL_NOTIFICATIONS,
});
