import { LionButtonSubmit } from '@lion/button';
import { property } from 'lit-element';
import { CSSResult } from 'lit';
import { customElement } from 'lit/decorators/custom-element.js';
import { HvButton } from './HvButton';

type themeType = 'primary' | 'secondary';

@customElement('hv-button-submit')
export class HvButtonSubmit extends LionButtonSubmit {
    @property({
        attribute: 'theme',
        reflect: true,
    })
    public theme: themeType = 'primary';

    public static get styles(): CSSResult[] {
        return HvButton.styles;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        'hv-button-submit': HvButtonSubmit;
    }
}
