import { RouterLocation } from '@vaadin/router';
import { Action } from 'redux';
import { PREFIX } from './company';

export const SET_AWAIT_LOGIN = 'SET_AWAIT_LOGIN';
export const awaitLogin = (loading: Boolean) => ({
    type: SET_AWAIT_LOGIN,
    loading,
});

export interface SetLocationAction extends Action<string> {
    location: RouterLocation;
}
export const SET_LOCATION = `${PREFIX}SET_LOCATION`;
export const setLocation: (location: RouterLocation) => SetLocationAction = (
    location: RouterLocation,
): SetLocationAction => ({
    type: SET_LOCATION,
    location,
});
