import { provider } from '@weavelab/frontend-connect';
import enums from 'enums';
import settings from 'internationalization/settings';
import EzUsageTemplate from '../../templates/ez-usage-template/usage-template';

const themeSettings = provider.settings('gas');

/**
 * Class for showing gas usage using Usage Template
 */
export default class EzGas extends EzUsageTemplate {
    /**
     * Constructor of EzGas
     */
    constructor() {
        super();
        /** @type {Number | null} */
        this.usageType = enums.UsageTypeGasUsage;
        /** @type {string}
         */
        this.label = 'm³';
        /** @type {Boolean} */
        this.active = false;
        /** @type {string} */
        this.usageHeaderType = 'gas';
        /** @type {String} */
        this.appTitle =
            themeSettings && themeSettings.title !== undefined
                ? themeSettings.title
                : this.appTitle;
        /** @type {String} */
        this.dataColor = settings.gasAccentColor;
    }

    /**
     * Callback for global state changed
     * @param  {...any} args
     */
    _stateChanged(...args) {
        // @ts-ignore
        super._stateChanged(...args);
    }
}

window.customElements.define('ez-gas', EzGas);
