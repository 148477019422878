import { ListCostReports } from '@async-reducers/costReports';
import '@atoms/HvUsageHeader';
import '@polymer/iron-flex-layout/iron-flex-layout';
import '@polymer/iron-icon/iron-icon';
import '@polymer/iron-icons/iron-icons';
import '@polymer/iron-pages/iron-pages';
import '@polymer/iron-selector/iron-selector';
import { html, PolymerElement } from '@polymer/polymer/polymer-element';
import { provider } from '@weavelab/frontend-connect';
import 'chart.js/dist/Chart';
import { currency, unit } from 'helpers/currency';
import { routePaths } from 'helpers/routing';
import { VAT } from 'helpers/vat';
import { connect } from 'pwa-helpers/connect-mixin';
import watch from 'redux-watch';
import { requestGraph } from '../../../actions/graph';
import { setSelectedSnapshot } from '../../../actions/snapshot';
import '../../../containers/invoice-grid/components/information-header/InformationHeader';
import enums from '../../../enums';
import {
    getCurrentWeekAsString,
    getMonday,
    getWeekDayAsString,
    monthNamesFull,
} from '../../../globals';
import { insertAt } from '../../../helpers/array';
// These are the actions needed by this element.
import {
    convertDateTo,
    datesAreOnSameDay,
    datesAreOnSameMonth,
    daysDiffBetweenDates,
    DEFAULT_LOCALE,
    getFirstDayOfTheMonth,
    getLastDayOfTheMonth,
    isFutureDate,
} from '../../../helpers/dates';
import { hasOneSnapshot, hasSnapshots } from '../../../helpers/snapshot';
import { getSplitBusinessFromJWT } from '../../../helpers/splitBusiness';
import { hexToRGB } from '../../../helpers/string';
import { isMonthSettled, store } from '../../../store';
import '../../elements/dropdown-selector-component/DropdownSelector';
import '../../ez-icons';
import '../../ez-menu-icons';
import '../../general/ez-data-table/ez-data-table';
// chartJS Helpers
import { Snapshot } from '../../../types/snapshot';
import {
    chartJSGetLabel,
    chartJSGetTitle,
    defaultUsageChartType,
    getLegend,
    getXAxes,
    getYAxes,
    usageChartTypesEnum,
    usageTypeToProductType,
} from './chartJSHelpers';
import { customToolTip } from './create-tooltip';
import template from './usage-template.html';
import css from './usage-template.pcss';
import { pageUsageTypes } from './usageHelpers';

const themeSettings = provider.settings('usage-template');
// const hvUsageHeaderSettings = provider.settings('hv-usage-header');

const dateFormat = { month: 'short', year: 'numeric' };

/**
 * Class EzUsageTemplate
 * @extends PolymerElement
 */
export default class EzUsageTemplate extends connect(store)(PolymerElement) {
    /**
     * Constructor of class
     */
    constructor() {
        super();
        /** @type {Array} */
        this.dataSetsColors = [];
        /** @type {string} */
        this.label = '';
        /** @type {number | null} */
        this.usageType = null;
        /** @type {string} */
        this.usageHeaderType = 'cost';
        /** @type {boolean} */
        this.active = false;
        /** @type {boolean} */
        this.waitingForData = false;
        /** @type {Date} */
        this.currentDate = new Date();
        /** @type {Date} */
        this.previousDate = this.currentDate;
        /** @type {string} */
        this.totalUsage = 0;
        /** @type {string} */
        this.totalCost = 0;
        /** @type {boolean} */
        this.showDataTable = false;
        /** @type {String} */
        this.appTitle = this.appTitle === undefined ? '' : this.appTitle;
        /** @type {Number} */
        this.scopeControlVariant =
            themeSettings && !Number.isNaN(themeSettings.scopeControlVariant)
                ? themeSettings.scopeControlVariant
                : 1;

        /** @type {Number} */
        this.infoLineVariant =
            themeSettings && !Number.isNaN(themeSettings.infoLineVariant)
                ? themeSettings.infoLineVariant
                : 1;
        /** @type {Boolean} */
        this.showStartDateLine =
            themeSettings?.showStartDateLine !== undefined
                ? !!themeSettings.showStartDateLine
                : false;

        /** @type {String} */
        this.graphDataLineBorderColor =
            themeSettings?.graphDataLineBorderColor !== undefined &&
            themeSettings.graphDataLineBorderColor !== ''
                ? themeSettings.graphDataLineBorderColor
                : '#95a4ac';

        /** @type {String} */
        this.graphDataLinePointBackground =
            themeSettings?.graphDataLinePointBackground !== undefined &&
            themeSettings.graphDataLinePointBackground !== ''
                ? themeSettings.graphDataLinePointBackground
                : '#95a4ac';

        /** @type {String} */
        this.graphLabelScaleYColor =
            themeSettings?.graphLabelScaleYColor !== undefined &&
            themeSettings.graphLabelScaleYColor !== ''
                ? themeSettings.graphLabelScaleYColor
                : '#aaa';

        /** @type {String} */
        this.graphLabelTickFontColor =
            themeSettings?.graphLabelTickFontColor !== undefined &&
            themeSettings.graphLabelTickFontColor !== ''
                ? themeSettings.graphLabelTickFontColor
                : '#aaa';

        /** @type {String} */
        this.graphLabelXFontColor =
            themeSettings?.graphLabelXFontColor !== undefined &&
            themeSettings.graphLabelXFontColor !== ''
                ? themeSettings.graphLabelXFontColor
                : '#aaa';

        /** @type {String} */
        this.graphGridColor =
            themeSettings?.graphGridColor !== undefined &&
            themeSettings.graphGridColor !== ''
                ? themeSettings.graphGridColor
                : '#0000001a';

        /** @type {String} */
        this.disclaimer =
            themeSettings?.disclaimer != null ? themeSettings.disclaimer : null;

        /** @type {Boolean} */
        this.showSupplierInformation = themeSettings?.showSupplierInformation
            ? themeSettings.showSupplierInformation
            : false;

        this.noDataInformation = themeSettings?.noDataInformation
            ? themeSettings.noDataInformation
            : 'Wij wachten op je standen';

        this.chargingSessionsInfo = themeSettings?.chargingSessionsInfo
            ? themeSettings.chargingSessionsInfo
            : 'Stroomverbruik van het laadpunt';

        /** @type {Number} */
        this.currentWeekNumber = 0;

        /** @type {Boolean} */
        this.hideCurrentWeekNumber = true;

        /** @type {import('types/cost').CostType[]} */
        this.gasCost = [];
        /** @type {Number} */
        this.privateCost = 0;
        /** @type {Number} */
        this.businessCost = 0;
        /** @type {String} */
        this.pageName = '';

        /** @type {String} */
        this.prevPage = '';
        /** @type {Boolean} */
        this.firstPageLoad = true;
        /** @type {String} */
        this.defaultSelected = defaultUsageChartType;
        /** @type {String} */
        this.selected = this.defaultSelected;
        /** @type {String} */
        this.previousSelected = this.selected;
        /** @type {Boolean} */
        this.monthUsageCompleted = false;
        /** @type {Number} */
        this.snapshotsIndex = 0;
        /** @type {String} */
        this.currentMonthYear = 'de maand';
        /** @type {Boolean} */
        this.showNotificationOnPage = false;
        /** @type {Boolean} */
        this.chargingSessions = false;
        /** @type {Boolean} */
        this.displayHeaderTooltip = false;
        /** @type {Array} */
        this.graphDetails = [];
        this.usageTooltipInformation = themeSettings?.usageTooltipInformation
            ? themeSettings.usageTooltipInformation
            : 'Jouw tussenstand is gebaseerd op jouw factuurbedragen van de gehele periode minus het werkelijke verbruik en het verwachte verbruik.';

        // This default string could be overridden by setting this prop by the child
        this.graphDescription =
            'Deze grafiek gaat uit van volledige saldering incl. energiebelasting, btw en inkoopkosten. Lever je per jaar meer terug dan dat je van het net verbruikt, krijg je bij de jaarafrekening over dat extra deel alleen de kale stroomprijs incl. btw terug.';
    }

    /**
     * Get the HTML body
     */
    static get template() {
        const cssTemplate = document.createElement('template');
        cssTemplate.innerHTML = provider.styles(css);
        const htmlTemplate = document.createElement('template');
        htmlTemplate.innerHTML = template;
        return html`<style include="iron-flex">
                ${cssTemplate}
            </style>
            ${htmlTemplate}`;
    }

    /**
     * Get the properties of this class
     */
    static get properties() {
        return {
            currentChart: {
                type: Object,
            },
            label: String,
            init: Boolean,
            charts: {
                type: Array,
                value: [
                    {
                        name: 'usageChartDaily',
                        label: 'Dag',
                    },
                    {
                        name: 'usageChartWeekly',
                        label: 'Week',
                    },
                    {
                        name: 'usageChartMonthly',
                        label: 'Maand',
                    },
                    {
                        name: 'usageChartYearly',
                        label: 'Jaar',
                    },
                ],
            },
            selected: {
                type: String,
                observer: '_selectChart',
            },
            previousSelected: {
                type: String,
            },
            graphDetails: {
                type: Array,
                value: [],
            },
            supplierLogo: {
                type: String,
            },
            dataColor: {
                type: String,
                observer: 'dataColorChanged',
            },
            businessDataColor: {
                type: String,
                observer: 'dataColorChanged',
            },
            typeIcon: {
                type: String,
            },
            currentDate: {
                type: Object,
                value: new Date(),
                observer: '_dateChanged',
            },
            previousDate: {
                type: Object,
                value: new Date(),
            },
            header: {
                type: String,
            },
            subheader: {
                type: String,
            },
            active: {
                type: Boolean,
                value: false,
                observer: '_activeChanged',
            },
            nodata: {
                type: Boolean,
                value: false,
            },
            waitingForData: {
                type: Boolean,
            },
            showDataTable: {
                type: Boolean,
                value: false,
            },
            value: {
                type: String,
                value: 0.0,
            },
            busy: {
                type: Boolean,
                value: false,
            },
            insightsAppUser: {
                type: Boolean,
                value: false,
            },
            currentWeekNumber: {
                type: Number,
                value: 0,
            },
            hideCurrentWeekNumber: {
                type: Boolean,
                value: true,
            },
            costHasChanged: {
                type: Boolean,
                value: false,
            },
            elekCost: {
                type: Array,
                value: false,
                observer: '_costObserver',
            },
            elekProdCost: {
                type: Array,
                value: false,
                observer: '_costObserver',
            },
            gasCost: {
                type: Array,
                value: false,
                observer: '_costObserver',
            },
            privateCost: {
                type: Number,
                value: 0,
            },
            businessCost: {
                type: Number,
                value: 0,
            },
            pageName: {
                type: String,
            },
            showSupplierLogo: {
                type: Boolean,
                value: false,
            },
            snapshots: {
                type: Array,
                value: [],
            },
            selectedSnapshot: {
                type: Object,
            },
            sortedSnapshots: {
                type: Object,
            },
            activeSnapshotID: {
                type: String,
            },
            prevUsageType: {
                type: Number,
            },
            snapshotEndsBeforeMonthEnds: {
                type: Boolean,
            },
            currentMonthsDatasets: {
                type: Array,
                value: [],
            },
            currentMonthsEstimations: {
                type: Array,
                value: [],
            },
            startDate: {
                type: String,
                observer: 'startDateChanged',
            },
            activeSnapshot: {
                type: Object,
            },
            currentUsageType: {
                type: Number,
                value: 0,
            },
            hideNotification: {
                type: Boolean,
                value: true,
            },
            hasEnergysplitter: {
                type: Boolean,
                value: false,
            },
            // FIXME: Needs to be removed after a succesfull VAT release
            countErrors: {
                type: Number,
                value: 0,
            },
            usageTooltipInformation: {
                type: String,
            },
        };
    }

    /**
     * observer properties
     */
    static get observers() {
        return ['_costObserver(elekCost, elekProdCost, gasCost)'];
    }

    /**
     * onBeforeEnter is a Vaadin router lifecycle method
     * @param {RouterLocation} location
     * @param {PreventAndRedirectCommands} commands
     * @param {Router} router
     */
    onBeforeEnter(location) {
        this.totalCost = 0;
        this.waitingForData = false;
        // sets usage type
        this.currentUsageType = pageUsageTypes[location.pathname.substring(1)];
        this._initPage(location.pathname.substring(1));
        this.showNotificationOnPage =
            location.pathname === routePaths.energyCost;
    }

    /**
     * onAfterEnter is a Vaadin router lifecycle method
     * @param {RouterLocation} location
     * @param {PreventAndRedirectCommands} commands
     * @param {Router} router
     */
    onAfterEnter() {
        this.totalCost = 0;
        this.waitingForData = false;
    }

    /**
     * Ready Callback of class
     */
    ready() {
        super.ready();

        // required if state hasn't changed but the page has been rendered.
        const snapshotState = store.getState().jwtSnapshots;
        if (
            snapshotState &&
            snapshotState.selectedSnapshot &&
            snapshotState.sortedByLatestSnapshot?.length
        ) {
            this.selectedSnapshot = snapshotState.selectedSnapshot;
            this.sortedSnapshots = [...snapshotState.sortedByLatestSnapshot];
            this.activeSnapshotID = snapshotState.selectedSnapshot.id;
        }

        // watch if the selected snapshot changes
        const selectedSnapshotWatcher = watch(
            store.getState,
            'jwtSnapshots.selectedSnapshot',
        );
        store.subscribe(
            selectedSnapshotWatcher(
                /**
                 * @param {import('src/types/snapshot').Snapshot} JWTSnapshots
                 */
                (selectedSnapshot) => {
                    // we only want to trigger a change when the snapshot has really changed
                    if (selectedSnapshot) {
                        this.selectedSnapshot = selectedSnapshot;
                        this.activeSnapshotID = selectedSnapshot.id;
                        const endDate =
                            this.getSnapshotEndDate(selectedSnapshot);
                        this.currentDate = endDate;

                        this._fetchData(
                            this._getIntervalType(this.selected),
                            this.currentDate,
                            selectedSnapshot,
                        );

                        this.hasEnergysplitter =
                            selectedSnapshot.split_business != null
                                ? selectedSnapshot.split_business
                                : false;
                    }
                },
            ),
        );
        // watch if the sorted snapshot has changed
        const sortedSnapshotsWatcher = watch(
            store.getState,
            'jwtSnapshots.sortedByLatestSnapshot',
        );
        store.subscribe(
            sortedSnapshotsWatcher(
                /**
                 * @param {Object} JWTSnapshots
                 */
                (sortedSnapshots) => {
                    this.sortedSnapshots = [...sortedSnapshots];
                },
            ),
        );

        // watch when graph data is successfully fetched
        const graphDetailsChanged = watch(
            store.getState,
            'graph.data.detailed',
        );
        store.subscribe(
            graphDetailsChanged((details) => {
                this.graphDetails = details;
            }),
        );

        this.createBarsWithRoundTop();

        const usageWatch = watch(store.getState, 'usage');
        store.subscribe(
            usageWatch(
                /**
                 * Watches for new usage in the state
                 * @param {any} data
                 */
                (data) => {
                    if (
                        data &&
                        Array.isArray(data.__attachedCostData) &&
                        data.__attachedCostData.length > 0 &&
                        Array.isArray(data.__attachedCostData[0].data)
                    ) {
                        if (
                            this.currentUsageType === data.usage_type ||
                            (data.usage_type == null && data.datasets)
                        ) {
                            this.setUsageDataAndInformation(data);
                        }
                    } else if (this.currentChart) {
                        this.currentChart.destroy();
                    }
                },
            ),
        );

        // Watchers for electricity, production and gas costs.
        const watchelekUsageCost = watch(store.getState, 'elekUsageCost.data');
        store.subscribe(
            watchelekUsageCost((elekCost) => {
                this.elekCost = elekCost;
            }),
        );

        const watchElekProdCost = watch(store.getState, 'elekProdCost.data');
        store.subscribe(
            watchElekProdCost((elekProdCost) => {
                this.elekProdCost = elekProdCost;
            }),
        );

        const watchGasUsageCost = watch(store.getState, 'gasUsageCost.data');
        store.subscribe(
            watchGasUsageCost((gasCost) => {
                this.gasCost = gasCost;
            }),
        );

        // If the month is settled hide the notification component.
        const watchIsSettled = watch(store.getState, 'IsMonthSettled.data');
        store.subscribe(
            watchIsSettled((data) => {
                this.hideNotification = data.settled;
            }),
        );

        // Watches if the costReportsWithEstimations data has changed
        // this data gets changed when the user switches to year overview
        const watchCostReportsWithEstimations = watch(
            store.getState,
            'CostReportsWithEstimations',
        );
        store.subscribe(
            watchCostReportsWithEstimations((costAndUsages) => {
                if (!costAndUsages.busy && costAndUsages?.data != null) {
                    this.setUsageDataAndInformation(costAndUsages.data);
                }
            }),
        );
    }

    /**
     * Watches if the snapshot has not ended to fetch more usages.
     * @param {any} usage
     */
    setUsageDataAndInformation(usage) {
        this._checkInitialization(usage);

        // this logic gets called if the snapshot ends within a month.
        if (this.snapshotEndsBeforeMonthEnds) {
            const previousSnapshot = this.getSnapshotFrom(true);
            // if month values has not been completed and previous snapshot is not null fetch additional usages.
            if (previousSnapshot && !this.monthUsageCompleted) {
                const endDate = this.getSnapshotEndDate(previousSnapshot);
                const startDate = this.getSnapshotStartDate(previousSnapshot);
                let origionalStartDate = new Date(this.startDate);
                origionalStartDate = new Date(
                    origionalStartDate.setHours(
                        origionalStartDate.getHours() + 2,
                    ),
                );

                let fromDate = new Date(endDate);
                // if date contract starts within the same month set formDate to snapshot startDate.
                if (datesAreOnSameMonth(startDate, origionalStartDate)) {
                    fromDate = this.getSnapshotStartDate(previousSnapshot);
                }
                if (datesAreOnSameMonth(fromDate, this.startDate)) {
                    // check if it's the last months day to prevent fetching the last day over and over.
                    const lastMonthDay =
                        getLastDayOfTheMonth(fromDate).getDate();
                    this.monthUsageCompleted =
                        fromDate.getDate() === lastMonthDay ||
                        endDate > lastMonthDay;

                    this._fetchData(
                        this.intervalType,
                        fromDate,
                        previousSnapshot,
                    );
                }
            }

            return;
        }

        this.monthUsageCompleted = false;
    }

    /**
     * get the active snapshot and return the endDate
     * @param {Snapshot} snapshot
     * @return {Date | Null}
     */
    getSnapshotEndDate(snapshot) {
        if (
            this.activeSnapshotID != null &&
            this.activeSnapshotID !== '00000000-0000-0000-0000-000000000000'
        ) {
            let snapshotEndDate =
                snapshot.verified_snapshot_payload.end_date ||
                snapshot.verified_snapshot_payload.delivery_stopped_date;
            if (snapshotEndDate) {
                // const firstDayOfMonth = getFirstDayOfTheMonth(snapshotEndDate).getDate();
                const endDateDate = new Date(snapshotEndDate);

                // by setting the end date a bit for the start of the day the corret data will be fetched. Example endate backend is jan 1 00.00 this will transform it to dec 31 23.59

                snapshotEndDate = endDateDate;
                snapshotEndDate = new Date(
                    snapshotEndDate.setDate(snapshotEndDate.getDate() - 1),
                );
                snapshotEndDate = new Date(snapshotEndDate.setHours(23));
                snapshotEndDate = snapshotEndDate.setMinutes(59);

                // set snapshot end date and prevent future dates.

                const result =
                    snapshotEndDate && !isFutureDate(snapshotEndDate)
                        ? new Date(snapshotEndDate)
                        : new Date();

                return result;
            }
        }

        return new Date();
    }

    /**
     * get the active snapshot and return the startDate
     * @param {Snapshot} snapshot
     * @return {Date | Null}
     */
    getSnapshotStartDate(snapshot) {
        if (
            this.activeSnapshotID != null &&
            this.activeSnapshotID !== '00000000-0000-0000-0000-000000000000'
        ) {
            const snapshotStartDate =
                snapshot.verified_snapshot_payload.start_date;
            if (snapshotStartDate) {
                return new Date(snapshotStartDate);
            }
        }

        return new Date();
    }

    /**
     * showSupplierInformation checks if logo or are available and vendor allows showing the suppliers logo.
     * @param {Object} selectedSnapshot
     * @return {Boolean}
     */
    showSupplierInfo(selectedSnapshot) {
        let _selectedSnapshot = selectedSnapshot;
        if (selectedSnapshot == null) {
            _selectedSnapshot = store.getState().jwtSnapshots.selectedSnapshot;
        }
        const check =
            /* eslint-disable camelcase */
            _selectedSnapshot?.external_supplier?.logo_url !== '' ||
            /* eslint-disable camelcase */
            _selectedSnapshot?.external_supplier?.name !== '';
        this.showSupplierLogo =
            /* eslint-disable camelcase */
            _selectedSnapshot?.external_supplier?.logo_url !== '';
        return this.showSupplierInformation && check;
    }

    /**
     * Get total cost if exists
     * @param {Number?} costTotal
     * @param {String?} subheader
     * @param {String?} pageName
     * @return {string}
     */
    _getCostAmount(costTotal, subheader, pageName) {
        if (this.usageType) {
            return costTotal != null ? `${currency(costTotal)}` : '';
        }
        this._setHeaderAndSubheader(this.selected);
        const isEnergyCosts = this._currentPageIsEnergyCosts(pageName);

        if (isEnergyCosts) {
            const hasSplitBusiness = getSplitBusinessFromJWT();
            if (hasSplitBusiness) {
                return `Totaal werkelijke kosten ${subheader}`;
            }
        }

        return 'Totaal werkelijke kosten';
    }

    /**
     * getSupplierLogo get the supplier logo from the snapshot
     * @param {Object} snapshot
     * @return {string}
     */
    getSupplierLogo(snapshot) {
        if (snapshot && snapshot.external_supplier) {
            this.supplierLogo = snapshot.external_supplier.logo_url;
        }

        return this.supplierLogo;
    }

    /**
     * convertToUTC will convert to UTC
     * @param {Date} date
     * @return {Date}
     */
    convertToUTC = (date) => {
        const now_utc = Date.UTC(
            date.getUTCFullYear(),
            date.getUTCMonth(),
            date.getUTCDate(),
            date.getUTCHours(),
            date.getUTCMinutes(),
            date.getUTCSeconds(),
        );
        return new Date(now_utc);
    };

    /**
     * getWeeklyDates create a start and end date for a week
     * @param {Date} date
     * @return {Date[]}
     */
    getWeeklyDates = (date) => {
        const dateFrom = getMonday(date);
        const dateTill = new Date(dateFrom);

        dateTill.setDate(dateTill.getDate() + 7);

        return [this.formatDate(dateFrom), this.formatDate(dateTill)];
    };

    /**
     * getMonthlyDates create a start and end date for a month
     * @param {Date} date
     * @return {Date[]}
     */
    getMonthlyDates = (date) => {
        const dateFrom = new Date(date);
        dateFrom.setDate(1);
        const dateTill = new Date(
            dateFrom.getFullYear(),
            dateFrom.getMonth() + 1,
        );
        return [this.formatDate(dateFrom), this.formatDate(dateTill)];
    };

    /**
     * getYearlyDates create a start and end date for a year
     * @param {Date} date
     * @return {Date[]}
     */
    getYearlyDates = (date) => {
        const dateFrom = new Date(date);
        dateFrom.setDate(1);
        dateFrom.setMonth(0);
        const dateTill = new Date(dateFrom);
        dateTill.setFullYear(dateTill.getFullYear() + 1);
        return [this.formatDate(dateFrom), this.formatDate(dateTill)];
    };

    /**
     * getDailyDates create a start and end date for a day
     * @param {Date} date
     * @return {Date[]}
     */
    getDailyDates = (date) => {
        const dateFrom = new Date(date);
        const dateTill = new Date(dateFrom);

        dateTill.setDate(dateTill.getDate() + 1);

        return [this.formatDate(dateFrom), this.formatDate(dateTill)];
    };

    /**
     * daysInAWeek will return the days in a week for chargerdata but a day extra for other data
     * @param {Bool} chargerData
     * @return {int}
     */
    daysInAWeek = (chargerData) => (chargerData ? 7 : 8);

    /**
     * @param {import('types/cost').CostType[]} elekCost
     * @param {import('types/cost').CostType[]} elekProdCost
     * @param {import('types/cost').CostType[]} gasCost
     * When elek, prod or gas changes it calculates the costs for split-business
     */
    _costObserver(elekCost, elekProdCost, gasCost) {
        const isSplitBusiness = getSplitBusinessFromJWT();
        let privateCostCalculated = 0;
        let businessCostCalculated = 0;
        if (isSplitBusiness && elekCost && elekProdCost && gasCost) {
            if (elekCost.length > 0) {
                const { privateCost, businessCost } =
                    this._calculatePrivateAndBusinessCosts(elekCost);
                privateCostCalculated += privateCost;
                businessCostCalculated += businessCost;
            }
            if (elekProdCost.length > 0) {
                const { privateCost, businessCost } =
                    this._calculatePrivateAndBusinessCosts(elekProdCost);
                privateCostCalculated -= privateCost;
                businessCostCalculated -= businessCost;
            }
            if (gasCost.length > 0) {
                const { privateCost, businessCost } =
                    this._calculatePrivateAndBusinessCosts(gasCost);
                privateCostCalculated += privateCost;
                businessCostCalculated += businessCost;
            }
        }

        this.privateCost = privateCostCalculated;
        this.businessCost = businessCostCalculated;
    }

    /**
     *  @typedef {Object} privateAndBusinessCosts
     *  @property {number} privateCost.
     *  @property {number} businessCost.
     */

    /**
     * Split cost in work and private costs.
     * @param {import('types/cost').CostType[]} costs
     * @return {privateAndBusinessCosts}
     */
    _calculatePrivateAndBusinessCosts = (costs) =>
        costs.reduce(
            (acc, hourCost) => {
                if (hourCost.total_incl == null || hourCost.total_incl === '') {
                    if (this.countErrors < 2) {
                        console.error(
                            'Cost per hour is missing the total_incl property',
                            hourCost,
                        );
                        this.countErrors += 1;
                    }
                    if (hourCost.vat) {
                        hourCost.total_incl =
                            hourCost.total_excl + hourCost.vat;
                    } else {
                        hourCost.total_incl = hourCost.total_excl * VAT;
                    }
                }

                if (hourCost.business) {
                    acc.businessCost += hourCost.total_incl;
                } else {
                    acc.privateCost += hourCost.total_incl;
                }

                return acc;
            },
            {
                privateCost: 0.0,
                businessCost: 0.0,
            },
        );

    /**
     * Get the sub header
     * @param {number} privateCost
     * @param {number} businessCost
     * @param {String?} subheader
     * @param {String?} pageName
     * @return {string}
     */
    _getSubHeader(privateCost, businessCost, subheader, pageName) {
        const isEnergyCosts = this._currentPageIsEnergyCosts(pageName);
        if (!isEnergyCosts) {
            return subheader;
        }
        // split business logic
        const isSplitBusiness = getSplitBusinessFromJWT();
        if (isSplitBusiness) {
            // if both the private and businiss cost is null there arent any costs yet.
            if (privateCost !== 0 || businessCost !== 0) {
                return `Privé ${currency(
                    this.privateCost.toFixed(2),
                )} en zakelijk ${currency(this.businessCost.toFixed(2))}`;
            }
            return 'Wij wachten op je standen';
        }
        return subheader;
    }

    /**
     * @param {String} pageName
     * @return {Boolean}
     */
    _currentPageIsEnergyCosts = () =>
        window.location.pathname.includes('energiekosten');

    /**
     * Get sanitized value
     * @param {Number} value
     * @return {String}
     */
    _getValue = (value) =>
        value != null && (value > 0.1 || value < 0.0) ? `${unit(value)}` : '?';

    /**
     * Get sanitized value
     * @param {Boolean} active
     */
    _activeChanged(active) {
        this.active = active;
    }

    /**
     * Inits page when first routed to
     * @param {String} page
     */
    _initPage(page) {
        if (this.prevPage !== page) {
            this.init = true;
            this.firstPageLoad = false;
            this.prevPage = page;
        }
    }

    /**
     * If init and no data, set current date week back
     * @param {Object} usage from global state
     */
    _checkInitialization(usage) {
        if (this.init && usage.datasets[0].data.length === 0) {
            const tmpDate = new Date(this.currentDate);
            this.currentDate = new Date(tmpDate.setDate(tmpDate.getDate() - 7));

            this.init = false;
            this.nodata = true;
        } else {
            this._createChart(usage);
        }

        this.init = false;
    }

    /**
     * Shows data table yes or no
     */
    _showDataTable() {
        this.set('showDataTable', !this.showDataTable);
    }

    _disableDetails(selected) {
        return selected === 'usageChartYearly';
    }

    /**
     * Returns text to display when no data is available for current interval
     * @param {boolean} waitingForData if there is no data available for current interval
     * @param {boolean} nodata
     * @return {string}
     */
    _getNoDataText() {
        const snapshots = store.getState().jwtSnapshots;
        if (
            snapshots &&
            snapshots.selectedSnapshot &&
            snapshots.selectedSnapshot.snapshot_phase <
                enums.SnapshotPhaseDeliveryStarted
        ) {
            return `Wij wachten op goedkeuring van je netbeheerder.
                Daarna start je inzicht`;
        }
        return this.noDataInformation;
    }

    /**
     * Returns text to display when no data is available for current interval
     * @param {boolean} waitingForData
     * @param {boolean} nodata
     * @return {boolean}
     */
    _onlyEstimations = (waitingForData, nodata) => {
        if (waitingForData == null) {
            return !waitingForData === nodata;
        }
        return !nodata === waitingForData;
    };

    /**
     * checks if current usage chart is daily and only has estimations
     * @param {String} currentUsageChart 'usageChartDaily', '"usageChartWeekly', 'usageChartMonthly' or 'usageChartYearly'
     * @param {boolean} waitingForData
     * @param {boolean} nodata
     */
    hasDailyAndOnlyEstimations = (currentUsageChart, waitingForData, data) => {
        if (currentUsageChart === 'usageChartDaily') {
            return this._onlyEstimations(waitingForData, data);
        }
        return false;
    };

    /**
     * checks if current usage chart is not daily and only has estimations
     * @param {String} currentUsageChart 'usageChartDaily', '"usageChartWeekly', 'usageChartMonthly' or 'usageChartYearly'
     * @param {boolean} waitingForData
     * @param {boolean} nodata
     * @return {boolean}
     */
    isNotDailyAndOnlyHasEstimations = (
        currentUsageChart,
        waitingForData,
        nodata,
    ) => {
        if (currentUsageChart === 'usageChartDaily') {
            return false;
        }
        return this._onlyEstimations(waitingForData, nodata);
    };

    /**
     * Dispatches fetch usage data to the store with correct parameters
     * @param {String} intervalType 'daily', 'weekly', 'monthly' or yearly
     * @param {Date} currentDate is the current date to fetch data for
     * @param {Snapshot} snapshot
     */
    _fetchData(intervalType, currentDate, snapshot) {
        this._setHeaderAndSubheader(this.selected);
        this._setIconUrl();
        // Fallback to prevent broken charts, should not happen
        if (currentDate === 'Invalid Date') {
            currentDate = new Date();
            console.error('currentDate was empty');
        }
        const currentDateUTC = this.convertToUTC(currentDate);

        if (this.showDataTable) {
            this.showDataTable = false;
        }

        let formatedFrom;
        let formatedTill;
        switch (intervalType) {
            case 'weekly':
                [formatedFrom, formatedTill] =
                    this.getWeeklyDates(currentDateUTC);
                break;
            case 'monthly':
                [formatedFrom, formatedTill] =
                    this.getMonthlyDates(currentDateUTC);
                break;
            case 'yearly':
                [formatedFrom, formatedTill] =
                    this.getYearlyDates(currentDateUTC);
                window.store.dispatch(
                    ListCostReports.run(
                        formatedTill,
                        snapshot.id,
                        formatedFrom,
                        usageTypeToProductType(this.usageType),
                    ),
                );
                // This is implemented to prevent the default behaviour
                // for fetching usages on the old rest route
                return;
            default:
                [formatedFrom, formatedTill] =
                    this.getDailyDates(currentDateUTC);
        }

        // fetch new graph data.
        // TODO: check if request graph could do without full snapshot state.
        store.dispatch(
            requestGraph(
                intervalType,
                this.usageType,
                formatedFrom,
                formatedTill,
                snapshot,
                this.chargingSessions,
            ),
        );

        this.startDate = formatedFrom;
        this.prevUsageType = this.usageType;

        if (snapshot && this.prevUsageType === this.usageType) {
            this.checkIfSnapshotIsEndingWithinEndingMonth(
                snapshot,
                formatedFrom,
                intervalType,
            );
        }
    }

    /**
     * Check if snapshot is ending and ends within days before the day within the ending month
     * @param {Snapshot} selectedSnapshot
     * @param {Date} fromDate
     * @param {String} intervalType 'daily', 'weekly' or 'monthly'
     */
    checkIfSnapshotIsEndingWithinEndingMonth(
        selectedSnapshot,
        fromDate,
        intervalType,
    ) {
        // Always set snapshotEndsBefore to false;
        this.snapshotEndsBeforeMonthEnds = false;

        const snap = selectedSnapshot.verified_snapshot_payload;
        let endDate = snap.end_date
            ? snap.end_date
            : snap.delivery_stopped_date;
        endDate = new Date(endDate);

        if (endDate.getDate() === getLastDayOfTheMonth(endDate).getDate()) {
            return;
        }

        // if the from month is the end date of the selected and day is before the lastday of the month
        if (datesAreOnSameMonth(endDate, fromDate)) {
            this.snapshotEndsBeforeMonthEnds = true;
            this.intervalType = intervalType;
        }
    }

    /**
     * Format date to ISO String
     * @param {Date|number} date
     * @return {string}
     */
    formatDate = (date) => {
        const d = new Date(date);
        d.setHours(0, 0, 0, 0);
        return d.toISOString();
    };

    /**
     * Added rounded top to bar chart
     */
    createBarsWithRoundTop = () => {
        Chart.elements.Rectangle.prototype.draw = function () {
            const { ctx } = this._chart;
            const vm = this._view;
            let radius;
            const { borderWidth } = vm;

            const left = vm.x - vm.width / 2;
            const right = vm.x + vm.width / 2;
            const top = vm.y;
            const bottom = vm.base;

            ctx.beginPath();
            ctx.fillStyle = vm.backgroundColor;
            ctx.strokeStyle = vm.borderColor;
            ctx.lineWidth = borderWidth;

            // Corner points, from bottom-left to bottom-right clockwise
            // | 1 2 |
            // | 0 3 |
            const corners = [
                [left, bottom],
                [left, top],
                [right, top],
                [right, bottom],
            ];

            for (let i = 1; i < 3; i += 1) {
                const width = corners[2][0] - corners[1][0];
                const height = corners[0][1] - corners[1][1];
                const x = corners[1][0];
                const y = corners[1][1];

                radius = 5;

                ctx.moveTo(x + radius, y);
                ctx.lineTo(x + width - radius, y);
                // if height is lower then zero it's a negative bar chart, and ht ecurve.
                if (height < 0) {
                    ctx.quadraticCurveTo(x + width, y, x + width, y - radius);
                } else {
                    ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
                }
                ctx.lineTo(x + width, y + height);
                ctx.lineTo(x, y + height);

                // if height is lower then zero it's a negative bar chart.
                if (height < 0) {
                    ctx.lineTo(x, y - radius);
                } else {
                    ctx.lineTo(x, y + radius);
                }
                ctx.quadraticCurveTo(x, y, x + radius, y);
            }

            ctx.fill();
            if (borderWidth) {
                ctx.stroke();
            }
        };
    };

    /**
     * Callback for currentDate changed
     */
    _dateChanged() {
        if (!this.currentDate) return;

        // we only actually want to fetch whenever the date really changed, not because of some component refresh
        if (this.currentDate.toString() !== this.previousDate.toString()) {
            this._fetchData(
                this._getIntervalType(this.selected),
                this.currentDate,
                this.selectedSnapshot,
            );
            this.previousDate = this.currentDate;
        }
    }

    /**
     * Converts chartName to interval string
     * @param {string} chartName that is selected
     * @return {string} interval defined as string
     */
    _getIntervalType = (chartName) =>
        chartName.substring(10, chartName.length).toLowerCase();

    /**
     * Converts chartName to interval string
     * @param {string} chartName that is selected
     * @return {string} chart label
     */
    _getChartLabel(chartName) {
        const chart = this.charts.filter((c) => chartName === c.name);
        if (chart[0]?.label) {
            return chart[0].label;
        }
        return '';
    }

    /**
     * Callback for selected, fetches data for selected chart and sets header texts
     * @param {String} chartName is the chartname selected
     */
    _selectChart(chartName) {
        // we only want to fetch when the interval type really changed, not because of
        if (
            chartName !== 'no-selection' &&
            this.selected !== this.previousSelected
        ) {
            this._fetchData(
                this._getIntervalType(this.selected),
                this.currentDate,
                this.selectedSnapshot,
            );
            this.previousSelected = this.selected;
        }
    }

    /**
     * Sets the url of the icon
     */
    _setIconUrl() {
        switch (this.usageType) {
            case enums.UsageTypeElekProd:
                this.typeIcon = 'production';
                break;
            case enums.UsageTypeGasUsage:
                this.typeIcon = 'gas';
                break;
            case enums.UsageTypeElekUsage:
                this.typeIcon = 'stroom';
                break;
            default:
                this.typeIcon = 'coins';
                break;
        }
    }

    /**
     * Set properties of graph
     * @param {String} chartName is the name of selected chart
     */
    _setHeaderAndSubheader(chartName) {
        let usageStringHeader = 'Verbruik';
        let usageStringHeaderSubHeader = 'Verbruikt';
        if (this.usageType === enums.UsageTypeElekProd) {
            usageStringHeader = 'Teruglevering';
            usageStringHeaderSubHeader = 'Teruggeleverd';
        } else if (!this.usageType) {
            usageStringHeader = '';
            usageStringHeaderSubHeader = '';
        } else if (this.chargingSessions) {
            usageStringHeaderSubHeader = 'Opgeladen';
        }

        const headerAndDtile = this._getCurrentChartName(
            chartName,
            usageStringHeader,
            usageStringHeaderSubHeader,
        );
        this.header = headerAndDtile.header;
        this.subheader = headerAndDtile.subtitle;
    }

    /**
     *
     * @param {String} chartName
     * @param {String} usageStringHeader
     * @param {String} usageStringHeaderSubHeader
     * @return {chartName}
     */
    _getCurrentChartName(
        chartName,
        usageStringHeader,
        usageStringHeaderSubHeader,
    ) {
        let subHeaderAddition = '';
        let weekday = getWeekDayAsString(this.currentDate);

        let header;
        let subtitle;

        switch (chartName) {
            case 'usageChartDaily':
                header = `${usageStringHeader} dagelijks`;
                if (window.innerWidth >= 768 && this.usageType) {
                    subHeaderAddition = `${usageStringHeaderSubHeader} op `;
                } else {
                    weekday =
                        weekday.charAt(0).toUpperCase() + weekday.slice(1);
                }

                subtitle = `${
                    subHeaderAddition + weekday
                } ${this.currentDate.getDate()} ${
                    monthNamesFull[this.currentDate.getMonth()]
                }`;
                break;
            case 'usageChartWeekly':
                header = `${usageStringHeader} wekelijks`;
                if (window.innerWidth >= 768 && this.usageType) {
                    subHeaderAddition = `${usageStringHeaderSubHeader} van `;
                }
                subtitle =
                    subHeaderAddition +
                    getCurrentWeekAsString(this.currentDate);
                break;
            case 'usageChartMonthly':
                header = `${usageStringHeader} maandelijks`;
                if (window.innerWidth >= 768 && this.usageType) {
                    subHeaderAddition = `${usageStringHeaderSubHeader} in `;
                }
                subtitle = `${
                    subHeaderAddition +
                    monthNamesFull[this.currentDate.getMonth()]
                } ${new Date(this.currentDate).getFullYear()}`;
                break;
            case 'usageChartYearly':
                header = `${usageStringHeader} jaarlijks`;
                if (window.innerWidth >= 768 && this.usageType) {
                    subHeaderAddition = `${usageStringHeaderSubHeader} in `;
                }
                subtitle = `${subHeaderAddition} ${new Date(
                    this.currentDate,
                ).getFullYear()}`;
                break;
            default:
                break;
        }

        return { header, subtitle };
    }

    // required to fetch usages when chaging from pages (elec, gas, )
    connectedCallback() {
        super.connectedCallback();
        // the selectedSnapshot only exist when pages changes
        if (this.selectedSnapshot) {
            this._fetchData(
                this._getIntervalType(this.selected),
                new Date(),
                this.selectedSnapshot,
            );
        }
    }

    // Fallback to remove chart and tooltip when swiching components
    disconnectedCallback() {
        const chart = this.shadowRoot.querySelector('#chartcanvas');
        if (chart) {
            chart.remove();
        }
        const tooltip = window.document.querySelector('#chartjs-tooltip');
        if (tooltip) {
            tooltip.remove();
        }
        super.disconnectedCallback();
    }

    /**
     * Creates chart dataset object
     * @param {Array} data are the values shown in the current chart
     * @param {String} label
     * @param {String} backgroundColor
     * @return {Object} chart dataset object
     */
    _createBarDataSet(data, label, backgroundColor) {
        const datasetBar = {
            type: 'bar',
            maxBarThickness: 10,
            data,
            label,
            backgroundColor,
        };

        this.updateStyles({
            '--chart-theme-color': backgroundColor,
        });
        return datasetBar;
    }

    /**
     * Creates chart dataset object
     * @param {Array} data are the values shown in the current chart
     * @return {Object} chart dataset object
     */
    _createLineDataSet(data) {
        let label;
        switch (this.usageType) {
            case enums.UsageTypeElekProd:
                label = 'Verwachte teruglevering';
                break;
            case enums.UsageTypeElekUsage || enums.UsageTypeGasUsage:
                label = 'Verwachte verbruik';
                break;
            default:
                label = 'Verwachte kosten';
        }
        const datasetLine = {
            type: 'line',
            label,
            borderColor: this.graphDataLineBorderColor,
            pointBackgroundColor: this.graphDataLinePointBackground,
            borderWidth: 1,
            fill: false,
            data,
        };
        return datasetLine;
    }

    /**
     * Hides the tooltip if visible
     */
    _hideToolTipIfVisible = () => {
        const tooltipEl = document.getElementById('chartjs-tooltip');
        if (tooltipEl != null) {
            if (tooltipEl.style.opacity === '1') {
                tooltipEl.style.opacity = '0';
            }
        }
    };

    /**
     * This is the reduce function which combines the estimations of multiple contract
     * Example:
     *   - Contract 1 (1 jan till 15 jan) estimations [4,3,6,5,6,9,3,5]
     *   - Contract 2 (15 jan till 31 jan) estimations [0,0,0,0,7,8,9,2]
     *
     * This function will combine those 2 estimation to one like [4,3,6,5,7,8,9,2]
     */
    fillUpEmptyEstimations(prev, acc) {
        const combinedEstimations = prev;

        acc.forEach((value, i) => {
            if (
                combinedEstimations[i] === undefined ||
                Number(combinedEstimations[i]) === 0
            ) {
                combinedEstimations[i] = value;
            }
        });

        return combinedEstimations;
    }

    /**
     * Creates and displays chart with usage state data
     * @param {Object} data is the current global usage state
     */
    _createChart(data) {
        if (data == null) {
            console.error('Usage data could not be null!');
            return;
        }

        const usage = [...data.datasets[0].data];
        const estimations = [...data.datasets[1].data];
        const usageBusiness = [...data.datasets[2].data];

        if (this.currentChart) {
            this.currentChart.destroy();
        }
        // check for data
        const dataCheck = data != null && usage.length === 0 && usage;

        if (dataCheck && estimations.length === 0) {
            this.nodata = true;
            this.waitingForData = false;
            this.totalUsage = 0;
            this.totalCost = 0;
            return;
        }
        if (dataCheck && estimations && estimations.length) {
            this.waitingForData = true;
        } else {
            this.waitingForData = false;
        }

        // get the current week number
        const beginDate = new Date(this.currentDate.getFullYear(), 0, 1);
        const currentWeek = Math.ceil(
            (this.currentDate - beginDate) / (1000 * 60 * 60 * 24) / 7,
        );
        this.currentWeekNumber = currentWeek;

        // hide the week number when we display per month
        if (
            this.selected === 'usageChartMonthly' ||
            this.selected === 'usageChartYearly'
        ) {
            this.hideCurrentWeekNumber = true;
        } else {
            this.hideCurrentWeekNumber = false;
        }

        this.nodata = false;
        let totalPrice = 0;
        let totalUsage = 0;

        const chartName = this.selected;
        if (chartName != null && chartName.length > 1) {
            /**
             * @type {HTMLCanvasElement|null}
             */
            let chart = null;
            if (this.shadowRoot != null) {
                chart = this.shadowRoot.querySelector('#chartcanvas');
            }
            this._hideToolTipIfVisible();

            if (chart != null) {
                const datasets = [];
                // shallow copy only the values in the array.
                const attached = data.__attachedCostData;
                const usageCosts = [...attached[0].data];
                const usageBusinessCosts = [...attached[1].data];
                const savedData = {
                    private: {
                        length: usage.length,
                        cost: [...usageCosts],
                        usage: [...usage],
                    },
                    business: {
                        length: usageBusiness.length,
                        cost: [...usageBusinessCosts],
                        usage: [...usageBusiness],
                    },
                };

                const accumulateUsage = (acc, usg) => {
                    let _acc = acc;
                    _acc += usg;
                    return _acc;
                };

                const hasBusiness =
                    savedData.business.cost.reduce(accumulateUsage, 0) !== 0;

                let label = this._getLegendaLabel(this.usageType);
                const labelBusiness = 'Zakelijk';
                if (this.label.toLowerCase() === 'euro' && hasBusiness) {
                    label = 'Privé';
                }
                const barDataSet = this._createBarDataSet(
                    usage,
                    label,
                    this.dataSetsColors[0],
                );
                const barDataSetBusiness = this._createBarDataSet(
                    usageBusiness,
                    labelBusiness,
                    this.dataSetsColors[1],
                );

                // is snapshot ends before the month has ended combine multiple datasets.
                if (
                    this.snapshotEndsBeforeMonthEnds ||
                    this.currentMonthsDatasets.length > 0
                ) {
                    barDataSet.backgroundColor = [];
                    let count = 0;
                    this.currentMonthsDatasets.push(savedData);
                    this.currentMonthsEstimations.push(estimations);

                    if (this.currentMonthsDatasets.length > 1) {
                        this.currentMonthsDatasets.forEach(
                            (currentMonthsDataset, index) => {
                                const monthData = currentMonthsDataset.private;
                                const monthDataBusiness =
                                    this.currentMonthsDatasets[index].business;

                                const barColor = `rgba(${hexToRGB(
                                    this.dataColor,
                                ).map((bar) => `${bar}`)},${
                                    1 - (index < 3 ? index * 0.25 : 0.75)
                                })`;

                                // sets day cost, usage and price for all datasets.
                                const dataSetsArr = [
                                    monthData,
                                    monthDataBusiness,
                                ];
                                const dataCostArr = [
                                    usageCosts,
                                    usageBusinessCosts,
                                ];
                                const barDataSetsArr = [
                                    barDataSet,
                                    barDataSetBusiness,
                                ];
                                dataSetsArr.forEach((set, i) => {
                                    for (
                                        count;
                                        count < set.length;
                                        count += 1
                                    ) {
                                        barDataSetsArr[i].backgroundColor[
                                            count
                                        ] = barColor;
                                        const dayUsage = set.usage[count];
                                        barDataSetsArr[i].data[count] =
                                            dayUsage;
                                        totalUsage += Number(dayUsage);
                                        let price = 0;
                                        if (dayUsage !== 0) {
                                            const dayPrice = set.cost[count];
                                            price = dayPrice;
                                            if (!Number.isNaN(dayPrice)) {
                                                totalPrice += dayPrice;
                                            }
                                        }

                                        // add cost price for dataset
                                        dataCostArr[i][count] = price;
                                    }
                                });
                            },
                        );
                    }

                    if (!this.snapshotEndsBeforeMonthEnds) {
                        this.currentMonthsDatasets = [];
                    }
                } else {
                    if (this.currentMonthsDatasets.length !== 0) {
                        this.currentMonthsDatasets = [];
                    }

                    if (this.currentMonthsEstimations.length !== 0) {
                        this.currentMonthsEstimations = [];
                    }

                    const accumulateUsageCosts = (accumulator, price) => {
                        if (Number.isNaN(price)) {
                            return accumulator;
                        }
                        let _accumulator = accumulator;
                        _accumulator += price;
                        return _accumulator;
                    };

                    const accumulateDataSetUsage = (accumulator, price) => {
                        let _accumulator = accumulator;
                        _accumulator += Number(price);
                        return _accumulator;
                    };

                    totalPrice = usageCosts.reduce(accumulateUsageCosts, 0.0);

                    if (this.selected !== 'usageChartYearly') {
                        totalPrice = usageBusinessCosts.reduce(
                            accumulateUsageCosts,
                            totalPrice,
                        );
                    }

                    totalUsage = barDataSet.data.reduce(
                        accumulateDataSetUsage,
                        0.0,
                    );

                    totalUsage = barDataSetBusiness.data.reduce(
                        accumulateDataSetUsage,
                        totalUsage,
                    );
                }

                if (
                    !this.chargingSessions &&
                    estimations &&
                    estimations.length
                ) {
                    // Add estimations dataset if estimations has values.
                    if (this.currentMonthsEstimations.length > 0) {
                        const fillupEstimationMultipleMonths =
                            this.currentMonthsEstimations
                                .reverse()
                                .reduce(this.fillUpEmptyEstimations, []);

                        datasets.push(
                            this._createLineDataSet(
                                fillupEstimationMultipleMonths,
                            ),
                        );
                    } else {
                        datasets.push(this._createLineDataSet(estimations));
                    }
                }

                // only add dataset if costs exist, else it will only show estimations.
                if (!this.waitingForData) {
                    datasets.push(barDataSet);
                    if (hasBusiness) {
                        datasets.push(barDataSetBusiness);
                    }
                }

                const doubleChartData = {
                    labels: data.labels,
                    datasets,
                    costs: usageCosts,
                };
                this.totalCost = totalPrice;
                this.totalUsage = totalUsage;

                /**
                 * Create chart
                 */
                this.createChartObject(chart, doubleChartData, hasBusiness);
            }
        }
    }

    /**
     * createChartObject renders the chart data
     * @param {HTMLCanvasElement} chart
     * @param {import('./chartJSHelpers').ChartDataExtended} doubleChartData
     * @param {boolean} hasBusiness
     */
    createChartObject(chart, doubleChartData, hasBusiness) {
        const { usageType, currentDate } = this;
        const {
            graphLabelScaleYColor,
            graphLabelTickFontColor,
            graphGridColor,
            graphLabelXFontColor,
        } = this;
        const ctx = chart.getContext('2d');

        Chart.defaults.global.legend.labels.usePointStyle = true;
        this.currentChart = new Chart(ctx, {
            type: 'bar',
            data: doubleChartData,
            options: {
                scaleBeginAtZero: false,
                maintainAspectRatio: false,
                responsive: true,
                legend: getLegend(),
                tooltips: {
                    mode: 'index',
                    axis: 'x',
                    enabled: false,
                    // /**
                    //  * Custom function called for every model in graph when click event occurs on graph
                    //  * @param {ChartTooltipModel} tooltipModel
                    //  */
                    custom: (tooltipModel) => {
                        if (this.selected && chart != null) {
                            if (
                                usageType != null &&
                                doubleChartData.costs &&
                                tooltipModel.dataPoints &&
                                !this.chargingSessions
                            ) {
                                this.addCostToTooltip(
                                    tooltipModel,
                                    doubleChartData,
                                );
                            }

                            customToolTip(
                                tooltipModel,
                                this.dataSetsColors,
                                this.currentChart,
                                chart,
                            );
                        }
                    },
                    callbacks: {
                        label: (item) =>
                            chartJSGetLabel(
                                item,
                                doubleChartData,
                                usageType,
                                hasBusiness,
                            ),
                        title: (item) =>
                            chartJSGetTitle(item, this.selected, currentDate),
                    },
                },
                scales: {
                    yAxes: getYAxes(
                        hasBusiness,
                        usageType,
                        graphLabelScaleYColor,
                        graphLabelTickFontColor,
                        graphGridColor,
                    ),
                    xAxes: getXAxes(
                        hasBusiness,
                        graphLabelXFontColor,
                        graphGridColor,
                    ),
                },
            },
        });
    }

    /**
     * Add costs data when required
     * @param {ChartTooltipModel} tooltipModel
     * @param {import('./chartJSHelpers').ChartDataExtended} doubleChartData
     */
    addCostToTooltip = (tooltipModel, doubleChartData) => {
        const { index } = tooltipModel.dataPoints[0];

        const costData = Number(doubleChartData.costs[index]);
        const costDataFormatted = Number.isNaN(costData)
            ? currency(0)
            : currency(costData);
        insertAt(tooltipModel.body, 1, {
            after: [],
            before: [],
            lines: [costDataFormatted],
            cost: true,
        });
    };

    /**
     *
     * @param {Number} usageType
     * @return {string}
     */
    _getLegendaLabel(usageType) {
        switch (usageType) {
            case enums.UsageTypeElekUsage:
            case enums.UsageTypeGasUsage:
                return this.chargingSessions
                    ? this.chargingSessionsInfo
                    : 'Werkelijk verbruik';
            case enums.UsageTypeElekProd:
                return 'Werkelijke teruglevering';
            default:
                return 'Werkelijke kosten';
        }
    }

    /**
     * Sets currentDate backwards according to selected chartName
     */
    _backward() {
        this._hideToolTipIfVisible();
        // check if its the previous snapshots enddate if so select that snapshot.
        const previousSnapshot = this.getSnapshotFrom(false);
        if (previousSnapshot) {
            let startDate = this.getSnapshotStartDate(this.selectedSnapshot);
            if (startDate) {
                // set both days to the first day of the month
                startDate = new Date(
                    startDate.setMonth(startDate.getMonth() + 1),
                );
                startDate = new Date(startDate.setHours(0, 0, 0, 0));
                startDate = new Date(startDate.setDate(1));
                const currMonth = this.currentDate;
                currMonth.setDate(1);

                if (datesAreOnSameDay(startDate, currMonth)) {
                    this.totalCost = 0;
                    store.dispatch(setSelectedSnapshot(previousSnapshot));
                    let endDate = this.getSnapshotsEndDate(
                        this.selectedSnapshot,
                    );
                    if (endDate) {
                        endDate = new Date(endDate.setDate(1));
                        this.currentDate = endDate;
                    }

                    return;
                }
            }
        }

        let startMonth;
        let lastDayPrevMonth;
        let daysBack;

        switch (this.selected) {
            case 'usageChartDaily':
                this._goDaysBack(1);
                break;
            case 'usageChartWeekly':
                this._goDaysBack(7);
                break;
            case 'usageChartMonthly':
                startMonth = getFirstDayOfTheMonth(this.currentDate);
                lastDayPrevMonth = new Date(startMonth.setDate(0));
                daysBack = daysDiffBetweenDates(
                    this.currentDate,
                    lastDayPrevMonth,
                );
                this._goDaysBack(daysBack);
                break;
            case 'usageChartYearly':
                startMonth = getFirstDayOfTheMonth(this.currentDate);
                startMonth.setFullYear(startMonth.getFullYear() - 1);
                this.currentDate = startMonth;
                break;
            default:
                console.warn('Unknown graph type');
        }
    }

    /**
     * Sets currentDate forward according to selected chartName
     */
    _forward() {
        this._hideToolTipIfVisible();

        // checks if the next month is the start of a snapshots month else switch snapshot.
        let endDate = this.getSnapshotsEndDate();
        if (
            endDate &&
            this.selected === usageChartTypesEnum.usageChartMonthly
        ) {
            endDate = new Date(endDate.setHours(0, 0, 0, 0));
            endDate = new Date(endDate.setDate(1));
            let currDate = this.currentDate;
            currDate = new Date(currDate.setHours(0, 0, 0, 0));
            currDate = new Date(currDate.setDate(1));
            if (datesAreOnSameDay(endDate, currDate)) {
                const nextSnapshot = this.getSnapshotFrom(true);
                if (nextSnapshot) {
                    const startDate = this.getSnapshotStartDate(nextSnapshot);
                    if (startDate) {
                        this.totalCost = 0;
                        const nextMonthFirstDay =
                            getFirstDayOfTheMonth(startDate);
                        nextMonthFirstDay.setMonth(
                            nextMonthFirstDay.getMonth() + 1,
                        );
                        this.currentDate = nextMonthFirstDay;
                        store.dispatch(setSelectedSnapshot(nextSnapshot));
                        this.currentDate = nextMonthFirstDay;

                        return;
                    }
                }
            }
        }

        let d;

        switch (this.selected) {
            case 'usageChartDaily':
                this._goDaysForward(1);
                break;
            case 'usageChartWeekly':
                this._goDaysForward(7);
                break;
            case 'usageChartMonthly':
                d = new Date(this.currentDate);
                d.setMonth(d.getMonth() + 1, 1);
                this.currentDate = getLastDayOfTheMonth(d);
                break;
            case 'usageChartYearly':
                d = new Date(this.currentDate);
                d.setFullYear(d.getFullYear() + 1);
                this.currentDate = d;
                break;
            default:
                console.warn('Unknown graph type');
        }
    }

    /**
     * @param {Object} snapshot
     * @return {Object}
     */
    getSnapshotsEndDate = (snapshot) => {
        let _snapshot = snapshot;
        _snapshot = snapshot == null ? this.selectedSnapshot : snapshot;
        let endDate;
        if (_snapshot && _snapshot.verified_snapshot_payload) {
            endDate = _snapshot.verified_snapshot_payload.delivery_stopped_date;
            if (endDate) {
                return new Date(endDate);
            }
        }
        return null;
    };

    /**
     * Sets currentDate back amount of days
     * @param {Number} amount of days that currentDate should go back
     */
    _goDaysBack(amount) {
        const oldDate = new Date(this.currentDate);
        const newDate = new Date(oldDate.setDate(oldDate.getDate() - amount));
        this.currentDate = newDate;
    }

    /**
     * Sets currentdate forward amount of days
     * @param {Number} amount of days that currentDate should go forward
     */
    _goDaysForward(amount) {
        const oldDate = new Date(this.currentDate);
        const newDate = new Date(oldDate.setDate(oldDate.getDate() + amount));
        this.currentDate = newDate;
    }

    /**
     * Check if scope control variant
     * @param {Number|String} variant
     * @return {Boolean}
     */
    _scopeControlVariant(variant) {
        return (
            !Number.isNaN(Number(variant)) &&
            this.scopeControlVariant === parseInt(String(variant), 10)
        );
    }

    /**
     * Check if info line variant
     * @param {Number|String} variant
     * @return {Boolean}
     */
    _infoLineVariant(variant) {
        return (
            !Number.isNaN(Number(variant)) &&
            this.infoLineVariant === parseInt(String(variant), 10)
        );
    }

    /**
     * Get the start date for the first snapshot
     * @param {Array?} jwtSnapshots
     * @return {String}
     */
    _getContractStartDate = (jwtSnapshots) => {
        if (Array.isArray(jwtSnapshots) && jwtSnapshots.length > 0) {
            const snapshot = jwtSnapshots.selectedSnapshot;
            if (
                snapshot.verified_snapshot_payload &&
                snapshot.verified_snapshot_payload.start_date
            ) {
                const d = new Date(
                    snapshot.verified_snapshot_payload.start_date,
                );
                const t =
                    themeSettings &&
                    themeSettings.startDateTransform &&
                    typeof themeSettings.startDateTransform === 'function'
                        ? themeSettings.startDateTransform
                        : /**
                           * @param {String} t
                           * @return {String}
                           */
                          (td) => td;
                return t(
                    `${
                        [
                            'zondag',
                            'maandag',
                            'dinsdag',
                            'woensdag',
                            'donderdag',
                            'vrijdag',
                            'zaterdag',
                        ][d.getDay()]
                    } ${d.getDate()} ${
                        [
                            'januari',
                            'februari',
                            'maart',
                            'april',
                            'mei',
                            'juni',
                            'juli',
                            'augustus',
                            'september',
                            'oktober',
                            'november',
                            'december',
                        ][d.getMonth()]
                    } ${d.getFullYear()}`,
                    d,
                );
            }
        }
        return '';
    };

    /**
     * Check if prop is null
     * @param {any} prop
     * @return {boolean}
     */
    _null = (prop) => prop === null;

    /**
     * Set state from redux
     * @param {Object} state
     */
    _stateChanged(state) {
        if (state) {
            const { jwtSnapshots, graph, listCostReports } = state;
            this.jwtSnapshots = Array.isArray(jwtSnapshots) ? jwtSnapshots : [];
            if (graph?.busy != null && listCostReports?.busy != null) {
                this.busy = graph.busy || listCostReports.busy;
            }
        }
    }

    _isNotBusy(busy) {
        return !busy;
    }

    /**
     * toggleContractMenu
     */
    toggleContractMenu() {
        const menu = this.shadowRoot.querySelector('#contract__dropdown');
        menu.classList.toggle('open');
    }

    /**
     * Check if map has snapshots
     * @param {Map} snapshots
     * @return {Boolean}
     */
    hasSnapshots = (snapshots) => hasSnapshots(snapshots);

    /**
     * Returns if snapshot map contains more than 1 snapshot
     * @param {Map} snapshots
     * @return {Boolean}
     */
    _hasOneSnapshot = (snapshots) => hasOneSnapshot(snapshots);

    /**
     * Returns outer wrapper based on page
     * @param {Map} snapshots
     * @return {String}
     */
    _getfirstSnapshot(snapshots) {
        // get the first snapshot from the map
        if (snapshots && snapshots.size) {
            return this._getStartEndDateFromVerifiedSnapshot(
                snapshots.entries().next().value[1],
            );
        }

        return '';
    }

    /**
     * Returns outer wrapper based on page
     * @param {Object} snapshot
     * @return {String}
     */
    _getStartEndDateFromVerifiedSnapshot = (snapshot) => {
        const snap = snapshot.verified_snapshot_payload;
        let endDate = snap.end_date
            ? snap.end_date
            : snap.delivery_stopped_date;
        if (endDate == null || new Date(endDate).getTime() > Date.now()) {
            endDate = 'huidig';
        } else {
            endDate = `${convertDateTo(endDate, dateFormat, DEFAULT_LOCALE)}`;
        }
        return `${convertDateTo(
            snap.start_date,
            dateFormat,
            DEFAULT_LOCALE,
        )} - ${endDate}`;
    };

    /**
     * Returns outer wrapper based on page
     * @param {Object} _
     * @param {Object} snapshot
     * @return {TemplateResult}
     */
    _rendererSnapshotStartEnd = (_, snapshot) => {
        const snap = snapshot.verified_snapshot_payload;
        let endDate = snap.end_date
            ? snap.end_date
            : snap.delivery_stopped_date;
        if (
            endDate == null ||
            endDate === '' ||
            new Date(endDate).getTime() > Date.now()
        ) {
            if (new Date(snap.start_date).getDate() > Date.now()) {
                return `vanaf ${convertDateTo(
                    snap.start_date,
                    dateFormat,
                    DEFAULT_LOCALE,
                )}`;
            }
            endDate = 'huidig';
        } else {
            endDate = `${convertDateTo(endDate, dateFormat, DEFAULT_LOCALE)}`;
        }
        return `${convertDateTo(
            snap.start_date,
            dateFormat,
            DEFAULT_LOCALE,
        )} - ${endDate}`;
    };

    /**
     * Get the snapshot form snapshot ID
     * @param {UUID} snapshotID
     * @return {Object}
     */
    getSnapshotByID(snapshotID) {
        return this.sortedSnapshots.find(
            (snapshot) => snapshot.id === snapshotID,
        );
    }

    /**
     * Get the snapshot form snapshot ID
     * @param {boolean} previous
     * @return {(Object|null)}
     */
    getSnapshotFrom = (previous) => {
        const snapArray = this.sortedSnapshots;
        const snapshotIndex = snapArray.findIndex(
            (snap) => snap.id === this.selectedSnapshot.id,
        );
        // check that prevents out of bounds prev/next snapshot.
        if (
            (snapshotIndex > 0 && previous) ||
            (!previous && snapshotIndex < snapArray.length)
        ) {
            const index = previous ? snapshotIndex - 1 : snapshotIndex + 1;
            this.snapshotsIndex = index;
            const otherSnapshot = snapArray[index];
            if (otherSnapshot) {
                return otherSnapshot;
            }
        }
        return null;
    };

    dataColorChanged() {
        this.dataSetsColors = [this.dataColor, this.businessDataColor];
    }

    // notification logic
    /**
     * required to change the date in the notification component
     * @param {Date} newDate
     */
    startDateChanged(newDate) {
        this.currentMonthYear = convertDateTo(newDate, {
            month: 'long',
            year: 'numeric',
        });
        this.showNotification(newDate);
    }

    // check if the contact is a flex contract
    isAFlexContract(selectedSnapshot) {
        const snapshot = new Snapshot(selectedSnapshot);
        return snapshot.isFlex();
    }

    /**
     * show or hide notifcation if user has split business and the
     * current month isn't settled yet.
     * @param {Boolean} hasEnergysplitter
     */
    showNotification(startDate) {
        this.hideNotification = true;

        if (!this.showNotificationOnPage) {
            return;
        }
        if (this.hasEnergysplitter && this.selectedSnapshot && startDate) {
            const sDate = new Date(startDate);
            sDate.setDate(sDate.getDate() + 2);
            // if the date is in the future, hide the notification without a call
            if (sDate.getTime() > new Date().getTime()) {
                return;
            }
            const payload = {
                // DateTime (ISODate)
                date: sDate.toISOString(),
                // DateTime (UUID)
                snapshotId: this.selectedSnapshot.id,
            };
            window.store.dispatch(isMonthSettled.request(payload));
        }
    }
}
