import { PropertyValues, customElement, property } from 'lit-element';
import { TemplateResult, html } from 'lit-html';
import { classMap } from 'lit-html/directives/class-map';
import { ifDefined } from 'lit-html/directives/if-defined';

import { GridLogic } from '../GridLogic';

export type SortDirections = 'default' | 'asc' | 'desc';
export const defaultSort: SortDirections = 'default';
export const ascSort: SortDirections = 'asc';
export const descSort: SortDirections = 'desc';

export interface GridSortEvent extends CustomEvent {
    detail: {
        path: string;
        direction: SortDirections;
        connected: any;
    };
}

/**
 * Path is the property used for sorting the data.
 * sort-direction returns in which order the item needs to be sorted
 */
@customElement('grid-sorter')
export class GridSorter extends GridLogic {
    @property()
    public header?: string;

    @property({ attribute: 'direction' })
    public direction: SortDirections = defaultSort;

    @property({ type: Boolean })
    public connected: boolean = false;

    @property()
    public indicatorState: any = { default: true, asc: false, desc: false };

    @property({
        type: Boolean,
    })
    public loading: boolean = false;

    @property({
        type: Boolean,
        reflect: true,
    })
    protected loadIndicator: boolean = true;

    constructor() {
        super();
        this.addEventListener('click', this._onClick);
    }

    public attributeChangedCallback(
        name: string,
        oldVal: string,
        newVal: string,
    ): void {
        super.attributeChangedCallback(name, oldVal, newVal);

        if (name === 'direction') {
            this._pathOrDirectionChanged();
        }
    }

    protected render = (): TemplateResult => {
        const { indicatorState } = this;
        return html`
            <style>
                :host {
                    cursor: pointer;
                }

                header:hover .content {
                    color: var(--ew-theme-color);
                }

                .container,
                .indicators {
                    display: flex;
                    align-items: center;
                }

                .container {
                    padding-right: 7px;
                    display: inline;
                }

                :host([loadIndicator]) .container,
                :host([loadIndicator]) .indicators {
                    display: none;
                }

                .indicators::before {
                    content: '';
                    display: block;
                    width: 10px;
                    height: 6px;
                    background-size: 10px 6px;
                    text-indent: -9999px;
                    /** todo: implement lit-font-aswsome instead of an svg */
                    /* -webkit-mask-image: url('./assets/sort-arrow.svg');
                    mask-image: url('./assets/sort-arrow.svg'); */
                }
                .indicators:hover:before,
                .indicators:focus:before,
                header:hover .indicators::before,
                header:focus .indicators::before {
                    background-color: var(--primary-color);
                }

                .default::before {
                    background-color: var(--grey);
                }

                .asc::before {
                    background-color: var(--primary-color);
                }

                .desc::before {
                    background-color: var(--primary-color);
                    -webkit-transform: rotate(180deg);
                    transform: rotate(180deg);
                }

                @keyframes shine {
                    to {
                        background-position: 300% 0, 0 0;
                    }
                }
            </style>
            <header>
                <div class="container">
                    ${ifDefined(this.header)
                        ? html` ${this.header} `
                        : html` <slot name="header"></slot> `}
                </div>
                <div
                    @click="${this._onClick}"
                    direction="${this.direction}"
                    class="indicators ${classMap(indicatorState)}"
                >
                    <span part="order"></span>
                </div>
            </header>
            <main>
                <slot class="slotted"></slot>
            </main>
        `;
    };

    public _pathOrDirectionChanged() {
        const { connected, path, direction } = this;
        if (path === undefined || direction === undefined) {
            return;
        }

        if (connected) {
            this.dispatchEvent(
                new CustomEvent('sorter-changed', {
                    bubbles: true,
                    composed: true,
                    detail: { path, direction, connected },
                }),
            );
        } else {
            this.direction = defaultSort;
            this.indicatorState = { default: true, asc: false, desc: false };
        }
    }

    protected updated(changedProperties: PropertyValues): void {
        super.updated(changedProperties);
        if (changedProperties.has('direction')) {
            if (changedProperties.get('direction') != null) {
                this.setAttribute('direction', this.direction);
            }
        }
        if (changedProperties.has('loading')) {
            this.loadIndicator = this.loading;
        }
    }

    private _onClick(e: Event) {
        const { indicatorState } = this;

        this.connected = true;

        e.preventDefault();
        if (indicatorState.default) {
            this.indicatorState = { default: false, asc: true, desc: false };
            this.direction = ascSort;
        } else if (indicatorState.desc) {
            this.indicatorState = { default: true, asc: false, desc: false };
            this.direction = defaultSort;
        } else {
            this.indicatorState = { default: false, asc: false, desc: true };
            this.direction = descSort;
        }
    }
}

declare global {
    interface HTMLElementTagNameMap {
        'grid-sorter': GridSorter;
    }
}
