import { customElement } from 'lit-element';
import { MaxLength, MinLength, Required } from '@lion/form-core';
import { whitelabel } from '@weavelab/whitelabel';
import { resetHook } from 'helpers/resetHook';
import { Form } from './Form';
import { ContainsUpper } from './validators/ContainsUpper';
import { ContainsLower } from './validators/ContainsLower';
import { ContainsDigit } from './validators/ContainsDigit';
import { ContainsSpecialCharacter } from './validators/ContainsSpecialCharacter';
import { Equals } from './validators/Equals';

@customElement('ice-reset')
@whitelabel({ name: 'ice-reset' })
export class Reset extends Form {
    @whitelabel()
    public static showLabel?: boolean;

    @whitelabel()
    public static styleObject?: Partial<CSSStyleDeclaration>;

    @whitelabel()
    public static placeholder?: Partial<{
        password: string | boolean;
        repeat: string | boolean;
    }>;

    @whitelabel()
    public static label?: Partial<{
        password: string;
        repeat: string;
        submit: string;
    }>;

    public connectedCallback(): void {
        super.connectedCallback();

        this.style.width = '100%';

        Object.keys(Reset.styleObject ?? {}).forEach((k: string) => {
            // @ts-ignore
            this.style[k] = Reset.styleObject[k];
        });
    }

    private listener = (): void => {
        resetHook.passwordInputChanged(this.values.password);
    };

    protected form = [
        Form.field(
            'new-password',
            'password',
            Reset.label?.password ?? 'Nieuwe wachtwoord',
            {
                label: Reset.showLabel ?? false,
                listener: this.listener,
                placeholder: Reset.placeholder?.password ?? true,
                validators: [
                    new MaxLength(512, {
                        getMessage: async () =>
                            `Het wachtwoord mag niet langer zijn dan 512 tekens`,
                    }),
                    new MinLength(12, {
                        getMessage: async () =>
                            `Het wachtwoord mag niet korter zijn dan 12 tekens`,
                    }),
                    new ContainsUpper(null, {
                        getMessage: async () =>
                            `Het wachtwoord moet minstens 1 hoofdletter bevatten`,
                    }),
                    new ContainsLower(null, {
                        getMessage: async () =>
                            `Het wachtwoord moet minstens 1 kleine letter bevatten`,
                    }),
                    new ContainsDigit(null, {
                        getMessage: async () =>
                            `Het wachtwoord moet minstens 1 cijfer bevatten`,
                    }),
                    new ContainsSpecialCharacter(null, {
                        getMessage: async () =>
                            `Het wachtwoord moet minstens 1 speciaal teken bevatten`,
                    }),
                    new Required(null, {
                        getMessage: async () => `Verplicht veld`,
                    }),
                ],
            },
        ),
        Form.field(
            'new-password',
            'repeat',
            Reset.label?.repeat ?? 'Herhaal wachtwoord',
            {
                label: Reset.showLabel ?? false,
                placeholder: Reset.placeholder?.repeat ?? true,
                validators: [
                    new Equals(() => this.values.password, {
                        getMessage: async () =>
                            `De wachtwoorden moeten gelijk zijn`,
                    }),
                    new Required(null, {
                        getMessage: async () => `Verplicht veld`,
                    }),
                ],
            },
        ),
        Form.button('submit', Reset.label?.submit ?? 'Wachtwoord opslaan'),
    ];

    // eslint-disable-next-line class-methods-use-this
    protected async submit(values: {
        password: string;
        repeat: string;
    }): Promise<void> {
        resetHook.onReset(values.password, values.repeat);
    }
}

declare global {
    interface HTMLElementTagNameMap {
        'ice-reset': Reset;
    }
}
