import { provider } from '@weavelab/frontend-connect';
import { PolymerElement, html } from '@polymer/polymer/polymer-element';

import watch from 'redux-watch';

import { convertDateTo } from 'helpers/dates';
import { currencyRounded, currency } from 'helpers/currency';
import { routePaths } from 'data-access/router';
import { debounce } from 'helpers/functions';
import { store } from '../../../store';

import { requestInvoices } from '../../../actions/invoice';
import { requestSavings } from '../../../actions/savings';

import '@polymer/iron-flex-layout/iron-flex-layout';
import '@polymer/iron-pages/iron-pages';
import '@polymer/iron-selector/iron-selector';
import '@polymer/iron-icon/iron-icon';
import '@atoms/HvLoader/HvLoader';
import '../../elements/tooltip/Tooltip';

import '../../ez-icons';

import css from './ez-standings.pcss';
import template from './ez-standings.html';
import 'chart.js/dist/Chart';

import { getMonthShortNameFromDate } from '../../../globals';

import '../../atoms/DashboardBlockHalf';
import {
    customToolTip,
    hideToolTip,
} from '../../templates/ez-usage-template/create-tooltip';

import {
    getXAxes,
    getYAxes,
} from '../../templates/ez-usage-template/chartJSHelpers';
import settings from '../../../internationalization/settings';
import '../../../containers/invoice-grid/components/information-header/InformationHeader';

const themeSettings = provider.settings('savings');

/**
 * Class EzUsageTemplate
 * @extends PolymerElement
 */
export default class EzStandings extends PolymerElement {
    /**
     * @typedef CostObject
     * @property {Number} [upfront]
     * @property {Number} [estimate]
     * @property {Number} [calculated]
     * @property {String} month
     * @property {Date} date
     */

    /**
     * Constructor of class
     */
    constructor() {
        super();

        /** @type {number} */
        this.usageType;

        /** @type {Array} */
        this.costsPerMonth;

        /** @type {string} */
        this.value;

        /** @type {Date} */
        this.currentYear;

        /** @type {Date} */
        this.startDate;

        /** @type {Boolean} */
        this.prev = false;

        /** @type {Boolean} */
        this.next = false;

        const userID = store.getState().user.id;
        if (userID != null && userID.length > 0) {
            store.dispatch(requestInvoices(userID));
        }

        /** @type {Boolean} */
        this.displaySavings = true;

        /** @type {String} */
        this.graphDataLinePointBackground =
            themeSettings &&
            themeSettings.graphDataLinePointBackground !== undefined &&
            themeSettings.graphDataLinePointBackground !== ''
                ? themeSettings.graphDataLinePointBackground
                : '#95a4ac';

        /** @type {String} */
        this.graphLabelScaleYColor =
            themeSettings &&
            themeSettings.graphLabelScaleYColor !== undefined &&
            themeSettings.graphLabelScaleYColor !== ''
                ? themeSettings.graphLabelScaleYColor
                : '#aaa';

        /** @type {String} */
        this.graphLabelTickFontColor =
            themeSettings &&
            themeSettings.graphLabelTickFontColor !== undefined &&
            themeSettings.graphLabelTickFontColor !== ''
                ? themeSettings.graphLabelTickFontColor
                : '#aaa';

        /** @type {String} */
        this.graphLabelXFontColor =
            themeSettings &&
            themeSettings.graphLabelXFontColor !== undefined &&
            themeSettings.graphLabelXFontColor !== ''
                ? themeSettings.graphLabelXFontColor
                : '#aaa';
        /** @type {String} */
        this.graphGridColor =
            themeSettings &&
            themeSettings.graphGridColor !== undefined &&
            themeSettings.graphGridColor !== ''
                ? themeSettings.graphGridColor
                : '#0000001a';

        this.savingsTooltipInformation =
            themeSettings?.savingsTooltipInformation
                ? themeSettings.savingsTooltipInformation
                : 'Het saldo van jouw tussenstand is gebaseerd op het totaal van jouw maandbedragen minus het totaal van verwachte én werkelijke energiekosten.';

        this.graphLabels = {
            amount: 'Maandbedragen',
            estimated: 'Verwachte kosten',
            actualCosts: 'Werkelijke kosten',
        };
    }

    /**
     * Get the HTML body
     */
    static get template() {
        const cssTemplate = document.createElement('template');
        cssTemplate.innerHTML = provider.styles(css);
        const htmlTemplate = document.createElement('template');
        htmlTemplate.innerHTML = template;
        return html`<style include="iron-flex">
                ${cssTemplate}
            </style>
            ${htmlTemplate}`;
    }

    /**
     * Get the properties of this class
     */
    static get properties() {
        return {
            currentChart: {
                type: Object,
            },
            nodata: {
                type: Boolean,
                value: false,
            },
            totalSaved: {
                type: Number,
                value: 0,
            },
            totalSavings: {
                type: Number,
                value: 0,
            },
            costsPerMonth: {
                type: Array,
                value: () => [],
            },
            dataColor: {
                type: String,
            },
            snapshots: {
                type: Array,
            },
            snapshot: {
                type: Object,
            },
            chartData: {
                type: Object,
                value: {},
            },
            busy: {
                type: Boolean,
                value: false,
            },
            currentYear: {
                type: Object,
                value: null,
            },
            displaySavings: {
                type: Boolean,
                value: true,
            },
            TotalfixedMonthPrice: {
                type: Number,
                value: 0,
            },
            graphLabels: {
                amount: 'Maandbedragen',
                estimated: 'Verwachte kosten',
                actualCosts: 'Werkelijke kosten',
            },
        };
    }

    disconnectedCallback() {
        const chart = this.shadowRoot.querySelector('#chartcanvas');
        if (chart) {
            chart.remove();
        }
        const tooltip = window.document.querySelector('#chartjs-tooltip');
        if (tooltip) {
            tooltip.remove();
        }
        super.disconnectedCallback();
    }

    /**
     * _setPointBackgroundColors
     * Turns the dots in the colors based on the fact if
     * they're saving in comparison to the estimation money or not
     * @param {Object} data
     * @private
     */
    _setPointBackgroundColors = (data) => {
        if (!data) {
            return;
        }

        const [monhtlyPaymentAmount, estimateAmount, realAmount] = [
            data.datasets[0],
            data.datasets[1],
            data.datasets[2],
        ];

        if (realAmount) {
            // fill up graph line points with custom styles
            const pointStyle = realAmount.data.reduce(
                (prev) => {
                    prev.pointBorderColors.push(
                        themeSettings.chartThemePrimaryColor,
                    );
                    prev.pointBackgroundColors.push(
                        themeSettings.chartThemePrimaryColor,
                    );
                    return prev;
                },
                {
                    pointBorderColors: [],
                    pointBackgroundColors: [],
                },
            );
            realAmount.borderColor = themeSettings.chartThemePrimaryColor;
            realAmount.pointBorderColor = pointStyle.pointBorderColors;
            realAmount.pointBackgroundColor = pointStyle.pointBackgroundColors;
            realAmount.tension = 0.01;
        }
        if (estimateAmount) {
            estimateAmount.tension = 0.01;
            estimateAmount.borderColor =
                themeSettings.chartThemeSecondaryLineColor;
            estimateAmount.pointBorderColor =
                themeSettings.chartThemeSecondaryLineColor;
            estimateAmount.pointBackgroundColor =
                themeSettings.chartThemeSecondaryLineColor;
        }
        if (monhtlyPaymentAmount) {
            monhtlyPaymentAmount.pointBorderColor = '#000';
            monhtlyPaymentAmount.pointBackgroundColor = '#000';
            monhtlyPaymentAmount.borderColor = '#000';
            monhtlyPaymentAmount.tension = 0.01;
        }
    };

    /**
     * _getEvenOddClass returns even or add class
     * @param {Number} index
     * @return {String}
     */
    _getEvenOddClass = (index) => (index % 2 === 0 ? 'even' : 'odd');

    /**
     *
     * Ready Callback of class
     */
    ready() {
        super.ready();

        // Init snapshots and get data on page load
        const { jwtSnapshots } = store.getState();
        this.snapshot = jwtSnapshots.selectedSnapshot;
        this.snapshots = jwtSnapshots.snapshots;
        this.updateStyles({
            '--chart-theme-primary-color': themeSettings.chartThemePrimaryColor,
            '--chart-theme-secondary-color':
                themeSettings.chartThemeSecondaryColor,
            '--chart-theme-secondary-line-color':
                themeSettings.chartThemeSecondaryLineColor,
        });
        this._getData();

        // Create new chart when this page get selected
        const watchPage = watch(store.getState, 'app.routing.page');
        store.subscribe(
            watchPage(
                /**
                 * @param {string} newPage
                 */
                (newPage) => {
                    // remove whitespaces and backslashes from the route
                    const standings = routePaths.energyStandings
                        .trim()
                        .replace(/\\/g, '');
                    if (newPage === standings) {
                        this._createChart(this.chartData);
                    }
                },
            ),
        );

        // Listen to snapshot changes and redraw chart if needed
        const jwtSnapshotsWatcher = watch(store.getState, 'jwtSnapshots');
        store.subscribe(
            jwtSnapshotsWatcher(
                /**
                 * @param {Object} jwtSnapshots
                 */
                (_jwtSnapshots) => {
                    this.snapshots = _jwtSnapshots.snapshots;
                    const oldSnapshot = this.snapshot;
                    this.snapshot = _jwtSnapshots.selectedSnapshot;
                    if (
                        oldSnapshot &&
                        this.snapshot &&
                        this.snapshot.id !== oldSnapshot.id
                    ) {
                        this.chartData = {};
                        this._getData();
                    }
                },
            ),
        );

        // Listen to snapshot changes and redraw chart if needed
        const savingsWatcher = watch(store.getState, 'savings');
        store.subscribe(
            savingsWatcher(
                /**
                 * @param {Object} savings
                 */
                (savings) => {
                    const chilldProcessBusy = store.getState().getUsages.busy;
                    if (!chilldProcessBusy) {
                        this.busy = savings.busy;
                    }
                    if (savings?.data && !savings.busy) {
                        this._setCostsData(savings.data);
                        this._createChart(this.chartData);
                    }
                },
            ),
        );

        // if monthlyValues getting fetched show busy
        const monthlyBusyWatcher = watch(store.getState, 'getUsages.busy');
        store.subscribe(
            monthlyBusyWatcher(
                /**
                 * @param {Boolean} busy
                 */
                (busy) => {
                    this.busy = busy;
                },
            ),
        );

        const debounceHideToolTip = debounce(50, hideToolTip);
        this.addEventListener('scroll', debounceHideToolTip, false);
    }

    /**
     * Check if actual cost has at least 1 cost
     * @param {Object} data data for a chart
     * @return {Boolean}
     */
    dataContainsActualCosts = (data) => {
        let valid = false;

        // check if datasets exists and loop over all actual costs to find out if cost exists
        if (data?.datasets?.length > 0) {
            for (const actualCosts of data.datasets[0].data) {
                if (actualCosts != null) {
                    valid = true;
                    break;
                }
            }
        }
        return valid;
    };

    /**
     * Creates and displays chart with usage state data
     * @param {Object} data is the current global usage state
     */
    _createChart(data) {
        if (this.currentChart) {
            this.currentChart.destroy();
        }

        // Only draw chart if we have correct data to display
        if (!this.dataContainsActualCosts(data)) {
            this.nodata = true;
            return;
        }

        this._setPointBackgroundColors(data);
        this.nodata = false;
        /**
         * @type {HTMLCanvasElement|null}
         */
        let chart = null;
        if (this.shadowRoot != null) {
            chart = this.shadowRoot.querySelector('#chartcanvas');
        }

        let chartLegend = null;
        if (this.shadowRoot != null) {
            chartLegend = this.shadowRoot.querySelector('#chart-legend');
        }

        if (chart != null) {
            const ctx = chart.getContext('2d');
            const {
                graphLabelScaleYColor,
                graphLabelTickFontColor,
                graphLabelXFontColor,
                graphGridColor,
            } = this;
            // TODO linting: please allow Chart as global
            Chart.defaults.global.legend.labels.usePointStyle = true;
            // eslint-disable-next-line no-undef
            this.currentChart = new Chart(ctx, {
                type: 'line',
                data,
                options: {
                    title: {
                        text: this._getChartTitle(this.costsPerMonth),
                        display: true,
                        labelString: 'Euro',
                        fontColor: '#aaa',
                        fontFamily: 'EzFont',
                        fontSize: 12,
                        fontStyle: '500',
                        padding: 25,
                    },
                    scaleBeginAtZero: false,
                    maintainAspectRatio: false,
                    responsive: true,
                    hover: {
                        mode: 'nearest',
                        intersect: true,
                    },
                    legend: {
                        display: false,
                    },
                    legendCallback(chrt) {
                        const renderLabels = () =>
                            chrt.data.datasets
                                .map((dataset) => {
                                    const labels = this.graphLabels
                                        ? this.graphLabels !== undefined
                                        : {
                                              amount: 'Maandbedragen',
                                              estimated: 'Verwachte kosten',
                                              actualCosts: 'Werkelijke kosten',
                                          };
                                    const legendPoint = `<div class="legend-shape legend-point" style=background-color:${dataset.borderColor}></div>`;
                                    const legendStroke = `<div class="legend-shape legend-stroke" style=background-color:${dataset.borderColor}></div>`;
                                    return `<div class="legend-item">
                                        ${
                                            dataset.label === labels.amount
                                                ? legendStroke
                                                : legendPoint
                                        }
                                        <p>${dataset.label}</p>
                                  </div>
                                `;
                                })
                                .join('');
                        return `
                            <div class="chart-legend-container">
                              ${renderLabels(chrt)}
                            </div>`;
                    },
                    tooltips: {
                        intersect: false,
                        mode: 'nearest',
                        axis: 'x',
                        enabled: false,
                        /**
                         * Custom function called for every model in graph when click event occurs on graph
                         * @param {Object} tooltipModel
                         */
                        custom: (tooltipModel) => {
                            const toolT = { ...tooltipModel };
                            if (toolT && toolT.body) {
                                // If tool tip length is greater then 3 that means that the 3rd element is estimated value, filter that one out
                                // because earlier we have put 1 duplicated point to fill the graph line gap between real and esimated costs
                                if (toolT.body.length >= 3) {
                                    const index = 2;
                                    const slicedToolTBody = [
                                        ...toolT.body.slice(0, index),
                                        ...toolT.body.slice(index + 1),
                                    ];
                                    toolT.body = slicedToolTBody;
                                }

                                toolT.body.forEach((body, index) => {
                                    if (index === 0) {
                                        // eslint-disable-next-line no-param-reassign
                                        body.cost = true;
                                    }
                                    if (body && body.lines[0]) {
                                        const arr = body.lines[0].split(':');
                                        // eslint-disable-next-line no-param-reassign
                                        body.lines[0] = `${currency(arr[1])}`;
                                    }
                                });
                            }
                            if (chart != null) {
                                customToolTip(
                                    toolT,
                                    settings.savingsAccentColor,
                                    this.currentChart,
                                    chart,
                                    false,
                                );
                            }
                        },
                    },
                    scales: {
                        yAxes: getYAxes(
                            false,
                            null,
                            graphLabelScaleYColor,
                            graphLabelTickFontColor,
                            graphGridColor,
                        ),
                        xAxes: getXAxes(
                            false,
                            graphLabelXFontColor,
                            graphGridColor,
                        ),
                    },
                },
            });

            if (chartLegend != null) {
                chartLegend.innerHTML = this.currentChart.generateLegend();
            }
        }
    }

    /**
     * Calculate saving
     * @param {Array} costs
     * @return {string}
     */
    _getChartTitle(costs) {
        if (costs && costs.length > 1) {
            return `
                ${this.currentYear.getDate()} ${getMonthShortNameFromDate(
                this.currentYear,
            )} ${this.currentYear.getFullYear()} tot ${this.currentYear.getDate()} ${
                costs[costs.length - 1].month
            } ${new Date(costs[costs.length - 1].date).getFullYear()}`;
        }

        return 'Bespaarinzicht';
    }

    /**
     * Return price as formated string
     * @param {Number} price
     * @param {Boolean} displaySavings
     * @return {string}
     */
    _toPrice = (price, displaySavings) => {
        if (displaySavings) {
            return price ? currency(price) : '-';
        }

        return 'nvt';
    };

    _formatSavingsTitle(price) {
        if (!price) {
            return '';
        }
        return currencyRounded(price.replace('-', ''));
    }

    _postiveOrNegative(price) {
        if (!price) {
            return '';
        }
        if (price > 0) {
            return 'positive';
        }
        return 'negative';
    }

    _getSummedCosts(costsPerMonth, costProp) {
        const sum = costsPerMonth.reduce(
            (partialSum, a) => partialSum + (a[costProp] || 0),
            0,
        );

        return this._toPrice(sum, true);
    }

    _getMonthPrice = (price) => (price != null ? currency(price) : '-');

    _getMonthFormatted = (month) => month.toLocaleLowerCase();

    /**
     * Fetches data from one week ago
     */
    _getData() {
        // @ts-ignore
        if (this.snapshot && this.snapshot.id) {
            if (!this.startDate) {
                const extractedStartDate = this._getStartDate(this.snapshot);
                this.startDate = new Date(extractedStartDate);
            }
            if (!this.currentYear) {
                const extractedStartDate = this._getCurrentStartDate(
                    this.snapshot,
                );
                this.currentYear = new Date(extractedStartDate);
            }

            const endDate = new Date(
                this.currentYear.getFullYear() + 1,
                this.currentYear.getMonth(),
                this.currentYear.getDate(),
            );
            this._setShowSavings(endDate);

            this.next = this._nextYearAvailable();
            this.prev = this._prevYearAvailable();

            // dispatch the request to our store to fetch the new data
            store.dispatch(
                requestSavings(
                    this.currentYear.toISOString(),
                    endDate.toISOString(),
                ),
            );
            this.busy = true;
        }
    }

    /**
     * Check if we are allowed to see the previous button
     * @param {Date} endDate
     * @private
     */
    _setShowSavings(endDate) {
        const now = new Date();

        if (now > endDate) {
            this.displaySavings = false;
        } else {
            this.displaySavings = true;
        }
    }

    /**
     * Returns a valid start date either from snapshot start or current start
     * @param {Object} snapshot
     * @return {string|Date|*}
     * @private
     */
    _getStartDate = (snapshot) => snapshot.verified_snapshot_payload.start_date;

    /**
     * Returns a valid start date either from snapshot start or current start
     * @param {Object} snapshot
     * @return {string|Date|*}
     * @private
     */
    _getCurrentStartDate = (snapshot) => {
        if (
            snapshot.verified_snapshot_payload &&
            snapshot.verified_snapshot_payload.current_start
        ) {
            return snapshot.verified_snapshot_payload.current_start;
        }
        return snapshot.verified_snapshot_payload.start_date;
    };

    /**
     * Gets costs from state
     * @param {Array} costs
     */
    _setCostsData(costs) {
        this.busy = false;

        // validate if invoices have upfront payments else hide the page
        let noData = true;
        for (const cost of costs) {
            if (cost.upfront != null) {
                noData = false;
                break;
            }
        }
        if (noData) {
            this.nodata = noData;
            return;
        }

        // Create a deep clone of costs data array
        const costsDataForGraph = JSON.parse(JSON.stringify(costs));

        // Till now the real and esimtated lines do have a gap in between graph lines
        // This will copy the latest value of the real cost to its estimate value
        // This way there is no gap between lines
        for (const [i, cost] of costsDataForGraph.entries()) {
            const nextCs = costsDataForGraph[i + 1];
            if (nextCs?.estimate && !cost.estimate) {
                cost.estimate = cost.calculated;
                break;
            }
        }

        const datasets = [];

        datasets.push({
            type: 'line',
            label: this.graphLabels?.amount,
            borderColor: '#bbb',
            pointBackgroundColor: '#bbb',
            borderWidth: 1,
            pointRadius: 0,
            fill: false,
            data: costsDataForGraph.map((cs) =>
                this._setTwoDecimals(cs.upfront),
            ),
        });

        datasets.push({
            type: 'line',
            label: this.graphLabels?.estimated,
            borderColor: '#ddd',
            pointBackgroundColor: '#ddd',
            pointRadius: 3,
            borderWidth: 1,
            fill: false,
            data: costsDataForGraph.map((cs) =>
                this._setTwoDecimals(cs.estimate),
            ),
        });

        datasets.push({
            type: 'line',
            label: this.graphLabels?.actualCosts,
            borderColor: '#000',
            pointBackgroundColor: '#000',
            pointRadius: 4,
            borderWidth: 1,
            fill: false,
            data: costsDataForGraph.map((cs) =>
                this._setTwoDecimals(cs.calculated),
            ),
        });

        this.set('costsPerMonth', costs);

        const chartData = {
            labels: costsDataForGraph.map(
                (cs) =>
                    `${convertDateTo(cs.date, {
                        month: 'short',
                        year: 'numeric',
                    })}`,
            ),
            datasets,
        };
        this.set('chartData', chartData);
        this._recalculateTotalSavings(costs);
    }

    /**
     * Recalculate the total amount of savings for the given period
     * @param {Array} costs
     * @private
     */
    _recalculateTotalSavings(costs) {
        let totalSavingsCalculated = 0;

        costs.forEach((data) => {
            const { calculated = 0, estimate = 0, upfront } = data;

            const userCosts = calculated !== 0 ? calculated : estimate;
            totalSavingsCalculated += upfront - userCosts;
        });

        this.totalSavings = totalSavingsCalculated.toFixed(0);
    }

    /**
     * @param {Object} payload snapshot verified payload
     * @return {Boolean}
     */
    _nextYearAvailable() {
        if (this.currentYear && this.startDate) {
            const today = new Date();
            const curYear = new Date(this.currentYear);
            const endDateTime = curYear.setFullYear(curYear.getFullYear() + 1);
            return endDateTime < today.getTime();
        }
        return false;
    }

    /**
     * @return {Boolean}
     */
    _prevYearAvailable() {
        if (this.currentYear && this.startDate) {
            return this.currentYear.getTime() > this.startDate.getTime();
        }
        return false;
    }

    /**
     * Go backwards a year
     * @private
     */
    _backward() {
        if (
            this.snapshot.verified_snapshot_payload &&
            this.snapshot.verified_snapshot_payload.start_date
        ) {
            this.currentYear.setFullYear(this.currentYear.getFullYear() - 1);
            this._getData();
        }
    }

    /**
     * Go Forwards a year
     * @private
     */
    _forward() {
        if (
            this.snapshot.verified_snapshot_payload &&
            this.snapshot.verified_snapshot_payload.start_date
        ) {
            this.currentYear.setFullYear(this.currentYear.getFullYear() + 1);
            this._getData();
        }
    }

    /**
     * Return two decimals when value is not undefined
     * @param {number | undefined }cost
     * @return {*}
     * @private
     */
    _setTwoDecimals = (cost) => {
        if (!cost) {
            return cost;
        }
        return cost.toFixed(2);
    };

    /**
     * Format total savings
     * @param {number} totalSavings
     * @return {number}
     */
    _formatTotalSavings = (totalSavings) => {
        let total = totalSavings.toFixed(0);
        total = total.replace('-', '');
        return Number(total);
    };

    /**
     * _fillSavingsTiles fills al the tiles with savings data
     * @param {Number} totalSavings
     */
    _fillSavingsTiles(totalSavings) {
        if (totalSavings !== null) {
            if (totalSavings < 0) {
                return 'Verwachte bijbetaling op de jaarrekening';
            }
            return 'Verwachte teruggave op de jaarrekening';
        }
        return '';
    }
}

window.customElements.define('ez-standings', EzStandings);
