import {
    CapacityType,
    ElecCapacities,
    GasCapacities,
} from '../components/capacity/CapacityComponent';

export interface ICapcity {
    capacity: CapacityType | null;
    type: string;
}

export const SelectCapacityListner = (e: CustomEvent): ICapcity => {
    // @ts-ignore
    const selectedValue = e.composedPath()[0].value;
    // @ts-ignore
    const { type } = e.composedPath()[2];

    const isElec = type === 'elec';
    const isGas = type === 'gas';
    if (!isElec && !isGas) {
        return { capacity: null, type };
    }
    e.stopImmediatePropagation();

    const capacityArray = isElec ? ElecCapacities : GasCapacities;

    let capacityType: CapacityType = { id: '', name: '' } as CapacityType;

    // TODO: future replace with a binary search helper function.
    for (let i = 0; i < capacityArray.length; i += 1) {
        // @ts-ignore
        const item = capacityArray[i].name.split(' ').join('_');

        if (item === selectedValue) {
            if (isElec) {
                capacityType = capacityArray[i];
                break;
            }

            capacityType = capacityArray[i];
            break;
        }
    }

    return { capacity: capacityType, type };
};
