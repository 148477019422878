import { Validator } from '@lion/form-core';

export class ContainsDigit extends Validator {
    static get validatorName() {
        return 'ContainsDigit';
    }

    // eslint-disable-next-line class-methods-use-this
    execute(value: any) {
        return typeof value !== 'string' || !/[0-9]/.test(value);
    }
}
