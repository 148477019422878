export const landerPage = `SET_LANDER_PAGE`;
export const resetLander = `RESET_LANDER_PAGE`;
// set loader state and optional preventDebounce call
export const fromLanderPage = () => ({
    type: landerPage,
});

// set the loaders debounce
export const resetLanderPage = () => ({
    type: resetLander,
});
