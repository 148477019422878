import { all, call, takeLatest, put } from 'redux-saga/effects';
import enums from 'enums';
import {
    REQUEST_COST_VALUES,
    requestCostValuesSuccess,
    requestCostValuesFailed,
} from 'actions/costValues';
import { betterWrapper } from './helpers/wrapper';

import { store, costSummary } from '../store';

/**
 * Runs doGetGraphData
 * @param {Object} action
 */
function* doGetCostValues(action) {
    const { selectedSnapshot } = store.getState().jwtSnapshots;

    const requestObject = {
        dateFrom: action.dateFrom,
        dateTill: action.dateTill,
        intervalType: enums.IntervalTypeHourly,
        snapshotId: selectedSnapshot?.id,
    };

    if (
        selectedSnapshot == null ||
        selectedSnapshot.snapshot_phase === 900 ||
        selectedSnapshot.snapshot_phase < enums.SnapshotPhaseSmartMeterChecked
    ) {
        yield put(requestCostValuesSuccess(0, 0, 0, 0, requestObject));
        return;
    }

    const costsRequestObject = {
        from: action.dateFrom,
        snapshotId: selectedSnapshot.id,
    };

    try {
        const [costsData] = yield all([
            call(
                betterWrapper,
                costSummary.request(costsRequestObject),
                costSummary,
            ),
        ]);

        yield put(
            requestCostValuesSuccess(
                costsData.data.cur_month_real,
                costsData.data.savings_till_now_excl_cur_month,
                costsData.data.year_savings,
                costsData.data.till_date,
                requestObject,
            ),
        );
    } catch (error) {
        yield put(requestCostValuesFailed(error));
    }
}

/**
 * Watcher for REQUEST_GRAPH action
 */
export function* watchRequestCostValues() {
    yield takeLatest(REQUEST_COST_VALUES, doGetCostValues);
}
