import {
    LitElement,
    TemplateResult,
    customElement,
    html,
    property,
    css,
} from 'lit-element';
import '@atoms/HvButton';
import '@components/elements/modal/ModalComponent';

import '@atoms/HvIntroduction';

@customElement('oda-onboarding-step-wrapper')
export class OdaOnboardingStepWrapper extends LitElement {
    @property({ type: Boolean, attribute: 'disable-navigation' })
    public disableNavigation: boolean = false;

    @property({ type: Boolean })
    hidePrevious: boolean = false;

    @property({ type: Boolean, reflect: true })
    emptyMain: boolean = true;

    @property({ type: String })
    nextText: string = 'Volgende';

    @property({ type: String })
    previousText: string = 'Dit doe ik later';

    static get styles() {
        return [
            css`
                :host {
                    --oda-inner-wrapper-height: calc(100vh - 166px);
                    --oda-onboarding-step-wrapper-button-bottom: 27px;
                    --oda-onboarding-step-wrapper-button-left: 0;
                    --oda-onboarding-step-wrapper-button-width: 100%;
                    --oda-onboarding-step-wrapper-button-margin: 0 27px;
                    --oda-onboarding-content-padding: 20px;
                    --oda-onboarding-content-border: 1px solid #e5e5e5;
                    --oda-onboarding-content-padding: 20px;
                    display: flex;
                    flex-direction: column;
                    height: var(--oda-inner-wrapper-height);
                }
                .buttons {
                    display: flex;
                    flex-direction: column;
                }
                .content {
                    padding: 0 var(--oda-onboarding-content-padding);
                    display: flex;
                    flex-direction: column;
                    flex: 1;
                }
                .buttons > hv-button {
                    margin: var(--oda-onboarding-step-wrapper-button-margin);
                }

                .buttons > hv-button:first-of-type {
                    margin-bottom: 16px;
                }

                hv-introduction {
                    border: var(--oda-onboarding-content-border);
                    border-bottom: none;
                }
                main {
                    display: flex;
                    flex-direction: column;
                    border: var(--oda-onboarding-content-border);
                    padding: var(--oda-onboarding-content-padding);
                }

                :host([emptymain]) main {
                    padding: 0;
                }
            `,
        ];
    }

    handleSlotchangeEvent = (event: CustomEvent) => {
        const slot: HTMLSlotElement | null = event.target as HTMLSlotElement;
        if (slot) {
            const childs = slot.assignedElements();
            this.emptyMain = childs.length === 0;
        }
    };

    public render = (): TemplateResult => {
        return html`
            <slot name="top-content"></slot>
            <div class="content">
                <h3 class="title"><slot name="title"></slot></h3>
                <hv-introduction
                    ><slot name="introduction"></slot
                ></hv-introduction>
                <main>
                    <slot @slotchange=${this.handleSlotchangeEvent}></slot>
                </main>
            </div>
            <div class="buttons">
                <hv-button
                    @click=${this.nextHandler}
                    theme="primary"
                    ?disabled=${this.disableNavigation}
                    >${this.nextText}</hv-button
                >
                ${!this.hidePrevious
                    ? html`<hv-button
                          @click=${this.quitHandler}
                          theme="secondary"
                          >${this.previousText}</hv-button
                      >`
                    : ''}
            </div>
        `;
    };

    // previousHandler handles te previous step trigger
    public previousHandler = (): void => {
        this.dispatchEvent(
            new CustomEvent('weave-step-previous', {
                bubbles: true,
                composed: true,
            }),
        );
    };

    // quitHandler handles te quit step trigger
    public quitHandler = (): void => {
        this.dispatchEvent(
            new CustomEvent('quit', {
                bubbles: true,
                composed: true,
            }),
        );
    };

    // nextHandler handles te next step trigger
    public nextHandler = (): void => {
        this.dispatchEvent(
            new CustomEvent('weave-step-next', {
                bubbles: true,
                composed: true,
            }),
        );
    };
}

declare global {
    interface HTMLElementTagNameMap {
        'oda-onboarding-step-wrapper': OdaOnboardingStepWrapper;
    }
}
