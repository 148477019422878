export interface Vehicle {
    id: string;
    brand: string;
    model: string;
    year: number;
    latitude: number;
    longitude: number;
    snapshot_id: string;
    charging_state: ChargingState;
    smart_charging_state: SmartChargingState;
}

export type Vehicles = {
    vehicles: Omit<Vehicle, 'smart_charging_state'>[];
};

export enum SmartChargingStateEnum {
    Disabled = 'DISABLED',
    Considering = 'CONSIDERING',
    PlanExecutingStopping = 'PLAN:EXECUTING:STOPPING',
    PlanExecutingStopFailed = 'PLAN:EXECUTING:STOP_FAILED',
    PlanExecutingStopped = 'PLAN:EXECUTING:STOPPED',
    PlanExecutingStarting = 'PLAN:EXECUTING:STARTING',
    PlanExecutingStartFailed = 'PLAN:EXECUTING:START_FAILED',
    PlanExecutingStarted = 'PLAN:EXECUTING:STARTED',
    PlanExecutingChargeInterrupted = 'PLAN:EXECUTING:CHARGE_INTERRUPTED',
    PlanExecutingOverridden = 'PLAN:EXECUTING:OVERRIDDEN',
    PlanEndedFinished = 'PLAN:ENDED:FINISHED',
    PlanEndedUnplugged = 'PLAN:ENDED:UNPLUGGED',
    PlanEndedFailed = 'PLAN:ENDED:FAILED',
    PlanEndedDisabled = 'PLAN:ENDED:DISABLED',
    PlanEndedDeadlineChanged = 'PLAN:ENDED:DEADLINE_CHANGED',
}

export interface ExternalStart {
    createdAt: Date;
    endedAt: Date;
    targetType: string;
    targetId: string;
    vendorActionId: string;
}

export interface Plan {
    id: string;
    chargingLocationId: string;
    vehicleId: string;
    userId: string;
    vendor: string;
    currency: string;
    nonSmartCost: number;
    smartCost: number;
    stopAt: Date;
    startAt: Date;
    estimatedFinishAt: Date;
    stopConfirmedAt: Date;
    startConfirmedAt: Date;
    endedAt: Date;
    finalState: string;
    failureCondition: string;
}

export interface SmartChargingState {
    consideration?: { [key: string]: boolean };
    externalStart: ExternalStart;
    plan?: Plan;
    state: SmartChargingStateEnum;
    user_id: string;
    vehicle_id: string;
}

export interface ChargingState {
    battery_capacity: number;
    battery_level: number;
    charge_limit: number;
    charge_rate: number;
    charge_time_remaining: number;
    is_charging: boolean;
    is_fully_charged: boolean;
    is_plugged_in: boolean;
    last_updated: string;
    range: number;
}
