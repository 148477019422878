import '@atoms/HvAccordion';
import '@atoms/icon/HvIcon';
import '@elements/tooltip/Tooltip';
import { LionAccordion } from '@lion/accordion';
import { customElement, html, property, TemplateResult } from '@lion/core';
import '@polymer/iron-icon';
import { currency, unit } from 'helpers/currency';
import { choose } from 'lit/directives/choose';
import { map } from 'lit/directives/map';
import { when } from 'lit/directives/when';

import { whitelabel } from '@weavelab/whitelabel';
import UsageHeaderStyle from './HvUsageHeaderStyle';

type TotalPerSetting = { [key: string]: number };

type Usage = {
    total_incl: number;
    total_excl: number;
    total_per_setting: TotalPerSetting;
};

type Usages = Usage[];

function roundToTwo(num: number): number {
    // @ts-ignore
    return +(Math.round(num + 'e+2') + 'e-2');
}

// getDetailed makes sure the array with IUsage object is correctly mapped to something we can work with in the HvUsageHeader
export const getDetailed = (usages: Usages): UsageDetail[] => {
    let totalPerSetting: TotalPerSetting = {};
    if (usages?.length > 0) {
        for (const u of usages) {
            const vat = roundToTwo(u.total_incl / u.total_excl);
            for (const [key, value] of Object.entries(u.total_per_setting)) {
                if (!totalPerSetting.hasOwnProperty(key)) {
                    totalPerSetting[key] = 0;
                }
                totalPerSetting[key] += Number(value) * vat;
            }
        }
    }

    let usageDetails: UsageDetail[] = [];
    for (const [key, value] of Object.entries(totalPerSetting)) {
        usageDetails.push({
            name: key,
            description: '',
            price: Number(value),
        });
    }

    return usageDetails.filter((d) => d.price !== 0);
};

const totalCostsKeyElec = 'Stroomkosten totaal';
const totalCostsKeyGas = 'Gaskosten totaal';
const totalCostsKeyProd = 'Teruglevering totaal';

// getDetailedFromMultiple makes a new detailed object for the costs total arrays
export const getDetailedFromMultiple = (
    elecCosts: Usages,
    gasCosts: Usages,
    prodCosts: Usages,
): UsageDetail[] => {
    let totalPerSetting: TotalPerSetting = {};

    if (elecCosts?.length > 0) {
        totalPerSetting[totalCostsKeyElec] = 0;
        for (const ec of elecCosts) {
            totalPerSetting[totalCostsKeyElec] += Number(ec.total_incl);
        }
    }

    if (gasCosts?.length > 0) {
        totalPerSetting[totalCostsKeyGas] = 0;
        for (const gc of gasCosts) {
            totalPerSetting[totalCostsKeyGas] += Number(gc.total_incl);
        }
    }

    if (prodCosts?.length > 0) {
        totalPerSetting[totalCostsKeyProd] = 0;
        for (const pc of prodCosts) {
            totalPerSetting[totalCostsKeyProd] -= Number(pc.total_incl);
        }
    }

    let usageDetails: UsageDetail[] = [];
    for (const [key, value] of Object.entries(totalPerSetting)) {
        usageDetails.push({
            name: key,
            description: '',
            price: Number(value),
        });
    }

    return usageDetails.filter((d) => d.price !== 0);
};

export type UsageDetail = {
    name: string;
    description: string;
    price: number;
    groupedDetails?: UsageDetail[] | null;
};

export enum UsageSymbol {
    'cost' = 'totaal',
    'electricity' = 'kWh',
    'gas' = 'm',
    'production' = 'kWh',
}

export enum UsageTitle {
    'cost' = 'Kostentotaal',
    'electricity' = 'Stroom',
    'gas' = 'Gas',
    'production' = 'Teruggeleverd',
}

interface StringArray {
    [key: string]: string[];
}

interface costDesciption {
    mapWithCosts: StringArray;
    mapWithDescriptions: { [key: string]: string };
}

@customElement('hv-usage-header')
@whitelabel({ name: 'hv-usage-header' })
export class HvUsageHeader extends LionAccordion {
    @whitelabel()
    public static elecCostSettingsMap: StringArray = {
        Stroomkosten: ['Dagprijs EPEX'],
        'Variabele kosten': ['Energiebelasting', 'Inkoopkosten', 'ODE'],
        'Vaste kosten': [
            'Netbeheerkosten stroom',
            'Vaste leveringskosten',
            'Vermindering Energiebelasting',
        ],
    };

    @whitelabel()
    public static elecDescriptionsMap: { [key: string]: string } = {
        Stroomkosten:
            'De inkoopprijs van het stroomverbruik. Incl. btw, maar excl. belastingen en inkoopkosten.',
        'Variabele kosten': 'Dit zijn de verbruiksafhankelijke kosten per kWh.',
        'Vaste kosten':
            'Dit zijn kosten die iedere maand hetzelfde zijn, maar zijn verdeeld over alle dagen van de maand.',
    };

    @whitelabel()
    public static gasCostSettingsMap: StringArray = {
        Gaskosten: ['Dagprijs LEBA', 'Dagprijs TTF', 'Gastarief'],
        'Variabele kosten': ['Energiebelasting', 'Inkoopkosten', 'ODE'],
        'Vaste kosten': ['Netbeheerkosten gas', 'Vaste leveringskosten'],
    };

    @whitelabel()
    public static gasDescriptionsMap: { [key: string]: string } = {
        Gaskosten:
            'De inkoopprijs van het gasverbruik. Incl. btw, maar excl. belastingen en inkoopkosten.',
        'Variabele kosten': 'Dit zijn de verbruiksafhankelijke kosten per m3.',
        'Vaste kosten':
            'Dit zijn kosten die iedere maand hetzelfde zijn, maar zijn verdeeld over alle dagen van de maand.',
    };

    @property({ type: Number })
    public usage: number = 0;

    @property({ type: Boolean })
    public disableDetails: boolean = false;

    @property({ type: Number })
    public totalPrice: number = 0;

    @property({ type: String, reflect: true })
    public usageType?: keyof typeof UsageSymbol;

    // Fallback for safely usage of the usage type
    private get safeUsageType(): keyof typeof UsageSymbol {
        if (this.usageType !== undefined) {
            return this.usageType;
        }

        console.warn('Unconfigured usageType in usage-header');
        return 'electricity';
    }

    @property({ type: Array })
    public details: UsageDetail[] = [];

    public static override get styles() {
        return [UsageHeaderStyle];
    }

    private getMapCostAndDescriptionsByUsageType = (
        usageType: keyof typeof UsageSymbol,
    ): costDesciption => {
        if (usageType === 'gas') {
            return {
                mapWithCosts: HvUsageHeader.gasCostSettingsMap,
                mapWithDescriptions: HvUsageHeader.gasDescriptionsMap,
            };
        }

        return {
            mapWithCosts: HvUsageHeader.elecCostSettingsMap,
            mapWithDescriptions: HvUsageHeader.elecDescriptionsMap,
        };
    };

    public render = (): any =>
        html`<hv-accordion>
            <div slot="invoker">
                <header>
                    <!-- icon -->
                    <div id="usage-icon">
                        ${this.renderHeaderIcon(this.safeUsageType)}
                    </div>
                    <!-- desciption usage titel and amount -->
                    <div id="description">
                        <div id="information">
                            <span class="information-title">
                                ${UsageTitle[this.safeUsageType]}:
                            </span>
                            ${when(
                                this.safeUsageType !== 'cost',
                                () => html`
                                    ${unit(this.usage)}
                                    ${this.renderUsageType(this.safeUsageType)}
                                `,
                            )}
                        </div>
                        ${when(
                            this.safeUsageType !== 'cost',
                            () =>
                                html`<span id="price"
                                    >${currency(this.totalPrice)}</span
                                >`,
                            () =>
                                html`<span id="price"
                                    >${currency(this.usage)}</span
                                >`,
                        )}
                    </div>
                    <!-- icon idicator for the accoridion -->
                    ${when(
                        this.usageType !== 'production' && !this.disableDetails,
                        () => html`
                            ${when(
                                this.details?.length,
                                () => html`<hv-icon
                                    class="arrow"
                                    name="arrow-down"
                                    family=""
                                ></hv-icon>`,
                            )}
                        `,
                    )}
                </header>
            </div>
            <div slot="content">
                <div id="content-container">
                    ${when(
                        this.usageType !== 'production' && !this.disableDetails,
                        () => html`
                            ${this.renderDetails(
                                this.details,
                                this.safeUsageType,
                            )}
                        `,
                    )}
                </div>
            </div>
        </hv-accordion>`;

    // mapDetails adds the given usage details like price and name to the cost settings
    private mapDetails = (
        details: UsageDetail[],
        usageType: keyof typeof UsageSymbol,
    ): UsageDetail[] => {
        if (!details?.length) {
            return details;
        }
        // replace and remove the cost details name for readability
        for (const detail of details) {
            detail.name = detail.name
                .replace(' per kWh', '')
                .replace(' per m³', '')
                .replace(
                    'Vaste leveringskosten stroom',
                    'Vaste leveringskosten',
                )
                .replace('Vaste leveringkosten stroom', 'Vaste leveringkosten')
                .replace('Vaste leveringskosten gas', 'Vaste leveringskosten')
                .replace('Vaste leveringkosten gas', 'Vaste leveringskosten');
        }

        const newDetailsMap: UsageDetail[] = [];
        const hits = new Set();
        // get the map with the cost settings and descriptions for each usage type
        const { mapWithCosts, mapWithDescriptions } =
            this.getMapCostAndDescriptionsByUsageType(usageType);
        if (!mapWithCosts || this.usageType === 'cost') {
            return details;
        }

        const currentDetails = [...details];
        // loop over all cost groups check if regiotoeslag is present and add to the inkoopskosten
        currentDetails.forEach((value, key) => {
            if (value.name.toLowerCase() === 'regiotoeslag') {
                const inkoopDetailIndex = currentDetails.findIndex(
                    (detail) => detail.name.toLowerCase() === 'inkoopkosten',
                );
                // add the regiotoeslag price to the inkoopkosten price
                if (inkoopDetailIndex !== -1) {
                    currentDetails[inkoopDetailIndex].price += value.price;
                    currentDetails.splice(key, 1);
                } else {
                    // fallback to not show inkoopkosten
                    value.name = 'Overige';
                }
            }
        });

        // loop over all cost groups and add the details to the specific cost group
        for (const entry in mapWithCosts) {
            const newDetailsEntry: UsageDetail = {
                name: entry,
                description: '',
                price: 0,
            };
            // add the group details to the entry (group) and sum the total group price
            for (const subEntry of mapWithCosts[entry]) {
                const foundIndex = currentDetails.findIndex(
                    (i) =>
                        i.name.toLocaleLowerCase() === subEntry.toLowerCase(),
                );
                if (foundIndex !== -1) {
                    const foundDetail = currentDetails[foundIndex];
                    currentDetails.splice(foundIndex, 1);
                    hits.add(foundDetail.name);
                    newDetailsEntry.price += foundDetail.price;
                    /**
                     * if the detail is `Dagprijs` we want to add the detail price
                     * We do not want to add the grouped detail so that it isn't visible in the overview
                     */
                    if (foundDetail.name.includes('Dagprijs')) {
                        continue;
                    }
                    // Add the details to the entry (group)
                    if (!newDetailsEntry.groupedDetails) {
                        newDetailsEntry.groupedDetails = [];
                    }
                    newDetailsEntry.groupedDetails?.push({
                        name: foundDetail.name,
                        price: foundDetail.price,
                        description: '',
                    });
                }
            }
            newDetailsMap.push(newDetailsEntry);
        }

        // when details are missing in the cost settings add them to the other group
        if (currentDetails?.length > 0) {
            console.warn(
                'cost specific details are missing in cost settings',
                currentDetails,
            );
            console.table(currentDetails);
            const detailsGroups: UsageDetail[] = [];
            let totalOtherCosts = 0;
            currentDetails.forEach((detail) => {
                detailsGroups.push({
                    name: detail.name,
                    description: '',
                    price: detail.price,
                });
                totalOtherCosts += detail.price;
            });
            newDetailsMap.push({
                name: 'Overig',
                description: '',
                price: totalOtherCosts,
                groupedDetails: detailsGroups,
            });
        }

        // Check if grouped description exist and add the description to the grouped details
        for (const description in mapWithDescriptions) {
            const foundDetail = newDetailsMap.find(
                (i) => i.name === description,
            );
            if (foundDetail) {
                foundDetail.description = mapWithDescriptions[description];
            }
        }

        return newDetailsMap;
    };

    // renderDetails renders the details of a costs object, this mostly means the cost settings or mapped cost settings
    private renderDetails = (
        details: UsageDetail[],
        usageType: keyof typeof UsageSymbol,
    ): TemplateResult => {
        details = this.mapDetails(details, usageType);
        return html`
            ${map(
                details,
                (detail) => html`
                    <div class="cost-specification">
                        <div class="header">
                            <span class="cost-specification-description">
                                ${detail.name}
                                ${when(
                                    detail.description !== null &&
                                        detail.description !== '',
                                    () => html`
                                        <tool-tip value="${detail.description}">
                                        </tool-tip>
                                    `,
                                )}
                            </span>
                            <span>${currency(detail.price)}</span>
                        </div>
                        ${when(
                            detail.groupedDetails &&
                                detail.groupedDetails.length > 0,
                            () => html`
                                <div class="body">
                                    ${map(
                                        detail.groupedDetails!,
                                        (i) => html`
                                            <div class="entry">
                                                <span class="name"
                                                    >${i.name}</span
                                                >
                                                <span class="price"
                                                    >${currency(i.price)}</span
                                                >
                                            </div>
                                        `,
                                    )}
                                </div>
                            `,
                        )}
                    </div>
                `,
            )}
        `;
    };

    private renderHeaderIcon = (usageType: keyof typeof UsageSymbol) =>
        html`
            ${choose(
                usageType,
                [
                    [
                        'cost',
                        () => html`<iron-icon icon="ez:coins"></iron-icon>`,
                    ],
                    [
                        'electricity',
                        () => html`<iron-icon icon="ez:stroom"></iron-icon>`,
                    ],
                    ['gas', () => html`<iron-icon icon="ez:gas"></iron-icon>`],
                    [
                        'production',
                        () =>
                            html`<iron-icon icon="ez:production"></iron-icon>`,
                    ],
                ],
                () => {
                    console.warn(
                        `missing the icon for the given usage type: ${usageType}`,
                    );
                    return html``;
                },
            )}
        `;

    private renderUsageType = (
        usage: keyof typeof UsageSymbol,
    ): TemplateResult => {
        const usageTypeDescription = UsageSymbol[usage];
        return html` ${when(
            usage === 'gas',
            () => html`${usageTypeDescription}<sup>3</sup>`,
            () => html`${usageTypeDescription}`,
        )}`;
    };
}

declare global {
    interface HTMLElementTagNameMap {
        'hv-usage-header': HvUsageHeader;
    }
}
