import { css } from 'lit-element';

export default css`
    :host {
        display: none; /* Hidden by default */
        position: fixed; /* Stay in place */
        z-index: 1; /* Sit on top */
        left: 0;
        top: 0;
        width: 100%; /* Full width */
        height: 100%; /* Full height */
        max-height: 100vh;
        overflow: hidden;
        background-color: rgb(0, 0, 0); /* Fallback color */
        background-color: rgba(30, 33, 41, 0.85);
        font-family: inherit;

        --modal--font-familiy: inherit;
        --modal--border-color: rgba(0, 0, 0, 0.05);
        --modal__header--border-bottom: 1px solid var(--modal--border-color);
        --modal__min--width: auto;
        --modal__max--width: auto;
        --modal--width: 550px;
        --modal__main--margin: 48px 40px;
        --modal__header--margin: 0;
        --modal__header--padding: 10px 0;
        --modal__header--font-familiy: var(--modal--font-familiy);
        --modal__header--font-weight: 700;
        --modal__header--font-size: 24px;
        --modal__header--min-height: 50px;
        --modal__header--text-align: center;
        --modal__header--color: #000;
        --modal__header__sub-title--font-size: 16px;
        --modal__header__sub-title--margin: 4px 0 0 0;
        --modal__header__sub-title--color: #000;
        --modal__text-color: #000;
        --modal__text--font-weight: 300;
        --modal__close--font-size: 35px;
        --modal__close--text-color: #000;
        --modal__close--margin-top: 0;
        --modal-max-width: 90vw;

        --button-component--padding__left: 10px;
        --button-component--padding: 12px;
        --button-component--width: 100%;
        --button-component--background: var(--primary-color);
        --button-component--border: 1px solid var(--primary-color);
        --button-component--border-radius: 4px;
        --button-component__secondary--color: var(--tertiary-color);
        --button-component__secondary--border: 1px solid
            var(--button-border-color);
        --button-component--font-size: 18px;
        --button-component--font-family: inherit;
        --button-component--flex-grow: 1;
        --button-component--inner-margin__left: 15px;
        --button-component--inner-padding__left: 12px;
        --button-component--line-height: 1.78;
        --button-component--margin: 0 auto;

        font-family: var(--modal--font-familiy);
    }

    :host([show-trash-icon]) .close {
        border: 1px solid #646f79;
        border-radius: 2px;
        width: 32px;
        height: 32px;
        margin: -27px 27px 0 0;
    }

    .hidden {
        display: none !important;
    }

    /* Modal Content/Box */
    .modal-content {
        position: relative;
        border-radius: 4px;
        background-color: var(--app-white);
        min-width: var(--modal__min--width);
        width: var(--modal--width);
        max-width: var(--modal-max-width);
        margin: auto; /* 15% from the top and centered */
        z-index: 150;
        max-height: 100vh;
        overflow: auto;
    }

    /* The Close Button */
    .close {
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: var(--modal__close--margin-right);
        margin-top: var(--modal__close--margin-top);
        color: var(--modal__close--text-color);
        font-size: var(--modal__close--font-size);
        position: absolute;
        right: 0;
        width: 50px;
        height: 50px;
    }

    .close:hover,
    .close:focus {
        color: black;
        text-decoration: none;
        cursor: pointer;
    }

    h1 {
        font-family: var(--modal__header--font-familiy);
        font-size: var(--modal__header--font-size);
        font-weight: var(--modal__header--font-weight);
        font-stretch: normal;
        font-style: normal;
        line-height: 1.4;
        letter-spacing: -0.1px;
        text-align: var(--modal__header--text-align);
        color: var(--modal__header__sub-title--color);
        margin: 0;
    }

    header {
        min-height: var(--modal__header--min-height);
        border-bottom: var(--modal__header--border-bottom);
        margin: var(--modal__header--margin);
        padding: var(--modal__header--padding);
        display: flex;
        justify-content: center;
        flex-direction: column;
        position: relative;
    }

    main {
        margin: var(--modal__main--margin);
    }

    main button-component {
        margin: 10px 0;
    }

    #overlay {
        position: fixed;
        left: 0;
        top: 0;
        width: 100vw;
        height: 100vh;
        z-index: 100;
    }

    header > div {
        display: flex;
        flex-direction: column;
    }

    header p {
        margin: var(--modal__header__sub-title--margin);
        font-size: var(--modal__header__sub-title--font-size);
        color: var(--modal__header--color);
    }

    .modal-text {
        color: var(modal__text-color);
        font-weight: var(--modal__text--font-weight);
    }

    .fa-trash-alt {
        width: 12px;
        height: 14px;
        color: #646f79;
    }

    /* button-component {
        --button-component--padding: var(--button-component--padding);
        --button-component--font-size: var(--button-component--font-size);
        --button-component--line-height: var(--button-component--line-height);
        --button-component--border-radius: var(
            --button-component--border-radius
        );
        --button-component--width: var(--button-component--width);
        --button-component--margin: var(--button-component--margin);
    } */
`;
