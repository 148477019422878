import {
    customElement,
    LitElement,
    TemplateResult,
    html,
    css,
    CSSResult,
    property,
} from 'lit-element';

import { styleMap } from 'lit-html/directives/style-map';

@customElement('hv-progress-bar')
export class ProgressBar extends LitElement {
    @property({ type: Number })
    public currentStep?: number;

    @property({ type: Number })
    public totalSteps?: number;

    @property({ type: Boolean })
    public hidden: boolean = false;

    public static get styles(): CSSResult {
        return css`
            :host {
                --hv-progress-bar-height;
                --hv-progress-bar-background-color;
                --hv-progress-bar-indicator-color;
                display: block;
                height: var(--hv-progress-bar-height, 5px);
                width: 100%; 
            }

            :host([hidden]) {
                display: none;
            }

            .progress-bar-wrapper {
                height: 100%;
                width: 100%;
                background: var(--hv-progress-bar-background-color, gray);
            }

            .progress-bar-indicator {
                height: 100%;
                background: var(--hv-progress-bar-indicator-color, black);
            }
        `;
    }

    private calculateIndicatorWidth = (step?: number, totalSteps?: number) => {
        if (step && totalSteps) {
            return `${(step / totalSteps) * 100}%`;
        }
        return '0%';
    };

    public render = (): TemplateResult => {
        const indicatorStyles = {
            width: this.calculateIndicatorWidth(
                this.currentStep,
                this.totalSteps,
            ),
        };
        return html`<div class="progress-bar-wrapper">
            <div
                class="progress-bar-indicator"
                style=${styleMap(indicatorStyles)}
            ></div>
        </div>`;
    };
}

declare global {
    interface HTMLElementTagNameMap {
        'hv-progress-bar': ProgressBar;
    }
}
