import { css } from 'lit-element';

export default css`
    :host {
        --page-padding: 15px;
        --page-content-padding: 44px;
        --between-content-spacing: 15px;
        --page-description-padding: 14px 28px;

        --content-font-size: 14px;
        --header-font-size: 24px;

        --content-text-color: #222222;
        --header-text-color: #263e56;

        display: flex;
        padding: var(--page-padding);
        box-sizing: border-box;
        height: 100%;
        width: 100%;
    }

    .title {
        font-size: var(--header-font-size);
        color: var(--header-text-color);
        margin-top: 0;
        margin-left: var(--page-content-padding);
        margin-right: var(--page-content-padding);
    }

    small {
        font-size: var(--content-font-size);
        opacity: 0.75;
    }

    .button-wrapper {
        width: 100%;
    }

    .button-wrapper button {
        width: 100%;
        padding-left: var(--page-content-padding);
        padding-right: var(--page-content-padding);
    }

    .button-wrapper button + button {
        margin-top: var(--between-content-spacing);
    }

    a {
        font-size: inherit;
        color: var(--a-font-color);
    }

    .description {
        padding: var(--page-description-padding);
        padding-left: var(--page-content-padding);
        padding-right: var(--page-content-padding);
        display: flex;
        align-items: center;
        margin-bottom: var(--between-content-spacing);
    }

    .description .icon {
        padding-right: var(--between-content-spacing);
    }

    .loaded-at {
        color: gray;
    }

    .button-wrapper,
    charging-battery {
        display: block;
        max-width: 25rem;
    }

    button {
        border: none;
        width: calc(100% - calc(var(--page-content-padding) * 2));
    }

    .title,
    .explanation {
        margin-left: var(--page-content-padding);
        margin-right: var(--page-content-padding);
    }
`;
