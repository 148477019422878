import { css } from 'lit-element';

export default css`
    :host {
        --pagination--border--color: 1px solid #ddd;
        --pagination--font--size: 14px;
        --pagination--font--weight: 400;
        --pagination__ez-button--background-color: var(
            --lumo-primary-color-10pct
        );
        --pagination--color: var(--primary-text-color);

        display: flex;
        justify-content: center;
        align-items: center;
        font-size: var(--pagination--font--size);
    }
    :host([hidden]) {
        display: none;
        height: 0;
    }
    div.paginator-page-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
    ez-button-component {
        --ez-button--border-radius: 0;
        --ez-button--color: var(--pagination--color);
        --ez-button--background-color: var(
            --pagination__ez-button--background-color
        );
        --ez-button--font-weight: var(--pagination--font--weight);
        --ez-button--font-family: var(--primary-font-family);
        --ez-button--font-size: var(--pagination--font--size);
        --ez-button--border: var(--pagination--border--color);

        position: relative;
    }
    .info {
        display: flex;
        flex-grow: 1;
        white-space: nowrap;
        padding: 0 24px 0 4px;
        user-select: none;
    }
    .pages {
        display: flex;
        flex: 1 1 0;
        padding: 0 4px;
        min-width: 18px;
    }

    .pages[disabled] {
        color: var(--primary-color);
        opacity: 1;
        font-weight: 900;
    }

    ez-button-component.navigate-before {
        padding: 0 24px 0 4px;
        cursor: pointer;
    }
    ez-button-component.navigate-next {
        padding: 0 4px 0 24px;
        cursor: pointer;
    }
`;
