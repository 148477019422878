import { Usages } from './../../../async-reducers/usages';
import enums from 'enums';
import { Router } from '@vaadin/router';
import { routePaths } from 'data-access/router';
import { Snapshot } from 'types/snapshot';
import { ContactType, Contract, ValueOf } from '../Contract';

export class ContractBase implements Contract {
    public contractType: ContactType = 'UTILITIES';
    public currentPhase: number = enums.SnapshotPhaseDeliveryStarted;
    public maxWaitingDays: number = 10;
    public page: ValueOf<typeof routePaths> = routePaths.dashboard;
    snapshot!: Snapshot;

    // public functions
    public IsActive(): boolean {
        return this.snapshot.snapshot_phase === this.currentPhase;
    }

    public RouteToPage(): void {
        Router.go(this.page);
    }

    public checkIfDataIsAlreadyFetched = (): {
        fetched: boolean;
        usages: Usages;
    } => {
        const usages: Usages = window.store.getState().getUsages;
        return { fetched: usages ? true : false, usages };
    };
}
