import {
    LitElement,
    TemplateResult,
    customElement,
    html,
    property,
} from 'lit-element';
import '@lion/radio-group';
import '@molecules/HvRadio';
import '@molecules/HvRadioGroup';
import { hvRadioOptions } from 'types/hvRadio';

export interface radioValuechangedEvent extends Event {
    detail: {
        value: string;
    };
}

@customElement('hv-radio-component')
export class HvRadioComponent extends LitElement {
    @property({ type: Object })
    options: hvRadioOptions[] = [];

    @property({ type: String })
    name: string = '';

    @property({ type: String })
    current?: string;

    changed = (e: CustomEvent) => {
        if (this.current === (e.target as HTMLInputElement).value) {
            this.current = undefined;
        } else {
            this.current = (e.target as HTMLInputElement).value;
        }
        this.dispatchEvent(
            new CustomEvent('value', {
                bubbles: true,
                composed: true,
                detail: { value: this.current },
            }),
        );
    };

    public render = (): TemplateResult => {
        return html`
            <hv-radio-group name="${this.name}">
                ${this.options.map((option) => {
                    return html`<hv-radio
                        label="${option.label}"
                        ?recommend=${option.recommend}
                        .choiceValue=${option.value}
                        @user-input-changed=${this.changed}
                    ></hv-radio>`;
                })}
            </hv-radio-group>
        `;
    };
}

declare global {
    interface HTMLElementTagNameMap {
        'hv-radio-component': HvRadioComponent;
    }
}
