import { css } from '@lion/core';

export default css`
    :host {
        --margin: 30px 0px 60px;
        --question-margin: 0 0 10px 0;
        --awnser-margin: -10px 0 10px 0;
        --question-hover-color: #0096da;
        --border: solid 2px #d8e9f3;
        --question-spacing: 1rem;
        --arrow-icon-size: 32px;

        margin: var(--margin);
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }

    /**
     * header
     */
    h3 {
        margin: var(--question-margin);
        cursor: pointer;
    }
    h3:focus,
    h3:hover {
        color: var(--question-hover-color);
    }
    h3::before {
        content: '';
        display: block;
        margin-bottom: var(--question-spacing);
        border-bottom: var(--border);
    }
    h3:last-of-type::after,
    p:last-of-type::after {
        content: '';
        display: block;
        margin-top: var(--question-spacing);
        border-top: var(--border);
    }
    /* border fixses */
    h3[expanded='true']:last-of-type::after {
        border: none !important;
        margin-top: inherit;
    }

    h3 span {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    /* on focus set color */
    span[focused] {
        color: var(--question-hover-color);
    }
    span[focused] > hv-icon {
        --hv-icon-color: var(--question-hover-color);
    }
    /* fallback when focused = true but accordion aria is collapsed */
    span[aria-expanded='false'] {
        color: inherit !important;
    }
    span[aria-expanded='false'] > hv-icon {
        --hv-icon-color: inherit !important;
    }
    span[aria-expanded='true'] > hv-icon {
        transform: rotate(180deg);
    }
    span > div {
        display: inherit;
    }

    hv-icon {
        --hv-icon-font-size: var(--arrow-icon-size);
        transition: transform 300ms ease-in-out;
    }

    /**
     * content 
     */
    p {
        margin: var(--awnser-margin);
    }
`;
