import {
    customElement,
    LitElement,
    TemplateResult,
    html,
    CSSResult,
} from 'lit-element';
import { whitelabel } from '@weavelab/whitelabel';
import { connect, watch } from 'lit-redux-watch';
import { Snapshot } from 'types/snapshot';
import { Router } from '@vaadin/router';
import { routePaths } from 'helpers/routing';
import style from './settingsEditStyle';

const goTo = (type: string) => (): void => {
    Router.go(type);
};

@customElement('settings-edit')
@whitelabel({ name: 'user-settings-edit' })
export class SettingsEdit extends connect(window.store)(LitElement) {
    @watch('jwtSnapshots.selectedSnapshot')
    protected snapshot?: Snapshot;

    @watch('user.password_changed_at')
    protected password_changed_at?: string;

    @whitelabel()
    public static get styles(): CSSResult[] {
        return [style];
    }

    connectedCallback(): void {
        super.connectedCallback();
    }

    public render = (): TemplateResult => {
        const {
            editContactDetailsSettings,
            editAddressSettings,
            editPasswordSettings,
            editPaymentMethodSettings,
        } = routePaths;
        return html`
            <div class="content-wrapper">
                <div>
                    <h1>Wat wil je wijzigen?</h1>
                    <div class="settings-selector">
                        <div class="menu-item">
                            <a @click="${goTo(editContactDetailsSettings)}"
                                >Contactgegevens</a
                            >
                        </div>
                        ${this.snapshot &&
                        html`<div class="menu-item">
                            <a @click="${goTo(editAddressSettings)}"
                                >Factuuradres</a
                            >
                        </div>`}
                        <div class="menu-item">
                            <a @click="${goTo(editPaymentMethodSettings)}"
                                >Betaalmethode</a
                            >
                        </div>
                        ${this.password_changed_at?.length
                            ? html`<div class="menu-item">
                                  <a @click="${goTo(editPasswordSettings)}"
                                      >Wachtwoord</a
                                  >
                              </div>`
                            : ''}
                    </div>
                </div>
            </div>
        `;
    };
}

declare global {
    interface HTMLElementTagNameMap {
        'settings-edit': SettingsEdit;
    }
}
