import { css } from 'lit-element';

export default css`
    :host {
        --hv-radio-label-font-size: 13px;
        --hv-radio-label-icon-size: 17px;
        --hv-radio-label-icon-margin-right: 5px;
        --hv-radio-button-margin-left: 15px;
        --hv-radio-label-width: 100%;
        --hv-radio-label-recommend-margin-top: 2px;
        --hv-radio-label-recommend-font-weight: 600;
        --hv-radio-label-recommend-color: #3aad3a;

        font-size: var(--hv-radio-label-font-size);
    }
    div {
        margin-left: var(--hv-radio-button-margin-left);
        width: var(--hv-radio-label-width);
        display: flex;
        flex-direction: column;
    }
    span {
        margin-top: var(--hv-radio-label-recommend-margin-top);
        display: flex;
        align-items: center;
        font-weight: var(--hv-radio-label-recommend-font-weight);
        color: var(--hv-radio-label-recommend-color);
    }
    hv-icon {
        --hv-icon-font-size: var(--hv-radio-label-icon-size);
        --hv-icon-color: var(--hv-radio-label-recommend-color);
        margin-right: var(--hv-radio-label-icon-margin-right);
    }
`;
