import { ReduxLazy } from '@weavedev/redux-lazy';
import { CalculatorEventType } from 'gtm-middleware/calculator';
import { CustomerFormEventType } from 'gtm-middleware/customerForm';
import { OrderOverviewEventType } from 'gtm-middleware/orderOverview';
import { ProductListEventType } from 'gtm-middleware/productList';

// Actions
const UI_PRICE_DETAILS_CLICKED = 'UI_PRICE_DETAILS_CLICKED';
const UI_AUTOMATIC_INCASSO_CLICKED = 'UI_AUTOMATIC_INCASSO_CLICKED';

const UI_CALCULATOR_INTERACTION = 'UI_CALCULATOR_INTERACTION';
export const uiCalculatorInteraction = new ReduxLazy(
    UI_CALCULATOR_INTERACTION,
    {} as CalculatorEventType,
);

const UI_PRODUCT_LIST_INTERACTION = 'UI_PRODUCT_LIST_INTERACTION';
export const uiProductListInteraction = new ReduxLazy(
    UI_PRODUCT_LIST_INTERACTION,
    {} as ProductListEventType,
);

const UI_CUSTOMER_FORM_INTERACTION = 'UI_CUSTOMER_FORM_INTERACTION';
export const uiCustomerFormInteraction = new ReduxLazy(
    UI_CUSTOMER_FORM_INTERACTION,
    {} as CustomerFormEventType,
);

const UI_ORDER_OVERVIEW_INTERACTION = 'UI_ORDER_OVERVIEW_INTERACTION';
export const uiOrderOverviewInteraction = new ReduxLazy(
    UI_ORDER_OVERVIEW_INTERACTION,
    {} as OrderOverviewEventType,
);

export const uiPriceDetailsClicked = new ReduxLazy(
    UI_PRICE_DETAILS_CLICKED,
    null,
);

export const uiAutomaticIncassoClicked = new ReduxLazy(
    UI_AUTOMATIC_INCASSO_CLICKED,
    null,
);
