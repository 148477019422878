import { Validator } from '@lion/form-core';

export class ContainsLower extends Validator {
    static get validatorName() {
        return 'ContainsLower';
    }

    // eslint-disable-next-line class-methods-use-this
    execute(value: any) {
        return typeof value !== 'string' || !/[a-z]/.test(value);
    }
}
