import { Resource } from './helpers/Resource';
import { Bearer } from './helpers/types/Bearer';
import { UUID } from './helpers/types/UUID';

/**
 * Store member management object for usage
 */
export class DownloadDocument extends Resource {
    /**
     * Action string
     * @return {string}
     */
    static get action() {
        return 'download document';
    }

    /**
     * Description string
     * @return {string}
     */
    static get description() {
        return 'Download a document with type and id';
    }

    /**
     * Request method
     * @return {string}
     */
    static get method() {
        return 'get';
    }

    /**
     * Resource string
     * @return {string}
     */
    static get resource() {
        return 'User';
    }

    /**
     * Route string
     * @return {string}
     */
    static get route() {
        return '/user/{user_id}/document/{document_id}';
    }

    /**
     * Parameters object
     * @return {Object}
     */
    static get parameters() {
        return {
            user_id: {
                in: 'path',
                required: true,
                type: UUID,
            },
            document_id: {
                in: 'path',
                required: true,
                type: UUID,
            },
            type: {
                in: 'query',
                required: false,
                type: Number,
            },
            file: {
                in: 'query',
                required: false,
                type: Number,
            },
            'x-auth': {
                in: 'header',
                required: true,
                type: Bearer,
            },
        };
    }
}
