import {
    ChartTooltipItem,
    ChartDataSets,
    CommonAxe,
    ChartLegendOptions,
    ChartLegendLabelOptions,
} from 'chart.js';

import enums from 'enums';
import { convertDateTo } from 'helpers/dates';
import { unit } from 'helpers/currency';
import { usageSuffix } from './usageHelpers';
import {
    MonthlyCostReports,
    costReport as CostReport,
} from '@async-reducers/helpers/costReports';
const defaultFontSize = 14;
const defaultFontWeight = '300';
const defaultFontFamily = 'EzFont';
const maxStringLength = 3;

export interface ChartDataExtended {
    labels?: (string | string[] | number | number[] | Date | Date[])[];
    datasets?: ChartDataSets[];
    costs: number[];
}

// all available usage chart types
export type usageChartTypes =
    | 'usageChartDaily'
    | 'usageChartWeekly'
    | 'usageChartMonthly'
    | 'usageChartYeary';

// all available usage chart types in enum
export enum usageChartTypesEnum {
    'usageChartDaily' = 'usageChartDaily',
    'usageChartWeekly' = 'usageChartWeekly',
    'usageChartMonthly' = 'usageChartMonthly',
    'usageChartYearly' = 'usageChartYearly',
}

export const defaultUsageChartType = usageChartTypesEnum.usageChartMonthly;

// date helper functions
// get monday before date
const monday = (date: Date): Date => {
    let mon = new Date(date);
    while (mon.getDay() !== 1) {
        mon = new Date(mon.getTime() - 1000 * 60 * 60 * 24);
    }
    return mon;
};
// get weekDate with offset from monday date
const weekDate = (day: Date, offset: number): Date => {
    return new Date(day.getTime() + offset * 1000 * 60 * 60 * 24);
};

export const chartJSGetLabel = (
    item: ChartTooltipItem,
    data: ChartDataExtended,
    usageType: number | null = null,
    hasBusiness: boolean,
): any => {
    let index = item.datasetIndex;
    // Specific fix for energy production
    const dataSet = data.datasets;
    if (dataSet == null || index == null || item == null) {
        console.warn(
            'chart dataSet, item or index is missing and required',
            dataSet,
            index,
        );
        return;
    }

    const chartDatasets = getDataSets(dataSet, index, hasBusiness);
    if (chartDatasets == null) {
        return;
    }

    const itemIndex = item.index;
    if (itemIndex == null) {
        console.warn('chart item index doesn not exist');
        return;
    }
    if (chartDatasets.length === 1 && index > 0) {
        index = 0;
    }
    const definedData = chartDatasets[index].data![itemIndex];
    const suffix = usageTypeToSuffix(usageType);
    const label = floatToReadable(definedData, suffix);

    if (suffix === '') {
        return `€ ${label}`;
    }
    return label;
};

const getDataSets = (
    dataSet: ChartDataSets[],
    index: number,
    hasBusiness: boolean,
): ChartDataSets[] | undefined => {
    const dataSets = [];

    // return dataset for splitBusiness
    if (hasBusiness) {
        for (let i = 0; i < dataSet.length; i++) {
            const data = getDataFromDataset(dataSet, i);
            if (data == null) {
                return;
            }
            dataSets.push(data);
        }
        return dataSets;
    }
    const dataset = getDataFromDataset(dataSet, index);
    if (dataset == null) {
        return;
    }

    return [dataset];
};

const getDataFromDataset = (
    dataSet: ChartDataSets[],
    index: number,
): ChartDataSets | undefined => {
    const chartDataset = dataSet[index];
    if (chartDataset == null) {
        const data = dataSet[0];
        if (data == null) {
            console.warn('chart data from the dataset is empty');
            return;
        }
    }
    if (chartDataset.data == null) {
        console.warn('chart data property within the chart dataset is missing');
        return;
    }

    return chartDataset;
};

export const chartJSGetTitle = (
    item: ChartTooltipItem[],
    selected: usageChartTypes,
    currentDate: Date,
): any => {
    if (selected == null || (selected && item.length === 0)) {
        return '';
    }
    if (item[0]?.index == null) {
        console.warn('tooltip index is missing and required');
        return;
    }
    switch (getIntervalType(selected)) {
        case 'daily':
            const hours =
                item[0].index < 10 ? `0${item[0].index}` : item[0].index;
            return `${hours}:00-${hours}:59`;
        case 'weekly': {
            const localWeekDate = weekDate(monday(currentDate), item[0].index);
            return `${convertDateTo(localWeekDate, {
                weekday: 'short',
                day: '2-digit',
                month: 'short',
            })}`;
        }
        case 'monthly':
            currentDate = new Date(currentDate.setDate(item[0].index + 1));
            return `${convertDateTo(currentDate, {
                day: '2-digit',
                month: 'long',
            })}`;
        case 'yearly':
            return `${item[0].label} ${convertDateTo(currentDate, {
                year: 'numeric',
            })}`;
        default:
            console.warn('Unknown interval', getIntervalType(selected));
    }
};

// Converts chartName to interval string
export const getIntervalType = (chartName: string): string => {
    return chartName.substring(10, chartName.length).toLowerCase();
};

// Convert float to human readable value
const floatToReadable = (
    value: number | number[] | Chart.ChartPoint | null | undefined | string,
    suffix?: string,
): string => {
    if (value == null) {
        console.warn('Label number value may not be null');
        return '';
    }
    if (typeof value === 'string') {
        value = unit(parseFloat(value));
    }

    return `${value} ${suffix || ''}`;
};

// Convert usageType to string value
const usageTypeToSuffix = (usageType: number | null): string => {
    switch (usageType) {
        case enums.UsageTypeElekProd:
            return 'kWh';
        case enums.UsageTypeElekUsage:
            return 'kWh';
        case enums.UsageTypeGasUsage:
            return 'm³';
        default:
            return '';
    }
};

// getYAxes returns a CommonAxe y axes for chartjs
export const getYAxes = (
    hasBusiness: boolean,
    usageType: number | null,
    graphLabelScaleYColor: string,
    graphLabelTickFontColor: string,
    graphGridColor: string,
): CommonAxe[] => {
    const axes: CommonAxe[] = [
        {
            scaleLabel: {
                display: true,
                labelString: getLabelString(usageType),
                fontColor: graphLabelScaleYColor,
                fontFamily: defaultFontFamily,
                fontSize: defaultFontSize,
                fontStyle: defaultFontWeight,
            },
            ticks: {
                beginAtZero: true,
                suggestedMin: 0,
                autoSkip: false,
                fontColor: graphLabelTickFontColor,
                fontFamily: defaultFontFamily,
                fontSize: defaultFontSize,
                fontStyle: defaultFontWeight,
            },
            gridLines: {
                display: true,
                color: graphGridColor,
                zeroLineColor: graphGridColor,
            },
        },
    ];
    axes[0].stacked = hasBusiness;

    return axes;
};

// getXAxes returns a CommonAxe x axes for chartjs
export const getXAxes = (
    hasBusiness: boolean,
    graphLabelXFontColor: string,
    graphGridColor: string,
): CommonAxe[] => {
    const axes: CommonAxe[] = [
        {
            ticks: {
                /**
                 * Callback function for every tick
                 * @param {string} value
                 * @return {string}
                 */
                callback: (value: string): string => {
                    if (
                        typeof value === 'string' &&
                        value.length > maxStringLength
                    ) {
                        const val = value.substring(0, maxStringLength);
                        // fallback to prevent substring for time strings (11:00)
                        if (
                            val.charAt(maxStringLength - 1) !== ':' &&
                            val.charAt(1) !== ':'
                        ) {
                            return val;
                        }
                    }

                    return value;
                },
                min: 0,
                autoSkip: false,
                fontColor: graphLabelXFontColor,
                fontFamily: defaultFontFamily,
                fontSize: defaultFontSize,
                fontStyle: defaultFontWeight,
                padding: 5,
            },
            gridLines: {
                display: false,
                color: graphGridColor,
                zeroLineColor: graphGridColor,
            },
        },
    ];
    axes[0].stacked = hasBusiness;

    return axes;
};

// Returns label string based on usage type
const getLabelString = (usageType: number | null = null): string => {
    if (usageType === enums.UsageTypeGasUsage) {
        return usageSuffix.get(enums.UsageTypeGasUsage);
    } else if (
        usageType === enums.UsageTypeElekUsage ||
        usageType === enums.UsageTypeElekProd
    ) {
        return usageSuffix.get(enums.UsageTypeElekUsage);
    } else {
        return usageSuffix.get(0);
    }
};

/**
 * _getLegend returns the legend for the cost graph
 * @return {object}
 *
 */
export const getLegend = (): ChartLegendOptions => {
    return {
        display: true,
        position: 'bottom',
        align: 'end',
        labels: {
            boxWidth: 12,
            fontSize: 14,
        } as ChartLegendLabelOptions,
    };
};

export const createCostReportsPerMonth = (
    costReports: CostReport[],
): CostReport[] | null => {
    const reportsPerMonth: MonthlyCostReports = structuredClone(
        costReports,
    ).reduce((acc: MonthlyCostReports, val: CostReport) => {
        const d = new Date(val.readingDate);
        // if the array index doesnt exist create empty costReport object
        if (!acc[d.getMonth()]) {
            acc[d.getMonth()] = {
                totalIncl: 0,
                totalUsage: 0,
                readingDate: val.readingDate,
                productType: val.productType,
            } as CostReport;
        }

        // if production make positive numbers negative to subtract the costs and usage and the other way arround
        if (val.productType === enums.ProductTypeElekProduction) {
            acc[d.getMonth()].totalIncl -= val.totalIncl;
            acc[d.getMonth()].totalUsage -= val.totalUsage;
            return acc;
        }

        // default add total inclusive and usage
        acc[d.getMonth()].totalIncl += val.totalIncl;
        acc[d.getMonth()].totalUsage += val.totalUsage;

        return acc;
    }, {} as MonthlyCostReports);
    let totalIncl = 0;
    const monthlyCostReports = [...new Array(12)].map((_, i) => {
        let entry = {
            totalIncl: 0,
            totalUsage: 0,
            readingDate: '',
            productType: 1,
        };
        if (reportsPerMonth[i]) {
            entry = reportsPerMonth[i];
            entry.totalIncl = Number(reportsPerMonth[i].totalIncl.toFixed(2));
            entry.totalUsage = Number(reportsPerMonth[i].totalUsage.toFixed(2));
            totalIncl += entry.totalIncl;
        }
        return entry;
    });

    if (totalIncl === 0) {
        return null;
    }
    return monthlyCostReports;
};

export const usageTypeToProductType = (
    usageType: number,
): number | undefined => {
    switch (usageType) {
        case enums.UsageTypeGasUsage:
            return enums.ProductTypeGasUsage;
        case enums.UsageTypeElekUsage:
            return enums.ProductTypeElekUsage;
        case enums.UsageTypeElekProd:
            return enums.ProductTypeElekProduction;
        default:
            return undefined;
    }
};
