import { ForkEffect, put, takeLatest } from 'redux-saga/effects';
import { SET_LOCATION } from 'actions/routing';
import { RouterLocation } from '@vaadin/router';
import { ListBroadcasts } from '@async-reducers/broadcasts';
import { routePaths } from 'helpers/routing';
import { setNotification, deleteAllNotifications } from 'actions/notification';

// Checks if an user is authenticated and have a valid snapshot to display the dashboard
function* checkAndFetchNotifications(_: RouterLocation) {
    const authenticated = window.store.getState().app.userAuthenticated;
    if (authenticated) {
        yield put(ListBroadcasts.run());
    }
}

function* checkForNewNotifications(action: any) {
    const notification = action?.data?.data?.getLatestBroadcast;
    if (!notification && new Date(notification?.expiryDate) > new Date()) {
        return;
    }
    yield put(deleteAllNotifications());
    if (!notification) {
        return;
    }
    yield put(
        setNotification(
            notification.message,
            [
                routePaths.overview,
                routePaths.energyCost,
                routePaths.electricity,
                routePaths.production,
                routePaths.gas,
                routePaths.invoices,
                routePaths.settings,
                routePaths.energyStandings,
                routePaths.editMonthlyPaymentSettings,
            ],
            undefined,
            { ms: -1, fixed: true },
            'info',
        ),
    );
}

export function* watchSetLocation(): IterableIterator<ForkEffect> {
    // @ts-ignore
    yield takeLatest(SET_LOCATION, checkAndFetchNotifications);
}

export function* watchListBroadcasts(): IterableIterator<ForkEffect> {
    yield takeLatest(
        ListBroadcasts.actionTypeMap.callback,
        checkForNewNotifications,
    );
}
