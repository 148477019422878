export const setLoaderPrefix = `SET_LOADER_STATE`;
export const setLoaderBouncePrfix = `SET_LOADER_DEBOUNCE`;
// set loader state and optional preventDebounce call
export const setLoader = (
    loading: boolean,
    preventDebounce: boolean = false,
) => ({
    type: setLoaderPrefix,
    loading,
    preventDebounce,
});

// set the loaders debounce
export const setLoaderDebounce = (loaderDebounce: boolean = false) => ({
    type: setLoaderBouncePrfix,
    loaderDebounce,
});
