import { takeLatest, put } from 'redux-saga/effects';
import { changePassword } from '@async-reducers/user';
import { signIn } from '@async-reducers/authentication';

function* refreshToken() {
    window.STDHeaders.delete('X-Auth');
    const { email } = window.store.getState().user;
    const [, newPassword] = window.store.getState().changePassword.query;
    yield put(signIn.run(email, newPassword));
}

export function* watchChangePassword() {
    yield takeLatest(changePassword.actionTypeMap.callback, refreshToken);
}
