import { SHOW_LOGIN_PROMPT, HIDE_LOGIN_PROMPT } from '../actions/loginPrompt';

/**
 * State reducer for login prompt
 * @param {String?} state
 * @param {Object} action
 * @return {String?}
 */
export const loginPrompt = (state = null, action) => {
    switch (action.type) {
        case SHOW_LOGIN_PROMPT:
            return action.message === undefined
                ? 'Log in om verder te gaan'
                : action.message;
        case HIDE_LOGIN_PROMPT:
            return null;
        default:
            return state;
    }
};
