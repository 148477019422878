import { css } from 'lit-element';

export default css`
    :host {
        --tooltip--width: 390px;
        --tooltip--side-padding: 5px;
        --tooltip--top-bot-padding: 5px;
        --tooltip--margin: 0 0 0 0;
        --tooltip-icon-height: 14px;
        --tooltip-icon-width: 14px;

        position: relative;
        display: flex;
        cursor: pointer;
        margin: var(--tooltip--margin);
    }
    :host([hidden]) {
        display: none;
    }
    /* Tooltip text */
    .tooltiptext {
        display: none;
        text-align: left;
        padding: var(--tooltip--top-bot-padding) var(--tooltip--side-padding);
        border-radius: 6px;
        font-family: var(--primary-font-family);
        font-size: 14px;
        font-weight: 300;
        letter-spacing: 0.3px;
        color: #aaa;

        position: absolute;
        z-index: 1;
        max-width: calc(100vw - (2 * var(--tooltip--side-padding, 0)));
    }

    .tooltiptext br {
        content: '';
        margin: 7px 0;
        display: block;
    }

    img {
        width: var(--tooltip-icon-width);
        height: var(--tooltip-icon-height);
    }

    /* Show the tooltip text when you mouse over the tooltip element */
    :host(:hover) .tooltiptext {
        display: block;
        animation: fade-in 500ms;
        bottom: 14px;
        left: calc(var(--tooltip--width) / 2 * -1);
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.15);
        background-color: var(--white);
        border: solid 1px #eee;
        border-radius: 3px;
        margin-bottom: 15px;
        width: var(--tooltip--width);
        opacity: 1;
        height: fit-content;
    }
    @keyframes fade-in {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    @-moz-keyframes fade-in {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    @-webkit-keyframes fade-in {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    @-o-keyframes fade-in {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    @-ms-keyframes fade-in {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
`;
