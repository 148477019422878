import {
    LitElement,
    TemplateResult,
    customElement,
    html,
    PropertyValues,
    property,
} from 'lit-element';
import '../../../components/molecules/Spinner';

import {
    InAppBrowser,
    InAppBrowserEvent,
    InAppBrowserEventType,
    InAppBrowserObject,
} from '@awesome-cordova-plugins/in-app-browser';

import '@templates/smart-charging-step-wrapper/SmartChargingStepWrapper';
import { vehicleAuthenticate } from '../../../async-reducers/vehicle';
import { receiveSelf } from '@async-reducers/authentication';
import { connect, watch } from 'lit-redux-watch';
import { store } from 'data-access/store';
import { Snapshot } from 'types/snapshot';
import '../../../components/atoms/Popup';

/**
 * Used as ui view for the step with SmartChargingOnboarding.ts
 */
@customElement('view-car-connect-oauth')
export class CarConnectOauth extends connect(window.store)(LitElement) {
    @property({ type: Object })
    public iab?: InAppBrowserObject;

    static get styles() {
        return [];
    }

    @watch('jwtSnapshots.smartChargingSnapshots')
    public smartChargingSnapshot?: Snapshot[];

    @watch('vehicleAuthenticate.busy')
    public vehicleAuthenticateBusy: boolean = false;

    @watch('vehicleAuthenticate.error')
    public vehicleAuthenticateError?: object;

    @watch('vehicleAuthenticate.data.redirect_url')
    public vehicleAuthenticateData?: string;

    public connectedCallback(): void {
        super.connectedCallback();
        this.parentElement?.addEventListener(
            'enter',
            this.handleEnterEvent as EventListener,
        );
    }

    // Keeps track of updated properties
    public updated(changedProperties: PropertyValues): void {
        // Trigger handleAuthenticationURIDelivery when a URI is provided
        changedProperties.has('vehicleAuthenticateData') &&
            this.vehicleAuthenticateData &&
            this.handleAuthenticationURIDelivery(this.vehicleAuthenticateData);

        if (
            changedProperties.has('vehicleAuthenticateError') &&
            this.vehicleAuthenticateError
        ) {
            this.prevHandler();
            window.displayMessage(
                'Er is iets fout gegaan bij het starten van Enode connect, probeer het later nog eens...',
                'error',
            );
        }
    }

    // handleAuthenticationURIDelivery handles the response from vehicle authenticate and will
    // make sure the apps shows the authentication flow via enode
    public handleAuthenticationURIDelivery = (URI: string): void => {
        // First create new InAppBrowser instance (this will also open it)
        // mind that if the platform is not iOS or Android window.open() will be used
        // and cannot be tracked via events
        this.iab = InAppBrowser.create(URI, '_blank');
        // Setup event handlers for the navigation events
        this.iab
            ?.on('loadstart')
            ?.subscribe(this.handleInAppBrowserNavigationEvent);
        this.iab
            ?.on('loaderror')
            ?.subscribe(this.handleInAppBrowserNavigationEvent);
        this.iab?.on('exit')?.subscribe(this.handleInAppBrowserNavigationEvent);
    };

    private handleInAppBrowserNavigationEvent = (
        event: InAppBrowserEvent,
    ): void => {
        let browserUrl: URL | undefined;
        if (event.url !== undefined && event.url !== '') {
            browserUrl = new URL(event.url);
        }

        switch (event.type as InAppBrowserEventType) {
            // Handle load page errors in Enode flow
            case 'loaderror':
                window.displayMessage(
                    'Er is iets fout gegaan bij het koppelen van je auto, probeer het later nog eens...',
                    'error',
                );
                this.iab?.close();
                this.prevHandler();
                break;
            // Handle exit browser flow
            case 'exit':
                if (/^https?:\/\/([\w\d]+\.)*?enode\.io$/.test(event.url)) {
                    // User was still on a Enode page
                    window.displayMessage(
                        'Je hebt het koppelen van je auto voortijdig afgerond, probeer het nogmaals...',
                        'warning',
                    );
                    this.prevHandler();
                }
                break;
            case 'loadstart':
                // RedirectURI fired finish connect flow
                if (
                    (browserUrl &&
                        String(browserUrl?.hostname).includes(
                            'energyzero.nl',
                        )) ||
                    String(browserUrl?.hostname).includes(
                        'staging.energyzero.nl',
                    )
                ) {
                    this.iab?.close();
                    window.displayMessage(
                        'Het koppelen van je auto is gelukt, nog even geduld we controleren de data...',
                        'succes',
                    );

                    store.dispatch(receiveSelf.run());

                    this.nextHandler();
                }
                break;
            default:
        }
    };

    public handleEnterEvent = (_: CustomEvent): void => {
        if (
            !this.smartChargingSnapshot ||
            this.smartChargingSnapshot?.length === 0
        ) {
            // No smart charging snapshots?
            return;
        }

        // Reset if already exists
        if (this.iab) {
            this.iab.close();
            this.iab.hide();
            this.iab = undefined;
        }

        // Dispatch vehicle authenticate action to resolve the oauth URI
        window.store.dispatch(
            vehicleAuthenticate.run(this.smartChargingSnapshot[0].id),
        );
    };

    // let parent components know that a previous step has been trigger in this ui
    public prevHandler = (): void => {
        this.dispatchEvent(
            new CustomEvent('weave-step-previous', {
                bubbles: true,
                composed: true,
            }),
        );
    };

    public nextHandler = (): void => {
        this.dispatchEvent(
            new CustomEvent('weave-step-next', {
                bubbles: true,
                composed: true,
            }),
        );
    };

    public render = (): TemplateResult => {
        const { vehicleAuthenticateBusy } = this;
        return html`
            <div>
                ${vehicleAuthenticateBusy
                    ? html`<ez-spinner
                          >Wij verbinden je door met de Enode
                          service</ez-spinner
                      >`
                    : null}
            </div>
        `;
    };
}

declare global {
    interface HTMLElementTagNameMap {
        'view-car-connect-oauth': CarConnectOauth;
    }
}
