import axios from 'axios';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
    REQUEST_INVOICES,
    requestInvoicesSuccess,
    requestInvoicesFailed,
} from '../actions/invoice';
import headers from './helpers/headers';

/**
 * Collectives fetcher
 * @param {Object} action
 */
function* fetchInvoices(action) {
    try {
        const result = yield call(async () => {
            const response = await axios.get(
                `/v1/user/${action.userId}/invoice`,
                {
                    baseURL: window.API_LINK,
                    headers: headers(STDHeaders),
                },
            );
            if (response.statusText === 'OK' || response.status === 200) {
                return { data: response.data };
            }
            throw response.status;
        });
        yield put(requestInvoicesSuccess(result.data));
    } catch (e) {
        yield put(requestInvoicesFailed(e));
    }
}

/**
 * Watcher for REQUEST_INVOICES action
 */
export function* watchRequestInvoices() {
    yield takeLatest(REQUEST_INVOICES, fetchInvoices);
}
