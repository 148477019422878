import { html } from 'lit-element';

export const elecCapacityInfo = html`De zwaarte van je aansluiting kun je
    terugvinden op de factuur van je huidige energieleverancier. Als de (3)
    hoofdzekering(en) in de meterkast te zien zijn, is daar vaak de zwaarte op
    aangegeven. Heb je een 1-fase-aansluiting (slechts 1 hoofdzekering) kies dan
    voor “3x25 Ampere”. <br />
    Ter indicatie kun je uitgaan van het volgende verbruik:
    <br />0-15.000kWh:3x25A, 15.000-25.000kWh:3x35A, 25.000-40.000kWh:3x50A,
    40.000-80.000kWh:3x63A, >80.000kWh:3x80A.`;
export const gasCapacityInfo = html`De zwaarte van je aansluiting kun je
    terugvinden op de factuur van je huidige energieleverancier. De G-codering
    staat ook vermeld op de gasmeter. <br />
    Ter indicatie kun je uitgaan van het volgende gasverbruik: <br />0-10.000m3
    : G6 , 10.000-15.000m3 : G10 , 15.000-30.000m3 : G16 , >30.000m3 G25`;
