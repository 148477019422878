import { LitElement, customElement, html, property } from 'lit-element';
import { SVGTemplateResult, TemplateResult } from 'lit-html';
import { ifDefined } from 'lit-html/directives/if-defined';
import { whitelabel } from '@weavelab/whitelabel';
import { provider } from '@weavelab/frontend-connect';

import sharedStyles from '../../../../sharedStyles';
import style from './style';

type themeType = 'primary' | 'secondary' | 'text-and-arrow' | 'text' | '';
type size = 'small' | '';
type spacing = 'default' | 'big-top' | 'minimal' | 'none';

@customElement('button-component')
@whitelabel({ name: 'button-component' })
export class ButtonComponent extends LitElement {
    @property()
    public src?: string | any;

    @property()
    public label: string = '';

    @property()
    public type?: string;

    @property()
    public href?: string;

    @property({
        type: Boolean,
    })
    public disableInner: boolean = false;

    @property({
        attribute: 'src-height',
    })
    public srcHeight: string = '16';

    @property({
        attribute: 'src-width',
    })
    public srcWidth: string = '16';

    @property({
        attribute: 'theme',
    })
    public theme: themeType = '';

    @property({ type: String })
    public size?: size;

    @property({
        attribute: 'custom-style',
    })
    public customStyle?: string;

    @property({
        attribute: 'custom-inner-style',
    })
    public customInnerStyle?: string;

    @property({ type: String })
    public verticalSpacing?: spacing = 'none';

    @property()
    public padding: string = '15px 25px';

    @property({ type: Boolean, reflect: true })
    public disabled: boolean = false;

    @property({ attribute: 'hidden', type: Boolean, reflect: true })
    public hidden: boolean = false;

    @whitelabel() // Allow labelPrefix to be set from theme
    public static labelPrefix: string = '';

    @whitelabel() // Allow styles to be injected from theme
    static get styles() {
        return [sharedStyles, style];
    }

    @whitelabel()
    public static iconURL?: string;

    @property({ type: Boolean, reflect: true })
    public showIcon: boolean = false;

    @property({ type: Object })
    public iconRight?: any;

    // eslint-disable-next-line no-unused-vars
    private asset?: (arg: string) => string;

    constructor() {
        super();

        const { iconURL } = ButtonComponent;
        if (iconURL == null) {
            return;
        }

        this.showIcon = true;
        this.asset = provider.asset;
        this.iconRight = this.asset(iconURL);
    }

    protected render = (): TemplateResult => {
        const { src } = this;

        return html`
            <style>
                :host {
                    display: ${this.size === 'small' ? 'block' : 'flex'};

                    --icon_right--background-size: 15px;
                    --icon_right--width: 16px;
                    --icon_right--margin: 2px 0 0 12px;
                }

                :host([showIcon]) button span::after {
                    background: url(${ButtonComponent.iconURL}) left top
                        no-repeat;
                    background-size: var(--icon_right--background-size);
                    content: '';
                    height: var(--icon_right--background-size);
                    width: var(--icon_right--width);
                    margin: var(--icon_right--margin);
                }

                button {
                    padding: ${this.padding};
                }

                button .icon {
                    width: ${this.srcWidth}px;
                    height: ${this.srcHeight}px;
                }

                button img {
                    width: ${this.srcWidth}px;
                    height: ${this.srcHeight}px;
                }

                .ripple {
                    position: relative;
                    overflow: hidden;
                    transform: translate3d(0, 0, 0);
                }
                .ripple:after {
                    content: '';
                    display: block;
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    pointer-events: none;
                    background-image: radial-gradient(
                        circle,
                        #000 10%,
                        transparent 10.01%
                    );
                    background-repeat: no-repeat;
                    background-position: 50%;
                    transform: scale(10, 10);
                    opacity: 0;
                    transition: transform 0.5s, opacity 1s;
                }
                .ripple:active:after {
                    transform: scale(0, 0);
                    opacity: 0.2;
                    transition: 0s;
                }
            </style>
            <button
                theme=${ifDefined(this.theme)}
                size=${ifDefined(this.size)}
                verticalSpacing=${ifDefined(this.verticalSpacing)}
                type=${ifDefined(this.type)}
                ?disabled=${this.disabled}
                style=${ifDefined(this.customStyle)}
                area-label="Button ${this.label}"
                class="ripple"
            >
                ${src != null
                    ? html`
                          <style>
                              svg {
                                  width: ${this.srcWidth}px;
                                  height: ${this.srcHeight}px;
                              }
                          </style>
                          ${src instanceof SVGTemplateResult
                              ? src
                              : html`
                                    <div class="icon">
                                        <img
                                            class="center"
                                            src="${src}"
                                            alt="Button ${this.label}"
                                        />
                                    </div>
                                `}
                      `
                    : html``}
                <span
                    style=${ifDefined(this.customInnerStyle)}
                    class="${this.src && !this.disableInner ? 'inner' : ''}"
                >
                    ${this.label}
                </span>

                ${this.theme === 'text-and-arrow'
                    ? html`<div class="arrow-icon">></div>`
                    : null}
            </button>
        `;
    };
}

declare global {
    interface HTMLElementTagNameMap {
        'button-component': ButtonComponent;
    }
}
