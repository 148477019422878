import { PolymerElement, html } from '@polymer/polymer/polymer-element';
import { provider } from '@weavelab/frontend-connect';
// @ts-ignore
import { Router } from '@vaadin/router';
import enums from 'enums';
import watch from 'redux-watch';

import '@polymer/iron-flex-layout/iron-flex-layout';
import '@polymer/iron-icon/iron-icon';
import '@polymer/iron-pages/iron-pages';
import '@polymer/iron-selector/iron-selector';
import '../../ez-icons';

import 'atoms/DashboardBlockHalf';
import '../../../containers/phase-banner/PhaseBanner';

// This element is connected to the Redux store.
import { requestCostValues } from '@actions/costValues';
import { requestInsights } from '@actions/insights';
import { userHasData } from '@actions/user';
import { store } from 'data-access/store';
import { datesAreOnSameMonth } from 'helpers/dates';
import { routePaths } from 'helpers/routing';
import { snapshotHasElec, snapshotHasGas } from 'helpers/snapshot';
import { getCurrentWeekAsString, getDatesForSnapshot } from '../../../globals';

import template from './dashboard.html';
import css from './dashboard.pcss';

const themeSettings = provider.settings('dashboard');

const defaultReading = 'We kunnen geen meterstanden ophalen';
const defaultNetworkAdministrator =
    // eslint-disable-next-line max-len
    'Data uit de slimme meter ontvangen wij van jouw regionale netbeheerder, dit duurt min. 2 tot max. 10 dagen. Langer geen data? Check de status van jouw slimme meter bij de netbeheerder.';

/**
 * Class EzDashboard
 */
export default class EzDashboard extends PolymerElement {
    /**
     * Constructor of EzDashboard
     */
    constructor() {
        super();
        /** @type {String} */
        this.Cost = '0';
        /** @type {Boolean} */
        this.showWeek = false;
        // @ts-ignore
        this.showWeek =
            themeSettings && themeSettings.hideWeek !== undefined
                ? !themeSettings.hideWeek
                : true;
        /** @type {String} */
        this.appTitle =
            themeSettings && themeSettings.title ? themeSettings.title : '';
        /** @type {String} */
        this.dataNotificationText = '';
        /** @type {boolean} */
        this.fetchedData = false;
        /** @type {boolean} */
        this.userAuthenticated = false;
        /** @type {boolean} */
        this.insightsAppUser = false;
        /** @type {Number | Null} */
        this.insightsElec = null;
        /** @type {Number | Null} */
        this.insightsGas = null;
        /** @type {Number | Null} */
        this.insightsProd = null;
        /** @type {string} */
        this.selectedTab = '';
        /** @type  {Boolean} */
        this.busy = false;
        /** @type  {Boolean} */
        this.costBusy = false;
        /** @type  {Boolean} */
        this.monthValueBusy = false;
        /** @type  {Boolean} */
        this.showBenefits = !!themeSettings?.showBenefits;
        /** @type  {Boolean} */
        this.showWorkSchedule = !!themeSettings?.showWorkSchedule;
        /** @type {Object} */
        this.previousCostEndDate = new Date();
        this.defaultNetworkAdministrator = defaultNetworkAdministrator;
        this.previousMonthlyEndDate = new Date();
        this.displayPhaseMessage = false;
        this.firstMonthlyUsageLoad = true;
        /**
         * Disconnect watcher
         */
        /** @type {Function} */
        this.snapshotWatcher;
        /** @type {Function} */
        this.selectedSnapshotWatcher;
        /** @type {Function} */
        this.watchUsageWatcher;
        /** @type {Function} */
        this.costValuesWatcher;
        /** @type {Function} */
        this.costValuesBusyWatcher;
        /** @type {Function} */
        this.monthlyValuesWatcher;
        /** @type {Function} */
        this.monthlyValuesBusyWatcher;
        /** @type {Function} */
        this.insightsWatcher;
        /** @type {Function} */
        this.appWatcher;
        /** @type {Function} */
        this.userWatcher;

        /** @type {String} */
        this.faqURL = themeSettings?.faqURL;
    }

    /**
     * Get the HTML body
     */
    static get template() {
        const cssTemplate = document.createElement('template');
        cssTemplate.innerHTML = provider.styles(css);
        const htmlTemplate = document.createElement('template');
        htmlTemplate.innerHTML = template;
        return html`<style include="iron-flex">
                ${cssTemplate}
            </style>
            ${htmlTemplate}`;
    }

    /**
     * Get the properties of this class
     */
    static get properties() {
        return {
            user: {
                type: Object,
                value: {},
                observer: '_userChanged',
            },
            hasData: {
                type: Boolean,
                value: true,
            },
            hasDataCosts: {
                type: Boolean,
                value: true,
            },
            hasDataSavings: {
                type: Boolean,
                value: true,
            },
            hasDataEstimations: {
                type: Boolean,
                value: true,
            },
            busy: {
                type: Boolean,
                value: false,
            },
            subheader: {
                type: String,
            },
            checkDataRequestDone: Boolean,
            userID: {
                type: String,
                value: '',
            },
            snapshot: {
                type: Object,
            },
            snapshots: {
                type: Array,
            },
            snapshotStartDate: {
                type: Object,
                value: null,
            },
            insightsAppUser: {
                type: Boolean,
                value: false,
            },
            userAuthenticated: {
                type: Boolean,
                value: false,
            },
            insightsElec: {
                type: Number,
                value: null,
            },
            insightsGas: {
                type: Number,
                value: null,
            },
            insightsProd: {
                type: Number,
                value: null,
            },
            selectedTab: {
                type: String,
                value: 'collectief',
            },
            enabledItems: {
                type: Object,
                value: {},
            },
            monthlyValues: {
                type: Object,
                value: {},
            },
            readingInformation: {
                type: String,
                value: defaultReading,
            },
            snapshotPhaseIncorrect: {
                type: Boolean,
                value: false,
            },
            costMonthEndDate: {
                type: Object,
                value: new Date(),
            },
            MonthlyUsageEndDate: {
                type: Object,
                value: new Date(),
            },
            displayPhaseMessage: {
                type: Boolean,
                value: false,
            },
        };
    }

    /**
     * Class ready callback
     */
    ready() {
        super.ready();
        this.firstMonthlyUsageLoad = true;
        const awLogin = store.getState().awaitLogin;
        if (!awLogin) {
            const { snapshots } = store.getState().jwtSnapshots;
            this.snapshots = snapshots;
            this._snapshotStateChanged(snapshots);
        }
        // required for polymer to render the first snapshot view (without presist root)
        this.snapshotWatcher = watch(store.getState, 'jwtSnapshots.snapshots');
        store.subscribe(
            this.snapshotWatcher(
                /**
                 * @param {Snapshot[]} snapshots
                 */
                (snapshots) => {
                    // prevent setting logic from old snapshot state
                    const { awaitLogin } = store.getState();
                    if (!awaitLogin) {
                        this._snapshotStateChanged(snapshots);
                    }
                },
            ),
        );

        this.selectedSnapshotWatcher = watch(
            store.getState,
            'jwtSnapshots.selectedSnapshot',
        );
        store.subscribe(
            this.selectedSnapshotWatcher((selectedSnapshot) => {
                // fallback if authenication was busy (awaitLogin) the snapshots are null.
                if (this.snapshots == null && selectedSnapshot != null) {
                    this.snapshots = store.getState().jwtSnapshots.snapshots;
                }
                this._snapshotStateChanged(this.snapshots);
            }),
        );
        // TODO: remove logic isn't used anymore page overzicht doesnt exist
        this.watchUsageWatcher = watch(store.getState, 'usage');
        store.subscribe(
            this.watchUsageWatcher(
                /**
                 * @param {Usage} newusage
                 */
                (newusage) => {
                    const page = window.location.pathname.substring(1);
                    if (page && page === 'overzicht') {
                        this._checkIfUsageIsAvailable(newusage);
                    }
                },
            ),
        );

        const costVals = store.getState().costValues.data;
        if (costVals) {
            this._setMonthlyCostValues(costVals);
        }

        this.costValuesWatcher = watch(store.getState, 'costValues');
        store.subscribe(
            this.costValuesWatcher((costValues) => {
                this.hasDataCosts = !!costValues.data;
                if (
                    !costValues.busy &&
                    costValues.data &&
                    costValues.data.tillDate
                ) {
                    this.costMonthEndDate = new Date(costValues.data.tillDate);
                    this._setMonthlyCostValues(costValues.data);
                }
                this._getIfUserDataIsAvailable();
            }),
        );

        this.costValuesBusyWatcher = watch(store.getState, 'costValues.busy');
        store.subscribe(
            this.costValuesBusyWatcher((busy) => {
                this.costBusy = busy;
                this._getIfUserDataIsAvailable();
            }),
        );

        // on initalization of the component check if data exists
        const monthlyValuesData = window.store.getState().getUsages.data;
        if (monthlyValuesData) {
            this.monthlyValues = { ...monthlyValuesData };
            if (
                monthlyValuesData != null ||
                monthlyValuesData.totalElec ||
                monthlyValuesData.totalGas ||
                monthlyValuesData.totalProd
            ) {
                this.hasData = true;
            }
        }
        // gets fetched when the selected snapshots changes
        this.monthlyValuesWatcher = watch(store.getState, 'getUsages.data');
        store.subscribe(
            this.monthlyValuesWatcher(
                /**
                 * @param {Object} monthlyValues
                 */
                (monthlyValues) => {
                    if (
                        monthlyValues == null &&
                        window.store.getState().getUsages.busy
                    ) {
                        this.monthValueBusy = true;
                        return;
                    }
                    this.setMonthlyValues(monthlyValues);
                },
            ),
        );

        // if monthlyValues getting fetched show busy
        this.monthlyValuesBusyWatcher = watch(store.getState, 'getUsages.busy');
        store.subscribe(
            this.monthlyValuesBusyWatcher(
                /**
                 * @param {Boolean} busy
                 */
                (busy) => {
                    this.monthValueBusy = busy;
                },
            ),
        );

        this.insightsWatcher = watch(store.getState, 'insights');
        store.subscribe(
            this.insightsWatcher(
                /**
                 * @param {Object} insights
                 */
                (insights) => {
                    this.costBusy = insights.busy;
                    this._getIfUserDataIsAvailable();
                    if (insights) {
                        this.insightsElec = insights.e;
                        this.insightsGas = insights.g;
                        this.insightsProd = insights.p;
                        if (
                            this.insightsElec ||
                            this.insightsGas ||
                            this.insightsProd
                        ) {
                            this.hasData = true;
                        }
                        if (
                            this.insightsElec === null &&
                            this.insightsGas === null &&
                            this.insightsProd === null
                        ) {
                            this.hasData = false;
                        }
                    }
                },
            ),
        );

        // still required?
        const appState = store.getState().app;
        if (appState.insightsAppUser !== this.insightsAppUser) {
            this.set('insightsAppUser', appState.insightsAppUser);
        }

        // still required?
        if (appState.insightsAppUser) {
            store.dispatch(requestInsights());
        }

        this.appWatcher = watch(store.getState, 'app');
        store.subscribe(
            this.appWatcher(
                /**
                 * state.app
                 * @param {Object} app
                 */
                (app) => {
                    if (app.userAuthenticated !== this.userAuthenticated) {
                        this.set('userAuthenticated', app.userAuthenticated);
                    }
                    if (app.insightsAppUser !== this.insightsAppUser) {
                        this.set('insightsAppUser', app.insightsAppUser);
                    }
                },
            ),
        );

        this.userWatcher = watch(store.getState, 'user');
        store.subscribe(
            this.userWatcher(
                /**
                 * @param {Array} user
                 */
                (user) => {
                    if (this.user && user && this.user.id !== user.id) {
                        this.user = user;
                        this._userChanged(user);
                    }
                },
            ),
        );
    }

    disconnectedCallback() {
        // unsubscribe the store watchers
        this.snapshotWatcher();
        this.selectedSnapshotWatcher();
        this.watchUsageWatcher();
        this.costValuesWatcher();
        this.costValuesBusyWatcher();
        this.monthlyValuesWatcher();
        this.monthlyValuesBusyWatcher();
        this.insightsWatcher();
        this.appWatcher();
        this.userWatcher();

        super.disconnectedCallback();
    }

    /**
     * Called when snapshot state is changed
     * @param {Object} snapshotState snapshot state
     */
    _snapshotStateChanged(snapshots) {
        if (snapshots && snapshots.length > 0) {
            this.snapshots = snapshots;
            const oldSnapshot = this.snapshot;
            const { selectedSnapshot } = store.getState().jwtSnapshots;
            if (selectedSnapshot == null) {
                const [snapshot] = snapshots;
                this.snapshot = snapshot;
            } else {
                this.snapshot = selectedSnapshot;
            }

            if (this.snapshot && this.snapshot.verified_snapshot_payload) {
                const snapshotStartDate = new Date(
                    this.snapshot.verified_snapshot_payload.start_date,
                );
                this.set('snapshotStartDate', snapshotStartDate);
            }

            if (
                this.snapshot &&
                (!this.hasDataCosts ||
                    !oldSnapshot ||
                    (oldSnapshot && oldSnapshot.id !== this.snapshot.id))
            ) {
                this._getDataCosts(this.snapshot);
            }
            // check the snapshotPhase to set the readingInformation;
            this.checkSnapshotPhase(this.snapshot);

            // check if snapshot message should be displayed
            const showPhaseMessage = this.snapshotPhaseCheckToDisplayMessage(
                this.snapshot,
            );
            if (this.displayPhaseMessage !== showPhaseMessage) {
                this.set('displayPhaseMessage', showPhaseMessage);
            }
        } else if (snapshots && snapshots.length === 0 && this.snapshot) {
            this.snapshot = null;
            this.snapshots = null;
            this.readingInformation = defaultReading;
            this.snapshotPhaseIncorrect = true;
        }
    }

    setMonthlyValues(monthlyValues) {
        if (
            monthlyValues.totalElec ||
            monthlyValues.totalGas ||
            monthlyValues.totalProd
        ) {
            this.monthlyValues = { ...monthlyValues };
            this.hasData = true;
            this.monthValueBusy = false;
            return;
        }

        this.hasData = false;
        this._getIfUserDataIsAvailable();
    }

    /**
     * _usageAppUser
     * @param {Object} snapshot snapshot
     * @param {Boolean} authenticated authenticated
     * @return {Boolean}
     */
    _usageAppUser(snapshot, authenticated) {
        this.set(
            'appUser',
            !snapshot || (snapshot === undefined && authenticated),
        );
        return !snapshot || (snapshot === undefined && authenticated);
    }

    /**
     * _setUsageMonthlyValues called when monthlyValues state changed
     * @param {Object} monthlyValues
     */
    _setUsageMonthlyValues(monthlyValues) {
        if (monthlyValues.totalElec) {
            this.ElekUsageData = Number(monthlyValues.totalElec).toFixed(0);
        }
        if (monthlyValues.totalGas) {
            this.GasUsageData = Number(monthlyValues.totalGas).toFixed(0);
        }
        if (monthlyValues.totalProd) {
            this.ProdData = Number(monthlyValues.totalProd).toFixed(0);
        }
    }

    /**
     * @param {Object} monthlyValues
     * @return {String}
     */
    _getElekUsageData = (monthlyValues) => {
        if (monthlyValues.totalElec) {
            return Number(monthlyValues.totalElec).toFixed(0);
        }
        return '';
    };

    /**
     * @param {Object} monthlyValues
     * @return {String}
     */
    _getGaskUsageData = (monthlyValues) => {
        if (monthlyValues.totalGas) {
            return Number(monthlyValues.totalGas).toFixed(0);
        }
        return '';
    };

    /**
     * @param {Object} monthlyValues
     * @return {String}
     */
    _getCostUsageData = (monthlyValues) => {
        if (monthlyValues.totalCosts) {
            return Number(monthlyValues.totalCosts).toFixed(0);
        }
        return '';
    };

    /**
     * @param {Object} monthlyValues
     * @return {String}
     */
    _getProdkUsageData = (monthlyValues) => {
        if (monthlyValues.totalProd) {
            return Number(monthlyValues.totalProd).toFixed(0);
        }
        return '';
    };

    /**
     * _setMonthlyCostValues called when monthlyValues state changed
     * @param {Object} monthlyValues
     * @param {Object} state
     */
    _setMonthlyCostValues(monthlyValues) {
        if (monthlyValues == null) {
            return;
        }
        // Monthly cost
        const { curMonthReal } = monthlyValues;
        if (curMonthReal) {
            this.CostsForThisMonth = Number(curMonthReal).toFixed(0);
            // fallback when current month has no data it fetches the previous month.
            if (
                Number(this.CostsForThisMonth) === 0 &&
                datesAreOnSameMonth(new Date(), this.previousCostEndDate)
            ) {
                const d = this.previousCostEndDate;
                d.setMonth(d.getMonth() - 1);
                this._getDataCosts(this.snapshot, d);
            } else {
                this.hasDataCosts = this.CostsForThisMonth !== 0;
            }
            if (this.hasDataCosts) {
                this.CostsForThisMonthDescription =
                    'Totaal werkelijke energiekosten van';
            }
        } else if (
            curMonthReal === 0 ||
            this.CostsForThisMonth === 0 ||
            this.CostsForThisMonth == null
        ) {
            this.hasDataCosts = false;
            this.CostsForThisMonthDescription = `Nog géén data beschikbaar voor een kostenberekening`;
        }
        // Current savings
        const savingsTillNow = monthlyValues.savingsTillNowExclCurMonth;
        if (savingsTillNow !== null && savingsTillNow !== 0) {
            const totalSavings = Number(savingsTillNow).toFixed(0);
            if (Number(savingsTillNow) === 0 || savingsTillNow == null) {
                this.TotalSavingsDescription =
                    'Nog géén data beschikbaar voor een kostenberekening';
            } else {
                this.TotalSavings = totalSavings;
                let description = 'Berekende ';
                description +=
                    Number(savingsTillNow) < 0
                        ? 'bijbetaling '
                        : 'tussenstand ';
                description += 'tot en met de laatst afgeronde maand';
                this.TotalSavingsDescription = description;
            }
            this.hasDataSavings = totalSavings !== 0;
        }
        // Total year savings with estimated savings
        const { yearSavings } = monthlyValues;
        if (yearSavings !== null && yearSavings !== 0) {
            const totalSavings = Number(yearSavings).toFixed(0);
            this.EstimatedSavings = totalSavings;

            if (Number(yearSavings) === 0 || totalSavings == null) {
                this.EstimatedSavingsDescription =
                    'Nog géén data beschikbaar voor een kostenberekening';
            } else {
                let description = 'Verwachte ';
                description +=
                    Number(yearSavings) < 0 ? 'bijbetaling ' : 'tussenstand ';
                description += 'op de eerstvolgende jaarrekening';
                this.EstimatedSavingsDescription = description;
            }

            this.hasDataEstimations = totalSavings !== 0;
        }
    }

    /**
     * Checks if dataset is not empty.
     * @param {Usage} usage defines labels array and dataset array
     * @return {Boolean} defines if user has data available
     */
    _checkIfUsageIsAvailable(usage) {
        if (store.getState().user.hasData) {
            this.hasData = true;
            return true;
        }
        if (usage && usage.datasets && usage.datasets.length > 0) {
            if (usage.datasets[0].data.length > 0) {
                store.dispatch(userHasData());
                this.hasData = usage.datasets[0].data.length > 0;
                return this.hasData;
            }
        }
        this.hasData = false;
        return this.hasData;
    }

    /**
     * Fetches data from between the till and from date
     * @param {Object} snapshot
     * @param {Date | null} endDate
     */
    _getDataCosts(snapshot, endDate) {
        let from;
        let till;
        if (endDate) {
            till = new Date(endDate);
            from = new Date(endDate.getFullYear(), endDate.getMonth(), 1);
        } else {
            const obj = getDatesForSnapshot(snapshot);
            from = obj.from;
            till = obj.till;
        }
        this.subheader = getCurrentWeekAsString(new Date(from));
        const requestObject = {
            dateFrom: from.toISOString(),
            dateTill: till.toISOString(),
            intervalType: enums.IntervalTypeHourly,
            snapshotID: snapshot.id,
        };
        const { costValues } = store.getState();
        if (costValues.busy) {
            return;
        }

        if (requestObject.snapshotID) {
            store.dispatch(
                requestCostValues(
                    requestObject.intervalType,
                    requestObject.snapshotID,
                    requestObject.dateFrom,
                    requestObject.dateTill,
                ),
            );
            // if fetching the cost again, set costBusy to show the loading indicator.
            this.costBusy = true;
        }
    }

    /**
     * Get snapshot id from active snapshot
     * @param {Array<Object>} snapshots
     * @return {string?}
     */
    _getSnapshotId = (snapshots) => {
        /** @type {string?} */
        let id = null;
        (snapshots || []).forEach((s) => {
            if (
                s &&
                s.snapshot_phase &&
                s.snapshot_phase >= enums.SnapshotPhaseUsageChecked &&
                s.id
            ) {
                id = s.id;
            }
        });
        return id;
    };

    /**
     * Transform usage data and return human readable value
     * @param {Array?} data is the global usage state
     * @return {string}
     */
    _dataTransform = (data) => {
        let res = 0;
        (data || []).forEach((r) => {
            res += r && !Number.isNaN(r.reading) ? r.reading : 0;
        });
        return parseFloat(`${res}`).toFixed(0);
    };

    /**
     * Calculate cost total
     * @param {Array<Array>} used
     * @param {Array<Array>} produced
     * @return {string}
     */
    _costDataTransform = (used, produced) => {
        let res = 0;
        (used || []).forEach((use) => {
            (use || []).forEach((useItem) => {
                if (useItem && !Number.isNaN(useItem.cost)) {
                    res += useItem.cost;
                }
            });
        });
        (produced || []).forEach((prod) => {
            (prod || []).forEach((prodItem) => {
                if (prodItem && !Number.isNaN(prodItem.cost)) {
                    res -= prodItem.cost;
                }
            });
        });
        return parseFloat(`${res}`).toFixed(2).split('.').join(',');
    };

    /**
     *
     * @param {any} snapshot
     * @return {boolean}
     */
    _shouldHideSavings = (snapshot) => Number(snapshot.snapshot_phase) >= 900;

    /**
     * Called when user state changed
     * @param {Object} user is the user object in the global state
     */
    _userChanged(user) {
        if (
            user.email &&
            !this.checkDataRequestDone &&
            user.id === this.userID
        ) {
            this.checkDataRequestDone = true;
        } else if (user.id !== this.userID) {
            this.checkDataRequestDone = false;
            this.checkDataRequestDone = true;
        }
        this.userID = user.id;
    }

    /**
     * Check if welcome variant
     * @param {Number|String} variant
     * @return {Boolean}
     */
    _welcomeVariant = (variant) =>
        (themeSettings && !Number.isNaN(themeSettings.welcomeVariant)
            ? Number(themeSettings.welcomeVariant)
            : 1) ===
        (!Number.isNaN(Number(variant)) ? Number(String(variant)) : 1);

    // TODO: LINT refactor logic in this function
    /**
     * Get JWT snapshots for which to display actions
     * @param {Object} snapshot
     * @return {Array}
     */
    _actionableJwtSnapshots(_snapshot) {
        const snapshots = [];
        snapshots[0] = _snapshot;
        if (snapshots && snapshots.length > 0) {
            return snapshots
                .filter((s) => s)
                .map((snapshot) => ({
                    location: snapshot.delivery_location
                        ? `${snapshot.delivery_location.street_name} ${snapshot.delivery_location.house_number}` +
                          `${snapshot.delivery_location.house_addition}, ${snapshot.delivery_location.city}`
                        : '',
                    payDate:
                        snapshot.verified_snapshot_payload &&
                        snapshot.verified_snapshot_payload.start_date
                            ? ((d) => {
                                  const date = new Date(
                                      new Date(d).getTime() - 12096e5,
                                  );
                                  return `${date.getDate()} ${
                                      [
                                          'januari',
                                          'februari',
                                          'maart',
                                          'april',
                                          'mei',
                                          'juni',
                                          'juli',
                                          'augustus',
                                          'september',
                                          'oktober',
                                          'november',
                                          'december',
                                      ][date.getMonth()]
                                  } ${date.getFullYear()}`;
                              })(snapshot.verified_snapshot_payload.start_date)
                            : '',
                    showAuthorizePayment: this._showAuthorizePayment(snapshot),
                    showBuySmartMeter: this._showBuySmartMeter(snapshot),
                    showNeedAcceptance: this._showNeedAcceptance(snapshot),
                    snapshot,
                    startDate:
                        snapshot.verified_snapshot_payload &&
                        snapshot.verified_snapshot_payload.start_date
                            ? ((d) => {
                                  const date = new Date(d);
                                  return `${date.getDate()} ${
                                      [
                                          'januari',
                                          'februari',
                                          'maart',
                                          'april',
                                          'mei',
                                          'juni',
                                          'juli',
                                          'augustus',
                                          'september',
                                          'oktober',
                                          'november',
                                          'december',
                                      ][date.getMonth()]
                                  } ${date.getFullYear()}`;
                              })(snapshot.verified_snapshot_payload.start_date)
                            : '',
                }))
                .filter(
                    ({
                        showAuthorizePayment,
                        showBuySmartMeter,
                        showNeedAcceptance,
                    }) =>
                        showAuthorizePayment ||
                        showBuySmartMeter ||
                        showNeedAcceptance,
                );
        }
        return [];
    }

    /**
     * If has actions
     * @param {Array} snapshots
     * @return {Boolean}
     */
    _hasActions(snapshots) {
        return snapshots && this._actionableJwtSnapshots(snapshots).length > 0;
    }

    /**
     * If has no actions
     * @param {Array} snapshots
     * @return {Boolean}
     */
    _hasPaymentOrNotHasActions(snapshots) {
        return !this._hasActions(snapshots) || this._hasPayment(snapshots);
    }

    /**
     * If has snapshotPhase after FirstInvoiceSend and before NeverDelivered
     * @param {Object} snapshot
     * @return {Boolean}
     */
    _hasPayment(snapshot) {
        return (
            snapshot &&
            snapshot.snapshot_phase > enums.SnapshotPhaseFirstInvoiceSend &&
            snapshot.snapshot_phase < enums.SnapshotPhaseNeverDelivered
        );
    }

    /**
     * Authorize payment action
     * @param {Object} e
     */
    _authorizePayment(e) {
        if (e && e.model && e.model.item && e.model.item.snapshot) {
            this.__authorizePayment(e.model.item.snapshot);
        }
    }

    /**
     * Open popup to authorize payment page
     * @param {Object} snapshot
     */
    __authorizePayment = (snapshot) => {
        if (snapshot && Array.isArray(snapshot.required_actions)) {
            window.open(
                snapshot.required_actions.find(
                    /**
                     * @param {Object} o
                     * @return {Boolean}
                     */
                    (o) => o.action_type === 1,
                ).href,
                '_blank',
            );
        }
    };

    /**
     * Buy smart meter action
     * @param {Object} e
     */
    _buySmartMeter(e) {
        if (e && e.model && e.model.item && e.model.item.snapshot) {
            this.__buySmartMeter(e.model.item.snapshot);
        }
    }

    /**
     * Open popup to buy smart meter page
     * @param {Object} snapshot
     */
    __buySmartMeter = (snapshot) => {
        if (snapshot && Array.isArray(snapshot.required_actions)) {
            window.open(
                snapshot.required_actions.find(
                    /**
                     * @param {Object} o
                     * @return {Boolean}
                     */
                    (o) => o.action_type === 2,
                ).href,
                '_blank',
            );
        }
    };

    /**
     * Check if we should show locations
     * @param {Object} snapshot
     * @return {Boolean}
     */
    _includeLocations(snapshot) {
        return this._actionableJwtSnapshots(snapshot).length > 0;
    }

    /**
     * Show authorize payment account option
     */
    _continueContractOrder() {
        Router.go(`${routePaths.customerOverview}/${this.snapshot.id}`);
    }

    /**
     * Show authorize payment account option
     * @param {Object?} snapshot
     * @return {Boolean}
     */
    _showAuthorizePayment = (snapshot) => {
        const on = [enums.PaymentMethodWithPaymentAccount];
        return (
            snapshot &&
            !snapshot.has_mandate &&
            Array.isArray(snapshot.required_actions) &&
            snapshot.required_actions.find(
                /**
                 * @param {Object} o
                 * @return {Boolean}
                 */
                (o) => o.action_type === 1,
            ) &&
            snapshot.paymentaccount &&
            snapshot.paymentaccount.id &&
            !Number.isNaN(snapshot.payment_method) &&
            on.indexOf(Number(snapshot.payment_method)) >= 0
        );
    };

    /**
     * Show contract needs acceptance
     * @param {Object?} snapshot
     * @return {Boolean}
     */
    _showNeedAcceptance = (snapshot) =>
        ((snapshot && snapshot.snapshot_phase < 2) ||
            (snapshot.snapshot_phase === 2 && snapshot.error_type !== 1)) &&
        !themeSettings.disableAskResumeOnboarding;

    /**
     * Show show smart meter buy option
     * @param {Object?} snapshot
     * @return {Boolean}
     */
    _showBuySmartMeter = (snapshot) =>
        snapshot &&
        Array.isArray(snapshot.required_actions) &&
        snapshot.required_actions.find(
            /**
             * @param {Object} o
             * @return {Boolean}
             */
            (o) => o.action_type === 2,
        ) &&
        !Number.isNaN(snapshot.smart_meter_choice_type) &&
        !Number.isNaN(snapshot.snapshot_phase);

    /**
     * Navigate to electric delivery graph
     */
    _goToEnergyDeliveryGraph = () => {
        Router.go(routePaths.production);
    };

    /**
     * Navigate to costs graph
     */
    _goToCostsGraph = () => {
        Router.go(routePaths.energyCost);
    };

    /**
     * Navigate to savings graph
     */
    _goToSavingsGraph = () => {
        Router.go(routePaths.energySavings);
    };

    /**
     * Navigate to electric usage graph
     */
    _goToEnergyUsageGraph = () => {
        Router.go(routePaths.electricity);
    };

    /**
     * Navigate to gas usage graph
     */
    _goToGasGraph = () => {
        Router.go(routePaths.gas);
    };

    /**
     * Navigate to work schedule page
     */
    _goToWorkSchedule = () => {
        Router.go(routePaths.energySplitter);
    };

    /**
     * Will check if user has data available this month and else set a text for the user
     * @private
     */
    _getIfUserDataIsAvailable() {
        if (this.monthValueBusy || this.costBusy) {
            this.set(
                'dataNotificationText',
                'We wachten nog op je energiedata...',
            );
        } else {
            this.set('dataNotificationText', null);
        }
    }

    /**
     * Check if contract has not started
     * @param {Date | Null} startDate
     * @return {Boolean}
     */
    _contactHasNotStarted = (startDate) => {
        if (startDate == null) {
            return false;
        }

        return startDate.getTime() > Date.now();
    };

    /**
     * Get the day diffrence from given till current date
     * @param {Date | Null} startDate
     * @return {Number}
     */
    _getDayFromDate = (startDate) => {
        if (startDate == null) {
            return 0;
        }

        const day = parseInt(
            (startDate - Date.now()) / (1000 * 60 * 60 * 24),
            10,
        );
        if (day === 1) {
            return `binnen ${day} dag`;
        }
        if (day < 1) {
            console.warn(
                'the start date has already passed: ',
                startDate.toISOString(),
            );
            return `spoedig`;
        }
        return `binnen ${day} dagen`;
    };

    /**
     * Check if montly cost is not available and contract is started
     * @param {string| Null} CostsForThisMonth
     * @param {string| Null} TotalSavings
     * @param {string| Null} EstimatedSavings
     * @param {string| Null} totalElec
     * @param {string| Null} totalGas
     * @param {string| Null} totalProd
     * @param {Boolean} snapshotPhaseIncorrect
     * @return {Number}
     */
    _monthlyCostsNotAvailable(
        CostsForThisMonth,
        TotalSavings,
        EstimatedSavings,
        totalElec,
        totalGas,
        totalProd,
        snapshotStartDate,
    ) {
        // check if contract has started else prevent showing the dashboard and show readingInformation
        const contractNotStarted =
            this._contactHasNotStarted(snapshotStartDate);
        if (!contractNotStarted) {
            return true;
        }

        return false;
    }

    /**
     * Set the readingInformation by snapshot phase
     * @param {Object} snapshot
     */
    checkSnapshotPhase(snapshot) {
        switch (snapshot.snapshot_phase) {
            case enums.SnapshotPhaseDeliveryStopped ||
                enums.SnapshotPhaseInsightContractStopped:
                this.readingInformation = 'Gestopt met leveren';
                this.snapshotPhaseIncorrect = true;
                break;
            case enums.SnapshotPhaseNeverDelivered ||
                enums.SnapshotPhaseInsightNeverActive:
                this.readingInformation = 'Nooit beleverd';
                this.snapshotPhaseIncorrect = true;
                break;
            case snapshot.snapshot_phase > 900:
                this.snapshotPhaseIncorrect = true;
                break;
            default:
                this.readingInformation = defaultReading;
                this.snapshotPhaseIncorrect = false;
                break;
        }
    }

    /**
     * Check snapshot phase if snapshot is above 900 show snapshot display message
     * @param {Object} snapshot
     * @return {boolean}
     */
    snapshotPhaseCheckToDisplayMessage = (snapshot) => {
        if (snapshot == null) {
            return false;
        }
        const phase = Number(snapshot.snapshot_phase);
        return phase >= 12 || phase > 900;
    };

    /**
     * @param {Snapshot} snapshot
     * @return {boolean}
     */
    hasGas(snapshot) {
        return snapshotHasGas(snapshot);
    }

    /**
     * @param {Snapshot} snapshot
     * @return {boolean}
     */
    hasElec(snapshot) {
        return snapshotHasElec(snapshot);
    }
}
window.customElements.define('ez-dashboard', EzDashboard);
