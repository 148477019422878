import { PolymerElement, html } from '@polymer/polymer/polymer-element';
import { provider } from '@weavelab/frontend-connect';

// Router import
import { Router } from '@vaadin/router';

import watch from 'redux-watch';

// more Router import
import { receiveSelf } from '@async-reducers/authentication';
import { routePaths } from 'helpers/routing';
import {
    formatAddress,
    snapshotAllowedToBelisted,
    snapshotHasElec,
    snapshotHasGas,
} from 'helpers/snapshot';

// This element is connected to the Redux store.
import { store, getAuthToken } from 'data-access/store';
import enums from 'enums';
import { logOut } from 'actions/user';

// @ts-ignore
import { setSelectedSnapshot } from 'actions/snapshot';
import { updateDrawerState } from 'actions/app';
import '@polymer/iron-flex-layout/iron-flex-layout-classes';
// eslint-disable-next-line no-unused-vars
import { Snapshot } from 'types/snapshot';
import { isFutureDate } from 'helpers/dates';
import template from './menu-items.html';
import css from './menu-items.pcss';

// eslint-disable-next-line no-unused-vars
const landerSettings = provider.settings('lander');
const appSettings = provider.settings('app');

/**
 * Menu Item class
 */
export default class EzMenuItems extends PolymerElement {
    /**
     * Constructor of class
     */
    constructor() {
        super();
        /** @type {Boolean} */
        this.insightsAppUser = false;
        /** @type {Boolean} */
        this.requestedAuthToken = false;
        /** @type {Boolean} */
        this.userAuthenticated = false;
        /** @type {Object} */
        this.user = {};
        /** @type {Object} */
        this.snapshot = {};
        /** @type {Boolean} */
        this.showLogout = true;
    }

    /**
     * Get template for menu items
     */
    static get template() {
        const cssTemplate = document.createElement('template');
        cssTemplate.innerHTML = provider.styles(css);
        const htmlTemplate = document.createElement('template');
        htmlTemplate.innerHTML = template;
        return html`<style include="iron-flex">
                ${cssTemplate}
            </style>
            ${htmlTemplate}`;
    }

    /**
     * Gets properties of class
     */
    static get properties() {
        return {
            user: {
                type: Object,
                value: {},
            },
            page: {
                type: String,
            },
            invoiceNotification: {
                type: Boolean,
                value: false,
            },
            payingCustomer: {
                type: Boolean,
                value: true,
            },
            enabledItems: {
                type: Object,
                value: {},
            },
            insightsAppUser: {
                type: Boolean,
                value: false,
            },
            requestedAuthToken: {
                type: Boolean,
                value: false,
            },
            userAuthenticated: {
                type: Boolean,
                value: false,
            },
            snapshot: {
                type: Object,
                value: {},
            },
            snapshotMenuItems: {
                type: Array,
                value: [],
            },
            selectedSnapshotMenuItem: String,
            splitBusiness: {
                type: Boolean,
                value: false,
            },
            chargingSessions: {
                type: Boolean,
                value: false,
            },
            showCurrentPrices: {
                type: Boolean,
                value: false,
            },
            smartChargingMenuEnabled: {
                type: Boolean,
                value: false,
            },
            insightContract: {
                type: Boolean,
                value: false,
            },
        };
    }

    /**
     * Callback for class finished constructing
     */
    ready() {
        super.ready();

        const invoiceDocumentWatcher = watch(store.getState, 'ListDocuments');
        store.subscribe(
            invoiceDocumentWatcher(
                /**
                 * @param {Object} documents
                 */
                (documents) => {
                    if (
                        documents &&
                        documents.data &&
                        documents.data.invoices
                    ) {
                        this.invoiceNotification =
                            documents.data.invoices.filter(
                                (invoice) => invoice.document.status === 1,
                            ).length > 0;
                    }
                    if (
                        documents &&
                        documents.data &&
                        documents.data.snapshots
                    ) {
                        this.payingCustomer =
                            documents.data.snapshots.length > 0;
                    } else {
                        this.payingCustomer = false;
                    }
                },
            ),
        );

        const authTokenWatcher = watch(store.getState, 'GetAuthToken');
        store.subscribe(
            authTokenWatcher(
                /** authToken state watcher
                 * @param {Object} authToken
                 */
                (authToken) => {
                    if (
                        authToken &&
                        authToken.data &&
                        authToken.data.token &&
                        !authToken.busy &&
                        this.requestedAuthToken
                    ) {
                        if (
                            this.user &&
                            this.user.location &&
                            this.user.location.area_code !== '' &&
                            this.user.location.house_number !== ''
                        ) {
                            const url = new URL(window.APP_URL);
                            url.pathname = '/klant/adres/';
                            const auth = JSON.stringify({
                                email: this.user.email,
                                password: authToken.data.token,
                            });
                            url.searchParams.append('auth', btoa(auth));
                            url.searchParams.append(
                                'postalCode',
                                this.user.location.area_code,
                            );
                            url.searchParams.append(
                                'houseNumber',
                                this.user.location.house_number,
                            );
                            url.searchParams.append(
                                'houseNumberSuffix',
                                this.user.location.house_addition,
                            );
                            const redirectURI = `${url.protocol}//${url.host}/#${url.pathname}${url.search}`;
                            if (window.cordova) {
                                console.warn('Auth redirect URI', redirectURI);
                                const browserWindow = window.open(
                                    redirectURI,
                                    '_blank',
                                    'location=yes, usewkwebview=yes',
                                );
                                if (browserWindow) {
                                    browserWindow.addEventListener(
                                        'exit',
                                        () => {
                                            console.warn(
                                                'Browser exit reveiving self',
                                            );
                                            store.dispatch(receiveSelf.run());
                                        },
                                    );
                                }
                            } else {
                                // @ts-ignore
                                // window.location.href = redirectURI;
                                window.open(
                                    redirectURI,
                                    '_blank',
                                    'location=yes, usewkwebview=yes',
                                );
                            }
                        }
                    }
                },
            ),
        );

        const appWatcher = watch(store.getState, 'app');
        store.subscribe(
            appWatcher(
                /**
                 * app state watcher
                 * @param {Object} app
                 */
                (app) => {
                    if (app) {
                        this.insightsAppUser = app.insightsAppUser;
                        this.userAuthenticated = app.userAuthenticated;
                        if (app.insightsAppUser) {
                            this.payingCustomer = false;
                        }
                        if (
                            this.snapshot &&
                            this.snapshot.snapshot_phase &&
                            this.snapshot.snapshot_phase >=
                                enums.SnapshotPhaseDemoContractCreated &&
                            this.snapshot.calculated_product_group_verified &&
                            this.snapshot.calculated_product_group_verified.name
                                .toLowerCase()
                                .includes('independer')
                        ) {
                            this.payingCustomer = false;
                            this.insightsAppUser = false;
                        }
                    }
                },
            ),
        );

        const snapshotsWatcher = watch(store.getState, 'jwtSnapshots');
        store.subscribe(
            snapshotsWatcher(
                // @ts-ignore
                (jwtSnapshots) => {
                    if (
                        jwtSnapshots &&
                        jwtSnapshots.snapshots &&
                        jwtSnapshots.snapshots.length > 0
                    ) {
                        if (this._contextMenu === undefined) {
                            // @ts-ignore
                            const snapshotSelectorContextMenu =
                                this.shadowRoot.querySelector(
                                    'vaadin-context-menu',
                                );
                            // @ts-ignore
                            if (snapshotSelectorContextMenu) {
                                this._contextMenu = snapshotSelectorContextMenu;
                                this._contextMenu.addEventListener(
                                    'item-selected',
                                    (e) => this._changeSelectedSnapshot(e),
                                );
                            }
                        }

                        this.snapshots = jwtSnapshots.snapshots;
                        this.selectedSnapshot = jwtSnapshots.selectedSnapshot;
                        if (this.selectedSnapshot) {
                            this.selectedSnapshotMenuItem =
                                this.selectedSnapshot.id;
                        }
                    } else {
                        this.snapshots = null;
                        this.selectedSnapshot = null;
                    }

                    if (
                        jwtSnapshots?.smartChargingSnapshots?.length > 0 &&
                        appSettings.smartCharging
                    ) {
                        this.smartChargingMenuEnabled = true;
                    } else {
                        this.smartChargingMenuEnabled = false;
                    }
                },
            ),
        );

        const state = store.getState();
        this.busy = state.busy || null;
        const watchBusy = watch(store.getState, 'busy');
        store.subscribe(
            watchBusy(
                /**
                 * @param {String?} busy
                 * @return {void}
                 */
                (busy) => {
                    this.busy = busy;
                },
            ),
        );

        if (this.shadowRoot != null) {
            const classes = this.shadowRoot.querySelectorAll('.menu-item');
            classes.forEach((el) => {
                el.addEventListener('click', (e) => {
                    const { target } = e;
                    if (target instanceof HTMLElement) {
                        let { id } = target;
                        // fallback for monthly-payment menu item
                        if (
                            (id === '' && target.parentElement != null) ||
                            (id === 'monthlyPayment' &&
                                target.parentElement != null)
                        ) {
                            if (
                                target.parentElement.id === 'monthlyPayment' ||
                                id === 'monthlyPayment'
                            ) {
                                this.goToMonthlyPayment();
                                store.dispatch(updateDrawerState(false));
                                return;
                            }
                            // overwrite id with parent element id (above the icon)
                            id = target.parentElement.id;
                        }
                        if (id !== '') {
                            if (id.includes('overzicht')) {
                                id = '';
                            }
                            Router.go(id);
                            // fallback to close the mobile menu
                            store.dispatch(updateDrawerState(false));
                        }
                    }
                });
            });
        }
        if (this._isLocal()) {
            const customerMenuItem = this.$.klant;
            if (customerMenuItem instanceof HTMLElement) {
                customerMenuItem.style.display = 'block';
            }
        }
    }

    snapshotHasNoGas(selectedSnapshot) {
        const hasGas = selectedSnapshot?.verified_snapshot_payload?.has_gas;
        return !hasGas;
    }

    /**
     * returns wheteher it is a demo contract
     * @param {Snapshot} snapshot
     * @return {Boolean} if element should be hidden
     */
    _isDemoContract = (snapshot) => {
        if (
            !snapshot ||
            (Number(snapshot.snapshot_phase) >= 900 &&
                Number(snapshot.snapshot_phase) < 1000)
        ) {
            return true;
        }
        return false;
    };

    /**
     * returns whether it is a demo contract or if the snapshot is not started
     * @param {Snapshot} snapshot
     * @return {Boolean} if element should be hidden
     */
    isDemoOrNotStarted = (snapshot) => {
        if (snapshot == null || Object.keys(snapshot).length === 0) {
            return false;
        }
        const snapshotNotStarted = isFutureDate(
            snapshot.verified_snapshot_payload.start_date,
        );
        if (snapshotNotStarted) {
            return true;
        }

        return this._isDemoContract(snapshot);
    };

    /**
     * navigates the user to the settings page
     */
    _goToSettings = () => {
        Router.go(routePaths.settings);
    };

    /**
     * navigates the user to the work schedule page
     */
    _goToWorkSchedule = () => {
        Router.go(routePaths.energySplitter);
    };

    goToCurrentPrices = () => {
        Router.go(routePaths.hourPrices);
    };

    goToMonthlyPayment = () => {
        Router.go(routePaths.editMonthlyPaymentSettings);
    };

    /**
     * _openBecomeCustomerFlow
     * Redirect the user to the onboarding flow (/klant)
     * if user is on the app version we request an auth token
     * the user will then be redirected to the phones browser
     * and will login automaticly with the auth token
     */
    _openBecomeCustomerFlow() {
        if (this.user && this.user.id) {
            try {
                store.dispatch(
                    getAuthToken.request({
                        user_id: this.user.id,
                    }),
                );
                this.requestedAuthToken = true;
            } catch (error) {
                console.warn('open become customer flow failed: ', error);
            }
        }
    }

    /**
     * Returns proper class for menu items
     * @param {String} page name of current page
     * @param {String} name menu item
     * @return {String} class name
     */
    _isSelected = (page, name) => {
        if (page === name) {
            return 'selected';
        }
        return '';
    };

    /**
     * Toggle snapshot menu
     * @private
     */
    _toggleSnapshotSelectorMenu() {
        const address = this.shadowRoot.querySelector('.snapshots-list-mobile');
        address.classList.toggle('opened');
    }

    /**
     * Checks if deployed locally
     * @return {Boolean} boolean indiciating if environment is local or not
     */
    _isLocal = () => window.APP_URL === 'http://localhost';

    /**
     * _changeSelectedSnapshot handles item-selected event for context menu
     * @param {Event} e
     */
    _changeSelectedSnapshot(e) {
        // @ts-ignore
        const snap = e.model.item;
        this._toggleSnapshotSelectorMenu();
        store.dispatch(setSelectedSnapshot(snap));
    }

    /**
     * Returns outer wrapper based on page
     * @param {Array} snapshots
     * @return {Boolean}
     */
    _multipleSnapshots = (snapshots) => snapshots?.length > 1;

    /**
     * Get the current chosen snapshot and compare it to the iterated one
     * @param {string} snapshotId
     * @return {string}
     * @private
     */
    _seeIfSnapshotIsCurrent(snapshotId, selectedSnapshotMenuItem) {
        if (selectedSnapshotMenuItem === snapshotId) {
            return 'current';
        }
        return '';
    }

    /**
     * Get the amount of available snapshotMenuItems
     * @param {Array} snapshotMenuItems
     * @return {number|*}
     * @private
     */
    _getSnapshotAmount(snapshotMenuItems) {
        return snapshotMenuItems.length > 1 || 0;
    }

    /**
     * @return {Boolean}
     */
    _showWorkSchedule = () => {
        if (landerSettings && landerSettings.workSchedule) {
            return landerSettings.workSchedule;
        }
        return false;
    };

    _snapshotAllowedInList(snapshot) {
        return snapshotAllowedToBelisted(snapshot);
    }

    /**
     * logout function
     */
    logoutUser = () => {
        store.dispatch(logOut());
    };

    /**
     * Format the address if the street name extends a certain length.
     * @param {Object} address
     * @param {string} address.area_code
     * @param {string} address.city
     * @param {string} address.house_addition
     * @param {number} address.house_number
     * @param {string} address.id
     * @param {string} address.street_name
     * @return {string}
     */
    _formatAddress = (address) => formatAddress(address);

    /**
     * @param {Snapshot} selectedSnapshot
     * @return boolean
     */
    hasGas(selectedSnapshot) {
        return snapshotHasGas(selectedSnapshot);
    }

    /**
     * @param {Snapshot} selectedSnapshot
     * @return boolean
     */
    hasElec(selectedSnapshot) {
        return snapshotHasElec(selectedSnapshot);
    }
}

window.customElements.define('ez-menu-items', EzMenuItems);
