// import { setNotification } from './../actions/notification';
// import { Order } from './../actions/offer';
// import { setNotification } from '../actions/notification';
// import { Snapshot } from './../types/snapshot';
import { takeLatest, ForkEffect } from 'redux-saga/effects';
import { SetLocationAction, SET_LOCATION } from '../actions/routing';
// import { store } from 'data-access/store';
// import enums from 'src/enums';
// import { Snapshot } from 'types/snapshot';
// import { Route } from '@vaadin/router';

/**
 * Watcher for REQUEST_GRAPH_SUCCESS action
 */
export function* watchSnapshotVast(): IterableIterator<ForkEffect> {
    yield takeLatest(SET_LOCATION, routeUpdated);
}

/**
 * If route (location) gets updated updated.
 */
const routeUpdated: (result: SetLocationAction) => void = (
    result: SetLocationAction,
): void => {
    const { location } = result;
    const page = location.pathname.split('/')[1];
    // check if the page is klant (onboarding)
    // let showNotification = false;
    if (location.route && page.toLowerCase() === 'klant') {
        // TODO: Make me alive in this following pbi https://lab.weave.nl/energyzerocl/energyzero-frontend/-/issues/836
        // Where we show this notification bases on the vendor config
        // In async-reducers/vendorMinStartDate.ts we already fetch this data in prep
        // showNotification = checkIfUserContractIsVastAndActive(
        //     location.route.component,
        //     location.route,
        // );
    }
};

// // checks if the user is logged in and the page if customer-form or order-overview
// const checkIfUserContractIsVastAndActive = (
//     componentName: string | undefined,
//     route: Route,
// ) => {
//     // check if customer is signed in.
//     const bearer = localStorage.getItem('auth');
//     if (bearer === null) {
//         return false;
//     }

//     // check if the component gets rendered
//     const allowedComponentNames = ['customer-form', 'order-overview'];
//     if (
//         (componentName && allowedComponentNames.includes(componentName)) ||
//         route.path.includes('/overview/')
//     ) {
//         return checkIfUserRenewsHisSnapshot();
//     }

//     return false;
// };

// // checks if a vast snapshots gets renewed and check if the address already exists
// const checkIfUserRenewsHisSnapshot = (): boolean => {
//     const order = store.getState().order;
//     if (checkIfDurationTypeIsNotFlex(order)) {
//         return checkActivePostalcodeInSnapshots(order);
//     }
//     return false;
// };

// // checks if the durationType is not flex (monthly)
// const checkIfDurationTypeIsNotFlex = (order: Order): boolean => {
//     const { offer } = order;
//     const offers = store.getState().offers;
//     if (offers[offer.id]) {
//         const durationType =
//             offers[offer.id].product_group_offering.configurations[offer.index]
//                 .duration_type;
//         return durationType !== enums.ProductDurationMonth;
//     }

//     return false;
// };

// const checkActivePostalcodeInSnapshots = (order: Order): boolean => {
//     const postalCode = order.postalCode.toLowerCase();
//     const houseNumber =
//         typeof order.houseNumber === 'string'
//             ? // @ts-ignore
//               order.houseNumber.toLowerCase()
//             : order.houseNumber;
//     const houseNumberSuffix =
//         typeof order.houseNumberSuffix === 'string'
//             ? order.houseNumberSuffix.toLowerCase()
//             : order.houseNumberSuffix;
//     const sortedSnapshots = getSortedSnapshots();
//     // check each snapshot if the address exist and is active
//     for (let i = 0; i < sortedSnapshots.length; i++) {
//         const snapshot = sortedSnapshots[i];
//         const deliveryLocation = snapshot.delivery_location;
//         const deliveryAddition =
//             typeof deliveryLocation.house_addition === 'string'
//                 ? deliveryLocation.house_addition.toLowerCase()
//                 : deliveryLocation.house_addition;
//         if (
//             deliveryLocation.area_code.toLowerCase() === postalCode &&
//             deliveryLocation.house_number === houseNumber &&
//             deliveryAddition === houseNumberSuffix
//         ) {
//             const snapshotPhase = Number(snapshot.snapshot_phase);
//             if (Number.isNaN(snapshotPhase)) {
//                 console.warn('snapshotPhase is nan');
//                 return false;
//             }
//             if (
//                 snapshotPhase !== enums.SnapshotPhaseDeliveryStopped &&
//                 snapshotPhase < enums.SnapshotPhaseDemoContractCreated
//             ) {
//                 const snapshotEndDate = new Date(
//                     snapshot.verified_snapshot_payload.start_date,
//                 );
//                 const today = new Date();
//                 if (snapshotEndDate.getTime() < today.getTime()) {
//                     return true;
//                 }
//             }
//         }
//     }

//     return false;
// };

// const getSortedSnapshots = (): Snapshot[] => {
//     const snapshotsObj = store.getState().jwtSnapshots;
//     const snapshots = snapshotsObj.snapshots;
//     if (snapshots == null || snapshots.length === 0) {
//         return [];
//     }
//     return snapshotsObj.sortedByLastestSnapshot;
// };
