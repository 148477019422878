import {
    css,
    customElement,
    LitElement,
    property,
    PropertyValues,
} from 'lit-element';
import { connect, watch } from 'lit-redux-watch';
import { store } from '../../../store';
import { hvAvailableIcon, hvIconFamily, hvIconTone, Icon } from './icon';

@customElement('hv-icon')
export class HvIcon extends connect(store)(LitElement) {
    @property({ type: String })
    public name?: hvAvailableIcon;

    @property({ type: String })
    public tone: hvIconTone = 'filled';

    @watch('setIconFamily.data.family')
    protected family?: hvIconFamily;

    @property({ type: Boolean })
    private loaded: boolean = false;

    @property({ type: Object })
    private icon?: Icon;

    protected updated(changedProperties: PropertyValues): void {
        if (changedProperties.has('family') && this.family && !this.loaded) {
            this.icon = new Icon(
                this.name as hvAvailableIcon,
                this.family as hvIconFamily,
                this.tone,
                this.className,
            );
            this.icon.init(() => {
                // only fire an update when font awesome, and do this here
                // because it is not class scoped, but lit scoped
                if (this.family === 'font-awesome') this.requestUpdate();
            });
            this.loaded = true;
        }
    }

    render() {
        return this.icon?.create();
    }

    static get styles() {
        return css`
            :host {
                --hv-icon-color: #000;
                --hv-icon-font-size: 1em;

                width: var(--hv-icon-font-size);
                height: var(--hv-icon-font-size);

                /* these attributes are required by Google material icons font */
                display: inline-block;
                vertical-align: middle;
                user-select: none;
            }

            svg {
                fill: var(--hv-icon-color);
            }

            .m-i,
            .m-i-outlined,
            .m-i-sharp,
            .m-i-round,
            .m-i-two-tone {
                /* these attributes are required by Google material icons font */
                font-weight: normal;
                font-style: normal;
                font-size: var(--hv-icon-font-size);
                line-height: 1;
                color: var(--hv-icon-color);
                letter-spacing: normal;
                text-transform: none;
                display: inline-block;
                width: 100%;
                height: 100%;
                white-space: nowrap;
                word-wrap: normal;
                direction: ltr;
                -webkit-font-feature-settings: 'liga';
                -webkit-font-smoothing: antialiased;
            }

            .m-i {
                font-family: 'Material Icons';
            }
            .m-i-outlined {
                font-family: 'Material Icons Outlined';
            }
            .m-i-sharp {
                font-family: 'Material Icons Sharp';
            }
            .m-i-round {
                font-family: 'Material Icons Round';
            }
            .m-i-two-tone {
                font-family: 'Material Icons Two Tone';
            }
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        'hv-icon': HvIcon;
    }
}
