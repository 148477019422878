import {
    customElement,
    LitElement,
    TemplateResult,
    html,
    css,
    CSSResult,
} from 'lit-element';
import { whitelabel } from '@weavelab/whitelabel';
import '../atoms/Popup';

@customElement('ez-spinner')
@whitelabel({ name: 'spinner' })
export class Spinner extends LitElement {
    @whitelabel()
    public static get styles(): CSSResult {
        return css``;
    }

    public render = (): TemplateResult => html`
        <ez-popup>
            <div class="Popup loader" style="width: 300px;">
                <div class="PopupLoader"></div>
                <div class="PopupMessage"><slot></slot></div>
            </div>
        </ez-popup>
    `;
}

declare global {
    interface HTMLElementTagNameMap {
        'ez-spinner': Spinner;
    }
}
