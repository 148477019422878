import { PolymerElement, html } from '@polymer/polymer/polymer-element';
import { provider } from '@weavelab/frontend-connect';
// @ts-ignore
import watch from 'redux-watch';
import { setShowMenu } from '@actions/menu';
import { checkForSnapshotsWithACollective } from 'helpers/collective';
import { routePaths } from 'data-access/router';
import { uiCalculatorInteraction } from 'actions/ui';
import { numberFormat } from '../../../../helpers/currency.ts';
import css from './calculation-tool.pcss';
import template from './calculation-tool.html';

import '@polymer/paper-input/paper-input';

import '@polymer/paper-button/paper-button';
import '@polymer/iron-flex-layout/iron-flex-layout-classes';
import '@polymer/paper-checkbox/paper-checkbox';

import '../../../atoms/RadioButtonGroup';
import '../../../general/ez-dropdown-menu/dropdown-menu';
import '../../../molecules/Spinner';
import '../../../elements/modal/ModalComponent';
import '../../../elements/base/input-component/InputComponent';
import '../../../elements/dropdown-selector-component/DropdownSelector';
import '../ez-header/EzHeaderVideo';
import '../../../elements/tooltip/Tooltip';
import {
    ElecCapacities,
    GasCapacities,
} from '../../../capacity/CapacityComponent';
import { SelectCapacityListner } from '../../../../helpers/SelectCapacityListner';
import {
    retrieveOffers,
    resetOffer,
    setTransmissionTypes,
} from '../../../../actions/offer';
import { resetCalculator } from '../../../../actions/calculator';
import { dutchPostalCodesRegex } from '../../../../helpers/regex';
// This element is connected to the Redux store.
import { notifyEdsnLive, store } from '../../../../store';
import {
    clearCollective,
    setChargePoleUsage,
    setCollective,
    setElectricity,
    setElectricityProductionEstimate,
    setElectricityUsageEstimate,
    setElectricityUsageFactorEstimate,
    setGas,
    setGasUsageEstimate,
    setHouseNumber,
    setHouseNumberSuffix,
    setPostalCode,
    setProposition,
    setSolarMail,
    setEnergysplitter,
    resetOrder,
    addLabel,
    setGeoInformation,
} from '../../../../actions/order';
import { resetEnroll } from '../../../../actions/enroll';

import enums from '../../../../enums';
import { requestCollectives } from '../../../../actions/collective';
import {
    elecCapacityInfo,
    gasCapacityInfo,
} from '../helpers/tooltipInformation';
import settings from '../../../../internationalization/settings';
import {
    calculateEnergyEstimationsClicked,
    calculateOfferClicked,
    gasAvailableClicked,
    gasUsageChanged,
    helpWithUsageEstimationAmountInHouseholdsClicked,
    helpWithUsageEstimationClicked,
    helpWithUsageEstimationTypeOfBuildingClicked,
    helpWithUsageEstimationTypeOfProductsClicked,
    houseNumberChanged,
    noGasAvailableClicked,
    noPowerAvailableClicked,
    numberSuffixChanged,
    postalCodeChanged,
    powerAvailableClicked,
    powerDeliveryAmountOfkWhsChanged,
    powerDeliveryAmountOfkWhsClicked,
    powerDeliveryAmountOfPanelsChanged,
    powerDeliveryAmountOfPanelsClicked,
    powerDeliveryQuestionNoClicked,
    powerDeliveryQuestionYesClicked,
    powerUsageChanged,
} from '../../../../gtm-middleware/calculator';
import { focusPaperInputEventIsInvalid } from '../helpers/paperInputEvent';

const themeSettings = provider.settings('calculation-tool');

/**
 * Calculation tool class
 */
export default class EzCalculationTool extends PolymerElement {
    /** @typedef {import('../../../../actions/user').User} User */

    /** @typedef {import('../reducers/order').Order} Order */

    /**
     * Gets properties of class
     */
    static get properties() {
        return {
            user: {
                type: Object,
                value: {},
            },
            order: {
                type: Object,
                value: {
                    chargePoleUsage: null,
                    collective: null,
                    electricity: null,
                    electricityProductionEstimate: null,
                    electricityUsageEstimate: null,
                    electricityUsageFactorEstimate: null,
                    gas: false,
                    gasUsageEstimate: null,
                    houseNumber: null,
                    houseNumberSuffix: null,
                    offer: null,
                    postalCode: null,
                    proposition: null,
                    redirectType: null,
                    transmissionTypeElec: null,
                    transmissionTypeGas: null,
                },
            },
            geocode: {
                type: Object,
            },

            estimatedElekProd: {
                type: Number,
            },
            numberOfSolarPannels: {
                type: Number,
                observer: '_numberOfSolarPannelsChanged',
            },
            collectiveChecked: {
                type: Boolean,
                value: false,
                observer: '_collectiveCheckedValueChanged',
            },
            prodChecked: {
                type: Boolean,
                value: false,
                observer: '_prodCheckedValueChanged',
            },
            chargingPoleChecked: {
                type: Boolean,
                value: true,
            },
            fillProdBySolarPannels: {
                type: Boolean,
                value: false,
            },
            collectives: {
                type: Array,
            },
            selectedCollectiveID: {
                type: Object,
                observer: '_selectedCollectiveChanged',
            },

            showHelpCollectives: {
                type: Boolean,
                value: false,
            },
            showHelpProd: {
                type: Boolean,
                value: false,
            },
            showHelpChargingPole: {
                type: Boolean,
                value: false,
            },
            showHelpPowerUsage: {
                type: Boolean,
                value: false,
            },
            showEstimationTool: {
                type: Boolean,
                value: false,
            },
            estimationToolResidenceType: {
                type: Array,
                value: [
                    { name: 'Tussenwoning', id: 0 },
                    { name: 'Hoekwoning', id: 1 },
                    { name: 'Appartement', id: 2 },
                    { name: '2-onder-1 kap', id: 3 },
                    { name: 'Vrijstaand', id: 4 },
                ],
            },
            houseAdditions: {
                type: Array,
                value: [],
            },
            houseAdditionsSelectorHelperText: {
                type: String,
                value: 'Selecteer',
            },
            selectedHouseAddition: {
                type: Object,
                observer: '_houseNumberSuffixValueObserver',
            },
            disableHouseAddition: {
                type: Boolean,
                value: false,
            },
            selectedEstimationToolResidenceTypeID: {
                type: Object,
                value: { name: 'Tussenwoning', id: 0 },
                observer: '_selectedEstimationToolResidenceTypeIDObserver',
            },
            estimationToolResidents: {
                type: Array,
                value: [
                    { name: '1 persoon', id: 0 },
                    { name: '2 personen', id: 1 },
                    { name: '3 personen', id: 2 },
                    { name: '4 personen', id: 3 },
                    { name: '5+ personen', id: 4 },
                ],
            },
            selectedEstimationToolResidentsID: {
                type: Object,
                value: { name: '1 persoon', id: 0 },
                observer: '_selectedEstimationToolResidentsIDObserver',
            },
            estimationToolProductType: {
                type: Array,
                value: [
                    { name: 'Stroom en gas', id: 0 },
                    { name: 'Alleen stroom', id: 1 },
                    { name: 'Alleen gas', id: 2 },
                ],
            },
            selectedEstimationToolProductTypeID: {
                type: Object,
                value: { name: 'Stroom en gas', id: 0 },
                observer: '_selectedEstimationToolProductTypeIDObserver',
            },
            transmissionTypeGas: {
                type: Number,
                value: 20111,
            },
            transmissionTypeElec: {
                type: Number,
                value: 10211,
            },
            highLow: {
                type: Boolean,
                value: false,
            },
            params: {
                type: Object,
            },
            elekEstimation: {
                type: Number,
                observer: 'elekEstimationChanged',
            },
            elekHigh: {
                type: Number,
            },
            elekLow: {
                type: Number,
            },
            offer: {
                type: Object,
            },
            elecUsageCap: {
                type: Number,
                value: 99999,
            },
            gasUsageCap: {
                type: Number,
                value: 99999,
            },
            usageCapErrorSuffix: {
                type: String,
            },
            houseNumberPrevious: {
                type: String,
            },
            postalCodePrevious: {
                type: String,
            },
        };
    }

    /**
     * Constructor of the calculation tool
     */
    constructor() {
        super();
        this.asset = provider.asset;
        /** @type {User} */
        this.user = {};
        /** @type {Order} */
        this.order;
        /** @type {Array} */
        this.collectives = [];
        /** @type {Object} */
        this.geocode = {};
        /** @type {Boolean} */
        this.collectiveChecked = false;
        /** @type {Object} */
        this.selectedCollectiveID = {};
        /** @type {Number} */
        this.numberOfSolarPannels = 0;
        /** @type {Boolean} */
        this.showHelpCollectives = false;
        /** @type {Boolean} */
        this.showHelpProd = false;
        /** @type {Boolean} */
        this.showEstimationTool = false;

        /** @type {Boolean} */
        this.highLow = false;
        /** @type {String} */
        this.subRoute = '';
        /** @type {String} */
        this.secSubRoute = '';
        /** @type {Object} */
        this.offer = {
            error: '',
        };

        /** @type {Array} */
        this.sellingPoints =
            themeSettings && Array.isArray(themeSettings.sellingPoints)
                ? themeSettings.sellingPoints
                : [];

        /** @type {String} */
        this.submitText =
            themeSettings && themeSettings.submitButtonString
                ? themeSettings.submitButtonString
                : 'Versturen';

        /** @type {Boolean} */
        this.showCollective =
            themeSettings && themeSettings.hideCollective != null
                ? !themeSettings.hideCollective
                : false;

        /** @type {Boolean} */
        this.showDualMeters = false;

        /** @type {Boolean} */
        this.displayEstimationTool = themeSettings
            ? !themeSettings.hideEstimationTool
            : true;

        /** @type {Boolean} */
        this.showExtraInfo = themeSettings
            ? !themeSettings.hideExtraInfo
            : true;

        /** @type {String} */
        this.electricityLabelString =
            themeSettings && themeSettings.setElectricityLabelString
                ? themeSettings.setElectricityLabelString
                : '';

        /** @type {String} */
        this.electricityOffString =
            themeSettings && themeSettings.setElectricityOffString
                ? themeSettings.setElectricityOffString
                : '';

        /** @type {String} */
        this.electricityOnString =
            themeSettings && themeSettings.setElectricityOnString
                ? themeSettings.setElectricityOnString
                : '';

        /** @type {String} */
        this.electricityProductionLabelString =
            themeSettings && themeSettings.setElectricityProductionLabelString
                ? // @ts-ignore
                  themeSettings.setElectricityProductionLabelString
                : '';

        /** @type {String} */
        this.electricityProductionOffString =
            themeSettings && themeSettings.setElectricityProductionOffString
                ? // @ts-ignore
                  themeSettings.setElectricityProductionOffString
                : '';

        /** @type {String} */
        this.electricityProductionOnString =
            themeSettings && themeSettings.setElectricityProductionOnString
                ? // @ts-ignore
                  themeSettings.setElectricityProductionOnString
                : '';

        /** @type {String} */
        this.electricityProductionTitle =
            themeSettings && themeSettings.setElectricityProductionTitle
                ? // @ts-ignore
                  themeSettings.setElectricityProductionTitle
                : '';

        /** @type {String} */
        this.electricityTitle =
            themeSettings && themeSettings.setElectricityTitle
                ? themeSettings.setElectricityTitle
                : '';

        /** @type {String} */
        this.estimationToolProductTypeLabel =
            themeSettings && themeSettings.setEstimationToolProductTypeLabel
                ? themeSettings.setEstimationToolProductTypeLabel
                : '';
        /** @type {String} */
        this.setEstimationToolButtonString =
            themeSettings && themeSettings.setEstimationToolButtonString
                ? themeSettings.setEstimationToolButtonString
                : 'Energieverbruik inschatten met de woonsituatie';
        /** @type {String} */
        this.setEstimationToolModalButtonString =
            themeSettings && themeSettings.setEstimationToolModalButtonString
                ? themeSettings.setEstimationToolModalButtonString
                : 'Bereken energieverbruik';
        /** @type {String} */
        this.estimationToolResidenceTypeLabel =
            themeSettings && themeSettings.setEstimationToolResidenceTypeLabel
                ? themeSettings.setEstimationToolResidenceTypeLabel
                : '';

        /** @type {String} */
        this.estimationToolResidentsLabel =
            themeSettings && themeSettings.setEstimationToolResidentsLabel
                ? themeSettings.setEstimationToolResidentsLabel
                : '';

        /** @type {String} */
        this.estimationToolTitle =
            themeSettings && themeSettings.setEstimationToolTitle
                ? themeSettings.setEstimationToolTitle
                : '';

        /** @type {String} */
        this.gasLabelString =
            themeSettings && themeSettings.setGasLabelString
                ? themeSettings.setGasLabelString
                : '';

        /** @type {String} */
        this.gasOffString =
            themeSettings && themeSettings.setGasOffString
                ? themeSettings.setGasOffString
                : '';

        /** @type {String} */
        this.gasOnString =
            themeSettings && themeSettings.setGasOnString
                ? themeSettings.setGasOnString
                : '';

        /** @type {String} */
        this.gasTitle =
            themeSettings && themeSettings.setGasTitle
                ? themeSettings.setGasTitle
                : '';

        /** @type {Number} */
        this.elecUsageCap =
            themeSettings && themeSettings.setElecUsageCap
                ? themeSettings.setElecUsageCap
                : 99999;

        /** @type {Number} */
        this.gasUsageCap =
            themeSettings && themeSettings.setGasUsageCap
                ? themeSettings.setGasUsageCap
                : 99999;

        /** @type {String} */
        this.usageCapErrorSuffix =
            themeSettings && themeSettings.setUsageCapErrorSuffix
                ? themeSettings.setUsageCapErrorSuffix
                : undefined;

        /** @type {Boolean} */
        this.prodTypePickerDropDownOpen = false;

        /** @type {Boolean} */
        this.electricityChargingPoleEnabled =
            !!themeSettings?.electricityChargingPoleEnabled;
        this.chargingPoleChecked = this.electricityChargingPoleEnabled;

        this.electricityChargingPoleInfoText =
            themeSettings?.electricityChargingPoleInfoText;

        this.electricityChargingPoleInfoTextEnabled =
            !!themeSettings?.electricityChargingPoleInfoText;

        this.showUsageInfo =
            themeSettings.showUsageInfo !== undefined
                ? themeSettings.showUsageInfo
                : this.showExtraInfo;

        this.vendorPhoneNumber = settings.phoneNumber;

        /** @type {Number} */
        this.defaultChargePoleValue = themeSettings?.defaultChargePoleUsage
            ? themeSettings.defaultChargePoleUsage
            : 4000;

        this.kwhInformation = themeSettings?.kwhInformation
            ? themeSettings.kwhInformation
            : `Vul hier in hoeveel kWh je opwekt
        in één jaar. Je totale opwekking van een jaar wordt verrekend met je afgenomen
        verbruik.`;

        this.chargingPoleInformation = themeSettings?.chargePoleInformation
            ? themeSettings.chargePoleInformation
            : `Vul ‘ja’ in als je thuis een eigen laadpunt hebt.
        Ook als deze in aanvraag is of nog in behandeling.
        Vul ‘nee’ in wanneer je geen eigen laadpunt hebt, of krijgt.
        Let op: als je een eigen laadpunt hebt en het verwachte aantal kWh hiervoor opgeeft,
        dan heeft dat géén invloed op de hoogte van je maandbedrag.
        Het verbruik op je eigen laadpunt wordt apart verrekend tussen LeasePlan Energy en de leasemaatschappij.`;

        this.elecCapInfo = themeSettings?.elecCapacityInfo
            ? themeSettings.elecCapacityInfo
            : elecCapacityInfo;
        this.gasCapInfo = themeSettings?.gasCapacityInfo
            ? themeSettings.gasCapacityInfo
            : gasCapacityInfo;

        /** @type {String} */
        this.editURL = 'https://www.mijndomein.nl/energie/';

        /** @type {String} */
        this.editURLStaging = 'https://www.geenlinknaarproductie.nl';
        store.dispatch(requestCollectives(window.VENDOR));

        this.allVendors = 1;
        this.mijndomeinVendor = 2;
        this.updateGasEstimation = false;
        this.gasEstimation = 0;
        this.defaultEstimationFactor = 0.6;

        // set the default elec and gas capacity
        this.electricityCapacity = ElecCapacities[1].id;
        this.gasCapacity = GasCapacities[0].id;
        this.capacityEnabled = !!themeSettings?.capacityEnabled;
        // fallback to check if the gas/electricity are selected
        this.gasSelected = !this.capacityEnabled;
        this.electrictiySelected = !this.capacityEnabled;

        // By default you only have the info of a normal electricity input.
        // When charging pole is enabled we also add the information about the charging pole
        const chargingPoleText = this.electricityChargingPoleEnabled
            ? ' exclusief eventueel het verbruik van je privé laadpunt'
            : '';
        const electricityInformationText =
            themeSettings?.electricityInformationText
                ? themeSettings.electricityInformationText
                : 'Vul hier het verbruik van je woning in';

        /** @type {String} */
        this.helpPowerUsageToolTip = themeSettings?.helpPowerUsageToolTip
            ? themeSettings.helpPowerUsageToolTip
            : `${electricityInformationText}${chargingPoleText}.`;

        this.selectedHouseAddition = {};
        this.houseAdditions = [];
        this.houseAdditionsSelectorHelperText = 'Geen';
        this.disableHouseAddition = false;
    }

    /**
     * On element ready
     */
    ready() {
        super.ready();
        /** @type {Object} */
        const state = store.getState();
        const watchOrder = watch(store.getState, 'order');
        store.subscribe(
            watchOrder(
                /**
                 * @param {Object} order
                 * @return {void}
                 */
                (order) => {
                    this.order = { ...order };
                    // Only update the gas / elek estimation if calculation has been clicked
                    if (this.updateGasEstimation && order.gasUsageEstimate) {
                        this.updateGasEstimation = false;
                        this.gasEstimation = 0;
                        this.gasEstimation = order.gasUsageEstimate;
                    }
                    if (order.electricityUsageEstimate) {
                        this.elekEstimation = 0;
                        this.elekEstimation = order.electricityUsageEstimate;
                    }
                    if (!this.order.electricity) {
                        this.hasElectricity = true;
                    }
                },
            ),
        );

        const watchOffer = watch(store.getState, 'offers');
        store.subscribe(
            watchOffer(
                /**
                 * @param {Object} order
                 * @return {void}
                 */
                (offers) => {
                    this.offer = { ...offers };
                },
            ),
        );

        this.geocode = state.geocode || {};
        const watchGeocode = watch(store.getState, 'geoCodeEDSNCheck');
        store.subscribe(
            watchGeocode(
                /**
                 * @param {Object} geocode
                 * @return {void}
                 */
                (geocode) => {
                    this.geocode = { ...geocode };
                    const { order } = this;
                    if (this.geocode.data != null && order != null) {
                        if (
                            (order.electricity &&
                                order.electricityUsageEstimate > 0) ||
                            (order.gas && order.gasUsageEstimate > 0)
                        ) {
                            // DO NOT SUBMIT IF NO PARAMS ARE SET FROM URL
                            if (
                                this.params &&
                                (Object.keys(this.params).length > 0 ||
                                    this.params.has('has_elec') ||
                                    this.params.has('has_gas'))
                            ) {
                                this._calculateMonthlyCosts();
                            }
                        }
                        if (
                            geocode &&
                            geocode.data &&
                            geocode.house_addition &&
                            geocode.data.house_additions.length === 0
                        ) {
                            this.geocode.data.houseNumberSuffix = null;
                        }
                        this.geocode = JSON.parse(JSON.stringify(this.geocode));
                        store.dispatch(setGeoInformation(this.geocode.data));
                        store.dispatch(
                            setHouseNumberSuffix(
                                this.geocode.data.houseNumberSuffix !==
                                    undefined
                                    ? this.geocode.data.houseNumberSuffix
                                    : '',
                            ),
                        );
                    }
                },
            ),
        );
        this.calculator = state.calculator || {};
        const watchCalculator = watch(store.getState, 'calculator');
        store.subscribe(
            watchCalculator(
                /**
                 * @param {Object} calculator
                 * @return {void}
                 */
                (calculator) => {
                    this.calculator = { ...calculator };
                    if (
                        this.offer.error &&
                        this.offer.error.response &&
                        this.offer.error.response.data &&
                        this.offer.error.response.data.detail.includes(
                            'EDSN is not available for requests',
                        )
                    ) {
                        this.shadowRoot.querySelector('modal-component').open();
                    }
                },
            ),
        );
        this.collectives =
            state.collectives && Array.isArray(state.collectives.data)
                ? state.collectives.data
                : [];
        const watchCollectives = watch(store.getState, 'collectives.data');
        store.subscribe(
            watchCollectives(
                /**
                 * @param {Object} collectives
                 * @return {void}
                 */
                (collectives) => {
                    this.collectives = Array.isArray(collectives)
                        ? [...collectives]
                        : [];
                    if (
                        this.secSubRoute &&
                        this.subRoute === 'collective' &&
                        this.collectives.filter(
                            (c) => c && c.id === this.secSubRoute,
                        ).length > 0
                    ) {
                        this.selectedCollectiveID = this.collectives.find(
                            (c) => c && c.id === this.secSubRoute,
                        );
                    }
                },
            ),
        );
        this.user = store.getState().user;
        const watchUser = watch(store.getState, 'user');
        store.subscribe(
            watchUser(
                /**
                 * @param {Object} user
                 */
                (user) => {
                    this.user = JSON.parse(JSON.stringify(user));
                },
            ),
        );
        const watchGeo = watch(store.getState, 'geoCodeEDSNCheck');
        store.subscribe(
            watchGeo((geo) => {
                if (!geo.data) {
                    this.houseAdditionsSelectorHelperText = 'Geen';
                    this.disableHouseAddition = false;
                    return;
                }
                if (
                    geo.data.house_additions &&
                    geo.data.house_additions.length === 0
                ) {
                    this.houseAdditionsSelectorHelperText = 'Geen';
                    this.houseAdditions = [];
                    this.disableHouseAddition = true;
                    return;
                }
                this.houseAdditionsSelectorHelperText = 'Selecteer';
                this.disableHouseAddition = false;
                this.houseAdditions = [{ name: 'Geen', id: 0 }];
                this.houseAdditions = [
                    ...this.houseAdditions,
                    ...geo.data.house_additions.map((addition, i) => ({
                        name: addition,
                        id: i,
                    })),
                ];
                const suffix = this.checkIfParamsHasKeyInBothVersions(
                    this.params,
                    'houseNumberSuffix',
                );
                if (suffix == null) {
                    this.selectedHouseAddition = this.houseAdditions[0];
                    return;
                }
                this.houseAdditions.forEach((houseAddition) => {
                    if (houseAddition.name === suffix) {
                        this.selectedHouseAddition = houseAddition;
                    }
                });
            }),
        );
    }

    /**
     * Debounce ui interaction events to make sure we only dispatch input after a user stops typing for two seconds
     */
    UICalculatorInteractionPowerUsageChanged = () => {
        store.dispatch(uiCalculatorInteraction.run(powerUsageChanged(true)));
    };

    UICalculatorInteractionGasUsageChanged = () => {
        store.dispatch(uiCalculatorInteraction.run(gasUsageChanged(true)));
    };

    UICalculatorInteractionKWHSChanged = () => {
        store.dispatch(
            uiCalculatorInteraction.run(powerDeliveryAmountOfkWhsChanged(true)),
        );
    };

    UICalculatorInteractionPanelsChanged = () => {
        store.dispatch(
            uiCalculatorInteraction.run(
                powerDeliveryAmountOfPanelsChanged(true),
            ),
        );
    };

    /**
     * if elekEstimation is changed set high low distribution
     * @param {number} elekEstimation
     * @param {number | undefined} oldValue
     */
    elekEstimationChanged(elekEstimation) {
        if (this.highLow) {
            this.elekHigh = Math.round(
                elekEstimation * this.defaultEstimationFactor,
            );
            this.elekLow = Math.round(
                elekEstimation * (1 - this.defaultEstimationFactor),
            );
        }
    }

    /**
     * If collective id exist add collective to the URL
     * @param {URL} searchParams
     * @return {void}
     */
    checkIfCollectiveIsNeededInTheURL(searchParams) {
        // check if snapshot with collective exists
        const snapshotCollectiveID = checkForSnapshotsWithACollective();
        if (
            snapshotCollectiveID &&
            snapshotCollectiveID !== '00000000-0000-0000-0000-000000000000'
        ) {
            if (
                !searchParams.has('collective') &&
                !this.params?.has('collective')
            ) {
                searchParams.append('collective', snapshotCollectiveID);
                // Adds the collective to the URL without reloading the page
                window.history.replaceState(
                    {},
                    '',
                    `${routePaths.customer}?${searchParams.toString()}`,
                );
            }
        }
    }

    /**
     * set the search params from the url
     */
    setParams() {
        const { searchParams } = new URL(window.location);
        const searchParamsArray = [...searchParams];
        if (searchParamsArray.length) {
            // timeout required to disable elek or gas.
            setTimeout(() => {
                this._setValuesWhenParamsChange(searchParams);
                this.params = searchParams;
                if (!this.params.has('collective')) {
                    this.checkIfCollectiveIsNeededInTheURL(searchParams);
                }
            }, 100);
        } else {
            store.dispatch(resetOrder());
            this.checkIfCollectiveIsNeededInTheURL(searchParams);
        }

        // for mijndomein
        const currentTheme =
            themeSettings && !Number.isNaN(themeSettings.variant)
                ? parseInt(themeSettings.variant, 10)
                : this.allVendors;
        if (currentTheme === this.mijndomeinVendor) {
            this.setPostcodeForMijndomein(searchParams);
        }
    }

    /**
     * set the values for the postalcode to fetch the address for the mijndomein vendor
     * @param {URLSearchParams} searchParams
     */
    setPostcodeForMijndomein(searchParams) {
        const postalCode = this.checkIfParamsHasKeyInBothVersions(
            searchParams,
            'postalCode',
        ).trim();
        const houseNumber = this.checkIfParamsHasKeyInBothVersions(
            searchParams,
            'houseNumber',
        );
        // prevent fetching postalcode if postalcode or housenumber is missing.
        if (
            postalCode === '' ||
            postalCode == null ||
            houseNumber === '' ||
            houseNumber == null
        ) {
            return;
        }
        const houseNumberSuffix = this.checkIfParamsHasKeyInBothVersions(
            searchParams,
            'houseNumberSuffix',
        );

        if (postalCode !== '' && houseNumber !== '') {
            if (houseNumberSuffix !== '' && houseNumberSuffix != null) {
                this._houseNumberSuffixValueChanged(null, houseNumberSuffix);
            }
            this._houseNumberValueChanged(null, houseNumber);
            store.dispatch(setPostalCode(postalCode));
        }
    }

    /**
     * onBeforeEnter is a Vaadin router lifecycle method
     * @param {RouterLocation} location
     * @param {PreventAndRedirectCommands} commands
     * @param {Router} router
     */
    onBeforeEnter() {
        if (store.getState().showMenu) {
            store.dispatch(setShowMenu(false));
        }
        // reset the estimation values
        store.dispatch(setElectricityUsageEstimate(0));
        store.dispatch(setGasUsageEstimate(0));
        store.dispatch(setElectricityProductionEstimate(0));
        store.dispatch(setChargePoleUsage(0));
        store.dispatch(setElectricityUsageEstimate(0));

        // get params
        this.setParams();
        store.dispatch(setGas(true));
    }

    /**
     * onAfterEnter is a Vaadin router lifecycle method
     * @param {RouterLocation} location
     * @param {PreventAndRedirectCommands} commands
     * @param {Router} router
     */
    onAfterEnter() {
        // reset the estimation values
        store.dispatch(setElectricityUsageEstimate(0));
        store.dispatch(setGasUsageEstimate(0));
        store.dispatch(setElectricityProductionEstimate(0));
        store.dispatch(setChargePoleUsage(0));
        store.dispatch(setElectricityUsageEstimate(0));

        // get params
        this.setParams();
        store.dispatch(setGas(true));
    }

    /**
     * get adrress edit URL
     * @return {String}
     */
    _getEditURL() {
        if (
            window.location.href.indexOf('weave') > -1 ||
            window.location.href.indexOf('localhost') > -1
        ) {
            return themeSettings?.vendorEditURLStaging
                ? themeSettings.vendorEditURLStaging
                : 'https://www.geenlinknaarproductie.nl';
        }

        return themeSettings?.vendorEditURL
            ? themeSettings.vendorEditURL
            : this.editURL;
    }

    _setLabels = (params) => {
        params.forEach((p) => {
            store.dispatch(addLabel(p));
        });
    };

    /**
     *
     * @param {URLSearchParams} params
     * Checks when params are changed if logic needs to be changed as well.
     */
    _setValuesWhenParamsChange(params) {
        // set no elect if the param (string) is zero
        if (params == null && Object.keys(params).length === 0) {
            return;
        }
        this._setLabels(params);

        const hasElec = params.has('elec_usage_amount');
        if (hasElec && Number(params.get('elec_usage_amount') > 0)) {
            const elecUsageAmount = Number(params.get('elec_usage_amount'));
            store.dispatch(setElectricityUsageEstimate(elecUsageAmount));
        }

        if (
            (params.get('has_gas') && !params.get('has_elec')) ||
            (hasElec && Number(params.get('elec_usage_amount')) <= 0)
        ) {
            this._noElec();
        } else {
            this._elec();
        }

        const hasGas = params.has('gas_usage_amount');
        if (hasGas && Number(params.get('gas_usage_amount') > 0)) {
            const elecUsageAmount = Number(params.get('gas_usage_amount'));
            store.dispatch(setGasUsageEstimate(elecUsageAmount));
        }
        // set no gas if the param (string) is zero
        if (
            (params.get('has_elec') && !params.get('has_gas')) ||
            (hasGas && Number(params.get('gas_usage_amount')) <= 0)
        ) {
            this._noGas();
        } else {
            this._gas();
        }

        if (
            params.has('elec_prod_amount') &&
            Number(params.get('elec_prod_amount')) > 0
        ) {
            this._prod();
            store.dispatch(
                setElectricityProductionEstimate(
                    Number(params.get('elec_prod_amount')),
                ),
            );
        } else {
            this._noProd();
        }

        if (
            this.electricityChargingPoleEnabled &&
            params.has('charging_pole_usage') &&
            params.get('charging_pole_usage') !== '0'
        ) {
            this.order.chargingPoleUsage = Number(
                params.get('charging_pole_usage'),
            );
            store.dispatch(setChargePoleUsage(this.order.chargingPoleUsage));
        } else {
            store.dispatch(setChargePoleUsage(0));
            this.chargingPoleChecked = false;
        }

        if (params.has('collective')) {
            store.dispatch(setCollective(params.get('collective')));
        }

        if (params.has('proposition')) {
            store.dispatch(setProposition(params.get('proposition')));
        }

        if (params.has('solar_mail')) {
            store.dispatch(setSolarMail(params.get('solar_mail') === 'true'));
        }

        if (params.has('energysplitter')) {
            store.dispatch(
                setEnergysplitter(params.get('energysplitter') === 'true'),
            );
        }
        if (params.has('high_low_distribution')) {
            const distribution = params.get('high_low_distribution');
            if (distribution !== '') {
                const distr = Number(distribution);
                this.highLow = true;
                this.elekHigh = distr * Number(params.get('elec_usage_amount'));
                this.elekLow =
                    (1 - distr) * Number(params.get('elec_usage_amount'));
                store.dispatch(setElectricityUsageFactorEstimate(distr));
            }
        }

        if (params.has('electricity_capacity')) {
            this.electricityCapacity = Number(
                params.get('electricity_capacity'),
            );
            this.shadowRoot.querySelector('#elec__capacity').value =
                this.electricityCapacity;
            this.electrictiySelected = true;
        }
        if (params.has('gas_capacity')) {
            this.gasCapacity = Number(params.get('gas_capacity'));
            this.shadowRoot.querySelector('#gas__capacity').value =
                this.gasCapacity;
            this.gasSelected = true;
        }
    }

    /**
     * On connectedCallback
     */
    connectedCallback() {
        super.connectedCallback();
        store.dispatch(resetCalculator());
        this.setParams();

        this.addEventListener('sl-change', (e) => {
            const { capacity, type } = SelectCapacityListner(e);

            if (!capacity) {
                return;
            }

            if (type === 'gas') {
                this.gasCapacity = capacity.id;
                this.gasSelected = true;
                return;
            }

            this.electricityCapacity = capacity.id;
            this.electrictiySelected = true;
        });
    }

    /**
     * @param {Object} params
     * @param {String} key
     * @return {String}
     */
    checkIfParamsHasKey = (params, key) => {
        const { order } = store.getState();

        if (params?.has(key)) {
            return params.get(key);
        }
        if (order[key]) {
            return order[key];
        }
        return '';
    };

    /**
     *
     * @param {Object} params
     * @param {String} key
     * @return {String}
     */
    checkIfParamsHasKeyInBothVersions = (params, key) => {
        const keys = this.getOtherVariant(key);
        for (const k of keys) {
            const res = this.checkIfParamsHasKey(params, k);
            if (res && res !== '' && res !== 'null') {
                return res;
            }
        }
        return '';
    };

    /**
     *
     * @param {String} key
     * @return {any}
     */
    getOtherVariant = (key) => {
        switch (key) {
            case 'postalCode':
                return [key, 'area_code'];
            case 'houseNumber':
                return [key, 'house_number'];
            case 'houseNumberSuffix':
                return [key, 'house_addition'];
            default:
                return [key];
        }
    };

    /**
     * Gets the energy production amount if any is available
     * @param {Object} order
     * @return {null|Number|string}
     * @private
     */
    _getProduction = (order) => {
        if (order.electricityProductionEstimate) {
            return order.electricityProductionEstimate;
        }

        return '';
    };

    /**
     * Get the amount of electricity usage
     * @param {Object} order
     * @return {null|Number|string}
     * @private
     */
    _getPowerUsage = (order) => {
        if (order.electricityUsageEstimate) {
            return order.electricityUsageEstimate;
        }

        return '';
    };

    /**
     * Get the amount of gas usage
     * @param {Object} order
     * @return {null|Number|any}
     * @private
     */
    _getGasUsage = (order) => {
        if (order.gasUsageEstimate) {
            return order.gasUsageEstimate;
        }

        return '';
    };

    /**
     * Get the amount of gas usage
     * @param {Object} order
     * @return {null|Number|any}
     * @private
     */
    _getChargingPole(order) {
        const chargingEstimation = order.chargePoleUsage;
        const chargingPole = this._getNumberAsNullable(chargingEstimation);

        if (chargingPole && chargingPole.toString() !== '') {
            return chargingPole;
        }

        return '';
    }

    /**
     * Get the redirect type i.e. manual retention or nothing (null)
     * @return {null|string}
     * @private
     */
    _getType() {
        return this.params.redirect_type;
    }

    /**
     * Get changed value from event
     * @param {Object} event
     * @param {Boolean} isNumber
     * @return {String | Number}
     */
    _valueFromValueChangedEvent = (event, isNumber) => {
        const firstElement = event.composedPath()[0];
        if (firstElement) {
            if (isNumber) {
                return Number(firstElement.value);
            }
            return firstElement.value.trim();
        }

        return '';
    };

    /**
     * Toggle the prod type picker drop down
     */
    _toggleProdTypePickerDropDown() {
        this.prodTypePickerDropDownOpen = !this.prodTypePickerDropDownOpen;
    }

    /**
     * Returns the number or an empty string when the number is zero, undefined or null
     * @param {Number} num
     * @return {Number | String}
     */
    _getNumberAsNullable = (num) => (!num || num < 0 ? '' : num);

    /**
     * Change electricity production estimate
     * @param {Event} event
     * @return {void}
     */
    _electricityProductionEstimateValueChanged(event) {
        const num = this._valueFromValueChangedEvent(event, true);

        if (this.numIsGreaterThanZero(num)) {
            this.UICalculatorInteractionKWHSChanged();
            store.dispatch(setElectricityProductionEstimate(Math.abs(num)));
        }
    }

    /**
     * Change electricity production panels estimate
     * @param {Event} event
     * @return {void}
     */
    _electricityProductionEstimatePanelsValueChanged(event) {
        const num = this._valueFromValueChangedEvent(event, true);

        if (num && !Number.isNaN(num)) {
            this.UICalculatorInteractionPanelsChanged();
        }
    }

    /**
     * Change electricity high usage estimate
     * @param {Event} event
     * @return {void}
     */
    _electricityHighUsageEstimateValueChanged(event) {
        if (this.highLow) {
            const num = this._valueFromValueChangedEvent(event, true);
            this.elekHigh = num;
        }
    }

    /**
     * Get electricity high usage estimate
     * @param {number} elekHigh
     * @return {String}
     */
    _getElectricityHighUsageEstimate = (elekHigh) => {
        if (elekHigh) {
            return elekHigh;
        }

        return '';
    };

    /**
     * Change electricity low usage estimate
     * @param {Event} event
     * @return {void}
     */
    _electricityLowUsageEstimateValueChanged(event) {
        if (this.highLow) {
            const num = this._valueFromValueChangedEvent(event, true);
            this.elekLow = num;
        }
    }

    /**
     * Value change electricity usage estimate
     * @param {Event} event
     * @return {void}
     */
    _electricityTotalEstimateValueChanged(event) {
        const num = this._valueFromValueChangedEvent(event, true);
        if (this.numIsGreaterThanZero(num)) {
            store.dispatch(setElectricityUsageEstimate(num));
        }
    }

    /**
     * Focus change electricity low usage estimate
     * @param {Event} event
     * @return {void}
     */
    _electricityTotalEstimateValueFocusEvent(event) {
        const num = this._valueFromValueChangedEvent(event, true);
        if (this.numIsGreaterThanZero(num)) {
            this.UICalculatorInteractionPowerUsageChanged();
        }
    }

    /**
     * Get electricity low usage estimate
     * @param {Number} elekLow
     * @return {String}
     */
    _getElectricityLowUsageEstimate = (elekLow) => {
        if (elekLow) {
            return elekLow;
        }
        return '';
    };

    /**
     * Toggle show high and low usage estimate fields
     */
    _toggleHighLow() {
        if (this.highLow) {
            store.dispatch(
                setElectricityUsageFactorEstimate(this.defaultEstimationFactor),
            );
        }
        this.elekLow = 0;
        this.elekHigh = 0;
        store.dispatch(setElectricityUsageEstimate(0));

        this.highLow = !this.highLow;
    }

    /**
     * Value change gas usage estimate
     * @param {Event} event
     * @return {void}
     */
    _gasUsageEstimateValueChanged(event) {
        const num = this._valueFromValueChangedEvent(event, true);

        if (this.numIsGreaterThanZero(num)) {
            store.dispatch(setGasUsageEstimate(Math.abs(num)));
        }
    }

    /**
     * Focus change gas usage estimate
     * @param {Event} event
     * @return {void}
     */
    _gasUsageEstimateValueFocusEvent(event) {
        const num = this._valueFromValueChangedEvent(event, true);

        if (this.numIsGreaterThanZero(num)) {
            this.UICalculatorInteractionGasUsageChanged();
        }
    }

    /**
     * numIsGreaterThanZero check if val is number and greater then zero
     * @param {Number} val
     * @return {Boolean}
     */
    numIsGreaterThanZero(val) {
        return !Number.isNaN(val) && val > 0;
    }

    /**
     * Change chargingPole usage estimate
     * @param {Event} event
     * @return {void}
     */
    _electricityChargingPoleValueChanged(event) {
        const num = this._valueFromValueChangedEvent(event, true);

        if (!Number.isNaN(num)) {
            store.dispatch(setChargePoleUsage(Math.abs(num)));
        }
    }

    /**
     * Change house number
     * @param {Event | Null} event
     * @param {String} houseNumber
     * @return {void}
     */
    _houseNumberValueChanged(event, houseNumber) {
        let _houseNumber = houseNumber;
        if (event != null) {
            _houseNumber = _houseNumber.value;
        }
        if (Number.isNaN(Number(_houseNumber)) || _houseNumber === '') {
            return;
        }
        const groups = /^\s*(\d*)(-|\s*)(\D*)\s*$/g.exec(_houseNumber);

        if (groups && groups.length > 2) {
            if (groups[1].length > 0) {
                store.dispatch(setHouseNumber(Number(groups[1])));
            } else {
                store.dispatch(setHouseNumber(null));
            }
            if (groups[3].length > 0) {
                store.dispatch(setHouseNumberSuffix(groups[3]));

                // @ts-ignore
                this.$.houseAddition.focus();
            }
        }
    }

    /**
     * Focus house number event
     * @param {Event | Null} event
     * @return {void}
     */
    _houseNumberFocusChanged(event) {
        const { inValid, firstElement, isValid } =
            focusPaperInputEventIsInvalid(event, this.houseNumberPrevious);
        if (inValid) {
            return;
        }

        this.houseNumberPrevious = firstElement.value;

        if (Number.isNaN(Number(firstElement.value))) {
            return;
        }
        store.dispatch(
            uiCalculatorInteraction.run(houseNumberChanged(isValid)),
        );
    }

    /**
     * Change house number suffix
     * @param {Event | Null} event
     * @param {String} houseNumberSuffix
     * @return {void}
     */
    _houseNumberSuffixValueChanged = (event, houseNumberSuffix) => {
        let _houseNumberSuffix = houseNumberSuffix;
        if (event != null) {
            _houseNumberSuffix = _houseNumberSuffix.value;
        }
        store.dispatch(setHouseNumberSuffix(_houseNumberSuffix));
    };

    /**
     * Focus house number suffix event
     * @param {Event | Null} event
     * @return {void}
     */
    _houseNumberSuffixFocusChanged = (event) => {
        const { inValid, firstElement } = focusPaperInputEventIsInvalid(
            event,
            this.houseNumberSuffixPrevious,
        );
        if (inValid) {
            return;
        }

        this.houseNumberSuffixPrevious = firstElement.value;
        store.dispatch(setHouseNumberSuffix(firstElement.value));
    };

    _houseNumberSuffixValueObserver = (selectedHouseAddition) => {
        if (
            selectedHouseAddition &&
            selectedHouseAddition.name != null &&
            selectedHouseAddition.name.length > 0
        ) {
            store.dispatch(
                setHouseNumberSuffix(
                    selectedHouseAddition.name === 'Geen'
                        ? null
                        : selectedHouseAddition.name,
                ),
            );
            if (this.geocode?.data) {
                this.geocode.data.houseNumberSuffix =
                    selectedHouseAddition.name === 'Geen'
                        ? null
                        : selectedHouseAddition.name;
                store.dispatch(
                    uiCalculatorInteraction.run(numberSuffixChanged(true)),
                );
            }
            // Also set geocode information again when the validated house addition is changes in the dropdown
            // This makes sure the feedback is shown under the address input and also updates the information in the store
            this.geocode = JSON.parse(JSON.stringify(this.geocode));
            store.dispatch(setGeoInformation(this.geocode.data));
        }
    };

    /**
     * Change postal code suffix
     * @param {Event | Null} event
     * @param {string} postalCode
     * @return {void}
     */
    _postalCodeValueChanged = (event, postalCode) => {
        let _postalCode = postalCode;
        if (event != null) {
            _postalCode = this._valueFromValueChangedEvent(event);
        }
        // Remove al spaces from the postalcode
        const firstElement = event.composedPath()[0];
        if (firstElement) {
            firstElement.value = firstElement.value.replaceAll(' ', '');
        }
        const groups = dutchPostalCodesRegex.exec(_postalCode);
        if (
            groups &&
            groups.length > 2 &&
            groups[1].length === 4 &&
            groups[2].length === 2
        ) {
            store.dispatch(setPostalCode(`${groups[1]}${groups[2]}`));
        } else {
            store.dispatch(setPostalCode(_postalCode));
        }
    };

    /**
     * Focus postal code event
     * @param {Event | Null} event
     * @return {void}
     */
    _postalCodeValueFocusChanged = (event) => {
        const { inValid, firstElement, isValid } =
            focusPaperInputEventIsInvalid(event, this.postalCodePrevious);
        if (inValid) {
            return;
        }
        this.postalCodePrevious = firstElement.value.replaceAll(' ', '');
        store.dispatch(uiCalculatorInteraction.run(postalCodeChanged(isValid)));
    };

    /**
     * Class injector function
     * @param {Boolean} invalidated
     * @return {String}
     */
    _classInvalidated = (invalidated) => (invalidated ? ' invalidated' : '');

    /**
     * Helper function for a dom-if
     * @param {any} error
     * @param {String} postalCode
     * @return {Boolean}
     */
    _showGeocodeError = (error) => !!error;

    /**
     * Helper function for a dom-if
     * @param {Boolean} invalidated
     * @param {String} postalCode
     * @param {Object} geocode
     * @return {Boolean}
     */
    _showGeocodeResponse = (invalidated, postalCode, geocode) => {
        const showResponse = postalCode
            ? !!postalCode && !invalidated
            : !!geocode.data && !invalidated;
        return showResponse;
    };

    /**
     * Sets has elec false
     */
    _noElec() {
        if (!this.order.gas) {
            this._gas();
        }
        if (this.order.electricity) {
            store.dispatch(setElectricity(false));
            store.dispatch(
                uiCalculatorInteraction.run(noPowerAvailableClicked()),
            );
            store.dispatch(setElectricityUsageEstimate(0));
        }
    }

    /**
     * Sets has elec true
     */
    _elec = () => {
        if (!this.order.electricity) {
            store.dispatch(
                uiCalculatorInteraction.run(powerAvailableClicked()),
            );
            store.dispatch(setElectricity(true));
        }
    };

    /**
     * Sets has gas false
     */
    _noGas() {
        if (!this.order.electricity) {
            this._elec();
        }
        if (this.order.gas) {
            store.dispatch(setGas(false));
            store.dispatch(
                uiCalculatorInteraction.run(noGasAvailableClicked()),
            );
            store.dispatch(setGasUsageEstimate(0));
        }
    }

    /**
     * Sets has gas true
     */
    _gas = () => {
        if (!this.order.gas) {
            store.dispatch(uiCalculatorInteraction.run(gasAvailableClicked()));
            store.dispatch(setGas(true));
        }
    };

    /**
     * Sets has prod true
     */
    _noProd() {
        this.prodChecked = false;
    }

    /**
     * Sets has prod true
     */
    _prod() {
        this.prodChecked = true;
    }

    /**
     * Sets soral pannels false
     */
    _unSetSolarPannel() {
        this.fillProdBySolarPannels = false;
        this.prodTypePickerDropDownOpen = false;
        const val = this.shadowRoot.querySelector('#prodUsage')?.value;
        if (val != null) {
            this.shadowRoot.querySelector('#prodUsage').value = null;
        }
        store.dispatch(
            uiCalculatorInteraction.run(powerDeliveryAmountOfkWhsClicked()),
        );
        store.dispatch(setElectricityProductionEstimate(0));
    }

    /**
     * Sets default value
     */
    _setDefaultValueChargePole() {
        store.dispatch(
            setChargePoleUsage(Math.abs(this.defaultChargePoleValue)),
        );
    }

    /**
     * Sets solar panels true
     */
    _setSolarPannel() {
        this.fillProdBySolarPannels = true;
        this.prodTypePickerDropDownOpen = false;
        const val = this.shadowRoot.querySelector('#panelUsage')?.value;
        if (val != null) {
            this.shadowRoot.querySelector('#panelUsage').value = null;
        }
        store.dispatch(
            uiCalculatorInteraction.run(powerDeliveryAmountOfPanelsClicked()),
        );
        store.dispatch(setElectricityProductionEstimate(0));
    }

    /**
     * Sets prod amount based on number of solar panels
     */
    _numberOfSolarPannelsChanged() {
        const kwhPerPanel = 250;
        if (!this.numberOfSolarPannels) {
            store.dispatch(setElectricityProductionEstimate(0));
        } else if (!Number.isNaN(this.numberOfSolarPannels)) {
            this.numberOfSolarPannels = Math.abs(this.numberOfSolarPannels);
            store.dispatch(
                setElectricityProductionEstimate(
                    this.numberOfSolarPannels * kwhPerPanel,
                ),
            );
        }
    }

    /**
     * Toggles the help dialog for energy production
     */
    _toggleEstimationTool() {
        if (!this.showEstimationTool) {
            store.dispatch(
                uiCalculatorInteraction.run(helpWithUsageEstimationClicked()),
            );
        }
        this.showEstimationTool = !this.showEstimationTool;
    }

    /**
     * Retrieve collectives
     */
    _collectiveCheckedValueChanged() {
        if (this.collectiveChecked) {
            if (
                this.secSubRoute &&
                this.subRoute === 'collective' &&
                this.collectives &&
                Array.isArray(this.collectives) &&
                this.collectives.filter((c) => c && c.id === this.secSubRoute)
                    .length > 0
            ) {
                this.selectedCollectiveID = this.collectives.find(
                    (c) => c && c.id === this.secSubRoute,
                );
            }
            if (this.selectedCollectiveID && this.selectedCollectiveID.id) {
                store.dispatch(setCollective(this.selectedCollectiveID.id));
            }
        } else if (this.subRoute === 'collective') {
            store.dispatch(clearCollective());
        }
    }

    /**
     * Check if collectives are present
     * @param {Array} collectives the list of collectives
     * @return {Boolean} indication if there are collectives
     */
    _hasCollectives = (collectives) => {
        if (collectives == null) {
            return false;
        }
        return collectives.length !== 0;
    };

    /**
     * Set production to zero on changed value
     * @param {Boolean} val
     * @param {Boolean} old
     */
    _prodCheckedValueChanged = (val, old) => {
        if (!val && old) {
            store.dispatch(setElectricityProductionEstimate(0));
        }
        if (old !== undefined) {
            store.dispatch(
                uiCalculatorInteraction.run(
                    val
                        ? powerDeliveryQuestionYesClicked()
                        : powerDeliveryQuestionNoClicked(),
                ),
            );
        }
    };

    _selectedEstimationToolResidenceTypeIDObserver = (val, old) => {
        if (old != null && val.id !== old.id) {
            store.dispatch(
                uiCalculatorInteraction.run(
                    helpWithUsageEstimationTypeOfBuildingClicked(
                        this.estimationToolResidenceType.find(
                            (i) => i.id === val.id,
                        ).name,
                    ),
                ),
            );
        }
    };

    _selectedEstimationToolResidentsIDObserver = (val, old) => {
        if (old != null && val.id !== old.id) {
            store.dispatch(
                uiCalculatorInteraction.run(
                    helpWithUsageEstimationAmountInHouseholdsClicked(
                        this.estimationToolResidents.find(
                            (i) => i.id === val.id,
                        ).name,
                    ),
                ),
            );
        }
    };

    _selectedEstimationToolProductTypeIDObserver = (val, old) => {
        if (old != null && val.id !== old.id) {
            store.dispatch(
                uiCalculatorInteraction.run(
                    helpWithUsageEstimationTypeOfProductsClicked(
                        this.estimationToolProductType.find(
                            (i) => i.id === val.id,
                        ).name,
                    ),
                ),
            );
        }
    };

    /**
     * Save selected collective to redux
     * @param {Object} selected
     */
    _selectedCollectiveChanged = (selected) => {
        if (selected && selected.id) {
            store.dispatch(setCollective(selected.id));
        } else {
            store.dispatch(clearCollective());
        }
    };

    /**
     * Calculate monthly cost based on user specifications
     */
    _calculateMonthlyCosts() {
        const isValid = this._isValidInput();
        if (!isValid) {
            return;
        }

        store.dispatch(resetOffer());
        store.dispatch(resetEnroll());

        let gasMeter = this.gasCapacity;
        // only overwrite the gasMeter if the capacity dropdown is not visable on this page
        if (!this.capacityEnabled) {
            if (this.order && this.order.gasUsageEstimate >= 500) {
                gasMeter =
                    enums.TransmissionValueType10M3uurTussen500M3En4000M3jaar;
            }
            if (this.order && this.order.gasUsageEstimate >= 4000) {
                gasMeter = enums.TransmissionValueType10M3uurMeerDan4000M3jaar;
            }
        }

        store.dispatch(
            uiCalculatorInteraction.run(
                calculateOfferClicked(
                    this.geocode?.error ? 'invalid' : 'valid',
                ),
            ),
        );
        store.dispatch(
            setTransmissionTypes(this.electricityCapacity, gasMeter),
        );
        store.dispatch(
            retrieveOffers(this.order, gasMeter, this.electricityCapacity),
        );
    }

    /**
     * Calculate energy consumption estimate based on situation
     */
    _calculateEstimate() {
        const dataTblElec = [
            [1600, 2000, 1400, 1800, 2200],
            [2750, 3150, 2550, 2950, 3350],
            [3800, 4200, 3600, 4000, 4400],
            [4400, 4800, 4200, 4600, 5000],
            [5100, 5500, 4900, 5300, 5700],
        ];
        const dataTblGas = [
            [1100, 1400, 650, 1350, 1950],
            [1200, 1500, 750, 1450, 2050],
            [1350, 1650, 900, 1600, 2200],
            [1500, 1800, 1050, 1750, 2350],
            [1600, 1900, 1150, 1850, 2450],
        ];

        const residence = this.selectedEstimationToolResidenceTypeID.id || 0;
        const people = this.selectedEstimationToolResidentsID.id || 0;
        const product = this.selectedEstimationToolProductTypeID.id || 0;
        this.updateGasEstimation = true;

        store.dispatch(setGasUsageEstimate(dataTblGas[people][residence]));
        store.dispatch(
            setElectricityUsageEstimate(dataTblElec[people][residence]),
        );

        store.dispatch(setGas(product % 2 === 0));
        store.dispatch(setElectricity(product < 2));
        store.dispatch(
            uiCalculatorInteraction.run(calculateEnergyEstimationsClicked()),
        );
        this._toggleEstimationTool();
    }

    /**
     * Check if input is correctly set
     * @return {Boolean} indicating if input is valid
     */
    _isValidInput() {
        const {
            collective,
            electricity,
            electricityProductionEstimate,
            gas,
            gasUsageEstimate,
            houseNumber,
            houseNumberSuffix,
            postalCode,
            chargePoleUsage,
        } = this.order;

        if (
            this._hasCollectives(this.collectives) &&
            this.collectiveChecked &&
            (!collective || collective === '')
        ) {
            window.displayMessage(
                'Selecteer een energieproject of -collectief.',
                'warning',
            );
            return false;
        }
        if (!postalCode || postalCode === '') {
            window.displayMessage('Vul een postcode in.', 'warning');
            return false;
        }

        if (
            !houseNumber ||
            (this.params &&
                this.checkIfParamsHasKeyInBothVersions(
                    this.params,
                    'houseNumber',
                ) !== houseNumber)
        ) {
            const hNumber = this.checkIfParamsHasKeyInBothVersions(
                this.params,
                'houseNumber',
            );
            if (!hNumber || hNumber === '') {
                window.displayMessage('Vul een huisnummer in.', 'warning');
                return false;
            }
        }
        let hSuffix = null;
        if (this.params) {
            if (
                this.mijndomeinVendor ||
                this.checkIfParamsHasKeyInBothVersions(
                    this.params,
                    'houseNumberSuffix',
                ) !== ''
            ) {
                hSuffix = this.checkIfParamsHasKeyInBothVersions(
                    this.params,
                    'houseNumberSuffix',
                );
            }
        } else {
            hSuffix = houseNumberSuffix;
        }

        // elec check
        if (electricity) {
            const inputsElekEstimations =
                this.shadowRoot.querySelectorAll('#powerUsage');
            let estimation;
            const powerUsageTotal = inputsElekEstimations[0].value;
            if (this.highLow) {
                const high = Number(inputsElekEstimations[1].value);
                estimation = high + Number(inputsElekEstimations[2].value);
                const defaultEstimationFactor = (high / estimation).toFixed(2);
                store.dispatch(
                    setElectricityUsageFactorEstimate(
                        Number(defaultEstimationFactor),
                    ),
                );
            } else {
                estimation = Number(powerUsageTotal);
            }

            if (!estimation || Number.isNaN(estimation)) {
                window.displayMessage(
                    'Vul het geschatte stroomverbruik per jaar in.',
                    'warning',
                );
                return false;
            }
            // Whenever we have production we should have an elec value higher than 0
            if (electricityProductionEstimate > 0 && powerUsageTotal === 0) {
                window.displayMessage(
                    'Vul het geschatte stroomverbruik per jaar in.',
                    'warning',
                );
                return false;
            }

            if (powerUsageTotal && powerUsageTotal > this.elecUsageCap) {
                window.displayMessage(
                    `Het stroomverbruik mag niet hoger dan ${numberFormat(
                        this.elecUsageCap,
                    )} kWh per jaar zijn.${
                        this.usageCapErrorSuffix
                            ? ` ${this.usageCapErrorSuffix}`
                            : ''
                    }`,
                    'warning',
                );
                return false;
            }

            if (!this.electrictiySelected) {
                window.displayMessage(
                    'Selecteer je electriciteit capaciteit',
                    'warning',
                );
                return false;
            }

            // add elekEstimation to the order state.
            store.dispatch(setElectricityUsageEstimate(Math.abs(estimation)));
        }

        // gas check
        if (
            gas &&
            (gasUsageEstimate === null ||
                gasUsageEstimate === undefined ||
                gasUsageEstimate < 10)
        ) {
            window.displayMessage(
                `Vul minimaal 10 m³ gasverbruik in. Geen gas nodig? Klik op de tekstlink 'Ik wil geen gas'.`,
                'warning',
            );
            return false;
        }
        if (gas && gasUsageEstimate && gasUsageEstimate > this.gasUsageCap) {
            window.displayMessage(
                `Het gasverbruik mag niet hoger dan ${numberFormat(
                    this.gasUsageCap,
                )} m³ per jaar zijn.${
                    this.usageCapErrorSuffix
                        ? ` ${this.usageCapErrorSuffix}`
                        : ''
                }`,
                'warning',
            );
            return false;
        }
        if (gas && !this.gasSelected) {
            window.displayMessage('Selecteer je gas capaciteit', 'warning');
            return false;
        }

        if (this.prodChecked && electricityProductionEstimate === 0) {
            window.displayMessage(
                'Vul de geschatte opwekking per jaar in.',
                'warning',
            );
            return false;
        }

        if (this.highLow && this.elekLow === 0) {
            window.displayMessage(
                'Vul je stroom dal jaarverbruik in.',
                'warning',
            );
            return false;
        }
        if (this.highLow && this.elekHigh === 0) {
            window.displayMessage(
                'Vul je stroom normaal jaarverbruik in.',
                'warning',
            );
            return false;
        }

        if (
            this.chargingPoleChecked &&
            (chargePoleUsage === 0 || !chargePoleUsage)
        ) {
            window.displayMessage(
                `Vul het aantal kWh's voor laadsessies per jaar in.`,
                'warning',
            );
            return false;
        }

        if (chargePoleUsage && chargePoleUsage > this.elecUsageCap) {
            window.displayMessage(
                `Stroomverbruik laadpunt mag niet hoger dan ${this.elecUsageCap} kWh per jaar zijn.`,
                'warning',
            );
            return false;
        }
        if (!gas && !electricity) {
            window.displayMessage(
                'Er moet tenminste voor stroom- of gaslevering gekozen worden.',
                'warning',
            );
            return false;
        }

        // when production is filled in and elec product was not selected
        // A customer should have an elec product when filling in production
        if (electricityProductionEstimate > 0 && !electricity) {
            window.displayMessage(
                'Indien je opwekken invult dien je ook het geschatte stroomverbruik per jaar in te vullen.',
                'warning',
            );
            return false;
        }

        return true;
    }

    /**
     * Check if theme uses variant than the default (1) allVendors
     * @param {Number|String} variant
     * @param {UUID | Null} collective
     * @return {Boolean}
     */
    _variant(variant, collective) {
        let _variant = variant;
        const theme =
            themeSettings && !Number.isNaN(themeSettings.variant)
                ? parseInt(themeSettings.variant, 10)
                : this.allVendors;
        // if subrout is includes also show area code as variant.
        if (_variant === this.allVendors && collective != null) {
            _variant = this.mijndomeinVendor;
        }
        return (
            !Number.isNaN(Number(_variant)) &&
            parseInt(String(_variant), 10) === theme
        );
    }

    /**
     * Check if theme uses electricity production variant
     * @param {Number|String} electricityProductionVariant
     * @return {Boolean}
     */
    _electricityProductionVariant(electricityProductionVariant) {
        const theme =
            themeSettings &&
            !Number.isNaN(themeSettings.electricityProductionVariant)
                ? parseInt(themeSettings.electricityProductionVariant, 10)
                : this.allVendors;
        return (
            !Number.isNaN(Number(electricityProductionVariant)) &&
            parseInt(String(electricityProductionVariant), 10) === theme
        );
    }

    /**
     * Check the thrown error is because of EDSN down
     * @param {String} err
     * @return {Boolean}
     */
    _errorButNotEDSNDown = (err) => {
        if (!err || !err.message) {
            return false;
        }
        if (!err.response || !err.response.data || !err.response.data.detail) {
            return true;
        }
        const error = err.response.data.detail;
        if (error && error.indexOf('EDSN is not available for requests') > -1) {
            return false;
        }
        if (error === undefined || error === null || error === false) {
            return true;
        }

        return error.length !== 0;
    };

    /**
     * Send the EDSN down notification
     * @return {void}
     */
    _createEDSNNotification() {
        const name = this.shadowRoot.querySelector('#edsn-name').value;
        const email = this.shadowRoot.querySelector('#edsn-email').value;

        if (name.length === 0 || email.length === 0) {
            return;
        }
        const {
            collective,
            electricity,
            electricityProductionEstimate,
            electricityUsageEstimate,
            gas,
            gasUsageEstimate,
            houseNumber,
            houseNumberSuffix,
            postalCode,
            transmissionTypeGas,
            transmissionTypeElec,
        } = this.order;

        const highLowDistribution = Number(
            this.order.electricityUsageFactorEstimate,
        );

        store.dispatch(
            notifyEdsnLive.request(
                { vendorId: window.VENDOR },
                {
                    data: {
                        area_code: postalCode,
                        collective_id: collective,
                        elec_amount: electricityUsageEstimate,
                        email,
                        gas_amount: gasUsageEstimate,
                        has_elec: electricity,
                        has_gas: gas,
                        high_low_distribution: highLowDistribution,
                        house_addition: houseNumberSuffix,
                        house_number: houseNumber.toString(),
                        name,
                        prod_amount: electricityProductionEstimate,
                        transmission_value_type_elec: transmissionTypeElec,
                        transmission_value_type_gas: transmissionTypeGas,
                        vendor_id: window.VENDOR,
                    },
                },
            ),
        );

        this.shadowRoot.querySelector('modal-component').close();
    }

    /**
     * Link classname if exists
     * @param {String} className
     * @return {String}
     */
    _className = (className) => (className ? ` ${className}` : '');

    /**
     * Gets template of class
     */
    static get template() {
        const cssTemplate = document.createElement('template');
        cssTemplate.innerHTML = provider.styles(css);
        const htmlTemplate = document.createElement('template');
        htmlTemplate.innerHTML = template;
        return html`<style include="iron-flex">
                ${cssTemplate}
            </style>
            ${htmlTemplate}`;
    }

    /**
     * @param {object} geocode
     * @param {URLSearchParams} params
     * @param {object} order
     * @return {string}
     */
    getAddress(geocode) {
        if (geocode == null || geocode.data == null) {
            return '';
        }
        let address = `${geocode.data.street_name}`;
        const { houseNumber } = this.order;
        if (houseNumber) {
            address += ` ${houseNumber}`;
            const hSuffix = this.order.houseNumberSuffix;
            if (hSuffix && hSuffix !== '') {
                address += `${hSuffix}`;
            }
        }

        if (geocode.data.city) {
            address += `, ${geocode.data.city}`;
        }
        return address;
    }
}

window.customElements.define('calculation-tool', EzCalculationTool);
