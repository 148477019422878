import '@atoms/icon/HvIcon';
import { provider } from '@weavelab/frontend-connect';
import {
    customElement,
    html,
    LitElement,
    property,
    TemplateResult,
} from 'lit-element';
import { ifDefined } from 'lit-html/directives/if-defined';
import { choose } from 'lit/directives/choose';
import { notificationBarComponentType } from 'types/notification';
import sharedStyle from '../../sharedStyles';
import '../atoms/HvButton';
import style from './HvNotificationComponentStyle';

@customElement('hv-notification')
export class NotificationBarComponent extends LitElement {
    @property({
        attribute: 'src-height',
    })
    public srcHeight: string = '18';

    @property({
        attribute: 'src-width',
    })
    public srcWidth: string = '18';

    @property({ attribute: 'info', type: String })
    public info: string = '';

    @property({ attribute: 'hidden', type: Boolean, reflect: true })
    public hidden: boolean = false;

    @property({ attribute: 'cta-text', type: String })
    public ctaText?: string;

    @property({ attribute: 'callback', type: Function })
    public callback?: () => void;

    @property({ attribute: 'type', type: String })
    public type: notificationBarComponentType = 'default';

    static get styles() {
        return [sharedStyle, style];
    }

    getIcon = (): TemplateResult => {
        const { asset } = provider;
        return html`
            ${choose(this.type, [
                [
                    'success',
                    () =>
                        html`<hv-icon
                            class="thumb"
                            name="thumb-up"
                            tone="outlined"
                        ></hv-icon>`,
                ],
                [
                    'default',
                    () => html`<img
                        class="center"
                        src="${asset('icons/check.svg')}"
                    />`,
                ],
                [
                    'warning',
                    () =>
                        html`<hv-icon
                            class="warning"
                            name="warning"
                            tone="outlined"
                        ></hv-icon>`,
                ],
                [
                    'info',
                    () =>
                        html`<hv-icon
                            class="info"
                            name="info"
                            tone="outlined"
                        ></hv-icon>`,
                ],
            ])}
        `;
    };

    public render = (): TemplateResult => {
        if (this.hidden) {
            return html``;
        }

        return html`
            <style>
                .icon,
                img {
                    width: ${this.srcWidth}px;
                    height: ${this.srcHeight}px;
                }
            </style>
            <div class="notification-bar" type="${this.type}">
                <div class="notification-bar__body">
                    <div class="icon">${this.getIcon()}</div>
                    <p type="${this.type}"><slot></slot></p>
                </div>
                ${this.ctaText
                    ? html`<hv-button
                          theme="primary"
                          @click="${ifDefined(this.callback)}"
                          >${this.ctaText}</hv-button
                      >`
                    : ''}
            </div>
        `;
    };
}

declare global {
    interface HTMLElementTagNameMap {
        'hv-notification': NotificationBarComponent;
    }
}
