export const SET_ERROR = 'SET_ERROR';

/**
 * Dispatch for error store
 * @param {string} error
 * @return {import("redux").AnyAction}
 */
export const setError = (error) => ({
    type: SET_ERROR,
    error,
});
