import { html, LitElement, TemplateResult, state, query } from 'lit-element';
import { Validator } from '@lion/form-core';
import 'atoms/HvButtonSubmit';
import 'atoms/Input';
import '@lion/fieldset/lion-fieldset';
import '@lion/form/lion-form';
import { LionFieldset } from '@lion/fieldset';

export type FormButtonType = 'default' | 'submit';
export type FormPart<T> = (form: T) => TemplateResult;

export type FormFieldType =
    | 'off'
    | 'username'
    | 'new-password'
    | 'current-password';

interface FieldOptions {
    // Show the label above the input field (default: true)
    label?: boolean;

    // Callback to be triggered on input field changes (note: use this.value)
    // eslint-disable-next-line no-unused-vars
    listener?: (e: CustomEvent) => void;

    // Show the label in the input field as a placeholder (default: false)
    placeholder?: boolean | string;

    // Use @lion/core Validators (default: [])
    validators?: Validator[];
}
const defaultOptions: Required<FieldOptions> = {
    label: true,
    listener: () => undefined,
    placeholder: false,
    validators: [],
};

export abstract class Form extends LitElement {
    @state()
    private job?: Promise<void>;

    @query('lion-fieldset')
    private fieldset?: LionFieldset;

    public get values(): any {
        return this.fieldset?.modelValue;
    }

    /**
     * Returns true if form is currently running a job
     */
    protected get busy(): boolean {
        return !!this.job;
    }

    private formPartMemo?: { source: any; result: TemplateResult[] };

    /**
     * Returns memozied form parts
     */
    protected get formParts(): TemplateResult[] {
        // Invalidate if source is replaced
        if (this.formPartMemo?.source !== this.form) {
            this.formPartMemo = undefined;
        }

        // Generate on first run or after invalidate
        if (this.formPartMemo === undefined) {
            this.formPartMemo = {
                source: this.form,
                result: (this.form ?? []).map((p: FormPart<any>) => p(this)),
            };
        }

        // Return form parts
        return this.formPartMemo.result;
    }

    public render(): TemplateResult {
        return html`
            <lion-form>
                <form @submit=${Form.preventDefault}>
                    <lion-fieldset name="fieldset"
                        >${this.formParts}</lion-fieldset
                    >
                </form>
            </lion-form>
        `;
    }

    protected static button(type: 'submit', label: string): FormPart<Form> {
        switch (type) {
            case 'submit':
                return (form: Form) => html`<hv-button-submit
                    ?busy="${form.busy}"
                    ?disabled="${form.busy}"
                    @click="${form.internalSubmit}"
                    >${label}</hv-button-submit
                >`;
            default:
                throw new Error(`Form.button('${type}', ...) not implemented`);
        }
    }

    protected static field(
        type: FormFieldType,
        name: string,
        label: string,
        options: FieldOptions = {},
    ): FormPart<Form> {
        let placeholder = options.placeholder ?? defaultOptions.placeholder;
        if (typeof placeholder !== 'string') {
            placeholder = placeholder ? label : '';
        }

        return (form: Form) => html`
            <hv-input
                autocomplete="${type}"
                autocapitalize="off"
                ?disabled="${form.busy}"
                ?label-sr-only="${!(options.label ?? defaultOptions.label)}"
                label="${label}"
                type="${Form.fieldType(type)}"
                name="${name}"
                placeholder="${placeholder}"
                .validators="${options.validators || []}"
                .showsFeedbackFor="${['touched']}"
                @user-input-changed="${options.listener ??
                defaultOptions.listener}"
            ></hv-input>
        `;
    }

    protected abstract form: FormPart<this>[];

    // eslint-disable-next-line no-unused-vars
    protected abstract submit(values: { [key: string]: string }): Promise<void>;

    /**
     * Disables shadowDOM
     */
    protected createRenderRoot(): Element | ShadowRoot {
        return this;
    }

    private async internalSubmit(): Promise<void> {
        // Force validation
        let valid = true;
        this.fieldset?.validate({ clearCurrentResult: true });
        valid = valid && !this.fieldset?.hasFeedbackFor.includes('error');
        Array.from(this.fieldset?.children || []).forEach(
            (el: Element | null) => {
                if (
                    // eslint-disable-next-line no-undef
                    typeof (<HTMLElementTagNameMap['hv-input']>el)?.validate ===
                    'function'
                ) {
                    // eslint-disable-next-line no-undef
                    const input = <HTMLElementTagNameMap['hv-input']>el;
                    input.dirty = true;
                    input.touched = true;
                    input.validate({
                        clearCurrentResult: true,
                    });
                    valid = valid && !input.hasFeedbackFor.includes('error');
                }
            },
        );

        // Cancel on validation issues
        if (!valid) {
            return;
        }

        // Skip if we are already running a job
        if (this.job) {
            await this.job;
            return;
        }

        // Run and save job promise to mark as busy
        this.job = this.submit(this.values);
        await this.job;

        // Clear job
        this.job = undefined;
    }

    private static fieldType(type: FormFieldType): string {
        switch (type) {
            case 'current-password':
            case 'new-password':
                return 'password';
            case 'off':
            case 'username':
            default:
                return 'text';
        }
    }

    private static preventDefault(e: Event): void {
        e.preventDefault();
    }
}
