import { css } from 'lit-element';

export default css`
    .loginWrapper {
        display: flex;
        flex-direction: column;
    }
    .loginWrapper .loginButton {
        margin: 12px 0;
    }
`;
