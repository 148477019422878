import { html, TemplateResult } from 'lit-html';
import { capitalizeFirstCharacter } from '../../../helpers/string';
import {
    convertDateTo,
    DEFAULT_LOCALE,
    isValidDate,
} from '../../../helpers/dates';
import { InvoiceInfo } from 'types/documents';
import { convertToLocalCurrency } from '../../../helpers/price';

export const renderTemplateCapitalizeFirstCharater = (
    str: string,
    _: any,
): TemplateResult => {
    return html`${capitalizeFirstCharacter(str)}`;
};

export const renderTemplateAddCurrency = (
    str: string | number,
    _: any,
): TemplateResult => {
    return html`${convertToLocalCurrency(str)}`;
};

export const renderTemplateDate = (value: string, _: any): TemplateResult => {
    const isValid = isValidDate(value);
    if (!isValid) {
        console.warn('The given date is not a valid date');
        return html`${value}`;
    }
    const date: Date = new Date(value);
    const dateFormat = {
        month: 'short',
        year: 'numeric',
    } as Intl.DateTimeFormatOptions;
    return html`${renderTemplateCapitalizeFirstCharater(
        convertDateTo(date, dateFormat, DEFAULT_LOCALE),
        null,
    )}`;
};

export const renderTemplatePeriod = (
    value: string,
    _: InvoiceInfo,
): TemplateResult => {
    const isValid = isValidDate(value);
    if (!isValid) {
        console.warn('The given date is not a valid date');
        return html`${value}`;
    }
    return html`${renderTemplateCapitalizeFirstCharater(
        value.split(' ').join('. '),
        null,
    )}`;
};
