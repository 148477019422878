import {
    customElement,
    LitElement,
    TemplateResult,
    html,
    css,
    CSSResult,
} from 'lit-element';

@customElement('hv-progress-bar-label')
export class HvProgressBarLabel extends LitElement {
    public static get styles(): CSSResult {
        return css`
            :host {
                --hv-progress-bar-label-font-size: 16px;
                --hv-progress-bar-label-color: #777777;
                --hv-progress-bar-label-padding: 20px 20px 6px 20px;
                display: flex;
                text-transform: uppercase;
                font-size: var(--hv-progress-bar-label-font-size);
                color: var(--hv-progress-bar-label-color);
                padding: var(--hv-progress-bar-label-padding);
            }
        `;
    }

    public render = (): TemplateResult => html`<slot></slot>`;
}

declare global {
    interface HTMLElementTagNameMap {
        'hv-progress-bar-label': HvProgressBarLabel;
    }
}
