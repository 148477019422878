import { css } from 'lit-element';

export default css`
    #INJECT__themeStyle_user-settings-edit {
        content: 'user-settings-edit';
    }
    :host {
        display: block !important;
    }

    h1 {
        font-family: var(--primary-font-family);
        font-size: 26px;
        font-weight: 400;
        line-height: 0.73;
        letter-spacing: -0.5px;
        color: var(--primary-color);
        margin-bottom: 0;
    }

    .content-wrapper {
        padding: 20px 40px;
        font-family: var(--primary-font-family);
        max-height: calc(100vh - 134px);
    }

    .settings-selector {
        display: block;
        margin-top: 35px;
        border-top: 1px solid #eee;
        font-size: 14px;
    }

    .settings-selector a {
        text-decoration: none;
        cursor: pointer;
        display: block;
        border-bottom: 1px solid #eee;
        padding: 15px 0;
        color: #999;
        font-weight: 400;
    }

    @media only screen and (max-device-width: 820px) {
        h1 {
            margin-left: 20px;
        }
    }
`;
