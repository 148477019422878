import { ReduxAsync } from '@weavedev/redux-async';
import axios, { AxiosResponse } from 'axios';
import { gqlResponse } from '@async-reducers/helpers/gql';
import enums from 'enums';
import { createCostReportsPerMonth } from '@components/templates/ez-usage-template/chartJSHelpers';
import headerParser from '../sagas/helpers/headers';
import { UsageType } from './helpers/costReports';

const monthsShort = [
    'jan',
    'feb',
    'mrt',
    'apr',
    'mei',
    'jun',
    'jul',
    'aug',
    'sep',
    'okt',
    'nov',
    'dec',
];
const costIndex = 0;
const estimationIndex = 1;

const LIST_COST_REPORTS = 'LIST_COST_REPORTS';
const LIST_COST_REPORTS_SUCCESS = 'LIST_COST_REPORTS_SUCCESS';
const LIST_COST_REPORTS_FAILED = 'LIST_COST_REPORTS_FAILED';
export const ListCostReports = new ReduxAsync(
    LIST_COST_REPORTS,
    LIST_COST_REPORTS_SUCCESS,
    LIST_COST_REPORTS_FAILED,
    async (
        endDate: Date,
        snapshotID: string,
        startDate: Date,
        productType?: typeof enums.ProductType | null,
    ): Promise<any> => {
        const headers = window.STDHeaders;
        headers.append('vendors', window.VENDOR);
        const response: AxiosResponse = await axios.post(
            '/v1/gql',
            {
                query: `
                    query ListCostReports(
                        $endDate: Time!,
                        $productType: Int,
                        $snapshotID: UUID!,
                        $startDate: Time!)
                    {
                        listCostReports(input: {
                            endDate: $endDate,
                            estimation: false,
                            productType: $productType,
                            snapshotID: $snapshotID,
                            startDate: $startDate 
                        }
                        ){
                            totalIncl
                            totalUsage
                            readingDate
                            productType
                        }
                    }
                `,
                variables: {
                    endDate,
                    productType,
                    snapshotID,
                    startDate,
                },
            },
            {
                baseURL: window.API_LINK,
                headers: headerParser(headers),
            },
        );

        // if a pure gql error occurs, gql will always send a 200 regardless
        // in case there is not a 200, a different error, like a network error could have occurred
        if (
            response &&
            response.statusText !== 'OK' &&
            response.status !== 200
        ) {
            throw response.status;
        }
        const gqlData = response.data as gqlResponse;
        if (!gqlData.errors?.length) {
            return response.data;
        }
        window.displayMessage(
            'Er is iets misgegaan met het ophalen van de jaardata.',
            'warning',
        );
        throw gqlData.errors[0]?.message;
    },
);

const LIST_COST_REPORTS_ESTIMATIONS = 'LIST_COST_REPORTS_ESTIMATIONS';
const LIST_COST_REPORTS_ESTIMATIONS_SUCCESS =
    'LIST_COST_REPORTS_ESTIMATIONS_SUCCESS';
const LIST_COST_REPORTS_ESTIMATIONS_FAILED = 'LIST_CCOSE_REPORTS_FAILED';
export const ListCostReportsEstimations = new ReduxAsync(
    LIST_COST_REPORTS_ESTIMATIONS,
    LIST_COST_REPORTS_ESTIMATIONS_SUCCESS,
    LIST_COST_REPORTS_ESTIMATIONS_FAILED,
    async (
        endDate: Date,
        snapshotID: string,
        startDate: Date,
        productType?: typeof enums.ProductType | null,
    ): Promise<any> => {
        const headers = window.STDHeaders;
        headers.append('vendors', window.VENDOR);
        const response: AxiosResponse = await axios.post(
            '/v1/gql',
            {
                query: `
                    query ListCostReports(
                        $endDate: Time!,
                        $productType: Int,
                        $snapshotID: UUID!,
                        $startDate: Time!)
                    {
                        listCostReports(input: {
                            endDate: $endDate,
                            estimation: true,
                            productType: $productType,
                            snapshotID: $snapshotID,
                            startDate: $startDate 
                        }
                        ){
                            totalIncl
                            totalUsage
                            readingDate
                            productType
                        }
                    }
                `,
                variables: {
                    endDate,
                    productType,
                    snapshotID,
                    startDate,
                },
            },
            {
                baseURL: window.API_LINK,
                headers: headerParser(headers),
            },
        );

        // if a pure gql error occurs, gql will always send a 200 regardless
        // in case there is not a 200, a different error, like a network error could have occurred
        if (
            response &&
            response.statusText !== 'OK' &&
            response.status !== 200
        ) {
            throw response.status;
        }
        const gqlData = response.data as gqlResponse;
        if (!gqlData.errors?.length) {
            return response.data;
        }
        window.displayMessage(
            'Er is iets misgegaan met het ophalen van de jaarestimations.',
            'warning',
        );
        throw gqlData.errors[0]?.message;
    },
);

/**
 * createCostReportsData creates a usage chart object used in usages and cost charts
 * @param usageType
 * @returns UsageType
 */
const createCostReportsData = (
    usageType: typeof enums.UsageType,
): UsageType => {
    const CostUsageAndEstimationData = {
        labels: [],
        datasets: [],
        __attachedCostData: [],
        __dataLabels: [],
        intervalType: enums.IntervalTypeYearly,
        usage_type: usageType,
        failure: false,
        isFetching: true,
    } as UsageType;
    CostUsageAndEstimationData.labels = monthsShort;
    CostUsageAndEstimationData.__dataLabels = monthsShort;

    // datasets is the usage
    // data array 0 is usages
    // data array 1 is estimated usage
    CostUsageAndEstimationData.datasets.push({ data: [] });
    CostUsageAndEstimationData.datasets.push({ data: [] });
    // business usages could be removed in the future (is required till then)
    CostUsageAndEstimationData.datasets.push({ data: [] });
    // Attached data is the price
    // Attached data array 0 is costs
    // Attached data array 1 is business cost
    CostUsageAndEstimationData.__attachedCostData.push({ data: [] });
    CostUsageAndEstimationData.__attachedCostData.push({ data: [] });

    return CostUsageAndEstimationData;
};

/**
 * CostReportsWithEstimations adds the results from the report and report estimations
 * to usage chart data object
 */
const COST_REPORTS_CHART_DATA = 'COST_REPORTS_CHART_DATA';
const COST_REPORTS_CHART_DATA_SUCCESS = 'COST_REPORTS_CHART_DATA_SUCCESS';
const COST_REPORTS_CHART_DATA_FAILED = 'COST_REPORTS_CHART_DATA_FAILED';
export const CostReportsWithEstimations = new ReduxAsync(
    COST_REPORTS_CHART_DATA,
    COST_REPORTS_CHART_DATA_SUCCESS,
    COST_REPORTS_CHART_DATA_FAILED,
    async (
        error: boolean,
        usageType: typeof enums.UsageType,
    ): Promise<UsageType> => {
        // create initial object for the cost usage and estimation data
        const CostUsageAndEstimationData = createCostReportsData(usageType);
        // when en error occurs return the empty object with an error
        if (error) {
            CostUsageAndEstimationData.failure = error;
            CostUsageAndEstimationData.isFetching = error;
            return CostUsageAndEstimationData;
        }
        const costReports =
            window.store.getState().listCostReports?.data.data.listCostReports;
        const costReportsEstimations =
            window.store.getState().ListCostReportsEstimations?.data.data
                .listCostReports;

        const allCostReportsPerMonth = createCostReportsPerMonth(costReports);
        const allEstimationsPerMonth = createCostReportsPerMonth(
            costReportsEstimations,
        );
        if (allCostReportsPerMonth) {
            CostUsageAndEstimationData.datasets[costIndex].data =
                allCostReportsPerMonth.map((report) =>
                    !usageType ? report.totalIncl : report.totalUsage,
                );
            CostUsageAndEstimationData.__attachedCostData[costIndex].data =
                allCostReportsPerMonth.map((report) =>
                    !usageType ? report.totalUsage : report.totalIncl,
                );
        }
        if (allEstimationsPerMonth) {
            CostUsageAndEstimationData.datasets[estimationIndex].data =
                allEstimationsPerMonth.map((report) =>
                    !usageType ? report.totalIncl : report.totalUsage,
                );
        }
        CostUsageAndEstimationData.isFetching = false;

        return CostUsageAndEstimationData;
    },
);
