import { css } from 'lit-element';

export default css`
    :host {
        position: relative;
        border-bottom: 1px solid var(--primary-color);
        cursor: pointer;
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none; /* Non-prefixed version, currently
                            supported by Chrome, Opera and Firefox */
    }
    :host(:focus) {
        outline: none;
    }
    :host([hideText='false']) {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
    }
    :host([hideText='false']) checkbox-wrapper {
        min-height: calc(var(--checkbox-height) + 5px);
    }
    :host([hidden]) {
        display: none;
    }
    :host(:last-of-type) {
        border-bottom: none;
    }

    :host([theme='light']) p {
        color: var(--secondary-color);
    }
    :host([theme='dark']) #checkbox,
    :host([theme='dark']) p {
        color: var(--secondary-color);
    }
    :host([theme='dark']) #checkbox {
        border: var(--checkbox-border-width) solid var(--primary-color);
    }

    p {
        margin: 0 0 0 12px;
        line-height: initial;
        flex-grow: 1;
    }
    #checkbox {
        -moz-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        -webkit-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        width: var(--checkbox-width);
        height: var(--checkbox-height);
        border-radius: var(--checkbox-border-radius);
        border: var(--checkbox-border-width) solid #d7dbe1;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    :host(.selected) #checkbox {
        border: var(--checkbox-border-width) solid var(--primary-color);
        background-color: var(--primary-color);
    }
    :host(:hover) #checkbox {
        border: var(--checkbox-border-width) solid var(--primary-color);
    }
    .checkbox-wrapper {
        display: flex;
        align-items: center;
        flex-grow: 1;
        font-family: var(-primary-font-family);
        font-size: 13px;
        font-weight: 400;
        font-style: normal;
        font-stretch: normal;
        line-height: 20px;
        letter-spacing: normal;
        color: var(--secondary-color);
    }
`;
