import { provider } from '@weavelab/frontend-connect';
import {
    LitElement,
    PropertyValues,
    TemplateResult,
    customElement,
    html,
    property,
} from 'lit-element';
import { connect, watch } from 'lit-redux-watch';
import { Snapshot } from 'types/snapshot';
import { setSelectedSnapshot } from '../../../../actions/snapshot';
import '../../../../components/elements/dropdown-selector-component/DropdownSelector';
import { convertDateTo } from '../../../../helpers/dates';
import { isPageMobile } from '../../../../helpers/mobileHelpers';
import { store } from '../../../../store';

const dateFormat = {
    month: 'short',
    year: 'numeric',
} as Intl.DateTimeFormatOptions;
const themeSettings = provider.settings('invoices');

@customElement('information-header')
export class InformationHeader extends connect(store)(LitElement) {
    @property({ type: Boolean, reflect: true })
    public isMobile: boolean = isPageMobile();

    @watch('jwtSnapshots.sortedByLatestSnapshot')
    private sortedSnapshots?: Snapshot[];

    @watch('jwtSnapshots.snapshots')
    private snapshots?: Snapshot[];

    @watch('jwtSnapshots.selectedSnapshot')
    private selectedSnapshot?: Snapshot;

    @property({ type: Boolean })
    public showSupplierLogo: boolean = false;

    @property({ type: Boolean })
    public allowFutureSnapshots: boolean = false;

    @property({ type: String })
    public supplierLogo: string = '';

    @property({ type: Object })
    public showSupplierInformation = themeSettings?.showSupplierInformation
        ? themeSettings.showSupplierInformation
        : false;

    @property({ type: Boolean, reflect: true })
    public hidden: boolean = false;

    public render = (): TemplateResult =>
        this.sortedSnapshots
            ? html`
                  <style>
                      :host {
                          --background-color: #fff;

                          background-color: var(--background-color);
                      }
                      :host([isMobile]) header {
                          font-size: 12px;
                          padding: 0 0 0 24px;
                      }

                      header {
                          display: flex;
                          align-items: center;
                          flex: 1 0 100%;
                          justify-content: space-between;
                          padding: 0 24px;
                          border-bottom: 2px solid #e1e1e1;
                      }
                      .snapshot__information {
                          display: flex;
                          flex-direction: row;
                          align-items: center;
                      }
                      .snapshot__information img {
                          max-height: 50px;
                      }
                  </style>
                  <header id="contract">
                      <div class="description">Huidige weergave:</div>
                      <div class="snapshot__information">
                          ${this.selectedSnapshot &&
                          this.showSupplierInfo(this.selectedSnapshot)
                              ? html`
                                    <div class="supplier__logo">
                                        ${this.showSupplierLogo &&
                                        this.selectedSnapshot?.external_supplier
                                            ?.logo_url !== ''
                                            ? html`
                                                  <img
                                                      src="${this.getSupplierLogo(
                                                          this.selectedSnapshot,
                                                      )}"
                                                  />
                                              `
                                            : null}
                                        ${this.showSupplierLogo &&
                                        this.selectedSnapshot?.external_supplier
                                            ?.name !== ''
                                            ? html`
                                                  <p
                                                      classs="supplier__logo--text"
                                                  >
                                                      ${this.selectedSnapshot
                                                          .external_supplier
                                                          .name}
                                                  </p>
                                              `
                                            : null}
                                    </div>
                                `
                              : null}
                          <div id="contract__dropdown">
                              <dropdown-selector-component
                                  placeholder="Selecteer een energiecontract"
                                  item-label="name"
                                  item-value="id"
                                  items-prop="verified_snapshot_payload"
                                  .items="${this.filterSnapshots(
                                      this.sortedSnapshots,
                                  )}"
                                  value="${this.selectedSnapshot
                                      ? this.selectedSnapshot.id
                                      : ''}"
                                  position-submenu="bottom"
                                  .onRender="${this.rendererSnapshotStartEnd}"
                                  ?hidden="${this.sortedSnapshots == null}"
                                  ?disabled=${this.sortedSnapshots.length <= 1}
                                  @form-element-changed=${this
                                      .selectedSnapshotChanged}
                              ></dropdown-selector-component>
                          </div>
                      </div>
                  </header>
              `
            : html``;

    protected updated(changedProperties: PropertyValues) {
        if (changedProperties.has('sortedSnapshots')) {
            if (this.showSupplierInformation) {
                this.hidden = false;
            } else {
                this.hidden = !(
                    this.sortedSnapshots && this.sortedSnapshots.length > 1
                );
            }
        }
    }

    private selectedSnapshotChanged = (e: CustomEvent): void => {
        e.stopImmediatePropagation();
        // @ts-ignore
        const selectedSnapshotID = e.detail.value;
        if (
            this.selectedSnapshot &&
            this.snapshots &&
            this.snapshots.length &&
            selectedSnapshotID !== this.selectedSnapshot.id
        ) {
            const snapshot = this.snapshots.find(
                (snap: Snapshot) => snap.id === selectedSnapshotID,
            );
            if (snapshot) {
                store.dispatch(setSelectedSnapshot(snapshot));
                return;
            }

            console.warn(`couldn't find the selected snapshot`);
        }
    };

    // showSupplierInformation checks if logo or are available and vendor allows showing the suppliers logo.
    private showSupplierInfo = (snapshot: Snapshot): boolean => {
        const externalSupplier = snapshot.external_supplier;
        if (externalSupplier == null || externalSupplier.logo_url == null) {
            return false;
        }

        const check =
            externalSupplier.logo_url !== '' || externalSupplier.name !== '';
        this.showSupplierLogo = externalSupplier.logo_url !== '';
        return this.showSupplierInformation && check;
    };

    // Returns outer wrapper based on page
    private rendererSnapshotStartEnd = (_: any, snapshot: Snapshot): string => {
        const snap = snapshot.verified_snapshot_payload;
        if (snap == null) {
            return ``;
        }
        let endDate = snap.end_date
            ? snap.end_date
            : snap.delivery_stopped_date;
        if (
            endDate == null ||
            endDate === '' ||
            new Date(endDate).getTime() > Date.now()
        ) {
            if (new Date(snap.start_date).getTime() > Date.now()) {
                return `vanaf ${convertDateTo(snap.start_date, dateFormat)}`;
            }
            endDate = 'huidig';
        } else {
            endDate = `${convertDateTo(endDate, dateFormat)}`;
        }
        return `${convertDateTo(snap.start_date, dateFormat)} - ${endDate}`;
    };

    private filterSnapshots = (sortedSnapshots: Snapshot[]): Snapshot[] => {
        if (this.allowFutureSnapshots) {
            return sortedSnapshots;
        }
        const now = new Date().getTime();
        const sort = sortedSnapshots.filter(
            (snapshot) =>
                new Date(
                    snapshot.verified_snapshot_payload.start_date,
                ).getTime() < now,
        );
        return sort;
    };

    private getSupplierLogo = (snapshot: Snapshot): string => {
        if (snapshot && snapshot.external_supplier) {
            this.supplierLogo = snapshot.external_supplier.logo_url;
        }

        return this.supplierLogo;
    };
}

declare global {
    interface HTMLElementTagNameMap {
        'information-header': InformationHeader;
    }
}
