import {
    Bearer,
    BearerSupportedTypes,
    ISODate,
    ISODateSupportedTypes,
    ParameterDefinitionsObject,
    ParameterIn,
    RequestAction,
    RequestOptions,
    RequestSagaIterableIterator,
    ResolvableOrType,
    Resource,
    ResourceOptions,
    UUID,
    UUIDSupportedTypes,
} from 'goagen-js-resource';
import { Method } from 'axios';

export interface ListWorkDaysScheduleRequestObject {
    // (ISODate)
    endDate?: ISODateSupportedTypes;
    // Settled (Boolean)
    settled?: boolean;
    // Snapshot ID (UUID)
    snapshotId?: UUIDSupportedTypes;
    // (ISODate)
    startDate?: ISODateSupportedTypes;
    // Injected Bearer header (Bearer)
    xAuth?: BearerSupportedTypes;
}

export interface ResolvableListWorkDaysScheduleRequestObject {
    // (ISODate)
    endDate?: ResolvableOrType<ListWorkDaysScheduleRequestObject['endDate']>;
    // Settled (Boolean)
    settled?: ResolvableOrType<ListWorkDaysScheduleRequestObject['settled']>;
    // Snapshot ID (UUID)
    snapshotId?: ResolvableOrType<
        ListWorkDaysScheduleRequestObject['snapshotId']
    >;
    // (ISODate)
    startDate?: ResolvableOrType<
        ListWorkDaysScheduleRequestObject['startDate']
    >;
    // Injected Bearer header (Bearer)
    xAuth?: ResolvableOrType<ListWorkDaysScheduleRequestObject['xAuth']>;
}

/**
 * Store member management object for listWorkDays schedule
 */
export class ListWorkDaysSchedule extends Resource<
    ListWorkDaysScheduleRequestObject,
    ResolvableListWorkDaysScheduleRequestObject
> {
    /**
     * Create new ListWorkDaysSchedule
     *
     * | Parameter |  | Target |  |  |
     * | --- | --- | --- | --- | --- |
     * | `endDate` | `ISODate` | query | `endDate` |  |
     * | `settled` | `Boolean` | query | `settled` | Settled |
     * | `snapshotId` * | `UUID` | query | `snapshotID` | Snapshot ID |
     * | `startDate` | `ISODate` | query | `startDate` |  |
     * | `xAuth` | `Bearer` | header | `X-Auth` | Injected Bearer header |
     */
    constructor(
        storeName: string,
        options: ResourceOptions<ResolvableListWorkDaysScheduleRequestObject> = {},
    ) {
        super(storeName, options);
    }

    /**
     * Generate request action
     *
     * | Parameter |  | Target |  |  |
     * | --- | --- | --- | --- | --- |
     * | `endDate` | `ISODate` | query | `endDate` |  |
     * | `settled` | `Boolean` | query | `settled` | Settled |
     * | `snapshotId` * | `UUID` | query | `snapshotID` | Snapshot ID |
     * | `startDate` | `ISODate` | query | `startDate` |  |
     * | `xAuth` | `Bearer` | header | `X-Auth` | Injected Bearer header |
     */
    public request(
        requestObject: ListWorkDaysScheduleRequestObject = {},
        requestOptions: RequestOptions = {},
    ): RequestAction<ListWorkDaysScheduleRequestObject> {
        return super.request(requestObject, requestOptions);
    }

    /**
     * Run request as saga
     *
     * | Parameter |  | Target |  |  |
     * | --- | --- | --- | --- | --- |
     * | `endDate` | `ISODate` | query | `endDate` |  |
     * | `settled` | `Boolean` | query | `settled` | Settled |
     * | `snapshotId` * | `UUID` | query | `snapshotID` | Snapshot ID |
     * | `startDate` | `ISODate` | query | `startDate` |  |
     * | `xAuth` | `Bearer` | header | `X-Auth` | Injected Bearer header |
     */
    // @ts-ignore
    public get requestSaga(): (
        requestObject?: ListWorkDaysScheduleRequestObject,
        requestOptions?: RequestOptions,
    ) => RequestSagaIterableIterator<
        RequestAction<ListWorkDaysScheduleRequestObject>
    > {
        return super.requestSaga;
    }

    /**
     * Action string
     */
    static get action(): string {
        return 'listWorkDays';
    }

    /**
     * Description string
     */
    static get description(): string {
        return 'List workdays for a specific snapshot';
    }

    /**
     * Request method
     */
    static get method(): Method {
        return 'get';
    }

    /**
     * Resource string
     */
    static get resource(): string {
        return 'schedule';
    }

    /**
     * Route string
     */
    static get route(): string {
        return '/schedule/workday';
    }

    /**
     * Unique name string
     */
    static get uniqueName(): string {
        return 'ListWorkDaysSchedule';
    }

    /**
     * Parameters object
     */
    static get parameters(): ParameterDefinitionsObject {
        return {
            endDate: {
                in: ParameterIn.query,
                name: 'endDate',
                type: ISODate,
            },
            settled: {
                description: 'Settled',
                in: ParameterIn.query,
                name: 'settled',
                type: Boolean,
            },
            snapshotId: {
                description: 'Snapshot ID',
                in: ParameterIn.query,
                name: 'snapshotID',
                required: true,
                type: UUID,
            },
            startDate: {
                in: ParameterIn.query,
                name: 'startDate',
                type: ISODate,
            },
            xAuth: {
                description: 'Injected Bearer header',
                in: ParameterIn.header,
                name: 'X-Auth',
                type: Bearer,
            },
        };
    }
}
