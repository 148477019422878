import '@polymer/iron-iconset-svg/iron-iconset-svg';
import { provider } from '@weavelab/frontend-connect';

const themeSettings = provider.settings('menu-icons');
const $documentContainer = document.createElement('template');
$documentContainer.setAttribute('style', 'display: none;');

// @ts-ignore
$documentContainer.innerHTML =
    themeSettings.icons ||
    `<iron-iconset-svg name="ez" size="24">
    <svg>
    <defs>
        <svg id="dot" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
            <path d="M38.42 25c0 7.38-5.78 13-13.42 13s-13.42-5.62-13.42-13S17.36 
                12 25 12s13.42 5.55 13.42 13z" fill="#ff6f49" />
        </svg>
        <svg id="home" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
            <defs>
                <style>
                    .cls-1 {
                        fill: #aaa
                    }
                </style>
            </defs>
            <path class="cls-1" d="M12.5 12.5h9.89v9.89H12.5zM27.61 12.5h9.89v9.89h-9.89zM12.5 27.61h25v9.89h-25z" />
        </svg>
        <svg id="stroom" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
            <path d="M13.73 7.5h20L28 20.55h9L17.24 42.49l1.38-16.54H13l.75-18.36z" fill="#32cd32"/>
        </svg>
        <svg id="coins" xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
            <g fill="none" fill-rule="evenodd">
                <path fill="#FF6F49" d="M14.23.918c7.351 0 13.31 5.96 13.31 13.312 0 7.351-5.959 13.31-13.31
                    13.31C6.878 27.54.918 21.582.918 14.23.918 6.878 6.878.918 14.23.918z"/>
                <path fill="#FFFEFE" d="M19.626 17.69l1.441 1.63c-1.462 1.462-3.572 2.402-5.66 2.402-3.405
                    0-6.204-2.047-7.207-5.013H5.923v-1.337h1.964a6.185 6.185 0 0
                    1-.084-1.087c0-.376.02-.752.084-1.107H5.923v-1.336h2.298c1.023-2.904 3.843-4.91 7.29-4.91
                    2.068 0 4.136.857 5.535 2.257l-1.42 1.796c-1.086-1.149-2.59-1.859-4.094-1.859-2.005 0-3.74
                    1.086-4.617 2.716h5.243v1.336h-5.744c-.063.355-.104.71-.104 1.087 0 .376.041.752.125
                    1.107h5.723v1.337h-5.243c.878 1.629 2.612 2.715 4.617 2.715 1.462 0 2.966-.668 4.094-1.734z"/>
            </g>
        </svg>

        <svg id="gas" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
            <path d="M22.52 7.5c0 15-11 11.91-11 22.69a13.14 13.14 0 0 0 
                8.77 12.31 6.49 6.49 0 0 1-1.6-3.88c0-3.28 6-8.2 6.27-8.45.3.25 6.27 5.17 6.27 
                8.45a6.49 6.49 0 0 1-1.6 3.88 13.14 13.14 0 0 0 8.87-12.28C38.5 19.1 23.31 8.07 22.52 7.5z"
                fill="#3cc6e5" />
        </svg>
        <svg id="production" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
            <defs>
                <style>
                    .cls-1 {
                        fill: #ffc107
                    }
                </style>
            </defs>
            <circle class="cls-1" cx="24.78" cy="25.22" r="6.8" />
            <path class="cls-1" d="M16.47 18a1.52 1.52 0 0 1-1.07-.45l-3.08-3.08a1.59 
                1.59 0 0 1-.17-2.12 1.53 1.53 0 0 1 2.25-.11l3.07 3.08a1.61 1.61 0 0 1 .15 
                2.16 1.53 1.53 0 0 1-1.15.52zM36.68 38.2a1.55 1.55 0 0 1-1.08-.44l-3.08-3.08a1.6 1.6 0 0 
                1-.16-2.13 1.52 1.52 0 0 1 2.24-.1l3.08 3.07a1.61 1.61 0 0 1 .15 2.16 1.53 1.53 0 0 
                1-1.15.52zM24.86 15a1.59 1.59 0 0 1-1.38-1.62V9.13a1.59 1.59 0 0 1 1.38-1.62A1.52 1.52 
                0 0 1 26.52 9v4.44A1.52 1.52 0 0 1 24.86 15zM24.86 42.49a1.59 1.59 0 0 1-1.38-1.62v-4.22A1.59 
                1.59 0 0 1 24.86 35a1.52 1.52 0 0 1 1.66 1.51V41a1.52 1.52 0 0 1-1.66 1.49zM41 26.52h-4.35A1.59 
                1.59 0 0 1 35 25.14a1.52 1.52 0 0 1 1.51-1.66h4.33a1.59 1.59 0 0 1 1.62 1.38A1.52 1.52 0 
                0 1 41 26.52zM13.46 26.52H9.13a1.59 1.59 0 0 1-1.62-1.38A1.52 1.52 0 0 1 9 23.48h4.33A1.59 
                1.59 0 0 1 15 24.86a1.52 1.52 0 0 1-1.54 1.66zM33.53 18a1.53 1.53 0 0 1-1.15-.52 1.61 
                1.61 0 0 1 .15-2.16l3-3a1.59 1.59 0 0 1 2.12-.17 1.53 1.53 0 0 1 .11 2.25l-3.16 3.15a1.52 
                1.52 0 0 1-1.07.45zM13.32 38.2a1.53 1.53 0 0 1-1.15-.52 1.61 1.61 0 0 1 .15-2.16l3-3a1.6 
                1.6 0 0 1 2.13-.16 1.52 1.52 0 0 1 .1 2.24l-3.15 3.16a1.55 1.55 0 0 1-1.08.44z"
            />
        </svg>
        <svg id="hamburger" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" 
            viewBox="0 0 50 50"><defs><style>.cls-1{fill:#444}</style></defs><path 
            class="cls-1" d="M43.2 27.5a2.5 2.5 0 0 0 0-5H6.8a2.5 2.5 0 1 0 0 5zM43.2 
            14.5a2.5 2.5 0 0 0 0-5H6.8a2.5 2.5 0 1 0 0 5zM43.2 40.5a2.5 2.5 0 0 0 
            0-5H6.8a2.5 2.5 0 1 0 0 5z"/></svg>
        <svg id="arrow-left" xmlns="http://www.w3.org/2000/svg" width="9" height="16" viewBox="0 0 9 16">
        <path fill="none" fill-rule="evenodd" stroke="#8899A9" stroke-linecap="round" 
            stroke-linejoin="round" stroke-width="2" d="M8 15L1 8l7-7"
        />
      </svg>
      <svg id="arrow-right" xmlns="http://www.w3.org/2000/svg" width="9" height="16" viewBox="0 0 9 16">
        <path fill="none" fill-rule="evenodd" stroke="#8899A9" 
            stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 15l7-7-7-7"
        />
      </svg>
        </defs>
    </svg>
</iron-iconset-svg>`;

if (document.head != null) {
    document.head.appendChild($documentContainer.content);
}
