import { LitElement, PropertyValues, property } from 'lit-element';

export type inputThemeType = 'default' | 'borderless';

export class TextComponentBehavior extends LitElement {
    @property()
    public src?: string;

    @property({ type: String })
    public value: string | undefined = '';

    @property({ type: String, reflect: true })
    public pattern?: string;

    @property({ attribute: 'min', type: Number })
    public min?: number;

    @property({ attribute: 'max', type: Number })
    public max?: number;

    @property({ type: Boolean })
    public rounded: boolean = false;

    @property({ attribute: 'min-length', type: Number })
    public minlength?: number;

    @property({ attribute: 'max-length', type: Number })
    public maxlength?: number;

    @property()
    public id: string = '';

    @property()
    public placeholder: string = '';

    @property()
    public suffix?: string;

    @property()
    public inputSuffix?: string;

    // default properties
    @property()
    public type: string = 'text';

    @property({ attribute: 'theme', reflect: true })
    public theme: string = 'default';

    @property({
        type: Boolean,
    })
    public required: boolean = false;

    @property({ type: Boolean })
    public margin: boolean = true;

    @property({ type: Boolean, reflect: true })
    public error: boolean = false;

    @property({ attribute: 'validate-on-focus-out', type: Boolean })
    public validateOnFocusOut: boolean = true;

    @property({
        attribute: 'throttle-value',
        type: Boolean,
    })
    public throttleValue: boolean = false;

    @property({
        attribute: 'auto-validate',
        type: Boolean,
        reflect: true,
    })
    public autoValidate: boolean = false;

    @property({
        attribute: 'error-message',
        reflect: true,
    })
    public errorMessage?: string;

    @property({ type: Object })
    public input: HTMLInputElement | null = null;

    @property({ attribute: 'image-width' })
    public imageWidth?: string;

    @property({ attribute: 'image-height' })
    public imageHeight?: string;

    @property({ type: Boolean })
    private validity: boolean = false;

    @property({ type: Boolean })
    public trim: boolean = false;

    public attributeChangedCallback(
        name: string,
        oldVal: string,
        newVal: string,
    ): void {
        super.attributeChangedCallback(name, oldVal, newVal);
        if (name && name === 'value') {
            this.value = newVal;
        }
    }

    protected updated(changes: PropertyValues) {
        let htmlInput = this.shadowRoot!.querySelector(
            'input',
        ) as HTMLInputElement;
        if (htmlInput == null) {
            // @ts-ignore
            htmlInput = this.shadowRoot!.querySelector(
                'textarea',
            ) as HTMLTextAreaElement;
        }
        this.input = htmlInput;
        if (this.input) {
            if (changes.has('minlength') && this.minlength) {
                this.input.min = this.minlength.toString();
            }
            if (changes.has('maxLength') && this.maxlength) {
                this.input.maxLength = this.maxlength;
            }
        }
        if (changes.has('required')) {
            if (this.required) {
                this.setAttribute('required', '');
            } else {
                this.removeAttribute('required');
            }
        }
    }

    public validate = (): boolean => {
        this.error = false;
        // Check if value exist
        if (this.value) {
            if (this.trim) {
                this.value = this.value.trim();
            }
            // Validate the component if required or the value is not empty.
            if (this.required || (this.value != null && this.value !== '')) {
                // Trim the begin and end of the string. (no whitespaces)
                const value =
                    typeof this.value === 'string'
                        ? this.value.trim()
                        : this.value;
                // when value only contained spaces.
                if (value === '' && this.value.length > 0) {
                    this.error = true;
                } else if (this.pattern) {
                    // validate regular expression.
                    const regularExpression = new RegExp(this.pattern);
                    const val = regularExpression.test(value);
                    const check = regularExpression.test(value) && !!value;
                    this.error = !(check || val);
                }
                if (this.minlength && !this.error) {
                    if (value.length <= this.minlength) {
                        this.error = true;
                    }
                }
                if (this.maxlength && !this.error) {
                    if (value.length > this.maxlength) {
                        this.error = true;
                    }
                }
            }
        } else if (this.required) {
            // Show error if the value doesn't exist and validation is required.
            this.error = true;
        }
        return !this.error;
    };

    public checkValidateWithoutError = (): boolean => {
        const val = this.input!.reportValidity();
        const check = this.input!.checkValidity() && !!this.value;
        return !(check || val);
    };

    public changedInput(
        event?: FocusEvent | KeyboardEvent,
        value?: string,
    ): void {
        if (event?.target != null || value) {
            if (event) {
                const ev: HTMLInputElement = event.target as HTMLInputElement;
                this.value = ev.value;
            }

            // check if the input is valid recording the given pattern (pattern)
            this.validity = this.validate();
            if (this.autoValidate) {
                this.error = !this.validity;
            }

            if (
                (this.validateOnFocusOut && event instanceof FocusEvent) ||
                (this.validateOnFocusOut && this.throttleValue)
            ) {
                if (
                    this.min !== undefined &&
                    parseInt(this.value!, 10) <= this.min
                ) {
                    this.value = `${this.min}`;
                }
                if (
                    this.max !== undefined &&
                    parseInt(this.value!, 10) > this.max
                ) {
                    this.value = `${this.max}`;
                }
                /**
                 * Dispatch event for form validation.
                 */
                if (this.value !== '') {
                    const changeEvent: Event = new CustomEvent(
                        'form-element-changed',
                        {
                            bubbles: true,
                            composed: true,
                            detail: {
                                required: this.required,
                                validity: this.validity,
                                element: this,
                                id: this.id,
                                value: this.value,
                            },
                        } as CustomEvent,
                    );

                    this.dispatchEvent(changeEvent);
                }
            }
        }
    }
}

// Interface based on type Event needed for typechecking.
export interface CustomInputEvent extends Event {
    detail: {
        properties: {
            id: string;
            required: boolean;
            validity: boolean;
        };
        value: string;
    };
}

customElements.define('text-component-behavior', TextComponentBehavior);
