import { css } from 'lit-element';

export default css`
    :host {
        --new-price--font-weight: 700;
        --new-price--font-size: 22px;
    }

    .new__price {
        font-weight: var(--new-price--font-weight);
        font-size: var(--new-price--font-size);
    }

    .old__price {
        text-decoration: line-through;
    }
`;
