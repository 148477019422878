import { PolymerElement, html } from '@polymer/polymer/polymer-element';
import { provider } from '@weavelab/frontend-connect';

// @ts-ignore
import watch from 'redux-watch';

import { Router } from '@vaadin/router';
import { routePaths } from 'helpers/routing';
import { debounce } from 'helpers/functions';
import { SelectCapacityListner } from 'helpers/SelectCapacityListner';
import enums from 'enums';
import {
    chooseButtonClicked,
    chooseCapacityChanged,
    chooseOpenDetailsClicked,
    chooseRecalculateClicked,
    chooseSendOfferClicked,
    PriceOfferSend,
    ProductListEventInputChanged,
} from 'gtm-middleware/productList';
import { uiProductListInteraction } from '@actions/ui';
import {
    invalidMessage,
    returnErrorOrEmptyString,
} from 'gtm-middleware/helpers/actionValue';
import css from './product-list.pcss';
import template from './product-list.html';

import '@polymer/paper-button/paper-button';
import '@polymer/iron-flex-layout/iron-flex-layout-classes';
import {
    elecCapacityInfo,
    gasCapacityInfo,
} from '../helpers/tooltipInformation';
import { retrieveOffers } from '../../../../actions/offer';
import {
    addLabel,
    setOffer,
    setCollective,
    clearCollective,
    resetOrder,
    setGeoInformation,
} from '../../../../actions/order';

import '../product-details/product-details';

import '../../../general/ez-dropdown-menu/dropdown-menu';
import '../../../atoms/Popup';
import '../../../atoms/RadioButtonGroup';
import '../../../atoms/RadioButton';
import '../../../atoms/RadioButtons';
import '../../../elements/modal/ModalComponent';
import '../../../elements/base/button/ButtonComponent';
import '../../../elements/base/input-component/InputComponent';
import '../../../elements/base/checkbox-component/CheckboxComponent';
import '../../../elements/base/behavior/error-handling/ErrorHandling';
import '../../../elements/product-information/ProductInformation';
import '../ez-header/EzHeaderVideo';
import '../../../elements/tooltip/Tooltip';
import '../../../capacity/CapacityComponent';

// This element is connected to the Redux store.
// @ts-ignore
import { store, createLead } from '../../../../store';
import { resetEnroll } from '../../../../actions/enroll';
import { NumberToPrice } from '../helpers/number';

const themeSettings = provider.settings('product-list');
const defaultEmailError = `Vul een geldig e-mailadres in.`;
/**
 * Login class
 */
export default class EzProductList extends PolymerElement {
    /** @typedef {import('../../../../actions/offer').Offer} Offer */
    /** @typedef {import('../../../../reducers/order').Order} Order */

    /**
     * Gets properties of class
     */
    static get properties() {
        return {
            name: {
                type: String,
                value: '',
            },
            email: {
                type: String,
                value: '',
            },
            phone: {
                type: String,
                value: '',
            },
        };
    }

    /**
     * Constructor of reset password
     */
    constructor() {
        super();
        this.asset = provider.asset;

        /** @type {Offer} */
        this.offers;
        /** @type {Order} */
        this.order;
        /** @type {Array} */
        this.collectives;
        /** @type {Object} */
        this.selections;
        /** @type {Object} */
        this.showDetails;
        /** @type {Boolean} */
        this.enableToolTip = false;

        /** @type {String | Object | null} */
        this.lastEventObject;
        /** @type {Boolean} */
        this.additionalContractProduct;
        /** @type {Boolean} */
        this.additionalContractProductSelected = false;
        /** @type {Boolean} */
        this.showAdditionalProductGroupModal = false;
        // @ts-ignore
        this.additionalContractProduct =
            themeSettings && themeSettings.additionalContractProduct;
        /** @type {String} */
        this.bannerTitleString;

        this.bannerTitleString =
            themeSettings && themeSettings.bannerTitleString
                ? themeSettings.bannerTitleString
                : '';
        /** @type {String} */
        this.bannerSubTitleString;

        this.bannerSubTitleString =
            themeSettings && themeSettings.bannerSubTitleString
                ? themeSettings.bannerSubTitleString
                : '';
        /** @type {String} */
        this.additionalContractProductTitle;

        this.additionalContractProductTitle =
            themeSettings && themeSettings.additionalContractProductTitle
                ? themeSettings.additionalContractProductTitle
                : '';

        /** @type {String} */
        this.additionalContractProductBody;

        this.additionalContractProductBody =
            themeSettings && themeSettings.additionalContractProductBody
                ? themeSettings.additionalContractProductBody
                : '';

        /** @type {String} */
        this.additionalContractFooter;

        this.additionalContractFooter =
            themeSettings && themeSettings.additionalContractFooter
                ? themeSettings.additionalContractFooter
                : '';

        /** @type {Array} */
        this.additionalContractChecks;

        this.additionalContractChecks =
            themeSettings && themeSettings.additionalContractChecks
                ? themeSettings.additionalContractChecks
                : [];

        /** @type {String} */
        this.additionalContractProductButtonString;

        this.additionalContractProductButtonString =
            themeSettings && themeSettings.additionalContractProductButtonString
                ? themeSettings.additionalContractProductButtonString
                : '';
        /** @type {String} */
        this.additionalContractProductCancelString;

        this.additionalContractProductCancelString =
            themeSettings && themeSettings.additionalContractProductCancelString
                ? themeSettings.additionalContractProductCancelString
                : '';
        /** @type {String} */
        this.additionalContractProductSalesLabel;
        this.additionalContractProductSalesLabel =
            themeSettings && themeSettings.additionalContractProductSalesLabel
                ? themeSettings.additionalContractProductSalesLabel
                : '';
        /** @type {String} */
        this.additionalContractProductCollectiveID;
        this.additionalContractProductCollectiveID =
            themeSettings && themeSettings.additionalContractProductCollectiveID
                ? themeSettings.additionalContractProductCollectiveID
                : '';
        /** @type {Boolean} */
        this.leadContractSelected = true;

        /** @type {String} */
        this.flexDescription =
            themeSettings && themeSettings.flexDescription
                ? themeSettings.flexDescription
                : null;
        /** @type {String} */
        this.vastDescription =
            themeSettings && themeSettings.vastDescription
                ? themeSettings.vastDescription
                : null;

        /** @type {Boolean} */
        this.enableToolTip =
            themeSettings &&
            themeSettings.enableTooltip !== undefined &&
            themeSettings.enableTooltip;

        if (this.enableToolTip) {
            this.toolTipText = themeSettings.tooltipText;
        }

        this.phoneNumberText = themeSettings.phoneNumberText
            ? themeSettings.phoneNumberText
            : 'Laat hier je telefoonnummer achter dan nemen wij (eventueel) contact met je op';

        this.elecCapInfo = themeSettings?.elecCapacityInfo
            ? themeSettings.elecCapacityInfo
            : elecCapacityInfo;
        this.gasCapInfo = themeSettings?.gasCapacityInfo
            ? themeSettings.gasCapacityInfo
            : gasCapacityInfo;

        this.electricityCapacity = enums.TransmissionValueType1x6AGeschakeldNet;
        this.gasCapacity =
            enums.TransmissionValueType10M3uurTussen500M3En4000M3jaar;
    }

    /**
     * onBeforeEnter is a Vaadin router lifecycle method
     * @param {RouterLocation} location
     * @param {PreventAndRedirectCommands} commands
     * @param {Router} router
     * @return {RedirectResult | undefined }
     */
    onBeforeEnter(location, commands) {
        const { order } = store.getState();
        // check if order exist else redirect user to the customer page.
        if (order == null && order.offer != null) {
            return commands.redirect(routePaths.customer);
        }
        // set elec and gas capacity.
        this.electricityCapacity = order.transmissionTypeElec;
        this.gasCapacity = order.transmissionTypeGas;

        return null;
    }

    /**
     * On element ready
     */
    ready() {
        super.ready();
        /** @type {Object} */
        const state = store.getState();

        this.offers = state.offers || {};
        this.__setSelections(this.switches);
        const watchOffer = watch(store.getState, 'offers');
        store.subscribe(
            watchOffer(
                /**
                 * @param {Object} offers
                 * @return {void}
                 */
                (offers) => {
                    // the debounce is mandatory for fetching offers and prevent flickering.
                    // that is because the first offer state returns no offers.
                    debounce(300, () => {
                        this.offers = { ...offers };
                        this.__setSelections(this.switches);
                    });
                },
            ),
        );

        this.order = state.order || {};
        const watchOrder = watch(store.getState, 'order');
        store.subscribe(
            watchOrder(
                /**
                 * @param {Object} order
                 * @return {void}
                 */
                (order) => {
                    this.order = { ...order };
                },
            ),
        );

        this.geocode = state.geocode || {};
        const watchGeocode = watch(store.getState, 'geocode');
        store.subscribe(
            watchGeocode(
                /**
                 * @param {Object} geocode
                 * @return {void}
                 */
                (geocode) => {
                    this.geocode = { ...geocode };
                    if (geocode && geocode.data && geocode.data.street_name) {
                        store.dispatch(setGeoInformation(geocode.data));
                    }
                },
            ),
        );

        this.collectives =
            state.collectives && Array.isArray(state.collectives.data)
                ? state.collectives.data
                : [];
        const watchCollectives = watch(store.getState, 'collectives.data');
        store.subscribe(
            watchCollectives(
                /**
                 * @param {Object} collectives
                 * @return {void}
                 */
                (collectives) => {
                    this.collectives = Array.isArray(collectives)
                        ? collectives
                        : [];
                },
            ),
        );
    }

    /**
     * On connectedCallback
     */
    connectedCallback() {
        super.connectedCallback();
        /** @type {Object} */
        this.switches = {};

        /**
         * Add new switches to switches
         * @param {Object} newSwitches
         */
        const subscribe = (newSwitches) => {
            for (const trackable in newSwitches) {
                if (newSwitches[trackable]) {
                    this.switches = {
                        ...this.switches,
                        [trackable]:
                            newSwitches[trackable].default !== undefined
                                ? !!newSwitches[trackable].default
                                : false,
                    };
                }
            }
        };

        if (this.order && this.order.hasOwnProperty('solarMail')) {
            this.additionalContractProductSelected = this.order.solarMail;
        }

        if (themeSettings.topSwitches) {
            subscribe(themeSettings.topSwitches);
        }

        if (themeSettings.bottomSwitches) {
            subscribe(themeSettings.bottomSwitches);
        }

        this.addEventListener('sl-change', this.selectCapacityListener);
    }

    disconnectedCallback = () => {
        this.removeEventListener('sl-change', this.selectCapacityListener);
        super.disconnectedCallback();
    };

    // update the selected capacity for gas or elec
    selectCapacityListener = (e) => {
        const { capacity, type } = SelectCapacityListner(e);
        if (!capacity || capacity.id === '') {
            return;
        }
        let updateOffers = false;
        // update gas or elek
        if (type === 'gas') {
            if (this.gasCapacity !== capacity.id) {
                this.gasCapacity = capacity.id;
                store.dispatch(
                    uiProductListInteraction.run(
                        chooseCapacityChanged(
                            capacity.name,
                            'doorlaatwaarde-gas',
                        ),
                    ),
                );
                updateOffers = !updateOffers;
            }
        } else if (this.electricityCapacity !== capacity.id) {
            this.electricityCapacity = capacity.id;
            store.dispatch(
                uiProductListInteraction.run(
                    chooseCapacityChanged(
                        capacity.name,
                        'doorlaatwaarde-stroom',
                    ),
                ),
            );
            updateOffers = !updateOffers;
        }
        if (!updateOffers) {
            return;
        }

        if (!this.order) {
            console.warn('Order object is missing');
            return;
        }

        store.dispatch(
            retrieveOffers(
                this.order,
                this.gasCapacity,
                this.electricityCapacity,
            ),
        );
    };

    /**
     * Generate output value string
     * @param {Function?} f
     * @param {Number|String} v
     * @param {String} d
     * @return {String}
     */
    _valueTransform = (f, v, d) =>
        typeof f === 'function' ? f(v) : `${v}${d}`;

    /**
     * Get render object for top switches
     * @return {Array}
     */
    _renderTopSwitches() {
        // @ts-ignore
        return this._renderSwitches(themeSettings.topSwitches);
    }

    /**
     * Get render object for top switches
     * @return {Boolean}
     */
    _renderTopAnchors() {
        // @ts-ignore
        return (
            themeSettings.topAnchors &&
            themeSettings.topAnchors.enabled &&
            window.innerWidth <= themeSettings.topAnchors.maxWidth
        );
    }

    /**
     * @return {Number}
     * @param {Object} elem
     */
    _getOffsetTop(elem) {
        let offsetTop = 0;
        do {
            if (!isNaN(elem.offsetTop)) {
                offsetTop += elem.offsetTop;
            }
        } while ((elem = elem.offsetParent));
        return offsetTop;
    }

    /**
     * @return {Number}
     * @param {Object} elem
     */
    _getOffsetLeft(elem) {
        let offsetLeft = 0;
        do {
            if (!isNaN(elem.offsetLeft)) {
                offsetLeft += elem.offsetLeft;
            }
        } while ((elem = elem.offsetParent));
        return offsetLeft;
    }

    /**
     * Scroll to selected anchor
     * @param {Object} e
     */
    _onTopAnchorClick(e) {
        const anchorTarget = e.target.getAttribute('data-target-anchor');
        if (anchorTarget && this.shadowRoot) {
            const targetElement = this.shadowRoot.querySelector(
                `div[data-anchor="${anchorTarget}"]`,
            );
            if (targetElement && targetElement.parentElement) {
                const otherAnchors = this.shadowRoot.querySelectorAll(
                    'li[data-target-anchor]',
                );
                if (otherAnchors) {
                    // Unselect all anchors
                    otherAnchors.forEach((otherAnchor) => {
                        otherAnchor.classList.remove('selected');
                    });
                }
                // Select current target anchor
                e.target.classList.add('selected');
                const positionInfo = targetElement.getBoundingClientRect();
                const offsetTop =
                    this._getOffsetTop(targetElement) - positionInfo.width / 4;
                const offsetLeft =
                    this._getOffsetLeft(targetElement) - positionInfo.width / 4;
                targetElement.parentElement.scrollTo({
                    left: offsetLeft,
                    top: offsetTop,
                    behavior: 'smooth',
                });
            }
        }
    }

    /**
     * Get render object for bottom switches
     * @param {any} collective
     * @return {Array}
     */
    _renderBottomSwitches(collective) {
        // @ts-ignore
        return !collective
            ? this._renderSwitches(themeSettings.bottomSwitches)
            : false;
    }

    /**
     * Convert switches object to renderable switch array
     * @param {Object} switches
     * @return {Array}
     */
    _renderSwitches(switches) {
        const returnable = [];
        for (const id in switches) {
            if (switches[id]) {
                returnable.push({ ...switches[id], id });
            }
        }
        return returnable;
    }

    /**
     * Get a switch value
     * @param {Object} switches
     * @param {String} id
     * @return {Boolean}
     */
    _getSwitch(switches, id) {
        return switches && id ? switches[id] : false;
    }

    /**
     * Set class if switch
     * @param {Object} switches
     * @param {String} id
     * @param {String} className
     * @return {String}
     */
    _classSwitch(switches, id, className) {
        return this._getSwitch(switches, id) ? ` ${className}` : '';
    }

    /**
     * Check if additional product is toggled
     * @param {Event} event
     */
    _additionalProductSelectorChanged(event) {
        // @ts-ignore
        const { checked } = event.target;
        this.additionalContractProductSelected = checked;
    }

    /**
     * Set all switches as classes
     * @param {Object} switches
     * @return {String}
     */
    _classSwitches(switches) {
        let returnable = '';
        for (const s in switches) {
            if (switches[s]) {
                returnable += this._classSwitch(switches, s, `${s}_switch_on`);
            }
        }
        return returnable;
    }

    /**
     * Set name of switch
     * @param {Object} switches
     * @param {String} id
     * @param {String} on
     * @param {String} off
     * @return {String}
     */
    _nameSwitch(switches, id, on, off) {
        return this._getSwitch(switches, id)
            ? this._nameSwitchAlways(on, 'I')
            : this._nameSwitchAlways(off, 'O');
    }

    /**
     * Get name of switch
     * @param {String} value
     * @param {String} fallback
     * @return {String}
     */
    _nameSwitchAlways(value, fallback) {
        return value !== undefined ? value : fallback;
    }

    /**
     * Check if variant
     * @param {String|Number} value
     * @param {String|Number} variant
     * @return {Boolean}
     */
    _variantSwitch(value, variant) {
        return (
            (value !== '0' &&
                value !== 0 &&
                !value &&
                !isNaN(Number(variant)) &&
                parseInt(String(variant)) === 1) ||
            (!isNaN(Number(value)) &&
                !isNaN(Number(variant)) &&
                parseInt(String(value)) === parseInt(String(variant)))
        );
    }

    /**
     * Get id from switch event
     * @param {Object} e
     * @return {String}
     */
    _idFromSwitchEvent(e) {
        if (e && e.target && e.target.id) {
            const parts = e.target.id.split('/');
            return parts.length > 1 ? parts[1] : '__SWITCH_EVENT_ERROR__';
        }
        return '__SWITCH_EVENT_ERROR__';
    }

    /**
     * Toggle a switch
     * @param {MouseEvent} e
     */
    _toggleSwitch(e) {
        const id = this._idFromSwitchEvent(e);
        this.switches = {
            ...this.switches,
            [id]: !this.switches[id],
        };
    }

    /**
     * Clicked the product-information and switch
     * @param {MouseEvent} e
     */
    _clickSwitch(e) {
        const productText = e.target.title;
        if (productText) {
            this.switches.showFlex = productText === 'Flex';
            this.switches = { ...this.switches };
        }
    }

    /**
     * Set a switch to true
     * @param {Object} e
     */
    _onSwitch(e) {
        const id = this._idFromSwitchEvent(e);
        this.switches = {
            ...this.switches,
            [id]: true,
        };
    }

    /**
     * Set a switch to false
     * @param {Object} e
     */
    _offSwitch(e) {
        const id = this._idFromSwitchEvent(e);
        this.switches = {
            ...this.switches,
            [id]: false,
        };
    }

    /**
     * Route user back to start
     */
    _goBack() {
        store.dispatch(
            uiProductListInteraction.run(chooseRecalculateClicked()),
        );
        const { order } = store.getState();
        store.dispatch(resetEnroll());
        store.dispatch(resetOrder());
        let orderURI = `${routePaths.customer}/adres/?postalCode=${order.postalCode}&houseNumber=${order.houseNumber}&houseNumberSuffix=${order.houseNumberSuffix}`;
        if (order.collective) {
            orderURI = `${orderURI}&collective=${order.collective}`;
        }
        Router.go(orderURI);
    }

    /**
     * Class name setter based on check
     * @param {any} bool
     * @param {String} inject
     * @return {String}
     */
    _class(bool, inject) {
        return bool ? ` ${inject}` : '';
    }

    /**
     * Get product short name
     * @param {Object} item
     * @return {String}
     */
    _classPanel(item) {
        let shortName = '';
        if (
            item &&
            item.value &&
            item.value.product_group_offering &&
            Array.isArray(item.value.product_group_offering.configurations) &&
            item.value.product_group_offering.configurations.length > 0 &&
            item.value.product_group_offering.configurations[0].metadata &&
            item.value.product_group_offering.configurations[0].metadata
                .short_name
        ) {
            shortName =
                item.value.product_group_offering.configurations[0].metadata
                    .short_name;
        }
        return shortName ? ` ${shortName.toLowerCase()}_shortName` : '';
    }

    /**
     * Get open state of details panel
     * @param {Object} showDetails
     * @param {Object} item
     * @param {Number} index
     * @return {Boolean}
     */
    _isShowDetails(showDetails, item, index) {
        if (
            showDetails &&
            item &&
            item.key &&
            showDetails[item.key] &&
            !isNaN(index) &&
            Array.isArray(showDetails[item.key])
        ) {
            return showDetails[item.key][index];
        }
        return false;
    }

    /**
     * Open a details page
     * @param {Object} e
     */
    _openDetails(e) {
        let id = '';
        // check if target exist
        if (e && e.target) {
            // if target exist get id if id is empty check if parent has id.
            id = e.target.id;
            if ((id === '' || id == null) && e.target.parentNode != null) {
                id = e.target.parentNode.id;
            }
        }
        if (e && e.target && id) {
            const ar = id.split('/');
            if (ar.length > 1 && !isNaN(ar[1])) {
                this.showDetails = {
                    ...this.showDetails,
                    [ar[0]]: this.showDetails[ar[0]].map(
                        /**
                         * @param {Boolean} o
                         * @param {Number} i
                         * @return {Boolean}
                         */
                        (o, i) => (i === parseInt(ar[1]) ? !o : o),
                    ),
                };
            } else {
                this.showDetails = {
                    ...this.showDetails,
                    [id]: this.showDetails[id].map(
                        /**
                         * @param {Boolean} o
                         * @param {Number} i
                         * @return {Boolean}
                         */
                        (o, i) => (i === this.selections[id] ? !o : o),
                    ),
                };
            }
        }

        store.dispatch(
            uiProductListInteraction.run(chooseOpenDetailsClicked()),
        );
    }

    /**
     * Open the lead modal
     * @param {Object} e
     */
    _openSendOffer(e) {
        e.stopImmediatePropagation();
        if (this.shadowRoot) {
            const leadModal = this.shadowRoot.querySelector('#lead-modal');
            leadModal.open();
        }

        store.dispatch(uiProductListInteraction.run(chooseSendOfferClicked()));
    }

    /**
     * Close a details page
     * @param {Object} e
     */
    _closeDetails(e) {
        const [key, index] = e.target.id.split('/');
        this.showDetails = {
            ...this.showDetails,
            [key]: this.showDetails[key].map(
                /**
                 * @param {Boolean} o
                 * @param {Number} i
                 * @return {Boolean}
                 */
                (o, i) => (i === parseInt(index) ? !o : o),
            ),
        };
    }

    /**
     * Make slots to save selections
     * @param {Object} switches
     */
    __setSelections(switches) {
        const selections = {};
        const showDetails = {};
        this._getPanelContracts(this.offers, switches, true).forEach((p) => {
            let index = 0;

            // Find cheapest index
            const configurations = this._configurations(p);
            let value = Number.MAX_VALUE;
            configurations.forEach((o, i) => {
                const local = this.__configurationPrice(o);
                value = local !== null && local < value ? local : value;
                index = local === value ? i : index;
            });

            // @ts-ignore
            selections[p.key] = index;
            if (
                p &&
                p.value &&
                p.value.product_group_offering &&
                Array.isArray(p.value.product_group_offering.configurations)
            ) {
                // @ts-ignore
                showDetails[p.key] = [
                    ...p.value.product_group_offering.configurations.map(
                        () => false,
                    ),
                ];
            }
        });
        this.showDetails = showDetails;
        this.selections = selections;
    }

    /**
     * Boolean invert
     * @param {any} arg
     * @return {Boolean}
     */
    _not(arg) {
        return !arg;
    }

    /**
     * Get selection
     * @param {Object} item
     * @return {String}
     */
    _selectedConfiguration(item) {
        // @ts-ignore
        const targetElements =
            this.shadowRoot.querySelectorAll(`[anchor-selector]`);
        if (targetElements) {
            targetElements.forEach((element) => {
                element.removeAttribute('selected-by-anchor');
            });
        }
        // @ts-ignore
        let targetElement = this.shadowRoot.querySelector(
            `[anchor-selector="${item.key}"]`,
        );
        // @ts-ignore
        const targetAnchor = this.shadowRoot.querySelector(
            `.AnchorLinksWrapper .PanelContracts`,
        );
        if (
            !targetElement ||
            (targetElement && !targetElement.getAttribute('selected-by-anchor'))
        ) {
            setTimeout(() => {
                if (targetAnchor) {
                    // @ts-ignore
                    const offers = targetAnchor.getAttribute('items');
                    // @ts-ignore
                    const parsedOffers = JSON.parse(offers);

                    let offerIndex = 0;
                    let bestOffer = parsedOffers[0];
                    // loop over all offers to compare the prices then set the index and the offer for the lowest price.
                    parsedOffers.map((offer, index) => {
                        if (bestOffer.monthly_pricing > offer.monthly_pricing) {
                            offerIndex = index;
                            bestOffer = offer;
                        }
                    });
                    // @ts-ignore
                    targetElement = this.shadowRoot.querySelector(
                        `[anchor-selector="${bestOffer.offer_id}"]`,
                    );
                    // @ts-ignore
                    targetElement.setAttribute('selected-by-anchor', 'true');
                    // @ts-ignore
                    targetAnchor.selected = offerIndex;
                } else {
                    // @ts-ignore
                    targetElement = this.shadowRoot.querySelector(
                        `[anchor-selector="${item.key}"]`,
                    );
                    // @ts-ignore
                    targetElement.setAttribute('selected-by-anchor', 'true');
                }
            }, 100);
        }
        return this.selections[item.value.id];
    }

    /**
     * Get name from offer
     * @param {Object} item
     * @param {String} [opts]
     * @return {String}
     */
    _name(item, opts) {
        const name =
            item &&
            item.value &&
            item.value.product_group_offering &&
            item.value.product_group_offering.name
                ? item.value.product_group_offering.name
                : '';
        switch (opts) {
            case 'LOWER_CASE':
                return name.toLowerCase();
            default:
                return name;
        }
    }

    /**
     * Get description array from offer
     * @param {Object} item
     * @return {Array}
     */
    _descriptionList(item) {
        return item &&
            item.value &&
            item.value.product_group_offering &&
            Array.isArray(item.value.product_group_offering.configurations) &&
            item.value.product_group_offering.configurations.length > 0 &&
            item.value.product_group_offering.configurations[0].metadata &&
            Array.isArray(
                item.value.product_group_offering.configurations[0].metadata
                    .description,
            )
            ? item.value.product_group_offering.configurations[0].metadata
                  .description
            : [];
    }

    /**
     * Get amount of configurations
     * @param {Object} item
     * @return {Number}
     */
    __amountConfigurations(item) {
        return item &&
            item.value &&
            item.value.product_group_offering &&
            Array.isArray(item.value.product_group_offering.configurations)
            ? item.value.product_group_offering.configurations.length
            : 0;
    }

    /**
     * Get panel styling
     * @param {Object} offers
     * @param {Object} item
     * @param {Object} switches
     * @return {String}
     */
    _stylePanel(offers, item, switches) {
        // @ts-ignore
        if (themeSettings && typeof themeSettings.panelStyling === 'function') {
            let panels = this._getPanelContracts(offers, switches).length;
            panels = panels > 0 ? panels : 1;

            let options = 0;
            this._getPanelContracts(offers, switches).forEach(
                (o) => (options += this.__amountConfigurations(o) || 1),
            );
            options = options > 0 ? options : 1;

            let thisOptions = this.__amountConfigurations(item);
            thisOptions = thisOptions > 0 ? thisOptions : 1;

            // @ts-ignore
            return ` ${themeSettings.panelStyling(
                panels,
                options,
                thisOptions,
            )}`;
        }
        return '';
    }

    /**
     * Check if the contract has multiple configurations
     * @param {Object} item
     * @return {Boolean}
     */
    _isMultiContract(item) {
        return this.__amountConfigurations(item) > 1;
    }

    /**
     * Check if the contract has only a single configuration
     * @param {Object} item
     * @return {Boolean}
     */
    _isSingleContract(item) {
        return !this._isMultiContract(item);
    }

    /**
     * Return configurations for an offer
     * @param {Object} item
     * @return {Array}
     */
    _configurations(item) {
        return item &&
            item.value &&
            item.value.product_group_offering &&
            Array.isArray(item.value.product_group_offering.configurations)
            ? item.value.product_group_offering.configurations
            : [];
    }

    /**
     * Get configuration name
     * @param {Object} configuration
     * @return {String}
     */
    _configurationTitle(configuration) {
        return configuration &&
            configuration.metadata &&
            configuration.metadata.title
            ? configuration.metadata.title
            : configuration.name;
    }

    /**
     * Get product title
     * @param {Object} item
     * @return {String}
     */
    _productTitle(item) {
        return item &&
            item.value &&
            item.value.product_group_offering &&
            item.value.product_group_offering.name
            ? item.value.product_group_offering.name
            : '';
    }

    /**
     * Get configuration name
     * @param {Object} configuration
     * @return {String}
     */
    _configurationOptsTitle(configuration) {
        return configuration && configuration.name ? configuration.name : null;
    }

    /**
     * Get configuration subtitle
     * @param {Object} configuration
     * @return {String}
     */
    _configurationSubtitle(configuration) {
        return configuration &&
            configuration.metadata &&
            configuration.metadata.sub_title
            ? configuration.metadata.sub_title
            : `€ ${String(configuration.monthly_pricing).replace(
                  '.',
                  ',',
              )} p/m`;
    }

    /**
     * Get configuration subtitle
     * @param {Object} configuration
     * @return {String}
     */
    _configurationOptsSubtitle(configuration) {
        return configuration && configuration.monthly_pricing
            ? `€ ${String(configuration.monthly_pricing).replace('.', ',')} p/m`
            : '';
    }

    /**
     * Get configuration price
     * @param {Object} configuration
     * @return {String}
     */
    _configurationOptsPrice(configuration) {
        return configuration && configuration.monthly_pricing
            ? NumberToPrice(configuration.monthly_pricing, null, 2, false)
            : '';
    }

    /**
     * Get configuration yearly price
     * @param {Object} configuration
     * @return {String}
     */
    _configurationOptsYearPrice(configuration) {
        return configuration && configuration.yearly_pricing
            ? NumberToPrice(configuration.yearly_pricing, null, 2, false)
            : '';
    }

    /**
     * Get price difference for configuration
     * @param {Object} configuration
     * @param {Object} item
     * @return {String}
     */
    _configurationOptsPriceDifference(configuration, item) {
        const bestPrice = this.__bestPrice(item);
        const price = this.__configurationPrice(configuration);
        return price !== null && price > bestPrice
            ? `(+ ${NumberToPrice(price - bestPrice)} p/m)`
            : 'Beste prijs';
    }

    /**
     * Get price difference for configuration
     * @param {Object} conf
     * @param {Object} item
     * @return {Boolean}
     */
    _isBestOffer(conf, item) {
        const bestPrice = this.__bestPrice(item);
        const price = this.__configurationPrice(conf);
        return price !== null && !(price > bestPrice);
    }

    /**
     * Filter the anchor menu for price config reasons
     * @param {Object} item
     * @param {Number} index
     * @param {Array} arr
     * @return {Boolean}
     */
    anchorFilter(item, index, arr) {
        if (arr.length > 1) {
            return index === 1;
        }
        return true;
    }

    /**
     * Get best price in offer type as string
     * @param {Object} item
     * @param {String} [opts]
     * @return {String}
     */
    _bestPrice(item) {
        // TODO: VAT when verified payload is updated check pass item as param
        return NumberToPrice(this.__bestPrice(item));
    }

    /**
     * Get selected price
     * @param {Object} item
     * @param {Object} selections
     * @param {String} opts
     * @return {String}
     */
    _selectedPrice(item, selections, opts) {
        const current = selections[item.value.id];
        const configuration = this._configurations(item)[current];
        // TODO: future update this logic VAT is now always set to the elec or gas percentage
        const { cost } = configuration.products[0];
        const price = NumberToPrice(
            this.__configurationPrice(configuration) || 0,
            cost,
            2,
            false,
        );
        const priceArr = price.split('€')[1].trim().split(',');
        switch (opts) {
            case 'pre':
                return `${priceArr[0]}`;
            case 'post':
                return `${priceArr[1]}`;
            default:
                return price;
        }
    }

    /**
     * Get selected yearly price
     * @param {Object} item
     * @param {Object} selections
     * @return {String}
     */
    _selectedYearPrice(item, selections) {
        const current = selections[item.value.id];
        const configuration = this._configurations(item)[current];
        // TODO: future update this logic VAT is now always set to the elec or gas percentage
        const { cost } = configuration.products[0];
        return NumberToPrice(
            this.__configurationYearPrice(configuration) || 0,
            cost,
            2,
            false,
        )
            .split('€')[1]
            .trim();
    }

    /**
     * Get best price in offer type
     * @param {Object} item
     * @return {Number}
     */
    __bestPrice(item) {
        const configurations = this._configurations(item);
        let value = Number.MAX_VALUE;
        configurations.forEach((o) => {
            const local = this.__configurationPrice(o);
            value = local !== null && local < value ? local : value;
        });
        return value;
    }

    /**
     * If this offer has the best price
     * @param {Object} offer
     * @param {Object} offers
     * @param {Object} switches
     * @return {Boolean}
     */
    __isBestOffer(offer, offers, switches) {
        return !(
            this.__bestPriceInOffers(
                this._getPanelContracts(offers, switches),
            ) < this.__bestPrice(offer)
        );
    }

    /**
     * If this configuration has the best price
     * @param {Object} configuration
     * @param {Object} offers
     * @param {Object} switches
     * @return {Boolean}
     */
    __isBestConfiguration(configuration, offers, switches) {
        return !(
            this.__bestPriceInOffers(
                this._getPanelContracts(offers, switches),
            ) < (this.__configurationPrice(configuration) || Number.MAX_VALUE)
        );
    }

    /**
     * Sets class to best if this offer has the best price
     * @param {Object} offer
     * @param {Object} offers
     * @param {Object} switches
     * @return {String}
     */
    _classBestOffer(offer, offers, switches) {
        return this.__isBestOffer(offer, offers, switches) ? ' best' : '';
    }

    /**
     * Sets class to best if this configuration has the best price
     * @param {Object} configuration
     * @param {Object} offers
     * @param {Object} switches
     * @return {String}
     */
    _classBestConfiguration(configuration, offers, switches) {
        return this.__isBestConfiguration(configuration, offers, switches)
            ? ' best'
            : '';
    }

    /**
     * Get the ribbon content
     * @param {Object} offer
     * @param {Object} offers
     * @param {Object} switches
     * @return {String}
     */
    _ribbonContent(offer, offers, switches) {
        let shortName = '';
        if (
            offer &&
            offer.value &&
            offer.value.product_group_offering &&
            Array.isArray(offer.value.product_group_offering.configurations) &&
            offer.value.product_group_offering.configurations.length > 0 &&
            offer.value.product_group_offering.configurations[0].metadata &&
            offer.value.product_group_offering.configurations[0].metadata
                .short_name
        ) {
            shortName =
                offer.value.product_group_offering.configurations[0].metadata
                    .short_name;
        }
        return this.__isBestOffer(offer, offers, switches)
            ? 'Beste prijs'
            : shortName;
    }

    /**
     * Get best price from multiple offers (as array)
     * @param {Object} offers
     * @return {Number}
     */
    __bestPriceInOffers(offers) {
        let value = Number.MAX_VALUE;
        offers.forEach(
            /**
             * @param {Object} o
             */
            (o) => {
                const local = this.__bestPrice(o);
                value = local !== null && local < value ? local : value;
            },
        );
        return value;
    }

    /**
     * Get price from configuration
     * @param {Object} configuration
     * @return {Number?}
     */
    __configurationPrice(configuration) {
        if (
            configuration &&
            !Number.isNaN(Number(configuration.monthly_pricing))
        ) {
            return parseFloat(configuration.monthly_pricing);
        }
        return null;
    }

    /**
     * Get yearly price from configuration
     * @param {Object} configuration
     * @return {Number?}
     */
    __configurationYearPrice(configuration) {
        if (
            configuration &&
            !Number.isNaN(Number(configuration.yearly_pricing))
        ) {
            return parseFloat(configuration.yearly_pricing);
        }
        return null;
    }

    /**
     *
     * @param {Object} e
     */
    _configurationChanged(e) {
        this.selections = {
            ...this.selections,
            [e.target.id]: e.detail.selected,
        };
    }

    /**
     *
     * @param {Object} e
     */
    _combinedConfigurationChanged(e) {
        const combinedSelections = JSON.parse(e.target.getAttribute('items'));
        const offerID = combinedSelections[e.detail.selected].offer_id;
        let offerWithIDCount = 0;
        combinedSelections.forEach((element) => {
            if (element.offer_id === offerID) {
                offerWithIDCount++;
            }
        });
        // @ts-ignore
        const allTargets =
            this.shadowRoot.querySelectorAll(`[anchor-selector]`);
        // @ts-ignore
        const targetElement = this.shadowRoot.querySelector(
            `[anchor-selector="${offerID}"]`,
        );
        if (allTargets && allTargets.length > 0) {
            allTargets.forEach((target) => {
                target.removeAttribute('selected-by-anchor');
            });
        }
        if (targetElement) {
            // @ts-ignore
            targetElement.setAttribute('selected-by-anchor', 'true');
        }
        if (!(offerWithIDCount > 1)) {
            return;
        }
        // @ts-ignore
        this._configurationChanged(e);
        this.selections = {
            ...this.selections,
            [offerID]: e.detail.selected,
        };
    }

    /**
     * Get selection
     * @param {Object} item
     * @return {String}
     */
    _selectedCombinedConfiguration(item) {
        return this.selections[item.value.id];
    }

    /**
     * Converts object to array
     * @param {Object} obj
     * @return {Array}
     */
    _objToArr(obj) {
        const arr = [];
        for (const key in obj) {
            if (obj[key]) {
                arr.push({ key, value: obj[key] });
            }
        }
        return arr;
    }

    /**
     * Class-name generator for grey offers
     * @param {Object} offer
     * @return {String}
     */
    _classGreyOffer(offer) {
        const filter = ['model'];
        const name = this._name(offer).toLowerCase();
        return filter.find((f) => name.includes(f.toLowerCase()))
            ? ' grey'
            : '';
    }

    /**
     * Get offers from all product groups and returns an array with all the offers combined
     * @param {Object} obj
     * @param {Object} switches
     * @param {Boolean} [includeHidden]
     * @return {Array}
     */
    _getPanelContractsCombinedOffering(obj, switches, includeHidden) {
        const combinedOffering = [];
        const contracts = this._getPanelContracts(obj, switches, includeHidden);
        // Check for multiple single conf product_groups to check for model contract
        const singleConfGroup = [];
        contracts.forEach((contract) => {
            if (
                contract.value &&
                contract.value.product_group_offering &&
                contract.value.product_group_offering.configurations.length > 0
            ) {
                if (
                    contract.value.product_group_offering.configurations
                        .length === 1
                ) {
                    singleConfGroup.push(
                        contract.value.product_group_offering.configurations[0]
                            .id,
                    );
                }
                contract.value.product_group_offering.configurations.forEach(
                    (offer) => {
                        offer.offer_id = contract.key;
                    },
                );
                combinedOffering.push(
                    ...contract.value.product_group_offering.configurations,
                );
            }
        });
        // Model contract view enabled hide multiple conf contracts for now
        if (singleConfGroup.length > 1) {
            return combinedOffering.filter((item) =>
                singleConfGroup.includes(item.id),
            );
        }
        return combinedOffering;
    }

    /**
     * Converts offers to panels array
     * @param {Object} obj
     * @param {Object} switches
     * @param {Boolean} [includeHidden]
     * @return {Array}
     */
    _getPanelContracts(obj, switches, includeHidden) {
        /** @type {Array} */
        let filter = [];

        // @ts-ignore
        if (
            themeSettings &&
            typeof themeSettings.productSetFilterByName === 'function'
        ) {
            // @ts-ignore
            filter = themeSettings.productSetFilterByName(switches || {});
        }

        /**
         * Default function
         * @param {Array} a
         * @return {Array}
         */
        let f = (a) => a;

        // @ts-ignore
        if (
            themeSettings &&
            typeof themeSettings.productSetTransform === 'function'
        ) {
            // @ts-ignore
            f = themeSettings.productSetTransform;
        }

        // @ts-ignore
        let includeHiddenOrOptional = includeHidden;

        // @ts-ignore
        if (themeSettings.topAnchors.enabled && window.innerWidth <= 822) {
            // @ts-ignore
            if (themeSettings.topAnchors.settings.combineProductGroups) {
                includeHiddenOrOptional = true;
            }
        }

        return f(
            this._objToArr(obj)
                .sort((a, b) => {
                    const weightA =
                        a && a.value && !Number.isNaN(a.value.weight)
                            ? parseInt(a.value.weight, 10)
                            : 0;
                    const weightB =
                        b && b.value && !Number.isNaN(b.value.weight)
                            ? parseInt(b.value.weight, 10)
                            : 1;
                    return weightA - weightB;
                })
                .filter((o) => {
                    const name = this._name(o).toLowerCase();
                    return (
                        includeHiddenOrOptional ||
                        !filter.find((f) => name.includes(f.toLowerCase()))
                    );
                }),
        );
    }

    /**
     * Save electricity capacity and recalculate
     */
    _setElectricityCapacity() {
        store.dispatch(
            retrieveOffers(
                this.order,
                this.gasCapacity,
                this.electricityCapacity,
            ),
        );
    }

    /**
     * Get current capacity name
     * @param {Object} electricityCapacity
     * @return {String}
     */
    _getElectricityCapacity() {
        return enums.TransmissionValueType[this.electricityCapacity];
    }

    /**
     * Save gas capacity and recalculate
     */
    _setGasCapacity() {
        store.dispatch(
            retrieveOffers(
                this.order,
                this.gasCapacity,
                this.electricityCapacity,
            ),
        );
    }

    /**
     * Get current capacity name
     * @param {string} gasCapacity
     * @return {String}
     */
    _getGasCapacity() {
        return enums.TransmissionValueType[this.gasCapacity];
    }

    /**
     * Reset or set additionalProdcts or collective
     * @param {MouseEvent} e
     * @param {Object} additionalContractProduct
     */
    setAdditionalProductOrCollective(e, additionalContractProduct) {
        if (
            additionalContractProduct &&
            !this.additionalContractProductSelected &&
            this.lastEventObject == null
        ) {
            this.showAdditionalProductGroupModal = true;
            e.preventDefault();
            this.lastEventObject = {};
            this.lastEventObject.target = e.target;
            this.lastEventObject.target.id = e.target.id;
        } else if (
            additionalContractProduct &&
            this.additionalContractProductSelected &&
            this.lastEventObject != null
        ) {
            store.dispatch(
                setCollective(this.additionalContractProductCollectiveID),
            );
        } else if (
            additionalContractProduct &&
            !this.additionalContractProductSelected &&
            this.lastEventObject != null
        ) {
            store.dispatch(clearCollective());
        }
    }

    /**
     * Select an proposition based on an id in one of the following formats:
     * submit/id (the index from the selection will be used)
     * submit/id/index
     * @param {MouseEvent} e
     */
    submitProposition(e) {
        if (e && e.target && e.target.id) {
            if (e instanceof MouseEvent) {
                e.stopImmediatePropagation();
            }

            // get additional contract product and when exist set or reset additional products or collective.
            const additionalContractProduct =
                themeSettings && themeSettings.additionalContractProduct;
            if (additionalContractProduct) {
                this.setAdditionalProductOrCollective(
                    e,
                    additionalContractProduct,
                );
            }

            // get all items from the elements id splitted by a slash
            const items = e.target.id.split('/');
            const propositionID = items[0];
            // if lenght is 1 its the default proposition from the radio buttons
            // if lenght is 2 its the mijndomein proposition and the selected index is the second item.
            const propositionChoice =
                items.length === 1
                    ? this.selections[propositionID]
                    : parseInt(items[1], 10);

            store.dispatch(uiProductListInteraction.run(chooseButtonClicked()));
            this.dispatchPropositionAndRedirect(
                propositionID,
                propositionChoice,
            );
        }
    }

    /**
     * Same logic as above but specific for the mijndomein vendor
     * @param {UUID} propositionUUID
     * @param {Number} propositionChoice
     */
    dispatchPropositionAndRedirect(propositionUUID, propositionChoice) {
        if (propositionUUID != null && propositionChoice != null) {
            store.dispatch(setOffer(propositionUUID, propositionChoice));

            // show additional product group modal (mijndomein popup for solarpanels)
            if (!this.showAdditionalProductGroupModal) {
                Router.go(routePaths.customerForm);
                this.lastEventObject = null;
                this.additionalContractProductSelected = false;
            }
        } else {
            window.displayMessage(
                'Error: propositie ophalen is niet geluk, neem contact op met de beheerder.',
            );
        }
    }

    /**
     * @return {Boolean}
     */
    _isLoggedIn() {
        // todo: check auth?
        return (
            store.getState().jwtSnapshots &&
            store.getState().jwtSnapshots.snapshots &&
            store.getState().jwtSnapshots.snapshots.length > 0
        );
    }

    /**
     * Continue the additional product modal
     */
    _addAdditionalProductToOrder() {
        this.showAdditionalProductGroupModal = false;
        this.additionalContractProductSelected = true;
        store.dispatch(addLabel(['l_solar_mail', 'true']));
        this.submitProposition(this.lastEventObject);
    }

    /**
     * Close the additional product modal
     */
    _cancelAdditionalProduct() {
        this.showAdditionalProductGroupModal = false;
        this.additionalContractProductSelected = false;
        this.submitProposition(this.lastEventObject);
    }

    /**
     * Get the specification string
     * @return {String}
     */
    _specificationString() {
        // @ts-ignore
        if (themeSettings && themeSettings.specificationString) {
            // @ts-ignore
            return String(themeSettings.specificationString);
        }
        return 'Specificaties';
    }

    /**
     * Variant selector
     * @param {String|Number} variant
     * @return {Boolean}
     */
    _isVariant(variant) {
        return (
            !isNaN(Number(variant)) &&
            ((themeSettings &&
                themeSettings.variant === parseInt(String(variant))) ||
                (!themeSettings && parseInt(String(variant)) === 1))
        );
    }

    /**
     * Flex description
     * @param {Object} switches
     * @return {Boolean}
     */
    _hasProductDescription(switches) {
        // flex check for theme description
        if (
            (switches && themeSettings.flexDescription == null) ||
            themeSettings.flexDescription === ''
        ) {
            return false;
        }
        // vast check for theme description
        if (
            (switches && themeSettings.vastDescription == null) ||
            themeSettings.vastDescription === ''
        ) {
            return false;
        }

        return true;
    }

    /**
     * Product description title
     * @param {Object} switches
     * @return {String}
     */
    _productDescriptionTitle(switches) {
        return switches.showFlex ? 'Vast' : 'Flex';
    }

    /**
     * Product description
     * @param {Object} switches
     * @return {String}
     */
    _productDescription(switches) {
        return switches.showFlex
            ? themeSettings.vastDescription
            : themeSettings.flexDescription;
    }

    /**
     * Get collective from collectives based on collective id
     * @param {String} collective
     * @param {Array} collectives
     * @return {Object}
     */
    _getCollective(collective, collectives) {
        return collectives.find((c) => c.id === collective);
    }

    /**
     * Get collective image from collectives based on collective id
     * @param {String} collective
     * @param {Array} collectives
     * @return {String}
     */
    _getCollectiveSrc(collective, collectives) {
        const found = this._getCollective(collective, collectives);
        return found && found.logo_url ? found.logo_url : '';
    }

    /**
     * Get the selection details to show under the product, the order and the options
     * @return {Array}
     */
    _getSelectionDetails() {
        // @ts-ignore
        return themeSettings && Array.isArray(themeSettings.selectionDetails)
            ? themeSettings.selectionDetails
            : [];
    }

    /**
     * Check if selection detail is of a certain type
     * @param {String} type
     * @param {String} match
     * @return {Boolean}
     */
    _isSelectionDetailType(type, match) {
        return type === match;
    }

    /**
     * Check if detail may be renderd.
     * @param {Object} item
     * @return {Boolean}
     */
    _renderDetailsType(item) {
        return this._hasCollectives(item);
    }

    /**
     * Check if collective is not null or empty.
     * @param {String} match
     * @return {Boolean}
     */
    _hasCollectives(match) {
        if (match === 'COLLECTIVE') {
            const { collective } = this.order;
            if (collective === null || collective === '') {
                return false;
            }
        }
        return true;
    }

    /**
     * get all the names for lead contracts
     * @param {Offers} offers
     * @return {Array}
     */
    _getLeadContracts(offers) {
        const productNames = [];
        if (offers && Object.entries(offers).length > 0 && !offers.error) {
            Object.values(offers).forEach((offer) => {
                const configurations =
                    // eslint-disable-next-line camelcase
                    offer?.product_group_offering?.configurations;
                if (configurations?.length > 0) {
                    configurations.forEach((configuration) => {
                        if (
                            configuration.name.toLowerCase() !==
                            'model contract'
                        ) {
                            productNames.push(configuration);
                        }
                    });
                }
            });
        }
        return productNames;
    }

    /**
     * get all the names for lead contracts
     * @param {Offers} offers
     * @return {boolean}
     */
    showContracts(offers) {
        return offers && Object.entries(offers).length > 1 && !offers.error;
    }

    /**
     * get all the names for lead contracts
     * @param {str} string
     * @return {RegExpMatchArray | null}
     */
    _returnIfStringHasEmail(str) {
        // from: https://emailregex.com/
        const regex =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return str.match(regex);
    }

    /**
     * Close the lead offer
     * @param {MouseEvent} e
     */
    _createLeadOffer(e) {
        e.stopImmediatePropagation();
        const leadModal = this.shadowRoot.querySelector('#lead-modal');
        if (leadModal) {
            const inputComponents =
                leadModal.querySelectorAll('input-component');
            const checkboxComponents =
                leadModal.querySelectorAll('checkbox-component');
            // set the defaults.
            const payload = { product_interests: [] };
            let vendorID = '';
            this.leadContractSelected = false;
            let hasErrors = false;

            // check if input fields are valid and add the valid fields to the payload
            for (const input of inputComponents) {
                const inputValid = input.validate();
                if (!inputValid) {
                    hasErrors = true;
                } else if (input.value !== '') {
                    // Add parameter key by id with value.
                    payload[input.id] = input.value;
                }

                if (input.id === 'email') {
                    const emailArray = this._returnIfStringHasEmail(
                        input.value,
                    );
                    if (emailArray?.length == null) {
                        window.displayMessage(defaultEmailError);
                        store.dispatch(
                            uiProductListInteraction.run(
                                ProductListEventInputChanged(
                                    returnErrorOrEmptyString(
                                        !input.error,
                                        input.errorMessage,
                                    ),
                                    'e-mailadres',
                                    'stuur-mij-het-prijsvoorstel',
                                ),
                            ),
                        );
                        return;
                    }
                }
            }

            // Check all checkboxes and add valid checkboxes to the paramters.
            for (const checkbox of checkboxComponents) {
                const checkboxValid = checkbox.validate();
                const checkboxSelected = checkbox.value;
                if (checkboxValid && checkboxSelected) {
                    this.leadContractSelected = true;
                    const offer = checkbox.item;
                    // create new productInterest Object
                    const newProductInterest = {
                        product_group_id: offer.id,
                        config: offer.config_id,
                    };
                    // add new productInterest to the payload.
                    if (payload.hasOwnProperty('product_interests')) {
                        payload.product_interests.push(newProductInterest);
                    }
                    // when vendor ID hasn't been set yet update the vendor id.
                    if (vendorID === '') {
                        vendorID = offer.vendor_id;
                    }
                }
            }

            if (!hasErrors) {
                this._postNewLeadOffer(
                    this.order,
                    payload,
                    vendorID,
                    leadModal,
                );
                store.dispatch(
                    uiProductListInteraction.run(PriceOfferSend('valid')),
                );
            } else {
                const errorDescription = `Niet alle vereiste velden zijn ingevuld`;
                store.dispatch(
                    uiProductListInteraction.run(
                        PriceOfferSend(invalidMessage(errorDescription)),
                    ),
                );
                window.displayMessage(errorDescription);
            }
        }
    }

    /**
     * Post new lead offer
     * @param {Order} order
     * @param {Array} payload
     * @param {String} vendorID
     * @param {Object} leadModal
     */
    _postNewLeadOffer(order, payload, vendorID, leadModal) {
        try {
            store.dispatch(
                createLead.request({
                    payload: {
                        ...payload,
                        area_code: order.postalCode,
                        house_number: order.houseNumber,
                        house_addition: order.houseNumberSuffix,
                        elek_usage: order.electricityUsageEstimate,
                        gas_usage: order.gasUsageEstimate,
                        elek_prod: order.electricityProductionEstimate,
                        high_low_distribution:
                            order.electricityUsageFactorEstimate,
                        charging_pole_usage: order.chargePoleUsage,
                        collective_id: order.collective,
                        vendor_id: vendorID,
                    },
                }),
            );
            // close the modal and display succes message.
            leadModal.close();
            window.displayMessage(
                `Het versturen van het prijsvoorstel is gelukt, je ontvangt het prijsvoorstel binnen enkele minuten.`,
            );
        } catch (error) {
            window.displayMessage(
                `Het versturen van een prijsvoorstel is helaas niet gelukt. Probeer het nog een keer.`,
            );
        }
    }

    getHouseAddition(geoInformation) {
        if (geoInformation && geoInformation.houseNumberSuffix) {
            return geoInformation.houseNumberSuffix;
        }

        return '';
    }

    _priceNameChanged(event) {
        const firstElement = event.composedPath()[0];
        this._priceNameObserver(firstElement);
    }

    _priceNameObserver = (firstElement) => {
        if (
            firstElement == null ||
            firstElement.value === '' ||
            this.name === firstElement.value
        ) {
            return;
        }
        this.name = firstElement.value;
        const errorMessage = firstElement.getAttribute('error-message');
        store.dispatch(
            uiProductListInteraction.run(
                ProductListEventInputChanged(
                    returnErrorOrEmptyString(!firstElement.error, errorMessage),
                    'naam',
                    'stuur-mij-het-prijsvoorstel',
                ),
            ),
        );
    };

    _priceEmailChanged(event) {
        const firstElement = event.composedPath()[0];
        this._priceEmailObserver(firstElement);
    }

    _priceEmailObserver = (firstElement) => {
        if (
            firstElement == null ||
            firstElement.value === '' ||
            this.email === firstElement.value
        ) {
            return;
        }
        this.email = firstElement.value;
        const errorMessage = firstElement.getAttribute('error-message');
        const emailArray = this._returnIfStringHasEmail(firstElement.value);
        if (emailArray?.length == null && firstElement.value !== '') {
            window.displayMessage(defaultEmailError);
        }
        store.dispatch(
            uiProductListInteraction.run(
                ProductListEventInputChanged(
                    returnErrorOrEmptyString(
                        emailArray ? emailArray.length > 0 : false,
                        errorMessage,
                    ),
                    'e-mailadres',
                    'stuur-mij-het-prijsvoorstel',
                ),
            ),
        );
    };

    _pricePhoneChanged(event) {
        const firstElement = event.composedPath()[0];
        this._pricePhoneObserver(firstElement);
    }

    _pricePhoneObserver = (firstElement) => {
        if (
            firstElement == null ||
            firstElement.value === '' ||
            this.phone === firstElement.value
        ) {
            return;
        }
        this.phone = firstElement.value;
        const errorMessage = firstElement.getAttribute('error-message');
        store.dispatch(
            uiProductListInteraction.run(
                ProductListEventInputChanged(
                    returnErrorOrEmptyString(!firstElement.error, errorMessage),
                    'telefoonnummer',
                    'stuur-mij-het-prijsvoorstel',
                ),
            ),
        );
    };

    /**
     * Gets template of class
     */
    static get template() {
        const cssTemplate = document.createElement('template');
        cssTemplate.innerHTML = provider.styles(css);
        const htmlTemplate = document.createElement('template');
        htmlTemplate.innerHTML = template;
        return html`<style include="iron-flex">
                ${cssTemplate}
            </style>
            ${htmlTemplate}`;
    }
}

window.customElements.define('product-list', EzProductList);
