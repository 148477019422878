import {
    LitElement,
    customElement,
    TemplateResult,
    html,
    property,
    CSSResultArray,
} from 'lit-element';

import style from './style';
import sharedStyles from '../../../../sharedStyles';

@customElement('password-validation')
export class PasswordValidation extends LitElement {
    static get styles(): CSSResultArray {
        return [sharedStyles, style];
    }

    @property({ attribute: 'validation-text', type: String })
    public validationText: string = '';

    @property({ type: Boolean, attribute: 'is-valid' })
    public isValid: boolean = false;

    public render = (): TemplateResult => {
        return html` <div class="wrapper">
            ${this.isValid
                ? html`
                      <svg
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 130.2 130.2"
                          class="valid"
                      >
                          <polyline
                              class="path check"
                              fill="none"
                              stroke-width="6"
                              stroke-linecap="round"
                              stroke-miterlimit="10"
                              points="100.2,40.2 51.5,88.8 29.8,67.5 "
                          />
                      </svg>
                  `
                : html`<svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      class="invalid"
                  >
                      <path d="M0 0h24v24H0z" fill="none" />
                      <path
                          d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
                      />
                  </svg>`}
            <span
                class="validation__text ${this.isValid
                    ? 'validation__text--valid'
                    : 'validation__text--invalid'}"
                >${this.validationText}</span
            >
        </div>`;
    };
}

declare global {
    interface HTMLElementTagNameMap {
        'password-validation': PasswordValidation;
    }
}
