import {
    customElement,
    LitElement,
    TemplateResult,
    html,
    css,
    CSSResult,
    property,
} from 'lit-element';
import { whitelabel } from '@weavelab/whitelabel';

@customElement('ez-radio-button')
@whitelabel({ name: 'radio-button' })
export class RadioButton extends LitElement {
    @property({ type: Function })
    public selectCallback?: (i: number) => void; // eslint-disable-line no-unused-vars

    @property({ type: Number })
    public index: number | null = null;

    @whitelabel()
    public static get styles(): CSSResult {
        return css`
            :host([hidden]) {
                display: none !important;
            }
        `;
    }

    constructor() {
        super();

        this.addEventListener('click', this.select);
    }

    public disconnectedCallback = () => {
        this.removeEventListener('click', this.select);
        super.disconnectedCallback();
    };

    public render = (): TemplateResult => html`<slot></slot>`;

    /**
     * Select handler for radiobutton
     */
    public select = (): void => {
        if (
            typeof this.selectCallback === 'function' &&
            typeof this.index === 'number' &&
            !Number.isNaN(this.index)
        ) {
            this.selectCallback(this.index);
        }
    };
}

declare global {
    interface HTMLElementTagNameMap {
        'ez-radio-button': RadioButton;
    }
}
