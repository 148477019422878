import { takeLatest, ForkEffect } from 'redux-saga/effects';
import { Router } from '@vaadin/router';
import { routePaths } from 'data-access/router';
import { archiveSnapshot, store } from '../store';
import { logOut } from '../actions/user';

/**
 * Message when archive the snapshot is failed
 */
const archiveFailed = (): void => {
    console.warn('Achiving the snapshot has failed.');
};

/**
 * If setting a notify message for edsn down Succeeds
 */
const archiveSucces = (): void => {
    localStorage.clear();
    store.dispatch(logOut);
    Router.go(routePaths.customer);
};

/**
 * Watcher for archiveSnapshot.REQUEST_FAILED and archiveSnapshot.REQUEST_SUCCESS action
 */
export function* watchArchiveSnapshot(): IterableIterator<ForkEffect> {
    yield takeLatest([archiveSnapshot.REQUEST_FAILED], archiveFailed);
    yield takeLatest([archiveSnapshot.REQUEST_SUCCESS], archiveSucces);
}
