export const PREFIX = 'Calculator: ';

export const START_CALCULATOR = `${PREFIX}START_CALCULATOR`;
/**
 * Set calculator to busy
 * @return {Object}
 */
export const startCalculator = () => ({ type: START_CALCULATOR });

export const PROMPT_CALCULATOR = `${PREFIX}PROMPT_CALCULATOR`;
/**
 * Set calculator to issue
 * @param {String} address
 * @return {Object}
 */
export const promptCalculator = (address) => ({
    type: PROMPT_CALCULATOR,
    address,
});

export const FINISH_CALCULATOR = `${PREFIX}FINISH_CALCULATOR`;
/**
 * Set calculator to done
 * @return {Object}
 */
export const finishCalculator = () => ({ type: FINISH_CALCULATOR });

export const RESET_CALCULATOR = `${PREFIX}RESET_CALCULATOR`;
/**
 * Reset calculator busy state
 * @return {Object}
 */
export const resetCalculator = () => ({ type: RESET_CALCULATOR });
