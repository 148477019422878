import { takeLatest, put } from 'redux-saga/effects';
import { UPDATE_ROUTING, updateEnabledItems } from '../actions/app';

import { SET_SELECTED_SNAPSHOT } from '../actions/snapshot';
import { notBusy } from '../actions/busy';
import enums from '../enums';

/**
 * setEnabledItems enables ui items based on snapshots verified payload
 * @param {Object} action
 */
function* setEnabledItems(action) {
    const payload =
        action.snapshot && action.snapshot.verified_snapshot_payload
            ? action.snapshot.verified_snapshot_payload
            : null;
    const { snapshot } = action;
    const enabledItems = {
        competition:
            snapshot && snapshot.competition ? snapshot.competition : false,
        production: !!(
            payload &&
            payload.has_elec &&
            payload.elec_prod_amount > 0
        ),
        electricity: !!(payload && payload.has_elec),
        gas: !!(payload && payload.has_gas),
        savings: !!snapshot,
        costs: !!payload,
        invoices: !!(
            snapshot &&
            snapshot.snapshot_phase > 6 &&
            snapshot.snapshot_phase < enums.SnapshotPhaseDemoContractCreated
        ),
        splitBusiness:
            snapshot && snapshot.split_business
                ? snapshot.split_business
                : false,
    };

    yield put(
        // UI state based on customer payload data
        updateEnabledItems(enabledItems),
    );
}
/**
 * Watcher for SET_JWT_SNAPSHOTS action
 */
export function* watchSetJwtSnapshot() {
    yield takeLatest(SET_SELECTED_SNAPSHOT, setEnabledItems);
}

/**
 * Set state to not busy when rerouted
 */
function* fixBusyState() {
    yield put(notBusy());
}

/**
 * Watcher for UPDATE_ROUTING action
 */
export function* watchUpdateRouting() {
    yield takeLatest(UPDATE_ROUTING, fixBusyState);
}
