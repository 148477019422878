/* eslint-disable camelcase */
import { PolymerElement, html } from '@polymer/polymer/polymer-element';
import { provider } from '@weavelab/frontend-connect';

import { Router } from '@vaadin/router';
import watch from 'redux-watch';
import { store } from '../../../../../../store';

import { setError } from '../../../../../../actions/error';

import '../../../../../atoms/RadioButtonGroup';
import settingsEditCss from '../../settings-edit.pcss';
import css from '../styles.pcss';
import template from './template.html';

import { setSelectedSnapshot } from '../../../../../../actions/snapshot';
import { routePaths } from '../../../../../../helpers/routing';

/**
 * Login class
 * @namespace SettingsEditAddress
 * @class SettingsEditAddress
 * @extends PolymerElement
 */
export default class SettingsEditAddress extends PolymerElement {
    /**
     * Gets properties of class
     */
    static get properties() {
        return {
            addressChoice: {
                type: Boolean,
                value: true,
                observer: '_addressChoiceChanged',
            },
            address: {
                type: Object,
                value: {
                    area_code: '',
                    house_number: '',
                    house_addition: '',
                },
            },
            verifiedAddress: {
                type: Object,
                observer: '_verifiedAddressChanged',
            },
            snapshot: {
                type: Object,
                observer: '_snapshotChanged',
            },
            deliveryLocationString: {
                type: String,
                value: '',
            },
            user: {
                type: Object,
            },
        };
    }

    /**
     * Subscribes on user state when element is ready
     */
    ready() {
        super.ready();

        this.snapshot = store.getState().jwtSnapshots.selectedSnapshot;
        const snapshotsWatcher = watch(store.getState, 'jwtSnapshots');
        store.subscribe(
            snapshotsWatcher((jwtSnapshots) => {
                if (jwtSnapshots && jwtSnapshots.selectedSnapshot) {
                    this.snapshot = jwtSnapshots.selectedSnapshot;
                }
            }),
        );
        this.user = store.getState().user;
        const userWatcher = watch(store.getState, 'user');
        store.subscribe(
            userWatcher(
                /**
                 * @param {Object} user
                 */
                (user) => {
                    this.user = user;
                },
            ),
        );
    }

    /**
     * observers
     */
    static get observers() {
        return [
            // Observer method name, followed by a list of dependencies, in parenthesis
            '_addressChanged(address.*)',
        ];
    }

    /**
     * _snapshotChanged
     * @param {Object} snapshot
     */
    _snapshotChanged(snapshot) {
        if (snapshot && snapshot.invoice_location) {
            const loc = snapshot.invoice_location;
            this.deliveryLocationString = `${loc.street_name} ${loc.house_number} ${loc.house_addition}, ${loc.area_code} ${loc.city}`;
        }
    }

    /**
     * _addressChoiceChanged
     * {Boolean} choice
     */
    _addressChoiceChanged() {
        this._createAddressString();
    }

    /**
     * Goes back to settings overview
     */
    _backButtonPressed = () => {
        Router.go(routePaths.editSettings);
    };

    /**
     * _addressChanged
     * {Object} address
     */
    _addressChanged() {
        // @ts-ignore
        const a = this.address;
        if (
            a &&
            a.area_code &&
            a.area_code.length === 6 &&
            a.house_number &&
            a.house_number.length > 0
        ) {
            this._fetchAddress(a.area_code, a.house_number);
            setTimeout(() => {
                this._createAddressString();
            }, 500);
        }
    }

    /**
     * _verifiedAddressChanged
     * {Object} verified
     */
    _verifiedAddressChanged() {
        setTimeout(() => {
            this._createAddressString();
        }, 500);
    }

    /**
     * _verifiedAddressChanged
     */
    _createAddressString() {
        // @ts-ignore
        if (
            this.addressChoice &&
            this.snapshot &&
            this.snapshot.delivery_location
        ) {
            // @ts-ignore
            const verified = this.snapshot.delivery_location;
            this.set(
                'verifiedAddressString',
                'Is dit het adres? ' +
                    // @ts-ignore
                    `${verified.street_name} ${verified.house_number} ${
                        // @ts-ignore
                        verified.house_addition ? verified.house_addition : ''
                    } ${verified.area_code} ${verified.city}`,
            );
        } else {
            // @ts-ignore
            const verified = this.verifiedAddress;
            if (verified && verified.street_name) {
                this.set(
                    'verifiedAddressString',
                    'Is dit het adres? ' +
                        // @ts-ignore
                        `${verified.street_name} ${verified.house_number} ${
                            // @ts-ignore
                            this.address.house_addition
                                ? this.address.house_addition
                                : ''
                            // @ts-ignore
                        } ${this.address.area_code} ${verified.city}`,
                );
            } else {
                this.set('verifiedAddressString', '');
            }
        }
    }

    /**
     * _validateHouseNumber
     * @param {String|Number} verifiedHouseNumber
     * @return {Object}
     */
    _validateHouseNumber(verifiedHouseNumber) {
        const houseNumber = +verifiedHouseNumber;
        if (!houseNumber) {
            window.displayMessage(
                'Je huisnummer is niet geldig. Vul een geldig huisnummer in.',
            );
            return { err: true, houseNumber };
        }

        return { err: false, houseNumber };
    }

    /**
     * _updateInvoiceLocation
     */
    _updateInvoiceLocation() {
        const { err, houseNumber } = this._validateHouseNumber(
            this.address?.house_number,
        );
        if (err) {
            return;
        }
        // @ts-ignore
        const verified = this.verifiedAddress;
        let body = {
            area_code: '',
            house_number: 0,
            city: '',
            house_addition: '',
            street_name: '',
        };
        // @ts-ignore
        if (!this.addressChoice) {
            body = {
                area_code: this.address.area_code,
                house_number: houseNumber,
                city: verified.city,
                house_addition: '',
                street_name: verified.street_name,
            };

            if (this.address.house_addition.length > 0) {
                body.house_addition = this.address.house_addition;
            }
        }

        fetch(
            `
            ${API_LINK}/v1/user/${this.user.id}/snapshot/${
                this.snapshot.id
            }/updateInvoiceLocation${
                this.addressChoice ? '?sameAsDelivery=true' : ''
            }`,
            {
                method: 'PUT',
                headers: STDHeaders,
                body: JSON.stringify(body),
            },
        )
            .then((res) => res.json())
            .then((data) => {
                if (data.status === 400) {
                    store.dispatch(
                        setError(
                            'Het is niet gelukt om het factuuradres te wijzigen. Probeer het later nog eens.',
                        ),
                    );
                    return;
                }
                window.displayMessage(
                    'Je factuuradres is succesvol gewijzigd.',
                );
                const snapshot = store.getState().jwtSnapshots.selectedSnapshot;
                if (snapshot && data?.delivery_location) {
                    snapshot.invoice_location = data.delivery_location;
                    store.dispatch(setSelectedSnapshot(snapshot));
                }
                this.set('address', {
                    area_code: '',
                    house_number: '',
                    house_addition: '',
                });
                this.set('verifiedAddressString', '');
                this.notifyPath('verifiedAddressString');

                this.set('addressChoice', true);
                this.notifyPath('addressChoice');
                window.displayMessage(
                    'Je factuuradres is succesvol gewijzigd.',
                );

                Router.go(routePaths.settings);
            })
            .catch((error) => {
                console.warn(error);
                store.dispatch(
                    setError(
                        'Het is niet gelukt om het factuuradres te wijzigen. Probeer het later nog eens.',
                    ),
                );
            });
    }

    /**
     * _addressChanged
     * @param {String} areaCode
     * @param {String} verifiedHouseNumber
     */
    _fetchAddress(areaCode, verifiedHouseNumber) {
        const { err, houseNumber } =
            this._validateHouseNumber(verifiedHouseNumber);
        if (err) {
            return;
        }
        fetch(
            `${API_LINK}/v2/metering-point/location/check?area_code=${areaCode}&house_number=${houseNumber}`,
            {
                method: 'POST',
                headers: STDHeaders,
            },
        )
            .then((res) => res.json())
            .then((data) => {
                this.set('verifiedAddress', data);
            })
            .catch((error) => {
                console.warn(error);
                return store.dispatch(
                    setError(
                        'Kan adres niet ophalen, probeer het later nog eens.',
                    ),
                );
            });
    }

    /**
     * Gets template of class
     */
    static get template() {
        const cssTemplate = document.createElement('template');
        cssTemplate.innerHTML =
            provider.styles(css) + provider.styles(settingsEditCss);
        const htmlTemplate = document.createElement('template');
        htmlTemplate.innerHTML = template;
        return html`<style include="iron-flex">
                ${cssTemplate}
            </style>
            ${htmlTemplate}`;
    }
}

window.customElements.define('settings-edit-address', SettingsEditAddress);
