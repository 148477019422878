/**
 * Iron flex @apply CSS blocks for patching. These can be removed from the
 * codebase once all @apply occurrences have been removed from all theme files.
 */
export const layoutFlex: { [k: string]: string } = {
    '--layout': `
        display: flex;
    `,
    '--layout-inline': `
        display: inline-flex;
    `,
    '--layout-horizontal': `
        display: flex;
        --at-apply-fdir: row;
        -ms-flex-direction: var(--at-apply-fdir);
        -webkit-flex-direction: var(--at-apply-fdir);
        flex-direction: var(--at-apply-fdir);
    `,
    '--layout-horizontal-reverse': `
        display: flex;
        --at-apply-fdir: row-reverse;
        -ms-flex-direction: var(--at-apply-fdir);
        -webkit-flex-direction: var(--at-apply-fdir);
        flex-direction: var(--at-apply-fdir);
    `,
    '--layout-vertical': `
        display: flex;
        --at-apply-fdir: column;
        -ms-flex-direction: var(--at-apply-fdir);
        -webkit-flex-direction: var(--at-apply-fdir);
        flex-direction: var(--at-apply-fdir);
    `,
    '--layout-vertical-reverse': `
        display: flex;
        --at-apply-fdir: column-reverse;
        -ms-flex-direction: var(--at-apply-fdir);
        -webkit-flex-direction: var(--at-apply-fdir);
        flex-direction: var(--at-apply-fdir);
    `,
    '--layout-wrap': `
        -ms-flex-wrap: wrap;
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;
    `,
    '--layout-wrap-reverse': `
        -ms-flex-wrap: wrap-reverse;
        -webkit-flex-wrap: wrap-reverse;
        flex-wrap: wrap-reverse;
    `,
    '--layout-flex-auto': `
        -ms-flex: 1 1 auto;
        -webkit-flex: 1 1 auto;
        flex: 1 1 auto;
    `,
    '--layout-flex-none': `
        -ms-flex: none;
        -webkit-flex: none;
        flex: none;
    `,
    '--layout-flex': `
        -ms-flex: 1 1 0.000000001px;
        -webkit-flex: 1;
        flex: 1;
        -webkit-flex-basis: 0.000000001px;
        flex-basis: 0.000000001px;
    `,
    '--layout-flex-2': `
        -ms-flex: 2;
        -webkit-flex: 2;
        flex: 2;
    `,
    '--layout-flex-3': `
        -ms-flex: 3;
        -webkit-flex: 3;
        flex: 3;
    `,
    '--layout-flex-4': `
        -ms-flex: 4;
        -webkit-flex: 4;
        flex: 4;
    `,
    '--layout-flex-5': `
        -ms-flex: 5;
        -webkit-flex: 5;
        flex: 5;
    `,
    '--layout-flex-6': `
        -ms-flex: 6;
        -webkit-flex: 6;
        flex: 6;
    `,
    '--layout-flex-7': `
        -ms-flex: 7;
        -webkit-flex: 7;
        flex: 7;
    `,
    '--layout-flex-8': `
        -ms-flex: 8;
        -webkit-flex: 8;
        flex: 8;
    `,
    '--layout-flex-9': `
        -ms-flex: 9;
        -webkit-flex: 9;
        flex: 9;
    `,
    '--layout-flex-10': `
        -ms-flex: 10;
        -webkit-flex: 10;
        flex: 10;
    `,
    '--layout-flex-11': `
        -ms-flex: 11;
        -webkit-flex: 11;
        flex: 11;
    `,
    '--layout-flex-12': `
        -ms-flex: 12;
        -webkit-flex: 12;
        flex: 12;
    `,
    '--layout-start': `
        -ms-flex-align: start;
        -webkit-align-items: flex-start;
        align-items: flex-start;
    `,
    '--layout-center': `
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
    `,
    '--layout-end': `
        -ms-flex-align: end;
        -webkit-align-items: flex-end;
        align-items: flex-end;
    `,
    '--layout-baseline': `
        -ms-flex-align: baseline;
        -webkit-align-items: baseline;
        align-items: baseline;
    `,
    '--layout-start-justified': `
        -ms-flex-pack: start;
        -webkit-justify-content: flex-start;
        justify-content: flex-start;
    `,
    '--layout-center-justified': `
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
    `,
    '--layout-end-justified': `
        -ms-flex-pack: end;
        -webkit-justify-content: flex-end;
        justify-content: flex-end;
    `,
    '--layout-around-justified': `
        -ms-flex-pack: distribute;
        -webkit-justify-content: space-around;
        justify-content: space-around;
    `,
    '--layout-justified': `
        -ms-flex-pack: justify;
        -webkit-justify-content: space-between;
        justify-content: space-between;
    `,
    '--layout-center-center': `
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
    `,
    '--layout-self-start': `
        -ms-align-self: flex-start;
        -webkit-align-self: flex-start;
        align-self: flex-start;
    `,
    '--layout-self-center': `
        -ms-align-self: center;
        -webkit-align-self: center;
        align-self: center;
    `,
    '--layout-self-end': `
        -ms-align-self: flex-end;
        -webkit-align-self: flex-end;
        align-self: flex-end;
    `,
    '--layout-self-stretch': `
        -ms-align-self: stretch;
        -webkit-align-self: stretch;
        align-self: stretch;
    `,
    '--layout-self-baseline': `
        -ms-align-self: baseline;
        -webkit-align-self: baseline;
        align-self: baseline;
    `,
    '--layout-start-aligned': `
        -ms-flex-line-pack: start;
        -ms-align-content: flex-start;
        -webkit-align-content: flex-start;
        align-content: flex-start;
    `,
    '--layout-end-aligned': `
        -ms-flex-line-pack: end;
        -ms-align-content: flex-end;
        -webkit-align-content: flex-end;
        align-content: flex-end;
    `,
    '--layout-center-aligned': `
        -ms-flex-line-pack: center;
        -ms-align-content: center;
        -webkit-align-content: center;
        align-content: center;
    `,
    '--layout-between-aligned': `
        -ms-flex-line-pack: justify;
        -ms-align-content: space-between;
        -webkit-align-content: space-between;
        align-content: space-between;
    `,
    '--layout-around-aligned': `
        -ms-flex-line-pack: distribute;
        -ms-align-content: space-around;
        -webkit-align-content: space-around;
        align-content: space-around;
    `,
    '--layout-block': `
        display: block;
    `,
    '--layout-invisible': `
        visibility: hidden !important;
    `,
    '--layout-relative': `
        position: relative;
    `,
    '--layout-fit': `
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    `,
    '--layout-scroll': `
        -webkit-overflow-scrolling: touch;
        overflow: auto;
    `,
    '--layout-fullbleed': `
        margin: 0;
        height: 100vh;
    `,
    '--layout-fixed-top': `
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
    `,
    '--layout-fixed-right': `
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
    `,
    '--layout-fixed-bottom': `
        position: fixed;
        right: 0;
        bottom: 0;
        left: 0;
    `,
    '--layout-fixed-left': `
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
    `,
};
