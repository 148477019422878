import { css } from 'lit-element';

export default css`
  /* layout */
  :host {
    position: relative;
    display: block;
    background: white;
    cursor: pointer;
    width: 100%;
    padding: 30px;
  }
  
  :host([disabled]) {
    cursor: default;
  }

  .block-half, .disabled {
    display: flex;
    height: 200px;
    flex-direction: column;
    justify-content: center;
  }

  .faq {
    font-size: 35px;
    text-align: center;
  }

  @media screen and (max-width: 1000px) {
    .faq {
      font-size: 22px;
    }
}

  .layout {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .dash-block, .disabled .title {
    transform: translateY(15px);
    opacity: 0;
    animation: fadeIn 1.5s ease-in forwards;
    min-height: 86px;
    display: flex;
    align-items: center;
  }
  /* end of layout */

  /* tooltip */
  tool-tip {
    display: block;
    position: absolute;
    right: 0;
    bottom: 0;
    padding: 14px;
    z-index: 1;
  }
  /* end of tooltip */
  
  /* icons */

  iron-icon {
    --iron-icon-height: 28px !important;
    --iron-icon-width: 28px !important;

    align-self: center !important;
    padding-right: 6px !important;
  }

  /* end of icons */

  /* textuals */

  .value, .disabled {
    font-family: var(--primary-font-family);
    font-size: 43.5px;
    font-weight: 300;
    line-height: 1;
    letter-spacing: -1.7px;
    color: #555;
  }

  .unit {
    margin-left: 5px;
    font-size: 16.5px;
    line-height: 2;
    letter-spacing: -0.6px;
    color: #aaa;
  }

  .upper-unit {
    font-size: 11.5px;
    line-height: 2;
    letter-spacing: -0.6px;
    color: #aaa;
  }

  .bodyContent, .disabled span {
    font-family: var(--primary-font-family);
    font-size: 12px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    padding-top: 10px;
    text-align: center;
    color: var(--grey);
  }

  .block-half .bodyContent span {
    padding: unset;
    margin: unset;
    display: unset;
  }

  .strong {
    font-weight: normal;
  }
  /* end of textuals */

  /* media queries */
  @media only screen and (max-width: 760px) {
    tool-tip {
      display: none;
    }
  }

  @media only screen and (max-device-width: 480px) {
    :host {
      padding: 15px;
    }

    iron-icon {
      --iron-icon-height: 35px;
      --iron-icon-width: 35px;

      padding: 0 0 10px 0;
    }

    span {
      padding: 6px;
      box-sizing: border-box;
    }

    .disabled .title {
      align-items: flex-start;
    }

    :host([disabled]) .disabled .title  {
      min-height: 10px;
    }
    :host([disabled]) .disabled .text {
      margin-top: 16px;
    }
    :host([disabled]) .disabled .text, 
    :host([disabled]) .disabled .text span  {
      font-size: 14px;
      height: fit-content;
    }
    .block-half, .disabled {
      justify-content: initial;
    }
  }
  /* end media queries */

  /* keyframes */
  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateY(15px);
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
  /* end of keyframes */

  /* disabled */
  .disabled {
    align-items: center;
    justify-content: center;
  }
  
  .disabled .savings, .disabled .settings, .disabled .price-cap {
    margin: unset;
    padding: unset;
    cursor: pointer;
    color: var(--default-primary-color);
  }

  .disabled .text {
    color: var(--color-gray-100);
  }

  .price-cap {
    text-decoration: none;
  }
  /* end of disabled */

  /* skeleton */
  .skeleton {
    width: 100%;
    opacity: 0.1;
    animation: fading 1.5s infinite;
  }

  .skeleton-wrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  }

  .unit-wrapper {
    flex-direction: row;
    display: flex;
  }

  .skeleton .line {
    margin: auto auto 6px;
    border-radius: 12px;
  }

  .skeleton .line:last-of-type {
    margin-bottom: 0;
  }

  .skeleton .line_one {
    background: rgba(227, 237, 254, 1);
    width: 100%;
    height: 8px;
  }

  .skeleton .line_two {
    background: rgba(230, 230, 230, 1);
    width: 95%;
    height: 12px;
  }

  .skeleton .line_three {
    background: rgba(230, 230, 230, 1);
    width: 75%;
    height: 8px;
  }
  /* end of skeleton */
}
`;
