import { css } from 'lit-element';

export default css`
    :host {
        --check-mark-color: var(--primary-color);

        font-size: 14px;
    }

    svg {
        height: 14px;
        width: 14px;
    }

    .valid {
        stroke: var(--check-mark-color);
    }

    .invalid {
        fill: var(--app-text-light);
        opacity: 0;
        transform: rotate(-90deg);
        animation: fadeInAndRotate 0.2s ease-in forwards;
    }

    .wrapper {
        display: flex;
        align-items: center;
    }

    .validation__text {
        margin-left: 8px;
    }

    .validation__text--valid {
        color: var(--app-text-light);
    }

    .validation__text--invalid {
        color: var(--tertiary-color);
    }

    .path {
        stroke-dasharray: 1000;
        stroke-dashoffset: 0;
    }

    .path.line {
        stroke-dashoffset: 1000;
        -webkit-animation: dash 0.9s 0.35s ease-in-out forwards;
        animation: dash 0.9s 0.35s ease-in-out forwards;
    }
    .path.check {
        stroke-dashoffset: -100;
        -webkit-animation: dash-check 0.9s 0.35s ease-in-out forwards;
        animation: dash-check 0.9s 0.35s ease-in-out forwards;
    }

    @-webkit-keyframes dash {
        0% {
            stroke-dashoffset: 1000;
        }
        100% {
            stroke-dashoffset: 0;
        }
    }

    @keyframes dash {
        0% {
            stroke-dashoffset: 1000;
        }
        100% {
            stroke-dashoffset: 0;
        }
    }

    @-webkit-keyframes dash-check {
        0% {
            stroke-dashoffset: -100;
        }
        100% {
            stroke-dashoffset: 900;
        }
    }

    @keyframes dash-check {
        0% {
            stroke-dashoffset: -100;
        }
        100% {
            stroke-dashoffset: 900;
        }
    }

    @keyframes fadeInAndRotate {
        0% {
            opacity: 0;
            transform: rotate(-90deg);
        }
        100% {
            opacity: 1;
            transform: rotate(0deg);
        }
    }
`;
