import { GridCell } from './../GridCell';
import { customElement, property, PropertyValues, query } from 'lit-element';
import { TemplateResult, html } from 'lit-html';

import { GridLogic } from '../GridLogic';
import '../../base/checkbox-component/CheckboxComponent';
import { CheckboxComponent } from '../../base/checkbox-component/CheckboxComponent';
import { CheckboxEvent } from '../../base/checkbox-component/helpers/events';

/**
 * Path is the property used for sorting the data.
 * sort-direction returns in which order the item needs to be sorted
 */
@customElement('grid-checkbox')
export class GridCheckbox extends GridLogic {
    @property()
    public path: string = '';

    @property()
    public header?: string;

    @property({ type: Boolean })
    public checked: boolean = false;

    @property({ type: Number })
    public itemsSelected: number = 0;

    @property({ type: Number })
    public itemsTotal: number = 0;

    @query('checkbox-component')
    private checkbox?: HTMLElementTagNameMap['checkbox-component'];

    private preventUpdate: boolean = false;

    protected render = (): TemplateResult => {
        return html`
            <style>
                header {
                    cursor: pointer;
                }
                .info {
                    font-size: 10px;
                }
            </style>
            <header
                class="checkbox-parent checkbox-header"
                @click=${this.checkSlottedElements}
            >
                <checkbox-component
                    class="checkbox-parent"
                    @checkbox-changed=${this.checkSlottedElements}
                    ?checked=${this.checked}
                ></checkbox-component>

                ${this.itemsTotal && this.itemsSelected > 0
                    ? html`<span
                          class="checkbox-parent info"
                          @click=${this.checkSlottedElements}
                      >
                          ${this.itemsSelected} / ${this.itemsTotal}</span
                      >`
                    : null}
            </header>
            <main>
                <slot
                    @slotchange=${this.handleSlotchangeEvent}
                    class="slotted"
                ></slot>
            </main>
        `;
    };

    public firstUpdated(changedProperties: PropertyValues) {
        super.firstUpdated(changedProperties);
        addEventListener('checkbox-parent-change', ((e: CustomEvent) => {
            e.stopImmediatePropagation();

            this.checked = e.detail as boolean;
            this.preventUpdate = true;
        }) as EventListener);
    }

    protected updated(changedProperties: PropertyValues) {
        if (
            changedProperties.has('checked') &&
            changedProperties.get('checked') !== undefined
        ) {
            if (this.checkbox) {
                this.checkbox.checked = this.checked;
            }
        }
    }

    public reset = (): void => {
        this.preventUpdate = true;

        this.checked = false;
        this.itemsSelected = 0;
        this.itemsTotal = this.itemsTotal;

        this.requestUpdate();
    };

    // Get grid-cells from the slot.
    private getGridCellsElements = (): GridCell[] | undefined => {
        if (this.shadowRoot) {
            const slot = this.shadowRoot.querySelector('slot');
            if (slot) {
                // slotted elements (grid-cells)
                return slot.assignedElements() as GridCell[];
            }
        }

        return;
    };

    // adds a checkbox component to every slot
    private handleSlotchangeEvent = (e: Event): void => {
        e.stopImmediatePropagation();
        // get all grid cells
        const slotElements = this.getGridCellsElements();
        if (slotElements == null) {
            return;
        }

        slotElements.forEach((element) => {
            element.checkbox = true;
        });

        this.dispatchEvent(
            new CustomEvent('checkbox-slotted-length', {
                bubbles: true,
                composed: true,
                detail: slotElements.length,
            }),
        );
    };

    private checkSlottedElements = (e: CheckboxEvent): void => {
        const target = e.target;
        if (
            !this.preventUpdate &&
            target &&
            (target as CheckboxComponent as HTMLElement).classList.contains(
                'checkbox-parent',
            )
        ) {
            let checked = e.detail.checked;
            // when clicked on elements inside head update the checked first.
            if (
                (checked == null &&
                    (target as HTMLElement).localName ===
                        'checkbox-component') ||
                (checked == null &&
                    (target as HTMLElement).localName === 'span')
            ) {
                checked = !this.checked;
            }

            e.stopImmediatePropagation();
            const slotElements = this.getGridCellsElements();
            if (!slotElements) {
                return;
            }

            // check if target is header and set checkbox value and prevent dispatch after checkbox update from header.
            if ((target as HTMLElement).classList.contains('checkbox-header')) {
                this.preventUpdate = true;
                const checkbox: CheckboxComponent | undefined = (
                    target as HTMLElement
                ).querySelector('.checkbox-parent') as CheckboxComponent;

                if (checkbox) {
                    checked = !checkbox.checked;
                }
            }

            // update all the child checkbox component values
            slotElements.forEach((element) => {
                const shadow = element.shadowRoot;

                if (shadow) {
                    const checkbox = shadow.querySelector('checkbox-component');
                    if (checkbox) {
                        checkbox.checked = checked;
                    }
                }
            });

            // set checked
            if (this.checkbox) {
                this.checked = checked;
            }

            this.dispatchCheckboxParentChanged();
        } else if (this.preventUpdate) {
            this.preventUpdate = !this.preventUpdate;
        }
    };

    private dispatchCheckboxParentChanged = (): void => {
        this.dispatchEvent(
            new CustomEvent('all-checkboxes-changed', {
                bubbles: true,
                composed: true,
                detail: this.checked,
            }),
        );
    };
}

declare global {
    interface HTMLElementTagNameMap {
        'grid-checkbox': GridCheckbox;
    }
}
