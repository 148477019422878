import { customElement } from 'lit-element';
import { MaxLength, Required } from '@lion/form-core';
import { whitelabel } from '@weavelab/whitelabel';
import { store } from 'data-access/store';
import { forgotPassword } from '@async-reducers/user';
import { Form } from './Form';
import { IsEmailWithTrim } from './validators/EmailWithTrim';
import { CustomEventValueTrimmer } from './helpers/value';

@customElement('ice-forgot')
@whitelabel({ name: 'ice-forgot' })
export class Forgot extends Form {
    @whitelabel()
    public static showLabel?: boolean;

    @whitelabel()
    public static styleObject?: Partial<CSSStyleDeclaration>;

    @whitelabel()
    public static placeholder?: Partial<{
        email: string | boolean;
    }>;

    @whitelabel()
    public static label?: Partial<{
        email: string;
        submit: string;
    }>;

    public connectedCallback(): void {
        super.connectedCallback();

        this.style.width = '100%';

        Object.keys(Forgot.styleObject ?? {}).forEach((k: string) => {
            // @ts-ignore
            this.style[k] = Forgot.styleObject[k];
        });
    }

    protected form = [
        Form.field('username', 'email', Forgot.label?.email ?? 'E-mailadres', {
            label: Forgot.showLabel ?? false,
            placeholder: Forgot.placeholder?.email ?? 'naam@domein.nl',
            validators: [
                new IsEmailWithTrim(null, {
                    getMessage: async () => 'E-mailadres is onjuist',
                }),
                new MaxLength(320, {
                    getMessage: async () =>
                        'Het e-mailadres mag niet langer zijn dan 320 tekens',
                }),
                new Required(null, {
                    getMessage: async () => 'Verplicht veld',
                }),
            ],
            listener: CustomEventValueTrimmer,
        }),
        Form.button('submit', Forgot.label?.submit ?? 'Wachtwoord reset'),
    ];

    // eslint-disable-next-line class-methods-use-this
    protected async submit(values: { email: string }): Promise<void> {
        if (values.email === '') {
            window.displayMessage('Vul een e-mailadres in.');
            return;
        }
        store.dispatch(forgotPassword.run(values.email.trim()));
    }
}

declare global {
    interface HTMLElementTagNameMap {
        'ice-forgot': Forgot;
    }
}
