import { setIconFamily } from '@async-reducers/icon';
import { Toast } from '@atoms/Toast';
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';
import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { Keyboard } from '@capacitor/keyboard';
import { StatusBar, Style } from '@capacitor/status-bar';
import '@components/elements/modal/ModalComponent';
import '@elements/lock-icon/LockIcon';
import '@molecules/HvLoaderModal/HvLoaderModal';
import '@molecules/HvNotificationComponent';
import '@molecules/HvNotificationsManager';
import '@polymer/app-layout/app-layout';
import '@polymer/iron-flex-layout/iron-flex-layout-classes';
import '@polymer/iron-form/iron-form';
import '@polymer/iron-icon/iron-icon';
import '@polymer/iron-icons/iron-icons';
import '@polymer/iron-pages/iron-pages';
import '@polymer/paper-button/paper-button';
import '@polymer/paper-dialog/paper-dialog';
import '@polymer/paper-icon-button/paper-icon-button';
import { setPassiveTouchGestures } from '@polymer/polymer/lib/utils/settings';
import { html, PolymerElement } from '@polymer/polymer/polymer-element';
import { Router } from '@vaadin/router';
import '@vaadin/vaadin-context-menu/vaadin-context-menu';
import { SwipeBack } from 'capacitor-plugin-ios-swipe-back';
import { provider } from '@weavelab/frontend-connect';
import { connect } from 'pwa-helpers/connect-mixin';
import { installMediaQueryWatcher } from 'pwa-helpers/media-query';
import { installOfflineWatcher } from 'pwa-helpers/network';
import watch from 'redux-watch';
import { receiveSelf } from '@async-reducers/authentication';
import { showOnboarding } from '@async-reducers/settings';
// These are the actions needed by this element.
import { mainRoutes, protectedRouteCheck, routePaths } from 'helpers/routing';
import { persistor, store } from 'data-access/store';
import {
    updateDrawerState,
    updateEnabledItems,
    updateLayout,
    updateOffline,
} from 'actions/app';
import { formInjector } from 'helpers/formInjector';

import { setLocation } from 'actions/routing';
import { debounce } from 'helpers/functions';
import {
    formatAddress,
    isOda,
    snapshotAllowedToBelisted,
} from 'helpers/snapshot';
import { hideLoginPrompt } from 'actions/loginPrompt';
import { logOut } from 'actions/user';

// eslint-disable-next-line no-unused-vars
import { notBusy } from 'actions/busy';
import { setError } from 'actions/error';
import { setLoaderDebounce } from 'actions/loader';
import { setShowMenu } from 'actions/menu';
import { setSelectedSnapshot } from 'actions/snapshot';
import settings from 'internationalization/settings';
import { insertScriptElement } from 'helpers/script';
import { checkForSnapshotsWithACollective } from 'helpers/collective';
import { formatDate } from '../../../globals';
// This element is connected to the Redux store.
import '../../EnodeSignIn';
import '../../ez-icons';
import '../../ez-menu-icons';
// Busy spinner
import '../../molecules/Spinner';
// Login popup
import '../ez-login-prompt/login-prompt';
import template from './app.html';
// @ts-ignore
import css from './app.pcss';
// Menu items
import './ez-menu-items/menu-items';

const vendorStyles = provider.settings('vendor-style');
const themeSettings = provider.settings('app');
const screenSettings = provider.settings('screens');
const landerSettings = provider.settings('lander');
const updateWaitMS = 1000;
const loaderDebounceMS = 1000;
const android = 'android';
const ios = 'ios';

/**
 * Ez app class
 * @extends PolymerElement
 */
export default class EzApp extends formInjector(
    connect(store)(PolymerElement),
) {
    /**
     * Gets template of class
     */
    static get template() {
        const cssTemplate = document.createElement('template');
        cssTemplate.innerHTML = provider.styles(css);
        const vendorTemplate = document.createElement('template');
        vendorTemplate.innerHTML = vendorStyles.cssText;
        const htmlTemplate = document.createElement('template');
        htmlTemplate.innerHTML = template;
        return html`<style include="iron-flex">
                ${vendorTemplate}
                ${cssTemplate}
            </style>
            ${htmlTemplate}`;
    }

    /**
     * Gets properties of class
     */
    static get properties() {
        return {
            _drawerOpened: Boolean,
            _snackbarOpened: Boolean,
            _offline: Boolean,
            acceptedTermsAndConditions: Boolean,
            userAuthenticated: {
                type: Boolean,
                value: false,
            },
            setupComplete: {
                type: Boolean,
                value: false,
            },
            showAccount: {
                type: Boolean,
                value: false,
            },
            showBackButton: {
                type: Boolean,
                value: false,
            },
            enabledItems: {
                type: Object,
                value: {
                    competition: false,
                    production: true,
                    electricity: true,
                    gas: false,
                    savings: true,
                    costs: true,
                    invoices: true,
                    splitBusiness: false,
                    chargingSessions: false,
                },
            },
            user: Object,
            snapshots: {
                type: Array,
                value: [],
            },
            selectedSnapshot: {
                type: Object,
            },
            sortedByLastestSnapshot: {
                type: Array,
                value: [],
            },
            _contextMenu: Object,
            appStartTitle: String,
            appStartBody: String,
            appStartPrimaryBtnTitle: String,
            appStartPrimaryBtnUrl: String,
            appStartSecondaryBtnTitle: String,
            appStartSecondaryBtnUrl: String,
            appScreens: {
                type: Object,
                value: {},
                notify: true,
            },
            iDinEnabled: Boolean,
            snapshotMenuItems: {
                type: Array,
                value: [],
            },
            selectedSnapshotMenuItem: String,
            hideScrollbar: {
                type: Boolean,
                value: false,
                reflectToAttribute: true,
            },
            routerOutlet: {
                type: Object,
            },
            showMenu: {
                type: Boolean,
                value: false,
            },
            splitBusiness: {
                type: Boolean,
                value: false,
            },
            loaderState: {
                type: Boolean,
                value: false,
            },
            insightContract: {
                type: Boolean,
                value: false,
            },
            currentPage: {
                type: String,
                value: '',
            },
            busy: {
                type: String,
                value: '',
            },
            hideNewContractButton: {
                type: Boolean,
                value: false,
            },
        };
    }

    /**
     * Class constructor sets passive touch gestures
     */
    constructor() {
        super();

        // To force all event listeners for gestures to be passive.
        // See https://www.polymer-project.org/3.0/docs/devguide/settings#setting-passive-touch-gestures
        setPassiveTouchGestures(true);
        // Allow asset access from within .html files
        this.asset = provider.asset;

        // Mark state as not busy on load
        store.dispatch(notBusy());
        store.dispatch(hideLoginPrompt());

        /** @type {String} */
        this.electricityAccentColor = settings.electricityAccentColor;
        /** @type {String} */
        this.electricityProductionAccentColor =
            settings.electricityProductionAccentColor;

        /** @type {String} */
        this.gasAccentColor = settings.gasAccentColor;

        /** @type {String} */
        this.savingsAccentColor = settings.savingsAccentColor;

        /** @type {String} */
        this.energyCostAccentColor = settings.energyCostAccentColor;

        /** @type {String} */
        this.iconFamily =
            themeSettings && themeSettings.iconFamily
                ? themeSettings.iconFamily
                : 'material';

        /** @type {String} */
        this.subtitle =
            themeSettings && themeSettings.subtitle
                ? themeSettings.subtitle
                : '';

        /** @type {String} */
        this.mobileDrawerAlign =
            themeSettings && themeSettings.mobileDrawerAlign
                ? themeSettings.mobileDrawerAlign
                : 'left';

        /** @type {Number} */
        this.mobileMenuVariant =
            themeSettings &&
            themeSettings.mobileMenuVariant != null &&
            !Number.isNaN(themeSettings.mobileMenuVariant)
                ? themeSettings.mobileMenuVariant
                : 1;

        /** @type {String} */
        this.appTitle = settings.appTitle;

        /** @type {Object} */
        this.appScreens = screenSettings || null;
        /** @type {String} */
        this.appStartTitle =
            themeSettings &&
            screenSettings &&
            screenSettings.start &&
            screenSettings.start.title &&
            screenSettings.start.title
                ? screenSettings.start.title
                : 'Welkom bij de gratis inzichten app';
        this.appStartBody =
            themeSettings &&
            screenSettings &&
            screenSettings.start &&
            screenSettings.start.body
                ? screenSettings.start.body
                : 'Deze app biedt je gratis inzicht in je energieverbruik. Dit is de basis om te kunnen besparen op je energie rekening.';
        this.appStartIllustration =
            themeSettings &&
            screenSettings &&
            screenSettings.start &&
            screenSettings.start.illustration
                ? screenSettings.start.illustration
                : 'start.svg';
        this.appStartPrimaryBtnTitle =
            themeSettings &&
            screenSettings &&
            screenSettings.start &&
            screenSettings.start.primaryBtnTitle
                ? screenSettings.start.primaryBtnTitle
                : 'Start';
        this.appStartPrimaryBtnUrl =
            themeSettings &&
            screenSettings &&
            screenSettings.start &&
            screenSettings.start.primaryBtnUrl
                ? screenSettings.start.primaryBtnUrl
                : 'explanation';
        this.appStartSecondaryBtnTitle =
            themeSettings &&
            screenSettings &&
            screenSettings.start &&
            screenSettings.start.secondaryBtnTitle
                ? screenSettings.start.secondaryBtnTitle
                : 'Login';
        this.appStartSecondaryBtnUrl =
            themeSettings &&
            screenSettings &&
            screenSettings.start &&
            screenSettings.start.secondaryBtnUrl
                ? screenSettings.start.secondaryBtnUrl
                : 'lander';

        this.hideOneSnapshot = themeSettings?.hideOneSnapshot
            ? themeSettings.hideOneSnapshot
            : false;
        this.showAddressAreaCode =
            themeSettings?.showAddressAreaCode != null
                ? themeSettings.showAddressAreaCode
                : true;
        this.hideNewContractButton =
            themeSettings?.hideNewContractButton != null
                ? themeSettings.hideNewContractButton
                : false;
        this.meterScanOpened = false;
        this.iDinEnabled = false;
        this.myAccount =
            themeSettings && themeSettings.myAccount != null
                ? themeSettings.myAccount
                : false;
        this.vendorSplitBussines = settings.splitBusiness;
        this.vendorChargingSessions = settings.vendorChargingSessions;

        this.showCurrentPrices = !!themeSettings?.showCurrentPrices;
        this.ABRoutes = themeSettings.ABRoutes || [
            routePaths.customer,
            routePaths.customerProductList,
            routePaths.customerForm,
            routePaths.customerOverview,
        ];

        // Serviceworker update
        document.addEventListener('update', () => {
            console.warn('update received');
            setTimeout(() => {
                window.location.reload();
            }, updateWaitMS);
        });

        // prices changes
        this.today = new Date();
        this.earliestDeliveryDate = themeSettings?.earliestDeliveryDate
            ? themeSettings.earliestDeliveryDate
            : new Date('2022', '00', '01');

        this.minimumWaitingDays = themeSettings?.minimumWaitingDays
            ? themeSettings.minimumWaitingDays
            : 30;
        this.showNotificationStyle = false;

        this.currentPage = location.pathname.split('/')[1];
        this.prevLocation = null;

        const onboarding =
            themeSettings?.onboarding != null ? themeSettings.onboarding : true;
        store.dispatch(showOnboarding.run(onboarding));

        if (!onboarding && !this.hideNewContractButton) {
            this.hideNewContractButton = !onboarding;
        }
    }

    /**
     * Init firebase analytics for the vendors that have the option enabled
     */
    initfirebaseAnalytics = async () => {
        // only init when device settings are set to init
        if (themeSettings.enableFirebaseAnalytics && !window.env.dev) {
            await FirebaseAnalytics.setCollectionEnabled({
                enabled: true,
            }).catch((e) =>
                console.warn(`Initialising firebase anayltics has failed`, e),
            );
        }
    };

    /**
     * Connected callback setup app
     */
    connectedCallback() {
        super.connectedCallback();
        installOfflineWatcher((offline) =>
            store.dispatch(updateOffline(offline)),
        );
        installMediaQueryWatcher(`(min-width: 460px)`, () =>
            store.dispatch(updateLayout()),
        );
        if (themeSettings.analytics && !window.env.dev) {
            const s = document.createElement('script');
            s.type = 'text/javascript';
            s.src = themeSettings.analytics;
            s.id = 'insertedanalytics';
            window.document.head.appendChild(s);
        }

        // app listeners to go back in history or close the app if the history is empty
        App.addListener('backButton', ({ canGoBack }) => {
            // fallback if the user is at the login page
            if (
                !canGoBack ||
                (window.history.length <= 3 && window.location.pathname === '/')
            ) {
                App.exitApp();
            }
            // return to the previous page
            window.history.back();
        });

        // if the app is a mobile device (IOS or Android)
        if (Capacitor.isNativePlatform()) {
            this.initfirebaseAnalytics();
            // Ios specific
            if (Capacitor.getPlatform() === ios) {
                Keyboard.setScroll({ isDisabled: true });
                SwipeBack.enable();
                this.setStatusBarStyleLight();
            } else if (Capacitor.getPlatform() === android) {
                // Display content under transparent status bar (Android only)
                StatusBar.setOverlaysWebView({ overlay: false });
            }
        }
    }

    // IOS Specific functions
    setStatusBarStyleLight = async () => {
        await StatusBar.setStyle({ style: Style.Light });
    };

    /**
     * Add listner for vaadin route changes to set the old page logic.
     */
    initListeners() {
        window.addEventListener('vaadin-router-location-changed', (event) => {
            if (event.detail && event.detail.location) {
                // prevent setting location when await login is busy
                if (window.store.getState().awaitLogin) {
                    return;
                }
                const { location } = event.detail;
                // fallback to prevent loading the same component multiple times
                if (this.prevLocation !== location.pathname) {
                    this.prevLocation = location.pathname;
                    const thirthPath = window.location.pathname.split('/')[3];
                    if (thirthPath === 'monthly-payment') {
                        this.currentPage = thirthPath;
                        store.dispatch(setLocation(location));
                        return;
                    }

                    this.currentPage = location.pathname.split('/')[1];
                    store.dispatch(setLocation(location));
                }
            }
        });
        window.addEventListener('close-menu', () => {
            this.set('showMenu', false);
        });
    }

    /**
     * Vaadin init router
     */
    initRouter() {
        if (this.routerOutlet && !this.router) {
            let dashboard;
            let currentPrices;
            this.router = new Router(this.routerOutlet);
            this.router.setRoutes([
                ...mainRoutes,
                {
                    action: async (context, commands) => {
                        import(
                            /* webpackChunkName: "energy-splitter" */ '../../../containers/energy-splitter/EnergySplitter'
                        );
                        if (!this.getSplitBusiness()) {
                            // TODO move this logic to the contract class
                            return commands.redirect(routePaths.overview);
                        }

                        return protectedRouteCheck(context, commands);
                    },
                    component: 'energy-splitter',
                    path: routePaths.energySplitter,
                },
                {
                    action: async (context, commands) => {
                        import(
                            /* webpackChunkName: "energy-splitter" */ '../../../containers/current-prices/CurrentPrices'
                        );
                        if (!this.showCurrentPrices) {
                            return commands.redirect(routePaths.overview);
                        }

                        return protectedRouteCheck(context, commands);
                    },
                    component: 'current-prices',
                    path: routePaths.hourPrices,
                },
                {
                    action: async (context, commands) => {
                        import(
                            /* webpackChunkName: "energy-splitter" */ '../../../containers/current-prices/CurrentPrices'
                        );
                        if (!this.showCurrentPrices) {
                            return commands.redirect(routePaths.login);
                        }
                        if (store.getState().showMenu) {
                            store.dispatch(setShowMenu(false));
                        }

                        if (!currentPrices) {
                            currentPrices =
                                commands.component('current-prices');
                        }
                        return currentPrices;
                    },
                    component: 'current-prices',
                    path: routePaths.currentPrices,
                },
                {
                    action: async (context, commands) => {
                        // FIX: for strange notation of params in URL
                        if (
                            context.hash?.startsWith(
                                '#/klant/adres/postalCode',
                                0,
                            )
                        ) {
                            return commands.redirect(
                                `/klant/adres/?postalCode${window.location.hash.substring(
                                    24,
                                )}`,
                            );
                        }
                        if (context.hash?.startsWith('#/', 0)) {
                            return commands.redirect(
                                window.location.hash.substring(1),
                            );
                        }

                        // todo: could implements logic to see if the page is not found and display
                        // a message in the window that hte pages has been redirected.
                        import(
                            /* webpackChunkName: "dashboard-wrapper" */ '../../../views/pages/DashboardWrapper'
                        );

                        const auth = await protectedRouteCheck(
                            context,
                            commands,
                        );
                        if (!dashboard) {
                            dashboard = commands.component('dashboard-wrapper');
                        }
                        if (auth == null) {
                            return dashboard;
                        }
                        return auth;
                    },
                    path: '(.*)',
                },
            ]);
        }
    }

    /**
     * Check if mobile menu variant
     * @param {Number|String} variant
     * @return {Boolean}
     */
    _mobileMenuVariant(variant) {
        return (
            !Number.isNaN(variant) &&
            this.mobileMenuVariant === parseInt(String(variant), 10)
        );
    }

    /**
     * Toggles menu drawer
     */
    _toggleDrawer() {
        this._destoryActiveTooltips();
        store.dispatch(updateDrawerState(!this._drawerOpened));
    }

    /**
     * Destroys all the active tooltips
     */
    _destoryActiveTooltips = () => {
        const tooltips = document.querySelectorAll('#chartjs-tooltip');
        if (tooltips && tooltips.length > 0) {
            tooltips.forEach((tooltip) => {
                tooltip.remove();
            });
        }
    };

    /**
     * _toggleSnapshotSelectorMenu
     */
    _toggleSnapshotSelectorMenu() {
        const menu = this.shadowRoot.querySelector('.snapshot-selector-menu');
        const arrow = this.shadowRoot.querySelector(
            '.snapshot-selector-menu iron-icon',
        );
        menu.classList.toggle('open');
        arrow.classList.toggle('open');
        const box = this.shadowRoot.querySelector('.snapshot-list');
        box.classList.toggle('open');
    }

    /**
     * _openContextMenu
     * @param {Event} e
     */
    _openContextMenu(e) {
        if (this._contextMenu) {
            this._contextMenu.open(e);
        }
    }

    /**
     * Get the current chosen snapshot and compare it to the iterated one
     * @param {string} snapshotId
     * @param {string} selectedSnapshotMenuItem
     * @return {string}
     * @private
     */
    _seeIfSnapshotIsCurrent = (snapshotId, selectedSnapshotMenuItem) => {
        if (selectedSnapshotMenuItem === snapshotId) {
            return 'current';
        }
        return '';
    };

    /**
     * Format the address if the street name extends a certain length.
     * @param {Object} address
     * @param {string} address.area_code
     * @param {string} address.city
     * @param {string} address.house_addition
     * @param {number} address.house_number
     * @param {string} address.id
     * @param {string} address.street_name
     * @return {string}
     */
    _formatAddress = (address) => formatAddress(address);

    /**
     * navigates the user to the orer page
     */
    _toOrderFlow() {
        if (landerSettings && landerSettings.additionalAddressDialogEnabled) {
            this.dispatchEvent(
                new CustomEvent('show-additional-address-dialog', {
                    bubbles: true,
                    composed: true,
                }),
            );
        } else {
            // close the menu
            this.toggelMenu();
            // hide sidebar menu
            if (store.getState().showMenu) {
                store.dispatch(setShowMenu(false));
            }
            // fallback for snapshots with an collective.
            const snapshotCollectiveID = checkForSnapshotsWithACollective();
            if (
                snapshotCollectiveID &&
                snapshotCollectiveID !== '00000000-0000-0000-0000-000000000000'
            ) {
                Router.go(
                    `${routePaths.customer}?collective=${snapshotCollectiveID}`,
                );
                return;
            }

            Router.go(routePaths.customer);
        }
    }

    /**
     * Toggle header snapshot menu
     * @private
     */
    _toggleHeaderSnapshotSelectorMenu() {
        this.toggelMenu();
    }

    /**
     * _changeSelectedSnapshot handles item-selected event for context menu
     * @param {Event} e
     */
    _changeSelectedSnapshot(e) {
        e.stopImmediatePropagation();
        const snap = e.model.item;
        store.dispatch(setSelectedSnapshot(snap));
    }

    /**
     * _getAddress returns address string for selectedSnapshot
     * @param {Object} snapshot
     * @return {String}
     */
    _getAddress(snapshot) {
        if (snapshot && snapshot.delivery_location) {
            return this._formatAddress(snapshot.delivery_location);
        }
        return 'Geen adres gevonden';
    }

    /**
     * _multipleContractsOneAddress returns boolean when for one address multiple snapshots are found
     * @param {Array} snapshots
     * @return {Boolean}
     */
    _multipleContractsOneAddress(snapshots) {
        if (!snapshots || (snapshots && snapshots.length === 1)) {
            return false;
        }
        for (const x of snapshots) {
            let found = 0;
            for (const y of snapshots) {
                if (this._getAddress(x) === this._getAddress(y)) {
                    found++;
                }
            }
            if (found > 1) {
                return true;
            }
        }
        return false;
    }

    /**
     * Logout a user
     */
    _logOut = () => {
        persistor
            .purge()
            .then(() => {
                localStorage.clear();
                store.dispatch(logOut);
                Router.go(routePaths.overview);
            })
            .catch((err) => {
                console.warn('failed at purging state: ', err);
            });
    };

    /**
     * Ready subscribes on error and adds display message event listener
     */
    ready() {
        super.ready();

        if (this.shadowRoot) {
            this.routerOutlet = this.shadowRoot.querySelector(`#router-outlet`);
        }
        // best practice to make the page feel and load faster.
        window.addEventListener('load', () => {
            this.initRouter();
        });
        // initalize AB testing script
        if (themeSettings.ABTestURL) {
            const scriptAdded = insertScriptElement(themeSettings.ABTestURL);
            if (!scriptAdded) {
                console.warn('AB script is already loaded');
            }
        }

        // required to listen for vaadin router changes
        this.initListeners();

        const selectedSnapshotWatcher = watch(store.getState, 'jwtSnapshots');
        store.subscribe(
            selectedSnapshotWatcher(
                /**
                 * @param {JWTSnapshotState} JwtSnapshotState
                 */
                (JwtSnapshotState) => {
                    this.selectedSnapshot = JwtSnapshotState.selectedSnapshot;
                    this.snapshots = JwtSnapshotState.snapshots;
                    if (this.selectedSnapshot) {
                        this.insightContract = isOda(this.selectedSnapshot);
                    }
                },
            ),
        );

        const menuWatcher = watch(store.getState, 'showMenu');
        store.subscribe(
            menuWatcher(
                /**
                 * @param {boolean} menu
                 */
                (menu) => {
                    this.showMenu = menu;
                },
            ),
        );

        const w = watch(store.getState, 'error.message');
        store.subscribe(
            w(
                /**
                 * @param {string} newError
                 */
                (newError) => {
                    if (newError !== '') {
                        window.displayMessage(newError, 'error');
                        store.dispatch(setError(''));
                    }
                },
            ),
        );

        if (
            !this.acceptedTermsAndConditions &&
            !this.userAuthenticated &&
            this.currentPage === 'splash'
        ) {
            Router.go(routePaths.start);
        }
        this.addEventListener('display-message', (e) => {
            this.displayMessage(e);
        });

        if (localStorage.getItem('auth') != null) {
            window.store.dispatch(receiveSelf.run());
        }

        const u = watch(store.getState, 'user');
        store.subscribe(
            u(
                /**
                 * @param {Object} user
                 */
                (user) => {
                    if (user.email != null && user.email !== '') {
                        this.set('showAccount', true);
                    } else {
                        this.set('showAccount', false);
                    }
                },
            ),
        );

        const pageWatcher = watch(store.getState, 'app');
        store.subscribe(
            pageWatcher(
                /**
                 * @param {Object} app
                 */
                (app) => {
                    if (app.routing) {
                        this.setEnabledItems(app);
                        if (window.cordova) {
                            this.$.ezHeader.scrollIntoView();
                        }
                        // hide scrollbar on parallax pages
                        this.hideScrollbar = this.currentPage === 'werkrooster';
                    }
                },
            ),
        );

        // watcher to show the latest snapshot for the addresses
        const addressesWatcher = watch(
            store.getState,
            'jwtSnapshots.sortedAndFilteredAddresses',
        );
        store.subscribe(
            addressesWatcher((addresses) => {
                this.snapshotMenuItems = addresses;
                this.notifyPath('snapshotMenuItems');
            }),
        );

        const snapshotsWatcher = watch(store.getState, 'jwtSnapshots');
        store.subscribe(
            snapshotsWatcher((jwtSnapshots) => {
                if (
                    jwtSnapshots &&
                    jwtSnapshots.snapshots &&
                    jwtSnapshots.snapshots.length > 0
                ) {
                    if (this._contextMenu === undefined) {
                        const snapshotSelectorContextMenu =
                            this.shadowRoot.querySelector(
                                'vaadin-context-menu',
                            );
                        if (snapshotSelectorContextMenu) {
                            this._contextMenu = snapshotSelectorContextMenu;
                            this._contextMenu.addEventListener(
                                'item-selected',
                                (e) => this._changeSelectedSnapshot(e),
                            );
                        }
                    }

                    this.snapshots = jwtSnapshots.snapshots;
                    this.selectedSnapshot = jwtSnapshots.selectedSnapshot;
                    this.sortedByLastestSnapshot =
                        jwtSnapshots.sortedByLastestSnapshot;
                    if (this.selectedSnapshot) {
                        this.selectedSnapshotMenuItem =
                            this.selectedSnapshot.id;
                    }
                    this.setEnabledItems();
                    if (jwtSnapshots.hasFlex) {
                        this.showCurrentPrices = true;
                    }
                } else {
                    this.snapshots = null;
                    this.selectedSnapshot = null;
                }
                this.splitBusiness = this.getSplitBusiness();
            }),
        );

        const state = store.getState();
        this.busy = state.busy ?? '';
        const watchBusy = watch(store.getState, 'busy');
        store.subscribe(
            watchBusy(
                /**
                 * @param {String?} busy
                 * @return {void}
                 */
                (busy) => {
                    this.busy = busy;
                },
            ),
        );

        this.loginPrompt = state.loginPrompt || null;
        const watchLoginPrompt = watch(store.getState, 'loginPrompt');
        store.subscribe(
            watchLoginPrompt(
                /**
                 * @param {String?} loginPrompt
                 * @return {void}
                 */
                (loginPrompt) => {
                    this.loginPrompt = loginPrompt;
                },
            ),
        );

        // watches if onboarding is show if not hide the create new contract button in the address menu
        const watchOnboarding = watch(store.getState, 'showOnboarding');
        store.subscribe(
            watchOnboarding(
                /**
                 * @param {Object} onboarding
                 * @return {void}
                 */
                (onboarding) => {
                    const onboardingIsHidden = !onboarding.data;
                    if (this.hideNewContractButton !== onboardingIsHidden) {
                        this.hideNewContractButton = onboardingIsHidden;
                    }
                },
            ),
        );

        /** Listen for cordova device ready here */
        document.addEventListener(
            'deviceready',
            () => {
                console.log('Cordova device ready');
            },
            false,
        );
        this.addEventListener('competition', () => {
            const enabled = this.enabledItems;
            enabled.competition = true;
            enabled.splitBusiness = this.splitBusiness;
            store.dispatch(updateEnabledItems(enabled));
        });

        this.addEventListener('show-additional-address-dialog', () => {
            const { additionAddressDialog } = this.$;
            // @ts-ignore
            additionAddressDialog.open();
        });

        // debounced loader function call
        const setLoaderDebounced = debounce(loaderDebounceMS, (loaderState) =>
            this.loaderLogic(loaderState),
        );

        // Loader watcher
        const loaderWatcher = watch(store.getState, 'loader');
        store.subscribe(
            /**
             * @param {LoaderInterface} loaderState
             * @return {void}
             */
            loaderWatcher((loaderState) => {
                if (loaderState.loading == null) {
                    return;
                }
                if (loaderState.preventDebounce) {
                    this.loaderLogic(loaderState);
                    if (loaderState.preventDebounce) {
                        window.store.dispatch(setLoaderDebounce());
                    }
                    return;
                }
                // call loader debounced
                setLoaderDebounced(loaderState);
            }),
        );
        if (this.iconFamily) {
            window.store.dispatch(
                setIconFamily.run({ family: this.iconFamily }),
            );
        }
    }

    loaderLogic(loaderState) {
        const loaderComponent =
            this.shadowRoot.querySelector('hv-loader-modal');

        if (!loaderComponent) {
            console.warn('loader component could not be found');
            return;
        }

        // fallback to set/remove the busy attribute in polymer 3.
        loaderComponent.busy = loaderState.loading;
        if (loaderState.loading) {
            loaderComponent.setAttribute('busy', loaderState.loading);
        } else {
            loaderComponent.removeAttribute('busy');
        }
    }

    /**
     * setEnabledItems
     * @param {any} app
     */
    setEnabledItems(app) {
        if (
            this.selectedSnapshot &&
            this.selectedSnapshot.snapshot_phase &&
            this.selectedSnapshot.snapshot_phase >= 900
        ) {
            const { href } = window.location;
            // This part is responsible for the iDIN flow handling
            if (
                this.selectedSnapshot.snapshot_phase === '900' ||
                Number(this.selectedSnapshot.snapshot_phase) === 900
            ) {
                if (href.includes('failure')) {
                    window.displayMessage(
                        `Het koppelen van je meterpunt(en) is mislukt. Vul alsjeblieft de laatste zes cijfers van je barcode in.`,
                    );
                    this.meterScanOpened = true;
                } else if (href.includes('success')) {
                    window.displayMessage(
                        `Het koppelen van je meterpunt(en) is gelukt. Kom na 3 dagen terug voor je verbruik.`,
                    );
                    this.selectedSnapshot.snapshot_phase = 990;
                    this._toHome(true);
                }
                this.meterScanOpened = true;
            } else {
                this.meterScanOpened = false;
            }
            this.enabledItems = {
                competition:
                    this.selectedSnapshot &&
                    this.selectedSnapshot.competition &&
                    this.selectedSnapshot.competition,
                costs:
                    this.selectedSnapshot &&
                    this.selectedSnapshot.calculated_product_group_verified
                        .yearly_pricing !== 0,
                electricity:
                    this.selectedSnapshot &&
                    this.selectedSnapshot.verified_snapshot_payload.has_elec,
                gas: false,
                invoices: false,
                production:
                    this.selectedSnapshot &&
                    this.selectedSnapshot.verified_snapshot_payload
                        .elec_prod_amount > 0,
                savings: false,
                splitBusiness: this.getSplitBusiness(),
                chargingSessions: this.vendorChargingSessions,
            };
        } else if (app) {
            this.enabledItems = app.enabledItems;
        }
    }

    /**
     * get splitbusiness for the user
     * @return {Boolean}
     */
    getSplitBusiness() {
        if (this.selectedSnapshot == null) {
            return false;
        }
        return this.vendorSplitBussines;
    }

    /**
     * goToIdin enables IDin
     */
    goToIdin() {
        this.meterScanOpened = false;
        this.iDinEnabled = true;
    }

    /**
     * Displays a message in toast
     * @param {Object} e event object containing text in detail
     * {'info'| 'succes'| 'warning'| 'error'}
     */
    displayMessage(e) {
        const toast = this.shadowRoot.querySelector('ez-toast');
        if (toast) {
            toast.text = e.detail.text;
            let duration = 5000;
            if (e.detail.duration != null) {
                duration = e.detail.duration;
            }
            if (toast instanceof Toast) {
                toast.type = e.detail.type ? e.detail.type : 'info';
                toast.showMessage(duration);
                toast.addEventListener('click', (event) => {
                    const toast = event.target;
                    toast.style.visibility = 'hidden';
                });
            }
            return;
        }

        console.warn('toast component is missing');
    }

    /**
     * Formats date to string format
     * @param {Date} date from user state object
     * @return {String} dd-mm-yyyy
     */
    _formatDate = (date) => formatDate(date);

    /**
     * Sets active property when page is name of page
     * @param {String} name of page
     * @param {String} selected page
     * @return {Boolean} when page is selected
     */
    _usageActive = (name, selected) => name === selected;

    /**
     * Returns if we should show the desktop header
     * @param {String} page
     * @return {Boolean} indicating if in epic flow
     */
    _shouldShowDesktopHeader = (page) =>
        page === 'klant' ||
        page === 'lander' ||
        page === 'resetpw' ||
        page === 'forgot';

    /**
     * Returns outer wrapper based on page
     * @return {String} class for outer wrapper
     */
    _getOuterWrapperClass() {
        if (
            this.currentPage === 'klant' ||
            this.currentPage === 'lander' ||
            this.currentPage === 'resetpw' ||
            this.currentPage === 'forgot'
        ) {
            return 'klantOuterWrapper';
        }
        return 'appOuterWrapper';
    }

    /**
     * Returns app wrapper based on page
     * @return {String} class for app wrapper
     */
    _getAppWrapperClass() {
        if (
            this.currentPage === 'customer' ||
            this.currentPage === 'lander' ||
            this.currentPage === 'resetpw' ||
            this.currentPage === 'forgot'
        ) {
            return 'klantAppWrapper';
        }
        return 'appWrapper';
    }

    /**
     * Navigate to user settings page
     */
    _goToSettings = () => {
        const currentHref = window.location.href;
        if (currentHref.includes(routePaths.customer)) {
            Router.go(routePaths.overview);
        } else {
            Router.go(routePaths.settings);
        }
    };

    /**
     * Navigate to overview page
     */
    _goToOverview = () => {
        Router.go(routePaths.overview);
    };

    /**
     * _toHome
     * @param {Boolean} param
     * Navigates to home or the given theme setting
     */
    _toHome(param = false) {
        if (settings.logoAppLink && settings.logoAppLink !== '' && !param) {
            window.location.href = settings.logoAppLink;
        }

        if (this.userAuthenticated) {
            Router.go(routePaths.overview);
            return;
        }

        if (settings.logoRedirect && settings.logoRedirect.length > 2) {
            window.location.href = settings.logoRedirect;
        }
        Router.go(routePaths.overview);
    }

    /**
     * Submit additional address form
     */
    _submitAddititonalAddressForm() {
        const form = this.$.additionalAddressForm;
        if (form.validate()) {
            const values = form.serializeForm();
            fetch(
                `
                ${API_LINK}/v1/geocode/check?areaCode=${
                    values.area_code
                }&houseNumber=${Number(values.house_number)}${
                    values.house_addition && values.house_addition.length > 0
                        ? `&houseAddition=${values.house_addition}`
                        : ``
                }`,
                {
                    method: 'GET',
                    headers: STDHeaders,
                },
            )
                .then((res) => res.json())
                .then((data) => {
                    if (data.status && data.status > 204) {
                        throw new Error('Error address not found or validates');
                    }
                    let href = `/klant?postalCode=${values.area_code}`;
                    href += `&houseNumber=${values.house_number}`;
                    href += `&houseNumberSuffix=${
                        values.house_addition ? values.house_addition : ''
                    }`;
                    window.location.href = href;
                    // @ts-ignore
                    this.$.additionAddressDialog.close();
                })
                .catch(() =>
                    store.dispatch(
                        setError(
                            'Het door jou gekozen adres is niet gevonden. Controleer de velden en probeer het nogmaals.',
                        ),
                    ),
                );
        }
    }

    /**
     * State changed observer
     * @param {Object} state the app state
     */
    _stateChanged(state) {
        if (state != null) {
            this.user = state.user;
            this._offline = state.app.offline;
            this._snackbarOpened = state.app.snackbarOpened;
            this._drawerOpened = state.app.drawerOpened;
            this.acceptedTermsAndConditions =
                state.app.acceptedTermsAndConditions;
            this.userAuthenticated = state.app.userAuthenticated
                ? state.app.userAuthenticated
                : false;
            this.setupComplete = state.user.onboarding_finished;
        }
    }

    /**
     * check if the user has multiple snapshots.
     * @param {Object} selectedSnapshot
     * @return {Boolean}
     */
    _userHasMultipleSnapshots() {
        if (this.hideOneSnapshot == null || !this.hideOneSnapshot) {
            return true;
        }
        return this.snapshots?.length > 1;
    }

    _snapshotAllowedInList(snapshot) {
        return snapshotAllowedToBelisted(snapshot);
    }

    toggelMenu = () => {
        const menu = this.shadowRoot.querySelector('.snapshot-list');
        if (menu) {
            menu.classList.toggle('open');
        }
    };

    addNotificationStyle = (hideNotification) =>
        !hideNotification ? 'notification-flex' : '';

    renderPageTitle = (page) => {
        switch (page) {
            case 'monthly-payment':
                return 'Mijn maandbedrag';
            case 'settings':
                return 'Mijn gegevens';
            default:
                return page;
        }
    };

    _removeAllSpaces(event) {
        if (!event) {
            return;
        }
        const firstElement = event.composedPath()[0];
        if (firstElement?.value == null || firstElement?.value === '') {
            return;
        }
        firstElement.value = firstElement.value.replaceAll(' ', '');
    }

    _trimSpaces(event) {
        if (!event) {
            return;
        }
        const firstElement = event.composedPath()[0];
        if (firstElement?.value == null || firstElement?.value === '') {
            return;
        }
        firstElement.value = firstElement.value.trim();
    }
}

window.customElements.define('ez-app', EzApp);
