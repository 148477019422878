import {
    Bearer,
    BearerSupportedTypes,
    ISODate,
    ISODateSupportedTypes,
    ParameterDefinitionsObject,
    ParameterIn,
    RequestAction,
    RequestOptions,
    RequestSagaIterableIterator,
    ResolvableOrType,
    Resource,
    ResourceOptions,
    UUID,
    UUIDSupportedTypes,
} from 'goagen-js-resource';
import { Method } from 'axios';

export interface ShowUsageRequestObject {
    // (Boolean)
    chargerData?: boolean;
    // (ISODate)
    dateFrom?: ISODateSupportedTypes;
    // (ISODate)
    dateTill?: ISODateSupportedTypes;
    // (Number)
    intervalType?: number;
    // (UUID)
    snapshotId?: UUIDSupportedTypes;
    // (Number)
    usageType?: number;
    // (UUID)
    userId?: UUIDSupportedTypes;
    // Injected Bearer header (Bearer)
    xAuth?: BearerSupportedTypes;
}

export interface ResolvableShowUsageRequestObject {
    // (Boolean)
    chargerData?: ResolvableOrType<ShowUsageRequestObject['chargerData']>;
    // (ISODate)
    dateFrom?: ResolvableOrType<ShowUsageRequestObject['dateFrom']>;
    // (ISODate)
    dateTill?: ResolvableOrType<ShowUsageRequestObject['dateTill']>;
    // (Number)
    intervalType?: ResolvableOrType<ShowUsageRequestObject['intervalType']>;
    // (UUID)
    snapshotId?: ResolvableOrType<ShowUsageRequestObject['snapshotId']>;
    // (Number)
    usageType?: ResolvableOrType<ShowUsageRequestObject['usageType']>;
    // (UUID)
    userId?: ResolvableOrType<ShowUsageRequestObject['userId']>;
    // Injected Bearer header (Bearer)
    xAuth?: ResolvableOrType<ShowUsageRequestObject['xAuth']>;
}

/**
 * Store member management object for show usage
 */
export class ShowUsage extends Resource<
    ShowUsageRequestObject,
    ResolvableShowUsageRequestObject
> {
    /**
     * Create new ShowUsage
     *
     * | Parameter |  | Target |  |  |
     * | --- | --- | --- | --- | --- |
     * | `chargerData` | `Boolean` | query | `charger_data` |  |
     * | `dateFrom` * | `ISODate` | query | `dateFrom` |  |
     * | `dateTill` | `ISODate` | query | `dateTill` |  |
     * | `intervalType` * | `Number` | query | `intervalType` |  |
     * | `snapshotId` | `UUID` | query | `snapshotID` |  |
     * | `usageType` * | `Number` | query | `usageType` |  |
     * | `userId` | `UUID` | query | `userID` |  |
     * | `xAuth` | `Bearer` | header | `X-Auth` | Injected Bearer header |
     */
    constructor(
        storeName: string,
        options: ResourceOptions<ResolvableShowUsageRequestObject> = {},
    ) {
        super(storeName, options);
    }

    /**
     * Generate request action
     *
     * | Parameter |  | Target |  |  |
     * | --- | --- | --- | --- | --- |
     * | `chargerData` | `Boolean` | query | `charger_data` |  |
     * | `dateFrom` * | `ISODate` | query | `dateFrom` |  |
     * | `dateTill` | `ISODate` | query | `dateTill` |  |
     * | `intervalType` * | `Number` | query | `intervalType` |  |
     * | `snapshotId` | `UUID` | query | `snapshotID` |  |
     * | `usageType` * | `Number` | query | `usageType` |  |
     * | `userId` | `UUID` | query | `userID` |  |
     * | `xAuth` | `Bearer` | header | `X-Auth` | Injected Bearer header |
     */
    public request(
        requestObject: ShowUsageRequestObject = {},
        requestOptions: RequestOptions = {},
    ): RequestAction<ShowUsageRequestObject> {
        return super.request(requestObject, requestOptions);
    }

    /**
     * Run request as saga
     *
     * | Parameter |  | Target |  |  |
     * | --- | --- | --- | --- | --- |
     * | `chargerData` | `Boolean` | query | `charger_data` |  |
     * | `dateFrom` * | `ISODate` | query | `dateFrom` |  |
     * | `dateTill` | `ISODate` | query | `dateTill` |  |
     * | `intervalType` * | `Number` | query | `intervalType` |  |
     * | `snapshotId` | `UUID` | query | `snapshotID` |  |
     * | `usageType` * | `Number` | query | `usageType` |  |
     * | `userId` | `UUID` | query | `userID` |  |
     * | `xAuth` | `Bearer` | header | `X-Auth` | Injected Bearer header |
     */
    // @ts-ignore
    public get requestSaga(): (
        requestObject?: ShowUsageRequestObject,
        requestOptions?: RequestOptions,
    ) => RequestSagaIterableIterator<RequestAction<ShowUsageRequestObject>> {
        return super.requestSaga;
    }

    /**
     * Action string
     */
    static get action(): string {
        return 'show';
    }

    /**
     * Description string
     */
    static get description(): string {
        return "Get usage information for a user's meteringpoint";
    }

    /**
     * Request method
     */
    static get method(): Method {
        return 'get';
    }

    /**
     * Resource string
     */
    static get resource(): string {
        return 'usage';
    }

    /**
     * Route string
     */
    static get route(): string {
        return '/usage/';
    }

    /**
     * Unique name string
     */
    static get uniqueName(): string {
        return 'ShowUsage';
    }

    /**
     * Parameters object
     */
    static get parameters(): ParameterDefinitionsObject {
        return {
            chargerData: {
                in: ParameterIn.query,
                name: 'charger_data',
                type: Boolean,
            },
            dateFrom: {
                in: ParameterIn.query,
                name: 'dateFrom',
                required: true,
                type: ISODate,
            },
            dateTill: {
                in: ParameterIn.query,
                name: 'dateTill',
                type: ISODate,
            },
            intervalType: {
                in: ParameterIn.query,
                name: 'intervalType',
                required: true,
                type: Number,
            },
            snapshotId: {
                in: ParameterIn.query,
                name: 'snapshotID',
                type: UUID,
            },
            usageType: {
                in: ParameterIn.query,
                name: 'usageType',
                required: true,
                type: Number,
            },
            userId: {
                in: ParameterIn.query,
                name: 'userID',
                type: UUID,
            },
            xAuth: {
                description: 'Injected Bearer header',
                in: ParameterIn.header,
                name: 'X-Auth',
                type: Bearer,
            },
        };
    }
}
