import {
    Bearer,
    BearerSupportedTypes,
    ParameterDefinitionsObject,
    ParameterIn,
    RequestAction,
    RequestOptions,
    ISODateSupportedTypes,
    RequestSagaIterableIterator,
    ResolvableOrType,
    Resource,
    ResourceOptions,
    UUID,
    UUIDSupportedTypes,
} from 'goagen-js-resource';
import { Method } from 'axios';

export interface OfferRecalculateSnapshotRequestObject {
    // (UUID)
    snapshotId?: UUIDSupportedTypes;
    // Injected Bearer header (Bearer)
    xAuth?: BearerSupportedTypes;

    normal_usage?: number;
    low_usage?: number;
    gas_usage?: number;
    production?: number;
    calculation_date?: ISODateSupportedTypes;
}

export interface ResolvableOfferRecalculateSnapshotRequestObject {
    // (UUID)
    snapshotId?: ResolvableOrType<
        OfferRecalculateSnapshotRequestObject['snapshotId']
    >;
    // Injected Bearer header (Bearer)
    xAuth?: ResolvableOrType<OfferRecalculateSnapshotRequestObject['xAuth']>;

    normal_usage?: ResolvableOrType<
        OfferRecalculateSnapshotRequestObject['normal_usage']
    >;

    low_usage?: ResolvableOrType<
        OfferRecalculateSnapshotRequestObject['low_usage']
    >;

    gas_usage?: ResolvableOrType<
        OfferRecalculateSnapshotRequestObject['gas_usage']
    >;
    production?: ResolvableOrType<
        OfferRecalculateSnapshotRequestObject['production']
    >;

    calculation_date?: ResolvableOrType<
        OfferRecalculateSnapshotRequestObject['calculation_date']
    >;
}

/**
 * Store member management object for OfferRecalculate snapshot
 */
export class OfferRecalculateSnapshot extends Resource<
    OfferRecalculateSnapshotRequestObject,
    ResolvableOfferRecalculateSnapshotRequestObject
> {
    /**
     * Create new OfferRecalculateSnapshot
     *
     * | Parameter |  | Target |  |  |
     * | --- | --- | --- | --- | --- |
     * | `snapshotId` * | `UUID` | path | `snapshotID` |  |
     * | `xAuth` | `Bearer` | header | `X-Auth` | Injected Bearer header |
     */
    constructor(
        storeName: string,
        options: ResourceOptions<ResolvableOfferRecalculateSnapshotRequestObject> = {},
    ) {
        super(storeName, options);
    }

    /**
     * Generate request action
     *
     * | Parameter |  | Target |  |  |
     * | --- | --- | --- | --- | --- |
     * | `snapshotId` * | `UUID` | path | `snapshotID` |  |
     * | `xAuth` | `Bearer` | header | `X-Auth` | Injected Bearer header |
     */
    public request(
        requestObject: OfferRecalculateSnapshotRequestObject = {},
        requestOptions: RequestOptions = {},
    ): RequestAction<OfferRecalculateSnapshotRequestObject> {
        return super.request(requestObject, requestOptions);
    }

    /**
     * Run request as saga
     *
     * | Parameter |  | Target |  |  |
     * | --- | --- | --- | --- | --- |
     * | `snapshotId` * | `UUID` | path | `snapshotID` |  |
     * | `xAuth` | `Bearer` | header | `X-Auth` | Injected Bearer header |
     */
    // @ts-ignore
    public get requestSaga(): (
        requestObject?: OfferRecalculateSnapshotRequestObject,
        requestOptions?: RequestOptions,
    ) => RequestSagaIterableIterator<
        RequestAction<OfferRecalculateSnapshotRequestObject>
    > {
        return super.requestSaga;
    }

    /**
     * Action string
     */
    static get action(): string {
        return 'OfferRecalculate';
    }

    /**
     * Description string
     */
    static get description(): string {
        return 'Offer the user a recalculated contract based on properties';
    }

    /**
     * Request method
     */
    static get method(): Method {
        return 'post';
    }

    /**
     * Resource string
     */
    static get resource(): string {
        return 'snapshot';
    }

    /**
     * Route string
     */
    static get route(): string {
        return '/snapshot/offer/{snapshotID}/recalculate';
    }

    /**
     * Unique name string
     */
    static get uniqueName(): string {
        return 'OfferRecalculateSnapshot';
    }

    /**
     * Parameters object
     */
    static get parameters(): ParameterDefinitionsObject {
        return {
            snapshotId: {
                in: ParameterIn.path,
                name: 'snapshotID',
                required: true,
                type: UUID,
            },
            xAuth: {
                description: 'Injected Bearer header',
                in: ParameterIn.header,
                name: 'X-Auth',
                type: Bearer,
            },
        };
    }
}
