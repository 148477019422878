import { css } from 'lit-element';

export default css`
    :host {
        --hv-radio-button-margin-left: 5px;
        --hv-radio-button-background-color: #fff;
        --hv-radio-button-width: 10px;
        --hv-radio-button-height: 10px;
        --hv-radio-button-border-radius: 50%;
        --hv-radio-button-border: 3px solid #fff;
        --hv-radio-button-box-shadow: 0 0 1px 1px grey;
        --hv-radio-button-transition: 0.1s;
        --hv-radio-button-background--checked: #3aad3a;
        --hv-radio-button-box-shadow--checked: 0 0 1px 1px #3aad3a;
        --hv-radio-button-border--checked: 3px solid #fff;
    }
    div {
        margin-left: var(--hv-radio-button-margin-left);
        background: var(--hv-radio-button-background-color);
        width: var(--hv-radio-button-width);
        height: var(--hv-radio-button-height);
        border-radius: var(--hv-radio-button-border-radius);
        border: var(--hv-radio-button-border);
        box-shadow: var(--hv-radio-button-box-shadow);
        transition: var(--hv-radio-button-transition);
    }
    div[checked] {
        transition: var(--hv-radio-button-transition);
        background: var(--hv-radio-button-background--checked);
        box-shadow: var(--hv-radio-button-box-shadow--checked);
        border: var(--hv-radio-button-border--checked);
    }
`;
