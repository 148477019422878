import { css } from 'lit-element';

export default css`
    :host {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
    .current-status {
        float: left;
        margin-right: 10px;
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: #95a4ac;
    }

    .toggle {
        margin-right: -12px;
    }
`;
