import {
    INVALIDATE_GEOCODE,
    REQUEST_GEOCODE_SUCCESS,
    REQUEST_GEOCODE_FAILED,
} from '../actions/geocode';

/**
 * State reducer for geocode request
 * @param {Object} state
 * @param {Object} action
 * @return {Object}
 */
export const geocode = (
    state = { query: null, data: null, invalidated: false },
    action,
) => {
    switch (action.type) {
        case INVALIDATE_GEOCODE:
            return { ...state, invalidated: true, error: null };
        case REQUEST_GEOCODE_SUCCESS:
            return {
                ...state,
                error: null,
                data: action.data,
                invalidated:
                    !action.data ||
                    !action.data.city ||
                    !action.data.street_name,
            };
        case REQUEST_GEOCODE_FAILED:
            return { ...state, error: action.error, invalidated: true };
        default:
            return state;
    }
};
